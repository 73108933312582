import List from "src/dataTypes/lists/List";
import NumberList from "src/dataTypes/numeric/NumberList";
import StringList from "src/dataTypes/strings/StringList";
import DateList from "src/dataTypes/dates/DateList";
import IntervalList from "src/dataTypes/numeric/IntervalList";
import Table from "src/dataTypes/lists/Table";
import NetworkEncodings from "src/operators/structures/NetworkEncodings";
import ListGenerators from "src/operators/lists/ListGenerators";
import ListConversions from "src/operators/lists/ListConversions";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import TableOperators from "src/operators/lists/TableOperators";
import { instantiate } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc Table Encodings
 *
 * @namespace
 * @category basics
 */
function TableEncodings() {}
export default TableEncodings;

TableEncodings.ENTER = String.fromCharCode(13);
TableEncodings.ENTER2 = String.fromCharCode(10);
TableEncodings.ENTER3 = String.fromCharCode(8232);

TableEncodings.SPACE = String.fromCharCode(32);
TableEncodings.SPACE2 = String.fromCharCode(160);

TableEncodings.TAB = "	";
TableEncodings.TAB2 = String.fromCharCode(9);







/**
 * Decode a String in format CSV into a Table
 * @param {String} csv CSV formatted text
 *
 * @param {Boolean} first_row_header first row is header (default: false), 2 for automatic detection
 * @param {String} separator separator character (default: ","). If the value is 'auto' then the separator is determined automatically from a list of 8 commonly used characters
 * @param {Object} value_for_nulls Object to be placed instead of null values
 * @param {Boolean} listsToStringList if true (default value), converts lists that are not StringLists, NumberLists… (probably because they contain strings and numbers) into StringLists
 * @param {String} name to be assigned to table
 * @return {Table} resulting Table
 * tags:decoder
 * examples:santiago/examples/forIntroPanel/replacingElementsInTable
 */
TableEncodings.CSVtoTable = function(csvString, firstRowIsHeader, separator, valueForNulls, listsToStringList, name) {
  if(csvString==null) return null;
  

  //valueForNulls = valueForNulls == null ? "" : valueForNulls;
  listsToStringList = listsToStringList==null?false:listsToStringList;
  
  if(csvString.indexOf("\n")==-1 && csvString.indexOf(",")==-1 && csvString.indexOf(";")==-1 ){

    if(csvString.indexOf("http:/")===0 || csvString.indexOf("https:/")===0 || csvString.indexOf("fttp:/")===0 || csvString.indexOf("fttps:/")===0){
      throw new Error("The provided string seems to be a url, not a csv<br><br>Use <b>CSVLoader</b> to upload and decode csv files");
    } else {
      throw new Error("the provided string doesn't seem to be a csv, it contains no enters or chomas");
    }

  }

  var i, j;
  firstRowIsHeader = firstRowIsHeader == null ? false : firstRowIsHeader;

  var automaticFirstRowAsHEaderDetection = firstRowIsHeader==2;

  if(automaticFirstRowAsHEaderDetection){
    firstRowIsHeader = true;
  }

  if(csvString == null) return null;
  if(csvString === "") return new Table();

  csvString = csvString.replace(/\$/g, "");

  //var blocks = csvString.split("\"");
  

  //var blocks = csvString.split(/'|"/);

  var blocks = csvString.split("\"");


  for(i = 1; blocks[i] != null; i += 2) {
    blocks[i] = blocks[i].replace(/\n|\r/g, "*ENTER*");
  }
  csvString = blocks.join("\""); //TODO: create a general method for replacements inside "", apply it to chomas

  // var enterChar = TableEncodings.ENTER2;
  // var lines = csvString.split(enterChar);
  // if(lines.length == 1) {
  //   enterChar = TableEncodings.ENTER;
  //   lines = csvString.split(enterChar);
  //   if(lines.length == 1) {
  //     enterChar = TableEncodings.ENTER3;
  //     lines = csvString.split(enterChar);
  //   }
  // }

  var lines = csvString.split(/\n|\r/g);


  if(lines.length==1 && firstRowIsHeader){
    throw new Error("CSV contains only one line and firstRowIsHeader is true, a Table can't be build");
  }

  if(separator == 'auto'){
    // try and automatically detect separator using the first 11 rows
    var aAutoSepChars = [',',';',':','|','\t','+','-','_'];
    var shortCsvString = lines.slice(0,11).join('\n');
    var tTemp,score,scoreBest=0,sepBest=',';
    for(i=0;i<aAutoSepChars.length;i++){
      try{
        tTemp = TableEncodings.CSVtoTable(shortCsvString,firstRowIsHeader,aAutoSepChars[i]);
      }
      catch(e){
        tTemp=null;
      }
      if(tTemp == null || tTemp.length == 0) continue;
      // using the wrong separator will tend to:
      // a) give fewer columns
      // b) if firstRowIsHeader then some may have empty names
      // c) have more null values
      // d) have varying col lengths
      // build a simple score reflecting this and choose best
      TableOperators.buildInformationObject(tTemp);
      score = tTemp.length;
      if(firstRowIsHeader){
        var sLNames = tTemp.getNames();
        score -= sLNames.getElementNumberOfOccurrences('');
      }
      // count nulls in table
      var numNulls = tTemp.reduce(function(count, col) {
        return count + col.getElementNumberOfOccurrences(null);
        }, 0);
      // count cells in table
      var numCells = tTemp.reduce(function(count, col) {
        return count + col.length;
        }, 0);
      score -= numCells == 0 ? 0 : numNulls/numCells;
      if(!tTemp.infoObject.allListsSameLength)
        score--;
      if(score > scoreBest){
        scoreBest = score;
        sepBest = aAutoSepChars[i];
      }
    }
    separator = sepBest;
  }

  separator = (separator==null || separator==="")?",":separator;
  if(separator == '\\t' || separator == 'tab')
    separator = '\t';

  var table = new Table();
  var comaCharacter = separator;

  if(csvString == null || csvString === "" || csvString == " " || lines.length === 0) return null;

  var startIndex = 0;
  var headerContent;
  if(firstRowIsHeader) {
    startIndex = 1;

    //currently applied only in automatic header detection case, but could be applied always
    if(automaticFirstRowAsHEaderDetection){
      headerContent = NetworkEncodings.replaceChomasInLine(lines[0], separator).split(",");
      for(i=0;i<headerContent.length;i++){
        headerContent[i] = headerContent[i].replace("*CHOMA*", separator);
      }
    } else {
      headerContent = lines[0].split(comaCharacter);
    }

  }

  var element;
  var cellContent;
  var numberCandidate;
  var cellContents;
  //var actualIndex;


  var k;
  i = 0;

  for(k = startIndex; k < lines.length; k++) {
    //console.log(k, "lines[k].length", lines[k].length);

    if(lines[k].length < 2){
      continue;
    }

    cellContents = NetworkEncodings.replaceChomasInLine(lines[k], separator).split(comaCharacter); //TODO: will be obsolete (see previous TODO)
    //actualIndex = firstRowIsHeader ? i : i;

    // console.log('    √ i, actualIndex, cellContents.length, cellContents', i, actualIndex, cellContents.length, cellContents);
    // console.log(lines[k]);
    // console.log('-');
    // console.log(cellContents);
    // console.log('');
    //if(k>5) return null;

    for(j = 0; j < cellContents.length; j++) {
      table[j] = table[j] == null ? new List() : table[j];

      if(firstRowIsHeader && i === 0) {
        table[j].name = ( headerContent[j] == null ? "" : TableEncodings._removeQuotes(headerContent[j]) ).trim();
      }
      
      

      cellContent = cellContents[j].replace(/\*CHOMA\*/g, separator).replace(/\*ENTER\*/g, "\n");

      cellContent = cellContent === '' ? valueForNulls : cellContent;

      if(cellContent!=null) {
        cellContent = String(cellContent);
        numberCandidate = Number(cellContent.replace(',', '.'));
        element = (numberCandidate || (numberCandidate === 0 && cellContent !== '')) ? numberCandidate : cellContent;
        if(typeof element == 'string') element = TableEncodings._removeQuotes(element);
      } else {
        element = null;
      }
      
      table[j][i] = element;

      //if(j===0) console.log('   table[0][actualIndex]= ['+element+']');
    }

    i++;
  }

  //console.log('table[0].length',table[0].length);

  for(i = 0; table[i] != null; i++) {
    table[i] = table[i].getImproved();
    if(listsToStringList && table[i].type=="List") table[i] = ListConversions.toStringList(table[i]);
  }

  table = table.getImproved();
  table.name = name;

  if(automaticFirstRowAsHEaderDetection){
    //will atempt to infer if first row where actually values instead of names
    
    //1. check if there's at least a #L with a name that's not a number
    var allNumberListsHaveNumberNames = true;
    var nNumerical = 0;
    for(var i=0; i<table.length; i++){
      if(table[i].type=="NumberList"){
        nNumerical++;
        if(String(Number(table[i].name))!=Number(table[i].name)){
          allNumberListsHaveNumberNames = false;
          break;
        }
      }
    }

    if(!allNumberListsHaveNumberNames) return table;

    //2. check in how many cases names are part of list values
    var nCategoricalVarsWithNamesAsValues = 0;
    var nCategorical = 0;
    for(i=0; i<table.length; i++){
      if(table[i].type!="NumberList"){
        nCategorical++;
        if(table[i].includes(table[i].name)){
          nCategoricalVarsWithNamesAsValues++;
        } else {
          console.log("  [CSVT] table[i].name not appearing in other values:",table[i].name);
          var noRepetitions = table[i].getWithoutRepetitions().length==table[i].length;
          if(noRepetitions){
            console.log("     [CSVT] but all are different anyways");
            nCategoricalVarsWithNamesAsValues++;
          }
        }
      }
    }

    if(nCategorical>0 && ( (nCategoricalVarsWithNamesAsValues+nNumerical)/table.length)>0.3){
      for(i=0; i<table.length; i++){
        table[i].unshift(table[i].name);
        table[i].name = "col "+i;
      }
    }
  }


  return table;
};

/**
 * @ignore
 */
TableEncodings._removeQuotes = function(string) {
  if(string.length === 0) return string;
  if((string.charAt(0) == "\"" || string.charAt(0) == "'") && (string.charAt(string.length - 1) == "\"" || string.charAt(string.length - 1) == "'")) string = string.substr(1, string.length - 2);
  return string;
};


/**
 * Whenever the csv is simple, it doesn't contain "", and the types of lists are known in advance, this method is faster
 * @param {String} csv CSV formatted text
 *
 * @param {StringList} types of lists to be used (example NumberList, StringList…) If types is null, it will be assumed that first row is for types
 * @param {Boolean} first_row_header first row is header (default: false), except when first row is for types (in that case check this parameter true if second row is headers)
 * @param {String} separator separator character (default: ",")
 * @param {String} name to be assigned to table
 * @param {String} characterForChoma character used to replace chomas when the CSV was created (for instance "CHOMA", which is suggested in TableToCSV)
 * @return {Table} resulting Table
 * tags:decoder
 */
TableEncodings.CSVtoTableFast = function(csvString, types, firstRowIsHeader, separator, name, characterForChoma) {
  if(csvString==null) return;

  var lines = csvString.split(/\r\n|\n|\r/g);

  separator = separator==null?",":separator;

  if(types==null){
    types=lines[0].split(separator);
    lines = lines.slice(1);
  }
  
  var table = new mo.Table();
  var parts;
  var j;
  table.name = name;

  parts = lines[0].split(separator);

  if(characterForChoma!=null && !(characterForChoma instanceof RegExp)) characterForChoma = new RegExp(characterForChoma, "g");
  
  for(var i =0; i<types.length; i++){
    table[i] = instantiate(types[i]);
    if(firstRowIsHeader){
      table[i].name = parts[i];
      if(characterForChoma!=null) table[i].name = table[i].name.replace(characterForChoma,",");
    }
  }

  if(firstRowIsHeader) lines = lines.slice(1);

  for(i = 0; i<lines.length; i++){
    parts = lines[i].split(separator);
    if(parts.length!=types.length) continue;
    for(j=0; j<parts.length; j++){
      table[j][i] = table[j].type=="NumberList"?Number(parts[j]):parts[j];
      if(characterForChoma!=null && table[j].type=="StringList") table[j][i] = table[j][i].replace(characterForChoma,",");
    }
  }

  return table.getImproved();
};

/**
 * Encode a Table into a String in format CSV
 * @param {Table} Table to be encoded
 *
 * @param {String} separator character (default: ",")
 * @param {Boolean} first row as List names (default: false)
 * @param {String} replaceCommasBy if a value is provided (suggested: "CHOMA"), strings will have the commas replaced and won't be surrounded by quotes  (which makes the csv suitable to be decoded with CSVToTableFast)
 * @param {Boolean} addTypesAsFirstLine if true (default: false) writes the types of lists as first line, which makes it more suitable for using CSVToTableFast
 * @return {String} resulting String in CSV format
 * tags:encoder
 */
TableEncodings.TableToCSV = function(table, separator, namesAsHeaders,replaceCommasBy,addTypesAsFirstLine) {
  if(table==null) return;

  separator = separator || ",";
  var i;
  var j;
  var list;
  var type;
  if(table == null) return null;
  var lines = ListGenerators.createListWithSameElement(table[0].length, "");
  var addSeparator;
  for(i = 0; table[i] != null; i++) {
    list = table[i];
    type = list.type;
    addSeparator = i != table.length - 1;
    for(j = 0; j < list.length; j++) {
      if(list[j] !== null){
        switch(type) {
          case 'NumberList':
            lines[j] += list[j];
            break;
          default:
            if(replaceCommasBy!=null){
              lines[j] += String(list[j]).replace(/,/g,replaceCommasBy);
            } else {
              lines[j] += "\"" + list[j] + "\"";
            }
            break;
        }
      }
      if(addSeparator) lines[j] += separator;
    }
  }

  var headers = '';
  if(namesAsHeaders) {
    for(i = 0; table[i] != null; i++) {
      list = table[i];
      if(replaceCommasBy!=null){
        headers += list.name.replace(/,/g,replaceCommasBy);
      } else {
        headers += "\"" + list.name + "\"";
      }
      if(i != table.length - 1) headers += separator;
    }
    headers += '\n';
  }

  return (addTypesAsFirstLine?table.getTypes().join(separator)+"\n":"") + headers + lines.getConcatenated("\n");
};

/**
 * Encode a Table into a JSON String in a format that preserves key attributes
 * @param {Table} Table to be encoded
 * @param {Boolean} if true keep as object (default:false)
 *
 * @return {String} resulting String in JSON format
 * tags:encoder
 */
TableEncodings.TableToJSONString = function(table,bKeepObject) {
  if(table == null) return '';
  var array = table.slice(0);
  var names = table.getNames();
  var types = table.getTypes();
  var aNulls = [];
  // preserve whether it is undefined, true, or false
  for(var i=0; i < table.length; i++){
    if(table[i].containsNulls === undefined)
      aNulls.push(null);
    else
      aNulls.push(table[i].containsNulls);
  }
  var obj = {
    data:array,
    name:table.name,
    type:table.type,
    handle:table.handle,
    names:names,
    types:types,
    nulls:aNulls
    };
  if(table.containsNulls !== undefined)
    obj.containsNulls = table.containsNulls;
  if(bKeepObject) return obj;
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return JSON.stringify({"error":"cannot convert."});
  }
}

/**
 * Decode a JSON String representation of a Table
 * @param {String} json formatted text
 *
 * @return {Table} resulting Table
 * tags:decoder
 */
TableEncodings.JSONStringToTable = function(json) {
  if(json == null || json.length == 0) return new Table();
  var obj,i;
  if(typeof json == 'object')
    obj = json;
  else
    try {
      obj = JSON.parse(json);
    } catch(err) {
      return null;
    }
  var table;
  if(obj.type == 'NumberTable')
    table = NumberTable.fromArray(obj.data);
  else
    table = Table.fromArray(obj.data);
  table.name = obj.name == null ? obj.name : String(obj.name);
  table.type = obj.type;
  if(obj.handle != null)
    table.handle = obj.handle;
  if(obj.containsNulls != null)
    table.containsNulls = obj.containsNulls;
  if(obj.types && obj.types.length > 0)
    for(i=0; i < obj.types.length; i++){
      if(table[i]){
        switch(obj.types[i]){
          case 'StringList':
            table[i] = StringList.fromArray(table[i],false);
            break;
          case 'NumberList':
            table[i] = NumberList.fromArray(table[i],false);
            break;
          case 'DateList':
            table[i] = DateList.fromArray(table[i],true);
            break;
          case 'IntervalList':
            table[i] = IntervalList.fromArray(table[i],false);
            break;
          case 'List':
          default:
        }
      }
    }
  else{
    // No types defined. Get improved versions of lists
    for(i=0;i<table.length;i++){
      table[i] = table[i].getImproved();
      // sometimes numbers get encoded as strings
      if(table[i].type == 'StringList'){
        var bAllNumbers=true;
        for(var j=0;bAllNumbers && j<table[i].length;j++){
          bAllNumbers = !isNaN(table[i][j]) && table[i][j].trim() !== '';
        }
        if(bAllNumbers)
          table[i] = ListConversions.toNumberList(table[i]);
      }
    }
  }
  if(obj.names)
    for(i=0; i < obj.names.length; i++){
      if(table[i]) table[i].name = String(obj.names[i]);
    }
  if(obj.nulls)
    for(i=0; i < obj.nulls.length; i++){
      if(table[i] && obj.nulls[i] != null)
        table[i].containsNulls = obj.nulls[i];
    }
  return table;
}

