import Tree from "src/dataTypes/structures/networks/Tree";
import Node from "src/dataTypes/structures/elements/Node";
import NumberList from "src/dataTypes/numeric/NumberList";
import ColorListGenerators from "src/operators/graphic/ColorListGenerators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import Table from "src/dataTypes/lists/Table";
import ListOperators from "src/operators/lists/ListOperators";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import Interval from "src/dataTypes/numeric/Interval";
import StringList from "src/dataTypes/strings/StringList";

/**
 * @classdesc Tools to convert Trees to other data types
 *
 * @namespace
 * @category networks
 */
function TreeConversions() {}
export default TreeConversions;

/**
 * convert a table that describes a tree (higher hierarchies in first lists) into a Tree
 * count ocurrences of leaves in the table and assigns value to nodes weight
 * assigns the index of row associated to leaves in properties: firstIndexInTable and indexesInTable
 * @param {Table} table
 *
 * @param {String} fatherName name of father node
 * @param {Boolean} colorsOnLeaves
 * @param {Number} colorAssignmentMode <|>0: bottom up (Default)<|>1: top down solid<|>2: top down fading towards color
 * @param {String} color of deepest leaf for colorAssignmentMode 2
 * @param {NumberList} weights to use, must be same length as rows in the table. If not specified then weight is 1 for all elements
 * @param {ColorList} colors to use when colorsOnLeaves is true, must be same length as rows in the table. If not specified then a default set of colors are used
 * @param {number} iTableMode the method to interpret column data <|>0: each list is a level of the tree(default)<|>1: interpret a 2 column table as listing of parent-child pairs
 * @return {Tree}
 * tags:conversion
 */
TreeConversions.TableToTree = function(table, fatherName, colorsOnLeaves, colorAssignmentMode, colorDeepest, nLWeights, colors, iTableMode)  {
  if(table == null) return;

  if(table.length===0){
    throw new Error("Table has no Lists");
  } else if(table[0]===null){
    throw new Error("table[0] is null");
  } else if(nLWeights != null && nLWeights.length != table[0].length){
    throw new Error("weightsList must have a value for each row in the table");
  } else if(colors != null && colors.length != table[0].length){
    throw new Error("colors list must have a value for each row in the table");
  }

  fatherName = fatherName == null ? table[0].name : fatherName;
  colorAssignmentMode = colorAssignmentMode == null ? 0 : colorAssignmentMode;
  colorDeepest = colorDeepest == null  || colorDeepest == '' ? 'rgb(255,255,255)' : colorDeepest;
  iTableMode = iTableMode == null ? 0 : iTableMode;

  if(nLWeights != null && nLWeights.getMin() < 0)
    nLWeights = NumberListOperators.normalizeToInterval(nLWeights,new Interval(0,1));

  var tree = new Tree();
  var node, parent;
  var id;

  var father = new Node(fatherName, fatherName);
  tree.addNodeToTree(father, null);

  var nLists = table.length;
  //var nElements = table[0].length;
  var i, j;
  var list, element, iListToColorFrom;
  var leavesColorsDictionary;

  if(colorsOnLeaves){
    if(colorAssignmentMode == 0)
      iListToColorFrom = nLists-1;
    else  
      iListToColorFrom = 0;
    if(colors){
      var colorDictTable = Table.fromArray([table[iListToColorFrom], colors]);
      leavesColorsDictionary = ListOperators.buildDictionaryObjectForDictionary(colorDictTable);
    }
    else
      leavesColorsDictionary = ColorListGenerators.createCategoricalColorListForList(table[iListToColorFrom])[4].value;
  }

  if(iTableMode == 1){
    if(table.length != 2) throw new Error("Table must have 2 Lists for parent-child mode");
    var sLCurrent = ListOperators.difference(table[0].getWithoutRepetitions(),table[1].getWithoutRepetitions());
    if(sLCurrent.length == 0) throw new Error("Invalid data for a tree. Likely has loops in the relationships.")
    for(i=0; i < sLCurrent.length;i++){
      node = new Node(String(sLCurrent[i]), String(sLCurrent[i]));
      tree.addNodeToTree(node,father);
    }
    var sLChildren, nodec, nodep;
    // traverse table rows processing any that have parent in sLCurrent, save the children
    while(sLCurrent.length > 0){
      sLChildren = new StringList();
      for(i=0;i<table[0].length;i++){
          if(sLCurrent.indexOfElement(table[0][i]) == -1) continue;
          nodep = tree.nodeList.getNodeById(String(table[0][i]));
          if(nodep == null) new Error('Invalid data for a tree. Node '+String(table[0][i])+ ' not found as parent')
          nodec = tree.nodeList.getNodeById(String(table[1][i]));
          if(nodec != null) throw new Error('Invalid data for a tree. Node '+String(table[1][i])+ ' has multiple parents.')
          nodec = new Node(String(table[1][i]), String(table[1][i]));
          if(colorsOnLeaves && colorAssignmentMode == 0) {
              nodec.color = leavesColorsDictionary[String(table[1][i])];
          }
          else if( colorsOnLeaves && (colorAssignmentMode == 1 || colorAssignmentMode == 2) ) {
              nodec.color = leavesColorsDictionary[String(table[1][i])];
          }
          sLChildren.push(String(table[1][i]));
          tree.addNodeToTree(nodec,nodep);
          // addNodeToTree sets .weight property to 1
          if(nLWeights != null)
              nodec.weight = nLWeights[i];
      }
      sLCurrent = sLChildren.clone();
    }
  }
  else{
    for(i=0; i<nLists; i++){
      if (table[i] == null) continue;
      list = table[i];
      //if(list.length!=nElements) return null;

      for(j=0; j<list.length; j++){
        element = list[j];

        if(element!=null){
          id = TreeConversions._getId(table, i, j);
          node = tree.nodeList.getNodeById(id);
          if(node == null) {
            node = new Node(id, String(element));

            if( colorsOnLeaves && i==(nLists-1) && colorAssignmentMode == 0) {

              node.color = leavesColorsDictionary[element];
            }
            else if( colorsOnLeaves && (colorAssignmentMode == 1 || colorAssignmentMode == 2) ) {

              node.color = leavesColorsDictionary[table[0][j]];
            }

            if(i === 0) {
              tree.addNodeToTree(node, father);
            } else {

              parent = tree.nodeList.getNodeById(TreeConversions._getId(table, i - 1, j));

              tree.addNodeToTree(node, parent);
            }

            node.firstIndexInTable = j;
            node.indexesInTable = new NumberList(j);

            if(nLWeights != null)
              node.weight = nLWeights[j];

          } else {
            if(nLWeights != null)
              node.weight+= nLWeights[j];
            else
              node.weight++;

            node.indexesInTable.push(j);
          }
        }
      }
    }
  }

  if(colorsOnLeaves && colorAssignmentMode == 2){
    for(i=0; i < tree.nodeList.length; i++){
      node = tree.nodeList[i];
      if(node.level == 0)
        node.color = colorDeepest;
      else{
        node.color = ColorOperators.interpolateColors(node.color,colorDeepest,(node.level-1)/(tree.nLevels-1) );
      }
    }
  }
  tree.assignDescentWeightsToNodes();

  var _assignIndexesToNode = function(node) {

    var i;
    if(node.toNodeList.length === 0) {
      return node.indexesInTable;
    } else {
      node.indexesInTable = new NumberList();
    }
    for(i = 0; node.toNodeList[i] != null; i++) {
      node.indexesInTable = node.indexesInTable.concat( _assignIndexesToNode(node.toNodeList[i]) );
    }
    return node.indexesInTable;
  };

  _assignIndexesToNode(tree.nodeList[0]);

  return tree;
};

/**
 * @todo write docs
 */
TreeConversions._getId = function(table, i, j) {
  var iCol = 1;
  var id = "_"+String(table[0][j]);
  while(iCol <= i) {
    id += "_" + String(table[iCol][j]);
    iCol++;
  }
  return id;
};


/**
 * convert a tree into a Table (inverse operator of TableToTree)
 * @param {Tree} tree to convert
 *
 * @param {Boolean} includes_superior_node (default:true) first column is for the superior node (the column has unique value: superior node name)
 * @param {Boolean} valuesDifferentation (default:false) changes the name of value when names coincide and parents (value in previous column) are different
 * @return {Table}
 * tags:conversion,tree
 */
TreeConversions.TreeToTable = function(tree, includes_superior_node,valuesDifferentation) {
    if(tree==null) return;
    includes_superior_node = includes_superior_node==null?true:includes_superior_node;
    valuesDifferentation = valuesDifferentation==null?false:valuesDifferentation;

    var table = new mo.Table();
    var leaves = tree.getLeaves();
    var chain;
    var name;

    var noBrotherWithSameName = function(col, name, parentName){
      if(col==0) return false;
      for(var i=0;i<table[col].length;i++){
        if(table[col][i]==name && table[col-1][i]!=parentName) return true;
      }
      return false;
    };

    if(!valuesDifferentation) noBrotherWithSameName = function(){return false};
    
    for(var i=0; i<leaves.length; i++){
        chain = tree.getAncestorsOf(leaves[i]).reverse();
        chain.addNode(leaves[i]);
        if(!includes_superior_node) chain = chain.slice(1);
        for(var j=chain.length-1; j>=0; j--){
            if(table[j]==null) table[j]=new mo.StringList();
            name = chain[j].name;
            while(noBrotherWithSameName(j, name, j>0?chain[j-1].name:"")) name+="´";
            table[j][i] = name;
        }
    }

    return table;
};

/**
 * convert a tree into a javascript object that describes the tree nodes and properties including the children of each node
 * @param {Tree} tree is the input tree to convert
 *
 * @param {Boolean} bAll when true include all node properties<br>when false include only primary properties
 * @return {Object}
 * tags:conversion,tree
 */
TreeConversions.treeToObject = function(tree,bAll) {
  if(tree == null || tree.type != 'Tree') return null;
  var oOutput = {};
  var aCurrentParentObjects = [oOutput];
  var curLevel = 0, o;
  var aBareProperties = ['name','id','level','weight','color'];
  var nodeList = tree.traverse(null,null,function(nd){
    if(nd.level == 0)
      o = oOutput;
    else{
      o = {};
      aCurrentParentObjects[nd.level].push(o);
    }
    for(var p in nd){
      if(p == 'type' && nd.level == 0) continue;
      if(!bAll && !aBareProperties.includes(p)) continue;
      if(typeof nd[p] != 'object' && nd[p] != null){
        o[p] = nd[p];
      }
    }
    if(nd.toNodeList.length > 0){
      o.children = [];
      aCurrentParentObjects[nd.level+1] = o.children;
    }
    return true;
  });
  return oOutput;
};

/**
 * convert an object into a standard Tree datatype
 * @param {Object} obj is the input object to convert
 *
 * @return {Tree}
 * tags:conversion,tree
 */
TreeConversions.objectToTree = function(obj) {
  if(obj == null) return null;
  var tree = new Tree();

  var fnAddObjectToTree = function(tr,parentNode,o){
    var name = o.name != null ? o.name : '';
    var id = o.id != null ? o.id : '_' + name;
    if(tr.nodeList.getNodeById(id) != null){
      // already exists, make it unique
      id = tr.nodeList.getNewId();
    }
    var nd = new Node(id, name);
    tr.addNodeToTree(nd, parentNode);
    var bFirstArray = true;
    for(var i in o){
      if(i == 'id' || i == 'name') continue;
      if(Object.prototype.toString.call(o[i]) == "[object Array]"){
        if(bFirstArray){
          for(var j=0;j<o[i].length;j++)
            fnAddObjectToTree(tr,nd,o[i][j]);
          bFirstArray = false;
        }
        else{
          // just add the array as a property of node
          // clone it using stringify
          nd[i] = JSON.parse(JSON.stringify(o[i]));
        }
        continue;
      }
      // otherwise we have a simple property
      nd[i] = o[i];
    }
  }

  fnAddObjectToTree(tree,null,obj);

  return tree;
};
