import NumberOperators from "src/operators/numeric/NumberOperators";
import { instantiateWithSameType, typeOf, instantiate } from "src/tools/utils/code/ClassUtils";
import List from "src/dataTypes/lists/List";
import Table from "src/dataTypes/lists/Table";
import NumberList from "src/dataTypes/numeric/NumberList";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import StringList from "src/dataTypes/strings/StringList";
import ListOperators from "src/operators/lists/ListOperators";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import ListGenerators from "src/operators/lists/ListGenerators";
import ColorScales from "src/operators/graphic/ColorScales";
import ColorListGenerators from "src/operators/graphic/ColorListGenerators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import Tree from "src/dataTypes/structures/networks/Tree";
import Node from "src/dataTypes/structures/elements/Node";
import Relation from "src/dataTypes/structures/elements/Relation";
import Network from "src/dataTypes/structures/networks/Network";

import StringOperators from "src/operators/strings/StringOperators";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import StringListOperators from "src/operators/strings/StringListOperators";
import DateListOperators from "src/operators/dates/DateListOperators";
import StringListConversions from "src/operators/strings/StringListConversions";
import DateOperators from "src/operators/dates/DateOperators";
import {
  TYPES_SHORT_NAMES_DICTIONARY,
  getColorFromDataModelType
} from "src/tools/utils/code/ClassUtils";//repeated import source??

/**
 * @classdesc Table Operators
 *
 * @namespace
 * @category basics
 */
function TableOperators() {}
export default TableOperators;


/**
 * @todo finish docs
 */
TableOperators.getElementFromTable = function(table, i, j) {//deprecated, replaced by getCell
  if(table[i] == null) return null;
  return table[i][j];
};

/**
 * @todo finish docs
 */
TableOperators.getSubTable = function(table, x, y, width, height) {
  if(table == null) return table;

  var nLists = table.length;
  if(nLists === 0) return null;
  var result = new Table();

  if(width <= 0) width = (nLists - x) + width;
  x = Math.min(x, nLists - 1);
  width = Math.min(width, nLists - x);

  var nRows = table[0].length;

  if(nRows === 0) return null;

  if(height <= 0) height = (nRows - y) + height;

  y = Math.min(y, nRows - 1);
  height = Math.min(height, nRows - y);

  var column;
  var newColumn;
  var i;
  var j;
  var element;
  for(i = x; i < x + width; i++) {
    column = table[i];
    newColumn = new List();
    newColumn.name = table[i].name;
    for(j = y; j < y + height; j++) {
      element = column[j];
      newColumn.push(element);
    }
    result.push(newColumn.getImproved());
  }
  return result.getImproved();
};


/**
 * extracts a sub-table from a column to another
 * @param  {Table} table Table
 * @param  {Number|String} indexColumn0 first column<|0.name|>
 *
 * @param  {Number|String} indexColumn1 optional last column<|0.name|>
 * @return {Table}
 * tags:filter
 */
TableOperators.sliceColumns = function(table, indexColumn0, indexColumn1){
  if(typeof(indexColumn0)=='string') indexColumn0=table.getNames().indexOf(indexColumn0);
  if(indexColumn1!=null && typeof(indexColumn1)=='string') indexColumn1=table.getNames().indexOf(indexColumn1);
  return table.getSubList(indexColumn0, indexColumn1);
};

/**
 * filter the rows of a table by a criteria defined by an operator applied to all lists or a selected list
 * @param  {Table} table Table
 * @param  {String} operator selector filter operator:<|>=c : (default, exact match for numbers, contains for strings)<|>==<|><<|><=<|>><|>>=<|>!=<|>contains<|>between<|>!between<|>!contains<|>init : Function that returns a boolean<|>unique<|>fuzzy : fuzzy search<|>fuzzywords
 * @param  {Object} value to compare against, or list of values (for OR or AND filtering, see )
 *
 * @param  {Number|String|List} listToCheck it could be one of the following options:<br>null : (default) means it checks every list, a row is kept if at least one its values verify the condition<br>a number, an index of the list to check<br>a string : the name of the list to check<br>a list : (with same sizes as the lists in the table) that will be used to check conditions on elements and filter the table.<|0.name|>
 * @param  {Object} value2 only used for "between", "fuzzy" and "fuzzywords" operators. For the 2 fuzzy operators this is the threshold value in range [1,100] where higher values require a tighter match (default:50)
 * @param  {Boolean} bIgnoreCase for string compares, defaults to true
 * @param  {Boolean} returnIndexes return indexes of rows instead of filtered table (default false)
 * @param  {Number} multiplValuesMode when provided multiple values for comparison, a row is feltered if all values validate the comparison (OR, default) or at least one value (AND):<|>0:OR (default)<|>1:AND
 * @param  {Boolean} onNames apply filter on lists names, returning a table with same number of rows, and selected columns
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable, santiago/examples/forIntroPanel/replacingElementsInTable
 */
TableOperators.filterTable = function(table, operator, value, listToCheck, value2, bIgnoreCase, returnIndexes, multiplValuesMode, onNames){

  // input validation and defaults
  if(table==null || table.length === 0 || table[0]==null) return;
  if(table.isList && !table.isTable){
    // we have a single list, wrap it and continue
    var temp = new Table();
    temp.push(table);
    table = temp;
  }
  if(onNames) return table.getElements(ListOperators.filterList(table.getNames(), operator, value, listToCheck, value2, true));
  if(operator === undefined && value === undefined) return returnIndexes?NumberListGenerators.createSortedNumberList(table[0].length):table;
  if(operator === '!contains' && (value === undefined || value === '') ) return returnIndexes?NumberListGenerators.createSortedNumberList(table[0].length):table;
  if(operator==null) operator='=c';
  if(operator == '=') operator = '==';
  operator = operator.toLowerCase();

  var nLKeep = new NumberList();
  var nRows = table.getLengths().getMax();
  var r,c,val,val0,bKeep,score;
  var cStart=0;
  var cEnd=table.length;
  var type = typeOf(value);
  var bExternalList = listToCheck != null && listToCheck.isList === true;

  var multipleValues = value!= null && value["isList"]==true && value.length>0;
  if(multipleValues){
    multiplValuesMode = multiplValuesMode==null?0:multiplValuesMode;
    var indexes;

    nLKeep = TableOperators.filterTable(table, operator, value[0], listToCheck, value2, bIgnoreCase, true);

    for(var i=1; i<value.length; i++){
      val = value[i];
      indexes = TableOperators.filterTable(table, operator, val, listToCheck, value2, bIgnoreCase, true);
      nLKeep = multiplValuesMode==1?ListOperators.intersection(nLKeep, indexes):ListOperators.union(nLKeep, indexes);
    }

    return returnIndexes?nLKeep:table.getRows(nLKeep);
  }




  if(bExternalList && listToCheck.length != nRows){
    throw new Error('selected List (in listToCheck position) must have same length as table');
  }

  if(listToCheck!=null){
    if(typeof listToCheck === 'string') listToCheck=table.getNames().indexOf(listToCheck);
    if(listToCheck==-1) throw new Error('do not find any list with such name');
  }

  if(value==null){
    type = 'Null';
    value = '';
  } else if(type == 'string' &&  (String(value)==String(Number(value))) && value.trim() !== ''){
    type='number';
    value=Number(value);
  } else if(type == 'boolean'){
    type='string';
    value=String(value);
  }

  if(operator == '=c'){
    if(type == 'string')
      operator = 'contains';
    else
      operator = '==';
  }
  if(type == 'number' && (operator == 'between' || operator == '!between') ){
    if(isNaN(value2))
      operator='noop';
    else {
      value2 = Number(value2);
    }
  }
  if( (operator == 'between' || operator == '!between')  && value2 == null)
    operator='noop';
  if( (operator == 'fuzzy' || operator == 'fuzzywords') && value2 == null)
    value2=50;
  if(listToCheck != null){
    cStart=listToCheck;
    cEnd=listToCheck+1;
  }
  if(bExternalList){
    cStart=0;
    cEnd=1;
  }

  if(bIgnoreCase == null || (bIgnoreCase !== true && bIgnoreCase !== false) )
    bIgnoreCase = true;
  if(type == 'string' && bIgnoreCase){
    value = value.toLowerCase();
    value2 = value2 ? String(value2).toLowerCase() : value2;
  }
  if(operator == '==' && bIgnoreCase)
    operator = '==i';
  if(operator == '!=' && bIgnoreCase)
    operator = '!=i';
  // row matching, not using RegExp because value can contain control characters

  //console.log("[fT] operator:", operator);
  //console.log("[fT] type:", type);

  var stringedValue = String(value);
  var stringedValue2 = value2==null?null:String(value2);

  var isString = type=='string';
  var isNumber = type=='number';

  var list;

  switch(operator){
    case "==":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          list = bExternalList ? listToCheck : table[c];
          if(list.type=="NumberList" && isString || list.type=="StringList" && isNumber) continue;
          val0 = list[r];
          if(val0 == null) val0 = '';
          if(val0 == value){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "==i":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          list = bExternalList ? listToCheck : table[c];
          if(list.type=="NumberList" && isString) continue;
          val = list[r]==null?'':list[r];
          val = list.type=="StringList"?val:String(val);
          //val0 = bExternalList ? listToCheck[r] : table[c][r];
          //val = val0 == null ? '' : String(val0).toLowerCase();
          val = bIgnoreCase?val.toLowerCase():val;
          if(val == value){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "!=":
      for(r=0; r<nRows; r++){
        bKeep=true;
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          if(val0 == null) val0 = '';
          if(val0 == value){
            bKeep=false;
            break;
          }
        }
        if(bKeep)
          nLKeep.push(r);
      }
      break;
    case "!=i":
      for(r=0; r<nRows; r++){
        bKeep=true;
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          val = val0 == null ? '' : String(val0).toLowerCase();
          if(val == value){
            bKeep=false;
            break;
          }
        }
        if(bKeep)
          nLKeep.push(r);
      }
      break;
    case "contains":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          list = bExternalList ? listToCheck : table[c];
          if(list.type=="NumberList" && isString) continue;
          val = list.type=="StringList"?list[r]:String(list[r]);
          //val0 = bExternalList ? (listToCheck.type=="StringList"?listToCheck[r]:String(listToCheck[r])) : (table[c].type=="StringList"?table[c][r]:String(table[c][r]));
          val = bIgnoreCase?val.toLowerCase():val;
          if(val.includes(stringedValue)){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "!contains":
      for(r=0; r<nRows; r++){
        bKeep=true;
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          if(val.includes(stringedValue)){
            bKeep=false;
            break;
          }
        }
        if(bKeep)
          nLKeep.push(r);
      }
      break;
    case "init":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          if(val.indexOf(stringedValue) === 0){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "<":
    case "<=":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          if(type != typeOf(val0)) continue;
          //val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          val =  (type == 'string')?( bIgnoreCase ? String(val0).toLowerCase() : String(val0) ):Number(val0);
          if(val < value){
            nLKeep.push(r);
            break;
          }
          else if(val == value && operator == '<='){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case ">":
    case ">=":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          if(type != typeOf(val0)) continue;
          val =  (type == 'string')?( bIgnoreCase ? String(val0).toLowerCase() : String(val0) ):Number(val0);
          if(val > value){
            nLKeep.push(r);
            break;
          }
          else if(val == value && operator == '>='){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "between":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          //if(type != typeOf(val0)) continue;

          //val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          val =  (type == 'string')?( bIgnoreCase ? String(val0).toLowerCase() : String(val0) ):Number(val0);

          if(type == 'number')
            val = Number(val);

          if(value <= val && val <= value2){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "!between":
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          //if(type != typeOf(val0)) continue;

          //val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          val =  (type == 'string')?( bIgnoreCase ? String(val0).toLowerCase() : String(val0) ):Number(val0);

          if(type == 'number')
            val = Number(val);

          if(value > val || val > value2){
            nLKeep.push(r);
            break;
          }
        }
      }
      break;
    case "unique":
      // this operator will keep only rows with unique combinations of values in the target lists
      var dict = {};
      for(r=0; r<nRows; r++){
        var sCheck='';
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          sCheck = sCheck + '|' + val;
        }
        if(dict[sCheck] == null){
          dict[sCheck] = true;
          nLKeep.push(r);
        }
      }
      break;
    case "fuzzy":
    case "fuzzywords":
      // same as fuzzy but split target strings into words first and take the best match
      value = String(value).trim();
      if(value == '')
        break;
      value2 = Number(value2);
      var tfuzzy = new NumberTable(2);
      var aVals, scoreMax, i0;
      for(r=0; r<nRows; r++){
        for(c=cStart; c<cEnd; c++){
          val0 = bExternalList ? listToCheck[r] : table[c][r];
          val = bIgnoreCase ? String(val0).toLowerCase() : String(val0);
          if(operator == 'fuzzywords')
            aVals = val.split(' ');
          else
            aVals = [val];
          scoreMax = 0;
          for(i0 = 0; i0 < aVals.length && scoreMax < value2; i0++){
            score = 100*(1-StringOperators.getLevenshteinDistance(aVals[i0],value,true));
            // for fuzzywords also consider a match on the start of the word of the same size as the pattern
            // penalize it a bit compared to a normal match by using 90 rather than 100
            // if(operator == 'fuzzywords' && aVals[i0].length > value.length)
              // score = Math.max(score, 90*(1-StringOperators.getLevenshteinDistance(aVals[i0].substring(0,value.length),value,true)));
            scoreMax = Math.max(scoreMax,score);
          }
          if(scoreMax >= value2){
            tfuzzy[0].push(r);
            tfuzzy[1].push(scoreMax);
            break;
          }
        }
      }
      tfuzzy = tfuzzy.getListsSortedByList(1,false,true);
      nLKeep = tfuzzy[0];
      break;
    default:
      if(typeof(operator) == 'function'){
        for(r=0; r<nRows; r++){
          for(c=cStart; c<cEnd; c++){
            if(operator.call(this,table[c][r], value, value2, listToCheck, table, c, r, bIgnoreCase) ){
              nLKeep.push(r);
              break;
            }
          }
        }
      }
  }

  if(returnIndexes) return nLKeep;

  var newTable = new Table();
  newTable.name = table.name;
  var len = table.length;

  for(c=0; c<len; c++){
    newTable.push(table[c].getElements(nLKeep,true)); // need second parm to handle null elements properly
  }
  return newTable.getImproved();
};

/**
 * filters columns from table according to qualities, ideal for selecting the columns on a Table that are interesting for certain types of analysis or visualization
 * @param  {Table} table Table.
 * 
 * @param  {Number} minMumberDifferentValues number of different elements(default: 0)
 * @param  {Number} maxProportionDifferentValues maximum proportion of different, between 0 and 1 (default:1)
 * @param  {Number} minEntropy minimum entropy, between 0 (single value) and 1 (more than one value, each equally represented), (default:0)
 * @param  {Number} maxEntropy maximum entropy, between 0 (single value) and 1 (more than one value, each equally represented), (default:1)
 * @param {Number} mode other filter criteria:<|>0:type numbers<|>1:type strings<|>2:kind categories<|>3:kind integers<|>4:without nulls<|>5:without blank cells (zero, one or two spaces)<|>6:numeric and categorical lists
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
TableOperators.filterColumns = function(table, minMumberDifferentValues, maxProportionDifferentValues, minEntropy, maxEntropy, mode){
  if(table==null) return;
  
  var newTable = new Table();

  var column, iO;

  for(var i=0; i<table.length; i++){
    column = table[i];
    iO = ListOperators.buildInformationObject(column, true);

    if(minMumberDifferentValues!=null && iO.numberDifferentElements<minMumberDifferentValues) continue;
    if(maxProportionDifferentValues!=null && iO.numberDifferentElements/column.length>maxProportionDifferentValues) continue;
    if(minEntropy!=null && iO.entropy<minEntropy) continue;
    if(maxEntropy!=null && iO.entropy>maxEntropy) continue;

    switch(mode){
      case 0://type numbers
        if(!iO.isNumeric) continue;
        break;
      case 1://type strings
        if(column.type!="StringList") continue;
        break;
      case 2://type numbers
        if(!iO.isCategorical) continue;
        break;
      case 3://type integers
        if(iO.kind != "integer numbers") continue;
        break;
      case 4://without nulls
        if(iO.containsNulls) continue;
        break;
      case 5://without blank cells
        if(column.includes("") || column.includes(" ") || column.includes("  ")) continue;
        break;
      case 6://without blank cells
        if(column.type!="NumberList" && !iO.isCategorical) continue;
        break;
    }

    newTable.push(column);
  }

  return newTable.getImproved();
};


/**
 * filters lists on a table, keeping elements that are in the same of row of a certain element of a given list from the table
 * @param  {Table} table Table.
 * @param  {Number} nList index of list containing the element
 * @param  {Object} element used to filter the lists on the table
 * @return {Table}
 * tags:filter
 */
TableOperators.getSubTableByElementOnList = function(table, nList, element){
  if(table==null || nList==null || element==null) return;

  var i, j;
  var nLists = table.length;

  if(nList<0) nList = nLists+nList;
  nList = nList%nLists;

  var newTable = instantiateWithSameType(table);
  newTable.name = table.name;

  for(i=0; i<nLists; i++){
    var newList = new List();
    newList.name = table[i].name;
    newTable.push(newList);
  }
  // table.forEach(function(list){
  //   var newList = new List();
  //   newList.name = list.name;
  //   newTable.push(newList);
  // });

  var supervised = table[nList];
  var nSupervised = supervised.length;
  var nElements;

  for(i=0; i<nSupervised; i++){
    if(element==supervised[i]){
      nElements = newTable.length;
       for(j=0; j<nElements; j++){
          newTable[j].push(table[j][i]);
       }
    }
  }

  nLists = newTable.length;

  for(i=0; i<nLists; i++){
    newTable[i] = newTable[i].getImproved();
  }
  // newTable.forEach(function(list, i){
  //   newTable[i] = list.getImproved();
  // });

  return newTable.getImproved();
};

/**
 * filters lists on a table, keeping elements that are in the same of row of certain elements of a given list from the table
 * @param  {Table} table Table.
 * @param  {Number} nList index of list containing the element
 * @param  {List} elements used to filter the lists on the table
 * @return {Table}
 * tags:filter
 */
TableOperators.getSubTableByElementsOnList = function(table, nList, list){
  if(table==null || nList==null || list==null) return;

  var i, j;

  if(nList<0) nList = table.length+nList;
  nList = nList%table.length;

  var newTable = instantiateWithSameType(table);
  newTable.name = table.name;

  table.forEach(function(list){
    var newList = new List();
    newList.name = list.name;
    newTable.push(newList);
  });

  var supervised = table[nList];

  var listDictionary = ListOperators.getBooleanDictionaryForList(list);

  for(i=0; supervised[i]!=null; i++){
    if(listDictionary[supervised[i]]){
       for(j=0; newTable[j]!=null; j++){
          newTable[j].push(table[j][i]);
       }
    }
  }

  newTable.forEach(function(list, i){
    newTable[i] = list.getImproved();
  });

  return newTable.getImproved();
};

/**
 * creates a Table by randomly sampling rows from the input table.
 * @param  {Table} input table
 *
 * @param  {Number} f fraction of rows to randomly select [0,1] (Default is .5)<br>If f > 1 then used as count of rows to return
 * @param  {Boolean} avoidRepetitions (Default is true)
 * @param  {Boolean} shuffle return rows in random positions (Default is false)
 * @param {Number} randomSeed optional seed for getting the same random rows every time
 * @return {Table}
 * tags:filter,sampling
 */
TableOperators.getRandomRows = function(table, f, avoidRepetitions, shuffle, randomSeed) {
  if(table == null || table[0] == null) return null;
  avoidRepetitions = avoidRepetitions == null ? true : avoidRepetitions;

  if(f == null) f=0.5;
  if(f < 0) return null;
  var nRows = table[0].length;
  var n;
  if(f <= 1)
    n=Math.round(f*nRows);
  else
    n=Math.round(f);
  var listIndexes = NumberListGenerators.createSortedNumberList(nRows, 0, 1);

  //if(shuffle) listIndexes = listIndexes.getSortedRandom();

  listIndexes = listIndexes.getRandomElements(n, avoidRepetitions, randomSeed);

  if(!shuffle) listIndexes = listIndexes.getSorted();

  return table.getSubListsByIndexes(listIndexes);
};

/**
 * transposes a table
 * @param  {Table} table to be transposed
 *
 * @param {Boolean} firstListAsHeaders removes first list of the table and uses it as names for the lists on the transposed table (default=false)
 * @param {Boolean} headersAsFirstList adds a new first list made from the headers of original table (default=false)
 * @return {Table}
 * tags:transform
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
TableOperators.transpose = function(table, firstListAsHeaders, headersAsFirstList) {
  if(table == null || !table.isTable) return null;
  return table.getTransposed(firstListAsHeaders, headersAsFirstList);
};


/**
 * replaces null values present in any of the lists of the table, and using different criteria for lists with nulls and numbers, and lists with nulls and other objects (typically strings)
 * @param {Table} table to be transformed
 * 
 * @param {Object} elementToBeRemoved
 * @param {Object} elementToBePlaced
 * @return {Table}
 * tags:transform
 */
TableOperators.replaceElementInTable = function(table, elementToBeRemoved, elementToBePlaced){
  if(table==null) return;

  var nLists = table.length;
  var l;
  var i, j;
  var list, newList;

  var newTable = new Table();

  for(i=0; i<nLists; i++){
    list = table[i];
    l = list.length;
    newList = new List();
    newTable[i] = newList;
    for(j=0; j<l; j++){
      newList[j] = list[j]==elementToBeRemoved?elementToBePlaced:list[j];
    }
    newList = newList.getImproved();
    newList.name = list.name;
  }

  return newTable.getImproved();

};

TableOperators.replaceCellInTable = function(table, element, column, row){
  if(table==null) return;
  if(typeof(column)=='string') column = table.getNames().indexOf(column);

  var newTable = new Table();

  for(i=0; i<table.length; i++){
    if(i==column){
      newTable[i] = table[i].clone();
      newTable[i][row] = element;
      newTable[i] = newTable[i].getImproved();
    } else {
      newTable[i] = table[i];
    }
  }

  return newTable.getImproved();

};

/**
 * replaces or inserts an entire section of a Table, a List, part of a List, or an element
 * @param {Table} table to be transformed
 *
 * @param {Object} elementListOrTable element, list or table to be inserted
 * @param {Number|String} column0 index or name of column where insertion starts (0 by default)
 * @param {Number|String} column1 index or name of column where insertion ends (last column by default)
 * @param {Number} row0 index of row where insertion starts (0 by default)
 * @param {Number} row1 index of row where insertion ends (last row by default)
 * @return {Table}
 * tags:transform
 * examples:santiago/examples/forIntroPanel/replacingElementsInTable
 */
TableOperators.replaceSectionInTable = function(table, elementListOrTable, column0, column1, row0, row1){
  if(table==null) return;

  var onlyColumn = column0!=null && column0==column1;
  var onlyRow = row0!=null && row0==row1;
  var names;
  var i, j;

  if(column0==null) column0 = 0;
  if(column1==null) column1 = table.length-1;
  if(row0==null) row0 = 0;
  if(row1==null) row1 = table[0].length-1;

  if(typeof(column0)=='string' || typeof(column1)=='string') names = table.getNames();

  if(typeof(column0)=='string') column0 = names.indexOf(column0);
  if(typeof(column1)=='string') column1 = names.indexOf(column1);

  if(onlyColumn && onlyRow){
    if(elementListOrTable["isList"]) elementListOrTable = elementListOrTable[0];
    if(elementListOrTable["isList"]) elementListOrTable = elementListOrTable[0];
    return TableOperators.replaceCellInTable(table, elementListOrTable, column0, row0);
  }

  if(onlyColumn){
    var newTable = new Table();

    if(elementListOrTable["isTable"]) elementListOrTable = elementListOrTable[0];

    for(i=0; i<table.length; i++){
      if(i==column0){
        newTable[i] = new mo.List();
        newTable[i].name = table[i].name;
        for(j=0; j<table[i].length; j++){
          if(j<row0 || j>row1){
            newTable[i][j] = table[i][j];
          } else {
            newTable[i][j] = elementListOrTable[j-row0];
          }
        }
        newTable[i] = newTable[i].getImproved();
      } else {
        newTable[i] = table[i];
      }
    }
    return newTable.getImproved();
  }

  var newTable;

  if(onlyRow){
    newTable = new Table();

    if(elementListOrTable["isTable"]) elementListOrTable = elementListOrTable.getRow(0);

    for(i=0; i<table.length; i++){
      if(i<column0 || i>column1){
        newTable[i] = table[i];
      } else {
        newTable[i] = table[i].clone();
        newTable[i][row0] = elementListOrTable[i-column0];
        newTable[i] = newTable[i].getImproved();
      }
    }
    return newTable.getImproved();
  }

  newTable = new Table();

  column1 = Math.min(column1, column0+elementListOrTable.length);
  row1 = Math.min(row1, row0+elementListOrTable[0].length);

  for(i=0; i<table.length; i++){
    if(i<column0 || i>column1){
      newTable[i] = table[i];
    } else {
      newTable[i] = new mo.List();
      newTable[i].name = table[i].name;
      for(j=0; j<table[i].length; j++){
        if(j<row0 || j>row1){
          newTable[i][j] = table[i][j];
        } else {
          newTable[i][j] = elementListOrTable[i-column0][j-row0];
        }
      }
      newTable[i] = newTable[i].getImproved();
    }
  }

  return newTable.getImproved();

};


/**
 * replaces null values present in any of the lists of the table, and using different criteria for lists with nulls and numbers, and lists with nulls and other objects (typically strings)
 * @param {Table} table to be transformed
 *
 * @param {Number} modeForNumbers replacement criteria when finding a null, or a sequence of nulls, between two numbers (it also accepts a list of modes, to apply a different criteria to each list, modes can be numbers or strings)<|>0:replace by provided number (default), "element"<|>1:by previous non-null element, "previous"<|>2:by next non-null element, "next"<|>3:average (if all non-null elements are numbers), "average"<|>4:local average, average of previous and next non-null values (if numbers), "local average"<|>5:interpolate numbers (if all non-null elements are numbers), "interpolate"<|>6:by most common number, "common"<|>7:by median (if all non-null elements are numbers), "median"
 * @param {Number} modeForNotnumbers when finding a null, or a sequence of nulls, between two strings<|>0:replace by provided element (default)<|>1:by previous non-null element<|>2:by next non-null element<|>3:by most common element (different to null)
 * @param {Object} number to be used to replace nulls in lists with nulls and numbers (default: 0)
 * @param {Object} element to be used to replace nulls in list with nulls and other non-numerical elements (default: "-")
 * @param {Object} nullElement optional value that will be interpreted as null, examples: NaN, NA, ""… (this value is the one that will be replaced)
 * @return {Table}
 * tags:transform,clean
 * examples:santiago/examples/forIntroPanel/replacingElementsInTable
 */
TableOperators.replaceNullsInTable = function(table, modeForNumbers, modeForNotnumbers, number, element, nullElement){
  if(table==null) return;

  if(number!=null) modeForNumbers = modeForNumbers==null?0:modeForNumbers;
  if(element!=null) modeForNotnumbers = modeForNotnumbers==null?0:modeForNotnumbers;
  number = (number==null && modeForNumbers!=null)?0:number;
  element = (element==null && modeForNotnumbers!=null)?"-":element;

  var nLists = table.length;
  var l;
  var i, j;
  var list, newList;
  var notNumeric;
  var containsNull;

  var modesList = (modeForNumbers!=null && modeForNumbers["length"] && modeForNumbers["isList"])?modeForNumbers:null;
  var elements = (element!=null && element["length"] && element["isList"])?element:null;

  var newTable = new Table();

  if(modesList){
    nLists = Math.min(nLists, modesList.length);
    var modesDictionary = {0:0,1:1,2:2,3:3,4:4,5:5,6:6,7:7,"element":0,"previous":1,"next":2,"average":3,"local average":4,"interpolate":5,"common":6,"median":7};
  }

  for(i=0; i<nLists; i++){
    list = table[i];
    l = list.length;
    notNumeric = false;
    containsNull = false;
    for(j=0; j<l; j++){
      if(list[j]==nullElement){
        containsNull=true;
      } else if(typeof list[j] != "number"){
        notNumeric = true;
        if(containsNull) break;
      }
    }

    if(containsNull){
      if(modesList){
        newList = ListOperators.replaceNullsInList(list, modesDictionary[modesList[i]], elements?elements[i%nLists]:element, nullElement);
      } else if(notNumeric){
        newList = ListOperators.replaceNullsInList(list, modeForNotnumbers==3?6:modeForNotnumbers, element, nullElement);
      } else {
        newList = ListOperators.replaceNullsInList(list, modeForNumbers, number, nullElement);
      }
      newTable[i] = newList;
    } else {
      newTable[i] = list;
    }
  }

  return newTable.getImproved();

};


/**
 * divides the instances of a table in two tables: the training table and the test table
 * @param  {Table} table
 *
 * @param  {Number} proportion fraction of training instances. The rest will be test instances, between 0 and 1 (default:0.5)
 * @param  {Boolean} bShuffle reorder the rows randomly within the two groups if true (default:true)
 * @param  {Number} seed seed for random numbers for consistent pseudo random invocations
 * @param  {List|Table} secondary is an optional table or list that will be split in the same fashion on the primary one; particularly useful for splitting the supervised variable into one sample for training and the other for comparing with predicted variables.
 * @param  {Boolean} bPartitionInOrder choose all the training items from beginning items if true(default:false)
 * @param  {Boolean} bPutExtremeOutliersInTraining place any rows with extreme outlier numeric values in the training set(default:true)
 * @return {List} list containing the two tables
 * @return {Table} training Table
 * @return {Table} test Table
 * @return {NumberList} indexes of training items
 * @return {NumberList} indexes of test items
 * @return {List} secondary training items
 * @return {List} secondary test items
 * tags:ds,machine-learning,advanced
 */
TableOperators.trainingTestPartition = function(table, proportion, bShuffle, seed, secondary, bPartitionInOrder, bPutExtremeOutliersInTraining) {
  if(table == null) return;

  if(table.isList && !table.isTable){
    // wrap it and continue
    var temp = new Table();
    temp.push(table);
    table = temp;
  }
  proportion = proportion == null ? 0.5 : proportion;
  bShuffle = bShuffle == null ? true : bShuffle;
  bPartitionInOrder = bPartitionInOrder == null ? false : bPartitionInOrder;
  bPutExtremeOutliersInTraining = bPutExtremeOutliersInTraining == null ? true : bPutExtremeOutliersInTraining;
  if(secondary != null && !secondary.isTable && !secondary.isList)
    throw new Error('Secondary input must be a table or list');

  var indexesTr = new NumberList();

  var nRows = table[0].length;
  var listIndexes = NumberListGenerators.createSortedNumberList(nRows, 0, 1);
  var n = Math.round(proportion*nRows);

  if(bPartitionInOrder){
    indexesTr = listIndexes.getSubList(0,n-1);
    if(bShuffle) indexesTr = indexesTr.getSortedRandom();
  }
  else{
    if(bPutExtremeOutliersInTraining){
      // it's better to have extreme outliers in the training set so the built model will see the full range of possibilities
      // First find the set of extreme outliers.
      var nLTemp,nLOutlierRows = new NumberList();
      for(var i=0; i < table.length; i++){
        // only look at numeric lists
        if(table[i].type != 'NumberList') continue;
        nLTemp = NumberListOperators.getOutliers(table[i],1,5);
        nLOutlierRows = nLOutlierRows.concat(nLTemp);
      }
      nLOutlierRows = nLOutlierRows.getWithoutRepetitions();
      var nLNonOutliers = ListOperators.difference(listIndexes,nLOutlierRows);
      indexesTr = nLOutlierRows.concat(nLNonOutliers.getRandomElements(n-nLOutlierRows.length,true,seed,false));
      if(bShuffle)
        indexesTr = indexesTr.getSortedRandom();
      else
        indexesTr = indexesTr.getSorted();
    }
    else
      indexesTr = listIndexes.getRandomElements(n, true, seed, !bShuffle);
  }
  var indexesTe = ListOperators.difference(listIndexes,indexesTr);
  if(bShuffle)
    indexesTe = indexesTe.getSortedRandom();

  indexesTr.name = 'Row Indexes of Training Items';
  indexesTe.name = 'Row Indexes of Test Items';
  var trainingT = table.getRows(indexesTr);
  var testT = table.getRows(indexesTe);


  var aRet = [
    {
      type: "List",
      name: "tables",
      description: "List with two Tables",
      value: new List(trainingT, testT)
    },
    {
      type: "Table",
      name: "trainingT",
      description: "training Table",
      value: trainingT
    },
    {
      type: "Table",
      name: "testT",
      description: "test Table",
      value: testT
    },
    {
      type: "NumberList",
      name: "nLTrainingIndexes",
      description: "Row Indexes of Training Items",
      value: indexesTr
    },
    {
      type: "NumberList",
      name: "nLTestIndexes",
      description: "Row Indexes of Test Items",
      value: indexesTe
    },
    {
      type: "List",
      name: "secondaryTrainingOutput",
      description: "Secondary Training Output",
      value: null
    },
    {
      type: "List",
      name: "secondaryTestOutput",
      description: "Secondary Test Output",
      value: null
    }
  ];
  if(secondary != null){
    if(secondary.isTable){
      aRet[5].type = 'Table';
      aRet[5].value = secondary.getRows(indexesTr);
      aRet[6].type = 'Table';
      aRet[6].value = secondary.getRows(indexesTe);
    }
    else{
      aRet[5].value = secondary.getElements(indexesTr);
      aRet[6].value = secondary.getElements(indexesTe);
    }
    aRet[0].value.push(aRet[5].value);
    aRet[0].value.push(aRet[6].value);
  }
  return aRet;
};

/**
 * tests a model
 * @param  {NumberTable} numberTable coordinates of points
 * @param  {List} classes list of values of classes
 * @param  {Function} model function that receives two numbers and returns a guessed class
 *
 * @param  {Number} metric 0:error
 * @return {Number} metric value
 * tags:ds,machine-learning
 */
TableOperators.testClassificationModel = function(numberTable, classes, model, metric) {
  if(numberTable == null || classes == null || model == null) return null;

  metric = metric || 0;

  var nErrors = 0;

  classes.forEach(function(clss, i) {
    if(model(numberTable[0][i], numberTable[1][i]) != clss) {
      nErrors++;
    }
  });

  return nErrors / classes.length;
};



/**
 * @todo finish docs
 */
TableOperators.getSubListsByIndexes = function(table, indexes) {
  var newTable = new Table();
  newTable.name = table.name;
  var list;
  var newList;
  for(var i = 0; table[i] != null; i++) {
    list = table[i];
    newList = instantiateWithSameType(list);
    newList.name = list.name;
    for(var j = 0; indexes[j] != null; j++) {
      newList[j] = list[indexes[j]];
    }
    newTable[i] = newList.getImproved();
  }
  return newTable;
};

// old version replaced by above version Dec 1st, 2014
// - fixed bug where descending with 'false' value gets changed to 'true'
// - performance improvements for tables with lots of lists
// TableOperators.sortListsByNumberList=function(table, numberList, descending){
//  descending = descending || true;
/**
 * @todo finish docs
 */
TableOperators.sortListsByNumberList = function(table, numberList, descending) {
  if(descending == null) descending = true;

  var newTable = instantiate(typeOf(table));
  newTable.name = table.name;
  var nElements = table.length;
  var i;
  // only need to do the sort once, not for each column
  var indexList = numberList.clone();
  // save original index
  for(i = 0; i < indexList.length; i++) {
    indexList[i] = i;
  }
  indexList = ListOperators.sortListByNumberList(indexList, numberList, descending);
  // now clone and then move from original based on index
  for(i = 0; i < nElements; i++) {
    newTable[i] = table[i].clone();
    for(var j = 0; j < indexList.length; j++) {
      newTable[i][j] = table[i][indexList[j]];
    }
  }
  return newTable;
};
 
/**
 * aggregates lists from a table, using one or several of the lists of the table as the aggregation lists, and based on different aggregation modes for each list to aggregate
 * @param  {Table} table containing the aggregation list and lists to be aggregated
 * @param  {Number|NumberList} indexAggregationList index (Number), indexes (NumberList) or aggregation list (List), of the aggregation list(s); these are the variables (normally just one) that will lead the aggregation, so there will be as many rows in the resulting Table as different values of this variables (or different combination of variables values, when providing more than one)<|0.name|>
 *
 * @param  {NumberList} indexesListsToAggregate indexes of the lists to be aggregated and included in the result Table; typically this list contains the index of the aggregation list at the beginning (or indexes of several lists), to be aggregated using mode 0 (first element), thus resulting as the list of non repeated elements (default: all indexes for all variables in order, so when no list is provided it aggregates all variables)
 * @param  {NumberList} modes list of modes of aggregation (this list should have same length as indexes of aggregated lists), these are the options:<br>0:first element (default)<br>1:count (default)<br>2:sum<br>3:average<br>4:min<br>5:max<br>6:standard deviation<br>7:enlist (creates a list of elements)<br>8:last element<br>9:most common element<br>10:random element<br>11:indexes<br>12:count non repeated elements<br>13:enlist non repeated elements<br>14:concat elements (for strings, uses ', ' as separator)<br>15:concat non-repeated elements<br>16:frequencies tables<br>17:concat (for strings, no separator)<br>18:average weighted by listWeight parameter<br>19:median<br>20:function (must be provided in last inlet)
 * @param  {StringList} newListsNames optional names for generated lists (this list should have same length as indexes of aggregated lists, and the modes list)
 * @param  {NumberList} weightList list of numbers used for weighted average calculations (only for mode 18)
 * @param  {NumberList} aggregationModesForReminaingLists three numbers or names that indicate the aggregation mode to be used in remaining lists (all the lists of the Table not indicated in the indexes of aggregation), each mode for numeric-categorical, numeric (non categorical) and categorical (non numeric), respectively
 * @param  {Function} aggregationFunction aggregation function (mode 20)
 * @return {Table} aggregated table
 * tags:transform,filter
 * examples:santiago/examples/modules/aggregateTable,santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
TableOperators.aggregateTable = function(table, indexAggregationList, indexesListsToAggregate, modes, newListsNames, weightList, aggregationModesForReminaingLists, aggregationFunction){

  indexAggregationList = indexAggregationList||0;

  if(table==null) return;

  if(indexesListsToAggregate==null) indexesListsToAggregate = NumberListGenerators.createSortedNumberList( table.length);
  if(modes==null) modes = ListGenerators.createListWithSameElement( table.length, 9 );

  if(indexesListsToAggregate.type=='StringList') indexesListsToAggregate = table.getNames().indexesOfElements(indexesListsToAggregate);
  if(indexesListsToAggregate.type != 'NumberList' && Array.isArray(indexesListsToAggregate)) indexesListsToAggregate = NumberList.fromArray(indexesListsToAggregate);
  if(typeof(indexAggregationList)=='string') indexAggregationList = table.getNames().indexOf(indexAggregationList);

  if(!table.length ||  table.length<indexAggregationList || indexesListsToAggregate==null || !indexesListsToAggregate.length || modes==null) return;

  //remaining lists
  if(aggregationModesForReminaingLists!=null && aggregationModesForReminaingLists.length==3){
    var infoOb;
    indexesListsToAggregate = indexesListsToAggregate.clone();
    modes = modes.clone();
    for(var i=0; i<table.length; i++){
      if(!indexesListsToAggregate.includes(i)){
        indexesListsToAggregate.push(i);
        infoOb = ListOperators.buildInformationObject(table[i]);
        if(infoOb.isNumericCategorical){
          modes.push(aggregationModesForReminaingLists[0]);
        } else if(infoOb.isNumeric){
          modes.push(aggregationModesForReminaingLists[1]);
        } else {
          modes.push(aggregationModesForReminaingLists[2]);
        }
      }
    }
  }

  if(indexAggregationList["length"]!=null){

    if(indexAggregationList.length==table[0].length){
      aggregatorList = indexAggregationList;
    } else if(indexAggregationList.length==0){
      indexAggregationList = indexAggregationList[0];
    } else {//multiple aggregation
      var toAggregate = table.getColumns(indexAggregationList);
      var typesToAggregate = toAggregate.getTypes();

      var text;
      var j;
      var textsList = new StringList();
      var JOIN_CHARS = "*__*";
      l = toAggregate[0].length;
      for(i=0; i<l; i++){
        text = toAggregate[0][i];
        for(j=1; j<toAggregate.length; j++){
          text+=JOIN_CHARS+toAggregate[j][i];
        }
        textsList[i] = text;
      }

      

      /** new idea:

      don't remove indexAggregationList, just put textsList at the beginning
      as well as a new index in newIndexesListsToAggregate (0)
      and a new mode (0),
      and a new name ("")
      */
     
     //unshift?
     newTable = new Table.fromArray( [textsList].concat(table.getElements(indexesListsToAggregate)) );

     var newIndexesListsToAggregate = new NumberList();
      var newModes = new NumberList();
      newIndexesListsToAggregate[0] = 0;
      newModes[0] = 0;
      if(newListsNames!=null){
        newListsNames = newListsNames.clone();
        newListsNames.unshift("_");
      }

      for(var i=0; i<indexesListsToAggregate.length; i++){
        newIndexesListsToAggregate.push(indexesListsToAggregate[i]+1);
        newModes.push(modes[i]);
      }

      var indexes = mo.NumberListGenerators.createSortedNumberList(newTable.length-1, 1);

      newTable = TableOperators.aggregateTable(newTable, 0, indexes, newModes, newListsNames);

      return newTable;

      /*
      var aggregationTable = new Table();
      var parts;

      for(j=0; j<indexAggregationList.length; j++){
          aggregationTable[j] = instantiate(typesToAggregate[j]);
          aggregationTable[j].name = toAggregate[j].name;
      }

      l = newTable[0].length;

      for(i=0; i<l; i++){
        text = newTable[0][i];
        parts = text.split(JOIN_CHARS);
        for(j=0; j<indexAggregationList.length; j++){

          aggregationTable[j][i] = typesToAggregate[j]=="NumberList"?Number(parts[j]):parts[j];

        }
      }

      return Table.fromArray(aggregationTable.concat(newTable.getSubList(1))).getImproved();
      */

     ////////end new idea

     //---> fix this
     //
     /*
      newTable = new Table.fromArray( [textsList].concat(table.getElements(indexesListsToAggregate.getWithoutElements(indexAggregationList))) );


      var newIndexesListsToAggregate = new NumberList();
      var newModes = new NumberList();
      newIndexesListsToAggregate[0] = 0;
      newModes[0] = 0;
      

      for(i=indexAggregationList.length; i<indexesListsToAggregate.length; i++){
        newIndexesListsToAggregate.push(i+1);
        newModes.push(modes[i]);
      }

      newTable = TableOperators.aggregateTable(newTable, 0, newIndexesListsToAggregate, newModes, newListsNames);

      return newTable;
      */

      /*
      var aggregationTable = new Table();
      var parts;

      for(j=0; j<indexAggregationList.length; j++){
          aggregationTable[j] = instantiate(typesToAggregate[j]);
          aggregationTable[j].name = toAggregate[j].name;
      }

      l = newTable[0].length;

      for(i=0; i<l; i++){
        text = newTable[0][i];
        parts = text.split(JOIN_CHARS);
        for(j=0; j<indexAggregationList.length; j++){

          aggregationTable[j][i] = typesToAggregate[j]=="NumberList"?Number(parts[j]):parts[j];

        }
      }

      return Table.fromArray(aggregationTable.concat(newTable.getSubList(1))).getImproved();
      */
    }
  }



  var aggregatorList = aggregatorList==null?table[indexAggregationList]:aggregatorList;
  var indexesTable = ListOperators.getIndexesTable(aggregatorList);
  var newTable = new Table();
  var newList;
  var toAggregateList;
  var i, index;
  var l = indexesListsToAggregate.length;

  //indexesListsToAggregate.forEach(function(index, i){
  for(i=0; i<l; i++){
    index = indexesListsToAggregate[i];
    toAggregateList = table[index];
    newList = ListOperators.aggregateList(aggregatorList, toAggregateList, i<modes.length?modes[i]:1, indexesTable, weightList, aggregationFunction)[1];
    if(newListsNames && i<newListsNames.length){
      newList.name = newListsNames[i];
    } else {
      newList.name = toAggregateList.name;
    }
    newTable.push(newList);
  }
  //});

  return newTable.getImproved();
};

/**
 * dis-aggregates a list from a Table that has lists whose elements could be expanded into lists (["a","b,c,d"] will turn into [ ["a,b"], ["a","c"], ["a", "d"] ]).
 * @param  {Table} table containing the list to be expanded and lists with elemnts to be dis-aggregated
 * @param  {Number|String|NumberList|StringList} indexExpansion index (Number), name (String), indexes (NumberList) or names (StringList) of list(s) to expand, whoe elements will be repeated
 * @param  {Number|String} indexToSplit index of the list with elements to be split, this list contains elements separated by chomas
 * @return {Table} dis-aggregated table
 * tags:advanced
 */
TableOperators.disAggregateTable = function(table, indexesExpansion, indexToSplit){
  if(table==null || indexesExpansion==null || indexToSplit==null) return;

  var lists = table.getColumns(indexesExpansion);
  var toSplit = table.getColumn(indexToSplit);

  var split = new mo.List();
  var newTable = new mo.Table();

  for(var i=0; i<lists.length; i++){
    newTable[i] = instantiateWithSameType(lists[i]);
  }
  newTable.push(split);

  var parts;

  for(i=0; i<lists[0].length; i++){
    parts = StringList.fromArray(String(toSplit[i]).split(",")).trim();
    for(var j=0; j<parts.length; j++){
      for(var k=0; k<lists.length; k++){
        newTable[k].push(lists[k][i]);
      }
      split.push(String(Number(parts[j]))==parts[j]?Number(parts[j]):parts[j]);
    }
  }

  split = split.getImproved();

  return newTable;
};

/**
 * expands a table that describes segments
 * @param  {Table} table Table.
 * @param  {NumberList} weights per combination
 * @param  {number} expansionFactor
 * @return {Table}
 * tags:advanced,transform,generator
 */
TableOperators.deSegmentTable = function(table, weights, expansionFactor) {
    expansionFactor = expansionFactor==null?10:expansionFactor;
    
    var repetitions = mo.NumberListOperators.normalizeToSum(weights, expansionFactor*weights.length);
    var newTable = new Table();
    
    
    for(var i=0; i<table.length; i++){
        newTable[i] = instantiateWithSameType(table[i]);
        newTable[i].name = table[i].name;
    }
    
    var weightPerItem = new NumberList();
    newTable.push(weightPerItem);
    weightPerItem.name = "weight per item";
    
    var count = new mo.NumberList();
    newTable.push(count);
    count.name = "count repetitions segment";
    var n, w;
    
    for(var j=0;j<repetitions.length; j++){
        n = Math.round(repetitions[j]);
        w = weights[j];
        for(var k=0; k<n; k++){
            for(i=0; i<table.length; i++){
                newTable[i].push(table[i][j]);
            }
            weightPerItem.push(w/n);
            count.push(n);
        }
    }
    
    return newTable;
};


/**
 * (also known as long to wide) from two columns of repeated elements, creates a table in which the first list has no repeated elements and there's a column for each value of the second list; a third list is used to take elements, count, add… 
 * @param  {table} table
 * @param  {String|Number|List} firstAggregationList index of first list, this list will be aggregated, each elemnte appearing once<|0.name|>
 * @param  {String|Number|List} secondAggregationList index of second list, there will be a column for each value of this list<|0.name|>
 * @param  {String|Number|List} listToAggregate index of list to be aggregated, according to selected aggregation mode<|0.name|>
 * 
 * @param  {Number} aggregationMode aggregation mode:<|>0:first element<|>1:count (default)<|>2:sum<|>3:average<|>4:min value<|>5:max value
 * @param {Number} fillMode fill gaps with following options:<|>0:null (default)<|>1:0<|>2:provided value (fillValue inlet below)<|>3:interpolate values (for numeric columns)
 * @param  {Number} resultMode result mode:<br>0:classic pivot, a table of aggregations with first aggregation list elements without repetitions in the first list, and second aggregation elements as headers of the aggregation lists<br>1:two lists for combinations of first aggregated list and second aggregated list, and a third list for aggregated values(default)
 * @param  {Number} sortingMode sorts aggregated columns<|>0: no sorting applied (default)<|>1:sort by columns names
 * @param {Object} fillValue optional value to fill gaps (when fillMode is 2)
 * @return {Table}
 * tags:transform
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
TableOperators.pivotTable = function(table, firstAggregationList, secondAggregationList, listToAggregate, aggregationMode, fillMode, resultMode, sortingMode, fillValue){
  if(table==null || !table.length || firstAggregationList==null || secondAggregationList==null || listToAggregate==null) return;

 var listFirstAggregation = firstAggregationList["isList"]?firstAggregationList:table.getColumn(firstAggregationList);
 var listSecondAggregation = secondAggregationList["isList"]?secondAggregationList:table.getColumn(secondAggregationList);
 listToAggregate = listToAggregate["isList"]?listToAggregate:table.getColumn(listToAggregate);

  /*
  if(typeof(firstAggregationList)=='string') firstAggregationList = table.getNames().indexOf(firstAggregationList);
  if(typeof(secondAggregationList)=='string') secondAggregationList = table.getNames().indexOf(secondAggregationList);
  if(typeof(listToAggregate)=='string') listToAggregate = table.getNames().indexOf(listToAggregate);
  */

  aggregationMode = aggregationMode==null?1:aggregationMode;
  //aggregations modes to add:
  //<|>4:min<|>5:max<|>6:standard deviation<|>7:enlist (creates a list of elements)<|>8:last element<|>9:most common element<|>10:random element<|>11:indexes

  resultMode = resultMode||0;
  //nullValue = nullValue==null?"":nullValue;

  switch(fillMode){
    case 0:
    case 3:
      fillValue = null;
      break;
    case 1:
      fillValue = 0;
      break;
  }

  var element1;
  var coordinate, indexes;
  //var listToAggregate = table[listToAggregate];

  var newTable = new Table();
  var sum;
  var i;

  if(resultMode==1){//two lists of elements and a list of aggregation value
    var indexesDictionary = {};
    var elementsDictionary = {};

    //table[firstAggregationList].forEach(function(element0, i){//@todo: imporve this
    listFirstAggregation.forEach(function(element0, i){
      //element1 = table[secondAggregationList][i];
      element1 = listSecondAggregation[i];
      coordinate = String(element0)+"∞"+String(element1);
      if(indexesDictionary[coordinate]==null){
        indexesDictionary[coordinate]=new NumberList();
        elementsDictionary[coordinate]=new List();
      }
      indexesDictionary[coordinate].push(i);
      elementsDictionary[coordinate].push(listToAggregate[i]);
    });

    newTable[0] = new List();
    newTable[1] = new List();
    switch(aggregationMode){
      case 0://first element
        newTable[2] = new List();
        break;
      case 1://count
      case 2://sum
      case 3://average
        newTable[2] = new NumberList();
        break;
    }


    for(coordinate in indexesDictionary) {
      indexes = indexesDictionary[coordinate];
      //newTable[0].push(table[firstAggregationList][indexes[0]]);
      //newTable[1].push(table[secondAggregationList][indexes[0]]);
      newTable[0].push(listFirstAggregation[indexes[0]]);
      newTable[1].push(listSecondAggregation[indexes[0]]);

      switch(aggregationMode){
        case 0://first element
          newTable[2].push(listToAggregate[indexes[0]]);
          break;
        case 1://count
          newTable[2].push(indexes.length);
          break;
        case 2://sum
        case 3://average
          sum = 0;
          indexes.forEach(function(index){
            sum+=listToAggregate[index];
          });
          if(aggregationMode==3) sum/=indexes.length;
          newTable[2].push(sum);
          break;
      }
    }

    newTable[0] = newTable[0].getImproved();
    newTable[1] = newTable[1].getImproved();

    switch(aggregationMode){
      case 0://first element
        newTable[2] = newTable[2].getImproved();
        break;
    }

    //newTable[0].name = table[firstAggregationList].name;
    newTable[0].name = listFirstAggregation.name;

    return newTable;
  }

  ////////////////////////resultMode==0, a table whose first list is the first aggregation list, and each i+i list is the aggregations with elements for the second aggregation list

  newTable[0] = new List();

  var elementsPositions0 = {};
  var elementsPositions1 = {};

  var x, y;
  var element;
  var newList;

  //table[firstAggregationList].forEach(function(element0, i){
  listFirstAggregation.forEach(function(element0, i){
    //element1 = table[secondAggregationList][i];
    element1 = listSecondAggregation[i];
    element = listToAggregate[i];

    y = elementsPositions0[String(element0)];
    if(y==null){
      newTable[0].push(element0);
      y = newTable[0].length-1;
      elementsPositions0[String(element0)] = y;
    }

    x = elementsPositions1[String(element1)];
    if(x==null){
      switch(aggregationMode){
        case 0:
          newList = new List();
          break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          newList = new List();
          break;
      }
      newTable.push(newList);
      newList.name = String(element1);
      x = newTable.length-1;
      elementsPositions1[String(element1)] = x;
    }




    switch(aggregationMode){
      case 0://first element
        if(newTable[x][y]==null) newTable[x][y]=element;
        break;
      case 1://count
        if(newTable[x][y]==null) newTable[x][y]=0;
        newTable[x][y]++;
        break;
      case 2://sum
        if(newTable[x][y]==null) newTable[x][y]=0;
        newTable[x][y]+=element;
        break;
      case 3://average
        if(newTable[x][y]==null) newTable[x][y]=[0,0];
        newTable[x][y][0]+=element;
        newTable[x][y][1]++;
        break;
      case 4://min
        if(newTable[x][y]==null) newTable[x][y]=element;
        newTable[x][y]=Math.min(element, newTable[x][y]);
        break;
      case 5://max
        if(newTable[x][y]==null) newTable[x][y]=element;
        newTable[x][y]=Math.max(element, newTable[x][y]);
        break;

        /*
      case 6://most common
      case 7://median
      case 8://standard deviation
      case 9://count non repeated
        if(newTable[x][y]==null) newTable[x][y]=new list();
        newTable[x][y].push(element);
        break;
        */

    }

  });

  var needsInterpolation = false;

  switch(aggregationMode){
    case 0://first element
      for(i=1; i<newTable.length; i++){
        if(newTable[i]==null) newTable[i]=new List();

        newTable[0].forEach(function(val, j){
          if(newTable[i][j]==null) newTable[i][j]=fillValue;
        });

        newTable[i] = newTable[i].getImproved();
        if(newTable[i].type=="List") needsInterpolation=true;
      }
      break;
    case 1://count
    case 2://sum
    case 4://min
    case 5://max
      for(i=1; i<newTable.length; i++){
        if(newTable[i]==null) newTable[i]=new List();
        newTable[0].forEach(function(val, j){
          if(newTable[i][j]==null) newTable[i][j]=fillValue;
        });

        newTable[i] = newTable[i].getImproved();
        if(newTable[i].type=="List") needsInterpolation=true;
      }
      break;
    case 3://average
      for(i=1; i<newTable.length; i++){
        if(newTable[i]==null) newTable[i]=new List();
        newTable[0].forEach(function(val, j){
          if(newTable[i][j]==null){
            newTable[i][j]=fillValue;
          } else {
            newTable[i][j]=newTable[i][j][0]/newTable[i][j][1];
          }
        });
        newTable[i] = newTable[i].getImproved();
        if(newTable[i].type=="List") needsInterpolation=true;
      }

      /*
    case 6://most common
      case 7://median
      case 8://standard deviation
      case 9://count non repeated
      break;
      */
  }

  if(fillMode && needsInterpolation){
    newTable = TableOperators.replaceNullsInTable(newTable, 5);
  }

  if(sortingMode==1){
      var toSort = newTable.slice(1);
      var names = newTable.getNames().getSubList(1);
      //if(table[secondAggregationList].type == "NumberList") names = names.toNumberList();
      if(listSecondAggregation.type == "NumberList") names = names.toNumberList();
      
      toSort = List.fromArray(toSort).getSortedByList(names);
      var newTable2 = new Table();
      newTable2[0] = newTable[0];
      newTable = newTable2.concat(toSort);
      return newTable;
  }

  newTable[0] = newTable[0].getImproved();
  return newTable.getImproved();
};

/**
 * unpivots a table, creating a column of entities, a column of variables names and a column of values. Each combination of entity and variables will have its own row, along with its value
 * @param  {Table} table with at least two lists. (This parameter becomes optional when passing a List of entities and a Table of variables, with all Lists of same size)
 * @param  {Number|String|List} entitiesColumn name or position of List in the Table, or, alternatively an independent List. These are the entities.
 * @param  {NumberList|StringList|Table} variablesColumns names or positions of variables in the Table, or, alternatively an independent Tabe with variables.
 * @return {Table}
 * tags:transform
 */
TableOperators.unpivotTable = function(table, entitiesColumn, variablesColumns) {
    var entities = table.getColumn(entitiesColumn);
    var variables = table.getColumns(variablesColumns);
    var entity;

    var newT = new mo.Table();
    newT[0] = new List();
    newT[0].name = entities.name;
    newT[1] = new mo.StringList();
    newT[1].name = "variables";
    newT[2] = new mo.List();
    newT[2].name = "value";
    
    for(var i=0; i<entities.length; i++){
        entity = entities[i];
        for(var j=0; j<variables.length; j++){
            newT[0].push(entity);
            newT[1].push(variables[j].name);
            newT[2].push(variables[j][i]);
        }
    }
    
    newT[0] = newT[0].getImproved();
    newT[2] = newT[2].getImproved();
    
    return newT.getImproved();
};

/**
 * counts pairs of elements in same positions in two lists (the result is the adjacent matrix of the network defined by pairs)
 * @param  {Table} table with at least two lists
 * @param  {Boolean} bListsFromSameDomain if true force square matrix (default: false)
 * @param  {Number} iValues 0: simple frequency (default)<br>1: Global Association Distance<br>2: Relative Association Distance<br>3: row proportions
 * @param  {Number} iOrder 0: Occurrence order (default)<br>1: Frequency order<br>2: Alphabetical order
 * @param  {Number} maxItems (default: no limit)
 * @return {Table}
 * tags:count,statistics,advanced
 */
TableOperators.getCountPairsMatrix = function(table, bListsFromSameDomain,iValues,iOrder,maxItems) {
  if(table == null || table.length < 2 || table[0] == null || table[0][0] == null) return null;
  bListsFromSameDomain = bListsFromSameDomain == null ? false : bListsFromSameDomain;
  iValues = iValues == null ? 0 : iValues;
  iOrder = iOrder == null ? 0 : iOrder;

  var list0,list1;
  if(iOrder == 0){
    list0 = table[0].getWithoutRepetitions();
    list1 = table[1].getWithoutRepetitions();
    if(bListsFromSameDomain){
      // use the same set of list values in both dimensions
      list0 = list0.concat(list1);
      list0 = list0.getWithoutRepetitions();
      list1 = list0;
    }
  }
  else if(iOrder == 1){
    if(bListsFromSameDomain){
      list0 = table[0].concat(table[1]);
      var tf0 = list0.getFrequenciesTable(true);
      list1 = list0 = tf0[0];
    }
    else{
      var tf0 = table[0].getFrequenciesTable(true);
      list0 = tf0[0];
      tf0 = table[1].getFrequenciesTable(true);
      list1 = tf0[0];
    }
  }
  else if(iOrder == 2){
    if(bListsFromSameDomain){
      list0 = table[0].concat(table[1]);
      var tf0 = list0.getFrequenciesTable(true);
      tf0 = tf0.getListsSortedByList(0,true);
      list1 = list0 = tf0[0];
    }
    else{
      var tf0 = table[0].getFrequenciesTable(true);
      tf0 = tf0.getListsSortedByList(0,true);
      list0 = tf0[0];
      tf0 = table[1].getFrequenciesTable(true);
      tf0 = tf0.getListsSortedByList(0,true);
      list1 = tf0[0];
    }
  }
  if(maxItems != null && !isNaN(maxItems) && maxItems > 0){
    var nLKeep = NumberListGenerators.createSortedNumberList(maxItems);
    if(list0.length > maxItems)
      list0 = list0.getElements(nLKeep);
    if(list1.length > maxItems)
      list1 = list1.getElements(nLKeep);
  }

  var matrix = new NumberTable(list1.length);

  list1.forEach(function(element1, i) {
    matrix[i].name = String(element1);
    list0.forEach(function(element0, j) {
      matrix[i][j] = 0;
    });
  });

  var i0,i1;
  table[0].forEach(function(element0, i) {
    var element1 = table[1][i];
    i1=list1.indexOf(element1);
    i0=list0.indexOf(element0);
    if(i0 != -1 && i1 != -1)
      matrix[i1][i0]++;
  });
  if(iValues != 0){
    var maxOverall = 0;
    for(var i=0;i<matrix.length;i++){
      matrix[i]._max = matrix[i].getMax();
      maxOverall = Math.max(maxOverall,matrix[i]._max);
    }
    if(iValues == 1 && maxOverall != 0){
      var d0;
      // largest freq maps to dist=0, others larger
      for(var i=0;i<matrix.length;i++){
        for(var j=0;j<matrix[i].length;j++){
          d0 = (maxOverall - matrix[i][j])/maxOverall;
          matrix[i][j] = Number(d0.toFixed(5));
        }
      }
    }
    else if(iValues == 2 && maxOverall != 0){
      var d0;
      // largest freq in column maps to dist=0, others larger
      for(var i=0;i<matrix.length;i++){
        for(var j=0;matrix[i]._max > 0 && j<matrix[i].length;j++){
          d0 = (matrix[i]._max - matrix[i][j])/matrix[i]._max;
          matrix[i][j] = Number(d0.toFixed(5));
        }
      }
    }
    else if(iValues == 3){
      var rowsum;
      // sum row vals, convert to fraction of total in row
      for(var j=0;j<matrix[0].length;j++){
        rowsum=0;
        for(var i=0;i<matrix.length;i++)
          rowsum+=matrix[i][j];
        for(i=0;rowsum != 0 && i<matrix.length;i++){
          d0 = matrix[i][j]/rowsum;
          matrix[i][j] = Number(d0.toFixed(5));
        }
      }
    }
  }

  // add the list of actual strings so we can tell what the rows correspond to
  var tw = new Table();
  list0.name = table[0].name;
  tw.push(list0);
  matrix = tw.concat(matrix);
  return matrix;
};

/**
 * counts pairs of elements in same positions in two lists returning a table with pair frequencies
 * @param  {Table} table with at least two lists
 * @return {Table}
 * tags:count
 */
TableOperators.getPairsFrequencyTable = function(table) {
  if(table == null || table.length < 2 || table[0] == null || table[0][0] == null || table[0].length != table[1].length) return null;

  var dict={};
  for(var i=0;i<table[0].length;i++){
    var o = dict[table[0][i]];
    if(o == null){
      o = {sL: new List(), c:0, s:table[0][i]};
      dict[table[0][i]] = o;
    }
    o.sL.push(table[1][i]);
    o.c++;
  }

  var aObjs = [];
  for(o in dict)
    aObjs.push(dict[o]);
  aObjs = aObjs.sort(function(a,b){
    var d=b.c - a.c;
    if(d!=0) return d;
    return a.s < b.s ? 1 : -1;
  });

  var t = new Table();
  t[0] = instantiateWithSameType(table[0]);
  t[0].name = table[0].name == '' ? 'List 1' : table[0].name;
  t[1] = instantiateWithSameType(table[1]);
  t[1].name = table[1].name == '' ? 'List 2' : table[1].name;
  t[2] = new NumberList();
  t[2].name = 'Combination Frequency';

  var tf;
  for(i = 0; i < aObjs.length; i++){
    o=aObjs[i];
    tf = o.sL.getFrequenciesTable(true);
    for(var j=0; j < tf[0].length; j++){
      t[0].push(o.s);
      t[1].push(tf[0][j]);
      t[2].push(tf[1][j]);
    }
  }

  return t;
};

/**
 * Concatenate row elements of the table to yield a StringList with one item per row
 * @param  {Table} table The table to use
 *
 * @param  {String} separator to place between elements (default:space)
 * @return {StringList}
 * tags:combine
 */
TableOperators.concatenateRowElements = function(table, separator) {
  if(table == null || !table.isTable) return null;
  separator = separator == null ? ' ' : separator;
  var sL = new mo.StringList();
  var s,row,col,maxRows = table.getLengths().getMax();
  var bAllEmpty = true;
  for(row = 0; row < maxRows; row++){
    s = '';
    for(col = 0; col < table.length; col++){
      if(col>0) s += separator;
      if(table[col][row] === undefined) continue;
      s += String(table[col][row]);
      if(row == 0){
        if(col > 0)
          sL.name += separator;
        sL.name += String(table[col].name);
        bAllEmpty = bAllEmpty && String(table[col].name).length == 0;
      }
    }
    sL.push(s);
  }
  // if all table list names are blank keep the final result blank instead of a bunch of separators jammed together
  if(bAllEmpty) sL.name = '';
  return sL;
};

/**
 * filter a table selecting rows that have an element on one of its lists (filterTable is more recommended)
 * @param  {Table} table
 * @param  {Number|String} nList list index or name that could contain the element in several positions
 * @param  {Object} element
 *
 * @param {Boolean} keepRowIfElementIsPresent if true (default value) the row is selected if the list contains the given element, if false the row is discarded
 * @return {Table}
 * tags:filter
 */
TableOperators.filterTableByElementInList = function(table, nList, element, keepRowIfElementIsPresent) {
  if(table == null ||  table.length <= 0 || nList == null) return;
  if(element == null) return table;

  keepRowIfElementIsPresent = keepRowIfElementIsPresent==null?true:keepRowIfElementIsPresent;

  if(typeof nList == 'string'){
    var iList = table.getNames().indexOf(nList);
    if(iList == -1)
      throw new Error("The table doesn't contain a list with name " + nList);
    nList = iList;
  }

  var newTable = new Table();
  var i, j;
  var l = table.length;
  var l0 = table[0].length;

  newTable.name = table.name;

  for(j = 0; j<l; j++) {
    newTable[j] = instantiateWithSameType(table[j]);
    newTable[j].name = table[j].name;
  }

  if(keepRowIfElementIsPresent){
    for(i = 0; i<l0; i++) {
      if(table[nList][i] == element) {
        for(j = 0; j<l; j++) {
          newTable[j].push(table[j][i]);
        }
      }
    }
  } else {
    for(i = 0; i<l0; i++) {
      if(table[nList][i] != element) {
        for(j = 0; j<l; j++) {
          newTable[j].push(table[j][i]);
        }
      }
    }
  }

  for(j = 0; j<l; j++) {
    // subset of a NumberList is always a NumberList, much faster to skip for large NumberLists
    if(newTable[j].type != 'NumberList')
      newTable[j] = newTable[j].getImproved();
  }

  return newTable;
};

/**
 * filter a table selecting rows that have one of the elements provided on one of its lists (filterTable is more recommended)
 * @param  {Table} table
 * @param  {Number|String} nList list index or name that could contain some of the elements in several positions
 * @param  {List} elements to be used for comparison
 *
 * @param {Boolean} keepRowIfElementIsPresent if true (default value) the row is selected if the list contains the given element, if false the row is discarded
 * @return {Table}
 * tags:filter
 */
TableOperators.filterTableByElementsInList = function(table, nList, elements, keepRowIfElementIsPresent) {
  if(table == null ||  table.length <= 0 || nList == null) return;
  if(elements == null || elements.length===0) return table;

  keepRowIfElementIsPresent = keepRowIfElementIsPresent==null?true:keepRowIfElementIsPresent;

  if(typeof nList == 'string'){
    var iList = table.getNames().indexOf(nList);
    if(iList == -1)
      throw new Error("The table doesn't contain a list with name " + nList);
    nList = iList;
  }

  var elementsDictionary = ListOperators.getBooleanDictionaryForList(elements);

  var newTable = new Table();
  var i, j;
  var l = table.length;
  var l0 = table[0].length;

  newTable.name = table.name;

  for(j = 0; j<l; j++) {
    newTable[j] = new List();
    newTable[j].name = table[j].name;
  }

  if(keepRowIfElementIsPresent){
    for(i = 0; i<l0; i++) {
      if(elementsDictionary[ table[nList][i] ]) {
        for(j = 0; j<l; j++) {
          newTable[j].push(table[j][i]);
        }
      }
    }
  } else {
    for(i = 0; i<l0; i++) {
      if(!elementsDictionary[ table[nList][i] ]) {
        for(j = 0; j<l; j++) {
          newTable[j].push(table[j][i]);
        }
      }
    }
  }

  for(j = 0; j<l; j++) {
    newTable[j] = newTable[j].getImproved();
  }

  return newTable;
};

/**
 * filter a table selecting rows that have particular values in specific lists, all values must match
 * @param  {Table} table
 * @param  {NumberList} lists is the set of list indexes to test for matching values
 * @param  {List} values are the set of values to test for. If only 1 list is tested and there are multiple values then any of those values are accepted. A single value will be treated as a list with that value.
 *
 * @param {Boolean} keepMatchingRows if true (default value) the rows are kept if all the lists match the given values, if false matching rows are discarded
 * @param  {Boolean} returnIndexes return indexes of rows instead of filtered table (default false)
 * @return {Table}
 * tags:filter
 * examples:jeff/examples/selectRows
 */
TableOperators.selectRows = function(table, lists, values, keepMatchingRows, returnIndexes) {
  if(table == null ||  table.length <= 0) return;
  keepMatchingRows = keepMatchingRows==null?true:keepMatchingRows;
  returnIndexes = returnIndexes==null?false:returnIndexes;
  if(lists == null || values == null || lists.length == undefined) return keepMatchingRows ? table : null;
  if(!Array.isArray(values))
    values = [values];
  if(lists.length != values.length && lists.length != 1) return;

  var nLMatch = new NumberList();
  var nRows = table[0].length;
  var bOrValues = lists.length == 1 && values.length > 1;
  var dictValues = {};
  if(bOrValues)
    dictValues = ListOperators.getBooleanDictionaryForList(values);

  for(var r=0; r < nRows; r++){
    var bMatch = true;
    for(var c=0; c < lists.length && bMatch; c++){
      if(isNaN(lists[c]) || lists[c] < 0 || lists[c] >= table.length)
        return; // invalid input
      if(table[lists[c]][r] != values[c]){
        bMatch = false;
        if(bOrValues && dictValues[table[lists[c]][r]])
          bMatch = true;
      }
    }
    if(bMatch)
      nLMatch.push(r);
  }

  var newTable;
  if(returnIndexes){
    if(keepMatchingRows)
      return nLMatch;
    // want the other indices
    var nLAll = NumberListGenerators.createSortedNumberList(table[0].length);
    var nLNonMatch = ListOperators.difference(nLAll,nLMatch);
    return nLNonMatch;
  }
  if(keepMatchingRows)
    newTable = table.getRows(nLMatch);
  else
    newTable = table.getWithoutRows(nLMatch);

  return newTable;
};

/**
 * creates a new table that combines rows from two tables that have a variable in common (equivalent to SQL join)
 * @param {Table} table0 first table to be joined (aka left table in SQL naming)
 * @param {Table} table1 second table to be joined (aka right table in SQL naming)
 *
 * @param {Number|String} keyIndex0 index (Number) or name (String) of list in table0 (called first key list) that is also represented by another list (same variable) in table1 (default:0)
 * @param {Number|String} keyIndex1 index (Number) or name (String) of list in table1 (called second key list) that is also represented by another list (same variable) in table0 (default:0)
 * @param {Number} mode mode of join <|>0:inner (default), will keep the values that are common to key lists, and complete the table<|>1:left, will keep all the values first key list, add values from second key list that are also in first key list, and then complete the table
 * @return {Table}
 * tags:combine
 */
TableOperators.joinTwoTables = function(table0, table1, keyIndex0, keyIndex1, mode){
  if(table0==null || table1==null) return;

  var joinTable = new Table();
  var list0, list1;

  keyIndex0 = keyIndex0==null?0:keyIndex0;
  keyIndex1 = keyIndex1==null?0:keyIndex1;
  mode = mode==null?0:mode;

  //receives indexes or lists names
  if(typeof keyIndex0 == "string"){
    list0 = table0.getElement(keyIndex0);
    if(list0==null) throw Error("the table doesn't contain a list with name "+keyIndex0);
    keyIndex0 = table0.indexOf(list0);
  } else {
    if(keyIndex0<0) keyIndex0+=table0.length;
    keyIndex0 = keyIndex0%table0.length;
    list0 = table0[keyIndex0];
  }
  if(typeof keyIndex1 == "string"){
    list1 = table1.getElement(keyIndex1);
    if(list1==null) throw Error("the table doesn't contain a list with name "+keyIndex1);
    keyIndex1 = table1.indexOf(list1);
  } else {
    if(keyIndex1<0) keyIndex1+=table1.length;
    keyIndex1 = keyIndex1%table1.length;
    list1 = table1[keyIndex1];
  }

  var dictionary1 = ListOperators.getIndexesDictionary(list1);

  var n0 = table0.length;
  var n1 = table1.length;

  var l0 = list0.length;

  var val0;
  var indexes1;

  var i, j, k;
  var list;

  //prepares de table
  var joinList = new List();
  joinList.name = list0.name;
  joinTable[0] = joinList;

  for(k=0; k<n0; k++){
    if(k!=keyIndex0){
      list = new List();
      list.name = table0[k].name;
      list._list0=true;
      list._listOnTable = table0[k];
      joinTable.push(list);
    }
  }
  for(k=0; k<n1; k++){
    if(k!=keyIndex1){
      list = new List();
      list.name = table1[k].name;
      list._list0=false;
      list._listOnTable = table1[k];
      joinTable.push(list);
    }
  }

  //this is where the actual join happens
  for(i=0; i<l0; i++){
    val0 = list0[i];
    indexes1 = dictionary1[val0];
    if(indexes1!=null){
      for(j=0; j<indexes1.length; j++){
        joinList.push(val0);
        for(k=1; k<joinTable.length; k++){
           joinTable[k].push(  joinTable[k]._list0?joinTable[k]._listOnTable[i]:joinTable[k]._listOnTable[indexes1[j]] );
        }
      }
    } else if(mode==1){//left join
      joinList.push(val0);
      for(k=1; k<joinTable.length; k++){
         joinTable[k].push(  joinTable[k]._list0?joinTable[k]._listOnTable[i]:null );
      }
    }
  }

  for(i=0; i<joinTable.length; i++){
    joinTable[i] = joinTable[i].getImproved();
  }

  return joinTable.getImproved();
};


/**
 * creates a new table that combines rows from multiple tables that have a variable in common, this operator performs a sequence of joinTwoTables
 * @param {List} listOfTables list of tables to be joined
 * @param {NumberList|StringList} listOfIndexes indexes (NumberList) or names (StringList) of variables that are present in all tables
 *
 * @param {Number} mode mode of join <br>0:inner (default )<br>1:left
 * @return {Table}
 * tags:combine
 */
TableOperators.joinMultipleTables = function(listOfTables, listOfIndexes, mode){
  if(listOfTables==null || listOfIndexes==null) return;

  if(listOfTables.length<2) throw new Error("listOfTables must have at least two tables");
  if(listOfIndexes.length!=listOfTables.length) throw new Error("listOfIndexes must have same length as listOfTables");
  if(listOfTables[0]==null) throw new Error("listOfTables[0] is null");
  if(listOfTables[1]==null) throw new Error("listOfTables[1] is null");

  var joinTable = TableOperators.joinTwoTables(listOfTables[0], listOfTables[1], listOfIndexes[0], listOfIndexes[1], mode);
  var i;
  var l = listOfTables.length;

  for(i=2; i<l; i++){
    joinTable = TableOperators.joinTwoTables(joinTable, listOfTables[i], 0, listOfIndexes[i], mode);
  }

  return joinTable;
};


/**
 * builds a new Table where all combinations of different values is realized [!] In case the resulting Table has more than 1000000 rows, it will return the final size instead of the Table
 * @param  {Table} table
 *
 * @param {Boolean} returnNumberFinalRows (default: true) return the number of rows (instead of building the Table)
 * @return {Table}
 * tags:combine
 */
TableOperators.combineLists = function(table, returnNumberFinalRows) {
  if(table==null) return null;
  returnNumberFinalRows = returnNumberFinalRows==null?true:returnNumberFinalRows;

  var newTable = new Table();
  var combinedTable = new mo.Table();
  var indexes = new mo.NumberList();
  var lengths = new mo.NumberList();
  var nRows = 1;
  for(var i=0; i<table.length; i++){
    combinedTable[i] = new mo.List();
    newTable[i] = table[i].getWithoutRepetitions();
    indexes[i]=0;
    lengths[i]=newTable[i].length;
    nRows*=lengths[i];
  }

  if(returnNumberFinalRows || nRows>100000) return nRows;

  for(i=0; i<nRows; i++){
    for(var j=0; j<table.length;j++){
      combinedTable[j].push(newTable[j][indexes[j]]);
    }

    j=0;
    var exceed=true;
    indexes[j]++;
    exceed = indexes[j]>=lengths[j];
    while(exceed && j<table.length){
      indexes[j]=0;
      indexes[j+1]++;
      j++;
      exceed = indexes[j]>=lengths[j];
    }
    
  }

  for(var j=0; j<table.length;j++){
    combinedTable[j] = combinedTable[j].getImproved();
  }

  return combinedTable.getImproved();
};




/**
 * creates a new table with an updated first categorical List of elements and  added new numberLists with the new values
 * @param {Table} table0 table with a list of elements and a numberList of numbers associated to elements
 * @param {Table} table1 wit a list of elements and a numberList of numbers associated to elements
 * @return {Table}
 * tags:combine
 */
TableOperators.mergeDataTables = function(table0, table1) {
  if(table0==null || table1==null || table0.length<2 || table1.length<2) return;

  if(table1[0].length === 0) {
    var merged = table0.clone();
    merged.push(ListGenerators.createListWithSameElement(table0[0].length, 0));
    return merged;
  }

  var categories0 = table0[0];
  var categories1 = table1[0];

  var dictionaryIndexesCategories0 = ListOperators.getSingleIndexDictionaryForList(categories0);
  var dictionaryIndexesCategories1 = ListOperators.getSingleIndexDictionaryForList(categories1);

  var table = new Table();
  var list = ListOperators.concatWithoutRepetitions(categories0, categories1);

  var nElements = list.length;

  var nNumbers0 = table0.length - 1;
  var nNumbers1 = table1.length - 1;

  var numberTable0 = new NumberTable();
  var numberTable1 = new NumberTable();

  var index;

  var i, j;

  for(i = 0; i < nElements; i++) {
    //index = categories0.indexOf(list[i]);//@todo: imporve efficiency by creating dictionary
    index = dictionaryIndexesCategories0[list[i]];
    if(index > -1) {
      for(j = 0; j < nNumbers0; j++) {
        if(i === 0) {
          numberTable0[j] = new NumberList();
          numberTable0[j].name = table0[j + 1].name;
        }
        numberTable0[j][i] = table0[j + 1][index];
      }
    } else {
      for(j = 0; j < nNumbers0; j++) {
        if(i === 0) {
          numberTable0[j] = new NumberList();
          numberTable0[j].name = table0[j + 1].name;
        }
        numberTable0[j][i] = 0;
      }
    }

    //index = categories1.indexOf(list[i]);
    index = dictionaryIndexesCategories1[list[i]];
    if(index > -1) {
      for(j = 0; j < nNumbers1; j++) {
        if(i === 0) {
          numberTable1[j] = new NumberList();
          numberTable1[j].name = table1[j + 1].name;
        }
        numberTable1[j][i] = table1[j + 1][index];
      }
    } else {
      for(j = 0; j < nNumbers1; j++) {
        if(i === 0) {
          numberTable1[j] = new NumberList();
          numberTable1[j].name = table1[j + 1].name;
        }
        numberTable1[j][i] = 0;
      }
    }
  }

  table[0] = list;

  var l = numberTable0.length;

  for(i = 0; i<l; i++) {
    table.push(numberTable0[i]);
  }

  l = numberTable1.length;

  for(i = 0; i<l; i++) {
    table.push(numberTable1[i]);
  }
  return table;
};

/**
 * creates a new table with an updated first categorical List of elements and  added new numberLists with the new values from all data tables
 * @param {List} tableList list of data tables to merge
 *
 * @param {Boolean} categoriesAsColumns if true (default) returns a numberTable with a NumberList per category, if false return a data table with firt list containing all categories
 * @return {Table} numberTable fo categories weights, or data table with categories on first list, and merged values in n numberLists
 * tags:combine
 */
TableOperators.mergeDataTablesList = function(tableList, categoriesAsColumns) {//@todo: improve performance
  if(tableList==null || tableList.length < 2) return tableList;

  categoriesAsColumns = categoriesAsColumns==null?true:categoriesAsColumns;

  //var categories = new List();
  var categoriesIndexes = {};
  var numberTable = new NumberTable();
  var i, j, index;
  var i0, k, nCategories;
  var table;
  var n = tableList.length;
  var l;

  if(categoriesAsColumns){

    for(i = 0; i<n; i++) {
      table = tableList[i];
      l = table[0].length;
      for(j=0; j<l; j++){
        index = categoriesIndexes[ table[0][j] ];
        if(index==null){
          index = numberTable.length;
          //categories[index] = table[0][j];
          if(categoriesAsColumns) numberTable[index] = ListGenerators.createListWithSameElement(n, 0, table[0][j]);
          numberTable[index].name = table[0][j];
          categoriesIndexes[ nCategories ] = index;
        }
        numberTable[index][i] += table[1][j];
      }
    }

  } else {
    nCategories = 0;

    for(i = 0; i<n; i++) {
      table = tableList[i];

      l = table[0].length;
      numberTable[i] = new NumberList();
      numberTable.name = table.name;

      for(j=0; j<l; j++){
        index = categoriesIndexes[ table[0][j] ];
        if(index==null){
          index = nCategories;
          //categories[index] = table[0][j];
          if(categoriesAsColumns) numberTable[index] = ListGenerators.createListWithSameElement(n, 0, table[0][j]);

          categoriesIndexes[ table[0][j] ] = index;

          nCategories++;
        }



        if(numberTable[i][index]==null){
          i0 = numberTable[i].length;
          for(k=i0; k<index; k++){
            numberTable[i][k]=0;
          }
          numberTable[i][index] = table[1][j];
        } else {
          numberTable[i][index] += table[1][j];
        }
      }
    }

    for(i = 0; i<n; i++) {
      i0=numberTable[i].length;
      for(j=i0; j<=nCategories; j++){
        numberTable[i][j] = 0;
      }
    }


  }

  return numberTable;
};

/**
 * From two DataTables creates a new DataTable with combined elements in the first List, and added values in the second
 * @param {Object} table0
 * @param {Object} table1
 * @return {Table}
 */
TableOperators.sumDataTables = function(table0, table1) {//
  var table = table0.clone();
  var index;
  var element;
  for(var i = 0; table1[0][i] != null; i++) {
    element = table1[0][i];
    index = table[0].indexOf(element);//@todo make more efficient with dictionary
    if(index == -1) {
      table[0].push(element);
      table[1].push(table1[1][i]);
    } else {
      table[1][index] += table1[1][i];
    }
  }
  return table;
};

/**
 * calcualtes the sum of products of numbers associated with same elements
 * @param  {Table} table0 dataTable with categories and numbers
 * @param  {Table} table1 dataTable with categories and numbers
 * @return {Number}
 * tags:statistics
 */
TableOperators.dotProductDataTables = function(table0, table1) {
  if(table0==null || table1==null || table0.length<2 || table1.length<2) return null;

  var i, j;
  var l0 = table0[0].length;
  var l1 = table1[0].length;
  var product = 0;
  var element, element1;
  for(i=0; i<l0; i++){
    element = table0[0][i];
    for(j=0; j<l1; j++){
      element1 = table1[0][j];
      if(element==element1) product+=table0[1][i]*table1[1][j];
    }
  }

  return product;
};


/**
 * calcualtes the cosine similarity based on TableOperators.dotProductDataTables
 * @param  {Table} table0 dataTable with categories and numbers
 * @param  {Table} table1 dataTable with categories and numbers
 *
 * @param {Number} norm0 optionally pre-calculated norm of table0[1]
 * @param {Number} norm1 optionally pre-calculated norm of table1[1]
 * @return {Number}
 * tags:statistics,ds
 */
TableOperators.cosineSimilarityDataTables = function(table0, table1, norm0, norm1) {
  if(table0==null || table1==null || table0.length<2 || table1.length<2) return null;

  if(table0[0].length===0 || table1[0].length===0) return 0;

  norm0 = norm0==null?table0[1].getNorm():norm0;
  norm1 = norm1==null?table1[1].getNorm():norm1;

  var norms = norm0*norm1;
  if(norms === 0) return 0;
  return TableOperators.dotProductDataTables(table0, table1)/norms;
};

/**
 * @todo finish docs
 */
TableOperators.completeTable = function(table, nRows, value) {
  value = value === undefined ? 0 : value;

  var newTable = new Table();
  newTable.name = table.name;

  var list;
  var newList;
  var j;

  for(var i = 0; i < table.length; i++) {
    list = table[i];
    newList = list == null ? ListOperators.getNewListForObjectType(value) : instantiateWithSameType(list);
    newList.name = list == null ? '' : list.name;
    for(j = 0; j < nRows; j++) {
      newList[j] = (list == null || list[j] == null) ? value : list[j];
    }
    newTable[i] = newList;
  }
  return newTable;
};

/**
 * filters a Table keeping the NumberLists
 * @param  {Table} table to filter<
 * @return {NumberTable}
 * tags:filter
 */
TableOperators.getNumberTableFromTable = function(table) {
  if(table == null ||  table.length <= 0) {
    return null;
  }

  var i;
  var newTable = new NumberTable();
  newTable.name = table.name;
  for(i = 0; table[i] != null; i++) {
    if(table[i].type == "NumberList") newTable.push(table[i]);
  }
  return newTable;
};

/**
 * calculates de information gain of all variables in a table and a supervised variable
 * @param  {Table} variablesTable
 * @param  {List} supervised
 * @return {NumberList}
 * tags:ds,statistics
 */
TableOperators.getVariablesInformationGain = function(variablesTable, supervised) {
  if(variablesTable == null) return null;

  var igs = new NumberList();
  variablesTable.forEach(function(feature) {
    igs.push(ListOperators.getInformationGain(feature, supervised));
  });
  return igs;
};

/**
 * Split a table into a list of tables based on the categorical values of some list
 * @param  {Table} table The input table
 * @param  {Number|List|String} listCategories: list, name or index in the Table defines a list used to split the input table
 *
 * @param  {Boolean} bReturnObject if true then return an object which maps keys to tables. (default:false)
 * @return {List|Object}
 * tags:
 */
TableOperators.splitTableByCategoricList = function(table, listCategories, bReturnObject) {
  if(table == null || listCategories == null) return null;

  var list = listCategories["isList"]?listCategories:table.getColumn(listCategories);

  var childrenTable;
  var tablesList = new List();
  var childrenObject = {};

  list.forEach(function(element, i) {
    childrenTable = childrenObject[element];
    if(childrenTable == null) {
      childrenTable = new Table();
      childrenTable.name = String(element);
      childrenObject[element] = childrenTable;
      tablesList.push(childrenTable);
      table.forEach(function(list, j) {
        childrenTable[j] = instantiateWithSameType(list);
        childrenTable[j].name = list.name;
      });
      childrenTable._element = element;
    }
    table.forEach(function(list, j) {
      childrenTable[j].push(table[j][i]);
    });
  });
  if(bReturnObject)
    return childrenObject;
  return tablesList;
};

/**
 * Balance a table by duplicating rows if necessary so that there will be the same number of values in a categorical list
 * @param  {Table} table The input table
 * @param  {Number|List|String} listCategories: list, name or index in the Table defines a list used to balance the input table
 * @return {Table}
 * tags:
 */
TableOperators.balanceTableByCategoricList = function(table, listCategories) {
  if(table == null || listCategories == null) return null;
  // duplicate rows of data so that table has equal numbers of rows for each category
  var list = listCategories["isList"]?listCategories:table.getColumn(listCategories);
  var tFreq = list.getFrequenciesTable();
  var dictList = ListOperators.getIndexesDictionary(list);
  var nMax = tFreq[1][0];
  // keep all the original rows
  var nLtoKeep = NumberListGenerators.createSortedNumberList(list.length);
  var i,nToAdd,nLNew;
  for(i=1; i < tFreq[0].length; i++){
    nToAdd = nMax - tFreq[1][i];
    if(nToAdd == 0) continue;
    nLNew = ListOperators.skipSample(dictList[tFreq[0][i]],nToAdd);
    nLtoKeep = nLtoKeep.concat(nLNew);
  }
  var tWithExtra = table.getRows(nLtoKeep);
  return tWithExtra;
};

/**
 * builds a network from columns or rows, taking into account similarity in numbers (correlation) and other elements (Jaccard) (adds i property to nodes, position of list or row)
 * @param  {Table} table
 *
 * @param {Boolean} nodesAreRows if true (default value) each node corresponds to a row in the table, and rows are compared, if false lists are compared ([!] working only for NumberTable, using pearson correlation)
 * @param {String|Number|StringList} names optionally add names to nodes with a list that could be part of the table or not; receives a StringList for names, or an index (Number) for a list in the providade table<|0.name|>
 * @param {String|Number|List} colorsByList optionally add color to nodes from a NumberList (for scale), any List (for categorical colors) that could be part of the table or not; receives a List or an index if the list is in the providade table<|0.name|>
 * @param {Number} correlationThreshold 0.9 by default, above that level a relation is created
 * @param {Boolean} negativeCorrelation takes into account negative correlations for building relations
 * @param {Number} mode numeric correlation mode:<|>0: Pearson correlation (default)<|>1: cosine similarity<|>2: minimum number of connections per node (will guarantee all nodes will be connected, default is 3 but can be set on minConnectionsPerNode parameter
 * @param {NumberList|Number} weightByList optionally add weight to nodes from a NumberList; receives a numberList or an index if the list is in the providade table
 * @param {Number} minConnectionsPerNode only for option 3 in mode, default: 3
 * @return {Network}
 * tags:advanced,statistics,networks,transform
 * examples:santiago/examples/forIntroPanel/NetworkFromATable
 */
TableOperators.buildCorrelationsNetwork = function(table, nodesAreRows, names, colorsByList, correlationThreshold, negativeCorrelation, mode, weightByList, minConnectionsPerNode){
  if(table==null) return null;

  nodesAreRows = nodesAreRows==null?true:Boolean(nodesAreRows);
  correlationThreshold = correlationThreshold==null?0.9:correlationThreshold;
  negativeCorrelation = Boolean(negativeCorrelation);
  mode = mode==null?0:mode;
  minConnectionsPerNode = minConnectionsPerNode==null?3:minConnectionsPerNode;

  //var types = table.getTypes();
  var i, j;
  var l = table.length;
  var nRows = table[0].length;
  var node, node1, relation;
  var id, name;
  var pearson, jaccard, weight;
  var colorsList, colors;

  var someCategorical = false;
  var someText = false;
  var someNumeric = false;

  var network = new Network();


  var pseudoKinds = new StringList(); //numbers, categories and texts; similar to kind
  var type;

  var nNodes = nodesAreRows?nRows:l;

  var nNumbers=0;
  var nCategories=0;
  var nTexts=0;

  for(i=0; i<l;i++){
    type = table[i].type;
    if(type == "NumberList"){
      pseudoKinds[i] = 'numbers';
      nNumbers++;
    } else if(type == 'StringList'){
      if(table[i].getWithoutRepetitions().length/table[i].length>0.8){
        pseudoKinds[i] = 'texts';
        nTexts++;
      } else {
        pseudoKinds[i] = 'categories';
        nCategories++;
      }
    } else {
      pseudoKinds[i] = 'categories';
      nCategories++;
    }
  }

  if(colorsByList!=null){

    if(colorsByList["isList"]){
      colorsList = colorsByList;
    } else {
      colorsList = table.getColumn(colorsByList);
    }
    /*
    if(typeOf(colorsByList)=="number"){
      if(colorsByList<=nNodes){
        colorsList = table[colorsByList];
      }
    } else if(colorsByList["isList"]){
      if(colorsByList.length>=nNodes) colorsList = colorsByList;
    }
    */

    if(colorsList!=null){
      if(colorsList.type === "ColorList"){
        colors = colorsList;
      } else if(colorsList.type === "NumberList"){
        colors = ColorListGenerators.createColorListFromNumberList(colorsList, ColorScales.blueToRed, 0);// ColorListOperators.colorListFromColorScaleFunctionAndNumberList(ColorScales.blueToRed, colorsList, true);
      } else {
        colors = ColorListGenerators.createCategoricalColorListForList(colorsList)[0].value; //@todo [!] this method will soon change
      }
    }
  }

  var weights;

  if(weightByList!=null){

    weights = new NumberList();

    if(typeOf(weightByList)=="number"){
      if(weightByList<=l){
        weights = table[weightByList];
        if(weights.type!="NumberList") weights = null;
      }
    } else if(weightByList["isList"] && weightByList.type=="NumberList"){
      if(weightByList.length>=nNodes) weights = weightByList;
    }
  }

  //if(names!=null && typeOf(names)=="number" && names<l) names = table[names];

  if(names!=null && !names["isList"]){
    names = table.getColumn(names);
  }

  if(!nodesAreRows){ //why not just take transposed table?????

    if(table.type=="NumberTable"){//correlations network, for the time being

      for(i=0; i<l; i++){
        node = new Node("list_"+i, (table[i].name==null || table[i].name=="")?"list_"+i:table[i].name);
        network.addNode(node);
        node.i = i;
        node.numbers = table[i];

        if(colors!=null) node.color = colors[i];
        if(weights!=null) node.weight = weights[i];
      }

      for(i=0; i<l; i++){
        node = network.nodeList[i];
        for(j=i+1; j<l; j++){
          node1 = network.nodeList[j];
          pearson = NumberListOperators.pearsonProductMomentCorrelation(node.numbers, node1.numbers);
          weight = pearson;
          if( (negativeCorrelation && Math.abs(weight)>correlationThreshold) || (!negativeCorrelation && weight>correlationThreshold) ){
            id = i+"_"+j;
            name = node.name+"_"+node1.name;
            relation = new Relation(id, name, node, node1, Math.abs(weight)-correlationThreshold*0.9);
            relation.color = weight>0?'blue':'red';
            relation.pearson = pearson;
            network.addRelation(relation);
          }
        }
      }
    } else {
      //any table
    }
  } else {
    for(i=0; i<nRows; i++){
      id = "_"+i;
      name = names==null?id:names[i];
      node = new Node(id, name);
      node.i = i;

      node.row = table.getRow(i);
      node.numbers = new NumberList();
      node.categories = new List();
      node.texts = new StringList();

      if(colors!=null) node.color = colors[i];
      if(weights!=null) node.weight = weights[i];

      for(j=0; j<l; j++){
        //types[j]==="NumberList"?node.numbers.push(node.row[j]):node.categories.push(node.row[j]);
        switch(pseudoKinds[j]){
          case 'numbers':
            node.numbers.push(node.row[j]);
            someNumeric = true;
            break;
          case 'categories':
            node.categories.push(node.row[j]);
            someCategorical = true;
            break;
          case 'texts':
            node.texts.push(node.row[j]);
            someText = true;
            break;
        }
      }

      if(mode===0){
        node.numbers.sd = node.numbers.getStandardDeviation();
      } else {
        node.numbers.norm = node.numbers.getNorm();
      }

      network.addNode(node);
    }

    var relationWeights;

    for(i=0; i<nRows; i++){
      node = network.nodeList[i];
      relationWeights = new mo.NumberList();

      for(j=0; j<nRows; j++){
        if(mode!=2 && j<i+1) continue;

        node1 = network.nodeList[j];

        pearson = someNumeric?
          (mode===0?
            NumberListOperators.pearsonProductMomentCorrelation(node.numbers, node1.numbers, node.numbers.sd, node1.numbers.sd)
            :
            NumberListOperators.cosineSimilarity(node.numbers, node1.numbers, node.numbers.norm, node1.numbers.norm)
          )
          :
          0;

        //jaccard is normalized to -1, 1 so it can be negative
        jaccard = someCategorical?Math.pow( ListOperators.jaccardIndex(node.categories, node1.categories), 0.2 )*2 - 1 : 0;

        //texts

        //textDistance =  someText?… cosine simlairty based on pre-calculated words tables


        //dates, geo coordinates…

        if(someNumeric && someCategorical){
          weight = (pearson*nNumbers + jaccard*nCategories)/(nNumbers+nCategories);
        } else if (someNumeric){
          weight = pearson;
        } else {
          weight = jaccard;
        }

        if(mode==2){
          relationWeights.push(i==j?0:weight);
        } else {
          if( (negativeCorrelation && Math.abs(weight)>correlationThreshold) || (!negativeCorrelation && weight>correlationThreshold) ){
            id = i+"_"+j;
            name = names==null?id:node.name+"_"+node1.name;
            relation = new Relation(id, name, node, node1, Math.abs(weight)-correlationThreshold*0.9);
            relation.color = weight>0?'blue':'red';
            relation.pearson = pearson;
            relation.jaccard = jaccard;
            network.addRelation(relation);
          }
        }
      }

      if(mode==2){
        var nodesToConnect = network.nodeList.getSortedByList(relationWeights, false);
        relationWeights = relationWeights.getSorted(false);

        for(j=0;j<minConnectionsPerNode;j++){
          node1 = nodesToConnect[j];
          id = i+"_"+node1.id;
          name = names==null?id:node.name+"_"+node1.name;
          relation = new Relation(id, name, node, node1, relationWeights[j]);
          network.addRelation(relation);
        }
      }

    }
  }

  return network;
};


/**
 * builds a simple decision tree that finds combinations of values from variables on the table that maximize and minimize the probability of having a value on the supervized list,
 * works with categorical variables, with NumberLists being converted 3 quantiles (low, medium, high),
 * uses drawSimpleDecisionTree as ideal visualization for this tree
 * @param  {Table} variablesTable predictors table
 * @param  {Number|NumberList|String} supervised variable: list, or name or index in the Table (in which case the list will be removed from the predictors table)
 * @param {Object} supervisedValue main value in supervised list (associated with blue), in case of a numeric list, values are 'low', 'medium', 'high'
 *
 * @param {Number} min_entropy minimum value of entropy on nodes (0.2 default)
 * @param {Number} min_size_node minimum population size associated with node (10 default)
 * @param {Number} min_info_gain minimum information gain by splitting by best feature (0.002 default)
 * @param {Boolean} generatePattern generates a pattern of points picturing proprtion of followed class in node
 * @param {ColorScale} colorScale to assign color associated to probability (default: blueToRed)
 * @return {Tree} tree with aditional properties on its nodes, including: indexesOnTable, probablity, lift, color, entropy, weight
 * tags:ds,machine-learning
 */
TableOperators.buildSimpleDecisionTree  = function(table, supervised, supervisedValue, min_entropy, min_size_node, min_info_gain, generatePattern, colorScale){
  if(table==null || supervised==null) return;
  var newTable = new mo.Table();
  var iO;
  var nL;

  var newSupervised = supervised["isList"]?supervised:table.getColumn(supervised);

  for(var i=0; i<table.length; i++){
    if(table[i]==newSupervised) continue;
    iO = ListOperators.buildInformationObject(table[i], true);
    if(iO.isCategorical){
      newTable.push(table[i]);
    } else if(iO.isNumeric){
      nL = table[i].getQuantiles(3, 3);
      newTable.push(nL);
    } 
  }

  iO = ListOperators.buildInformationObject(newSupervised, true);
  
  //if(iO.isNumeric) newSupervised = newSupervised.getQuantiles(3, 3); //used to be a good idea…

  return TableOperators.buildDecisionTree(newTable, newSupervised, supervisedValue, min_entropy, min_size_node, min_info_gain, generatePattern, colorScale);
};


/**
 * builds a decision tree based on a table made of categorical lists, a list (the values of a supervised variable), and a value from the supervised variable. The result is a tree that contains on its leaves different populations obtained by iterative filterings by category values, and that contain extremes probabilities for having or not the valu ein the supervised variable.
 * [!] this operator only works with categorical lists (in case you have lists with numbers, find a way to simplify by ranges or powers)
 * [!] this operator is deprecated 
 * @param  {Table} variablesTable predictors table
 * @param  {Object} supervised variable: list, or index (number) in the table (in which case the list will be removed from the predictors table)
 * @param {Object} supervisedValue main value in supervised list (associated with blue)
 *
 * @param {Number} min_entropy minimum value of entropy on nodes (0.2 default)
 * @param {Number} min_size_node minimum population size associated with node (10 default)
 * @param {Number} min_info_gain minimum information gain by splitting by best feature (0.002 default)
 * @param {Boolean} generatePattern generates a pattern of points picturing proprtion of followed class in node
 * @param {ColorScale} colorScale to assign color associated to probability (default: blueToRed)
 * @return {Tree} tree with aditional properties on its nodes, including: indexesOnTable, probablity, lift, color, entropy, weight
 */
TableOperators.buildDecisionTree = function(variablesTable, supervised, supervisedValue, min_entropy, min_size_node, min_info_gain, generatePattern, colorScale){
  if(variablesTable == null ||  supervised == null) return;

  if(colorScale==null) colorScale = ColorScales.blueWhiteRed;

  if(typeOf(supervised)=='number'){
    var newTable = variablesTable.getWithoutElementAtIndex(supervised);
    supervised = variablesTable.getElement(supervised);
    variablesTable = newTable;
  }

  min_entropy = min_entropy == null ? 0.1 : min_entropy;
  min_size_node = min_size_node || 10;
  min_info_gain = min_info_gain || 0.002;

  var indexes = NumberListGenerators.createSortedNumberList(supervised.length);
  var tree = new Tree();

  console.log('\n\n\n*********buildDecisionTree**********');
  console.log('supervised.name:',supervised.name);
  console.log('predictors names:',variablesTable.getNames().join(', '));

  tree.supervisedValue = supervisedValue;

  if(supervisedValue==null){
    tree._colorDic = mo.ColorListGenerators.createCategoricalColorListForList(supervised)[4].value;
    console.log("[TO] tree._colorDic", tree._colorDic);
  }

  TableOperators._buildDecisionTreeNode(tree, variablesTable, supervised, 0, min_entropy, min_size_node, min_info_gain, null, null, supervisedValue, indexes, generatePattern, colorScale);

  tree.leavesWeights = tree.getLeaves().getPropertyValues("weight");
  tree.sumWeightsLeaves = tree.leavesWeights.getSum();
  tree.supervised = supervised;

  return tree;
};


/**
 * @ignore
 */
TableOperators._buildDecisionTreeNode = function(tree, variablesTable, supervised, level, min_entropy, min_size_node, min_info_gain, parent, value, supervisedValue, indexes, generatePattern, colorScale) {
  var MAX_LEVEL_FOR_CONSOLE = 0;

  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('\nlevel:',level);
  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('_buildDecisionTreeNode | supervised.name, supervisedValue', supervised.name, supervisedValue);

  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('supervised:', supervised);

  var nullSupervisedValue = supervisedValue==null;

  if(nullSupervisedValue){
    var fT = supervised.getFrequenciesTable(true);
    supervisedValue = fT[0][0];
  }

  var entropy = ListOperators.getListEntropy(supervised, supervisedValue);

  // supervised._P_valueFollowing=0;
  // var indexesSupervisedValue = new NumberList();

  // for(var i=0; i<supervised.length; i++){
  //   if(supervised[i]==supervisedValue){
  //     supervised._P_valueFollowing++;
  //     indexesSupervisedValue.push(i);
  //   }
  // }
  // supervised._P_valueFollowing/=supervised.length;


  //there's no supervisedValue, 


  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('entropy, min_entropy', entropy, min_entropy);
  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('supervised._P_valueFollowing', supervised._P_valueFollowing);

  var maxIg = 0;
  var iBestFeature = 0;
  var informationGains = 0;

  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('variablesTable.length:', variablesTable.length);
  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('entropy >= min_entropy:', entropy >= min_entropy);

  if(entropy >= min_entropy) {
    informationGains = TableOperators.getVariablesInformationGain(variablesTable, supervised);
    informationGains.forEach(function(ig, i){
      if(ig > maxIg) {
        maxIg = ig;
        iBestFeature = i;
      }
    });
  }

  var subDivide = entropy >= min_entropy && maxIg > min_info_gain && supervised.length >= min_size_node && level<9 && supervised._P_valueFollowing>0 && supervised._P_valueFollowing<1;

  if(level < MAX_LEVEL_FOR_CONSOLE) console.log('subDivide:', subDivide);

  var id = tree.nodeList.getNewId();
  var name = (value == null ? '' : value + ':') + (subDivide ? variablesTable[iBestFeature].name : 'P=' + supervised._biggestProbability + '(' + supervised._mostRepresentedValue + ')');
  var node = new Node(id, name);

  tree.addNodeToTree(node, parent);

  if(parent == null) {
    tree.informationGainTable = new Table();
    tree.informationGainTable[0] = variablesTable.getNames();
    if(informationGains) {
      tree.informationGainTable[1] = informationGains.clone();
      tree.informationGainTable = tree.informationGainTable.getListsSortedByList(informationGains, false);
    }
  }

  node.entropy = entropy;
  node.weight = supervised.length;
  node.supervised = supervised;
  node.supervisedValue = supervisedValue;
  node.indexes = node.indexesOnTable = indexes;
  node.value = value;
  node.fromList = parent==null?"":parent.bestFeatureName;
  node.mostRepresentedValue = supervised._mostRepresentedValue;
  node.biggestProbability = supervised._biggestProbability;
  node.valueFollowingProbability = supervised._P_valueFollowing;
  node.lift = node.valueFollowingProbability / tree.nodeList[0].valueFollowingProbability;
  node.i = tree.nodeList.length-1;

  if(node.fromList==""){
    node.filter = {}
  } else {
    node.filter = {
      column:node.fromList
    }
    if(node.value && node.value.includes && node.value.includes("-")){
      var parts = node.value.split("-");
      if(String(Number(parts[0]))==parts[0] && String(Number(parts[1]))==parts[1]){
        node.filter.type="range";
        node.filter.value=[
          true,
          Number(parts[0]),
          true,
          Number(parts[1])
        ]
      }
    }
    if(node.filter.type==null){
      node.filter.type="in_array";
      node.filter.value = [node.value];
    }
  }

  node.filters = [];

  var addFilters = function(node_in_chain){
    if(node_in_chain.parent) addFilters(node_in_chain.parent);
    if(node_in_chain.filter.column) node.filters.push(node_in_chain.filter);
  }

  addFilters(node);

  if(nullSupervisedValue){
    var clrs = new mo.ColorList();
    for(var i=0; i<fT[0].length; i++){
      clrs[i] = tree._colorDic[fT[0][i]];
    }

    node._color = node.color = ColorOperators.interpolateColors('white', ColorOperators.colorsLinearCombination(clrs, fT[1]), node.valueFollowingProbability) ; //tree._colorDic[supervisedValue];
  } else {
    node._color = node.color = colorScale(1-node.valueFollowingProbability);
  }

  if(generatePattern) {
    var newCanvas = document.createElement("canvas");
    newCanvas.width = 150;
    newCanvas.height = 100;
    var newContext = newCanvas.getContext("2d");
    newContext.clearRect(0, 0, 150, 100);

    TableOperators._decisionTreeGenerateColorsMixture(newContext, 150, 100, ['blue', 'red'],
			node.mostRepresentedValue==supervisedValue?
				[Math.floor(node.biggestProbability*node.weight), Math.floor((1-node.biggestProbability)*node.weight)]
				:
				[Math.floor((1-node.biggestProbability)*node.weight), Math.floor(node.biggestProbability*node.weight)]
    );

    var img = new Image();
    img.src = newCanvas.toDataURL();
    node.pattern = newContext.createPattern(img, "repeat");
  }

  if(!subDivide){
    node.isLeaf = true;
    node.label = Math.floor(node.valueFollowingProbability*100)/100;
    return node;
  }

  node.isLeaf = false;

  node.bestFeatureName = variablesTable[iBestFeature].name;
  node.bestFeatureName = node.bestFeatureName === "" ? "list "+ iBestFeature:node.bestFeatureName;
  node.iBestFeature = iBestFeature;
  node.informationGain = maxIg;

  node.label = node.bestFeatureName;

  var expanded = variablesTable.concat([supervised, indexes]);

  var tables = TableOperators.splitTableByCategoricList(expanded, variablesTable[iBestFeature]);
  var childTable;
  var childSupervised;
  var childIndexes;

  tables.forEach(function(expandedChild) {
    childTable = expandedChild.getSubList(0, expandedChild.length - 3);
    childSupervised = expandedChild[expandedChild.length - 2];
    childIndexes = expandedChild[expandedChild.length - 1];
    TableOperators._buildDecisionTreeNode(tree, childTable, childSupervised, level + 1, min_entropy, min_size_node, min_info_gain, node, expandedChild._element, nullSupervisedValue?null:supervisedValue, childIndexes, generatePattern, colorScale);
  });

  node.toNodeList = node.toNodeList.getSortedByProperty('valueFollowingProbability', false);

  return node;
};


/**
 * @ignore
 */
TableOperators._decisionTreeGenerateColorsMixture = function(ctxt, width, height, colors, weights){
  var x, y, i; //, rgb;
  var allColors = ListGenerators.createListWithSameElement(weights[0], colors[0]);

  for(i = 1; colors[i] != null; i++) {
    allColors = allColors.concat(ListGenerators.createListWithSameElement(weights[i], colors[i]));
  }

  for(x = 0; x < width; x++) {
    for(y = 0; y < height; y++) {
      i = (x + y * width) * 4;
      ctxt.fillStyle = allColors.getRandomElement();
      ctxt.fillRect(x, y, 1, 1);
    }
  }
};

/**
 * return true if all lists from table have same length, false otherwise
 * @param  {Table} table
 * @return {Boolean}
 */
TableOperators.allListsSameLength = function(table){
  if(table==null) return null;

  var l = table.length;
  var length = table[0].length;
  var i;
  for(i=1; i<l; i++){
    if(table[i].length!=length) return false;
  }

  return true;
};

/**
 * returns a Table with lists without repeated elements
 * @param  {Table} table
 * @return {NumberList}
 * tags:transform
 */
TableOperators.getListsWithoutRepetition = function(table){
  if(table==null) return;

  var l = table.length;
  var i;
  var newTable = new Table();

  for(i=0; i<l; i++){
    newTable[i] = table[i].getWithoutRepetitions();
  }

  return newTable.getImproved();
};

/**
 * returns a numberList with the number of different elements in each list
 * @param  {Table} table
 *
 * @param {Boolean} proprtion if true, returns the proportion (between 0 and 1) of different elements in each List (default:false)
 * @return {NumberList}
 * tags:count
 */
TableOperators.getNumberOfDifferentElementsOfLists = function(table, proprotion){
  if(table==null) return;

  var l = table.length;
  var i;
  var nList = new NumberList();
  nList.name = "count different elements"+proprotion?"(proportion)":"";

  for(i=0; i<l; i++){
    nList[i] = table[i].countDifferentElements();// table[i].getWithoutRepetitions().length;
    if(proprotion) nList[i]/=table[i].length;
  }

  return nList;
};

/**
 * builds an object with statistical information about the table  (infoObject property will be added to table and to lists)
 * @param  {Table} table
 *
 * @param {Boolean} bUseExistingObjectIfPresent (default true)
 * @return {Object}
 */
TableOperators.buildInformationObject = function(table, bUseExistingObjectIfPresent){
  if(table==null) return;

  bUseExistingObjectIfPresent = bUseExistingObjectIfPresent==null?true:bUseExistingObjectIfPresent; // <------- not sure if this could cause issues

  if(bUseExistingObjectIfPresent == true && table.infoObject != null) return table.infoObject;

  var n = table.length;
  var i, listInfoObject;
  var min = 999999999999;
  var max = -999999999999;
  var average = 0;
  var intsAndCats = true;

  var infoObject = {
    type:table.type,
    name:table.name,
    length:n,
    kind:'mixed', //mixed, numbers, integer numbers, texts, categories, integers and categories
    allListsSameLength:true,
    allListsSameSize:true,
    allListsSameType:true,
    allListsSameKind:true,
    listsInfoObjects:new List(),
    names:new StringList(),
    lengths:new NumberList(),
    types:new StringList(),
    kinds:new StringList()
  };


  for(i=0; i<n; i++){
    listInfoObject = ListOperators.buildInformationObject(table[i],bUseExistingObjectIfPresent);
    infoObject.listsInfoObjects[i] = listInfoObject;

    if(listInfoObject.containsNulls) infoObject.containsNulls=true;

    infoObject.names[i] = listInfoObject.name;
    infoObject.types[i] = listInfoObject.type;
    infoObject.kinds[i] = listInfoObject.kind;
    infoObject.lengths[i] = listInfoObject.length;

    if(listInfoObject.kind!='categories' && listInfoObject.kind!='integer numbers') intsAndCats = false;

    if(i>0){
      if(infoObject.lengths[i]!=infoObject.lengths[i-1]) infoObject.allListsSameLength = false;
      if(infoObject.types[i]!=infoObject.types[i-1]) infoObject.allListsSameType = false;
      if(infoObject.kinds[i]!=infoObject.kinds[i-1]) infoObject.allListsSameKind = false;
      if(infoObject.lengths[i]!=infoObject.lengths[i-1]) infoObject.allListsSameType = false;
    }

    if(listInfoObject.type == "NumberList"){
      min = Math.min(min, listInfoObject.min);
      max = Math.min(max, listInfoObject.max);
      average+=listInfoObject.average;
    }
  }

  if(average!==0) average/=infoObject.length;

  if(infoObject.allListsSameLength){
    infoObject.minLength = infoObject.lengths[0];
    infoObject.maxLength = infoObject.lengths[0];
    infoObject.averageLength = infoObject.lengths[0];
  } else {
    var interval = infoObject.lengths.getInterval();
    infoObject.minLength = interval.x;
    infoObject.maxLength = interval.y;
    infoObject.averageLength = (interval.x + interval.y)*0.5;
  }

  if(infoObject.allListsSameKind){
    switch(infoObject.kinds[0]){
      case 'numbers':
        infoObject.kind = 'numbers';
        break;
      case 'integer numbers':
        infoObject.kind = 'integer numbers';
        break;
      case 'texts':
        infoObject.kind = 'texts';
        break;
      case 'categories':
        infoObject.kind = 'categories';
        break;
    }
  } else {
    if(intsAndCats){
      infoObject.kind = 'integers and categories';
    } else if(infoObject.allListsSameType && infoObject.types[0]=='NumberList'){
      infoObject.kind = 'numbers';
    }
  }

  table.infoObject = infoObject;

  TableOperators._dataQualityReport(table);

  return infoObject;

};

/**
 * returns a table with only categorical lists
 * @param {Table} table
 * @return {Table} filtered table
 * tags:filter
 */
TableOperators.getCategoricalLists = function(table){
  var infoObject = TableOperators.buildInformationObject(table);

  var catTable = new mo.Table();

  for(var i=0;i<table.length; i++){
    if(table[i].infoObject.isCategorical) catTable.push(table[i]);
  }

  return catTable.getImproved();
};


/**
 * Generates a string containing details about the current state
 * of the Table. Useful for outputing to the console for debugging.
 * @param {Table} table Table to generate report on.
 * @param {Number} level If greater then zero, will indent to that number of spaces.
 * @return {String} Description String.
 */
TableOperators.getReport = function(table, level) {
  if(table==null) return null;

  var n = table.length;
  var i;
  var ident = "\n" + (level > 0 ? StringOperators.repeatString("  ", level) : "");
  var lengths = table.getLengths();
  var minLength = lengths.getMin();
  var maxLength = lengths.getMax();
  var averageLength = (minLength + maxLength) * 0.5;
  var sameLengths = minLength == maxLength;

  var text = level > 0 ? (ident + "////report of instance of Table////") : "///////////report of instance of Table//////////";

  if(table.length === 0) {
    text += ident + "this table has no lists";
    return text;
  }

  text += ident + "name: " + table.name;
  text += ident + "type: " + table.type;
  text += ident + "number of lists: " + table.length;

  text += ident + "all lists have same length: " + (sameLengths ? "true" : "false");

  if(sameLengths) {
    text += ident + "lists length: " + table[0].length;
  } else {
    text += ident + "min length: " + minLength;
    text += ident + "max length: " + maxLength;
    text += ident + "average length: " + averageLength;
    text += ident + "all lengths: " + lengths.join(", ");
  }

  var names = table.getNames();
  var types = table.getTypes();

  text += ident + "--";
  //names.forEach(function(name, i){
  for(i=0; i<n; i++){
    text += ident + i + ": " + names[i] + " ["+TYPES_SHORT_NAMES_DICTIONARY[types[i]]+"]";
  }

  text += ident + "--";

  var sameTypes = types.allElementsEqual();
  if(sameTypes) {
    text += ident + "types of all lists: " + types[0];
  } else {
    text += ident + "types: " + types.join(", ");
  }
  text += ident + "names: " + names.join(", ");

  if(table.length < 101) {
    text += ident + ident + "--------lists reports---------";

    for(i = 0; i<n; i++) {
      text += "\n" + ident + ("(" + (i) + "/0-" + (table.length - 1) + ")");
      try{
         text += ListOperators.getReport(table[i], 1);
      } catch(err){
        text += ident + "[!] something wrong with list " + err;
      }
    }
  }

  if(table.length == 2) {
    text += ident + ident + "--------lists comparisons---------";
    if(table[0].type=="NumberList" && table[1].type=="NumberList"){
      text += ident + "covariance:" + NumberListOperators.covariance(table[0], table[1]);
      text += ident + "Pearson product moment correlation: " + NumberListOperators.pearsonProductMomentCorrelation(table[0], table[1]);
    } else if(table[0].type!="NumberList" && table[1].type!="NumberList"){
      var nUnion = ListOperators.union(table[0], table[1]).length;
      text += ident + "union size: " + nUnion;
      var intersected = ListOperators.intersection(table[0], table[1]);
      var nIntersection = intersected.length;
      text += ident + "intersection size: " + nIntersection;

      if(table[0]._freqTable[0].length == nUnion && table[1]._freqTable[0].length == nUnion){
        text += ident + "[!] both lists contain the same non repeated elements";
      } else {
        if(table[0]._freqTable[0].length == nIntersection) text += ident + "[!] all elements in first list also occur on second list";
        if(table[1]._freqTable[0].length == nIntersection) text += ident + "[!] all elements in second list also occur on first list";
      }
      text += ident + "Jaccard distance: " + (1 - (nIntersection/nUnion));
    }
    //check for 1-1 matches, number of pairs, categorical, sub-categorical
    var subCategoryCase = ListOperators.subCategoricalAnalysis(table[0], table[1]);

    switch(subCategoryCase){
      case 0:
        text += ident + "no categorical relation found between lists";
        break;
      case 1:
        text += ident + "[!] both lists are categorical identical";
        break;
      case 2:
        text += ident + "[!] first list is subcategorical to second list";
        break;
      case 3:
        text += ident + "[!] second list is subcategorical to first list";
        break;
    }

    if(subCategoryCase!=1){
      text += ident + "information gain when segmenting first list by the second: "+ListOperators.getInformationGain(table[0], table[1]);
      text += ident + "information gain when segmenting second list by the first: "+ListOperators.getInformationGain(table[1], table[0]);
    }
  }

  ///add ideas to: analyze, visualize

  return text;
};

/**
 * Generates a string containing details about the current state
 * of the Table. Useful for outputing to the console for debugging.
 * @param {Table} table Table to generate report on.
 * @param {Number} level If greater then zero, will indent to that number of spaces.
 * @return {String} Description String.
 */
TableOperators.getReportHtml = function(table,level) {
  if(table==null) return;

  var infoObject = TableOperators.buildInformationObject(table);

  var ident = "<br>" + (level > 0 ? StringOperators.repeatString("&nbsp", level) : "");
  //var lengths = infoObject.lengths();
  //var minLength = lengths.getMin();
  //var maxLength = lengths.getMax();
  //var averageLength = (infoObject.minLength + infoObject.maxLength) * 0.5;
  //var sameLengths = infoObject.;

  var text = "<b>" +( level > 0 ? (ident + "<font style=\"font-size:16px\">table report</f>") : "<font style=\"font-size:18px\">table report</f>" ) + "</b>";

  if(table.length === 0) {
    text += ident + "this table has no lists";
    return text;
  }

  if(table.name){
    text += ident + "name: <b>" + table.name + "</b>";
  } else {
    text += ident + "<i>no name</i>";
  }
  text += ident + "type: <b>" + table.type + "</b>";
  text += ident + "kind: <b>" + infoObject.kind + "</b>";
  text += ident + "number of lists: <b>" + table.length + "</b>";

  //text += ident + "all lists have same length: <b>" + (infoObject.sameLengths ? "true" : "false") + "</b>";

  if(infoObject.allListsSameLength) {
    text += ident + "all lists have length: <b>" + table[0].length + "</b>";
  } else {
    text += ident + "min length: <b>" + infoObject.minLength + "</b>";
    text += ident + "max length: <b>" + infoObject.maxLength + "</b>";
    text += ident + "average length: <b>" + infoObject.averageLength + "</b>";
    text += ident + "all lengths: <b>" + infoObject.lengths.join(", ") + "</b>";
  }

  text += "<hr>";
  infoObject.names.forEach(function(name, i){
    text += ident + "<font style=\"font-size:10px\">" +i + ":</f><b>" + name + "</b> <font color=\""+getColorFromDataModelType(infoObject.types[i])+ "\">" + TYPES_SHORT_NAMES_DICTIONARY[infoObject.types[i]]+"</f>";
  });
  text += "<hr>";

  if(infoObject.allListsSameType) {
    text += ident + "types of all lists: " + "<b>" + infoObject.types[0] + "</b>";
  } else {
    text += ident + "types: ";
    infoObject.types.forEach(function(type, i){
      text += "<b><font color=\""+getColorFromDataModelType(type)+ "\">" + type+"</f></b>";
      if(i<infoObject.types.length-1) text += ", ";
    });
  }

  if(infoObject.allListsSameKind) {
    text += ident + "<br>kinds of all lists: " + "<b>" + infoObject.kinds[0] + "</b>";
  } else {
    text += ident + "<br>kinds: ";
    infoObject.kinds.forEach(function(kind, i){
      text += "<b>"+kind+"</b>";
      if(i<infoObject.kinds.length-1) text += ", ";
    });
  }


  text += "<br>" + ident + "names: <b>" + infoObject.names.join("</b>, <b>") + "</b>";


  //list by list

  if(table.length < 501) {
    text += "<hr>";
    text +=  ident + "<font style=\"font-size:16px\"><b>lists reports</b></f>";

    var i;
    for(i = 0; table[i] != null; i++) {
      text += "<br>" + ident + i + ": " + (table[i].name?"<b>"+table[i].name+"</b>":"<i>no name</i>");
      try{
         text += ListOperators.getReportHtml(table[i], 1, infoObject.listsInfoObjects[i]);
      } catch(err){
        text += ident + "[!] something wrong with list <font style=\"font-size:10px\">:" + err + "</f>";
        console.log('getReportHtml err', err);
      }
    }
  }

  if(table.length == 2) {//TODO:finish
    text += "<hr>";
    text += ident + "<b>lists comparisons</b>";
    if(table[0].type=="NumberList" && table[1].type=="NumberList"){
      text += ident + "covariance:" + NumberOperators.numberToString(NumberListOperators.covariance(table[0], table[1]), 4);
      text += ident + "Pearson product moment correlation: " + NumberOperators.numberToString(NumberListOperators.pearsonProductMomentCorrelation(table[0], table[1]), 4);
    } else if(table[0].type!="NumberList" && table[1].type!="NumberList"){
      var nUnion = ListOperators.union(table[0], table[1]).length;
      text += ident + "union size: " + nUnion;
      var intersected = ListOperators.intersection(table[0], table[1]);
      var nIntersection = intersected.length;
      text += ident + "intersection size: " + nIntersection;

      if(infoObject.listsInfoObjects[0].frequenciesTable[0].length == nUnion && infoObject.listsInfoObjects[1].frequenciesTable[0].length == nUnion){
        text += ident + "[!] both lists contain the same non repeated elements";
      } else {
        if(infoObject.listsInfoObjects[0].frequenciesTable[0].length == nIntersection) text += ident + "[!] all elements in first list also occur on second list";
        if(infoObject.listsInfoObjects[1].frequenciesTable[0].length == nIntersection) text += ident + "[!] all elements in second list also occur on first list";
      }
      text += ident + "Jaccard distance: " + (1 - (nIntersection/nUnion));
    }
    //check for 1-1 matches, number of pairs, categorical, sub-categorical
    var subCategoryCase = ListOperators.subCategoricalAnalysis(table[0], table[1]);

    switch(subCategoryCase){
      case 0:
        text += ident + "no categorical relation found between lists";
        break;
      case 1:
        text += ident + "[!] both lists are categorical identical";
        break;
      case 2:
        text += ident + "[!] first list is subcategorical to second list";
        break;
      case 3:
        text += ident + "[!] second list is subcategorical to first list";
        break;
    }

    if(subCategoryCase!=1){
      text += ident + "information gain when segmenting first list by the second: "+NumberOperators.numberToString( ListOperators.getInformationGain(table[0], table[1]), 4);
      text += ident + "information gain when segmenting second list by the first: "+NumberOperators.numberToString( ListOperators.getInformationGain(table[1], table[0]), 4);
    }
  }

  ///add ideas to: analyze, visualize

  return text;
};

TableOperators.getReportObject = function() {}; //TODO

/**
 * Generates a Table containing details about the lists in the input table.
 * @param {Table} tab Table to generate report on.
 *
 * @param {Boolean} bMeasuresAcrossTop in output, defaults to true
 * @param {Number} iReportType type of report:<|>0:All fields (default)<|>1:Numeric columns, few fields<|>2:Old format (for retrofitting purposes)
 * @return {Table} Descriptive Table.
 * tags:analysis
 */
TableOperators.getReportTable = function(tab, bMeasuresAcrossTop, iReportType){
  var i,list,infoObject,temp;
  var t = new Table();
  if(tab == null || tab.length == 0)
    return null;
  bMeasuresAcrossTop = bMeasuresAcrossTop == null ? true : bMeasuresAcrossTop;
  iReportType = iReportType == null ? 0 : iReportType;

  if(!tab.isTable){
    if(tab.length && tab.length > 0 && tab[0].isTable){
      // we have a list of tables
      t = null;
      for(i=0; i < tab.length; i++){
        temp = TableOperators.getReportTable(tab[i],true, iReportType);
        var name = tab[i].name != '' ? tab[i].name : 'Table ' + i;
        var sLTab = ListGenerators.createListWithSameElement(temp[0].length,name,'subtable name');
        var tNames = new Table();
        tNames.push(sLTab);
        temp = tNames.concat(temp);
        t = t == null ? temp : TableOperators.concatRows(t, temp);
      }
      if(!bMeasuresAcrossTop){
        // combine table name with column name
        for(i=0; i < t[0].length; i++)
          t[1][i] = t[0][i] + ' - ' + t[1][i];
        t = t.getWithoutElementAtIndex(0);
        t = TableOperators.transpose(t,true,true);
        t[0].name = 'Characteristic';
      }
      return t;
    }
    else
      throw new Error('TableOperators.getReportTable: invalid input');
  }

  if(tab.name == '')
    t.name='Table Details';
  else
    t.name='Details for Table ' + tab.name;
  t.push(new StringList());
  t[0].name = 'Characteristic';
  var aListInfo = [];
  for(i=0;i<tab.length;i++){
    list = new List();
    list.name = tab[i].name;
    aListInfo[i] = ListOperators.buildInformationObject(tab[i]);
    if(tab[i].type=='NumberList'){
      aListInfo[i].median = tab[i].getMedian();
    }
    else{
      if(iReportType != 2){
        // for non-numeric lists use length of string elements for numeric quanities
        var nLLengths = tab[i].toStringList().getLengths();
        var infoObjectLengths = ListOperators.buildInformationObject(nLLengths);
        aListInfo[i].min = infoObjectLengths.min;
        aListInfo[i].max = infoObjectLengths.max;
        aListInfo[i].sum = infoObjectLengths.sum;
        aListInfo[i].average = infoObjectLengths.average;
        aListInfo[i].median = nLLengths.getMedian();
      }
      else{
        // aListInfo[i] may have been reused and contain info from code above
        // when iReportType == 0 or 1, to keep consistency clear it out
        aListInfo[i].min = null;
        aListInfo[i].max = null;
        aListInfo[i].sum = null;
        aListInfo[i].average = null;
        aListInfo[i].median = null;
      }
    }
    if(aListInfo[i].numberDifferentElements == undefined){
      infoObject=aListInfo[i];
      infoObject.frequenciesTable = tab[i].getFrequenciesTable(true, true, true);
      infoObject.numberDifferentElements = infoObject.frequenciesTable[0].length;
      infoObject.categoricalColors = infoObject.frequenciesTable[3];
    }
    if(aListInfo[i].entropy == undefined){
      aListInfo[i].entropy = ListOperators.getListEntropy(tab[i], null, aListInfo[i].frequenciesTable);
    }
    aListInfo[i].countNulls = tab[i].countElement(null);
    t.push(list);
  }
  t[0].push('column number');
  t[0].push('type');
  t[0].push('kind');
  t[0].push('length');
  t[0].push(iReportType == 2 ? 'numberDifferentElements' : 'count Different Elements');
  t[0].push('Contains Nulls');
  t[0].push('count Nulls');
  t[0].push('entropy');
  t[0].push('average');
  t[0].push('median');
  t[0].push('sum');
  t[0].push('min');
  t[0].push('max');
  t[0].push('standard Deviation');
  t[0].push('coefficient of Variation');

  t[0].push('1st Common Element');
  t[0].push('2nd Common Element');
  t[0].push('3rd Common Element');
  t[0].push(iReportType == 2 ? '1st Common Frequency' : '1st Common count');
  t[0].push(iReportType == 2 ? '2nd Common Frequency' : '2nd Common count');
  t[0].push(iReportType == 2 ? '3rd Common Frequency' : '3rd Common count');

  t[0].push('Row 1');
  t[0].push('Row 2');
  t[0].push('Row 3');
  t[0].push('Row n-2');
  t[0].push('Row n-1');
  t[0].push('Row n');
  t[0].push('Row Random');

  var valOrEmpty = function(val){
    if(val == undefined) return '';
    return val;
  };

  var maxDecimals = function(val,nDec){
    if(val == undefined) return '';
    return Number(NumberOperators.numberToString(val,nDec));
  };

  // get the random row number, use a seed so it is consistent for same table
  NumberOperators.randomSeed(tab[0].length);
  var nRandom = Math.floor(tab[0].length * NumberOperators.random());
  // restore previous random state in case this function was called inside some other stream of random numbers that we want consistent
  NumberOperators.randomSeedPop();

  for(i=0;i<tab.length;i++){
    t[i+1].push(i);
    t[i+1].push(tab[i].type);
    t[i+1].push(aListInfo[i].kind);
    t[i+1].push(aListInfo[i].length);
    t[i+1].push(aListInfo[i].numberDifferentElements);
    t[i+1].push(aListInfo[i].containsNulls ? 'true' : 'false');
    t[i+1].push(aListInfo[i].countNulls);
    t[i+1].push(maxDecimals(aListInfo[i].entropy,4));
    t[i+1].push(maxDecimals(aListInfo[i].average,2));
    t[i+1].push(maxDecimals(aListInfo[i].median,2));
    t[i+1].push(maxDecimals(aListInfo[i].sum,2));
    t[i+1].push(valOrEmpty(aListInfo[i].min));
    t[i+1].push(valOrEmpty(aListInfo[i].max));
    if(tab[i].type == 'NumberList'){
      var stdev = tab[i].getStandardDeviation();
      t[i+1].push(maxDecimals(stdev,4));
      if(aListInfo[i].average == 0)
        t[i+1].push(0);
      else
        t[i+1].push(maxDecimals(stdev/aListInfo[i].average,4));
    }
    else{
      t[i+1].push('');
      t[i+1].push('');
    }

    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[0][0]));
    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[0][1]));
    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[0][2]));
    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[1][0]));
    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[1][1]));
    t[i+1].push(valOrEmpty(aListInfo[i].frequenciesTable[1][2]));

    t[i+1].push(valOrEmpty(tab[i][0]));
    t[i+1].push(valOrEmpty(tab[i][1]));
    t[i+1].push(valOrEmpty(tab[i][2]));
    t[i+1].push(valOrEmpty(tab[i][aListInfo[i].length-3]));
    t[i+1].push(valOrEmpty(tab[i][aListInfo[i].length-2]));
    t[i+1].push(valOrEmpty(tab[i][aListInfo[i].length-1]));
    // random row
    t[i+1].push(valOrEmpty(tab[i][nRandom]));

  }
  if(iReportType == 1){
    t = TableOperators.transpose(t,true,true);
    t = mo.TableOperators.filterTable(t,'==','NumberList',2);
    t = t.getColumns([0,1,9,14]);
    t = TableOperators.transpose(t,true,true);
  }
  else if(iReportType == 2){
    // restore original order and skip the new ones
    t = t.getRows([0,1,2,3,4,8,9,10,11,12,7,13,14,15,16,17,18,19,20,21,22,23,24,25,26,5]);
  }

  if(bMeasuresAcrossTop){
    t = TableOperators.transpose(t,true,true);
    t[0].name = 'column name';
  }
  return t;
};

/**
* takes a table and simplifies its lists, numberLists will be simplified using quantiles values (using getNumbersSimplified) and other lists reducing the number of different elements (using getSimplified)
* the number of different values per List is reduced (maximum nCategorires) and by all efects they can be used as categorical
* specially useful to build simpe decision trees using TableOperators.buildDecisionTree
* @param {Table} table to be simplified
*
* @param  {Number} nCategories number of different elements on each list (20 by default)
* @param {Object} othersElement to be placed instead of the less common elements ("other" by default)
* @param {Number} quantilesMode 0:quantiles values (default)<|>1:quantiles intervals
* @return {Table}
* tags:transform
*/
TableOperators.getTableSimplified = function(table, nCategories, othersElement, quantilesMode) {
  if(table==null || !(table.length>0)) return null;
 nCategories = nCategories||20;

 var i;
 var l = table.length;
 var newTable = new Table();
 newTable.name = table.name;

 for(i=0; i<l; i++){
   newTable.push(
     table[i].type==='NumberList'?
     table[i].getNumbersSimplified(quantilesMode==1?6:2, nCategories)
     :
     table[i].getSimplified(nCategories, othersElement)
   );
 }

 return newTable.getImproved();
};


/**
* Concatenate the columns of two tables (with same number of columns)
* @param {Table} table0
* @param {Table} table1
* @return {Table}
* tags:combine
*/
TableOperators.concatTables = function(table0, table1){
  if(table0==null || table1==null || table0.length!=table1.length) return;

  var newT = new mo.Table();

  for(var i=0;i<table0.length;i++){
    newT[i] = table0[i].concat(table1[i]);
    newT[i].name = table0[i].name;
  }

  return newT.getImproved();
}


/**
* Concatenate all the rows of each table into one final table.
* If necessary all the columns within each table are padded to the same length with ''
* @param {Table} table0
* @param {Table} table1
*
* @param {Table} table2
* @param {Table} table3
* @param {Table} table4
* @param {Table} table5
* @return {Table}
* tags:combine
*/
TableOperators.concatRows = function() {
  if(arguments == null || arguments.length === 0 ||  arguments[0] == null) return null;
  if(arguments.length == 1) return arguments[0];

  var i,j,tab1,tabResult,namePrev;
  // find maximum number of cols
  var maxCols=0;
  for(i = 0; i<arguments.length; i++) {
    if(arguments[i] == null) continue;
    maxCols = Math.max(maxCols,arguments[i].length);
    if(!arguments[i].isTable){
      console.log('TableOperators.concatRows arguments must be tables.');
      return null;
    }
  }

  for(i = 0; i<arguments.length; i++) {
    if(arguments[i] == null) continue;
    tab1 = arguments[i];
    var nLLengths = tab1.getLengths();
    var maxLen = nLLengths.getMax();
    var minLen = nLLengths.getMin();
    if(maxLen != minLen){
      // complete the table so all cols have same length
      tab1 = TableOperators.completeTable(tab1,maxLen,null);
    }
    while(tab1.length < maxCols){
      tab1.push(ListGenerators.createListWithSameElement(maxLen,null,''));
    }
    if(i == 0)
      tabResult = tab1.clone();
    else{
      // concat each list
      for(j = 0; j<tabResult.length; j++){
        namePrev = tabResult[j].name; // concat loses name
        tabResult[j] = tabResult[j].concat(tab1[j]);
        tabResult[j].name = namePrev != '' ? namePrev : tab1[j].name;
      }
    }
  }

  return tabResult;
};


/**
 * concat into a single List all the Lists from the Table
 * @param  {table} Table
 * @return {List} list
 * tags:combine
 */
TableOperators.concatLists = function(table){
  if(table==null) return;

  var nL = table[0];
  for(var i=1; i <table.length; i++){
    nL = nL.concat(table[i]);
  }

  //note: slightly more efficient with _concat (only one getImproved())
  return nL;
}

/**
 * unions all the lists from a Table
 * @param  {table} Table
 * @return {List} list without repeated elements
 * tags:combine
 */
TableOperators.unionListsFromTables = function(table){
  if(table==null) return;

  var list = table[0].getWithoutRepetitions();
  var i, j;
  var l = table.length;
  var n;

  for(i=1; i<l; i++){
    n = table[i].length;
    for(j=0; j<n; j++){
      if(!list.includes(table[i][j])) list.push(table[i][j]);
    }
  }

  return list;
};


/**
 * intersects all the lists from a Table
 * @param  {table} Table
 * @return {List} list with elements persent in all lists
 * tags:compare
 */
TableOperators.intersectListsFromTables = function(table){
  if(table==null) return;

  var list = table[0].getWithoutRepetitions();
  var i;
  var l = table.length;

  for(i=1; i<l; i++){
    list = ListOperators.intersection(list, table[i]);
  }

  return list;
};


/**
 * Calculate the Uncertainty Coefficient (also called Theil's U), a measure of categorical association with value in [0,1]
 * @param  {List} list0
 * @param  {List} list1
 *
 * @param  {Number} direction 0:Symmetric (default)<br>1:list1 to list2<br>2:list2 to list1<br>3:NumberList of results [symmetric,list1 to list2,list2 to list1]
 * @return {Number} coefficient in range [0,1] where 0 represents not associated at all and 1 represents perfectly associated
 * tags:statistics,advanced
 */
TableOperators.uncertaintyCoefficient = function(list0, list1, iDirection){
  // this really belongs in ListOperators but putting it there and adding import statements causes everything to break
  // algorithm based on https://github.com/danielmarcelino/SciencesPo/blob/master/R/TESTS.R
  if(list0==null || list1==null || list0.length != list1.length) return;
  if(list0.length == 0) return 0;
  iDirection = iDirection == null ? 0 : iDirection;
  var i,j;
  var len=list0.length;
  // pivotTable was used at first but far too slow for lots of combinations.
  var v0,v1,v01,val,valinner;
  var o0 = {};
  var o1 = {};
  for(i=0;i<len;i++){
    v0 = o0[list0[i]];
    if(v0 == null){
      v0 = o0[list0[i]] = {count:0, vals:{}};
    }
    v0.count++;
    v01 = v0.vals[list1[i]];
    if(v01 == null){
      v01 = v0.vals[list1[i]] = {count:0};
    }
    v01.count++;

    v1 = o1[list1[i]];
    if(v1 == null){
      v1 = o1[list1[i]] = {count:0, vals:{}};
    }
    v1.count++;
    v01 = v1.vals[list0[i]];
    if(v01 == null){
      v01 = v1.vals[list0[i]] = {count:0};
    }
    v01.count++;
  }
  var total = list0.length;
  var nLColSumsByTotal = new NumberList();
  var nLColSumsLog = new NumberList();
  var hxySum = 0;
  for(var key in o0){
    if(!o0.hasOwnProperty(key)) continue;
    val = o0[key].count/total;
    nLColSumsByTotal.push(val);
    nLColSumsLog.push(Math.log(val));
    for(var keyinner in o0[key].vals){
      if(!o0[key].vals.hasOwnProperty(keyinner)) continue;
      valinner = o0[key].vals[keyinner].count/total;
      hxySum += valinner*Math.log(valinner);
    };
  };
  var nLRowSumsByTotal = new NumberList();
  var nLRowSumsLog = new NumberList();
  for(var key in o1){
    if(!o1.hasOwnProperty(key)) continue;
    val = o1[key].count/total;
    nLRowSumsByTotal.push(val);
    nLRowSumsLog.push(Math.log(val));
  };

  var HY = -(nLColSumsByTotal.factor(nLColSumsLog).getSum());
  var HX = -(nLRowSumsByTotal.factor(nLRowSumsLog).getSum());

  var HXY = -(hxySum);
  var UCs = 2*(HX+HY-HXY)/(HX+HY);
  var UCrow = (HX+HY-HXY)/HX;
  var UCcol = (HX+HY-HXY)/HY;
  // Use a reasonable precision
  UCs  =Number(NumberOperators.numberToString(UCs,4));
  UCrow=Number(NumberOperators.numberToString(UCrow,4));
  UCcol=Number(NumberOperators.numberToString(UCcol,4));
  switch(iDirection){
    case 0:
      return UCs;
    case 1:
      return UCrow;
    case 2:
      return UCcol;
    case 3:{
      var nLret = new NumberList();
      nLret.push(UCs);
      nLret.push(UCrow);
      nLret.push(UCcol);
      return nLret;
    }
    default:
      throw new Error("TableOperators.uncertaintyCoefficient - invalid value for iDirection: "+iDirection);
  }
};

/**
 * Find the cell nearest some target col,row location with a particular value
 * @param  {Table} table to search
 * @param  {Number} colTarget is the target column location
 * @param  {Number} rowTarget is the target row location
 * @param  {Object} value is the value to find. Typically a string or number.
 *
 * @param  {Boolean} bNotEqual if true return nearest cell != value (default false)
 * @return {NumberList} NumberList with col and row coordinates of closest cell to target containing the desired value
 * tags:statistics,advanced
 */
TableOperators.findNearestCellWithValue = function(table, colTarget, rowTarget, value, bNotEqual){
  var nLCoords = new NumberList();
  if(table==null) return null;
  bNotEqual = bNotEqual == null ? false : bNotEqual;
  // assumes all lists are same length
  if(colTarget < 0) colTarget=0;
  if(colTarget >= table.length) colTarget=table.length-1;
  if(rowTarget < 0) rowTarget=0;
  if(rowTarget >= table[0].length) rowTarget=table[0].length-1;

  if((bNotEqual && table[colTarget][rowTarget] != value) || (!bNotEqual && table[colTarget][rowTarget] == value) ) return NumberList.fromArray([colTarget,rowTarget]);
  var r = 1;
  var nChecked = 1;
  var nCells = table.length * table[0].length;
  var col,row;
  var dir = 0; // 0 - right, 1 - down, 2 - left, 3 - up
  col = colTarget-r;
  row = rowTarget-r;
  while(nChecked < nCells){
    // we are traversing the outer edges of the square r units from the target
    // test for out of range coords
    if(col >= 0 && col < table.length && row >= 0 && row < table[col].length){
      if((bNotEqual && table[col][row] != value) || (!bNotEqual && table[col][row] == value)) return NumberList.fromArray([col,row]);
      nChecked++;
    }
    if(dir == 0){ // right
      col++;
      if(col > colTarget+r){
        dir++; // change to go down
        col--;
        row++;
      }
    }
    else if(dir == 1){ // down
      row++;
      if(row > rowTarget+r){
        dir++; // change to go left
        row--;
        col--;
      }
    }
    else if(dir == 2){ // left
      col--;
      if(col < colTarget-r){
        dir++; // change to go up
        col++;
        row--;
      }
    }
    else if(dir == 3){ // up
      row--;
      if(row <= rowTarget-r){ // need = to make sure we do not check original starting point
        // done for this value of r, increase
        dir = 0;
        r++;
        col = colTarget-r;
        row = rowTarget-r;
      }
    }
  }
  // checked all the cells without finding any with desired value
  return null;
};



/**
 * builds the vector of values from a data table and the complete list of categories
 * @param {Table} dataTable with categories and values
 * @param {List} allCategories complete list of categories
 *
 * @param {Object} allCategoriesIndexesDictionary optional dictionary of indexes if pre-calculated
 * @return {NumberList} values for each category from complete categorical list
 * tags:
 */
TableOperators.numberListFromDataTable = function(dataTable, allCategories, allCategoriesIndexesDictionary){
  if(dataTable==null || allCategories==null) return;

  var i;
  var l = dataTable[0].length;

  allCategoriesIndexesDictionary = allCategoriesIndexesDictionary==null?ListOperators.getSingleIndexDictionaryForList(allCategories):allCategoriesIndexesDictionary;

  var nL = ListGenerators.createListWithSameElement(allCategories.length, 0);
  var index;

  for(i=0; i<l; i++){
    index = allCategoriesIndexesDictionary[dataTable[0][i]];
    if(index!=null) nL[index] = dataTable[1][i];
  }

  return nL;
};

/**
 * anonymize lists from a table, using several possible methods
 * @param  {Table} table containing the lists to be anonymized
 *
 * @param  {NumberList} nLColumnsToModify columns to modify, if not specified then all columns are modified
 * @param  {NumberList} nLColumnModes is a list of explicit modes to use. This overrides modeCategory and modeNumeric. Options:<br>0: leave as it is<br>1: generate unique random string<br>2: generate unique random integer<br>3: shuffle existing data elements<br>4: random numbers in same interval<br>5: random numbers from comparable normal distribution<br>6: simplify numeric values to fewer sig digits<br>7: add noise to numbers based on small fraction of standard dev<br>8: generate unique alpha code<br>9: add noise to numbers but keep consistent ranking
 * @param  {Number|String|StringList} modeCategory to use for strings or categories. Options:<br>0: leave as it is<br>1: generate unique random string<br>2: generate unique random integer<br>3: shuffle existing data elements<br>8: generate unique alpha code<br>built-in list: one of [greek, female names, male names, names, cities, fruit, lorem]<br>Custom StringList
 * @param  {Number} modeNumeric to use for numeric columns. Options:<br>0: leave as it is<br>3: shuffle existing data elements<br>4: random numbers in same interval<br>5: random numbers from comparable normal distribution<br>6: simplify numeric values to fewer sig digits<br>7: add noise to numbers based on small fraction of standard dev<br>9: add noise to numbers but keep consistent ranking
 * @param  {Number} modeColumnNames to use for names of columns. Options:<br>0: leave as it is (default)<br>1: generate unique random string<br>2: generate unique random integer<br>3: shuffle existing data elements<br>4: replace by generic form 'Column n'
 * @param  {Number} seed to use for randomization, if not specified then results won't be repeatable
 * @param  {Boolean} bConsistentRemapping if true use consistent remapping across different categorical columns (default:true)
 * @param  {Boolean} bScrambleRowOrder if true scramble the order of the resulting rows (default:false)
 * @param  {Boolean} bScrambleColumnOrder if true scramble the order of the resulting columns (default:false)
 * @param  {Object} dictionary object of remappings to use for categories, if bConsistentRemapping is true will be modified with new mappings also
 * @return {Table} anonymized table
 * tags:transform
 */
TableOperators.anonymizeTable = function(table, nLColumnsToModify, nLColumnModes, modeCategory, modeNumeric, modeColumnNames, seed, bConsistentRemapping, bScrambleRowOrder, bScrambleColumnOrder, dict){
  if(table==null) return;
  if(nLColumnsToModify == null)
    nLColumnsToModify = NumberListGenerators.createSortedNumberList(table.length);
  if(nLColumnModes != null && nLColumnModes.length != nLColumnsToModify.length)
    throw new Error('nLColumnModes list must be same length as nLColumnsToModify');
  bConsistentRemapping = bConsistentRemapping == null ? true: bConsistentRemapping;
  modeColumnNames = modeColumnNames == null ? 0 : modeColumnNames;

  if(seed != null)
    NumberOperators.randomSeed(seed);

  var infoObject = TableOperators.buildInformationObject(table);
  var newTable = table.cloneWithEmptyLists();

  var i,mode,sLValues,L,dict0;
  if(!bConsistentRemapping && dict != null){
    // save a shallow copy of initial dict
    dict0 = {};
    for(var key in dict)
      dict0[key] = dict[key];
  }
  if(bConsistentRemapping && dict == null)
    dict = {};

  for(i=0;i<table.length;i++){
    if(!nLColumnsToModify.includes(i)){
      newTable[i] = table[i].clone();
      continue;
    }
    // modify it
    if(infoObject.listsInfoObjects[i].isCategorical || table[i].type != 'NumberList'){
      // category
      mode = modeCategory;
      if(modeCategory == null){
        mode = 1;
        if(table[i].type == 'NumberList')
          mode = 2;
      }
      sLValues = null;
      if(typeOf(modeCategory) == 'StringList' || typeOf(modeCategory) == 'string'){
        mode = 8; 
        sLValues = modeCategory;
      }
      if(nLColumnModes != null){
        mode = nLColumnModes[i];
      }
      L = ListOperators.anonymizeList(table[i],mode,dict,sLValues);
      if(dict0 != null && !bConsistentRemapping){
        // set it back to copy
        dict = {};
        for(var key in dict0)
          dict[key] = dict0[key];
      }
      newTable[i] = L;
      newTable[i].name = table[i].name;
    }
    else{
      // numeric non-category
      mode = modeNumeric; // if null then ListOperators.anonymizeList will choose
      if(nLColumnModes != null){
        mode = nLColumnModes[i];
      }
      L = ListOperators.anonymizeList(table[i],mode,null,sLValues);
      newTable[i] = L;
      newTable[i].name = table[i].name;
    }
  }
  // handle column names
  var LNames = newTable.getNames();
  switch(modeColumnNames){
    case 0:
      // do nothing
      break;
    case 1:
    case 2:
    case 3:
      L = ListOperators.anonymizeList(LNames,modeColumnNames);
      for(i = 0; i < table.length; i++){
        if(nLColumnsToModify.includes(i))
          newTable[i].name = L[i];
      }
      break;
    case 4:
      // do nothing for now. Will be handled after potential column reordering
      break;
    default:
      throw new Error('Invalid value for modeColumnNames:' + modeColumnNames);
  }

  if(bScrambleColumnOrder)
    newTable = newTable.getSortedRandom();
  if(modeColumnNames == 4){
    for(i = 0; i < table.length; i++){
      if(nLColumnsToModify.includes(i))
        newTable[i].name = 'Column ' + (i+1);
    }
  }

  if(bScrambleRowOrder){
    var nLNum = NumberListGenerators.createSortedNumberList(newTable[0].length);
    nLNum = nLNum.getSortedRandom();
    newTable = newTable.getRows(nLNum);
  }

  if(seed != null)
    NumberOperators.randomSeedPop();

  return newTable;
};

/**
 * Generates an HTML block giving summary of a row or subset of rows
 * from the Table.
 * @param {Table} table Table to generate report on.
 *
 * @param {Number|NumberList} iRow is a row index or a numberList of rows for the table (Default: 0)
 * @param {Number|String|StringList} title specifies the title for the data.<br>number: a column index<br>string: a column name or the title text<br>StringList: each string is listed in title
 * @param {Number|String|StringList} image specifies an image URL for the data.<br>number: a column index<br>string: a column name or the URL<br>StringList: a list of URLs, one per row
 * @param {String} clrBackground is the background color (Default:White)
 * @param {String} clrForeground is the foreground color (Default:inverse of clrBackground)
 * @param {Number} fontSize is the size in pts (Default:12pt)
 * @param {String} style is CSS text applied to a div surrounding the output (Default: 'margin: 0px 5px;')
 * @param {NumberList} nLModesByKind is a NumberList of display modes in the order [categories,numbers,strings]. Default is [6,2,0] Modes:<br>0:first element (default)<br>1:sum<br>2:average<br>3:min<br>4:max<br>5:list concatenated with commas<br>6:most common element<br>7:unique count<br>8:unique concatenated with commas<br>9:range of values<br>10:median<br>11:up to 3 items from list then count of remaining<br>12:item frequency table inline (max 5 elements)<br>13:word rate/100 table inline (max 5 elements)
 * @param {NumberList} nLModesByColumn is a NumberList of specific display modes for each column. If specified these override nLModesByKind. Modes:<br>0:first element (default)<br>1:sum<br>2:average<br>3:min<br>4:max<br>5:list concatenated with commas<br>6:most common element<br>7:unique count<br>8:unique concatenated with commas<br>9:range of values<br>10:median<br>11:up to 3 items from list then count of remaining<br>12:item frequency table inline (max 5 elements)<br>13:word rate/100 table inline (max 5 elements)
 * @param {String} hImage is the height of any images displayed (default:150px)
 * @param {Boolean} bShowComparison if true will show full table context for each item (default:true)
 * @return {String} HTML output.
 * tags:
 */
TableOperators.elementInfoHTML = function(table,iRow,title,image,clrBackground,clrForeground,fontSize,style,nLModesByKind,nLModesByColumn,hImage,bShowComparison) {
  if(table==null) return;
  if(table.isTable == null || !table.isTable) throw new Error('Input must be a table');
  iRow = iRow == null ? 0 : iRow;
  TableOperators.buildInformationObject(table,true);
  var rowType = typeOf(iRow);
  if(rowType == 'string'){
    if(!isNaN(iRow) && Number(iRow) >=0 && Number(iRow) < table[0].length){
      rowType = 'number';
      iRow = Number(iRow);
    }
  }
  if(rowType != 'number' && rowType != 'NumberList') throw new Error('Second inlet must be row number or NumberList');
  if(style == null)
    style = 'margin: 0px 5px;';
  if(clrBackground == null || clrBackground == ''){
    if(clrForeground == null || clrForeground == '')
      clrBackground = 'rgb(255,255,255)';
    else
      clrBackground = ColorOperators.invertColor(clrForeground);
  }
  if(clrForeground == null || clrForeground == '')
      clrForeground = ColorOperators.invertColor(clrBackground);

  if(nLModesByKind == null) nLModesByKind = NumberList.fromArray([6,2,0]);
  if(nLModesByKind.length != 3) throw new Error('nLModesByKind must have 3 items');
  if(nLModesByColumn != null && nLModesByColumn.length != table.length)
    throw new Error('nLModesByColumn must have 1 item for each column in the input table. nLModesByColumn has '+nLModesByColumn.length+' items but column count is '+table.length);
  hImage = hImage == null ? '150px' : hImage;
  bShowComparison = bShowComparison == null ? true : bShowComparison;

  var sHTML = '';
  var i,j,s0,row,mode,valSubList,valFullList;
  var sSizeAndColors = '';

  if(clrBackground != null)
    sSizeAndColors += ' background-color: ' + clrBackground + ';';
  if(clrForeground != null)
    sSizeAndColors += ' color: ' + clrForeground + ';';
  if(fontSize != null)
    sSizeAndColors += ' font-size: ' + fontSize + 'pt;';
  if(sSizeAndColors != '')
    sHTML += '<div style="' + sSizeAndColors + '">\n';

  if(style != null)
    sHTML += '<div style="' + style + '">\n';

  var sColorDeEmphasize = '';
  if(clrBackground != null && clrForeground != null)
    sColorDeEmphasize = ColorOperators.interpolateColors(clrForeground,clrBackground,.25);

  if(typeOf(iRow) == 'number'){
    // put in a numberlist
    j = iRow;
    iRow = new NumberList();
    iRow.push(j);
    rowType = 'NumberList';
  }

  var tableSubset = table.getRows(iRow);

  var LTitle = null;
  var bHasTitle = false;
  if(title != null){
    var sTitleText = '';
    if(title.isList){
      sTitleText = title.join('<br>');
    }
    else{
      LTitle = tableSubset.getColumn(title,null,null,true);
      if(LTitle == null)
        sTitleText = title;
      else{
        sTitleText = ListOperators.characterizeList(LTitle,11);
      }
    }
    sHTML += '<span style="font-size: 200%;">';
    sHTML += sTitleText + '<br></span>\n';
    bHasTitle = true;
  }

  // images
  var LImage = null;
  if(image != null){
    var sTitleText = '';
    if(!image.isList){
      LImage = tableSubset.getColumn(image,null,null,true);
      if(LImage == null){
        LImage = StringList.fromArray([image]);
      }
    }
  }
  if(LImage != null){
    sHTML += '<br>';
    for(i=0;i<LImage.length;i++){
      sHTML += '<img src="' + LImage[i] + '" height = "' + hImage + '">';
    }
    bHasTitle = true;
  }

  if(bHasTitle)
    sHTML += '<br>';

  for(i=0; i < tableSubset.length;i++){
    if(LTitle == tableSubset[i]) continue; // we already emitted as title
    if(LImage == tableSubset[i]) continue; // we already emitted as images
    s0 = tableSubset[i].name;
    if(s0 == null || s0 == '') s0 = 'Column ' + (i+1);
    if(sColorDeEmphasize != '')
      sHTML += '<span style="color:'+sColorDeEmphasize+'">' + s0 + ': </span>';
    else
      sHTML += s0 + ': ';
    // get mode
    mode = 0;
    if(nLModesByColumn != null)
      mode = nLModesByColumn[i];
    else{
      if(table[i].infoObject.kind == 'categories')
        mode = nLModesByKind[0];
      else if(table[i].infoObject.kind == 'numbers' || table[i].infoObject.kind == 'integer numbers')
        mode = nLModesByKind[1];
      else if(table[i].infoObject.kind == 'strings')
        mode = nLModesByKind[2];
    }
    if(isNaN(mode) || mode % 1 != 0 || mode < 0 || mode > 13) throw new Error('Mode must be an integer between 0 and 13');
    var bDetailsOnSubset = !bShowComparison;
    valSubList = ListOperators.characterizeList(tableSubset[i],mode,bDetailsOnSubset);
    if(tableSubset[0].length == 1 && mode != 13)
      valSubList = tableSubset[i][0];
    valFullList = ListOperators.characterizeList(table[i],mode,true);
    if(bShowComparison)
      sHTML += valSubList + '|' + valFullList + '<br>\n';
    else
      sHTML += valSubList + '<br>\n';
  }

  if(style != null)
    sHTML += '</div>';
  if(sSizeAndColors != '')
    sHTML += '</div>';
  return sHTML;
};

/**
 * second and simpler version of elementInfoHTML, builds an html info panel, with info about selected element(s), taking into account the Table they/it were extracted
 * @param {Table} table Table to generate report on.
 * @param {Number|NumberList} indexes or index of selected row(s)
 *
 * @param {List} xList selected list for x axis (in Scatter Space or other modules)
 * @param {List} yList selected list for y axis (in Scatter Space or other modules)
 * @param {List} sList selected list for sizes (in Scatter Space or other modules)
 * @param {List} cList selected list for colors (in Scatter Space or other modules)
 * @return {String} HTML output
 * tags:
 */
TableOperators.elementInfoHTML2 = function(table, indexes, xList, yList, sList, cList){
  if(table==null) return;

  var indexesXYSC = table.indexesOfElements([xList, yList, sList, cList]);
  var sortL = new mo.NumberList();

  for(var i=0; i<table.length; i++){
    switch(table[i]){
      case xList:
        sortL[i] = 0;
        break;
      case yList:
        sortL[i] = 1;
        break;
      case sList:
        sortL[i] = 2;
        break;
      case cList:
        sortL[i] = 3;
        break;
      default:
        sortL[i] = 4+i;
    }
  }

  table = table.getSortedByList(sortL);

  var comparativeTable = TableOperators.rowsReportTable(table, indexes);

  var labels = table.getLabels();
  var descriptions = table.getDescriptions();

  var html = "<frgb180.180.180><fs13>";
  var value;
  var average;
  var globalAverage;
  var decile;
  var common;
  var all;
  var differentAll;
  var different;
  var rank;
  var insert;
  
  for(var i=0; i<comparativeTable[0].length; i++){
    insert = "";
    if(table[i]==xList) insert += "<fcRed><b>X</b>:</f> ";
    if(table[i]==yList) insert += "<fcRed><b>Y</b>:</f> ";
    if(table[i]==sList) insert += "<fcRed><b>S</b>:</f> ";
    if(table[i]==cList) insert += "<fcRed><b>C</b>:</f> ";

    html+="<br><b><frgb180.180.180>"+insert+labels[i]+"</f></b>";
    globalAverage = comparativeTable.getColumn("average");
    if(comparativeTable.multiple){
        if(comparativeTable[1][i]=="NumberList"){
            average = comparativeTable.getColumn("SELECTED average");
            
            html+=" mean: <b><fcWhite>"+average[i]+"</f></b>/<fs10>"+globalAverage[i]+"</f>";
        } else {
            common = comparativeTable.getColumn("SELECTED 1st Common Element");
            all = comparativeTable.getColumn("SELECTED all values html");
            different = comparativeTable.getColumn("SELECTED numberDifferentElements");
            differentAll = comparativeTable.getColumn("numberDifferentElements");
            html+=" <fcWhite>"+all[i]+"</f> <fs10> among "+different[i]+"/"+differentAll[i]+"</f>";
        }
    } else {
        value = comparativeTable.getColumn("SELECTED values");
        if(comparativeTable[1][i]=="NumberList"){
            decile = comparativeTable.getColumn("SELECTED decile rank");
            html+=": <b><fcWhite>"+value[i]+"</f></b> <fs10>"+mo.NumberOperators.numberToPosition(decile[i])+" decile</f>";
        } else {
            rank = comparativeTable.getColumn("SELECTED occurrences rank");
            html+=": <b><fcWhite>"+value[i]+"</f> <fs10>"+mo.NumberOperators.numberToPosition(rank[i])+" in rank</f>";
        }
    }
    if(insert!="" && descriptions[i]!=null) html+= "<br>"+descriptions[i]+"</b>";
  }
  
  html+="</f></f>";
  return html;
}

/**
 * encode string columns as numeric features, existing numeric columns will remain unchanged
 * @param {Table} table to operate on
 *
 * @param {Number} method to use<|>0: one hot encoding (default)<|>1: ordinal encoding<|>2: binary encoding<|>3: one hot encoding, keep all
 * @param {Number} maxUniqueValues if a string column has more than this many unique values then it will not be encoded numerically (default:20)
 * @param {Boolean} bRemove Remove columns that cannot be encoded numerically (default:true)
 * @return {Table} tResult with numerical encoding
 * tags:machine-learning
 */
TableOperators.encodeStringLists = function(table, method, maxUniqueValues, bRemove){
  if(table==null) return;

  method = method == null ? 0 : method;
  maxUniqueValues = maxUniqueValues == null ? 20 : maxUniqueValues;
  bRemove = bRemove == null ? true : bRemove;

  var tResult = new Table();
  // builds table[i].infoObject
  TableOperators.buildInformationObject(table,true);
  var i,j,tt,bLeaveAlone,list;
  for(i=0;i < table.length;i++){
    bLeaveAlone = false;
    list = table[i];
    if(list.type == 'NumberList'){
      tResult.push(list.clone());
      continue;
    }
    if(list.type != 'StringList'){
      list = list.toStringList();
      ListOperators.buildInformationObject(list);
    }
    // is a StringList now for sure
    if(list.infoObject.numberDifferentElements < 2 ||
       list.infoObject.numberDifferentElements > maxUniqueValues)
      bLeaveAlone = true;
    else{
      tt = StringListOperators.encodeStringsAsNumericFeatures(list,method);
      for(j=0;j < tt.length;j++){
        tt[j].name = list.name + ':' + tt[j].name;
      }
      tResult = ListOperators.concat(tResult,tt);
    }
    if(bLeaveAlone && !bRemove)
      tResult.push(list.clone());
  }
  return tResult;
};

/**
 * deprecated, use transformDateLists
 * @param {Table|List} table to operate on or a single list with items encoded as dates
 *
 * @param {NumberList} list of encodings to use for each date. (default: [0,1,2,3,4,7,8])List elements can be: <br>0: year<br>1: month<br>2: day of month<br>3: day of week (0 is sunday)<br>4: hour<br>5: minute<br>6: second<br>7: decimal date<br>8: day number of year<br>9: quarter<br>10: decimal hours<br>11: day indicator columns(adds 7)<br>12: month indicator columns(adds 12)<br>13: day of week name<br>14: month name<br>15: week number in year<br>16: day number(use inlet below to set day 0)
 * @param {Boolean} bIgnoreExistingListNames when true do not use the existing list name to prefix output list names (default:false)
 * @param  {Date|String} startDate is start of day numbering(Default: 2000-01-01)<br>Can be Date object or string.<br>Can also be a DateList or StringList of dates in which case the earliest date is used as the reference.<br>Can also be the string 'earliest' in which case the earliest date in each list is used.
 * @return {Table} tResult with date encodings added
 * tags:deprecated
 * replacedBy:transformDateLists
 */
TableOperators.encodeDateLists = function(t,nL,bIgnoreExistingListNames,dStart) {
  return TableOperators.transformDateLists(t,nL,bIgnoreExistingListNames,dStart);
};

/**
 * encode DateLists,StringLists with valid dates, and NumberLists with decimal dates as new numeric feature lists at the end of the table
 * @param {Table|List} table to operate on or a single list with items encoded as dates
 *
 * @param {NumberList} list of encodings to use for each date. (default: [0,1,2,3,4,7,8])List elements can be: <br>0: year<br>1: month<br>2: day of month<br>3: day of week (0 is sunday)<br>4: hour<br>5: minute<br>6: second<br>7: decimal date<br>8: day number of year<br>9: quarter<br>10: decimal hours<br>11: day indicator columns(adds 7)<br>12: month indicator columns(adds 12)<br>13: day of week name<br>14: month name<br>15: week number in year<br>16: day number(use inlet below to set day 0)
 * @param {Boolean} bIgnoreExistingListNames when true do not use the existing list name to prefix output list names (default:false)
 * @param  {Date|String} startDate is start of day numbering(Default: 2000-01-01)<br>Can be Date object or string.<br>Can also be a DateList or StringList of dates in which case the earliest date is used as the reference.<br>Can also be the string 'earliest' in which case the earliest date in each list is used.
 * @return {Table} tResult with date encodings added
 * tags:dates,conversion,encode,transform,extract
 * examples:jeff/examples/transformDateLists
 */
TableOperators.transformDateLists = function(t,nL,bIgnoreExistingListNames,dStart) {
  // look for any date-like columns and add some useful new lists based on them
  if(t == null) return null;
  bIgnoreExistingListNames = bIgnoreExistingListNames == null ? false : bIgnoreExistingListNames;
  if(!t.isTable){
    if(t.isList){
      // wrap it in a table and continue
      var temp = new Table();
      temp.push(t.clone());
      t = temp;
    }
    else
      throw new Error('Invalid input for encodeDateLists');
  }
  nL = nL == null ? [0,1,2,3,4,7,8] : nL;
  var tOut = t.clone();
  var i,j,tdt,L,dt0,sPrefix,s;
  for(i=0; i<t.length; i++){
    L = t[i];
    if(L.type == 'StringList'){
      // see if it is a date
      dt0 = new Date(L[0]);
      if(isNaN(dt0)) continue; // invalid date
      L = StringListConversions.toDateList(L);
      // check to make sure they are all valid dates
      var bValid = true;
      for(j=0;j<L.length;j++){
        if(isNaN(L[j]) || !isNaN(t[i][j])){
          bValid=false;
          break;
        }
      }
      if(!bValid)continue;
    }
    else if(L.type == 'NumberList'){
      var bValidDates = true;
      var bAllInteger = true;
      for(j=0; j < L.length;j++){
        bAllInteger = bAllInteger && Number.isInteger(L[j]);
        s = String(L[j]);
        // if it is not exactly 4 digits before an optional decimal then assume not a date
        if(!s.match(/^\d{4}(\.|$)/)){
          bValidDates = false;
          break;
        }
      }
      if(bValidDates && !bAllInteger)
        L = DateOperators.convertFromDecimalDate(L);
      else
        continue;
    }
    if(L.type != 'DateList') continue;
    tdt=DateListOperators.transformDateList(L,nL,null,dStart);
    sPrefix = L.name === '' ? '' : L.name + ' ';
    if(bIgnoreExistingListNames)
      sPrefix = '';
    for(j=0;j<tdt.length;j++){
        // if table already contains this list do not add
        tdt[j].name = sPrefix + tdt[j].name;
        if(t.getElementByName(tdt[j].name) === null)
            tOut.push(tdt[j]);
    }
  }
  return tOut;
};

/**
 * encode the columns as numeric features, including categorical strings, long texts, and dates. Also produces a parameter object so that function can be run on different data of the same structure
 * @param {Table|List} table to operate on, can also be a single list
 *
 * @param {Object} Parameters to use to recreate process of a previous run. If specified then all other inlets are ignored
 * @param {Boolean} bNormalizeNumerics If true z-normalize columns that start as numerics (default:true)
 * @param {Number} method to use for categories<|>0: one hot encoding<|>1: ordinal encoding<|>2: binary encoding<|>3: one hot encoding, keep all(default)
 * @param {Number} maxUniqueValues if a string column has more than this many unique values then it will not be encoded categorically (default:20)
 * @param {NumberList} list of encodings to use for each date. (default: [0,1,2,3,4,7,8,10])List elements can be: <br>0: year<br>1: month<br>2: day of month<br>3: day of week (0 is sunday)<br>4: hour<br>5: minute<br>6: second<br>7: decimal date<br>8: day number in year<br>9: quarter<br>10: decimal hours<br>11: day indicator columns(adds 7)<br>12: month indicator columns(adds 12)
 * @param {Object} stopWords Words to be ignored in long text columns. Possible values:<br>false: keep all words(default)<br>true: ignore a standard list of stop words<br>StringList: pass in a specific list of words to ignore
 * @param {String} textDelimiter delimiter to use for separating long text lists (default: ' ')
 * @param {NumberList} weights to use for each input column. If specified then resulting columns are first z-normalized before the weights are applied. A factor is also applied based on the number of columns resulting for each input column
 * @return {Table} tResult with numerical encoding
 * @return {Object} oParameters to rerun on different input
 * tags:machine-learning
 * examples:jeff/examples/encodeTableColumnsAsNumbers
 */
TableOperators.encodeTableColumnsAsNumbers = function(table, parms, bNormalizeNumerics, methodCategories, maxUniqueValues, listDateEncodings, stopWords, textDelimiter, weights){
  if(table==null) return;
  // if we have a simple list then wrap it in a table
  if(table.isList && !table.isTable){
    var temp = new mo.Table();
    temp.push(table.clone());
    table = temp;
  }
  if(!table.isTable)
    throw new Error('encodeTableColumnsAsNumbers: Invalid input in first inlet.');

  methodCategories = methodCategories == null ? 3 : methodCategories;
  maxUniqueValues = maxUniqueValues == null ? 20 : maxUniqueValues;
  listDateEncodings = listDateEncodings == null ? [0,1,2,3,4,7,8,10] : listDateEncodings;
  stopWords = stopWords == null ? false : stopWords;
  bNormalizeNumerics = bNormalizeNumerics == null ? true : bNormalizeNumerics;
  textDelimiter = textDelimiter == null ? ' ' : textDelimiter;
  var oParms, bUsingParms = false;
  if(parms == null){
    oParms = {
      type : 'encodeTableColumnsAsNumbers',
      methodCategories : methodCategories,
      maxUniqueValues : maxUniqueValues,
      listDateEncodings : listDateEncodings,
      stopWords : (stopWords.isList ? stopWords.clone() : stopWords),
      bNormalizeNumerics : bNormalizeNumerics,
      columnInfo : [],
      outputToInputColumnMapping : [],
      textDelimiter : textDelimiter,
      weights : weights
    };
  }
  else{
    oParms = parms;
    bUsingParms = true;
    // override parm values
    methodCategories = oParms.methodCategories;
    maxUniqueValues = oParms.maxUniqueValues;
    listDateEncodings = oParms.listDateEncodings;
    stopWords = oParms.stopWords;
    bNormalizeNumerics = oParms.bNormalizeNumerics;
    textDelimiter = oParms.textDelimiter;
    weights = oParms.weights;
  }
  if(oParms.type != 'encodeTableColumnsAsNumbers')
    throw new Error('Invalid parms object in second inlet of encodeTableColumnsAsNumbers');
  if(bUsingParms && oParms.columnInfo.length != table.length)
    throw new Error('Parms object in second inlet of encodeTableColumnsAsNumbers does not have same structure as input table');
  if(weights && weights.length != table.length)
    throw new Error('Weights list must have a value for each column in the input table');

  if(stopWords === true)
    stopWords = mo.StringOperators.STOP_WORDS;
  if(listDateEncodings.isList == null)
    listDateEncodings = NumberList.fromArray(listDateEncodings);

  var tResult = new Table();
  // columns in table are processed as either:
  // numeric, categorical, long text, date, ignored

  TableOperators.buildInformationObject(table,true); // builds table[i].infoObject
  var i,j,k,tt,bLeaveAlone,L,oList,L0, bValid;
  var dt0,tTemp,nLKeep;
  var nRows = table[0].length;
  if(!bUsingParms){
    for(i=0;i < table.length;i++){
      bLeaveAlone = false;
      L = table[i];
      oList = {i : i, name: L.name, type : L.type, outputColumns: []};
      oParms.columnInfo.push(oList);
      if(L.infoObject.numberDifferentElements == 1){
        oList.pType = 'ignored';
        continue;
      }
      if(L.type == 'NumberList'){
        oList.pType = 'numeric';
        if(bNormalizeNumerics){
          L0 = NumberListOperators.normalizeByZScore(L);
          oList.averages = [L.infoObject.average];
          oList.standardDeviations = [L.infoObject.standardDeviation];
        }
        else
          L0 = L.clone();
        oList.outputColumns.push(tResult.length);
        oParms.outputToInputColumnMapping.push(i);
        tResult.push(L0);
        continue;
      }
      // look for dates next
      if(L.type == 'StringList'){
        // see if it is a date disguised as a string
        dt0 = new Date(L[0]);
        if(!isNaN(dt0)){
          // if the first one looks valid then convert and check them all
          L0 = L; // original
          L = StringListConversions.toDateList(L);
          bValid = true;
          for(j=0;j<L.length;j++){
            if(isNaN(L[j]) || !isNaN(L0[j])){
              // invalid date or a simple numeric value
              bValid=false;
              break;
            }
          }
        }
        if(!bValid){
          // not all valid, treat as a string
          L = table[i];
        }
      }
      if(L.type == 'DateList'){
        tTemp = DateListOperators.encodeDatesAsNumericFeatures(L,listDateEncodings);
        oList.pType = 'date';
        // prune any single value lists
        nLKeep = new NumberList();
        var localListDataEncodings = listDateEncodings.clone();
        for(j=0; j < tTemp.length; j++){
          L0 = tTemp[j].getWithoutRepetitions();
          if(L0.length > 1){
            oList.outputColumns.push(tResult.length);
            oParms.outputToInputColumnMapping.push(i);
            tResult.push(tTemp[j]);
            nLKeep.push(j);
          }
        }
        oList.listDateEncodings = listDateEncodings.getElements(nLKeep);
        continue;
      }
      // now handle remaining lists as StringLists
      if(L.type != 'StringList'){
        L = L.toStringList();
        ListOperators.buildInformationObject(L);
      }
      // is a StringList now for sure
       oList.bSpecialDelimitedLongText = false;
      if(textDelimiter != ' '){
        // handle lists that use the special delimiter differently
        for(j=0; j < L.length && !oList.bSpecialDelimitedLongText; j++){
          if(L[j].indexOf(textDelimiter) != -1)
            oList.bSpecialDelimitedLongText = true;
        }
      }
      if(!oList.bSpecialDelimitedLongText && L.infoObject.numberDifferentElements <= maxUniqueValues){
        oList.pType = 'categorical';
        tTemp = StringListOperators.encodeStringsAsNumericFeatures(L,methodCategories,null,null,true);
        for(j=0; j < tTemp.length; j++){
          oList.outputColumns.push(tResult.length+j);
          oParms.outputToInputColumnMapping.push(i);
        }
        tResult = ListOperators.concat(tResult,tTemp);
        oList.categories = L.getWithoutRepetitions().getSorted().toArray();
      }
      else if(!oList.bSpecialDelimitedLongText && L.infoObject.numberDifferentElements/L.length < 0.25 && L.infoObject.averageTextLengths <= 100){
        // categorical but with too many distinct categories, group lower frequency
        oList.pType = 'categorical_simplified';
        var L2 = L.getSimplified(maxUniqueValues,'Other');
        tTemp = StringListOperators.encodeStringsAsNumericFeatures(L2,methodCategories,null,null,true);
        for(j=0; j < tTemp.length; j++){
          oList.outputColumns.push(tResult.length+j);
          oParms.outputToInputColumnMapping.push(i);
        }
        tResult = ListOperators.concat(tResult,tTemp);
        oList.categories = L2.getWithoutRepetitions().getSorted().toArray();
      }
      else{
        oList.pType = 'long text';
        tTemp = StringListOperators.getWordsInTextsOccurrencesTable(L,0,stopWords,null,null,200,null,2,false,0.02,textDelimiter);
        if(tTemp.length > 0 && tTemp[0].length == 0){
          // no words met cutoff criteria likely, try without a cutoff
          tTemp = StringListOperators.getWordsInTextsOccurrencesTable(L,0,stopWords,null,null,200,null,2,false,0,textDelimiter);
        }
        // want to normalize by total word count
        for(j=1;j < tTemp.length; j++){
          var sumList = tTemp[j].getSum();
          if(sumList != 0)
            tTemp[j] = tTemp[j].factor(100/sumList); // *100 to make a bit bigger
        }
        tTemp = tTemp.getTransposed(true,false);
        oList.words = tTemp.getNames().toArray();
        for(j=0;j < tTemp.length;j++){
          tTemp[j].name = L.name + ' frequency:' + tTemp[j].name;
        }
        for(j=0; j < tTemp.length; j++){
          oList.outputColumns.push(tResult.length+j);
          oParms.outputToInputColumnMapping.push(i);
        }
        tResult = ListOperators.concat(tResult,tTemp);
      }
    }
  }
  else {
    // using parms object to rerun
    for(i=0;i < table.length;i++){
      L = table[i];
      oList = oParms.columnInfo[i];
      if(oList.pType == 'ignored')
        continue;
      if(oList.pType == 'numeric'){
        L0 = L.clone();
        if(bNormalizeNumerics){
          // need to normalize by same factors as in initial run
          var stddev = oList.standardDeviations[0] == 0 ? 1 : oList.standardDeviations[0];
          for(j=0; j < L0.length; j++){
            L0[j] = (L0[j] - oList.averages[0]) / stddev;
          }
        }
        tResult.push(L0);
        continue;
      }
      if(oList.pType == 'date'){
        if(L.type == 'StringList')
          L = StringListConversions.toDateList(L);
        tTemp = DateListOperators.encodeDatesAsNumericFeatures(L,oList.listDateEncodings);
        tResult = ListOperators.concat(tResult,tTemp);
        continue;
      }
      if(oList.pType == 'categorical'){
        tTemp = StringListOperators.encodeStringsAsNumericFeatures(L,methodCategories,StringList.fromArray(oList.categories),null,true);
        tResult = ListOperators.concat(tResult,tTemp);
        continue;
      }
      if(oList.pType == 'categorical_simplified'){
        // we need to translate any item in L that is not found in oList.categories to 'Other'
        var L2 = L.clone();
        for(j=0; j < L2.length;j++){
          if(!oList.categories.includes(L2[j]))
            L2[j] = 'Other';
        }
        tTemp = StringListOperators.encodeStringsAsNumericFeatures(L2,methodCategories,StringList.fromArray(oList.categories),null,true);
        tResult = ListOperators.concat(tResult,tTemp);
        continue;
      }
      if(oList.pType == 'long text'){
        if(L.type != 'StringList'){
          L = L.toStringList();
          ListOperators.buildInformationObject(L);
        }
        // we can only count words in oList.words, get 2000 instead of 200 most frequent in case distribution is very different
        tTemp = StringListOperators.getWordsInTextsOccurrencesTable(L,0,stopWords,null,null,2000,null,2,false,null,textDelimiter);
        // we need to reproduce table in same order as originally
        tTemp = tTemp.getTransposed(true,false);
        var tTemp2 = new Table();
        var Ltemp;
        for(j=0; j < oList.words.length; j++){
          Ltemp = tTemp.getColumn(oList.words[j],null,null);
          if(Ltemp == null){
            // insert a zero list
            Ltemp = ListGenerators.createListWithSameElement(tTemp[0].length,0,oList.words[j]);
          }
          tTemp2.push(Ltemp);
        }
        tTemp = tTemp2;
        // want to normalize by total word count
        tTemp = tTemp.getTransposed(false,true);
        for(j=1;j < tTemp.length; j++){
          var sumList = tTemp[j].getSum();
          if(sumList != 0)
            tTemp[j] = tTemp[j].factor(100/sumList); // *100 to make a bit bigger
        }
        if(tTemp.length > 0){
          tTemp = tTemp.getTransposed(true,false);
          for(j=0;j < tTemp.length;j++){
            tTemp[j].name = L.name + ' frequency:' + tTemp[j].name;
          }
          tResult = ListOperators.concat(tResult,tTemp);
        }
        continue;
      }
      // error, unknown type, shouldn't happen
      console.log('[TableOperators.encodeTableColumnsAsNumbers] Invalid column type ' + oList.pType);
    }
  }
  if(weights){
    // 1. zNormalize every col (unless done already)
    // 2. multiply by fraction any cols where one input produces many output cols
    // 3. apply weights
    TableOperators.buildInformationObject(tResult,true);
    for(i = 0; i < table.length; i++){
      oList = oParms.columnInfo[i];
      if(oList.averages == null){
        oList.averages = [];
        oList.standardDeviations = [];
      }
      for(j = 0; j < oList.outputColumns.length; j++){
        L = tResult[oList.outputColumns[j]];
        if(bUsingParms){
          // need to normalize by same factors as in initial run
          var stddev = oList.standardDeviations[j] == 0 ? 1 : oList.standardDeviations[j];
          L0 = L.clone();
          if(oList.pType != 'numeric' || !oParms.bNormalizeNumerics)
            for(k=0; k < L0.length; k++){
              L0[k] = (L0[k] - oList.averages[j]) / stddev;
            }
        }
        else if(oList.averages[j] == null){
          L0 = NumberListOperators.normalizeByZScore(L);
          oList.averages[j] = L.infoObject.average;
          oList.standardDeviations[j] = L.infoObject.standardDeviation;
        }
        else
          L0 = L;
        L0 = L0.factor(weights[i]/oList.outputColumns.length);
        tResult[oList.outputColumns[j]] = L0;
      }
    }
  }


  var aRet = [
    {
      type: "Table",
      name: "tableEncoded",
      description: "Table of encoded data",
      value: tResult
    },
    {
      type: "Object",
      name: "oRerun",
      description: "Object describing transformation algorithm",
      value: oParms
    }
  ];
  return aRet;
};

/**
 * heuristic to measure how "data interesting" a table is, based on criteria such as diversity of types of lists and entropy, returns a number (form more complete info use dataQualityReport)
 * @param {Table} table to operate on
 * @return {Number} number between 0 and 1 that measures data quality
 * tags:statistics,special
 */
TableOperators.dataQuality = function(table){
  if(table.infoObject==null) TableOperators.buildInformationObject(table);
  return table.infoObject.dataQualityReport.dataQuality;
}


///**
// * Scores a registered Table
// * @param {Table} table registered Table
// * @return {Object} object with complete score
// * tags:statistics,special,transformative
// */
//TableOperators.scoreReportObject = function(table){
//  if(table==null) return;
//  if(table.register==null) {
//    table.register={
//      frequency:1
//    }
//  }
//
//  var firstScore = table.register.scoreReportObject==null;
//  if(firstScore){
//    table.register.scoreReportObject = {
//      properties:{
//        dimensions:{
//          properties:{},
//          score:0
//        },
//        information:{
//          properties:{},
//          score:0
//        },
//        meaning:{
//          properties:{},
//          score:0
//        }
//      },
//      score:0
//    };
//  }
//
//  var scoreObj = table.register.scoreReportObject;
//  
//
//  ////////////DIMENSIONS
//
//  scoreObj.properties.dimensions.properties = {
//      nColumns:table.length,
//      nRows:table[0].length,
//      frequency:table.register.frequency
//  };
//
//  scoreObj.properties.dimensions.score = Math.log(scoreObj.properties.dimensions.properties.nColumns)*Math.log(scoreObj.properties.dimensions.properties.nRows)*scoreObj.properties.dimensions.properties.frequency;
//
//
//
//  ////////////INFORMATION
//
//  var _qualityReport = TableOperators.dataQualityReport(table);
//
//  scoreObj.properties.information.properties = {
//    diversityOfKinds:_qualityReport.diversityOfKinds,
//    diversityOfTypes:_qualityReport.diversityOfTypes,
//    diversityCategories:_qualityReport.diversityCategories,
//    proportionOfNotNulls:_qualityReport.proportionOfNotNulls,
//    proportionOfListsWithoutNulls:_qualityReport.proportionOfListsWithoutNulls,
//    numbersEntropy:_qualityReport.numbersEntropy
//  }
//  scoreObj.properties.information.score = _qualityReport.dataQuality/_qualityReport.sizeScore;
//
//  ////////////MEANING
//
//  scoreObj.properties.meaning.properties = {
//    basic:{
//      properties:{
//        name:table.name,
//        label:table.label,
//        description:table.description
//      },
//      score:0
//    },
//    content:{
//      properties:{
//        actors_list:table.register.actors_list,
//        includes_kpi:table.register.includes_kpi,
//        includes_geo_coordinates:table.register.includes_geo_coordinates
//      },
//      score:0
//    },
//    context:{
//      properties:{
//        source:table.register.source,
//        dataset_cost:table.register.dataset_cost,
//        trustiness:table.register.trustiness
//      },
//      score:0
//    },
//    lists:{
//      properties:{},
//      score:0
//    }
//  }
//
//  var _u = function(value){ return value!=null && value!="" && value!=false?1:0 };
//  var _scoreFromProperties = function(properties){
//    var scoreProp = 0;
//    var n = 0;
//    for(var propName in properties){
//      scoreProp+=_u(properties[propName]);
//      n++;
//    }
//    return (1+scoreProp)/(1+n);
//  }
//
//  scoreObj.properties.meaning.properties.basic.score = _scoreFromProperties(scoreObj.properties.meaning.properties.basic.properties);
//  scoreObj.properties.meaning.properties.content.score = _scoreFromProperties(scoreObj.properties.meaning.properties.content.properties);
//  scoreObj.properties.meaning.properties.context.score = _scoreFromProperties(scoreObj.properties.meaning.properties.context.properties);
//  
//  ///lists
//  var listObjects = scoreObj.properties.meaning.properties.lists;
//  listObjects.score = 0;
//
//  var list, listScore;
//
//  for(var i=0; i<table.length; i++){
//    list = table[i];
//    listScore = (1+_u(list.name)+_u(list.label)+_u(list.description)+_u(list.category))/5;
//    listObjects.properties["list_"+i] = {
//      properties:{
//        name:list.name||"",
//        label:list.label||"",
//        category:list.category||"",
//        description:list.description||"",
//        monetaryValue:list.monetaryValue||"",
//        mainActor:list.mainActor||""
//      },
//      score:listScore
//    }
//
//    if(list.monetaryValue) listScore+=0.2*table.length;
//    if(list.mainActor) listScore+=0.1*table.length;
//
//    listObjects.score+=listScore/table.length;
//  };
//
//
//  scoreObj.properties.meaning.score = scoreObj.properties.meaning.properties.basic.score*scoreObj.properties.meaning.properties.context.score*scoreObj.properties.meaning.properties.content.score*scoreObj.properties.meaning.properties.lists.score;
//
//
//
//  ///////////DIM
//  
//  scoreObj.score = scoreObj.properties.dimensions.score*scoreObj.properties.information.score*scoreObj.properties.meaning.score;
//
//  return scoreObj;
//}



/**
 * (this methid has been replaced by the more complete scoreReportObject) heuristic to measure how "data interesting" a table is, based on criteria such as diversity of types of lists and entropy, returns a detailed report
 * @param {Table} table to operate on
 * @return {Object} object with multiple properties used to calculate the dataQuality value, along with a textual summary
 * tags:#deprecated
 */
TableOperators.dataQualityReport = function(table){
  if(table.infoObject==null) TableOperators.buildInformationObject(table);
  return table.infoObject.dataQualityReport;
}

TableOperators._dataQualityReport = function(table){
  var infoObject = table.infoObject;

  //to add: recognition of geo and time variales into infoObject (e.g. "seemsLatitude = true")
  var x = infoObject.kinds.getWithoutRepetitions().length;
  var diversityOfKinds = Math.pow(x/table.length, 0.1)*Math.min(x/4, 1);
  x = infoObject.types.getWithoutRepetitions().length;
  var diversityOfTypes = Math.pow(x/table.length, 0.1)*Math.min(x/4, 1);
  var diversityCategories = 0;
  var proportionOfNotNulls = 0;
  var proportionOfListsWithoutNulls;
  var numbersEntropy=1;
  
  var nCat=0;
  var nNum=0;
  var list;
  var listInfoObject;
  var nDiffElements;
  proportionOfListsWithoutNulls = table.length;

  for(var i=0; i<infoObject.listsInfoObjects.length; i++){
    list = table[i];
    listInfoObject = infoObject.listsInfoObjects[i];
    nDiffElements = listInfoObject.numberDifferentElements;

    if(listInfoObject.isCategorical){
      x = (nDiffElements-1)/(list.length-1);
      diversityCategories += Math.pow(4*x*(1-x), 0.5);
      nCat++;
    }
    if(listInfoObject.containsNullsOrEquivalent){
      proportionOfListsWithoutNulls--;
      proportionOfNotNulls += 1 - list.indexesOf(null).length/list.length;
    } else {
      proportionOfNotNulls += 1;
    }
    if(listInfoObject.isNumeric){
      numbersEntropy+=listInfoObject.entropy==null?1:Math.sqrt(listInfoObject.entropy);
      nNum++;
    }

    //entropy and number different elements



  }
  
  diversityCategories = nCat==0?0.5:diversityCategories/nCat;
  proportionOfNotNulls/=table.length;
  proportionOfListsWithoutNulls/=table.length;
  numbersEntropy = nNum==0?0.5:numbersEntropy/nNum;
  var tableArea = table[0].length*table.length;
  var sizeScore = (1 - 100/( (tableArea/20)+100));

  var report = {
    diversityOfKinds:diversityOfKinds,
    diversityOfTypes:diversityOfTypes,
    diversityCategories:diversityCategories,
    proportionOfNotNulls:proportionOfNotNulls,
    proportionOfListsWithoutNulls:proportionOfListsWithoutNulls,
    numbersEntropy:numbersEntropy,
    sizeScore:sizeScore
  }
  report.dataQuality = Math.sqrt(Math.sqrt(diversityOfKinds)*Math.sqrt(diversityOfTypes))*diversityCategories*proportionOfNotNulls*proportionOfNotNulls*proportionOfListsWithoutNulls*numbersEntropy*sizeScore;

  table.infoObject.dataQualityReport = report;
}

/**
 * using a table with two columns as a dictionary (first list elements to be read, second list result elements), translates lists in a Table (replaces elements according to dictionary)
 * @param  {Table} table with lists to transalte
 * @param  {Table} dictionary table with two lists
 *
 * @param {Object} nullElement element to place in case no translation is found
 * @param {Boolean} keepsOriginal if no translation is found keeps original value (default: false)
 * @return {List}
 * tags:transform
 */
TableOperators.translateListsWithDictionary = function(table, dictionary, nullElement, keepsOriginal) {
  if(table == null || dictionary==null) return;

  var newT = new Table();
  for(var i=0; i<table.length; i++){
    newT[i] = ListOperators.translateWithDictionary(table[i], dictionary, nullElement, keepsOriginal);
  }
  return newT.getImproved();
}

/**
 * Find the most repeated elements in either rows or columns
 * @param  {Table} table to process
 *
 * @param  {Boolean} bInCols if true find the most repeated in each column(default)<br>if false then find most repeated in rows
 * @return {List}
 * tags:
 */
TableOperators.getMostRepeatedElements = function(table, bInCols) {
  if(table == null) return;
  bInCols = bInCols==null ? true : bInCols;
  if(!bInCols)
    table = table.getTransposed();
  var newList = new List();
  newList.name = 'Most Repeated';
  for(var i = 0; i < table.length; i++) {
    newList.push(table[i].getMostRepeatedElement());
  }
  return newList.getImproved();
};

/**
 * For 2 tables encode the corresponding values as two lists in an output table. Typically the tables have the same geometry but if one has fewer columns they will be duplicated until the sizes match. 
 * @param  {Table} table1 to process
 * @param  {Table} table2 to process
 *
 * @param  {Boolean} bIncludeCoords if true then include coords for each row (Default:false)
 * @return {Table}
 * tags:advanced
 */
TableOperators.getCorrespondingElements = function(t1,t2,bIncludeCoords){
  if(t1 == null || t2 == null) return null;
  bIncludeCoords = bIncludeCoords == null ? false : bIncludeCoords;
  var i0 = 0, i1 = 1;
  if(t1.length < t2.length){
    // if their lengths vary make t2 the shorter one
    var temp = t1;
    t1 = t2;
    t2 = temp;
    i0 = 1;
    i1 = 0;
  }
  if(t1[0].length != t2[0].length)
    throw new Error('The 2 tables must have the same number of rows.');
  var tOutput = new Table();
  tOutput.push(new List());
  tOutput.push(new List());
  tOutput[0].name = 'Table 1';
  tOutput[1].name = 'Table 2';
  if(bIncludeCoords){
    tOutput.push(new NumberList());
    tOutput.push(new NumberList());
    tOutput[2].name = 'Column';
    tOutput[3].name = 'Row';
  }
  var c,r;
  for(c=0; c < t1.length; c++){
    for(r=0; r < t1[c].length; r++){
      tOutput[i0].push(t1[c][r]);
      tOutput[i1].push(t2[c % t2.length][r]);
      if(bIncludeCoords){
        tOutput[2].push(c);
        tOutput[3].push(r);
      }
    }
  }
  return tOutput.getImproved();
};


/**
 * receives a table and a row index or list of row indexes, and compare the element(s) against the complete Table. Provides contextual information about a selection (for instance, for a number, it indicates its decile position, for a category value its ranking)
 * @param  {Table} table context
 * @param  {Number|NumberList} index or indexes
 * @return {Table}
 * tags:advanced,compare
 */
TableOperators.rowsReportTable = function(table, indexSelected) {
    if(table==null) return;
    /*
    if an elemnt is selected a row is extracted
    otherwise rows from elements will be extracted
    */
    var multiple = typeof(indexSelected)!='number';
    
    var row;
    var rows;
    if(multiple){
        rows = table.getRows(indexSelected);
    } else {
        row = table.getRow(indexSelected);
    }
    
    //variables salient properties
    
    if(table.reportTable==null) table.reportTable = mo.TableOperators.getReportTable(table,null,2);
    
    //
    var comparativeTable = table.reportTable.getColumns(['column name', 'type', 'kind', 'numberDifferentElements', 'average', 'sum', 'median', '1st Common Element']);
    var i;
    var rank = new mo.List();
    rank.name = "SELECTED rank";
    
    if(multiple){
        rows.reportTable = mo.TableOperators.getReportTable(rows,null,2);
        var comparativeSubTable = rows.reportTable.getColumns(['average', 'sum', 'median', 'numberDifferentElements', '1st Common Element']);
        
        for(i=0; i<comparativeSubTable.length; i++){
            comparativeSubTable[i].name = "SELECTED "+comparativeSubTable[i].name;
        }
        comparativeTable = comparativeTable.concat(comparativeSubTable);
        comparativeTable.multiple = true;
        
        var allValues = new mo.StringList();
        allValues.name = "SELECTED all values";
        var allValuesHtml = new mo.StringList();
        allValuesHtml.name = "SELECTED all values html";
        for(i=0; i<table.length; i++){
            if(table[i].type!="NumberList"){
                allValues[i] = rows[i].infoObject.frequenciesTable[0].join(" | ");
                if(allValues[i].length>30) allValues[i]=allValues[i].slice(0,29)+"…";
                 
                 allValuesHtml[i] = rows[i].infoObject.frequenciesTable[0].join("|");
                if(allValuesHtml[i].length>30) allValuesHtml[i]=allValuesHtml[i].slice(0,29)+"…";
                 allValuesHtml[i] = "<b>"+ allValuesHtml[i].replace(/\|/g, "</b> | <b>")+"</b>";
            } else {
                allValues[i] = "";
                allValuesHtml[i] = "";
            }
        }
        comparativeTable.push(allValues);
        comparativeTable.push(allValuesHtml);
        
        
    } else {
        comparativeTable = comparativeTable.clone();
        row.name = 'SELECTED values';
        comparativeTable.push(row);
        
        
        var decileRank = new mo.List();
        decileRank.name = 'SELECTED decile rank';
        var occurrences = new mo.NumberList();
        occurrences.name = 'SELECTED occurrences';
        var occurrencesRank = new mo.NumberList();
        occurrencesRank.name = 'SELECTED occurrences rank';
        
        var sorted;
        var rankPosition
        for(i=0; i<table.length; i++){
            if(table[i].type=="NumberList"){
                sorted = table[i].getSorted();
                rank[i] = sorted.indexOf(row[i]);
                decileRank[i] = Math.floor(10*rank[i]/table[i].length);
            } else {
                rank[i] = '';
                decileRank[i] = '';
            }
            
            occurrencesRank[i] =  table[i].infoObject.frequenciesTable[0].indexOf(row[i]);
            occurrences[i] = table[i].infoObject.frequenciesTable[1][occurrencesRank[i]];
        }
        
        comparativeTable.push(rank.getImproved());
        comparativeTable.push(decileRank.getImproved());
        comparativeTable.push(occurrences);
        comparativeTable.push(occurrencesRank);
        
        comparativeTable.multiple = false;
    }
    
    comparativeTable.nRows = multiple?1:indexSelected.length;
    comparativeTable.nRowsFrom = table[0].length;
    
    return comparativeTable;
}

/**
 * extracts subtables from a List that contains lists of names or indexes of columns of a table
 * the result is a List of Tables, each containing some columns from the original
 * @param  {Table} table original Table
 * @param  {Table} columnsList Table that contains lists with indexes or names to extract from th eoriginal Table
 * @return {List} List of Tables
 * tags:filter
 */
TableOperators.getSubTables = function(table, columnsList){
  if(table==null) return;
  var subTablesList = new mo.List();
  subTablesList.name = "subtables";
  for(var i=0; i<columnsList.length; i++){
    subTablesList.push(table.getColumns(columnsList[i]));
  }
  return subTablesList;
}

/**
 * map a function on each List of a Table
 * @param  {Table} table with Lists to be mapped by functions
 * @param  {Function}  func function to be applied, the function receives the element, its position on the list (if iterator is set to true), and, optionally, two parameters func(list[i], i, param0, param1). Alternatively it can receive the name of an operator (ietartor will be automatically set to false)
 *
 * @param {Object} param0 optional param to be sent to function (will receive it after index)
 * @param {Object} param1 optional param to be sent to function (will receive it after param0)
 * @param {Object} param2 optional param to be sent to function (will receive it after param1)
 * @param {Object} param3 optional param to be sent to function (will receive it after param2)
 * @param {Object} param4 optional param to be sent to function (will receive it after param3)
 * @param {Object} param5 optional param to be sent to function (will receive it after param4)
 * @param {boolean} iterator (default:true) if true, the second parameter the function receives is the iteration index, if false the function receives elements of the list and parameters
 * @return {List} results
 * tags:advanced,map
 */
TableOperators.mapFunctionOnLists = function(table, func, param0, param1, param2, param3, param4, param5, iterator){
  if(table==null) return;
  var listResults = new mo.List();
  var l = table.length;

  for(var i=0; i<l; i++){
    listResults.push(ListOperators.mapFunctionOnList(table[i], func, param0, param1, null, param2, param3, param4, param5, iterator));
  }

  return listResults.getImproved();
}

/**
 * map one by one a function on each List of a Table
 * @param  {Table} table with Lists to be mapped by functions
 * @param  {List} functionList list of functions, same length as Table
 * 
 * @param {Object} param0 optional param to be sent to function (will receive it after index)
 * @param {Object} param1 optional param to be sent to function (will receive it after param0)
 * @param {Object} param2 optional param to be sent to function (will receive it after param1)
 * @param {Object} param3 optional param to be sent to function (will receive it after param2)
 * @param {Object} param4 optional param to be sent to function (will receive it after param3)
 * @param {Object} param5 optional param to be sent to function (will receive it after param4)
 * @param {boolean} iterator (default:true) if true, the second parameter the function receives is the iteration index, if false the function receives elements of the list and parameters
 * @return {List} results
 * tags:advanced,map
 */
TableOperators.mapFunctionsOnLists = function(table, functionList, param0, param1, param2, param3, param4, param5, iterator){
  if(table==null) return;
  var listResults = new mo.List();
  var l = Math.min(table.length, functionList.length);

  for(var i=0; i<l; i++){
    listResults.push(ListOperators.mapFunctionOnList(table[i], functionList[i], param0, param1, null, param2, param3, param4, param5, iterator));
  }

  return listResults.getImproved();
}

/**
 * adds label, description, category, relevance, and any other property from a variables dictionary table to a Table [!] this method is tranformative
 * @param  {Table} table with Lists to be enriched with properties
 * @param  {Table} variablesDictionaryTable Table with propreties for Lists
 * @return {Table} enriched Table
 * tags:advanced,transformative
 */
TableOperators.useVariablesDictionary = function(table, variablesDictionaryTable) {
    if(table==null || variablesDictionaryTable==null) return;
    
    var dNames = variablesDictionaryTable.getColumn('name',null,null,true)||variablesDictionaryTable[0];
    var dLabels = variablesDictionaryTable.getColumn('label',null,null,true)||variablesDictionaryTable[1];
    var dDescriptions = variablesDictionaryTable.getColumn('description',null,null,true)||variablesDictionaryTable[2];
    var dCategories = variablesDictionaryTable.getColumn('category',null,null,true)||variablesDictionaryTable[3];
    var dRelevances = variablesDictionaryTable.getColumn('relevance',null,null,true)||variablesDictionaryTable[4];
    
    var columnNames = table.getNames();
    var namesDict = mo.ListOperators.getSingleIndexDictionaryForList(columnNames);
    var namesDictOnDictionary = mo.ListOperators.getSingleIndexDictionaryForList(dNames);
    
    var l = columnNames.length;

    for(var i=0; i<table.length;i++){
      var cName = table[i].name;
      var dIndex = namesDictOnDictionary[cName];

      if(dIndex != null) {
        table[i].label = dLabels[dIndex];
        table[i].description = dDescriptions[dIndex];
        table[i].category = dCategories[dIndex];
        table[i].relevance = dRelevances[dIndex];        
      }
    }
    
    return table;
};

/**
 * Takes a matrix Table, whose first list are names to be paired, and next columns are named numberLists with 0s and 1s (or higer values indicating relation strength) indicating existence of pairs (the matrix can interpreted as an adjacent matrix of a Network, the outcome of this operator can be transformed into a Network with TableToNetwork)
 * @param  {Table} table with a List of names of elements, and numeric Lists indicating a pair match when there's a 1 (when value is higher than 1, repeated pairs will be added to the new Table)
 * @return {Table} table with two columns, each line a pair (where there's a 1 in the matrix)
 * tags:transformative,conversion
 */
TableOperators.matrixToTwoColumns = function(matrix){
    var nT = new mo.Table();
    nT[0] = new mo.StringList();
    nT[0].name = matrix[0].name;
    nT[1] = new mo.StringList();
    nT[1].name = "related with";
    for(var i=1; i<matrix.length; i++){
        for(var j=0; j<matrix[0].length; j++){
            for(var k=0; k<matrix[i][j]; k++){
                nT[0].push(String(matrix[0][j]));
                nT[1].push(matrix[i].name);
            }
        }
    }
    return nT;
}

/**
 * Filter a table using both a key list and value list. A list of values that must match for each key and a list of values that must NOT match can be provided.
 * @param  {Table} t input table
 * @param  {Number|String} iKey key column name or index
 * @param  {Number|String} iCat value column name or index
 *
 * @param  {StringList} sLMatchAllValues list of values that must ALL match for each key returned
 * @param  {StringList} sLNotMatchValues list of values that must NOT match for each key returned
 * @param  {StringList} sLMatchAtLeastOneValues list of values that must match AT LEAST ONE OF for each key returned
 * @param  {Boolean} bReturnFilteredTable if true return the table. False means return list of indexes (default)
 * @param  {Boolean} bReturnAllRowsForMatchingKeys if true return all the rows for matching keys (default). If false only return those rows that contain a value mentioned in sLMatchAllValues or sLMatchAtLeastOneValues
 * @return {NumberList} nLMatchingRows
 * tags:filter,advanced
 */
TableOperators.filterTableAnchored = function(t,iKey,iCat,sLMatchAllValues,sLNotMatchValues,sLMatchAtLeastOneValues,bReturnFilteredTable, bReturnAllRowsForMatchingKeys) {
    // Find all the rows where an ikey value has records containing all values in sLMatchAllValues
    // and none of the values in sLNotMatchValues
    if(iKey == null || iCat == null) return null;
    if(iKey.isList) iKey = iKey.name;
    if(typeof(iKey) == 'string') iKey = t.getNames().indexOf(iKey);
    if(iCat.isList) iCat = iCat.name;
    if(typeof(iCat) == 'string') iCat = t.getNames().indexOf(iCat);
    bReturnFilteredTable = bReturnFilteredTable == null ? false : bReturnFilteredTable;
    bReturnAllRowsForMatchingKeys = bReturnAllRowsForMatchingKeys == null ? true : bReturnAllRowsForMatchingKeys;
    var sLMatchOne;
    var nLMatchingRows = new mo.NumberList();
    // everything matches to start
    var sLMatchingKeys = t[iKey].getWithoutRepetitions();
    var i,j,nLAnyMatchRows;
    if(sLMatchAllValues != null){
      for(i=0; i < sLMatchAllValues.length; i++){
        sLMatchOne = mo.TableOperators.filterTable(t,'==',sLMatchAllValues[i],iCat)[iKey];
        sLMatchingKeys = mo.ListOperators.intersection(sLMatchingKeys,sLMatchOne);
        if(sLMatchingKeys.length === 0)
          break;
      }
    }
    // check not matches
    if(sLNotMatchValues != null && sLMatchingKeys.length > 0){
      for(i=0; i < sLNotMatchValues.length; i++){
        sLMatchOne = mo.TableOperators.filterTable(t,'==',sLNotMatchValues[i],iCat)[iKey];
        sLMatchingKeys = mo.ListOperators.difference(sLMatchingKeys,sLMatchOne);
        if(sLMatchingKeys.length === 0)
          break;
      }
    }
    // check one of set of values
    if(sLMatchAtLeastOneValues != null && sLMatchAtLeastOneValues.length > 0 && sLMatchingKeys.length > 0){
      nLAnyMatchRows = mo.TableOperators.selectRows(t,[iCat],sLMatchAtLeastOneValues,true,true);
      var tMatch = t.getRows(nLAnyMatchRows);
      sLMatchingKeys = mo.ListOperators.intersection(sLMatchingKeys,tMatch[iKey]);
    }
    if(sLMatchingKeys.length == 0) // empty
      return bReturnFilteredTable ? t.getRows(nLMatchingRows) : nLMatchingRows;

    // get all the rows for these keys
    nLMatchingRows = mo.TableOperators.selectRows(t,[iKey],sLMatchingKeys,true,true);

    var sL1 = sLMatchAllValues == null ? new StringList() : sLMatchAllValues;
    var sL2 = sLMatchAtLeastOneValues == null ? new StringList() : sLMatchAtLeastOneValues;
    var sLAllValuesMentioned = mo.ListOperators.union(sL1,sL2);
    if(!bReturnAllRowsForMatchingKeys && sLAllValuesMentioned.length > 0){
      // intersect with rows containing any of the values of interest.
      // This is an OR test because we already tested AND condition above
      nLAnyMatchRows = mo.TableOperators.selectRows(t,[iCat],sLAllValuesMentioned,true,true);
      nLMatchingRows = mo.ListOperators.intersection(nLMatchingRows,nLAnyMatchRows);
    }
    return bReturnFilteredTable ? t.getRows(nLMatchingRows) : nLMatchingRows;
};

///**
//* apply operations (selection, string or Functions) over columns on a table
//* @param  {|List} list with modes names, or aggregative Functions, modes are:<br>length<br>average<br>sum<br>median<br>min<br>max<br>number different elements<br>most common element<br>concated different elements by ','
//* @return {List} results
//* tags:
//*/
//TableOperators.mapFunctionOnTable = function(table, functions){
//
//}
//
//
///**
//* apply operations (selection, string or Functions) over columns on a table
//* @param  {StringList|List} list with modes names, or aggregative Functions, modes are:<br>length<br>average<br>sum<br>median<br>min<br>max<br>number different elements<br>most common element<br>concated different elements by ','
//* @return {List} results
//* tags:
//*/
//TableOperators.mapFunctionsOnSubTables = function(table, columnsList, functions){
//
//}



///**
// * apply operations (selection, string or Functions) over columns on a table
// * @param  {StringList|List} list with modes names, or aggregative Functions, modes are:<br>length<br>average<br>sum<br>median<br>min<br>max<br>number different elements<br>most common element<br>concated different elements by ','
// * @return {List} results
// * tags:
// */
//TableOperators.operationsOnColumns = function(table, operations){
//
//}
//
///**
// * receives a subtable (or indexes over a table), a list of operations (strings or Functions), and performs all operations
// * this module is meant to return meaningful statistcs and metrics out of a sample from a table, compared with the table
// * Some metrics could be averages, most common elements for a variable, lift… 
// * @param  {Table} table context
// * @param  {Number|NumberList} index or indexes
// * @return {Table}
// * tags:advanced,nest
// */
//TableOperators.operationsOverSubTable = function(subtable, operations, listsNames, table){
//  var listResults = new mo.List();
//  return listResults.getImproved();
//}
