import List from "src/dataTypes/lists/List";
import Point3D from "src/dataTypes/geometry/Point3D";

Polygon3DList.prototype = new List();
Polygon3DList.prototype.constructor = Polygon3DList;

/**
 * @classdesc A {@link List} structure for storing {@link Polygon3D} instances.
 *
 * @description Creates a new Polygon3DList.
 * @constructor
 * @category geometry
 */
function Polygon3DList() {
  var array = List.apply(this, arguments);
  array = Polygon3DList.fromArray(array);
  return array;
}
export default Polygon3DList;

Polygon3DList.fromArray = function(array) {
  var result = List.fromArray(array);
  result.type = "Polygon3DList";

  result.getBarycenter = Polygon3DList.prototype.getBarycenter;

  return result;
};

/**
 * Returns the barycenter Point3D for Polygon3DList
 * @return {Point3D} barycenter
 * tags:math,geometry,3D
 */
Polygon3DList.prototype.getBarycenter = function() {
	var b;
	var x=0;
	var y=0;
	var z=0;
	for(var i=0; i<this.length; i++){
		b = this[i].getBarycenter();
		x+=b.x;
		y+=b.y;
		z+=b.z;
	}
	return new Point3D(x/this.length, y/this.length, z/this.length);
};