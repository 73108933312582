export default {
	"$schema": "http://json-schema.org/draft-04/schema#",
	"title": "Meta Extractor Schema for a List",
	"type": "object",
	"properties": {
		"origin": {
			"type": "object",
			"properties": {
				"type": {
					"type": "string"
				},
				"source": {
					"type": "object",
					"properties": {}
				},
				"hasDataBaseOrigin": {
					"type": "boolean"
				},
				"dataBaseDataType": {
					"type": "string"
				}
			}
		},
		"data": {
			"type": "object",
			"properties": {
				"datatype": {
					"type": "string"
				},
				"countRows": {
					"type": "number"
				},
				"countRowsSimpleText": {
					"type": "string"
				}
			}
		},
		"information": {
			"type": "object",
			"properties": {
				"content": {
					"type": "object",
					"properties": {
						"containsNulls": {
							"type": "boolean"
						},
						"countNulls": {
							"type": "number"
						},
						"proportionNulls": {
							"type": "number"
						},
						"isSortedAscending": {
							"type": "boolean"
						},
						"isSortedDescending": {
							"type": "boolean"
						},
						"mostCommonElement": {
							"type": [
								"number",
								"string"
							]
						},
						"nullIsMostCommon": {
							"type": "boolean"
						},
						"mostCommonElementNonNull": {
							"type": [
								"number",
								"string"
							]
						},
						"minCountCharacters": {
							"type": "number"
						},
						"maxCountCharacters": {
							"type": "number"
						},
						"meanCountCharacters": {
							"type": "number"
						},
						"uniqueCountCharacters": {
							"type": "boolean"
						},
						"min": {
							"type": "number"
						},
						"max": {
							"type": "number"
						},
						"mean": {
							"type": "number"
						},
						"standardDeviation": {
							"type": "number"
						},
						"sum": {
							"type": "number"
						},
						"amplitude": {
							"type": "number"
						},
						"allPositive": {
							"type": "boolean"
						},
						"allNegative": {
							"type": "boolean"
						},
						"allIntegers": {
							"type": "boolean"
						},
						"normalizedUnit": {
							"type": "boolean"
						},
						"containsOutliers": {
							"type": "boolean"
						}
					}
				},
				"isCategorical": {
					"type": "boolean"
				},
				"countDifferentElements": {
					"type": "number"
				},
				"uniqueValue": {
					"type": "boolean"
				},
				"proportionDifferentElements": {
					"type": "number"
				},
				"allDifferent": {
					"type": "boolean"
				},
				"allDifferentStrict": {
					"type": "boolean"
				},
				"entropy": {
					"type": "number"
				},
				"countMostCommonElement": {
					"type": "number"
				},
				"proportionMostCommonElement": {
					"type": "number"
				}
			}
		},
		"meaning": {
			"type": "object",
			"properties": {
				"ontologyConcepts": {
					"type": "object",
					"properties": {}
				}
			}
		},
		"originId": {
			"type": "string"
		},
		"simplifiedName": {
			"type": "string"
		},
		"type": {
			"type": "string"
		},
		"potentialType": {
			"type": "string"
		},
		"potentialTyperealized": {
			"type": "boolean"
		},
		"kind": {
			"type": "string"
		},
		"dimension": {
			"type": "string"
		},
		"specification": {
			"type": "object",
			"properties": {
				"name": {
					"type": "string"
				},
				"maxScore": {
					"type": "number"
				},
				"secondScore": {
					"type": "number"
				},
				"secondSprecification": {
					"type": "string"
				},
				"scoreConfidence": {
					"type": "number"
				},
				"description": {
					"type": [
						"string",
						"null"
					]
				},
				"icons": {
					"type": "array",
					"items": {
						"type": "string"
					}
				},
				"baseName": {
					"type": "string"
				}
			}
		},
		"name": {
			"type": "string"
		},
		"hash": {
			"type": "string"
		},
		"id": {
			"type": "string"
		},
		"parentResourceId": {
			"type": "string"
		},
		"mixedTypes": {
			"type": "boolean"
		},
		"elementsTypes": {
			"type": "array",
			"items": {
				"type": "string"
			}
		}
	}
}
