import StringList from "src/dataTypes/strings/StringList";
import List from "src/dataTypes/lists/List";
import Table from "src/dataTypes/lists/Table";
import { typeOf } from "src/tools/utils/code/ClassUtils";
import Point from "src/dataTypes/geometry/Point";
import Point3D from "src/dataTypes/geometry/Point3D";
import Polygon from "src/dataTypes/geometry/Polygon";
import Rectangle from "src/dataTypes/geometry/Rectangle";
import Interval from "src/dataTypes/numeric/Interval";
import DateInterval from "src/dataTypes/dates/DateInterval";
import DateOperators from "src/operators/dates/DateOperators";
import NumberList from "src/dataTypes/numeric/NumberList";
import ObjectConversions from "src/operators/objects/ObjectConversions";
import ListOperators from "src/operators/lists/ListOperators";
import TableOperators from "src/operators/lists/TableOperators";
import PointOperators from "src/operators/geometry/PointOperators";
import NumberTableOperators from "src/operators/numeric/numberTable/NumberTableOperators";
import StringOperators from "src/operators/strings/StringOperators";
import TableEncodings from "src/operators/lists/TableEncodings";

/**
 * @classdesc  Object Operators
 *
 * @namespace
 * @category basics
 */
function ObjectOperators() {}
export default ObjectOperators;

/**
 * identity function
 * @param  {Object} object
 * @return {Object}
 * tags:special
 */
ObjectOperators.identity = function(object) {
  return object;
};


/**
 * Creates a new Object copying the values of all enumerable own properties from one source objects to a target object. I.e. it transfer properties from one Object to the other (similar to javascript's Object.assign)
 * @param {Object} target 
 * @param {Object} source 
 * @return {Object}
 * tags:transform,combine
 */
ObjectOperators.mergeObjects = function(target, source){
  // TODO: Improve this function to give more functionalities, as:
  //   - select what properties to merge
  //   - to choose if overwrite already existing properties on target object (today it does by default)
  if(target == null) return null;
  if(source == null) return target;
  var oNew = Object.assign(ObjectOperators.cloneObject(target),source);
  return ObjectOperators.isEquivalent(target,oNew) ? target : oNew;
};

/**
 * clones an Object (it might need to serialize it if the Object contains functions or circular references, that will be lost in the cloning)
 * @param  {Object} object to be cloned
 * @return {Object}
 * tags:special
 */
ObjectOperators.cloneObject = function(object){
  if(object == null) return null;
  var string;
  if(object["clone"] && typeof(object.clone)=="function") return object.clone();
  try {
    string = JSON.stringify(object);
  } catch (e) {
    string = JSON.stringify(ObjectOperators.serliazeObject(object));
  }
  return JSON.parse(string);
};

/**
 * returns true if object is null, false otherwise (useful for activating preloading panel with preloadingActive module)
 * @param  {Object} object
 * @return {Boolean}
 * tags:special
 */
ObjectOperators.isNull = function(object) {
  return object==null;
};

/**
 * clones the object removing functions, so it could be passed on postMessage (sending data to external modules) or received by webWorkers (optionally used in JSBox)
 * @param  {Object} object
 * @return {Object}
 */
ObjectOperators.serliazeObject = function(object){
  if(object==null) return null;
  // console.log('                     ObjectOperators.serliazeObject | object:',object);
  // console.log('                     ObjectOperators.serliazeObject | object[isTable]:',object["isTable"]);
  // console.log('                     ObjectOperators.serliazeObject | object[isList]:',object["isList"]);

  var newObject;
  var l;
  var i;

  if(object["isTable"]) {
    // console.log('\n-------------------------------serializaing table:', object);
    newObject = object.toArray();
    l = object.length;
    for(i=0; i<l; i++){
      newObject[i] = ObjectOperators.serliazeObject(object[i]);
      // console.log('     ------newObject[i]', newObject[i]);
    }
    // console.log('------ serialized table:', newObject);
    return newObject;
  }

  if(object["isList"]) return object.toArray();

  return object;
};

ObjectOperators.serliazeObjectsInArray = function(array){
  if(array==null) return null;

  var newArray = [];
  var l = array.length;
  var i;
  for(i=0; i<l; i++){
    newArray[i] = ObjectOperators.serliazeObject(array[i]);
  }
  return newArray;
};





/**
 * returns an Interval with min and max values from object
 * @param  {Object} object
 * @return {Interval}
 * tags:extract
 */
ObjectOperators.getInterval = function(object){
  if(object==null) return;

  if(object.getInterval!=null) return object.getInterval();
};

/**
 * returns a Rectangle with object boundaries
 * @param  {Object} object
 *
 * @param {Number} expansion frame from center by a factor
 * @return {Rectangle}
 * tags:extract
 */
ObjectOperators.getFrame = function(object, expansion){
  var frame;

  if(object.getFrame){
    frame = object.getFrame();
    if(expansion!=null) frame = frame.expand(expansion);
    return frame;
  }
};


/**
 * return rich information about abject (currently working with List and Table)
 * @param  {Object} object
 * @param  {Boolean} bUseExistingObjectIfPresent (default is true)
 * @return {Object}
 * tags:extract
 */
ObjectOperators.buildInformationObject = function(object, bUseExistingObjectIfPresent) {
  if(object==null) return null;

  var infoObject;
  if(object.isTable){
    infoObject = TableOperators.buildInformationObject(object, bUseExistingObjectIfPresent);
  } else if(object.isList){
    infoObject = ListOperators.buildInformationObject(object, bUseExistingObjectIfPresent);
  } else if(object["buildInformationObject"]) {
    infoObject = object.buildInformationObject();
  }

  if(infoObject==null) return null;

  infoObject.objectType = infoObject.type;
  infoObject.type = null;
  return infoObject;
};


/**
 * return information score for an Object (currently only working with Lists)
 * @param {Object} object (currently List)
 * @return {number} information score
 * tags:special,extract,metadata,dim
 */
ObjectOperators.getInformationScore = function(object){
  if(object["isList"]){
    if(!object.infoObject) ListOperators.buildInformationObject(object);
    var freqTable = object.infoObject.frequenciesTable;
    return 0.1/(0.1 + Math.pow( ( (freqTable[0].length/object.length) - 0.25), 2)+Math.pow(ListOperators.getListEntropy(object,null,null,2)-0.75,2) ) || 0;
  }
  return 0;
};

/**
 * builds a string report of the object, with detailed information about its structure and contents
 * @param  {Object} object
 * @return {String}
 * tags:special,extract
 */
ObjectOperators.getReport = function(object) {
  if(object == null) return null;

  if(object.getReport) return object.getReport();

  var type = typeOf(object);

  switch(type){
    case 'Table':
      return TableOperators.getReport(object);
    case 'List':
      return ListOperators.getReport(object);
  }

  if(object.isTable) return TableOperators.getReport(object);
  if(object.isList) return ListOperators.getReport(object);


  var text = "///////////report of instance of Object//////////";
  if(object.name) text += "name: "+object.name;

  var string = ObjectConversions.objectToString(object);

  if(string.length < 2000) {
    text += "\n" + string;
    return text;
  }

  var propertyNames = new StringList();
  for(var propName in object) {
    propertyNames.push(propName);
  }

  if(propertyNames.length < 100) {
    text += "\nproperties: " + propertyNames.join(", ");
  } else {
    text += "\nfirst 100 properties: " + propertyNames.slice(0, 100).join(", ");
  }

  return text;
};



/**
 * builds an html report of the object, with detailed information about its structure and contents
 * @param  {Object} object
 * @return {String}
 * tags:special,extract
 */
ObjectOperators.getReportHtml = function(object,lev) {
  if(object == null) return null;
  lev = lev == null ? 0 : lev;

  if(object.getReportHtml) return object.getReportHtml();

  var type = typeOf(object);

  switch(type){
    case 'Table':
      return TableOperators.getReportHtml(object);
    case 'List':
      return ListOperators.getReportHtml(object);
  }

  if(object.isTable) return TableOperators.getReportHtml(object);
  if(object.isList) return ListOperators.getReportHtml(object);

  var text;
  if(type == 'Object'){
      if(lev == 0)
        text = '<fs18>Report for Object</f><br>Properties:<br>';
      else
        text = 'Object with Properties:<br>';
    for(var propName in object){
        text += propName + ': ';
        if(typeOf(object[propName]) == 'Object'){
            if(lev <= 1)
              text += ObjectOperators.getReportHtml(object[propName],lev+1);
            else
              text += 'Object<br>';
        }
        else if(typeOf(object[propName]) == 'Array')
            text += 'Array of length ' + object[propName].length + '<br>';
        else
            text += ObjectConversions.objectToString(object[propName]) + '<br>';
    }
  }
  else if(type == 'Array'){
    if(lev == 0)
        text = '<fs18>Report for Array of length ' + object.length +'</f><br><fs16>First Element:</f><br>';
    else
        text = 'Report for Array of length ' + object.length + ', First Element:<br>';
    text += ObjectOperators.getReportHtml(object[0],lev+1);
  }
  else if(type == 'string'){
    var sLlines = StringOperators.splitByEnter(object);
    var len = sLlines.length;
    // skip leading blank lines
    var iStart = -1;
    for(var i=0; i < sLlines.length && iStart == -1; i++){
      if(sLlines[i] != '') iStart = i;
    }
    if(iStart > 0)
      sLlines = sLlines.getSubList(iStart);
    if(len > 1){
      text = '<fs18>Report for Text with ' + len +' lines</f><br><fs16>First 10 Lines:</f><br>';
      text += '<span style="white-space: nowrap">';
      for(i = 0; i < sLlines.length && i < 10; i++)
        text += sLlines[i] + '<br>';
      text += '</span>';
    }
    else
      text = object; // just 1 line
  }
  else
    text = object;
  return text;
};


/**
 * return a string characterizing the object by the properties inside it
 * @param {Object} object
 * @return {String} string representing properties of the object
 * tags:extract
 */
ObjectOperators.getObjectStructure = function(object){
  var objSkeleton = ObjectOperators.replaceValuesByNull(object);
  return ObjectConversions.objectToString(objSkeleton);
};

/**
 * return an instance of the object with values replaced by null (recursive)
 * @param {Object} object
 * @return {Object} object with properties set to null
 * tags:transform
 */
ObjectOperators.replaceValuesByNull = function(object){
  var oNew = {};
  for (var i in object) {
    if (object.hasOwnProperty(i)) {
      if(typeof object[i] == "object" && object[i]!= null){
        oNew[i] = ObjectOperators.replaceValuesByNull(object[i]);
        continue;
      }
      // replace all values with null
      oNew[i] = null;
    }
  }
  return oNew;
};

/**
 * return length of list, string or Object with length property
 * @param {Object} object with length property
 *
 * @param {Boolean} bGetElementCount for sparse lists get the number of actual elements which could be less than the length (default:false)
 * @return {Number} length of number
 * tags:extract
 */
ObjectOperators.getLength = function(object,bGetElementCount){
  if(object==null || object["length"]==null) return;
  bGetElementCount = bGetElementCount == null ? false: bGetElementCount;
  if(bGetElementCount && object.length != null){
    var totalCount = 0;
    for(var index = 0; index < object.length; index++){
      if (index in object) {
        totalCount++;
      }
    }
    return totalCount;
  }

  return object.length;
};


/**
 * searches for an element on a List or a substring in a string
 * @param {Object} object List or string
 *
 * @param {Object} object or string to be searched in List or string
 * @return {Boolean} true if element is in List or in substring
 * tags:boolean
 * examples:santiago/examples/includes
 */
ObjectOperators.includes = function(object,element_or_substring){
  if(object==null) return;
  return object.includes(element_or_substring);
};


/**
 * uses a boolean to decide which of two objects it returns, equivalent to an "if-else"
 * @param  {Boolean} boolean
 * @param  {Object} object0 returned if boolean is true
 * @param  {Object} object1 returned if boolean is false
 * @return {Object} Returns object0 if boolean inlet is true and object1 if it's false
 * @return {Object} Returns the object0 if boolean inlet is true and null if it's false
 * @return {Object} Returns the object1 if boolean inlet is false and null if it's true
 * tags:flow,boolean
 */
ObjectOperators.booleanGate = function(boolean, obj0, obj1) {
  var obj = boolean ? obj0 : obj1;
  return [
    {
      type: "Object",
      name: "boolean value",
      description: "returns the value corresponding to the boolean input",
      value: obj
    },
    {
      type: "Object",
      name: "true value",
      description: "true value",
      value: boolean ? obj0 : null
    },
    {
      type: "Object",
      name: "false value",
      description: "false value",
      value: !boolean ? obj1 : null
    }
  ]  
};

/**
 * multiple boolean operations, including and, or, nand, xor
 * @param  {Number} mode 0:and (all true)<|>1:or (some true)<|>2:nand (not all true)<|>3:xor (not all equal)<|>4:xnor (all equal)<|>5:nor (all false)<|>6:some not true<|>7:more trues<|>8:more non true
 * @param  {Boolean} b0 first boolean
 * 
 * @param  {Boolean} b1 second boolean
 * @param  {Boolean} b2 third boolean
 * @param  {Boolean} b3 fourth boolean
 * @param  {Boolean} b4 fifth boolean
 * @param  {Boolean} b5 sixth boolean
 * @param  {Boolean} b6 seventh boolean
 * @param  {Boolean} b7 eighth boolean
 * @param  {Boolean} b8 nineth boolean
 * @return {Boolean}
 * tags:boolean,math
 */
ObjectOperators.booleanOperator = function(mode, b0, b1, b2, b3, b4, b5, b6, b7, b8) {
  if(arguments == null || arguments.length === 0) return null;

  var i, n=0;

  switch(mode){
    case 0://and
      for(i = 1; i<arguments.length; i++) {
        if(!arguments[i]) return false;
      }
      return true;
      break;
    case 1://or
      for(i = 1; i<arguments.length; i++) {
        if(arguments[i]) return true;
      }
      return false;
      break;
    case 2://nand
      for(i = 1; i<arguments.length; i++) {
        if(!arguments[i]) return true;
      }
      return false;
      break;
    case 3://xor
      for(i = 2; i<arguments.length; i++) {
        if(arguments[i]!=b0) return true;
      }
      return false;
      break;
    case 4://xnor
      for(i = 2; i<arguments.length; i++) {
        if(arguments[i]!=b0) return false;
      }
      return true;
      break;
    case 5://nor
      for(i = 1; i<arguments.length; i++) {
        if(arguments[i]) return false;
      }
      return true;
      break;
    case 6://some false
      for(i = 1; i<arguments.length; i++) {
        if(!arguments[i]) return true;
      }
      return false;
      break;
    case 7://more trues
      for(i = 1; i<arguments.length; i++) {
        n+=Number(arguments[i]);
      }
      return n>0.5*(arguments.length-1);
      break;
    case 8://more falses
      for(i = 1; i<arguments.length; i++) {
        n+=Number(!arguments[i]);
      }
      return n>0.5*(arguments.length-1);
      break;
  }
  return null;
};

/**
 * compares two objects and checks if they are equal to decide which of two other objects it returns
 * @param  {Object} compare0
 * @param  {Object} compare1
 * @param  {Object} object0 returned if equal
 * @param  {Object} object1 returned if different
 * @return {Object}
 * tags:compare,flow
 */
ObjectOperators.equalGate = function(compare0, compare1, object0, object1) {
  return compare0==compare1 ? object0 : object1;
};

/**
 * returns the topmost inlet that isn't null or an empty string
 * @param  {Object} argument0
 *
 * @param  {Object} argument1
 * @param  {Object} argument2
 * @param  {Object} argument3
 * @param  {Object} argument4
 * @param  {Object} argument5
 * @param  {Object} argument6
 * @param  {Object} argument7
 * @return {Number} result is the topmost inlet that is not null or empty string
 * tags:flow
 * examples:jeff/examples/priorityGate
 */
ObjectOperators.priorityGate = function() {
  for(var i=0;i<arguments.length;i++)
    if(arguments[i] != null && arguments[i] !== '') return arguments[i];
  return null;
};

/**
 * returns the input through the outlet specified by second inlet. Like a demultiplexer, a single-input, multiple-output switch
 * @param  {Object} input to pass through
 * @param  {Number} index of the outlet to pass through the value of inlet 0
 * @return {Object} outlet 0
 * @return {Object} outlet 1
 * @return {Object} outlet 2
 * @return {Object} outlet 3
 * @return {Object} outlet 4
 * @return {Object} outlet 5
 * @return {Object} outlet 6
 * @return {Object} outlet 7
 * tags:flow
 * examples:jeff/examples/channelSplitter
 */
ObjectOperators.channelSplitter = function(input,outlet) {
    if(input == null) return null;
    outlet = outlet == null ? 0 : Number(outlet);
    var aRet = [];
    for(var i=0;i<8;i++){
        aRet.push({
            type: 'Object',
            name: 'input value',
            description: 'outlet ' + i,
            value: i==outlet ? input : null
        })
    }
    return aRet;
};

/**
 * return a property value from its name
 * @param  {Object} object
 * @param  {String|StringList} property_value use '.' as a delimiter to get nested properties. When the property name includes the '.' use StringList instead,  where each element represent  a property name.
 * @return {Object}
 * tags:extract
 */
ObjectOperators.getPropertyValue = function(object, property_name) {
  if(object == null) return;

  var propertiesChain = typeof(property_name)==='string'?property_name.split('.'):property_name;
  var objectProp = object[propertiesChain[0]];
  var propCount = 1;

  while (propertiesChain[propCount]!=null) {
    if(objectProp == null) return null;
    objectProp = objectProp[propertiesChain[propCount]];
    propCount++;
  }
  return objectProp;
};

/**
 * Returns a List of values of a property of all elements.
 * @param  {Object} object
 * @param  {String} propertyName use '.' as a delimiter to get nested properties. When the property name includes the '.' use StringList instead,  where each element represent  a property name.
 *
 * @param  {Object} valueIfNull in case the property doesn't exist in the element
 * @return {List}
 * tags:extract
 */
ObjectOperators.getPropertyValues = function(object, propertyName, valueIfNull) {
  if(object == null || object.length == null) return;
  if(object.isList) return object.getPropertyValues(propertyName, valueIfNull);
  var newList = new List();
  newList.name = propertyName;
  var val;
  var l = object.length;
  for(var i = 0; i<l; i++) {
    val = ObjectOperators.getPropertyValue(object[i],propertyName);
    newList[i] = (val == null ? valueIfNull : val);
  }
  return newList.getImproved();
};

/**
 * Returns multiple named properties of an object
 * @param  {Object} object
 * @param  {String} propertyName0 use '.' as a delimiter to get nested properties.
 *
 * @param  {String} propertyName1 use '.' as a delimiter to get nested properties.
 * @param  {String} propertyName2 use '.' as a delimiter to get nested properties.
 * @param  {String} propertyName3 use '.' as a delimiter to get nested properties.
 * @param  {String} propertyName4 use '.' as a delimiter to get nested properties.
 * @return {Object} first Object
 * @return {Object} second Object
 * @return {Object} third Object
 * @return {Object} fourth Object
 * @return {Object} fifth Object
 * tags:extract
 */
ObjectOperators.getMultiplePropertyValues = function() {
  if(arguments == null || arguments.length === 0 ||  arguments[0] == null) return null;
  var object = arguments[0];
  var aRet = [];
  for(var i=1; i < arguments.length; i++){
    var o = {
      name: "value"+(i-1),
      description: arguments[i] ? "The value of property " + arguments[i] : ''
    }
    o.value = arguments[i] ? ObjectOperators.getPropertyValue(object,arguments[i]) : null;
    o.type = o.value == null ? 'Object' : typeOf(o.value);
    aRet.push(arguments[i] ? o : null);
  }
  aRet.isOutput = true;
  return aRet;
};

/**
 * return name property of Object
 * @param  {Object} object
 * @return {String}
 * tags:extract
 */
ObjectOperators.getName = function(object) {
  if(object==null) return;
  return object["name"];
};


/**
 * return true if property value exists in object
 * @param  {Object} object
 * @param  {String} property_value
 * @return {Boolean}
 * tags:compare
 */
ObjectOperators.isPropertyValue = function(object, property_value) {
  if(object == null) return false;
  for (var property in object) {
    if(object[property] === property_value)
      return true;
  }
  return false;
};

/**
 * return a a stringList of property names
 * @param  {Object} object
 * @return {StringList}
 * tags:extract
 */
ObjectOperators.getPropertiesNames = function(object) {
  if(object == null) return;
  var sLProps = StringList.fromArray(Object.getOwnPropertyNames(object));
  sLProps.removeElement('__ob__'); // explicitly remove this special property added by Vue
  return sLProps;
};

/**
 * return a table with a stringList of property names and a list of respective values
 * @param  {Object} object
 * @return {Table}
 * tags:extract
 */
ObjectOperators.getPropertiesNamesAndValues = function(object) {
  if(object == null) return;

  var table = new Table();

  table[0] = ObjectOperators.getPropertiesNames(object);
  table[1] = new List();

  table[0].forEach(function(value, i) {
    table[1][i] = object[value];
  });

  table[1] = table[1].getImproved();

  return table;
};

/**
 * assigns a value to a property in the object ([!] this method is optionally transformative)
 * @param  {Object} object
 * @param  {String} property_name use '.' as a delimiter to get nested properties. When the property name includes the '.' use StringList instead,  where each element represent  a property name.
 * @param  {Object} property_value
 * @param  {Boolean} transformative (default:false) check true if you want to change property value of Object without creating a new one (a clone) [!] This can propagate backwards and have unexpected consequences in other modules
 * @param  {Boolean} bForNullKeepPrevious (default:false) If the new property_value is null then keep the existing value of the property
 * @return {Object}
 * tags:transform,transformative
 */
ObjectOperators.setPropertyValue = function(object, property_name, property_value, transformative, bForNullKeepPrevious) {
  if(object == null || property_name == null) return;
  var newObject = transformative?object:ObjectOperators.cloneObject(object);
  if(bForNullKeepPrevious && property_value == null) return newObject;
  var propertiesChain = typeof(property_name)==='string'?property_name.split('.'):property_name;

  var tempObj = newObject;
  for(var i=0; i<propertiesChain.length-1;  i++){
      tempObj = tempObj[propertiesChain[i]] = tempObj[propertiesChain[i]] || {};
  }
  
  tempObj[propertiesChain[i]]  = property_value;
  return newObject;
};

/**
 * assign value to property name on all elements ([!] optionally transformative)
 * @param {Object} object
 * @param  {String} name of new object
 *
 * @param {Boolean} transformative (default:false) check if you want to change name of Object without creating a new one, a clone [!] This can propagate backwards and have unexpected consequences in other modules
 * @return {Object} cloned Object with name (when non transformative and the Object has a clone method)
 * tags:transform,metadata
 */
ObjectOperators.setName = function(object, name, transformative){
  if(object == null || typeof object =="string" || typeof object =="number" || typeof object=="boolean") return object;
  var newObject = transformative?object:ObjectOperators.cloneObject(object);
  newObject.name = name;
  return newObject;
};




/**
 * interpolates two different objects of the same type<br>currently working with numbers, intervals, numberLists, and polygons
 * @param  {Object} object0
 * @param  {Object} object1
 *
 * @param  {Number} value typically in range [0,1], defaults to 0.5
 * @param {Number} minDistance if objects are close enough, it delivers the orginal object
 * @return {Object}
 * tags:transform
 */
ObjectOperators.interpolateObjects = function(object0, object1, value, minDistance) {
  var type = typeOf(object0);
  var i;
  if(type != typeOf(object1)) return object0;

  value = value == null ? 0.5 : value;
  var antivalue = 1 - value;

  switch(type) {
    case 'number':
      if(minDistance && Math.abs(object0 - object1) <= minDistance) return object0;
      return antivalue * object0 + value * object1;
    case 'Interval':
      if(minDistance && (Math.abs(object0.x - object1.x) + Math.abs(object0.y - object1.y)) <= minDistance) return object0;
      return new Interval(antivalue * object0.x + value * object1.x, antivalue * object0.y + value * object1.y);
    case 'NumberList':
      if(minDistance && Math.abs(object0.subtract(object1).getSum()) <= minDistance) return object0;
      var minL = Math.min(object0.length, object1.length);
      var newNumberList = new NumberList();
      for(i = 0; i < minL; i++) {
        newNumberList[i] = antivalue * object0[i] + value * object1[i];
      }
      return newNumberList;
    case 'Polygon':
      var minL = Math.min(object0.length, object1.length);
      var newPolygon = new Polygon();
      for(i = 0; i < minL; i++) {
        newPolygon[i] = PointOperators.twoPointsInterpolation(object0[i],object1[i],value);
      }
      return newPolygon;
  }
  return null;
};


// ObjectOperators.fusionObjects = function(object, objectToFusion){

// }

/**
 * replaces an object by another if it matches the obectToReplace
 * @param  {Object} object to be replaced if equals to obectToReplace
 * @param  {Object} obectToReplace object to check
 * @param  {Object} objectToPlace to be delivered instead of given object (in case the object matches obectToReplace)
 * @return {Object} original object or replaced object
 * tags:transform
 */
ObjectOperators.replaceObject = function(object, obectToReplace, objectToPlace) {
  return object == obectToReplace ? objectToPlace : object;
};


/**
 * create an improved list from an Array
 * @param  {Array} array
 * @return {List}
 * tags:conversion,transform
 */
ObjectOperators.toList = function(array) {
  return List.fromArray(array).getImproved();
};


/**
 * applies Math.floor operator to any numeric object
 * @param  {Object} number, numberList…
 * @return {Object}
 * tags:
 */
ObjectOperators.floor = function(object){
  if(typeof object == 'number') return Math.floor(object);
  if(object.floor) return object.floor();
  return null;
};


/////universal operators



//////unibersal algebra


/**
 * adds two or more objects, addition is performed according to the different types
 * @param {Object} object0
 *
 * @param {Object} object1
 * @param {Object} object2
 * @param {Object} object3
 * @param {Object} object4
 * @param {Object} object5
 * @return {Object}
 * tags:math
 */
ObjectOperators.addition = function() {
  //console.log("addition__________________________________arguments:", arguments);
  var result;
  var i;
  if(arguments.length < 2) {
    if(arguments.length == 1 && arguments[0] != null && arguments[0].isList) {
      result = arguments[0][0];
      for(i = 1; arguments[0][i] != null; i++) {
        result = ObjectOperators.addition(result, arguments[0][i]);
      }
      return result;
    }
    return null;
  }

  if(arguments.length == 2) {
    if(arguments[0] != null && arguments[0].isList && arguments[1] != null && arguments[1].isList) {
      return ObjectOperators._applyBinaryOperatorOnLists(arguments[0], arguments[1], ObjectOperators.addition);
    } else if(arguments[0] != null && arguments[0].isList) {
      //console.log('list versus object');
      return ObjectOperators._applyBinaryOperatorOnListWithObject(arguments[0], arguments[1], ObjectOperators.addition);
    } else if(arguments[1] != null && arguments[1].isList) {
      //console.log('object versus list');
      return ObjectOperators._applyBinaryOperatorOnObjectWithList(arguments[0], arguments[1], ObjectOperators.addition);
    }

    var a0 = arguments[0];
    var a1 = arguments[1];
    var a0Type = typeOf(a0);
    var a1Type = typeOf(a1);
    //console.log('ObjectOperators.addition, a0Type, a1Type:['+a0Type, a1Type+']');
    var reversed = false;

    if(a1Type < a0Type && a1Type != "string" && a0Type != "string") {
      a0 = arguments[1];
      a1 = arguments[0];
      a0Type = typeOf(a0);
      a1Type = typeOf(a1);
      reversed = true;
    }

    var pairType = a0Type + "_" + a1Type;
    //console.log('ObjectOperators.addition, pairType:['+pairType+']');
    //
    switch(pairType) {
      case 'boolean_boolean':
        return a0 && a1;
      case 'date_string':
        return reversed ? a1 + DateOperators.dateToString(a0) : DateOperators.dateToString(a0) + a1;
      case 'number_string':
      case 'string_string':
      case 'string_number':
      case 'boolean_number':
      case 'number_number':
        return a0 + a1;
      case 'Point_Point':
        return new Point(a0.x + a1.x, a0.y + a1.y);
      case 'Point3D_Point3D':
        return new Point3D(a0.x + a1.x, a0.y + a1.y, a0.z + a1.z);
      case 'number_Point':
        return new Point(a0.x + a1, a0.y + a1);
      case 'number_Point3D':
        return new Point3D(a0.x + a1, a0.y + a1, a0.z + a1);
      case 'Interval_number':
        return new Interval(a0.x + a1, a0.y + a1);
      case 'Interval_Point':
        return new Point(a0.getMin() + a1.x, a0.getMax() + a1.y);
      case 'Interval_Interval':
        return new Point(a0.getMin() + a1.getMin(), a0.getMax() + a1.getMax());
      case 'Point_Rectangle':
        return new Rectangle(a0.x + a1.x, a0.y + a1.y, a1.width, a1.height);
      case 'Interval_Rectangle':
        return new Rectangle(a0.getMin() + a1.x, a0.getMax() + a1.y, a1.width, a1.height);
      case 'Rectangle_Rectangle':
        return new Rectangle(a0.x + a1.x, a0.y + a1.y, a0.width + a1.width, a0.height + a1.height);
      case 'date_number':
        return new Date(a0.getTime() + (a1 / DateOperators.millisecondsToDays));
      case 'date_date':
        return new Date(Number(a0.getTime() + a1.getTime())); //?
      case 'date_DateInterval':
        return new DateInterval(ObjectOperators.addition(a0, a1.date0), ObjectOperators.addition(a0, a1.date1));
      case 'DateInterval_number':
        return new DateInterval(ObjectOperators.addition(a0.date0, a1), ObjectOperators.addition(a0.date1, a1));
      case 'DateInterval_Interval':
        return new DateInterval(ObjectOperators.addition(a0.date0, a1.min), ObjectOperators.addition(a0.date1, a1.max));
      case 'DateInterval_DateInterval':
        return new DateInterval(ObjectOperators.addition(a0.date0, a1.date0), ObjectOperators.addition(a0.date1, a1.date1));
      case 'string_StringList':
        return a1.append(a0, false);
      case 'StringList_string':
        return a1.append(a0, true);
      default:
        //console.log("[!] addition didn't manage to resolve:", pairType, a0 + a1);
        return null;

    }
    return a0 + a1;

  }

  result = arguments[0];
  for(i = 1; i < arguments.length; i++) {
    //console.log(i, 'result:', result);
    result = ObjectOperators.addition(result, arguments[i]);
  }
  return result;
};


/**
 * multiplies two or more objects, multiplication is performed according to the different types
 * @param {Object} object0
 *
 * @param {Object} object1
 * @param {Object} object2
 * @param {Object} object3
 * @param {Object} object4
 * @param {Object} object5
 * @return {Object}
 * tags:math
 */
ObjectOperators.multiplication = function() {
  //console.log("multiplication__________________________________arguments:", arguments);
  var result;
  var i;
  if(arguments.length < 2) {
    if(arguments.length == 1 && arguments[0].isList) {
      result = arguments[0][0];
      for(i = 1; arguments[0][i] != null; i++) {
        result = ObjectOperators.multiplication(result, arguments[0][i]);
      }
      return result;
    }
    return null;
  }

  var a0 = arguments[0];
  var a1 = arguments[1];
  var a0Type = typeOf(a0);
  var a1Type = typeOf(a1);
  var pairType = a0Type + "_" + a1Type;

  //c.log('pairType:['+pairType+']');

  if(arguments.length == 2) {
    if(arguments[0] == null) return null;

    if(pairType=='NumberTable_NumberTable') return NumberTableOperators.product(a0, a1);

    if(arguments[0].isList && arguments[1].isList) {
      return ObjectOperators._applyBinaryOperatorOnLists(arguments[0], arguments[1], ObjectOperators.multiplication);
    } else if(arguments[0].isList) {
      //console.log('list versus object');
      return ObjectOperators._applyBinaryOperatorOnListWithObject(arguments[0], arguments[1], ObjectOperators.multiplication);
    } else if(arguments[1].isList) {
      return ObjectOperators._applyBinaryOperatorOnListWithObject(arguments[1], arguments[0], ObjectOperators.multiplication);
    }

    if(a1Type < a0Type){
      a0 = arguments[1];
      a1 = arguments[0];
      a0Type = typeOf(a0);
      a1Type = typeOf(a1);
    }

    pairType = a0Type + "_" + a1Type;
    //console.log('pairType:['+pairType+']');

    //
    switch(pairType) {
      case 'number_number':
      case 'boolean_boolean':
      case 'boolean_number':
      case 'Date_string':
      case 'number_string':
      case 'string_string':
        return a0 * a1; //todo: what to do with strings?
      case 'Point_Point':
        return mo.PointOperators.pointMultiplication(a0, a1);
        //return new Point(a0.x * a1.x, a0.y * a1.y);
      case 'Point3D_Point3D':
        return new Point3D(a0.x * a1.x, a0.y * a1.y, a0.z * a1.z);
      case 'number_Point':
        return new Point(a0.x * a1, a0.y * a1);
      case 'number_Point3D':
        return new Point3D(a0.x * a1, a0.y * a1, a0.z * a1);
      case 'Interval_number':
        return new Interval(a0.getMin() * a1, a0.getMax() * a1);
      case 'Interval_Point':
        return new Point(a0.getMin() * a1.x, a0.getMax() * a1.y);
      case 'Interval_Interval':
        return new Point(a0.getMin() + a1.getMin(), a0.getMax() + a1.getMax());
      case 'Point_Rectangle':
        return new Rectangle(a0.x * a1.x, a0.y * a1.y, a1.width, a1.height); //todo: no
      case 'Interval_Rectangle':
        return new Rectangle(a0.getMin() * a1.x, a0.getMax() * a1.y, a1.width, a1.height); //todo: no
      case 'Rectangle_Rectangle':
        return new Rectangle(a0.x * a1.x, a0.y * a1.y, a0.width * a1.width, a0.height * a1.height);
      case 'date_number':
        return new Date(a0.getTime() * (a1 / DateOperators.millisecondsToDays));
      case 'date_date':
        return new Date(Number(a0.getTime() + a1.getTime())); //todo: ???
      case 'date_DateInterval':
        return new DateInterval(ObjectOperators.multiplication(a0, a1.date0), ObjectOperators.multiplication(a0, a1.date1)); //todo: ???
      case 'DateInterval_number':
        return new DateInterval(ObjectOperators.multiplication(a0.date0, a1), ObjectOperators.multiplication(a0.date1, a1)); //todo: ???
      case 'DateInterval_Interval':
        return new DateInterval(ObjectOperators.multiplication(a0.date0, a1.min), ObjectOperators.multiplication(a0.date1, a1.max)); //todo: ???
      case 'DateInterval_DateInterval':
        return new DateInterval(ObjectOperators.multiplication(a0.date0, a1.date0), ObjectOperators.multiplication(a0.date1, a1.date1)); //todo: ???
      default:
        console.log("[!] multiplication didn't manage to resolve:", pairType, a0 * a1);
        return null;

    }
    return a0 * a1;
  }

  result = arguments[0];
  for(i = 1; i < arguments.length; i++) {
    //console.log(i, 'result:', result);
    result = ObjectOperators.multiplication(result, arguments[i]);
  }
  return result;
};

/**
 * divides two or more objects, division is performed according to the different types
 * @param {Object} object0
 *
 * @param {Object} object1
 * @param {Object} object2
 * @param {Object} object3
 * @param {Object} object4
 * @param {Object} object5
 * @return {Object}
 * tags:math
 */
ObjectOperators.division = function() {
  var result;
  var i;
  if(arguments.length < 2) {
    if(arguments.length == 1 && arguments[0] && arguments[0].isList) {
      result = arguments[0][0];
      for(i = 1; arguments[0][i] != null; i++) {
        result = ObjectOperators.division(result, arguments[0][i]);
      }
      return result;
    }
    return null;
  }
  if(arguments.length == 2) {
    if(arguments[0] != null && arguments[0].isList && arguments[1] != null && arguments[1].isList) {
      return ObjectOperators._applyBinaryOperatorOnLists(arguments[0], arguments[1], ObjectOperators.division);
    } else if(arguments[0] != null && arguments[0].isList) {
      //console.log('list versus object');
      return ObjectOperators._applyBinaryOperatorOnListWithObject(arguments[0], arguments[1], ObjectOperators.division);
    } else if(arguments[1] != null && arguments[1].isList) {
      return ObjectOperators._applyBinaryOperatorOnListWithObject(arguments[1], arguments[0], ObjectOperators.division);
    }

    var a0 = arguments[0];
    var a1 = arguments[1];
    var a0Type = typeOf(a0);
    var a1Type = typeOf(a1);

    if(a1Type < a0Type) {
      a0 = arguments[1];
      a1 = arguments[0];
      a0Type = typeOf(a0);
      a1Type = typeOf(a1);
    }

    var pairType = a0Type + "_" + a1Type;
    //console.log('pairType:['+pairType+']');
    //
    switch(pairType) {
      case 'number_number':
      case 'boolean_boolean':
      case 'boolean_number':
      case 'Date_string':
      case 'number_string':
      case 'string_string':
        return a0 / a1; //todo: what to do with strings?
      case 'Point_Point':
        return new Point(a0.x / a1.x, a0.y / a1.y);
      case 'Point3D_Point3D':
        return new Point3D(a0.x / a1.x, a0.y / a1.y, a0.z / a1.z);
      case 'number_Point':
        return new Point(a0.x / a1, a0.y / a1);
      case 'number_Point3D':
        return new Point3D(a0.x / a1, a0.y / a1, a0.z / a1);
      case 'Interval_number':
        return new Interval(a0.getMin() / a1, a0.getMax() / a1);
      case 'Interval_Point':
        return new Point(a0.getMin() / a1.x, a0.getMax() / a1.y);
      case 'Interval_Interval':
        return new Point(a0.getMin() + a1.getMin(), a0.getMax() + a1.getMax());
      case 'Point_Rectangle':
        return new Rectangle(a0.x / a1.x, a0.y / a1.y, a1.width, a1.height); //todo: no
      case 'Interval_Rectangle':
        return new Rectangle(a0.getMin() / a1.x, a0.getMax() / a1.y, a1.width, a1.height); //todo: no
      case 'Rectangle_Rectangle':
        return new Rectangle(a0.x / a1.x, a0.y / a1.y, a0.width / a1.width, a0.height / a1.height);
      case 'date_number':
        return new Date(a0.getTime() / (a1 / DateOperators.millisecondsToDays));
      case 'date_date':
        return new Date(Number(a0.getTime() + a1.getTime())); //todo: ???
      case 'date_DateInterval':
        return new DateInterval(ObjectOperators.division(a0, a1.date0), ObjectOperators.division(a0, a1.date1)); //todo: ???
      case 'DateInterval_number':
        return new DateInterval(ObjectOperators.division(a0.date0, a1), ObjectOperators.division(a0.date1, a1)); //todo: ???
      case 'DateInterval_Interval':
        return new DateInterval(ObjectOperators.division(a0.date0, a1.min), ObjectOperators.division(a0.date1, a1.max)); //todo: ???
      case 'DateInterval_DateInterval':
        return new DateInterval(ObjectOperators.division(a0.date0, a1.date0), ObjectOperators.division(a0.date1, a1.date1)); //todo: ???
      default:
        console.log("[!] division didn't manage to resolve:", pairType, a0 / a1);
        return null;

    }
    return a0 / a1;
  }

  result = arguments[0];
  for(i = 1; i < arguments.length; i++) {
    //console.log(i, 'result:', result);
    result = ObjectOperators.division(result, arguments[i]);
  }
  return result;
};





//removed, added an approach method in NumberList and Polygon

/**
 * modifies and object, making it closer to another (convergent asymptotic vector aka destiny) object, objects need to be vectors
 * @param  {Object} objectToModify object that will be modified
 * @param  {Object} objectDestiny  object guide (convergent asymptotic vector)
 * @param  {Number} speed speed of convergence
 */
// ObjectOperators.approach = function(objectToModify, objectDestiny, speed){
// 	var type = typeOf(objectToModify);
// 	if(type!=typeOf(objectDestiny)) return null;
// 	speed = speed||0.5;
// 	var antispeed = 1-speed;

// 	switch(type){
// 		case "NumberList":
// 			objectToModify.forEach(function(n, i){objectToModify[i] = antispeed*objectToModify[i] + speed*objectDestiny[i];});
// 			break;
// 	}
// }





ObjectOperators._applyBinaryOperatorOnLists = function(list0, list1, operator) {
  var n = Math.min(list0.length, list1.length);
  var i;
  var resultList = new List();
  for(i = 0; i < n; i++) {
    resultList.push(ObjectOperators._applyBinaryOperator(list0[i], list1[i], operator));
  }
  return resultList.getImproved();
};
ObjectOperators._applyBinaryOperatorOnListWithObject = function(list, object, operator) {
  var i;
  var resultList = new List();
  for(i = 0; i < list.length; i++) {
    resultList.push(ObjectOperators._applyBinaryOperator(list[i], object, operator));
  }
  resultList.name = list.name;
  return resultList.getImproved();
};
ObjectOperators._applyBinaryOperatorOnObjectWithList = function(object, list, operator) {
  var i;
  var resultList = new List();
  for(i = 0; i < list.length; i++) {
    resultList.push(ObjectOperators._applyBinaryOperator(object, list[i], operator));
  }
  resultList.name = list.name;
  return resultList.getImproved();
};
ObjectOperators._applyBinaryOperator = function(object0, object1, operator) {
  return operator(object0, object1);
};

/**
 * Apply a function to the input argument
 * @param  {Object} input passed into function
 * @param  {Function} func function to be applied, the function receives the input (also accepts a string decribing a function, an operation or a name of a moebio framework function)
 *
 * @param  {Object} optional second parameter
 * @param  {Object} optional third parameter
 * @param  {Object} optional fourth parameter
 * @param  {Object} optional fifth parameter
 * @param  {Object} optional sixth parameter
 * @return {Object}
 * tags:map
 */
ObjectOperators.applyFunctionOnInput = function(input, func, param1, param2, param3, param4, param5) {
  if(input==null || func==null) return;
  if(typeof(func)=="string") func = mo.StringOperators.stringToFunction(func);
  return func.apply(this, [input, param1, param2, param3, param4, param5]);
};

/**
 * Return true if both items are equal, or in case of Lists, Tables and other Objects, if they have the same values internally
 * @param  {Object} object1 is the first item
 * @param  {Object} object2 is the second item
 * @return {Boolean}
 * tags:compare,boolean
 * examples:santiago/examples/modules/isEquivalent
 */
ObjectOperators.isEquivalent = function(obj1, obj2){
  return ObjectOperators._recursiveEquivalent(obj1, obj2);
  /**
  if(obj1==null || obj2==null) return;
  if(obj1==obj2) return true;
  // primitive types
  var type = typeOf(obj1);
  if(type == 'string' || type == 'number' || type == 'boolean')
    return false;
  if(obj1.isEquivalent)
    return obj1.isEquivalent(obj2);
  if(obj1.isEqual)
    return obj1.isEqual(obj2);
  if(JSON.stringify(obj1) == JSON.stringify(obj1))
    return true;

  // For things we do not know how to compare well we return null for now.
  return null;
  **/
};

ObjectOperators._recursiveEquivalent = function(obj1, obj2){
  if(obj1==obj2) return true;
  // primitive types
  var type = typeOf(obj1);
  if(type == 'string' || type == 'number' || type == 'boolean' || type == 'null'){
    // console.log("   _recursiveEquivalent | different value in objects, obj1 with type ", type, ", obj2 with type ", typeOf(obj2));
    // console.log("   _recursiveEquivalent | obj1:", obj1);
    // console.log("   _recursiveEquivalent | obj2:", obj2);
    return false;
  }
  if(obj1 == null || obj2 == null) return false;
  if(Object.keys(obj1).length!=Object.keys(obj2).length){
    //console.log("   _recursiveEquivalent | different number of properties");
    return false;
  }
  
  for(var propName in obj1){
    if(!ObjectOperators._recursiveEquivalent(obj1[propName], obj2[propName])){
      // console.log("   _recursiveEquivalent | different value in property ["+propName+"]");
      // console.log("   _recursiveEquivalent | obj1[propName]:", obj1[propName]);
      // console.log("   _recursiveEquivalent | obj2[propName]:", obj2[propName]);
      return false;
    }
  }
  // Any 2 Dates are considered equivalent since they have no keys. Perhaps other object types also.
  if(Object.keys(obj1).length == 0 && obj1.toString != null && obj2.toString != null)
    return obj1.toString() == obj2.toString();
  return true;
};


/**
 * Return the input object through different outlets depending on the type
 * @param  {Object} obj is the input item
 * @return {Number} numeric value
 * @return {String} string value
 * @return {List} list value
 * @return {Table} table value
 * @return {Network} network value
 * @return {Tree} tree value
 * @return {Image} image value
 * @return {Object} object value
 * tags:
 */
ObjectOperators.splitByType = function(obj) {
  var ret = [
  {
    type: "Number",
    name: "value",
    description: "numeric value",
    value: null
  },
  {
    type: "String",
    name: "value",
    description: "string value",
    value: null
  },
  {
    type: "List",
    name: "value",
    description: "list value",
    value: null
  },
  {
    type: "Table",
    name: "value",
    description: "table value",
    value: null
  },
  {
    type: "Network",
    name: "value",
    description: "network value",
    value: null
  },
  {
    type: "Tree",
    name: "value",
    description: "tree value",
    value: null
  },
  {
    type: "Image",
    name: "value",
    description: "image value",
    value: null
  },
  {
    type: "Object",
    name: "value",
    description: "object value",
    value: null
  }
  ];
  if(obj==null) return ret;
  // primitive types
  var type = typeOf(obj);
  if(type == 'number')
    ret[0].value = obj;
  else if(type == 'string')
    ret[1].value = obj;
  else if(obj.isTable){
    // check before list since we give table priority
    ret[3].value = obj;
    ret[3].type = obj.type;
  }
  else if(obj.isList){
    ret[2].value = obj;
    ret[2].type = obj.type;
  }
  else if(type == 'Tree'){
    ret[5].value = obj;
    ret[5].type = obj.type;
  }
  else if(type == 'Network'){
    ret[4].value = obj;
    ret[4].type = obj.type;
  }
  else if(type == 'Object' && obj.nodeName && obj.nodeName.toLowerCase() === 'img'){
    ret[6].value = obj;
    ret[6].type = 'Image';
  }
  else{
    // everything else
    ret[7].value = obj;
  }
  return ret;
};

/**
 * Copy the top level properties of the second object on top of the first
 * @param  {Object} obj1 is the first item
 * @param  {Object} obj2 is the second item
 * @return {Object}
 * tags:
 */
ObjectOperators.overlayFirstLevelProperties = function(obj1, obj2){
  // clone obj1 or produce an empty object if it is null
  var oRet = obj1 == null ? {} : Object.assign({},obj1);
  // Copy the top level properties of obj2 on top replacing any that are the same
  if(obj2 != null)
    oRet = Object.assign(oRet,obj2);
  return oRet;
};

/**
* Creates a new Table from an array of arrays
* @param {Object[]} array of arrays
* 
* @param {Boolean} firstValueIsColumnName if true make first value of each array the name of the column (Default:true)
* @param {Boolean} treatStringNumbersAsNumeric any value that is a string but a valid number will be converted to numeric form (default:true)
* @return {Table}
* tags:transform
*/
ObjectOperators.arrayOfArraysToTable = function(array_arrays, firstValueIsColumnName, treatStringNumbersAsNumeric){
 if(array_arrays == null) return null;
 firstValueIsColumnName = firstValueIsColumnName==null?true:firstValueIsColumnName;
 treatStringNumbersAsNumeric = treatStringNumbersAsNumeric == null ? true : treatStringNumbersAsNumeric;
 var table = new Table();
 var j0 = firstValueIsColumnName?1:0;
   for(var i=0; i<array_arrays.length; i++){
       table[i] = new List();
       if(firstValueIsColumnName && array_arrays[i].length>0) {
        table[i].name = String(array_arrays[i][0]);
      } else {
        table[i].name = "_";
      }
       for(var j=j0;j<array_arrays[i].length;j++){
          table[i][j-j0] = array_arrays[i][j];
          if(treatStringNumbersAsNumeric && table[i][j-j0] !== null){
            var cellContent = String(table[i][j-j0]);
            var numberCandidate = Number(cellContent.replace(',', '.'));
            var element = (numberCandidate || (numberCandidate === 0 && cellContent !== '')) ? numberCandidate : cellContent;
            if(typeof element == 'string') element = TableEncodings._removeQuotes(element);
            table[i][j-j0] = element;
          }
       }
       table[i] = table[i].getImproved();
   }
   
   return table.getImproved();
};

