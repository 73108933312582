
import Polygon from "src/dataTypes/geometry/Polygon";
import StringList from "src/dataTypes/strings/StringList";
import Point from "src/dataTypes/geometry/Point";

/**
 * @classdesc NumberList Conversions
 *
 * @namespace
 * @category numbers
 */
function NumberListConversions() {}
export default NumberListConversions;

/**
 * Builds an {@link Polygon} from the NumberList,
 * using each consecutive pair of values in the numberList as
 * x and y positions.
 *
 * @param {NumberList} numberlist The NumberList to convert to a Polygon.
 * @return {Polygon} Polygon representing the values
 * in the NumberList as x/y coordinates.
 */
NumberListConversions.toPolygon = function(numberlist) {
  if(numberlist.length === 0) return null;
  var polygon = new Polygon();
  for(var i = 0; numberlist[i + 1] != null; i += 2) {
    polygon.push(new Point(numberlist[i], numberlist[i + 1]));
  }
  return polygon;
};

/**
 * Returns a new {@link StringList} with all values converted to strings
 *
 * @param {NumberList} numberlist The NumberList to convert.
 * @return {StringList} New list.
 */
NumberListConversions.toStringList = function(numberlist) {
  var i;
  var stringList = new StringList();
  for(i = 0; numberlist[i] != null; i++) {
    stringList[i] = String(numberlist[i]);
  }
  stringList.name = numberlist.name;
  return stringList;
};

/**
 * Converts numbers to category values based on a set of numeric thresholds
 * @param {NumberList} nL0 The NumberList to convert.
 * @param {NumberList} nLThresholds is a list of threshold values sorted highest to lowest. For example with [10,5,2] and categories ['A','B','C'] values >= 10 get value 'A', between 5 and 10 get value 'B' etc.
 * @param {StringList} sLCategories is the list of category values to use, one for each threshold
 * @return {StringList} New list.
 * tags:
 */
NumberListConversions.toCategoriesByThresholds = function(nL0, nLThresholds, sLCategories) {
  if(nL0 == null || nLThresholds == null || sLCategories == null) return null;
  if(nLThresholds.type != 'NumberList' || sLCategories.length != nLThresholds.length)
    throw new Error('Invalid input for toCategoriesByThresholds');
  var i,j,sCat;
  var sLOut = new StringList();
  sLOut.name = nL0.name == '' ? 'Categories' : nL0.name + ' Categories';
  for(i = 0; i < nL0.length; i++) {
    sCat = sLCategories[sLCategories.length-1];
    for(j = 0; j < nLThresholds.length; j++){
      if(nL0[i] >= nLThresholds[j]){
        sCat = sLCategories[j];
        break;
      }
    }
    sLOut.push(sCat);
  }
  return sLOut;
};
