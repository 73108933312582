import Point from 'src/dataTypes/geometry/Point';
import Polygon from 'src/dataTypes/geometry/Polygon';
import ColorOperators from "src/operators/graphic/ColorOperators";
import GeometryOperators from 'src/operators/geometry/GeometryOperators';
import Rectangle from 'src/dataTypes/geometry/Rectangle';
import Interval from "src/dataTypes/numeric/Interval";
import { TwoPi, HalfPi } from "src/Global";

/**
 * @classdesc A graphics object provides a surface for drawing and interaction
 * with drawn primitives.
 *
 * @description Creates a new graphcis object and initializes basic configuration
 * and optionally starts the draw cycle.
 *
 * @param {Object} options valid properties described below
 * @param {String|DOMNode|undefined|false} options.container a string selector or reference
 *                                                           to a node where the canvas for this
 *                                                           graphics object will be placed.
 *                                                           If this is a falsy value, then the graphics object
 *                                                           will not be attached to the DOM and can be used as an
 *                                                           offscreen bugger.
 * @param {Object} options.dimensions object with width and height properties to specify
 *                                    the dimensions of the canvas. If this is not passed
 *                                    in, the canvas will grow to always fit the size of its
 *                                    container.
 * @param {Function} options.init custom user function to initialize drawing related
 *                                things. Called once after base initialization.
 * @param {Function} options.cycle custom user function to render each frame. This
 *                                 is called once per frame of the draw loop unless
 *                                 cycleInterval is set to 0.
 * @param {Function} options.onResize custom user function to run on resize of the canvas *
 * @param {Number} options.cycleInterval how often in muilliseconds to run the cycle function
 *                                       if set to zero the cycle function will only run once.
 * @param {Boolean} noLoop if this is true only run the cycle function once,
 * @param {Boolean} noStart if this is true the cycle function will not be automatically started.
 *
 * @constructor
 * @category drawing
 */
function Graphics(options) {
  if(options==null){
    options = {
      container: document.querySelector( "#maindiv" ),
        cycle: function() {
        
        },
        onResize: function(){
          
        }
    }
  }
  this.container = options.container;
  if (typeof this.container === 'string') {
    // Assume it is a selector and try and find the actual DOM node
    this.container = document.querySelector(this.container);
  } else {
    this.container = options.container;
  }
  this.dimensions = options.dimensions;



  this.init = options.init || noOperation;
  this.cycle = options.cycle || noOperation;
  this.onResize = options.onResize || noOperation;
  this._cycleInterval = options.cycleInterval === undefined ? 30 : options.cycleInterval;
  if (options.noLoop) {
    this._cycleInterval = 0;
  }

  this._initialize(!options.noStart);
}
export default Graphics;
function noOperation() { }


/*****************************
 * Private lifecycle functions
 ****************************/

/*
 * Initialize the graphics proper. Includes
 * things like actually creating the backing canvas and
 * setting up mousehandlers.
 * @ignore
 */
Graphics.prototype._initialize = function (autoStart) {
  this.cW = 1; // canvas width
  this.cH = 1; // canvas height
  this.cX = 1; // canvas center x
  this.cY = 1; // canvas center y
  this.mX = 0; // cursor x
  this.mY = 0; // cursor y
  this.mP = new Point(0, 0); // cursor point // YY why have this and mX and mY
  this.nF = 0; // number of current frame since first cycle
  this.MOUSE_DOWN = false; //true on the frame of mousedown event
  this.MOUSE_UP = false; //true on the frame of mouseup event
  this.MOUSE_UP_FAST = false; //true on the frame of mouseup event
  this.WHEEL_CHANGE = 0; //differnt from 0 if mousewheel (or pad) moves / STATE
  this.NF_DOWN = undefined; //number of frame of last mousedown event
  this.NF_UP = undefined; //number of frame of last mouseup event
  this.MOUSE_PRESSED = undefined; //true if mouse pressed / STATE
  this.MOUSE_IN_DOCUMENT = true; //true if cursor is inside document / STATE
  this.mX_DOWN = undefined; // cursor x position on last mousedown event
  this.mY_DOWN = undefined; // cursor x position on last mousedown event
  this.mX_UP = undefined; // cursor x position on last mousedown event
  this.mY_UP = undefined; // cursor y position on last mousedown event
  this.PREV_mX = 0; // cursor x position previous frame
  this.PREV_mY = 0; // cursor y position previous frame
  this.DX_MOUSE = 0; //horizontal movement of cursor in last frame
  this.DY_MOUSE = 0; //vertical movement of cursor in last frame
  this.DX_MOUSE_PRESSED = 0; //horizontal movement of cursor in last frame
  this.DY_MOUSE_PRESSED = 0; //vertical movement of cursor in last frame
  this.DX_MOUSE_DRAGGED = 0;
  this.DY_MOUSE_DRAGGED = 0;
  this.MOUSE_MOVED = false; //boolean that indicates wether the mouse moved in the last frame / STATE
  this.T_MOUSE_PRESSED = 0; //time in milliseconds of mouse being pressed, useful for sutained pressure detection
  this.SHIFT_PRESSED = false; //true if SHIFT key is pressed
  this.IS_TOUCH = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
  this.force = 0; //in touchscreen cases, measures touch force, between 0 and 1

  this.KEY_JUST_PRESSED = null; //value of key just pressed in current frame
  this.KEY_PRESSED = null; //value of key pressed (value is not null while key is pressed)

  this.cursorStyle = 'auto';
  this.backGroundColor = 'white'; // YY why keep this if we only use the rgb version
  this.backGroundColorRGB = [255, 255, 255];
  this.cycleActive = undefined; // YY why do we need to maintain this state?

  // this._cycleOnMouseMovement = false;
  this._prevMouseX = 0;
  this._prevMouseY = 0;
  this._setIntervalId = undefined;
  this._setTimeOutId = undefined;
  this._interactionCancelledFrame = undefined;
  this._tLastMouseDown = undefined;
  this._alphaRefresh = 0; //if _alphaRefresh>0 instead of clearing the canvas each frame, a transparent rectangle will be drawn
  this.END_CYCLE_DELAY = 3000; //time in milliseconds, from last mouse movement to the last cycle to be executed in case cycleOnMouseMovement has been activated
  this.LISTENERS_ADDED = false;

  this.fontColor = "#000000";
  this.fontSize = "14";
  this.fontName = "Arial";
  this.fontAlign = 'left';
  this.fontBaseline = "top";
  this.fontStyle = "";

  // Create the canvas and get it ready for drawing
  this.canvas = document.createElement("canvas");

  //Allow the canvas to be focusable to enable keydown and keyup
  this.canvas.setAttribute("tabindex", "10000");
  //Hide the focus styling
  

  //var canvasStyle = "outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0);";
  //this.canvas.setAttribute("style", canvasStyle);

  
  if (this.container) {
    // If this container is falsy, then the canvas is not attached to the DOM
    // and can be used as an offscreen buffer.
    this.container.appendChild(this.canvas);
  }
  this.context = this.canvas.getContext("2d");

  this._adjustCanvas(this.dimensions);

  // YY TODO allow user to bind to these events as well. Probably
  // through a generic event mechanism. c.f. global addInteractionEventListener
  var boundMouseOrKeyboard = this._onMouseOrKeyBoard.bind(this);


  if (this.IS_TOUCH) {
    this.canvas.addEventListener("touchstart", boundMouseOrKeyboard, false);
    this.canvas.addEventListener("touchend", boundMouseOrKeyboard, false);
    this.canvas.addEventListener("touchmove", boundMouseOrKeyboard, false);
    //this.canvas.addEventListener("gesturestart", boundMouseOrKeyboard, false);
    //this.canvas.addEventListener("gestureend", boundMouseOrKeyboard, false);
    //this.canvas.addEventListener("gesturechange", boundMouseOrKeyboard, false);
  } //else {

  //mouse listeners are added regardles of wether the device is TOUCH
  this.canvas.addEventListener("mousemove", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("mousedown", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("mouseup", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("mouseenter", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("mouseleave", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("click", boundMouseOrKeyboard, false);

  this.canvas.addEventListener("DOMMouseScroll", boundMouseOrKeyboard, false);

  //this.canvas.addEventListener("mousewheel", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("mousewheel", boundMouseOrKeyboard, { passive: false });//TEST as per Dani's request
  //}

  this.canvas.addEventListener("keydown", boundMouseOrKeyboard, false);
  this.canvas.addEventListener("keyup", boundMouseOrKeyboard, false);

  // Setup resize listeners
  var boundResize = this._onResize.bind(this);

  // As moebio framework's instance CAN be shared by different contexts (i.e. Lichen, each different module, etc...)
  // we need to assign the listener to the container's window ('elementWindow' variable).
  // If we use 'window', as we used to do, the 'resize' event will only trigger when resizing the TOP window (usually Lichen)
  var elementDoc, elementWindow;
  // "this.container" is usually expectd to be properly set....
  if( this.container != null ){
    elementDoc = this.container.ownerDocument;
    if( elementDoc ){
      elementWindow = elementDoc.defaultView || elementDoc.parentWindow;
    }else{
      // Default to currently available window
      console.warn( "Unable to add listener for 'resize' event. Container's document not found");
      elementWindow = window;
    }
    // But there are exceptions (i.e., GeoMap module).
    // In those cases use the available window object.
    // To-Do: review if this is a valid, final approach
  }else{
    elementWindow = window;
  }
  elementWindow.addEventListener("resize", resizeThrottler(boundResize, 66), false);
  

  // Infrastructure for custom event handlers
  this._listeners = {
    "mousemove": [],
    "mousedown": [],
    "mouseup": [],
    "mouseenter": [],
    "mouseleave": [],
    "mousewheel": [],
    "click": [],
    "keydown": [],
    "keyup": []
  };

  if (this.IS_TOUCH) {
    console.log("touchscreen detected");
    this._listeners["touchstart"] = [];
    this._listeners["touchend"] = [];
    this._listeners["touchmove"] = [];
  }

  this.context.fillStyle = 'black';

  // Call the user provided init function.
  this.init();

  // Start the draw loop
  if (autoStart) {
    this._startCycle();
  }

  //useful to store information in the Graphics instance
  //specially useful when used through MoebioCanvas module
  this.memory = {};

};


/*
  Helper function for getting the mouse position
  see http://stackoverflow.com/a/19048340
 */
/**
 * Helper function for getting the mouse position
 * see http://stackoverflow.com/a/19048340
 *
 * @param  {Event} evt
 * @ignore
 */
Graphics.prototype._getRelativeMousePos = function (evt) {
  var rect = this.canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top
  };
};

/**
 * This method is used to handle user interaction events (e.g. mouse events). *
 * Sets some internal state and then displatches to external event handlers.
 *
 * @param  {Event} e event object
 * @ignore
 */
Graphics.prototype._onMouseOrKeyBoard = function (e) {
  var pos;

  if (this.IS_TOUCH) {
    var touches = e.touches != null && e.touches[0] != null ? e.touches[0] : e.targetTouches[0];
    console.log('_onMouseOrKeyBoard, e.type:' + e.type);
  }

  switch (e.type) {
    case "touchmove":
      this.force = touches.force;
    case "mousemove":
      if (e.type == "mousemove") {
        pos = this._getRelativeMousePos(e);
      } else {
        pos = { x: touches.clientX == null ? touches.pageX : touches.clientX, y: touches.clientY == null ? touches.clientY : touches.clientY };
      }

      this.mX = pos.x;
      this.mY = pos.y;

      this.mP.x = this.mX;
      this.mP.y = this.mY;

      this.MOUSE_IN_DOCUMENT = true;
      break;
    case "mousedown":
    case "touchstart":
      if (e.type == "touchstart") {
        this.mX = touches.clientX;
        this.mY = touches.clientY;
      }

      this.NF_DOWN = this.nF;
      this.MOUSE_PRESSED = true;
      this.T_MOUSE_PRESSED = 0;
      this._tLastMouseDown = new Date().getTime();
      this.mX_DOWN = this.mX;
      this.mY_DOWN = this.mY;
      this.MOUSE_IN_DOCUMENT = true;

      break;
    case "mouseup":
    case "touchend":
      this.NF_UP = this.nF;
      this.MOUSE_PRESSED = false;
      this.T_MOUSE_PRESSED = 0;
      this.mX_UP = this.mX;
      this.mY_UP = this.mY;
      this.MOUSE_IN_DOCUMENT = true;
      this.MOUSE_UP = true;
      break;
    case "mouseenter":
      this.MOUSE_IN_DOCUMENT = true;
      break;
    case "mouseleave":
      this.MOUSE_IN_DOCUMENT = false;
      this.SHIFT_PRESSED = false;
      break;
    case "keydown":
      this.SHIFT_PRESSED = e.shiftKey;
      this.KEY_PRESSED = e.key;
      this.KEY_JUST_PRESSED = e.key;
      break;
    case "keyup":
      this.SHIFT_PRESSED = false;
      this.KEY_PRESSED = null;
      break;
    case "mousewheel":
      //stops propagation for mouse wheel
      //added for solving interference with Vertical Landscape
      //e.preventDefault();
      //e.stopPropagation();
      ////////////////////
      break;

    //"gesturestart"
    //"gestureend"
    //"gesturechange"
  }



  this._emit(e.type, e);

  if (this.cycleActive && new Date().getTime() > this._LAST_TIME + 33) {
    this._onCycle();
  }
};


/*
 * Helper to throttle resize events for improved performance.
 * See https://developer.mozilla.org/en-US/docs/Web/Events/resize
 */
function resizeThrottler(actualResizeHandler, interval) {
  var resizeTimeout;
  return function (e) {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        resizeTimeout = null;
        actualResizeHandler(e);
      }, interval);
    }
  };
}

/**
 * This method is used to handle resizes of the window, and then optionally
 * pass that on to the user defined onResize method if the container dimensions
 * have changed.
 *
 * @param  {Event} e resize event
 * @ignore
 */
Graphics.prototype._onResize = function (e) {
  var currentW = this.cW;
  var currentH = this.cH;
  // If the user has set the dimensions explicitly
  // we do not auto adjust the canvas.
  if (this.dimensions === undefined) {
    this._adjustCanvas();
  }

  if (this.onResize !== noOperation) {
    var newDim = this._containerDimensions();
    if (newDim.width !== currentW || newDim.height !== currentH) {
      // Forward the event to the user defined resize handler
      this.onResize(e);
    }
  }
};

/**
 * Return the dimensions of the container that this graphics object
 * is associated with.
 *
 * @return {Object} object with width and height properties.
 * @ignore
 */
Graphics.prototype._containerDimensions = function () {
  // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
  if (this.container) {
    return {
      width: this.container.clientWidth,
      height: this.container.clientHeight
    };
  } else {
    return {
      width: 0,
      height: 0
    };
  }
};

Graphics.prototype._adjustCanvas = function (dimensions) {
  if (dimensions !== undefined) {
    this.cW = dimensions.width;
    this.cH = dimensions.height;
  } else {
    // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements

    var dim = this._containerDimensions();
    this.cW = dim.width;
    this.cH = dim.height;
  }

  this.cX = Math.floor(this.cW * 0.5);
  this.cY = Math.floor(this.cH * 0.5);

  this.canvas.setAttribute('width', this.cW);
  this.canvas.setAttribute('height', this.cH);

};



/////////////////////////////////////////////////////////////////////////////////
////////////////////// cycle ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


/*
 * Starts or restarts the draw cycle at the current cycleInterval
 * @ignore
 */
Graphics.prototype._startCycle = function () {
  this.cycleActive = true;
  //console.log('[G] _startCycle');
  if (this._cycleInterval === 0) {
    // Call the cycle only once function
    setTimeout(this._onCycle.bind(this), 10);
  } else {
    clearInterval(this._setIntervalId);
    this._setIntervalId = setInterval(this._onCycle.bind(this), this._cycleInterval);
  }
};

/**
 * Stops the draw cycle
 * @ignore
 */
Graphics.prototype._stopCycle = function (callback) {

  clearInterval(this._setIntervalId);
  this.cycleActive = false;
  this._setIntervalId = undefined;

  if (callback) {
    callback();
  }
};

Graphics.prototype.setDimensions = function (width, height) {
  this._adjustCanvas({width:width,height:height});
}

/**
 * Run the cycle function for a certain amount of time and then stop it.
 *
 * Can be called multiple times to delay the stop time to being time milliseconds
 * after the last call to this function.
 * @param  {Number} time time in milliseconds to run the cycle function before stopping ot. if 0 it starts and endless cycle, if 1 it executes the cycle once
 */
Graphics.prototype.cycleFor = function (time) {
  //console.log("*** cycleFor | time:", time);
  if (time === 0) {//infinite
    if (this._setIntervalId) clearTimeout(this._setTimeOutId);
    this._startCycle();
  } else if (time == 1) {//one frame cycle
    //if(this._setIntervalId) clearTimeout(this._setTimeOutId);
    //console.log("*** cycleFor | _stopCycle / _onCycle");
    this._stopCycle();
    this._onCycle();
  } else {//specified number of frames
    if (this._setIntervalId) {
      clearTimeout(this._setTimeOutId);
    } else {
      //this._onCycle(); // <----- maybe this is a good idea
      this._startCycle();
    }
    this._stopAfter(time);
  }
};

Graphics.prototype._stopAfter = function (time, callback) {
  var self = this;
  this._setTimeOutId = setTimeout(function () {
    self._stopCycle();
    if (callback) {
      callback();
    }
  }, time);
};


/*
 * This function is called on every cycle of the draw loop.
 * It is responsible for clearing the background and then calling
 * the user defined cycle function.
 */
Graphics.prototype._onCycle = function () {
  //console.log('[G] _onCycle');
  if (this._alphaRefresh === 0) {
    if (this.backGroundColorRGB != null) {
      this.context.fillStyle =
        'rgb(' + this.backGroundColorRGB[0] +
        ',' + this.backGroundColorRGB[1] +
        ',' + this.backGroundColorRGB[2] +
        ')';
      this.context.fillRect(0, 0, this.cW, this.cH);
    } else {
      this.context.clearRect(0, 0, this.cW, this.cH);
    }
  } else {
    this.context.fillStyle =
      'rgba(' + this.backGroundColorRGB[0] +
      ',' + this.backGroundColorRGB[1] +
      ',' + this.backGroundColorRGB[2] +
      ',' + this._alphaRefresh + ')';
    this.context.fillRect(0, 0, this.cW, this.cH);
  }

  this.setCursor('default');

  this.MOUSE_DOWN = this.NF_DOWN == this.nF;
  this.MOUSE_UP = this.NF_UP == this.nF;
  this.MOUSE_UP_FAST = this.MOUSE_UP && (this.nF - this.NF_DOWN) < 9;

  this.DX_MOUSE = this.mX - this.PREV_mX;
  this.DY_MOUSE = this.mY - this.PREV_mY;
  this.MOUSE_MOVED = this.DX_MOUSE !== 0 || this.DY_MOUSE !== 0;

  if (this.MOUSE_PRESSED) {
    this.T_MOUSE_PRESSED = new Date().getTime() - this._tLastMouseDown;
    this.DX_MOUSE_PRESSED = this.DX_MOUSE;
    this.DY_MOUSE_PRESSED = this.DY_MOUSE;
    this.DX_MOUSE_DRAGGED = this.mX - this.mX_DOWN;
    this.DY_MOUSE_DRAGGED = this.mY - this.mY_DOWN;
  } else {
    this.DX_MOUSE_PRESSED = 0;
    this.DY_MOUSE_PRESSED = 0;
    this.DX_MOUSE_DRAGGED = 0;
    this.DY_MOUSE_DRAGGED = 0;
  }

  // Call the user provided cycle function.
  this.cycle();

  this.WHEEL_CHANGE = 0;
  this.PREV_mX = this.mX;
  this.PREV_mY = this.mY;
  this.nF++;
  this.KEY_JUST_PRESSED = null;

  this._LAST_TIME = new Date().getTime();

  //if(this.cycleActive) window.requestAnimationFrame(this._onCycle);
  //if(this.cycleActive) window.requestAnimationFrame(function(){ instance._onCycle(instance) });
};

this._LAST_TIME;//hack


/*
 * Emit events to registered listeners.
 *
 * This function also does any normalization/customization
 * to the standard DOM events that the graphics object provides.
 */
Graphics.prototype._emit = function (eventName, e) {
  switch (eventName) {
    case 'mousewheel':
    case 'DOMMouseScroll':
      eventName = 'mousewheel';
      if (!e) {
        e = window.event;
      }
      if (e.wheelDelta) {
        this.WHEEL_CHANGE = e.wheelDelta / 120;
      } else if (e.detail) { /** Mozilla case. */
        this.WHEEL_CHANGE = -e.detail / 3;
      }
      e.value = this.WHEEL_CHANGE;
      break;
  }

  // Actually dispatch the event after any special handling
  var listenersLength = this._listeners[eventName].length;
  for (var i = 0; i < listenersLength; i++) {
    var callback = this._listeners[eventName][i];
    callback.call(callback.__context, e);
  }

  /*
  if(eventName == 'mousewheel'){
    //stops propagation for mouse wheel
    //added for solving interference with Vertical Landscape
    //e.preventDefault();
    e.stopPropagation();
    ////////////////////
  }
  */
};

/*****************************
 * Public lifecycle functions
 ****************************/

/**
 * Returns the current interval between calls to the cycle function.
 * @return {Number} the current cycleInterval (in milliseconds)
 */
Graphics.prototype.getCycleInterval = function () {
  return this._cycleInterval;
};

/**
 * Sets the current interval between calls to the cycle function.
 *
 * @param {Number} cycleInterval the interval in milliseconds at which
 *                               the cycle function should be called.
 */
Graphics.prototype.setCycleInterval = function (cycleInterval) {
  this._cycleInterval = cycleInterval;
  if (this.cycleActive) { // YY this should be removed
    this._startCycle();
  }
};

/**
 * Starts the draw loop for this graphcis object.
 *
 * Note that the draw loop is typically automatically started on
 * creation of a graphics object.
 */
Graphics.prototype.start = function () {
  return this._startCycle();
};

/**
 * Stops the draw loop for this graphics object.
 */
Graphics.prototype.stop = function () {
  return this._stopCycle();
};


/**
 * Set the cycle function to only run mouse movement and have
 * it run for a given amount of time after the mouse movement
 * has ended. If a cycle function is currently running it will
 * continue to run until time has elapsed. If you want it to stop
 * immediately @see stop.
 *
 *
 * @param  {Number} time time in milliseconds after which the cycle function will
 *                       continue to run
 */
Graphics.prototype.cycleOnMouseMovement = function (time) {
  var self = this;
  if (this.cycleOnMouseMovementListener) {
    if (this.LISTENERS_ADDED && time <= 1) {
      //console.log("[G] remove listeners");

      this.canvas.removeEventListener('mousemove', this.cycleOnMouseMovementListener, false);
      this.canvas.removeEventListener('mousewheel', this.cycleOnMouseMovementListener, false);
      this.canvas.removeEventListener('mousedown', this.cycleOnMouseMovementListener, false);
      this.canvas.removeEventListener('keydown', this.cycleOnMouseMovementListener, false);
      this.canvas.removeEventListener('keyup', this.cycleOnMouseMovementListener, false);

      if (this.IS_TOUCH) {
        this.canvas.removeEventListener("touchstart", this.cycleOnMouseMovementListener, false);
        this.canvas.removeEventListener("touchend", this.cycleOnMouseMovementListener, false);
        this.canvas.removeEventListener("touchmove", this.cycleOnMouseMovementListener, false);
      }

      this.LISTENERS_ADDED = false;
    }
  }

  if (time > 1) {
    this.cycleOnMouseMovementListener = function () {
      self.cycleFor(time);
    };

    if (!this.LISTENERS_ADDED) {

      this.canvas.addEventListener('mousemove', this.cycleOnMouseMovementListener, false);

      //this.canvas.addEventListener('mousewheel', this.cycleOnMouseMovementListener, false);
      this.canvas.addEventListener('mousewheel', this.cycleOnMouseMovementListener, { passive: false }); //TEST as per Dani's request
      //console.log("[G] add listeners");

      this.canvas.addEventListener('mousedown', this.cycleOnMouseMovementListener, false);
      this.canvas.addEventListener('keydown', this.cycleOnMouseMovementListener, false);
      this.canvas.addEventListener('keyup', this.cycleOnMouseMovementListener, false);

      if (this.IS_TOUCH) {
        this.canvas.addEventListener("touchstart", this.cycleOnMouseMovementListener, false);
        this.canvas.addEventListener("touchend", this.cycleOnMouseMovementListener, false);
        this.canvas.addEventListener("touchmove", this.cycleOnMouseMovementListener, false);
      }

      this.LISTENERS_ADDED = true;
    }
    //self.cycleFor(time);
  }

  self.cycleFor(time);
};

/**
 * Subscribe to an event that is emitted by the graphics object.
 *
 * The graphics object emits the following events.
 *
 * "mousemove"
 * "mousedown"
 * "mouseup"
 * "mouseenter
 * "mouseleave
 * "mousewheel
 * "click"
 * "keydown"
 * "keyup"
 *
 * @param  {String}   eventName one of the event types above
 * @param  {Function} callback  function to call when that event occurs
 *                              this function will be passed an event object
 */
Graphics.prototype.on = function (eventName, callback, context) {
  // allow clients to subscribe to events on the canvas.
  callback.__context = context;
  this._listeners[eventName].push(callback);
};

/**
 * Remove an event listener.
 * @param  {String}   eventName one of the event types that the graphics object
 *                              emits.
 * @param  {Function} callback  the function you want to remove. Note that this should
 *                              be a reference to a function that was previously added with
 *                              @see on
 */
Graphics.prototype.off = function (eventName, callback) {
  var index = this._listeners[eventName].indexOf(callback);
  if (index > -1) {
    this._listeners[eventName].splice(index, 1);
  }
};

/*****************************
 * Public drawing functions
 *****************************/

/**
 * Clear the canvas.
 */
Graphics.prototype.clearCanvas = function () {
  this.context.clearRect(0, 0, this.cW, this.cH);
};

/**
 * Set the background color for the graphics object.
 *
 * On every cycle of the draw loop the canvas will be cleared to this color.
 * Note that if you do set a color, you need to also set the background alpha as well
 *
 * @see  setBackgroundAlpha
 *
 * @param {String|Array|Number} color A string, array or individual numbers representing the rgb color.
 */
Graphics.prototype.setBackgroundColor = function (color) {
  if (typeof color === "number") {
    if (arguments.length > 3) {
      color = 'rgba(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ',' + arguments[3] + ')';
    } else {
      color = 'rgb(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ')';
    }
  } else if (Array.isArray(color)) {
    color = ColorOperators.RGBtoHEX(color[0], color[1], color[2]);
  }
  this.backGroundColor = color;
  this.backGroundColorRGB = ColorOperators.colorStringToRGB(this.backGroundColor);
};

/**
 * Set the alpha (opacity) of the background color. Defaults to 0 (transparent).
 *
 * @param {Number} backgroundAlpha a number from 0-1
 */
Graphics.prototype.setBackgroundAlpha = function (backgroundAlpha) {//TODO: this is not alpha background, is alpha refresh…
  this._alphaRefresh = backgroundAlpha;
};



/**
 * Draws a filled in Rectangle.
 * Fill color is expected to be set using {@link setFill}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @example
 * setFill('steelblue');
 * fRect(10, 10, 40, 40);
 *
 */
Graphics.prototype.fRect = function (x, y, width, height) {
  if (typeof x != 'number') {
    y = x.y;
    width = x.width;
    height = x.height;
    x = x.x;
  }
  this.context.fillRect(x, y, width, height);
};

/**
 * Draws a stroked Rectangle - showing just an outline.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @example
 * setStroke('orange');
 * sRect(10, 10, 40, 40);
 *
 */
Graphics.prototype.sRect = function (x, y, width, height) {
  if (typeof x != 'number') {
    y = x.y;
    width = x.width;
    height = x.height;
    x = x.x;
  }
  this.context.strokeRect(x, y, width, height);
};

/**
 * Draws a filled and stroked Rectangle.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @example
 * setFill('steelblue');
 * setStroke('orange');
 * fsRect(10, 10, 40, 40);
 *
 */
Graphics.prototype.fsRect = function (x, y, width, height) {
  if (typeof x != 'number') {
    y = x.y;
    width = x.width;
    height = x.height;
    x = x.x;
  }
  this.context.fillRect(x, y, width, height);
  this.context.strokeRect(x, y, width, height);
};

/**
 * Draws a filled in Arc.
 * Fill color is expected to be set using {@link setFill}.
 *
 * @param {Number} x X position of center of the Arc.
 * @param {Number} y Y position of center of the Arc.
 * @param {Number} r Radius of the Arc.
 * @param {Number} a0 first angle of the Arc.
 * @param {Number} a1 second angle of the Arc.
 * @example
 * setFill('steelblue');
 * fArc(40, 40, 20, 0.5, 0.8);
 *
 */
Graphics.prototype.fArc = function (x, y, r, a0, a1, counterclockwise) {
  this.context.beginPath();
  this.context.arc(x, y, r, a0, a1, counterclockwise);
  this.context.fill();
};

/**
 * Draws a stroked Arc.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of center of the Arc.
 * @param {Number} y Y position of center of the Arc.
 * @param {Number} r Radius of the Arc.
 * @param {Number} a0 first angle of the Arc.
 * @param {Number} a1 second angle of the Arc.
 * @example
 * setStroke('orange', 5);
 * sArc(40, 40, 20, 0.5, 0.8);
 *
 */
Graphics.prototype.sArc = function (x, y, r, a0, a1, counterclockwise) {
  this.context.beginPath();
  this.context.arc(x, y, r, a0, a1, counterclockwise);
  this.context.stroke();
};

/**
 * Draws a filled in Circle.
 * Fill color is expected to be set using {@link setFill}.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @example
 * setFill('steelblue');
 * fCircle(40, 40, 20);
 *
 */
Graphics.prototype.fCircle = function (x, y, r) {
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi);
  this.context.fill();
};

/**
 * Draws a stroked Circle.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @example
 * setStroke('orange', 5);
 * sCircle(40, 40, 20);
 *
 */
Graphics.prototype.sCircle = function (x, y, r) {
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi);
  this.context.stroke();
};

/**
 * Draws a filled and stroked Circle.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @example
 * setStroke('steelblue');
 * sCircle(40, 40, 20);
 *
 */
Graphics.prototype.fsCircle = function (x, y, r) {
  this.fCircle(x, y, r);
  this.context.stroke();
};

/**
 * Draws a filled in Ellipse.
 * Fill color is expected to be set using {@link setFill}.
 *
 * @param {Number} x X position of center of the Ellipse.
 * @param {Number} y Y position of center of the Ellipse.
 * @param {Number} rW Radial width of the Ellipse.
 * @param {Number} rH Radial height of the Ellipse.
 * @example
 * setFill('steelblue');
 * fEllipse(40, 40, 20, 30);
 */
Graphics.prototype.fEllipse = function (x, y, rW, rH) {
  var k = 0.5522848, // 4 * ((√(2) - 1) / 3)
    ox = rW * k, // control point offset horizontal
    oy = rH * k, // control point offset vertical
    xe = x + rW, // x-end
    ye = y + rH; // y-end
  this.context.beginPath();
  this.context.moveTo(x - rW, y);
  this.context.bezierCurveTo(x - rW, y - oy, x - ox, y - rH, x, y - rH);
  this.context.bezierCurveTo(x + ox, y - rH, xe, y - oy, xe, y);
  this.context.bezierCurveTo(xe, y + oy, x + ox, ye, x, ye);
  this.context.bezierCurveTo(x - ox, ye, x - rW, y + oy, x - rW, y);
  this.context.moveTo(x - rW, y);
  this.context.closePath();
  this.context.fill();
};

/**
 * Draws a stroked Ellipse.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of center of the Ellipse.
 * @param {Number} y Y position of center of the Ellipse.
 * @param {Number} rW Radial width of the Ellipse.
 * @param {Number} rH Radial height of the Ellipse.
 * @example
 * setStroke('orange');
 * sEllipse(40, 40, 20, 30);
 */
Graphics.prototype.sEllipse = function (x, y, rW, rH) {
  var k = 0.5522848,
    ox = rW * k,
    oy = rH * k,
    xe = x + rW,
    ye = y + rH;
  this.context.beginPath();
  this.context.moveTo(x - rW, y);
  this.context.bezierCurveTo(x - rW, y - oy, x - ox, y - rH, x, y - rH);
  this.context.bezierCurveTo(x + ox, y - rH, xe, y - oy, xe, y);
  this.context.bezierCurveTo(xe, y + oy, x + ox, ye, x, ye);
  this.context.bezierCurveTo(x - ox, ye, x - rW, y + oy, x - rW, y);
  this.context.moveTo(x - rW, y);
  this.context.closePath();
  this.context.stroke();
};

/**
 * Draws a filled and stroked Ellipse.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of center of the Ellipse.
 * @param {Number} y Y position of center of the Ellipse.
 * @param {Number} rW Radial width of the Ellipse.
 * @param {Number} rH Radial height of the Ellipse.
 * @example
 * setFill('steelblue');
 * setStroke('steelblue');
 * fsEllipse(40, 40, 20, 30);
 */
Graphics.prototype.fsEllipse = function (x, y, rW, rH) {
  this.fEllipse(x, y, rW, rH);
  this.context.stroke();
};


/**
 * TODO add comments to this to explain what it does.
 * @ignore
 */
Graphics.prototype._solidArc = function (x, y, a0, a1, r0, r1) {
  this.context.beginPath();
  this.context.arc(x, y, r0, a0, a1);
  this.context.lineTo(x + r1 * Math.cos(a1), y + r1 * Math.sin(a1));
  this.context.arc(x, y, r1, a1, a0, true);
  this.context.lineTo(x + r0 * Math.cos(a0), y + r0 * Math.sin(a0));
};

/**
 * [fSolidArc description]
 * @todo document fSolidArc
 */
Graphics.prototype.fSolidArc = function (x, y, a0, a1, r0, r1) {
  this._solidArc(x, y, a0, a1, r0, r1);
  this.context.fill();
};

/**
 * [sSolidArc description]
 * @todo document sSolidArc
 */
Graphics.prototype.sSolidArc = function (x, y, a0, a1, r0, r1) {
  this._solidArc(x, y, a0, a1, r0, r1);
  this.context.stroke();
};

/**
 * [fsSolidArc description]
 * @todo document fsSolidArc
 */
Graphics.prototype.fsSolidArc = function (x, y, a0, a1, r0, r1) {
  this.fSolidArc(x, y, a0, a1, r0, r1);
  this.context.stroke();
};


/**
 * Draws a line from a start position to an end position
 *
 * @param {Number} x0 Starting x position.
 * @param {Number} y0 Starting y position.
 * @param {Number} x1 Ending x position.
 * @param {Number} y1 Ending y position.
 * @example
 * setStroke('black');
 * line(0, 0, 40, 40);
 */
Graphics.prototype.line = function (x0, y0, x1, y1) {
  this.context.beginPath();
  this.context.moveTo(x0, y0);
  this.context.lineTo(x1, y1);
  this.context.stroke();
};

/**
 * Draws a bezier curve using {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingthis.context.D/bezierCurveTo|bezierCurveTo}
 *
 * @param {Number} x0 Starting x position.
 * @param {Number} y0 Starting y position.
 * @param {Number} cx0 First curve control point x position.
 * @param {Number} cy0 First cure control point y position.
 * @param {Number} cx1 Second curve control point x position.
 * @param {Number} cy1 Second cure control point y position.
 * @param {Number} x1 Ending x position.
 * @param {Number} y1 Ending y position.
 * @example
 * setStroke('black');
 * bezier(10, 10, 10, 0, 40, 0, 40, 10);
 */
Graphics.prototype.bezier = function (x0, y0, cx0, cy0, cx1, cy1, x1, y1) {
  this.context.beginPath();
  this.context.moveTo(x0, y0);
  this.context.bezierCurveTo(cx0, cy0, cx1, cy1, x1, y1);
  this.context.stroke();
};


/**
 * @todo add comments to this to explain what it does.
 * @ignore
 */
Graphics.prototype._lines = function () {
  if (arguments == null) return;

  var args = arguments[0];
  this.context.beginPath();
  this.context.moveTo(args[0], args[1]);
  for (var i = 2; args[i + 1] != null; i += 2) {
    this.context.lineTo(args[i], args[i + 1]);
  }
};

/**
 * TODO add comments to this to explain what it does.
 * @ignore
 */
Graphics.prototype._linesM = function () {
  if (arguments == null) {
    return;
  }

  var args = arguments[0];
  var p = new Polygon();
  this.context.beginPath();
  this.context.moveTo(args[0], args[1]);
  p[0] = new Point(args[0], args[1]);
  for (var i = 2; args[i + 1] != null; i += 2) {
    this.context.lineTo(args[i], args[i + 1]);
    p.push(new Point(args[i], args[i + 1]));
  }
  return p.containsPoint(this.mP);
};

/**
 * Draws a filled polygon using a series of
 * x/y positions.
 *
 * @param {...Number} positions x and y positions for the Polygon
 * @example
 * // This draws a filled triangle
 * // Inputs are pairs of x/y positions.
 * setFill('steelblue').
 * fLines(10, 10, 40, 10, 40, 40);
 *
 */
Graphics.prototype.fLines = function () {
  this._lines(arguments);
  this.context.fill();
};

/**
 * Draws a set of line segments using a series of
 * x/y positions as input.
 *
 * @param {...Number} positions x and y positions for the Lines
 * @example
 * // This draws the outline of a triangle.
 * // Inputs are pairs of x/y positions.
 * setStroke('orange');
 * sLines(10, 10, 40, 10, 40, 40, 10, 10);
 *
 */
Graphics.prototype.sLines = function () {
  this._lines(arguments);
  this.context.stroke();
};

/**
 * Draws a filled set of line segments using a series of
 * x/y positions as input.
 *
 * @param {...Number} positions x and y positions for the Lines
 * @example
 * // This draws a filled and outlined triangle.
 * // Inputs are pairs of x/y positions.
 * setFill('steelblue');
 * setStroke('orange');
 * fsLines(10, 10, 40, 10, 40, 40, 10, 10);
 *
 */
Graphics.prototype.fsLines = function () {
  this._lines(arguments);
  this.context.fill();
  this.context.stroke();
};

/**
 * Draws a mouse-enabled filled set of line segments using a series of
 * x/y positions as input. Returns true if moused over on current
 * cycle iteration.
 *
 * @param {...Number} positions x and y positions for the Lines
 * @returns {Boolean} if true, mouse is currently hovering over lines.
 * @example
 * // Turns Fill Red if moused over
 * setFill('steelblue');
 * setStroke('orange');
 * var on = fsLinesM(10, 10, 40, 10, 40, 40, 10, 10);
 * if(on) {
 *   setFill('red');
 *   fsLines(10, 10, 40, 10, 40, 40, 10, 10);
 * }
 *
 */
Graphics.prototype.fsLinesM = function () {
  var mouseOn = this._linesM(arguments);
  this.context.fill();
  this.context.stroke();
  return mouseOn;
};

/**
 * @ignore
 */
Graphics.prototype._polygon = function (polygon) {
  this.context.beginPath();
  this.context.moveTo(polygon[0].x, polygon[0].y);
  for (var i = 1; polygon[i] != null; i++) {
    this.context.lineTo(polygon[i].x, polygon[i].y);
  }
};

/**
 * Renders a filled polygon.
 *
 * @param  {Polygon} polygon the polygon to render
 */
Graphics.prototype.fPolygon = function (polygon) {
  this._polygon(polygon);
  this.context.fill();
};

/**
 * Render a stroked polygon
 * @param  {Polygon} polygon
 * @param  {Boolean} closePath
 */
Graphics.prototype.sPolygon = function (polygon, closePath) {
  this._polygon(polygon);
  if (closePath) {
    this.context.closePath();
  }
  this.context.stroke();
};

/**
 * Render a filled and stroked polygon
 * @param  {Polygon} polygon
 * @param  {Boolean} closePath
 */
Graphics.prototype.fsPolygon = function (polygon, closePath) {
  this._polygon(polygon);
  if (closePath) {
    this.context.closePath();
  }
  this.context.fill();
  this.context.stroke();
};

/**
 * [fEqTriangle description]
 * @todo document fEqTriangle
 */
Graphics.prototype.fEqTriangle = function (x, y, angle, r) {
  this._eqTriangle(x, y, angle, r);
  this.context.fill();
};

/**
 * Draws a stroked equilateral traiangle.
 * @todo document sEqTriangle
 */
Graphics.prototype.sEqTriangle = function (x, y, angle, r) {
  this._eqTriangle(x, y, angle, r);
  this.context.stroke();
};

/**
 * Draws a filled and stroked equilateral triangle.
 * @todo document fsEqTriangle
 */
Graphics.prototype.fsEqTriangle = function (x, y, angle, r) {
  this._eqTriangle(x, y, angle, r);
  this.context.fill();
  this.context.stroke();
};

/**
 * @param  {Number} x     [description]
 * @param  {Number} y     [description]
 * @param  {Number} angle [description]
 * @param  {Number} r     [description]
 * @return {Number}       [description]
 *
 * TODO document this
 * @ignore
 */
Graphics.prototype._eqTriangle = function (x, y, angle, r) {
  this.context.beginPath();
  angle = angle || 0;
  this.context.moveTo(r * Math.cos(angle) + x, r * Math.sin(angle) + y);
  this.context.lineTo(r * Math.cos(angle + 2.0944) + x, r * Math.sin(angle + 2.0944) + y);
  this.context.lineTo(r * Math.cos(angle + 4.1888) + x, r * Math.sin(angle + 4.1888) + y);
  this.context.lineTo(r * Math.cos(angle) + x, r * Math.sin(angle) + y);
};

//drawing and checking cursor

/**
 * Draws a mouse-enabled filled in Rectangle.
 * Fill color is expected to be set using {@link setFill}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @param {Number} margin Parameter around rectangle to count towards mouse over.
 * @return {Boolean} Returns true if the mouse is over the rectangle on the current
 * iteration of the cycle function.
 * @example
 * setFill('steelblue');
 * var on = fRectM(10, 10, 40, 40);
 * if(on) {
 *   setFill('red');
 *   fRect(10, 10, 40, 40);
 * }
 */
Graphics.prototype.fRectM = function (x, y, width, height, margin) {
  margin = margin == null ? 0 : margin;
  this.context.fillRect(x, y, width, height);
  return this.mY > y - margin && this.mY < y + height + margin && this.mX > x - margin && this.mX < x + width + margin;
};

/**
 * Draws a mouse-enabled stroked Rectangle.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @param {Number} margin Parameter around rectangle to count towards mouse over.
 * @return {Boolean} Returns true if the mouse is over the rectangle on the current
 * iteration of the cycle function.
 * @example
 * setStroke('orange');
 * var on = sRectM(10, 10, 40, 40);
 * if(on) {
 *   setStroke('black');
 *   sRect(10, 10, 40, 40);
 * }
 */
Graphics.prototype.sRectM = function (x, y, width, height, margin) {
  margin = margin == null ? 0 : margin;
  this.context.strokeRect(x, y, width, height);
  return this.mY > y - margin && this.mY < y + height + margin && this.mX > x - margin && this.mX < x + width + margin;
};

/**
 * Draws a mouse-enabled filled and stroked Rectangle.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 *
 * @param {Number} x X position of upper-left corner of Rectangle.
 * @param {Number} y Y position of upper-left corner of Rectangle.
 * @param {Number} width Width of Rectangle in pixels.
 * @param {Number} height Height of Rectangle in pixels.
 * @param {Number} margin Parameter around rectangle to count towards mouse over.
 * @return {Boolean} Returns true if the mouse is over the rectangle on the current
 * iteration of the cycle function.
 * @example
 * setFill('steelblue');
 * setStroke('orange');
 * var on = fsRectM(10, 10, 40, 40);
 * if(on) {
 *   setFill('red');
 *   setStroke('black');
 *   sRect(10, 10, 40, 40);
 * }
 */
Graphics.prototype.fsRectM = function (x, y, width, height, margin) {
  margin = margin == null ? 0 : margin;
  this.context.fillRect(x, y, width, height);
  this.context.strokeRect(x, y, width, height);
  return this.mY > y - margin && this.mY < y + height + margin && this.mX > x - margin && this.mX < x + width + margin;
};

/**
 * Draws a mouse-enabled filled in Circle.
 * Fill color is expected to be set using {@link setFill}.
 * Returns true if mouse is over circle on current iteration of cycle.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @param {Number} margin Margin around Circle to consider part of mouse over.
 * @return {Boolean} Returns true if the mouse is over the circle on the current
 * iteration of the cycle function.
 * @example
 * setFill('steelblue');
 * var on = fCircleM(40, 40, 20);
 * if(on) {
 *  setFill('red');
 *  fCircle(40, 40, 20);
 * }
 *
 */
Graphics.prototype.fCircleM = function (x, y, r, margin) { //check if you can avoid repeat
  margin = margin == null ? 0 : margin;
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi);
  this.context.fill();
  return Math.pow(x - this.mX, 2) + Math.pow(y - this.mY, 2) < Math.pow(r + margin, 2);
};

/**
 * Draws a mouse-enabled stroked Circle.
 * Stroke color is expected to be set using {@link setStroke}.
 * Returns true if mouse is over circle on current iteration of cycle.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @param {Number} margin Margin around Circle to consider part of mouse over.
 * @return {Boolean} Returns true if the mouse is over the circle on the current
 * iteration of the cycle function.
 * @example
 * setStroke('orange');
 * var on = sCircleM(40, 40, 20);
 * if(on) {
 *  setStroke('black');
 *  sCircle(40, 40, 20);
 * }
 *
 */
Graphics.prototype.sCircleM = function (x, y, r, margin) {
  margin = margin == null ? 0 : margin;
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi);
  this.context.stroke();
  return Math.pow(x - this.mX, 2) + Math.pow(y - this.mY, 2) < Math.pow(r + margin, 2);
};

/**
 * Draws a mouse-enabled filled and stroked Circle.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 * Returns true if mouse is over circle on current iteration of cycle.
 *
 * @param {Number} x X position of center of the Circle.
 * @param {Number} y Y position of center of the Circle.
 * @param {Number} r Radius of the Circle.
 * @param {Number} margin Margin around Circle to consider part of mouse over.
 * @return {Boolean} Returns true if the mouse is over the circle on the current
 * iteration of the cycle function.
 * @example
 * setFill('steelblue');
 * setStroke('orange');
 * var on = fsCircleM(40, 40, 20);
 * if(on) {
 *  setFill('red');
 *  setStroke('black');
 *  fsCircle(40, 40, 20);
 * }
 *
 */
Graphics.prototype.fsCircleM = function (x, y, r, margin) {
  margin = margin == null ? 0 : margin;
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi);
  this.context.stroke();
  this.context.fill();
  return Math.pow(x - this.mX, 2) + Math.pow(y - this.mY, 2) < Math.pow(r + margin, 2);
};

/**
 * Draws a mouse-enabled line from a start position to an end position.
 *
 * @param {Number} x0 Starting x position.
 * @param {Number} y0 Starting y position.
 * @param {Number} x1 Ending x position.
 * @param {Number} y1 Ending y position.
 * @param {Number} d Distance away from line to count towards mouse interaction.
 * @return {Boolean} Returns true if the mouse is over the line on the current
 * iteration of the cycle function.
 * @example
 * setStroke('black');
 * var on = lineM(0, 0, 40, 40);
 * if(on) {
 *  setStroke('red');
 *  line(0, 0, 40, 40);
 * }
 */
Graphics.prototype.lineM = function (x0, y0, x1, y1, d) {
  d = d || 4;
  this.context.beginPath();
  this.context.moveTo(x0, y0);
  this.context.lineTo(x1, y1);
  this.context.stroke();
  return this._distToSegmentSquared(x0, y0, x1, y1) < d * d;
};


/**
 *
 * TODO document this.
 * @ignore
 */
Graphics.prototype._distToSegmentSquared = function (x0, y0, x1, y1) {
  var l2 = Math.pow(x0 - x1, 2) + Math.pow(y0 - y1, 2);
  if (l2 === 0) return Math.pow(x0 - this.mX, 2) + Math.pow(y0 - this.mY, 2);
  var t = ((this.mX - x0) * (x1 - x0) + (this.mY - y0) * (y1 - y0)) / l2;
  if (t <= 0) return Math.pow(x0 - this.mX, 2) + Math.pow(y0 - this.mY, 2);
  if (t >= 1) return Math.pow(x1 - this.mX, 2) + Math.pow(y1 - this.mY, 2);
  var px = x0 + t * (x1 - x0);
  var py = y0 + t * (y1 - y0);
  return Math.pow(px - this.mX, 2) + Math.pow(py - this.mY, 2);
};

//TODO:fEqTriangleM, fPolygonM


/**
 * Draws a mouse-enabled bezier curve using {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingthis.context.D/bezierCurveTo|bezierCurveTo}.
 *
 *
 * @param {Number} x0 Starting x position.
 * @param {Number} y0 Starting y position.
 * @param {Number} cx0 First curve control point x position.
 * @param {Number} cy0 First cure control point y position.
 * @param {Number} cx1 Second curve control point x position.
 * @param {Number} cy1 Second cure control point y position.
 * @param {Number} x1 Ending x position.
 * @param {Number} y1 Ending y position.
 * @param {Number} d Distance away from line to count towards mouse interaction.
 * @return {Boolean} Returns true if the mouse is over the line on the current
 * iteration of the cycle function.
 * @example
 * setStroke('black');
 * var on = bezierM(10, 10, 10, 0, 40, 0, 40, 10);
 * if(on) {
 *  setStroke('red');
 *  bezierM(10, 10, 10, 0, 40, 0, 40, 10);
 * }
 */
Graphics.prototype.bezierM = function (x0, y0, cx0, cy0, cx1, cy1, x1, y1, d) { //TODO: fix this mess!
  d = d == null ? 2 : d;
  this.context.beginPath();
  this.context.moveTo(x0, y0);
  this.context.bezierCurveTo(cx0, cy0, cx1, cy1, x1, y1);
  this.context.stroke();
  if (this.mX < Math.min(x0, x1, cx0, cx1) - d || this.mX > Math.max(x0, x1, cx0, cx1) + d || this.mY < Math.min(y0, y1, cy0, cy1) - d || this.mY > Math.max(y0, y1, cy0, cy1) + d) return false;
  return GeometryOperators.distanceToBezierCurve(x0, y0, cx0, cy0, cx1, cy1, x1, y1, this.mP, false) < d;
};


//images

/**
 *  Draw an image on this.context. parameters options (s for source, d for destination):
 *
 *  drawImage(image, dx, dy)
 *  drawImage(image, dx, dy, dw, dh)
 *  drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh)
 *  @param {Image} image
 *
 *  @todo document parameters
 */
Graphics.prototype.drawImage = function (image) { //TODO: improve efficiency
  if (image == null) return;

  switch (arguments.length) {
    case 1:
      this.context.drawImage(image);
      break;
    case 3:
      this.context.drawImage(image, arguments[1], arguments[2]);
      break;
    case 5:
      this.context.drawImage(image, arguments[1], arguments[2], arguments[3], arguments[4]);
      break;
    case 9:
      this.context.drawImage(image, arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6], arguments[7], arguments[8]);
      break;

  }
};

/**
 * fits an image into a rectangle without chagning its proportions (thus probably loosing top-bottom or left-right margins)
 * @param  {Image} image
 * @param  {Rectangle} rectangle frame of the image
 * @param {number} mode 0:image will be croped when proportions don`t match 1:image will be forced inside rectangle (there will be a margin) 2:image is put in center with its actual dimensions (it could be cropped, and/or with margins)
 */
Graphics.prototype.fitImage = function (image, rectangle,mode) {
  if (image == null || rectangle == null) return;
  if(mode==null) mode = 0;

  var propIm = image.width / image.height;
  var propRc = rectangle.width / rectangle.height;
  var compProp = propIm / propRc;
  var condition;

  switch(mode){
    case 0:
      condition = propIm >= propRc;
      break;
    case 1:
      condition = propIm < propRc;
      break;
    case 2:
      this.context.drawImage(image, rectangle.x + rectangle.width*0.5 - image.width*0.5, rectangle.y + rectangle.height*0.5 - image.height*0.5);
      return;
  }

  if (condition) {
    this.context.drawImage(image, 0.5 * (image.width - image.width / compProp), 0, image.width / compProp, image.height, rectangle.x, rectangle.y, rectangle.width, rectangle.height);
  } else {
    this.context.drawImage(image, 0, 0.5 * (image.height - image.height * compProp), image.width, image.height * compProp, rectangle.x, rectangle.y, rectangle.width, rectangle.height);
  }
};

// styles

/**
 * Sets the current fill color for the graphics object.
 *
 * Callers can pass in either an rgba() color string, or a set of up to four numbers
 * representing the rgb or rgba color.
 *
 * @param {String|Numbers} style
 */
Graphics.prototype.setFill = function (style) {
  if (typeof style == "number") {
    if (arguments.length > 3) {
      this.context.fillStyle = 'rgba(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ',' + arguments[3] + ')';
      return;
    }
    this.context.fillStyle = 'rgb(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ')';
    return;
  }
  this.context.fillStyle = style;
};

/**
 * Set stroke to draw with in canvas
 *
 * @param {(String|Number)} style If string, then hex value or web color.
 *                                If Number, then a set of RGB or RGBA integers
 * @param {Number} lineWidth Optional width of line to use. Only valid if style parameter is a string.
 *
 * @example
 * setStroke('steelblue'); // sets stroke to blue.
 * setStroke(0,0,0,0.4); // sets stroke to black with partial opacity.
 * setStroke('black', 0.2); // provides lineWidth to stroke
 */
Graphics.prototype.setStroke = function (style, lineWidth) {
  if (typeof style == "number") {
    if (arguments.length > 3) {
      this.context.strokeStyle = 'rgba(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ',' + arguments[3] + ')';
      return;
    }
    this.context.strokeStyle = 'rgb(' + arguments[0] + ',' + arguments[1] + ',' + arguments[2] + ')';
    return;
  }
  this.context.strokeStyle = style;
  //TODO: will lineWidth still work if RGB or RGBA is used?
  if (lineWidth) this.context.lineWidth = lineWidth;
};

/**
 * Set shadow to use in canvas. Affects both fill and strokes.
 *
 * @param {String} color to use for shadow (default:black)
 * @param {Number} xOffset is the number of pixels to offset the shadow to the right (default:5)
 * @param {Number} yOffset is the number of pixels to offset the shadow to down (default:5)
 * @param {Number} blur level (default:5)
 */
Graphics.prototype.setShadow = function (color, xOffset, yOffset, blur) {
  color = color == null ? 'black' : color;
  xOffset = xOffset == null ? 5 : xOffset;
  yOffset = yOffset == null ? 5 : yOffset;
  blur = blur == null ? 5 : blur;
  this.context.shadowColor = color;
  this.context.shadowBlur = blur;
  this.context.shadowOffsetX = xOffset;
  this.context.shadowOffsetY = yOffset;
};

/**
 * Turns off shadow use in canvas.
 */
Graphics.prototype.resetShadow = function () {
  this.context.shadowBlur = 0;
  this.context.shadowOffsetX = 0;
  this.context.shadowOffsetY = 0;
};

/**
 * Set the current lineWidth for strokes.
 *
 * @param {Number} lineWidth [description]\
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineWidth|lineWidth}
 */
Graphics.prototype.setLW = function (lineWidth) {
  this.context.lineWidth = lineWidth;
};

//
// Clipping
//

/**
 * Creates a clipping circle (mask) with the given dimensions; apply cliping, draw, and finish with graphics.restore()
 * @param  {Number} x x coordinate of the circle's center
 * @param  {Number} y y coordinate of the circle's center
 * @param  {Number} r radius of circle
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip|Clip}
 */
Graphics.prototype.clipCircle = function (x, y, r) {
  this.context.save();
  this.context.beginPath();
  this.context.arc(x, y, r, 0, TwoPi, false);
  this.context.closePath();
  this.context.clip();
};

/**
 * Creates a clipping rectangle (mask) with the given dimensions; apply cliping, draw, and finish with graphics.restore()
 * @param  {Number} x x coordinate of top left corner
 * @param  {Number} y y coordinate of top left corner
 * @param  {Number} w width of rect
 * @param  {Number} h height of rect
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip|Clip}
 */
Graphics.prototype.clipRect = function (x, y, w, h) {
  this.context.save();
  this.context.beginPath();
  if(x.type == 'Rectangle'){
    y = x.y;
    w = x.width;
    h = x.height;
    x = x.x;
  }
  this.context.moveTo(x, y);
  this.context.lineTo(x + w, y);
  this.context.lineTo(x + w, y + h);
  this.context.lineTo(x, y + h);
  this.context.clip();
};

/**
 * Saves the entire state of the canvas by pushing the current state onto a stack.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/save|Save}
 */
Graphics.prototype.save = function () {
  this.context.save();
};

/**
 * Turns the path currently being built into the current clipping path.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip|Clip}
 */
Graphics.prototype.clip = function () {
  this.context.clip();
};

/**
 * Restores the most recently saved canvas state by popping the top entry
 * in the drawing state stack. If there is no saved state,
 * this method does nothing.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/restore|Restore}
 */
Graphics.prototype.restore = function () {
  this.context.restore();
};

//
// Text
//

/**
 * Draws filled in text.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @example
 * setText('black', 30, 'Arial');
 * fText("hello", 10, 10);
 *
 */
Graphics.prototype.fText = function (text, x, y) {
  this.context.fillText(text, x, y);
};

/**
 * Draws filled in text centered inside rectangle. Fontsize is adjusted to fit
 *
 * @param {String} text Text to draw.
 * @param {Rectangle} r Rectangle in which to draw text
 */
Graphics.prototype.fTextCenteredInRectangle = function (text, r) {
  this.context.save();
  var fSize = Math.floor(r.height * 0.9);
  this.setText(null, fSize, null, 'center', 'middle');
  var w = this.getTextW(text);
  if (w > r.width) {
    fSize = Math.floor(r.width / w * fSize) - 1;
    this.setText(null, fSize, null, 'center', 'middle');
  }
  if (fSize > 0)
    this.fText(text, r.x + r.width / 2, r.y + r.height / 2);

  this.context.restore();
};

/**
 * Draws stroked text.
 * Stroke color is expected to be set using {@link setStroke}.
 * Additionally, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @example
 * setText('black', 30, 'Arial');
 * setStroke('orange');
 * sText("hello", 10, 10);
 *
 */
Graphics.prototype.sText = function (text, x, y) {
  this.context.strokeText(text, x, y);
};

/**
 * Draws stroked and filled in text.
 * Stroke color is expected to be set using {@link setStroke}.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @example
 * setText('black', 30, 'Arial');
 * setStroke('orange');
 * fsText("hello", 10, 10);
 *
 */
Graphics.prototype.fsText = function (text, x, y) {
  this.context.strokeText(text, x, y);
  this.context.fillText(text, x, y);
};

/**
 * Draws filled in text and returns its width.
 *
 * @param  {String} text
 * @param  {Number} x x coordinate of text
 * @param  {Number} y x coordinate of text
 * @return {Number} the width of the rendered text in pixels
 */
Graphics.prototype.fTextW = function (text, x, y) {
  this.context.fillText(text, x, y);
  return this.context.measureText(text).width;
};

/**
 * Draws stroked and filled in text, and returns its width.
 *
 * @param  {String} text
 * @param  {Number} x x coordinate of text
 * @param  {Number} y x coordinate of text
 * @return {Number} the width of the rendered text in pixels
 */
Graphics.prototype.fsTextW = function (text, x, y) {
  this.context.strokeText(text, x, y);
  this.context.fillText(text, x, y);
  return this.context.measureText(text).width;
};

/**
 * Draws filled in text, rotated by some angle.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @param {Number} angle The angle in radians to rotate the text
 * @example
 * setText('black', 30, 'Arial');
 * fTextRotated("hello", 40, 40, 0.33*Math.PI);
 *
 */
Graphics.prototype.fTextRotated = function (text, x, y, angle) {
  this.context.save();
  this.context.translate(x, y);
  this.context.rotate(angle);
  this.context.fillText(text, 0, 0);
  this.context.restore();
};

/**
 * Draws filled in and stroked text, rotated by some angle.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @param {Number} angle The angle in radians to rotate the text
 * @example
 * setStroke('white', 2)
 * setText('black', 30, 'Arial');
 * fsTextRotated("hello", 40, 40, 0.33*Math.PI);
 *
 */
Graphics.prototype.fsTextRotated = function (text, x, y, angle) {
  this.context.save();
  this.context.translate(x, y);
  this.context.rotate(angle);
  this.context.strokeText(text, 0, 0);
  this.context.fillText(text, 0, 0);
  this.context.restore();
};

/**
 * Draws filled in text in an arc.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position of control point, to start the text if not centered.
 * @param {Number} y Y position of control point, to start the text if not centered.
 * @param {Number} xCenter X position of center of arc.
 * @param {Number} yCenter Y position of center of arc.
 * @param {Boolean} centered if false (default) text starts on control point, if true the control point is the center of the text
 * @example
 * setText('black', 30, 'Arial');
 * fTextArc("Wheels on the Bus Go Round and Round", 500, 300, 200, 200, true);
 *
 */
Graphics.prototype.fTextArc = function (text, x, y, xCenter, yCenter, centered) {
  if (text == null || text === "") {
    return;
  }

  var i;
  var r = Math.sqrt(Math.pow(x - xCenter, 2) + Math.pow(y - yCenter, 2));
  var a = Math.atan2(y - yCenter, x - xCenter);
  if (centered) {
    a -= this.getTextW(text) * 0.5 / r;
  }
  var xl, yl;
  var letters = text.split('');
  for (i = 0; letters[i] != null; i++) {
    xl = xCenter + r * Math.cos(a);
    yl = yCenter + r * Math.sin(a);
    this.fTextRotated(letters[i], xl, yl, a + HalfPi);
    a += this.getTextW(letters[i]) / r;
  }
};

/**
 * Draws a mouse-enabled filled in text.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @param {Number} size Size of the text being drawn.
 * @return {Boolean} Returns true if the mouse is over the text on the current
 * iteration of the cycle function.
 * @example
 * setText('black', 30, 'Arial');
 * var on = fTextM("hello", 10, 10, 30);
 * if(on) {
 *   setText('red', 30, 'Arial');
 *   fText("hello", 10, 10);
 * }
 *
 */
Graphics.prototype.fTextM = function (text, x, y, size) {
  size = Number(size || this.fontSize);
  this.context.fillText(text, x, y);
  return this.mY > y && this.mY < y + size && this.mX > x && this.mX < x + this.context.measureText(text).width;
};

/**
 * Draws a mouse-enabled filled and stroked text.
 * Fill color is expected to be set using {@link setFill}.
 * Stroke color is expected to be set using {@link setStroke}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @param {Number} size Size of the text being drawn.
 * @return {Boolean} Returns true if the mouse is over the text on the current
 * iteration of the cycle function.
 * @example
 * setText('black', 30, 'Arial');
 * setStroke('orange')
 * var on = fsTextM("hello", 10, 10, 30);
 * if(on) {
 *   setText('red', 30, 'Arial');
 *   setStroke('black')
 *   fsText("hello", 10, 10);
 * }
 *
 */
Graphics.prototype.fsTextM = function (text, x, y, size) {
  size = Number(size || this.fontSize);
  this.context.strokeText(text, x, y);
  this.context.fillText(text, x, y);
  return this.mY > y && this.mY < y + size && this.mX > x && this.mX < x + this.context.measureText(text).width;
};

/**
 * Draws a mouse-enabled filled text rotated by some angle.
 * Fill color is expected to be set using {@link setFill}.
 * Alternatively, setText can be used to set a number of
 * text rendering properties.
 *
 * @param {String} text Text to draw.
 * @param {Number} x X position to start the text.
 * @param {Number} y Y position to start the text.
 * @param {Number} angle The angle in radians to rotate the text
 * @param {Number} size Size of the text being drawn.
 * @return {Boolean} Returns true if the mouse is over the text on the current
 * iteration of the cycle function.
 * @example
 * setText('black', 30, 'Arial');
 * var on = fTextRotatedM("hello", 10, 10, (20 * Math.PI / 180), 30);
 * if(on) {
 *   setText('red', 30, 'Arial');
 *   setStroke('black')
 *   fsText("hello", 10, 10);
 * }
 *
 */
Graphics.prototype.fTextRotatedM = function (text, x, y, angle, size) {
  size = size || 12;
  this.context.save();
  this.context.translate(x, y);
  this.context.rotate(angle);
  this.context.fillText(text, 0, 0);
  this.context.restore();

  var dX = this.mX - x;
  var dY = this.mY - y;
  var d = Math.sqrt(dX * dX + dY * dY);
  var a = Math.atan2(dY, dX) - angle;
  var mXT = x + d * Math.cos(a);
  var mYT = y + d * Math.sin(a);

  return mYT > y && mYT < y + size && mXT > x && mXT < x + this.context.measureText(text).width;
};

/**
 * Helper function, returns first param if it is not null or undefined
 * esle returns second param.
 * @param  {Object} value
 * @param  {Object} fallback
 * @return {Object}
 * @ignore
 */
function ifDef(value, fallback) {
  if (value !== undefined && value !== null) {
    return value;
  } else {
    return fallback;
  }
}

/**
 * Sets default values for several text rendering properties.
 * Values that are undefined or null are not changed. Will also
 * call setText and set the current text properties to that.
 *
 * @see  setText
 *
 * @param {Object} color optional font color
 * @param {Object} fontSize optional font size
 * @param {Object} fontName optional font name (default: LOADED_FONT)
 * @param {Object} align optional horizontal align ('left', 'center', 'right')
 * @param {Object} baseline optional vertical alignment ('bottom', 'middle', 'top')
 * @param {Object} style optional font style ('bold', 'italic', 'underline')
 */
Graphics.prototype.setTextDefaults = function (color, fontSize, fontName, align, baseline, style) {
  this.fontColor = ifDef(color, this.fontColor);
  this.fontSize = ifDef(String(fontSize), this.fontSize);
  this.fontName = ifDef(fontName, this.fontName);
  this.fontAlign = ifDef(align, this.fontAlign);
  this.fontBaseline = ifDef(baseline, this.fontBaseline);
  this.fontStyle = ifDef(style, this.fontStyle);

  this.setText();
};

/**
 * Sets several text canvas rendering properties. If a value
 * is null or undefined, use the currently set default value.
 *
 * @see  setTextDefaults
 *
 * @param {Object} color optional font color
 * @param {Object} fSize optional font size
 * @param {Object} fName optional font name (default: LOADED_FONT)
 * @param {Object} align optional horizontal align ('left', 'center', 'right')
 * @param {Object} baseline optional vertical alignment ('bottom', 'middle', 'top')
 * @param {Object} style optional font style ('bold', 'italic', 'underline')
 */
Graphics.prototype.setText = function (color, fSize, fName, align, baseline, style) {
  var fontColor = ifDef(color, this.fontColor);
  var fontSize = ifDef(String(fSize), this.fontSize);
  var fontName = ifDef(fName, this.fontName);
  var fontAlign = ifDef(align, this.fontAlign);
  var fontBaseline = ifDef(baseline, this.fontBaseline);
  var fontStyle = ifDef(style, this.fontStyle);

  if (fontStyle !== '') {
    fontStyle += ' ';
  }

  this.context.fillStyle = fontColor;
  this.context.font = fontStyle + fontSize + 'px ' + fontName;
  this.context.textAlign = fontAlign;
  this.context.textBaseline = fontBaseline;
};

/**
 * Get the current font family
 * @return {String} the current font family
 */
Graphics.prototype.getFontFamily = function () {
  return this.fontName;
};

/**
 * Sets the current font family
 */
Graphics.prototype.setFontFamily = function (fontName) {
  this.setText(undefined, undefined, fontName);
};




/**
 * Returns the width in pixels of the text passed in given the current
 * state of the graphics canvas.
 *
 * @param  {String} text
 * @return {Number} width in pixels of the text if it were rendered.
 *
 * TODO it would be great to have a method that returned the whole
 * TextMetrics object
 */
Graphics.prototype.getTextW = function (text) {
  return this.context.measureText(text).width;
};

//
// Pixel data
//

/**
 * Return the RGBA color of the pixel at a given x,y coordinate on
 * the graphics object canvas as a string.
 *
 * @param  {Number} x x coordinate of pixel
 * @param  {Number} y y coordinate of pixel
 * @return {String} an rgba(r,g,b,a) string representation of the color
 */
Graphics.prototype.getPixelColor = function (x, y) {
  var rgba = this.context.getImageData(x, y, 1, 1).data;
  return 'rgba(' + rgba[0] + ',' + rgba[1] + ',' + rgba[2] + ',' + rgba[3] + ')';
};

/**
 * Return the RGBA color of the pixel at a given x,y coordinate on
 * the graphics object canvas as an array of integers.
 *
 * @param  {Number} x x coordinate of pixel
 * @param  {Number} y y coordinate of pixel
 * @return {Array} RGBA values [0-256)
 */
Graphics.prototype.getPixelColorRGBA = function (x, y) {
  return Array.prototype.slice.call(this.context.getImageData(x, y, 1, 1).data);
};

//
// Capture
//

/**
 * Captures the current state of this graphics objects' canvas to an image, optional an indicated area can be copied
 *
 * @param  {Number} x0 left coordinate of area to copy
 * @param  {Number} y0 top coordinate of area to copy
 * @param  {Number} w width of area to copy
 * @param  {Number} height of area to copy
 * @return {Image} HTML5 Image
 * tags: image
 */
Graphics.prototype.captureCanvas = function (x0, y0, w, h) {
  var im = new Image();

  if (x0 == null) {
    im.src = this.canvas.toDataURL();
    return im;
  }

  var canvas = document.createElement('canvas');

  canvas.width = w;
  canvas.height = h;

  canvas.getContext('2d').drawImage(this.canvas, x0, y0, w, h, 0, 0, w, h);

  im.src = canvas.toDataURL();

  return im;
};




//
// Cursor
//

/**
 * Change mouse cursor to given style. See {@link https://developer.mozilla.org/en-US/docs/Web/CSS/cursor|MDN Cursor Page} for all style options.
 * @param {String} name The name of the cursor style.
 */
Graphics.prototype.setCursor = function (name) {
  name = name == null ? 'default' : name;
  this.canvas.style.cursor = name;
};

/**
 * @todo write docs
 */
Graphics.prototype.getFrame = function () {
  return new Rectangle(0, 0, this.cW, this.cH);
};

//
// Advanced graphics (rely on Axis2D projection object)
//

/**
 * @todo document this
 * @ignore
 */
Graphics.prototype._linesInFrame = function (axis2D, numberListX, numberListY) {
  var l = Math.min(numberListX.length, numberListY.length);
  var i;

  this.context.beginPath();
  this.context.moveTo(axis2D.projectX(numberListX[0]), axis2D.projectY(numberListY[0]));

  for (i = 1; i < l; i++) {
    this.context.lineTo(axis2D.projectX(numberListX[i]), axis2D.projectY(numberListY[i]));
  }
};


/**
 * @todo write docs
 */
Graphics.prototype.sLinesInFrame = function (axis2D, numberListX, numberListY) {
  this._linesInFrame(axis2D, numberListX, numberListY);
  this.context.stroke();
};

/**
 * @todo write docs
 */
Graphics.prototype.fLinesInFrame = function (axis2D, numberListX, numberListY) {
  this._linesInFrame(axis2D, numberListX, numberListY);
  this.context.fill();
};

/**
 * @todo write docs
 */
Graphics.prototype.fsLinesInFrame = function (axis2D, numberListX, numberListY) {
  this._linesInFrame(axis2D, numberListX, numberListY);
  this.context.fill();
  this.context.stroke();
};

/**
 * @todo write docs
 */
Graphics.prototype.drawGridX = function (axis2D, dX, yLabel, stepsLabel) {
  var x0;
  var n;
  var i;
  var x, top, bottom;

  x0 = Math.floor(axis2D.departureFrame.x / dX) * dX;
  n = Math.min(Math.ceil(axis2D.departureFrame.width / dX), 1000);
  top = Math.min(axis2D.arrivalFrame.y, axis2D.arrivalFrame.y + axis2D.arrivalFrame.height);
  bottom = Math.max(axis2D.arrivalFrame.y, axis2D.arrivalFrame.y + axis2D.arrivalFrame.height);
  stepsLabel = stepsLabel == null ? 1 : stepsLabel;
  for (i = 0; i < n; i++) {
    x = Math.floor(axis2D.projectX(x0 + i * dX)) + 0.5;
    this.line(x, top, x, bottom);
    if (yLabel != null && i % stepsLabel === 0) {
      this.fText(String(x0 + i * dX), x, bottom + yLabel);
    }
  }
};

/**
 * @todo write docs
 */
Graphics.prototype.drawGridY = function (axis2D, dY, xLabel, stepsLabel) {
  var y0;
  var n;
  var i;
  var y, left, right;

  y0 = Math.floor(axis2D.departureFrame.y / dY) * dY;
  n = Math.min(Math.ceil(axis2D.departureFrame.height / dY), 1000);
  left = Math.min(axis2D.arrivalFrame.x, axis2D.arrivalFrame.x + axis2D.arrivalFrame.width);
  right = Math.max(axis2D.arrivalFrame.x, axis2D.arrivalFrame.x + axis2D.arrivalFrame.width);
  stepsLabel = stepsLabel == null ? 1 : stepsLabel;
  for (i = 0; i < n; i++) {
    y = Math.floor(axis2D.projectY(y0 + i * dY)) + 0.5;
    this.line(left, y, right, y);
    if (xLabel != null && i % stepsLabel === 0) {
      this.fText(String(y0 + i * dY), left + xLabel, y);
    }
  }
};

//
// Ported from Draw.js TODO - organize these appropriately.
//





/**
 * @todo write docs
 */
Graphics.prototype.drawSmoothPolygon = function (polygon, closed, amount) { //TODO: add tx, ty
  amount = amount == null ? 30 : amount;
  var controlPoints;

  if (polygon.length < 2) return null;
  this.context.beginPath();
  if (polygon.length == 2) {
    var a = Math.atan2(polygon[1].y - polygon[0].y, polygon[1].x - polygon[0].x) - 0.5 * Math.PI;
    var cosa = amount * Math.cos(a);
    var sina = amount * Math.sin(a);
    this.context.moveTo(polygon[0].x, polygon[0].y);
    this.context.bezierCurveTo(
      polygon[0].x + cosa, polygon[0].y + sina,
      polygon[1].x + cosa, polygon[1].y + sina,
      polygon[1].x, polygon[1].y
    );
    this.context.bezierCurveTo(
      polygon[1].x - cosa, polygon[1].y - sina,
      polygon[0].x - cosa, polygon[0].y - sina,
      polygon[0].x, polygon[0].y
    );
    this.context.stroke();
    return;
  }
  var i;
  var nPoints = polygon.length;
  var prevPoint = polygon[nPoints - 1];
  var point = polygon[0];
  var nextPoint = polygon[1];
  controlPoints = GeometryOperators.getSoftenControlPoints(prevPoint, point, nextPoint, amount);
  var prevCP = controlPoints[1];
  var cP;
  this.context.moveTo(point.x, point.y);
  prevPoint = point;
  var nSteps = nPoints + Number(closed);
  for (i = 1; i < nSteps; i++) {
    point = polygon[i % nPoints];
    nextPoint = polygon[(i + 1) % nPoints];
    controlPoints = GeometryOperators.getSoftenControlPoints(prevPoint, point, nextPoint, amount);
    cP = controlPoints[0];
    this.context.bezierCurveTo(prevCP.x, prevCP.y, cP.x, cP.y, point.x, point.y);
    prevCP = controlPoints[1];
    prevPoint = point;
  }
  this.context.stroke();
};




/**
 * Draws an ellipse using the current state of the canvas.
 * @param {CanvasRenderingContext2D} context
 * @param {Number} x The center x coordinate
 * @param {Number} y The center y coordinate
 * @param {Number} rW The horizontal radius of the ellipse
 * @param {Number} rH The vertical radius of the ellipse
 */
Graphics.prototype.drawEllipse = function (x, y, rW, rH) {
  var k = 0.5522848, // 4 * ((√(2) - 1) / 3)
    ox = rW * k, // control point offset horizontal
    oy = rH * k, // control point offset vertical
    xe = x + rW, // x-end
    ye = y + rH; // y-end

  this.context.moveTo(x - rW, y);
  this.context.bezierCurveTo(x - rW, y - oy, x - ox, y - rH, x, y - rH);
  this.context.bezierCurveTo(x + ox, y - rH, xe, y - oy, xe, y);
  this.context.bezierCurveTo(xe, y + oy, x + ox, ye, x, ye);
  this.context.bezierCurveTo(x - ox, ye, x - rW, y + oy, x - rW, y);
  this.context.moveTo(x - rW, y);
};

/**
 * Draws a polygon
 * @param {CanvasRenderingContext2D} context
 * @param {Polygon} Polygon to draw
 * @param {Boolean} close polygon
 * @param {Number} tx horizontal translation
 * @param {Number} ty vertical translation
 */
Graphics.prototype.drawPolygon = function (polygon, close, tx, ty) {
  tx = tx || 0;
  ty = ty || 0;
  var i;
  this.context.beginPath();
  this.context.moveTo(tx + polygon[0].x, ty + polygon[0].y);
  for (i = 1; polygon[i] != null; i++) {
    this.context.lineTo(tx + polygon[i].x, ty + polygon[i].y);
  }
  if (close) {
    this.context.lineTo(tx + polygon[0].x, ty + polygon[0].y);
  }
  this.context.stroke();
};

/**
 * @todo write docs
 */
Graphics.prototype.drawPolygonWithControlPoints = function (polygon, controlPoints, tx, ty) {
  tx = tx || 0;
  ty = ty || 0;
  var i;
  this.context.beginPath();
  this.context.moveTo(tx + polygon[0].x, ty + polygon[0].y);
  for (i = 1; polygon[i] != null; i++) {
    this.context.bezierCurveTo(tx + controlPoints[(i - 1) * 2].x, ty + controlPoints[(i - 1) * 2].y,
      tx + controlPoints[i * 2 - 1].x, ty + controlPoints[i * 2 - 1].y,
      tx + polygon[i].x, ty + polygon[i].y);
  }
  this.context.stroke();
};

/**
 * @todo write docs
 */
Graphics.prototype.drawBezierPolygon = function (bezierPolygon, tx, ty) {
  tx = tx || 0;
  ty = ty || 0;
  var bI;
  var N = Math.floor((bezierPolygon.length - 1) / 3);
  var i;
  this.context.moveTo(tx + bezierPolygon[0].x, ty + bezierPolygon[0].y);
  for (i = 0; i < N; i++) {
    bI = i * 3 + 1;

    this.context.bezierCurveTo(
      tx + bezierPolygon[bI].x, ty + bezierPolygon[bI].y,
      tx + bezierPolygon[bI + 1].x, ty + bezierPolygon[bI + 1].y,
      tx + bezierPolygon[bI + 2].x, ty + bezierPolygon[bI + 2].y
    );
  }
};

/**
 * Draws a curve that varies in color and linewidth from the start to the end
 * @param {Polygon} poly The curve to draw (it won't be closed)
 * @param {String} clr0 The color of the first point
 * @param {Number} a0 The alpha value for the first point
 * @param {Number} lw0 The line width at the first point
 * @param {String} clr1 The color of the last point
 * @param {Number} a1 The alpha value for the last point
 * @param {Number} lw1 The line width at the last point
 */
Graphics.prototype.drawMultiStyleCurve = function (poly, clr0, a0, lw0, clr1, a1, lw1) {
  var i, f, clr, lw, a;
  this.context.save();
  this.context.lineJoin = this.context.lineCap = "round";
  var intLw = new Interval(lw0, lw1);
  var intA = new Interval(a0, a1);
  for (i = 0; i < poly.length - 1; i++) {
    f = i / (poly.length - 2);
    lw = intLw.getInterpolatedValue(f);
    a = intA.getInterpolatedValue(f);
    clr = ColorOperators.interpolateColors(clr0, clr1, f);
    clr = ColorOperators.addAlpha(clr, a);
    this.context.beginPath();
    this.context.moveTo(poly[i].x, poly[i].y);
    this.context.lineTo(poly[i + 1].x, poly[i + 1].y);
    this.setStroke(clr, lw);
    this.context.stroke();
  }
  this.context.restore();
}


/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} context
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} radius The corner radius. Defaults to 5;
 */
Graphics.prototype.drawRoundRect = function (x, y, width, height, radius) {
  radius = radius || 0;
  var bottom = y + height;
  this.context.moveTo(x + radius, y);
  this.context.lineTo(x + width - radius, y);
  this.context.quadraticCurveTo(x + width, y, x + width, y + radius);
  this.context.lineTo(x + width, y + height - radius);
  this.context.quadraticCurveTo(x + width, bottom, x + width - radius, bottom);
  this.context.lineTo(x + radius, bottom);
  this.context.quadraticCurveTo(x, bottom, x, bottom - radius);
  this.context.lineTo(x, y + radius);
  this.context.quadraticCurveTo(x, y, x + radius, y);
};

/**
 * @todo write docs
 */
Graphics.prototype.drawTriangleFromBase = function (x, y, base, height, angle) {
  this.context.moveTo(x + 0.5 * base * Math.cos(angle + Math.PI * 0.5), y + 0.5 * base * Math.sin(angle + Math.PI * 0.5));
  this.context.lineTo(x + 0.5 * base * Math.cos(angle - Math.PI * 0.5), y + 0.5 * base * Math.sin(angle - Math.PI * 0.5));
  this.context.lineTo(x + height * Math.cos(angle), y + height * Math.sin(angle));
  this.context.lineTo(x + 0.5 * base * Math.cos(angle + Math.PI * 0.5), y + 0.5 * base * Math.sin(angle + Math.PI * 0.5));
};


/**
 * @todo write docs
 */
Graphics.prototype.drawQuadrilater = function (p0, p1, p2, p3, close) {
  close = close == null ? true : close;
  this.context.moveTo(p0.x, p0.y);
  this.context.lineTo(p1.x, p1.y);
  this.context.lineTo(p2.x, p2.y);
  this.context.lineTo(p3.x, p3.y);
  if (close) this.context.lineTo(p0.x, p0.y);
};



//TODO:
//prebuilt transformations:
//Graphics.prototype.applyFishEyeTransformation
//Graphics.prototype.applyHyperbolicTransformation
//…

/**
 * applies transformation function for X and Y. These will change line, sLines, fLines, fCirlce, sCircle, fRect, sRect, fCirlceM, sCircleM, fRectM, sRectM
 * @param {Function} fX horizontal projection (null for identity)
 * @param {Function} fY vertical projection (null for identity)
 * 
 * @param {Function} fXY function that transforms both X and Y dimensions NOT independently, fXY = function(x, y){…} and returns a Point NOT YET DONE
 */
Graphics.prototype.applyTransformationFunctions = function (fX, fY, fXY) {
  if (this._fRect == null) {
    this._line = this.line;
    this._sLines = function () {
      this.__lines(arguments);
      this.context.stroke();
    };
    this._fCircle = this.fCircle;
    this._sCircle = this.sCircle;
    this._fCircleM = this.fCircleM;
    this._sCircleM = this.sCircleM;
    this._fRect = this.fRect;
    this._sRect = this.sRect;
    this._fRectM = this.fRectM;
    this._sRectM = this.sRectM;
    this.__lines = this._lines;
    this.__polygon = this._polygon;

    this._fText = this.fText;
    this._clipRect = this.clipRect;
    this._drawImage = this.drawImage;

  }
  if (fX == null && fY == null) {
    this.line = this._line;
    this.sLines = this._sLines;
    this.fCircle = this._fCircle;
    this.sCircle = this._sCircle;
    this.fCircleM = this._fCircleM;
    this.sCircleM = this._sCircleM;
    this.fRect = this._fRect;
    this.sRect = this._sRect;
    this.fRectM = this._fRectM;
    this.sRectM = this._sRectM;
    this._lines = this.__lines;
    this._polygon = this.__polygon;

    this.fText = this._fText;
    this.clipRect = this._clipRect;
    this.drawImage = this._drawImage;
    return;
  }

  this.fX = fX;
  this.fY = fY;
  this.sX = function(x){
    return this.fX(x+0.5)-this.fX(x-0.5);
  };
  this.sY = function(y){
    return this.fY(y+0.5)-this.fY(y-0.5);
  };

  if (fX == null) this.fX = function (x) { return x; };
  if (fY == null) this.fY = function (y) { return y; };

  this.line = function (x0, y0, x1, y1) {
    this._line(this.fX(x0), this.fY(y0), this.fX(x1), this.fY(y1));
  };

  this.fRect = function (x, y, width, height) {
    this._fRect(this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };
  this.sRect = function (x, y, width, height) {
    this._sRect(this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };
  this.fRectM = function (x, y, width, height) {
    return this._fRectM(this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };
  this.sRectM = function (x, y, width, height) {
    return this._sRectM(this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };


  this.fCircle = function (x, y, r) {
    this._fCircle(this.fX(x), this.fY(y), Math.abs(this.fX(x + r) - this.fX(x)));
  };

  this.sCircle = function (x, y, r) {
    this._sCircle(this.fX(x), this.fY(y), Math.abs(this.fX(x + r) - this.fX(x)));
  };
  this.fCircleM = function (x, y, r) {
    return this._fCircleM(this.fX(x), this.fY(y), Math.abs(this.fX(x + r) - this.fX(x)));
  };
  this.sCircleM = function (x, y, r) {
    return this._sCircleM(this.fX(x), this.fY(y), Math.abs(this.fX(x + r) - this.fX(x)));
  };

  this._lines = function () {
    if (arguments == null) return;

    var args = arguments[0];
    this.context.beginPath();
    this.context.moveTo(this.fX(args[0]), this.fY(args[1]));
    for (var i = 2; args[i + 1] != null; i += 2) {
      this.context.lineTo(this.fX(args[i]), this.fY(args[i + 1]));
    }
  };

  this._polygon = function (polygon) {
    this.context.beginPath();
    this.context.moveTo(this.fX(polygon[0].x), this.fY(polygon[0].y));
    for (var i = 1; polygon[i] != null; i++) {
      this.context.lineTo(this.fX(polygon[i].x), this.fY(polygon[i].y));
    }
  };
  

  this.fText = function (text, x, y) {
    this._fText(text, this.fX(x), this.fY(y));
  };
  this.clipRect = function (x, y, width, height) {
    this._clipRect(this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };
  this.drawImage = function (image, x, y, width, height) {
    if(width==null) this._drawImage(image, this.fX(x), this.fY(y));
    this._drawImage(image, this.fX(x), this.fY(y), this.fX(x + width) - this.fX(x), this.fY(y + height) - this.fY(y));
  };


};



//not working as expected
/*
Graphics.prototype.destroy = function(ms){
  if(ms>0){
    setTimeout(function(){
      this.container.removeChild(this.canvas);
      this.canvas = null;
      this.setCursor = null;
      this.cycle = null;
      this._onCycle = null;
      this._onResize = null;
      this._onMouseOrKeyBoard = null;
      //this = null;
    }, ms)
  } else {
    this.container.removeChild(this.canvas);
    this.canvas = null;
    this.setCursor = null;
    this.onResize = null;
    this._onCycle = null;
    this._onResize = null;
    this._onMouseOrKeyBoard = null;
    //this = null;
  }
};
*/
