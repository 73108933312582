
import List from "src/dataTypes/lists/List";
import Table from "src/dataTypes/lists/Table";
import { isArray } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc Tools to convert Tables to other data types
 *
 * @namespace
 * @category basics
 */
function TableConversions() {}
export default TableConversions;

/**
 * Convert a Table into an Object or Array of objects
 * @param {Object} table to be converted
 *
 * @param {List} list of field names to include (by default will take all from table)
 * @return {Object} containing list of rows from input Table
 * tags:decoder,dani
 */
TableConversions.TableToObject = function(table, fields) { // To-Do: should return a List instead of Array?
    if(!table)
      return;

    // If no field names supplied, take them from first element
    if(!fields)
    {
      fields = table.getNames();
    }
    var result = [];
    for(var i = 0; i < table[0].length; i++) {
      var row = {};
      for(var f = 0; f < fields.length; f++)
      {
        row[fields[f]] = table[f][i];
      }
      result.push(row);
    }
    return {
      array: result
    };
};

/**
 * converts and array of arrays into a Table
 * @param {Array} arrayOfArrays array containing arrays
 */
TableConversions.ArrayOfArraysToTable = function(arrayOfArrays) {
  if(arrayOfArrays===null || !isArray(arrayOfArrays)) return;

  var l = arrayOfArrays.length;
  var i;
  var table = new Table();

  for(i=0; i<l; i++){
    if(isArray(arrayOfArrays[i])) table.push(List.fromArray(arrayOfArrays[i]).getImproved());
  }

  return table.getImproved();
};

/**
 * Generates an HTML version of the table data
 * @param {Table} table Table to display in HTML
 *
 * @param {Number} maxRows if specified, only show this many rows
 * @param {String} styleTable is the css style for the table element. Default is 'font-family: arial, sans-serif; border-collapse: collapse; width: 100%;'
 * @param {String|StringList} styleCol is the css style for columns. Default is 'border:1px solid rgba(200,200,200,.5); text-align: left; padding: 4px;'<br>This can also be a list with an entry for each list of the table
 * @param {String} styleRow is the css style for rows. Default is 'background-color: #ffffff;'
 * @param {String} styleRowOdd is the css style for odd numbered rows. If 'same' then styleRow value is used. Default is 'background-color: #f0f0f0;'
 * @param {Table} tCellStyles is a table of cell styles. Non-null elements are used to style individual cells.
 * @param {Boolean} bRenderImageURLs if true then show elements that look like image urls as embedded images (default:true)
 * @param {NumberList|String} nLColsWithBars is a list of numeric columns to show as embedded bars in the HTML. Can also be the string 'all' to operate onn all numeric lists (default:null which means no bars)
 * @param {String} clrBar is the color to use for bars (default: 'rgba(100,160,255,.5)')<br>This can also be a colorList with an item for each row
 * @return {String} HTML version of the table.
 * tags:html
 * examples:jeff/examples/tableToHtml
 */
TableConversions.tableToHtml = function(table,maxRows,styleTable,styleCol,styleRow,styleRowOdd,tCellStyles,bRenderImageURLs,nLColsWithBars,clrBar) {
  if(table==null) return;
  if(table.length == 0) return '';

  styleTable = styleTable == null ? 'font-family: arial, sans-serif; border-collapse: collapse; width: 100%;' : styleTable;
  styleCol = styleCol == null ? 'border:1px solid rgba(200,200,200,.5); text-align: left; padding: 4px;' : styleCol;
  styleRow = styleRow == null ? 'background-color: #ffffff;' : styleRow;
  styleRowOdd = styleRowOdd == null ? 'background-color: #f0f0f0;' : ( styleRowOdd == 'same' ? styleRow : styleRowOdd );
  maxRows = maxRows == null ? table[0].length : Math.min(maxRows,table[0].length);
  bRenderImageURLs = bRenderImageURLs == null ? true : bRenderImageURLs;
  clrBar = clrBar == null ? 'rgba(100,160,255,.5)' : clrBar;

  var std = (typeof styleCol =='string') ? '<td style="' + styleCol + '">' : '';
  var str = '<tr style="' + styleRow + '">';
  var strOdd = '<tr style="' + styleRowOdd + '">';

  var sHTML = '';
  var fnGetHTMLBarIndicator,cs;
  if(nLColsWithBars){
    fnGetHTMLBarIndicator = function(p,pMax,clr){
      if(pMax == 0) pMax = 1;
      var ip = Math.max(0,Math.round(100*p/pMax));
      var sHtml = '<div style="background-color: #eee; width:100%; border-style: solid; border-width: 1px; border-color:#aaa; display: inline-block;">';
      sHtml += '<div style="background-color:' + clr + '; width:' + ip + '%; height: 100%; font-size:14px; color:black;">';
      sHtml += '&nbsp;'+p+'</div></div>';
      return sHtml;
    }
    mo.TableOperators.buildInformationObject(table); // to get min/max for numberlists
  }
  var c,r;
  // headers
  sHTML += '<table style="' + styleTable + '">';
  // if all column names are empty then do not show column header row
  var bShowHeaders = false;
  for(c=0; c < table.length; c++){
    if(table[c].name != ''){
      bShowHeaders = true;
      break;
    }
  }
  if(bShowHeaders){
    sHTML += str;
    for(c=0; c < table.length; c++){
      if(styleCol.isList)
        std = '<td style="' + styleCol[c] + '">'
      sHTML += std + '<b>'+ (table[c].name == '' ? 'Column ' + (c+1) : table[c].name) + '</b></td>';
    }
    sHTML += '</tr>';
  }
  for(r=0; r < maxRows; r++){
    if(r % 2 == 1) // shifted 1 by header
      sHTML += str;
    else
      sHTML += strOdd;
    for(c=0; c < table.length; c++){
      if(tCellStyles && tCellStyles[c] != null && tCellStyles[c][r] != null && tCellStyles[c][r] != '')
        sHTML += '<td style="' + tCellStyles[c][r] + '">';
      else{
        if(styleCol.isList)
          std = '<td style="' + styleCol[c] + '">'
        sHTML += std;
      }
      
      if(bRenderImageURLs){
        var s = table[c][r] == null ? '' : String(table[c][r]).toLowerCase();
        if(s.indexOf('http') == 0 &&
           (s.indexOf('.jpg') != -1 || s.indexOf('.jpeg') != -1 || s.indexOf('.png') != -1 || s.indexOf('photo') != -1 || s.indexOf('image') != -1) ){
          // image (not a perfect test)
          sHTML += '<img src="' + table[c][r] + '" width=200>';
          continue;
        }
      }
      if(table[c].type == 'NumberList' && (nLColsWithBars == 'all' || (nLColsWithBars && nLColsWithBars.indexOf(c) != -1) ) ){
        if(clrBar && clrBar.type == 'ColorList' && clrBar.length == table[0].length)
          sHTML += fnGetHTMLBarIndicator(table[c][r],table[c].infoObject.max,clrBar[r]);
        else
          sHTML += fnGetHTMLBarIndicator(table[c][r],table[c].infoObject.max,clrBar);
      }
      else
        sHTML += table[c][r];
      sHTML += '</td>';
    }
    sHTML += '</tr>'
  }
  sHTML += '</table>';
  return sHTML;
};

/**
 * Convert a Table into a List by concatenating all the lists together
 * @param {Table} table to be converted
 * @return {List} list containing all the elements of the table
 * tags:
 */
TableConversions.tableToList = function(table) {
  if(table == null) return;
  // in future provide option to concat rows together
  var list = new List();
  for(var i=0;i<table.length;i++)
    list = list.concat(table[i]);
  return list.getImproved();
};
