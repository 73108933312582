import List from "src/dataTypes/lists/List";
import DateList from "src/dataTypes/dates/DateList";
import StringList from "src/dataTypes/strings/StringList";
import { typeOf } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc Provides a set of tools that work with Dates.
 *
 * @namespace
 * @category dates
 */
function DateOperators() {}
export default DateOperators;

DateOperators.millisecondsToHours = 1 / (1000 * 60 * 60);
DateOperators.millisecondsToDays = 1 / (1000 * 60 * 60 * 24);
DateOperators.millisecondsToWeeks = 1 / (1000 * 60 * 60 * 24 * 7);
DateOperators.millisecondsToYears = 0.00000000003169;

DateOperators.MONTH_NAMES = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
DateOperators.MONTH_NAMES_SHORT = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
DateOperators.MONTH_NDAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

DateOperators.WEEK_NAMES = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * parses a Date
 * @param  {String} string date in string format
 *
 * @param  {Number} formatCase <|>0: MM-DD-YYYY<|>1: YYYY-MM-DD (default)<|>2: MM-DD-YY<|>3: YY-MM-DD<|>4: DD-MM-YY<|>5: DD-MM-YYYY<|>6: YYYYMMDD
 * @param  {String} separator (default: '-')
 * @return {Date}
 * tags:decoder,transform
 */
DateOperators.stringToDate = function(string, formatCase, separator) {// @todo: move to StringConversions
  if(string == null) return null;
  separator = separator == null ? "-" : separator;
  formatCase = formatCase == null ? 1 : formatCase;

  formatCase = Number(formatCase);
  string = String(string);

  if(formatCase == 1) {
    if(separator != "-") string = string.replace(new RegExp(separator, "g"), "-");
    // parseDate transforms '.' so we keep backwards compatibility in that case
    if(string.indexOf('.') == -1)
      return DateOperators.parseDate(string);
    return new Date(string);
  }

  var y;
  var parts = string.split(separator);
  switch(formatCase) {
    case 0: //MM-DD-YYYY
      return new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
    case 1: //YYYY-MM-DD
      return new Date(string); //Number(parts[0]), Number(parts[1])-1, Number(parts[2]));
    case 2: //MM-DD-YY
      y = Number(parts[2]);
      y = y >= 0 ? y + 2000 : y + 1900;
      return new Date(y, Number(parts[0]) - 1, Number(parts[1]));
    case 3: //YY-MM-DD
      y = Number(parts[0]);
      y = y >= 0 ? y + 2000 : y + 1900;
      return new Date(y, Number(parts[1]) - 1, Number(parts[2]));
    case 4: //DD-MM-YY
      y = Number(parts[2]);
      y = y >= 0 ? y + 2000 : y + 1900;
      return new Date(y, Number(parts[1]) - 1, Number(parts[0]));
    case 5: //DD-MM-YYYY
      y = Number(parts[2]);
      return new Date(y, Number(parts[1]) - 1, Number(parts[0]));
    case 6: // YYYYMMDD
      y = Number(string.substr(0,4));
      var m = Number(string.substr(4,2));
      var d = Number(string.substr(6,2));
      return new Date(y, m - 1, d) ;
  }
};

 /**
 * creates a string from a Date or a list of Dates
 * @param  {Date|DateList} date or a DateList
 *
 * @param  {Number} formatCase 0: M-D-YYYY<|>1: YYYY-M-D<|>2: M-D-YY<|>3: YY-M-D<|>4: YYYY-M-D H:MM:SS<|>5: d month, YYYY<|>6: dayname, d month, YYYY<|>7: month name only<|>8: day name only<|>9: YYYY-MM-DD<|>10: HH:MM:SS
 * @param  {String} separator
 * @return {Date}
 * tags:decoder,transform
 */
DateOperators.dateToString = function(date, formatCase, separator) {// @todo: move to DateConversions
  if(date == null) return null;
  if(date.type == 'DateList' || typeOf(date) == 'Array'){
    // operate on each element
    var i,sLRet = new StringList();
    for(i=0;i<date.length;i++){
      sLRet.push(DateOperators.dateToString(date[i], formatCase, separator));
    }
    return sLRet;
  }
  separator = separator == null ? "-" : separator;
  formatCase = formatCase == null ? 0 : formatCase;
  if(typeof date == 'string')
    date = new Date(date);
  var year = date.getFullYear();
  var yearTwoLast = String(year).substr(2);
  var month = date.getMonth() + 1;
  var day = date.getDate();

  switch(formatCase) {
    case 0: //MM-DD-YYYY
      return month + separator + day + separator + year;
    case 1: //YYYY-MM-DD
      return year + separator + month + separator + day;
    case 2: //MM-DD-YY
      return month + separator + day + separator + yearTwoLast;
    case 3: //YY-MM-DD
      return yearTwoLast + separator + month + separator + day;
    case 4: //YYYY-MM-DD HH:MM:SS
      var hour = date.getHours();
      var min = date.getMinutes();
      min = min < 10 ? '0' + min : min;
      var sec = date.getSeconds();
      sec = sec < 10 ? '0' + sec : sec;
      return year + separator + month + separator + day + ' ' + hour + ':' + min + ':' + sec;
    case 5: // d Month YYYY
      return day + ' ' + DateOperators.MONTH_NAMES[month-1] + ' ' + year;
    case 6: // dayname, d Month YYYY
      var dd = date.getDay();
      dd = dd == 0 ? dd = 6 : dd-1; // our list starts with monday for some reason
      return DateOperators.WEEK_NAMES[dd] + ', ' + day + ' ' + DateOperators.MONTH_NAMES[month-1] + ' ' + year;
    case 7: // month name
      return DateOperators.MONTH_NAMES[month-1];
    case 8: // dayname
      var dd = date.getDay();
      dd = dd == 0 ? dd = 6 : dd-1; // our list starts with monday for some reason
      return DateOperators.WEEK_NAMES[dd];
    case 9: //YYYY-MM-DD
      return year + separator + (month < 10 ? '0'+month : month) + separator + (day < 10 ? '0'+day : day);
    case 10: // HH:MM:SS
      var hour = date.getHours();
      hour = hour < 10 ? '0' + hour : hour;
      var min = date.getMinutes();
      min = min < 10 ? '0' + min : min;
      var sec = date.getSeconds();
      sec = sec < 10 ? '0' + sec : sec;
      return hour + ':' + min + ':' + sec;
  }
};

 /**
 * takes a Date and zeroes out the time portion leaving only year,month and day set
 * @param  {Date} date to clear time
 * @return {Date}
 * tags:dates
 */
DateOperators.clearHoursMinutesSeconds = function(date) {
  if(date==null) return null;
  var s = DateOperators.dateToString(date,1); // converts to YYYY-MM-DD
  return DateOperators.stringToDate(s,1);
};

 /**
 * generates current date Date
 *
 * @param {Object} trigger any value that refreshes the Date
 * @return {Date}
 * tags:
 */
DateOperators.currentDate = function(trigger) {
  return new Date();
};

/**
 * adds days to a Date
 * @param {Date|String} date in string or Date format
 * @param {Number} nDays number of days
 * @return {Date}
 * tags:transform
 */
DateOperators.addDaysToDate = function(date, nDays) {
  if(date == null) return null;
  nDays = nDays==null?0:nDays;
  if(typeof date == 'string')
    date = DateOperators.parseDate(date);
  var date2 = new Date(date);
  date2.setDate(date2.getDate() + nDays);
  return date2;
};

/**
 * @todo write docs
 */
DateOperators.addMillisecondsToDate = function(date, nMilliseconds) {
  return new Date(date.getTime() + nMilliseconds);
};


/**
 * @todo write docs
 */
DateOperators.parseDate = function(string) {
  string = String(string);
  // javascript date handling is problematic. See for example: new Date('1994-10-9') and new Date('1994-10-09')
  // We use numeric form for the special case of YYYY-MM-DD to make them consistent
  var aParts = string.match(/^(\d{4})[\-\.](\d{1,2})[\-\.](\d{1,2})$/);
  if(aParts && aParts.length == 4)
    return new Date(aParts[1],aParts[2]-1,aParts[3]);
  return new Date(Date.parse(string));
};

/**
 * @todo write docs
 */
DateOperators.parseDates = function(stringList) {
  var dateList = new DateList();
  var i;
  for(i = 0; stringList[i] != null; i++) {
    dateList.push(this.parseDate(stringList[i]));
  }
  return dateList;
};

/**
 * @todo write docs
 */
DateOperators.hoursBetweenDates = function(date0, date1) {
  return(date1.getTime() - date0.getTime()) * DateOperators.millisecondsToHours;
};

/**
 * @todo write docs
 */
DateOperators.daysBetweenDates = function(date0, date1) {
  return(date1.getTime() - date0.getTime()) * DateOperators.millisecondsToDays;
};

/**
 * @todo write docs
 */
DateOperators.weeksBetweenDates = function(date0, date1) {
  return(date1.getTime() - date0.getTime()) * DateOperators.millisecondsToWeeks;
};

/**
 * @todo write docs
 */
DateOperators.yearsBetweenDates = function(date0, date1) {
  return(date1.getTime() - date0.getTime()) * DateOperators.millisecondsToYears;
};

/**
 * @todo write docs
 */
DateOperators.nDayInYear = function(date) {
  return Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) * DateOperators.millisecondsToDays);
};

/**
 * @todo write docs
 */
DateOperators.getDateDaysAgo = function(nDays) {
  return DateOperators.addDaysToDate(new Date(), -nDays);
};


/**
 * gets the week number within a year (weeks start on Sunday, first week may have less than 7 days if start in a day other than sunday
 * @param {Date} The date whose week you want to retrieve
 * @return {Number} The week number of the date in its year
 * tags:extract
 */
DateOperators.getWeekInYear = function(date) {
  if(date == null) return null;
  var onejan = new Date(date.getFullYear(), 0, 1);
  return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

/**
 * @todo write docs
 */
DateOperators.getNDaysInMonth = function(month, year) {
  return new Date(year, month, 0).getDate();
};

/**
 * takes a Date object or string representing a date and convert to a decimal form.
 * @param {Date|String} date to convert (as javascript Date object or a string)
 * @return {Number} numeric decimal date value
 * tags:decoder,dates
 */
DateOperators.getDecimalDate = function(d0) {
  var date;
  if(d0 == null) return null;
  if(typeof d0 == 'string'){
    date = new Date(d0);
    if(isNaN(date))
      throw new Error('Invalid format for date:' + d0);
  }
  else
    date = d0;

  var year = date.getFullYear();
  var d0 = new Date(year, 0, 1);
  var d1 = new Date(year + 1, 0, 1);
  return year + (date.getTime() - d0.getTime()) / (d1.getTime() - d0.getTime());
};

/**
 * takes a decimal date and return a Date object. Can also convert a NumberList of decimal dates to a DateList
 * @param {Number|NumberList} The numeric decimal date value
 * @return {Date}  The resulting Date object
 * tags:decoder,dates
 */
DateOperators.convertFromDecimalDate = function(nDate) {
  if(nDate == null) return null;
  if(nDate.type == 'NumberList'){
    var dL = new DateList();
    dL.name = nDate.name;
    for(var i=0;i < nDate.length; i++)
      dL.push(DateOperators.convertFromDecimalDate(nDate[i]));
    return dL;
  }
  var year = Math.floor(nDate);
  var d0 = new Date(year, 0, 1);
  var d1 = new Date(year + 1, 0, 1);
  var ntime = d0.getTime() + (d1.getTime()-d0.getTime())*(nDate-year);
  var date = new Date(Math.round(ntime));
  if(isNaN(date))
    throw new Error('Invalid decimal date value:' + nDate);
  return date;
};

DateOperators.getDateComponents = function(date){
  var comp = new mo.List();
  comp.push(date.getYear());
};

/**
 * extracts various properties from a date
 * @param  {Date} date  a date to operate on
 *
 * @param  {Number} iMode of output to create (default: 0):<|>0: year<|>1: month<|>2: day of month<|>3: day of week (0 is sunday)<|>4: hour<|>5: minute<|>6: second<|>7: decimal date<|>8: day number in year<|>9: quarter<|>10: decimal hours<|>11: day indicator vars(array of 7)<|>12: month indicator vars(array of 12)<|>13: day of week name<|>14: month name<|>15: am/pm
 * @return {Number}
 */
DateOperators.extractPropertiesFromDate = function(dt, cType){
  if(dt == null) return null;
  cType = cType == null ? 0 : cType;
  if(isNaN(cType) || cType % 1 !== 0 || cType < 0 || cType > 15)
    throw new Error('Invalid output type.');

  var res,dd,hrs,k;
  switch(cType){
    case 0:
      res = dt.getFullYear();
      break;
    case 1:
      res = dt.getMonth() + 1;
      break;
    case 2:
      res = dt.getDate();
      break;
    case 3:
      res = dt.getDay();
      break;
    case 4:
      res = dt.getHours();
      break;
    case 5:
      res = dt.getMinutes();
      break;
    case 6:
      res = dt.getSeconds();
      break;
    case 7:
      res = DateOperators.getDecimalDate(dt);
      break;
    case 8:
      res = DateOperators.nDayInYear(dt);
      break;
    case 9:
      dd = DateOperators.getDecimalDate(dt);
      res = Math.floor((dd - Math.floor(dd)) * 4) + 1;
      break;
    case 10:
      hrs = dt.getHours() + (dt.getMinutes()*60 + dt.getSeconds())/3600;
      res = Number(hrs.toFixed(5));
      break;
    case 11:
      res = [];
      for(k=0;k<7;k++){
        if(dt.getDay() == k)
          res.push(1);
        else
          res.push(0);
      }
      break;
    case 12:
      res = [];
      for(k=0;k<12;k++){
        if(dt.getMonth() == k)
          res.push(1);
        else
          res.push(0);
      }
      break;
    case 13:
      res = DateOperators.dateToString(dt,8);
      break;
    case 14:
      res = DateOperators.dateToString(dt,7);
      break;
    case 15:
      res = dt.getHours() >= 12 ? 'pm' : 'am';
      break;
  }
  return res;
};
