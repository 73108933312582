/* global console */

import List from "src/dataTypes/lists/List";
import NumberList from "src/dataTypes/numeric/NumberList";
import StringList from "src/dataTypes/strings/StringList";
import TableEncodings from "src/operators/lists/TableEncodings";
import ListGenerators from "src/operators/lists/ListGenerators";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import ListOperators from "src/operators/lists/ListOperators";
import {
  instantiate,
  instantiateWithSameType,
  typeOf
  } from "src/tools/utils/code/ClassUtils";
//

Table.prototype = new List();
Table.prototype.constructor = Table;

/**
 * @classdesc A sub-class of {@link List}, Table provides a 2D array-like structure.
 *
 * Each column is stored as its own {@link List}, making it a List of Lists.
 * Cells in the table can be accessed using table[column][row].
 *
 * Additional functions that work on Table can be found in:
 * <ul>
 *  <li>Operators:   {@link TableOperators}</li>
 *  <li>Conversions: {@link TableConversions}</li>
 *  <li>Generators: {@link TableGenerators}</li>
 *  <li>Encodings: {@link TableEncodings}</li>
 * </ul>
 *
 * @description Creates a new Table.
 * Input arguments are treated as the inital column values
 * of the Table.
 * @constructor
 * @category basics
 */
function Table() {
  var args = [];
  var i;
  var nArgs = arguments.length;

  for(i = 0; i < nArgs; i++) {
    args[i] = new List(arguments[i]);
  }

  var array = List.apply(this, args);
  array = Table.fromArray(array);

  return array;
}
export default Table;

/**
 * Creates a new Table from an array
 * @param {Number[]} array
 * @return {Table}
 */
Table.fromArray = function(array) {
  for ( var i=0; i< array.length; i++ ){
    if( Array.isArray(array[i])  && !array[i]["isList"] ) array[i] = List.fromArray(array[i]);
  }
  var result = List.fromArray(array);
  result.type = "Table";
  //assign methods to array:
  result.applyFunction = Table.prototype.applyFunction;
  result.getListsImproved = Table.prototype.getListsImproved;
  result.getColumn = Table.prototype.getColumn;
  result.getColumns = Table.prototype.getColumns;
  result.getWithoutColumn = Table.prototype.getWithoutColumn;
  result.getWithoutColumns = Table.prototype.getWithoutColumns;
  result.getColumnAndRemainingTable = Table.prototype.getColumnAndRemainingTable;
  result.getRow = Table.prototype.getRow;
  result.getRows = Table.prototype.getRows;
  result.getCell = Table.prototype.getCell;
  result.getLengths = Table.prototype.getLengths;
  result.getListLength = Table.prototype.getListLength;
  result.sliceRows = Table.prototype.sliceRows;
  result.getSubListsByIndexes = Table.prototype.getSubListsByIndexes; //deprecated
  result.getWithoutRow = Table.prototype.getWithoutRow;
  result.getWithoutRows = Table.prototype.getWithoutRows;
  result.getTransposed = Table.prototype.getTransposed;
  result.getListsSortedByList = Table.prototype.getListsSortedByList; //change name to getWithColumnsSortedByList
  result.getListsSortedByMultipleLists = Table.prototype.getListsSortedByMultipleLists;
  result.clone = Table.prototype.clone;
  result.cloneWithEmptyLists = Table.prototype.cloneWithEmptyLists;
  result.print = Table.prototype.print;
  result.appendRows = Table.prototype.appendRows;
  result.insertRow = Table.prototype.insertRow;

  //transformative
  result.removeRow = Table.prototype.removeRow;
  result.pushRow = Table.prototype.pushRow;

  //overiden
  result.destroy = Table.prototype.destroy;

  result.isTable = true;

  return result;
};


/**
 * Executes a given function on all the columns
 * in the Table, returning a new Table with the
 * resulting values.
 * @param {Function} func Function to apply to each
 * column in the table. Columns are {@link List|Lists}.
 * @return {Table} Table of values from applying function.
 */
Table.prototype.applyFunction = function(func) {
  //TODO: to be tested!
  var i;
  var newTable = new Table();

  newTable.name = this.name;

  for(i = 0; this[i] != null; i++) {
    newTable[i] = this[i].applyFunction(func);
  }
  return newTable.getImproved();
};


/**
 * applies getImproved() to all lists in the the Table
 * @return {Table} new Table with lists improved (best data type assigned)
 * tags:
 */
Table.prototype.getListsImproved = function() {
  return mo.Table.fromArray(this.map(function(list){return list.getImproved()}));
}



/**
 * returns a list from the Table, optionally slicing the list by providing initial and final indexes (notice that this method, except for the slicing, is equivalent to getElement because a Table is a List whose elements are Lists)
 * @param  {Number|String} indexOrNameOfColumn index (Number) or name (String) of column to extract.  A negative index can be used, indicating an offset from the end of the list of columns. For example -2 will get the second last column.<|0.name|>
 *
 * @param {Number} row0 initial index (included). A negative index can be used, indicating an offset from the end of the table lists. For example -2 is interpreted as the second last row.
 * @param {Number} row1 final index (included). A negative index can be used, indicating an offset from the end of the table lists. For example -2 is interpreted as the second last row.
 * @param {Boolean} without_repetitions without repetitions (default:false)
 * @return {List}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getColumn = function(indexOrNameOfColumn, row0, row1, without_repetitions) {

  var i;
  var found;

  if(indexOrNameOfColumn && indexOrNameOfColumn["isList"]){
    return indexOrNameOfColumn; //if the index is already a list, it returns it.
  } else if(typeof indexOrNameOfColumn =='string'){
    indexOrNameOfColumn = indexOrNameOfColumn.trim();
    found = false;
    for(i=0; i<this.length; i++){
      if(this[i].name==indexOrNameOfColumn){
        found = true;
        indexOrNameOfColumn = i;
        break;
      }
    }
    if(!found){
      // if(nullIfNotFound){
      //   return null;
      // } else {
      //   throw new Error("didn't find a list with name "+indexOrNameOfColumn);
      // }
      return null;
    }
  } else {
    indexOrNameOfColumn = indexOrNameOfColumn == null ? 0 : indexOrNameOfColumn;
    if(indexOrNameOfColumn<0){
      indexOrNameOfColumn+=this.length;
    }
  }

  var list = this[indexOrNameOfColumn];

  if(list==null){
    if(typeof indexOrNameOfColumn == 'number'){
      if(indexOrNameOfColumn>this.length){
        throw new Error("provided indexElementInColumn bigger than list in indexOrNameOfColumn");
      } else {
        return null;
      }
    } else if(typeof indexOrNameOfColumn == 'string'){
      
    } else {
      throw new Error("indexOrNameOfColumn should be a number or a string");
    }
  }

  if(list==null) return null;
  
  if(without_repetitions) return list.getWithoutRepetitions();

  if(row0==null && row1==null) return list;

  var l = list.length;

  if(row0==null) row0=0;
  if(row1==null) row1=l-1;
  if(row0<0) row0 += list.length;
  if(row1<0) row1 += list.length;

  row1 = Math.min(row1, l-1);

  var newList = new List();
  newList.name = list.name;

  for(i = row0; i <= row1; i++) {
    newList.push(list[i]);
  }

  return newList.getImproved();
};

/**
 * returns some lists from the Table, optionally slicing the list by providing initial and final indexes (notice that this method, except for the slicing, is equivalent to getElement because a Table is a List whose elements are Lists)
 * @param  {NumberList|StringList|List} indexesOrNames indexes (NumberList) or names (StringList) of columns to extract. A negative index can be used, indicating an offset from the end of the list of columns. For example -2 will remove the second last column.
 *
 * @param {Number} row0 initial index (included). A negative index can be used, indicating an offset from the end of the table lists. For example -2 is interpreted as the second last row.
 * @param {Number} row1 final index (included). A negative index can be used, indicating an offset from the end of the table lists. For example -2 is interpreted as the second last row.
 * @param {Boolean} emptyListIfNotFound true:adds an empty List if not found<br>false: doesn't add any List and the resulting Table could have less elements than indexes or names provided
 * @param {Boolean} reverse if true, will discard columns instead of select them
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getColumns = function(indexesOrNames, row0, row1, emptyListIfNotFound, reverse) {
  if(indexesOrNames==null) return;

  if(typeOf(indexesOrNames)=="string" && indexesOrNames.includes(',')) indexesOrNames = indexesOrNames.split(',');

  if(indexesOrNames["length"]==null || typeOf(indexesOrNames)=="string") indexesOrNames = [indexesOrNames];

  var newTable;
  if(reverse){
    newTable = this.getWithoutColumns(indexesOrNames);
    if(row0 != null || row1 != null)
      newTable = newTable.sliceRows(row0,row1);
    newTable.name = this.name;
    return newTable.getImproved();
  }

  newTable = new Table();
  var l = indexesOrNames.length;
  var i;
  var list;

  for(i=0; i<l; i++){
    list = this.getColumn(indexesOrNames[i], row0, row1);

    if(list==null){
      if(emptyListIfNotFound) newTable.push(new List());
    } else {
      newTable.push(list);
    }
    
  }

  return newTable.getImproved();
};

/**
 * Removes a column from the Table
 * @param  {Number|String|List} index Index, name of the List, or List to remove. A negative index can be used, indicating an offset from the end of the list of columns. For example -2 will remove the second last column.
 * @return {Table}
 * tags:filter
 */
Table.prototype.getWithoutColumn = function(indexListOrName){
  if(indexListOrName == null) return;
  var list = indexListOrName["isList"]?indexListOrName:this.getColumn(indexListOrName);
  if(list==null) return this;
  return this.getWithoutElement(list);
};

/**
 * Removes a column from the Table
 * @param  {NumberList|StringList|List} indexesOrNames indexes (NumberList) or names (StringList) of columns to remove.  A negative index can be used, indicating an offset from the end of the list of columns. For example -2 will remove the second last column.
 * @return {Table}
 * tags:filter
 */
Table.prototype.getWithoutColumns = function(indexesOrNames){
  if(indexesOrNames == null) return;
  var lists = this.getColumns(indexesOrNames);
  if(lists==null || lists.length==0) return this;
  return this.getWithoutElements(lists);
};

/**
 * returns a list from the Table and also a Table made from the remaining lists
 * @param  {Number|String} indexOrNameOfColumn index (Number) or name (String) of column to extract. A negative index can be used, indicating an offset from the end of the list of columns. For example -2 will get the second last column.
 *
 * @return {List}
 * @return {Table}
 * tags:filter
 */
Table.prototype.getColumnAndRemainingTable = function(indexOrNameOfColumn) {
  if(indexOrNameOfColumn == null) return;
  var LReturn = this.getColumn(indexOrNameOfColumn);
  var tableRemaining = this.getWithoutColumn(indexOrNameOfColumn);
  var aRet = [
    {
      type: "List",
      name: "list",
      description: "The list requested",
      value: LReturn
    },
    {
      type: "Table",
      name: "tableRemaining",
      description: "The Table with all the remaining columns",
      value: tableRemaining
    },
  ];
  return aRet;
};

/**
 * Returns a {@link List} with all the elements of a row.
 * @param  {Number} index Index of the row to get. A negative index can be used, indicating an offset from the end of the table lists. For example -2 will give the second last row.
 * @return {List}
 * tags:filter,extract
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getRow = function(index) {
  index = index==null?0:index;

  if(index<0){
    index += (this.length > 0) ? this[0].length : 0;
    console.log('Negative index used in getRow');
  }
  if(!Number.isInteger(Number(index)))
    return null;

  var list = new List();
  var i;
  var l = this.length;

  for(i = 0; i < l; i++) {
    list[i] = this[i][index];
  }

  return list.getImproved();;
};

/**
 * returns a table with all lists filtered by indexes
 * @param  {NumberList|Interval} indexes NumberList or Interval giving the range of rows to get. A negative index can be used, indicating an offset from the end of the table lists. For example -2 will give the second last row.
 *
 * @param  {Boolean} bReturnAllOnNull if true then return full Table if the indexes inlet is null (default:false)
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getRows = function(indexes,bReturnAllOnNull) {
  if(indexes==null){
    if(bReturnAllOnNull)
      return this;
    else
      return;
  }

  if(indexes.type == 'Interval'){
    if(indexes.x<0 && this.length>0) indexes.x += this[0].length;
    if(indexes.y<0 && this.length>0) indexes.y += this[0].length;
    indexes = NumberListGenerators.createSortedNumberList(Math.floor(indexes.y-indexes.x+1),indexes.x);
  }
  var newTable = new Table();
  var list;
  var i,j;
  var l = this.length;
  var il = indexes.length;
  var index;
  var bNegIndexMsg = false;

  for(i=0; i<l; i++){
    list = instantiateWithSameType(this[i]);
    for(j=0;j<il;j++){
      index = indexes[j];
      if(index<0){
        index += this[0].length;
        bNegIndexMsg = true;
      }
      list[j] = this[i][index];
    }
    // no need to do for numberLists since a subset is always still a numberList
    if(list.type != 'NumberList')
      list = list.getImproved();
    list.name = this[i].name;
    newTable[i] = list;
  }
  if(bNegIndexMsg)
    console.log('Negative index used in getRows');

  newTable.name = this.name;
  
  return newTable.getImproved();
};

/**
 * extracts an element from some list in the table
 *
 * @param  {Number|String} indexOrNameOfColumn index (Number) or name (String) of list (negative number accepted for counting from end downwards)
 * @param  {Number} indexElementInColumn index of element in list (negative number accepted for counting from end downwards)
 * @return {Object}
 * tags:
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getCell = function(indexOrNameOfColumn, indexElementInColumn) {
  var list = this.getColumn(indexOrNameOfColumn);

  return list.getElement(indexElementInColumn);
};


/**
 * Returns the length a column of the Table.
 * @param  {Number|String} indexOrNameOfColumn The Column to return its length (defaults)
 * @return {Number} Length of column at given indexOrNameOfColumn.
 */
Table.prototype.getListLength = function(indexOrNameOfColumn) {
  indexOrNameOfColumn = indexOrNameOfColumn || 0;
  if(indexOrNameOfColumn>=this.length) return;
  return this[indexOrNameOfColumn].length;
};

/**
 * returns the lengths of all the lists of the Table
 * @return {NumberList} Lengths of all lists in Table.
 */
Table.prototype.getLengths = function() {
  var lengths = new NumberList();
  var l = this.length;

  for(var i = 0; i<l; i++) {
    lengths[i] = this[i]==null?0:this[i].length;
  }
  return lengths;
};

/**
 * Filters a Table by selecting a section of rows, elements with last index included.
 * @param  {Number|Interval} startIndex Index of first element in all lists of the table (or an Interval with start and end indexes). A negative index can be used, indicating an offset from the end of the table lists. For example -2 will give the second last row.
 *
 * @param  {Number} endIndex Index of last elements in all lists of the table. A negative index can be used, indicating an offset from the end of the table lists. For example -2 will give the second last row.
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.sliceRows = function(startIndex, endIndex) {
  if(startIndex==null) return this;

  if(startIndex["x"]){
    endIndex = startIndex.y;
    startIndex = startIndex.x;
  };
  
  startIndex = startIndex==null?0:startIndex;
  endIndex = endIndex == null ? (this[0].length - 1) : endIndex;

  var i;
  var newTable = new Table();
  var newList;
  var l = this.length;

  newTable.name = this.name;
  for(i = 0; i<l; i++) {
    newList = this[i].getSubList(startIndex, endIndex);
    if(newList)
      newList.name = this[i].name;
    newTable.push(newList);
  }
  return newTable.getImproved();
};

/**
 * Filters the lists of the table by indexes (getRows).
 * @param  {NumberList} indexes
 * @return {Table}
 * tags:deprecated
 * replacedBy:getRows
 */
Table.prototype.getSubListsByIndexes = function(indexes) {
  return this.getRows(indexes);
};




/**
 * Returns a new Table with the row at the given index removed.
 * @param {Number} rowIndex Row to remove.  A negative index can be used, indicating an offset from the end of the table lists. For example -2 will exclude the second last row.
 * @return {Table} New Table.
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getWithoutRow = function(rowIndex) {
  var newTable = new Table();
  var l = this.length;
  newTable.name = this.name;
  if(rowIndex<0){
    rowIndex += (this.length > 0) ? this[0].length : 0;
    console.log('Negative index used in getWithoutRow');
  }
  if(!Number.isInteger(Number(rowIndex)))
    return this;

  for(var i = 0; i<l; i++) {
    newTable[i] = List.fromArray(this[i].slice(0, rowIndex).concat(this[i].slice(rowIndex + 1))).getImproved();
    newTable[i].name = this[i].name;
  }
  return newTable.getImproved();
};

/**
 * Returns a new Table with the rows listed in the given numberList removed.
 * @param {NumberList} rowsIndexes numberList of row indexes to remove. Negative indexes can be used, indicating an offset from the end of the table lists. For example -2 will exclude the second last row.
 * @return {Table}
 * tags:filter
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getWithoutRows = function(rowsIndexes) {// @todo improve efficiency by building dictionary and not using indexOf
  var newTable = new Table();
  var l = this.length;
  var nElements;
  newTable.name = this.name;
  // need to clone input since we can't modify it. It may be an array so we can't just use .clone
  rowsIndexes = NumberList.fromArray(rowsIndexes.slice(), false);
  // check for negative indexes
  for(var i=0; i < rowsIndexes.length; i++){
    if(rowsIndexes[i] < 0)
      rowsIndexes[i] += (this.length > 0) ? this[0].length : 0;
  }
  var dictionary = ListOperators.getBooleanDictionaryForList(rowsIndexes);
  for(i = 0; i<l; i++) {
    newTable[i] = instantiateWithSameType(this[i]);
    nElements = this[i].length;
    for(var j = 0; j<nElements; j++) {
      if(dictionary[j]==null) newTable[i].push(this[i][j]);
    }
    newTable[i].name = this[i].name;
  }
  return newTable.getImproved();
};



/**
 * Sort Table's lists by a list
 * @param  {Number|List|String} listOrIndex List used to sort, index of list in the table, or name of list<|0.name|>
 *
 * @param  {Boolean} ascending (true by default)
 * @param  {Boolean} stable equal values keep existing order (false by default)
 * @return {Table} table (of the same type)
 * tags:sort
 * examples:santiago/examples/forIntroPanel/MultipleWaysToManipulateTable
 */
Table.prototype.getListsSortedByList = function(listOrIndex, ascending, stable) { //depracated: use sortListsByList
  if(listOrIndex == null) return;

  var sortinglist = listOrIndex["isList"]? listOrIndex.clone() : this.getColumn(listOrIndex);
  if(sortinglist==null) return;

  ascending = ascending == null ? true : ascending;
  stable = stable == null ? false : stable;

  var newTable = instantiateWithSameType(this);
  var l = this.length;

  var pairsArray = [];

  for(var i = 0; i<sortinglist.length; i++) {
    pairsArray[i] = [i, sortinglist[i]];
  }

  var bSimpleComparator = (sortinglist.type=="NumberList" || sortinglist.type=="DateList") && !stable;
  var comparator;

  if(ascending) {
    if(bSimpleComparator){
      comparator = function(a, b) {
        return a[1] - b[1];
      };
    }
    else{
      if(stable)
        comparator = function(a, b) {
          return a[1] < b[1] ? -1 : a[1] > b[1] ?  1 : a[0] - b[0];
        };
      else
        comparator = function(a, b) {
          return a[1] < b[1] ? -1 : a[1] > b[1] ?  1 : 0;
        };
    }
  } else {
    if(bSimpleComparator){
      comparator = function(a, b) {
        return b[1] - a[1];
      };
    }
    else{
      if(stable)
        comparator = function(a, b) {
          return a[1] < b[1] ? 1 : a[1] > b[1] ? -1 : a[0] - b[0];
        };
      else
        comparator = function(a, b) {
          return a[1] < b[1] ? 1 : a[1] > b[1] ?  -1 : 0;
        };
    }
  }

  pairsArray = pairsArray.sort(comparator);

  var newList;
  newTable.name = this.name;
  
  for(i=0; i<l; i++){
    //newTable[i] = this[i].getSortedByList(sortinglist, ascending, null, stable);//<--- old method, inefficient
    newList = instantiateWithSameType(this[i]);
    newList.name = this[i].name;
    newTable[i] = newList;
    for(var j=0;j<sortinglist.length;j++){
      newList[j] = this[i][pairsArray[j][0]];
    }
  }

  return newTable;
};

/**
 * Sort the lists of a Table lists by multiple lists
 * @param  {NumberList|StringList} lists used to sort, indexes of lists in the table, or names of lists. Earlier lists have higher sorting priority.
 *
 * @param  {Boolean|StringList} ascending (true by default)<br>This can also be a list of true/false values, one for each sorting list
 * @return {Table} table (of the same type)
 * tags:sort
 */
Table.prototype.getListsSortedByMultipleLists = function(lists, ascending) {
  if(lists == null) return;

  // make it handle single element case also
  if(typeof lists == 'number'){
    var temp = new NumberList();
    temp.push(lists);
    lists = temp;
  }
  else if(typeof lists == 'string'){
    var temp = new StringList();
    temp.push(lists);
    lists = temp;
  }
  if(lists.type == 'StringList'){
    // names of lists -> transform to list indexes
    var sLNames = this.getNames();
    var temp = new NumberList();
    for(var i=0; i < lists.length; i++){
      var j = sLNames.indexOf(lists[i]);
      if(j == -1)
        throw new Error("No list with name "+lists[i]);
      temp.push(j);
    }
    lists = temp;
  }
  // now lists is always numeric index into table columns
  if(ascending == null) ascending = true;
  if(ascending.isList == null){
    // if not a list then convert into one of same length as lists
    ascending = ListGenerators.createListWithSameElement(lists.length,ascending);
  }
  var nLPolarity = new NumberList();
  var i;
  for(i = 0; i < ascending.length; i++){
    if(ascending[i] == true || ascending[i] == 'true' || ascending[i] == 1)
      nLPolarity.push(1);
    else
      nLPolarity.push(-1);
  }

  var newTable = instantiateWithSameType(this);
  var l = this.length;

  var rowArray = [];

  for(i = 0; i<this[0].length; i++) {
    rowArray[i] = {i:i, t:this, lists:lists, polarity:nLPolarity};
  }

  var comparator = function(a, b){
    var va,vb,p;
    for(var j=0; j < a.lists.length; j++){
      va = a.t[a.lists[j]][a.i];
      vb = b.t[b.lists[j]][b.i];
      p = j < a.polarity.length ? a.polarity[j] : 1;
      if(va < vb)
        return -1*p;
      else if(va > vb)
        return 1*p;
      else if(j == a.lists.length -1)
        return a.i - b.i;
    }
    return 0; // shouldn't reach here
  };

  rowArray = rowArray.sort(comparator);

  var newList;
  newTable.name = this.name;

  for(i=0; i<l; i++){
    newList = instantiateWithSameType(this[i]);
    newList.name = this[i].name;
    newTable[i] = newList;
    for(var j=0;j<this[i].length;j++){
      newList[j] = this[i][rowArray[j].i];
    }
  }

  return newTable;
};

/**
 * Transposes Table.
 * @param firstListAsHeaders
 * @param headersAsFirstList
 * @return {Table}
 */
Table.prototype.getTransposed = function(firstListAsHeaders, headersAsFirstList) {

  var tableToTranspose = firstListAsHeaders ? this.getSubList(1) : this;
  var l = tableToTranspose.length;
  var nElements;

  var table = instantiate(typeOf(tableToTranspose));
  if(tableToTranspose.length === 0) return table;
  var i;
  var j;
  var list;

  for(i = 0; i<l; i++) {
    list = tableToTranspose[i];
    nElements = list.length;
    for(j = 0; j<nElements; j++) {
      if(i === 0) table[j] = this.type == "NumberTable" ? new NumberList() : new List();
      table[j][i] = tableToTranspose[i][j];
    }
  }

  nElements = tableToTranspose[0].length;
  
  if(this.type != "NumberTable"){
    for(j = 0; j<nElements; j++) {
      table[j] = table[j].getImproved();
    }
  }

  if(firstListAsHeaders) {
    nElements = this[0].length;
    for(j = 0; j<nElements; j++) {
      table[j].name = String(this[0][j]);
    }
  }
  if(headersAsFirstList){
    var sLHeaders = new StringList();
    var iInit  = firstListAsHeaders?1:0;
    l = this.length;
    for(i = iInit; i<l; i++){
      sLHeaders.push(this[i].name);
    }
    table._splice(0,0,sLHeaders);
    table = table.getImproved();
    if(firstListAsHeaders) sLHeaders.name = this[0].name;
  }

  return table;
};


/**
 * removes a row from the table.
 * @param {Number} index The row to remove.
 * @return {Table}
 */
Table.prototype.removeRow = function(index) {
  for(var i = 0; this[i] != null; i++) {
    this[i].splice(index, 1);
  }
};

/**
 * Pushes a set of values to the columns of the table. A Convenience function that is transformative
 * @param {Object} value for first column
 * @param {Object} value for second column
 * @param {Object} value for third column
 * @param {Object} value for fourth column
 * @param {Object} value for fifth column
 * @return {Table}
 */
Table.prototype.pushRow = function() {
  if(arguments == null || arguments.length === 0 ||  arguments[0] == null) return null;
  var v = arguments;
  // If we have 1 argument that has length == this.length then push each element of it.
  // This lets us use this conveniently with getRow
  if(v.length == 1 && v[0].length == this.length)
    v = v[0];
  for(var i=0; i < v.length && i < this.length; i++)
    this[i].push(v[i]);
};

/**
 * makes a clone of the Table, that contains clones of the lists
 * @return {Table} Copy of table.
 */
Table.prototype.clone = function() {
  var l = this.length;
  var clonedTable = instantiateWithSameType(this);
  var i;

  clonedTable.name = this.name;

  for(i = 0; i<l; i++) {
    clonedTable.push(this[i].clone());
  }
  if(this.containsNulls !== undefined)
    clonedTable.containsNulls = this.containsNulls;
  return clonedTable;
};

/**
 * makes a copy of the Table, with empty lists having same types
 * @return {Table} Copy of table.
 */
Table.prototype.cloneWithEmptyLists = function() {
  var l = this.length;
  var i;
  var newTable = instantiateWithSameType(this);
  var newList;

  newTable.name = this.name;

  for(i = 0; i<l; i++) {
    newList = instantiateWithSameType(this[i]);
    newList.name = this[i].name;
    newTable.push(newList);
  }

  return newTable;
};



/**
 * Removes all contents of the Table.
 */
Table.prototype.destroy = function() {
  for(var i = 0; this[i] != null; i++) {
    this[i].destroy();
    delete this[i];
  }
};

/**
 * Prints contents of Table to console.log.
 */
Table.prototype.print = function() {
  console.log("///////////// <" + this.name + "////////////////////////////////////////////////////");
  console.log(TableEncodings.TableToCSV(this, null, true));
  console.log("/////////////" + this.name + "> ////////////////////////////////////////////////////");
};

/**
 * Modifies table by appending rows from input table, assumed to have same structure. Transformative
 * @param  {Table} tab2 rows will be appended on the end of existing table
 * @return {Table}
 */
Table.prototype.appendRows = function(tab2) {
  // Note that this method operates directly on existing object
  if(tab2 == null) return this;
  if(tab2 == this){
    // special case, clone or we end up in infinite loop below
    tab2 = tab2.clone();
  }
  var i,j;
  for(i=0; i < tab2.length; i++){
    if(i >= this.length){
      this[i] = instantiateWithSameType(tab2[i]);
      this[i].name = tab2[i].name;
    }
    for(j=0; j < tab2[i].length; j++)
      this[i].push(tab2[i][j]);
  }
  return this;
};

/**
 * insert a row into a table either at the end or in a specific position
 * @param  {List} row of values
 *
 * @param  {number} position to place the row (default: end of table)
 * @param  {boolean} bReplace if true replace row at position, otherwise insert it and move other rows down (default: false)
 * @return {Table} resulting table
 * tags:transform
 * examples:#/jeff/examples/insertRow
 */
Table.prototype.insertRow = function(row,position,bReplace,bTransformative) {
    // note that the inlet help for bTransformative is intentionally missing so it isn't accessible/described inside Studio
    // We keep the functionality though since it is useful in coding scenarios.
    if(row==null) return null;
    bReplace = bReplace == null ? false : bReplace;
    bTransformative = bTransformative == null ? false : bTransformative;
    var t = bTransformative ? this : this.clone();
    var i,val;
    for(i=0; i < t.length && i < row.length; i++){
      val = (t[i].type == 'NumberList' && !isNaN(row[i])) ? Number(row[i]) : row[i];
      t[i] = t[i].insertElement(val,position==null ? t[i].length : position,bReplace);
    }
    return t;
};
