import Network from "src/dataTypes/structures/networks/Network";

OntoMap.prototype = Object.create(Network.prototype);
OntoMap.prototype.constructor = OntoMap;

/*IRI constant definitions for the Universal Ontology and the Clients instantiation
* Internationalized Resource Identifiers (IRIs)
* https://www.w3.org/TR/owl2-syntax/#IRIs
* 
* fullIRI := an IRI as defined in [RFC3987], enclosed in a pair of < (U+3C) and > (U+3E) characters
* prefixName := a finite sequence of characters matching the as PNAME_NS production of [SPARQL]
* abbreviatedIRI := a finite sequence of characters matching the PNAME_LN production of [SPARQL]
* IRI := fullIRI | abbreviatedIRI
* 
*/
OntoMap.prototype.DW_URI_CLIENT_BASE = 'http://drumwave.com/datacatalog/ontologies/clients/';
OntoMap.prototype.DW_UNIVERSE_URI = 'http://drumwave.com/datacatalog/ontologies/universal#';

//Prefixes definitions
OntoMap.prototype.NAMESPACES = {
    owl: {
        prefix:'owl',
        namespace:"http://www.w3.org/2002/07/owl#"
    },
    rdf: {
        prefix:'rdf',
        namespace:"http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    },
    xml: {
        prefix:'xml',
        namespace:"http://www.w3.org/XML/1998/namespace"
    },
    xsd: {
        prefix:'xsd',
        namespace:"http://www.w3.org/2001/XMLSchema#"
    },
    rdfs: {
        prefix:'rdfs',
        namespace:"http://www.w3.org/2000/01/rdf-schema#"
    }
};

//List of external Ontologies to import
OntoMap.prototype.IMPORTS = [OntoMap.prototype.DW_UNIVERSE_URI];

// OWL DL 2 constructs used in OntoMap
OntoMap.prototype.CONSTRUCTS = {
    subClassOf: OntoMap.prototype.NAMESPACES.rdfs.namespace+'subClassOf',
    isA: OntoMap.prototype.NAMESPACES.rdf.namespace+'type', //as used in turte syntax
    a: OntoMap.prototype.NAMESPACES.rdf.namespace+'type', //as used in n3 syntax
    Class: OntoMap.prototype.NAMESPACES.rdf.namespace+'type',
    NamedIndividual: OntoMap.prototype.NAMESPACES.owl.namespace+'NamedIndividual',
    annotations:{
        label: OntoMap.prototype.NAMESPACES.rdfs.namespace+'label',
        comment: OntoMap.prototype.NAMESPACES.rdfs.namespace+'comment',
        seeAlso: OntoMap.prototype.NAMESPACES.rdfs.namespace+'seeAlso',
        isDefinedBy: OntoMap.prototype.NAMESPACES.rdfs.namespace+'isDefinedBy',
        versionInfo: OntoMap.prototype.NAMESPACES.owl.namespace+'versionInfo',
        priorVersion: OntoMap.prototype.NAMESPACES.owl.namespace+'priorVersion',
        backwardCompatibleWith: OntoMap.prototype.NAMESPACES.owl.namespace+'backwardCompatibleWith',
        incompatibleWith: OntoMap.prototype.NAMESPACES.owl.namespace+'incompatibleWith'
    }
}

/**
 * @classdesc  OntoMap are a DataType to store ontological network data.
 * OntoMap is created to represent Ontological Individuals network and be able to run reasoner.
 * extends from Network
 *
 * @namespace
 * @category ontology
 */
function OntoMap() {
    Network.call(this);

    //Uses the OWL Ontology definition standard to characterize this Ontology Instantiation network
    this.ontoObjectProperties = {
        "rdf:type":[
           OntoMap.prototype.NAMESPACES.owl.namespace+"Ontology"
        ]
    }

    //https://www.w3.org/TR/owl2-syntax/#Ontologies
    this.NAMESPACES = OntoMap.prototype.NAMESPACES;

    //https://www.w3.org/TR/owl-ref/#imports-def https://www.w3.org/TR/owl2-syntax/#Imports
    this.IMPORTS = OntoMap.prototype.IMPORTS;

    this.CONSTRUCTS = OntoMap.prototype.CONSTRUCTS;

}
export default OntoMap;

OntoMap.prototype.setCustomNamespace = function(namespacePrefix, namespace){
    this.NAMESPACES[namespacePrefix] = {
        prefix: namespacePrefix,
        namespace: namespace
    }
}

OntoMap.prototype.setBaseNamespace = function(namespacePrefix, namespace){
    this.NAMESPACES.base = {
        prefix: namespacePrefix,
        namespace: namespace
    }
}