import Point from "src/dataTypes/geometry/Point";
import Point3D from "src/dataTypes/geometry/Point3D";
import PointOperators from "src/operators/geometry/PointOperators";
import Polygon from "src/dataTypes/geometry/Polygon";
import Polygon3D from "src/dataTypes/geometry/Polygon3D";
import NumberList from "src/dataTypes/numeric/NumberList";
import Rectangle from "src/dataTypes/geometry/Rectangle";

/**
 * @classdesc Provides a set of tools that work with Geometric data.
 *
 * @namespace
 * @category geometry
 */
function GeometryOperators() {}
export default GeometryOperators;


/**
 * from three Points calculates two control Points for the middle Point that will define a curve (using Bézier) that goes softly through the three points
 * TODO: finish method by taking into account distances
 */
GeometryOperators.getSoftenControlPoints = function(point0, point1, point2, controlVectorSize) {
  controlVectorSize = controlVectorSize || 10;
  var angle = PointOperators.angleFromTwoPoints(point0, point2);
  var controlPoint0 = new Point(point1.x - controlVectorSize * Math.cos(angle), point1.y - controlVectorSize * Math.sin(angle));
  var controlPoint1 = new Point(point1.x + controlVectorSize * Math.cos(angle), point1.y + controlVectorSize * Math.sin(angle));
  return [controlPoint0, controlPoint1];
};

/**
 * @todo write docs
 */
GeometryOperators.bezierCurvePoints = function(x0, y0, c0x, c0y, c1x, c1y, x1, y1, t) {
  var s = 1 - t;
  var ax = s * x0 + t * c0x;
  var ay = s * y0 + t * c0y;

  var bx = s * c0x + t * c1x;
  var by = s * c0y + t * c1y;

  var cx = s * c1x + t * x1;
  var cy = s * c1y + t * y1;

  var ex = s * ax + t * bx;
  var ey = s * ay + t * by;

  var fx = s * bx + t * cx;
  var fy = s * by + t * cy;

  return new Point(t * fx + s * ex, t * fy + s * ey);
};



/**
 * @todo write docs
 */
GeometryOperators.trueBezierCurveHeightHorizontalControlPoints = function(x0, x1, y0, y1, c0x, c1x, x) {
  var dx = x1 - x0;
  x = (x - x0) / dx;
  c0x = (c0x - x0) / dx;
  c1x = (c1x - x0) / dx;

  if(GeometryOperators._bezierSimpleCurveTable == null) {
    var i, p;

    GeometryOperators._bezierSimpleCurveTable = new NumberList();

    for(i = 1; i < 10000; i++) {
      p = GeometryOperators.bezierCurvePoints(0, 0, c0x, 0, c1x, 1, 1, 1, i / 10000);
      GeometryOperators._bezierSimpleCurveTable[Math.floor(1000 * p.x)] = p.y;
    }

    GeometryOperators._bezierSimpleCurveTable[0] = 0;
    GeometryOperators._bezierSimpleCurveTable[1] = 1;
  }

  return GeometryOperators._bezierSimpleCurveTable[Math.floor(1000 * x)] * (y1 - y0) + y0;

};


/**
 * @todo write docs
 * This an approximation, it doesn't take into account actual values of c0x and c1x
 */
GeometryOperators.bezierCurveHeightHorizontalControlPoints = function(y0, c0x, c1x, y1, t) { //TODO:fix

  var cosinus = Math.cos(Math.PI * (t - 1));
  var sign = cosinus > 0 ? 1 : -1;

  return(0.5 + 0.5 * (Math.pow(cosinus * sign, 0.6) * sign)) * (y1 - y0) + y0;
};

GeometryOperators.distanceToBezierCurve = function(x0, y0, c0x, c0y, c1x, c1y, x1, y1, p, returnPoint) {
  var steps = Math.round((Math.abs(x0-x1) + Math.abs(y0-y1) ) / 50);
  steps = Math.min(50,Math.max(10,steps));
  var pt0,ptm;
  var dm = Number.MAX_VALUE;
  var d0,tm;
  // coarse check
  for(var s=0;s<=steps;s++){
    pt0 = GeometryOperators.bezierCurvePoints(x0, y0, c0x, c0y, c1x, c1y, x1, y1, s/steps);
    d0 = Math.pow(pt0.x - p.x, 2) + Math.pow(pt0.y - p.y, 2);
    if(d0<dm){
      dm=d0;
      tm=s/steps;
    }
  }
  // fine check
  dm = Number.MAX_VALUE;
  var tDelta = 0.1/steps;
  var tStart = Math.max(0,tm - 1/steps);
  var tEnd   = Math.min(1,tm + 1/steps);
  for(var t=tStart; t <= tEnd; t += tDelta){
    pt0 = GeometryOperators.bezierCurvePoints(x0, y0, c0x, c0y, c1x, c1y, x1, y1, t);
    d0 = Math.pow(pt0.x - p.x, 2) + Math.pow(pt0.y - p.y, 2);
    if(d0<dm){
      dm=d0;
      ptm=pt0;
    }
  }
  if(returnPoint) return ptm;
  return Math.sqrt(dm);
};


/**
 * @todo write docs
 */
GeometryOperators.triangleContainsPoint = function(pT0, pT1, pT2, p) {
  var a = (pT0.x - p.x) * (pT1.y - p.y) - (pT1.x - p.x) * (pT0.y - p.y);
  var b = (pT1.x - p.x) * (pT2.y - p.y) - (pT2.x - p.x) * (pT1.y - p.y);
  var c = (pT2.x - p.x) * (pT0.y - p.y) - (pT0.x - p.x) * (pT2.y - p.y);
  return(a > 0 && b > 0 && c > 0) || (a >= 0 && b >= 0 && c >= 0);
};

/**
 * @todo write docs
 */
GeometryOperators.triangleArea = function(triangle) {
  return Math.abs(triangle.a.x * (triangle.b.y - triangle.c.y) + triangle.b.x * (triangle.c.y - triangle.a.y) + triangle.c.x * (triangle.a.y - triangle.b.y)) / 2;
};


/////////////lines (line is a Point with values m and b in y=mx+b)

/**
 * @todo write docs
 */
GeometryOperators.lineFromTwoPoints = function(point0, point1) {
  if(point0.x == point1.x) return new Point(Infinity, point0.x);
  var m = (point1.y - point0.y) / (point1.x - point0.x);
  return new Point(m, point0.y - m * point0.x);
};

/**
 * @todo write docs
 */
GeometryOperators.distancePointToLine = function(point, line) {
  var m2;
  var b2;
  if(line.x === 0) {
    m2 = Infinity;
    b2 = point.x;
  } else {
    m2 = -1 / line.x;
    b2 = point.y - m2 * point.x;
  }
  var interPoint = GeometryOperators.intersectionLines(line, new Point(m2, b2));
  return Math.sqrt(Math.pow(point.x - interPoint.x, 2) + Math.pow(point.y - interPoint.y, 2));
};

/**
 * @todo write docs
 */
GeometryOperators.distancePointToSegment = function(point, point0Segment, point1Segment) {
  var m = point0Segment.x == point1Segment.x ? Infinity : (point1Segment.y - point0Segment.y) / (point1Segment.x - point0Segment.x);
  var line = m == Infinity ? new Point(Infinity, point0Segment.x) : new Point(m, point0Segment.y - m * point0Segment.x);
  var m2;
  var b2;
  if(line.x === 0) {
    m2 = Infinity;
    b2 = point.x;
  } else {
    m2 = -1 / line.x;
    b2 = point.y - m2 * point.x;
  }
  var interPoint = GeometryOperators.intersectionLines(line, new Point(m2, b2));
  if(interPoint.x >= Math.min(point0Segment.x, point1Segment.x) && interPoint.x <= Math.max(point0Segment.x, point1Segment.x)) return point.distanceToPoint(interPoint);
  return Math.min(point.distanceToPoint(point0Segment), point.distanceToPoint(point1Segment));
};

/**
 * @todo write docs
 */
GeometryOperators.intersectionLines = function(line0, line1) {
  if(line0.x == line1.x) {
    if(line0.y == line1.y) {
      if(line0.x == Infinity) {
        return new Point(line0.y, 0);
      } else {
        return new Point(0, line0.y);
      }
    }
    return null;
  }
  if(line0.x == Infinity) {
    return new Point(line0.y, line1.x * line0.y + line1.y);
  } else if(line1.x == Infinity) {
    return new Point(line1.y, line0.x * line1.y + line0.y);
  }

  var xx = (line1.y - line0.y) / (line0.x - line1.x);
  return new Point(xx, line0.x * xx + line0.y);
};

/**
 * Returns the intersection points of a line with a Rectangle.
 * @param  {Point} point0 One end of the line.
 * @param  {Point} point1 Second end of the line.
 * @param  {Rectangle} rect Rectangle to intersect with.
 *
 * @param  {Boolean} bLineInfinite If true use an infinite extended line. Default is false.
 * @return {Polygon} list of points of intersection - it could be an empty list.
 * tags:geometry
 */
GeometryOperators.intersectionLineRectangle = function(point0, point1, rect, bLineInfinite){
  bLineInfinite = bLineInfinite == null ? false : bLineInfinite;
  var lineTest = GeometryOperators.lineFromTwoPoints(point0,point1);
  // test each side of rectangle
  var lineTop = GeometryOperators.lineFromTwoPoints(rect.getTopLeft(),rect.getTopRight());
  var lineRight = GeometryOperators.lineFromTwoPoints(rect.getTopRight(),rect.getBottomRight());
  var lineBottom = GeometryOperators.lineFromTwoPoints(rect.getBottomLeft(),rect.getBottomRight());
  var lineLeft = GeometryOperators.lineFromTwoPoints(rect.getTopLeft(),rect.getBottomLeft());
  var pt;
  var polyResults = new Polygon();
  pt = GeometryOperators.intersectionLines(lineTest,lineTop);
  if(pt != null && rect.pointIsOnBorder(pt)) polyResults.push(pt);
  pt = GeometryOperators.intersectionLines(lineTest,lineRight);
  if(pt != null && rect.pointIsOnBorder(pt)) polyResults.push(pt);
  pt = GeometryOperators.intersectionLines(lineTest,lineBottom);
  if(pt != null && rect.pointIsOnBorder(pt)) polyResults.push(pt);
  pt = GeometryOperators.intersectionLines(lineTest,lineLeft);
  if(pt != null && rect.pointIsOnBorder(pt)) polyResults.push(pt);
  // Could be multiple results and may be intersection with infinite line
  if(!bLineInfinite){
    var polyKeep = new Polygon();
    for(var i=0;i<polyResults.length;i++){
      pt = polyResults[i];
      if(pt.x >= Math.min(point0.x,point1.x) &&
         pt.x <= Math.max(point0.x,point1.x) &&
         pt.y >= Math.min(point0.y,point1.y) &&
         pt.y <= Math.max(point0.y,point1.y) ){
        // inside the segment defined by point0 -> point1
        polyKeep.push(pt);
      }
    }
    polyResults = polyKeep;
  }
  return polyResults;
}

/**
 * @todo write docs
 */
GeometryOperators.VennCircles = function(area0, area1, areaIntersection, centerInLens, precision) {
  var rA = Math.sqrt(area0 / Math.PI);
  var rB = Math.sqrt(area1 / Math.PI);
  var d = GeometryOperators.circleDistancesFromCommonArea(rA, rB, areaIntersection, precision);

  var circle0;
  var circle1;

  if(centerInLens) {
    var x0 = (d * d + Math.pow(rA, 2) - Math.pow(rB, 2)) / (2 * d);

    circle0 = new Point3D(-x0, 0, rA);
    circle1 = new Point3D(d - x0, 0, rB);

  } else {
    circle0 = new Point3D(-d * 0.5, 0, rA);
    circle1 = new Point3D(d * 0.5, 0, rB);
  }

  if(areaIntersection === 0) {
    circle0.x -= d * 0.1;
    circle1.x += d * 0.1;
  }

  return new Polygon3D(circle0, circle1);
};

/**
 * very lazy and ineficcient solution (Newton algorithm)
 * @param r0
 * @param r1
 * @param areaComun
 * @param precision
 *
 */
GeometryOperators.circleDistancesFromCommonArea = function(r0, r1, commonArea, precision) {
  precision = precision || 0.1;
  var d0 = Math.max(r0, r1) - Math.min(r0, r1);
  var d1 = r0 + r1;
  var dM = (d0 + d1) * 0.5;

  var attempts = 0;

  var currentArea = GeometryOperators.circlesCommonArea(r0, r1, dM);

  while(Math.abs(currentArea - commonArea) > precision && attempts < 200) {
    if(currentArea > commonArea) {
      d0 = dM;
      dM = (d1 + dM) * 0.5;
    } else {
      d1 = dM;
      dM = (dM + d0) * 0.5;
    }
    attempts++;
    currentArea = GeometryOperators.circlesCommonArea(r0, r1, dM);
  }
  return dM;
};

/**
 * @todo write docs
 */
GeometryOperators.circlesCommonArea = function(ra, rb, d) {
  if(d >= (ra + rb)) return 0;
  if(d + Math.min(ra, rb) <= Math.max(ra, rb)) {
    return Math.PI * Math.pow(Math.min(ra, rb), 2);
  }

  var d2 = Math.pow(d, 2);
  var ra2 = Math.pow(ra, 2);
  var rb2 = Math.pow(rb, 2);

  return ra2 * Math.acos((d2 + ra2 - rb2) / (2 * d * ra)) + rb2 * Math.acos((d2 + rb2 - ra2) / (2 * d * rb)) - 0.5 * Math.sqrt((-d + ra + rb) * (d + ra - rb) * (d - ra + rb) * (d + ra + rb));
};

/**
 * This method return the angles required to draw the intersection shape (lens) of two circles
 */
GeometryOperators.circlesLensAngles = function(circle0, circle1) {
  if(circle1.x < circle0.x) {
    var _circle = circle1.clone();
    circle1 = circle0.clone();
    circle0 = _circle;
  }
  if(circle1.x + circle1.z <= circle0.x + circle0.z) {
    return null;
  } else if(circle0.x - circle0.z >= circle1.x - circle1.z) {
    return null;
  }

  var d = circle1.x - circle0.x;
  var x0 = (d * d + Math.pow(circle0.z, 2) - Math.pow(circle1.z, 2)) / (2 * d);
  var alfa = Math.acos(x0 / circle0.z);
  var h = circle0.z * Math.sin(alfa);
  var beta = Math.asin(h / circle1.z);

  if(circle0.x + x0 < circle1.x) {
    return new NumberList(-alfa, alfa, Math.PI - beta, Math.PI + beta);
  } else {
    return new NumberList(-alfa, alfa, beta, -beta);
  }
};

/**
 * finds vertical positions for circles placed on horizontal positions, avoiding overlap
 * @param {NumberList} xNumberList horizontal position
 * 
 * @param {NumberList|Number}  radii optional circles radii, could be a Number (all circles with same raidus) or a different radius per circle, 10 by default
 * @param {Number} margin optional distance between circles
 * @param {Number} jump speed at which it explores vertical space (default: 1)
 * @return {NumberList} vertical positions
 * tags:
 * examples:#/santiago/examples/circlesInAxis
 */
GeometryOperators.circlesInAxis = function(xNumberList, radii, margin, jump){
  if(xNumberList==null) return null;
  if(radii==null) radii = mo.ListGenerators.createListWithSameElement(xNumberList.length, 10);
  if(typeof(radii)=="number") radii = mo.ListGenerators.createListWithSameElement(xNumberList.length, radii);
  margin = margin==null?1:margin;
  var jump = jump==null?1:jump;

  var yNumberList = new NumberList();

  var collision = function(x,y,r){
    var d;
    for(var i=0; i<yNumberList.length; i++){
      d = radii[i]+r+margin;
      if(Math.pow(xNumberList[i] - x, 2)+Math.pow(yNumberList[i] - y, 2) < Math.pow(d, 2) ) {
        return true;
      }
    }
    return false;
  };

  var y, sign, n;

  for(var i=0; i<xNumberList.length; i++){
    y=0;
    sign = 1;
    n = 0;
    while(collision(xNumberList[i], y, radii[i])){
      n+=0.5;
      sign*=-1;
      y = Math.ceil(n)*sign*jump;
    }
    
    yNumberList[i] = y;
  }

  return yNumberList;
};



