import Rectangle from "src/dataTypes/geometry/Rectangle";
import RectangleOperators from "src/operators/geometry/RectangleOperators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import List from "src/dataTypes/lists/List";
import NumberList from "src/dataTypes/numeric/NumberList";
import ColorList from "src/dataTypes/graphic/ColorList";
import ColorScales from "src/operators/graphic/ColorScales";
import ColorListGenerators from "src/operators/graphic/ColorListGenerators";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";


/**
 * @classdesc Functions for drawing {@link Tree|Trees}.
 *
 * @namespace
 * @category drawing
 */
function TreeDraw() {}
export default TreeDraw;


/**
 * Simple tree visualization with levels in vertical rectangles.
 *
 * @param {Rectangle} frame
 * @param {Tree} tree The Tree to draw.
 *
 * @param {ColorList} levelColors
 * @param {Number} margin
 * tags:draw
 */
TreeDraw.drawRectanglesTree = function(frame, tree, levelColors, margin) {
  levelColors = levelColors == null ? ColorListGenerators.createCategoricalColors(1, tree.nLevels) : levelColors;
  margin = margin || 1;

  var dX = frame.width / tree.nLevels;
  TreeDraw._drawRectanglesTreeChildren(tree.nodeList[0], new Rectangle(frame.x, frame.y, dX, frame.height), levelColors, margin);
};

TreeDraw._drawRectanglesTreeChildren = function(node, frame, colors, margin, graphics) {
  graphics.context.fillStyle = colors[node.level];
  graphics.context.fillRect(frame.x + margin, frame.y + margin, frame.width - margin * 2, frame.height - margin * 2);
  var children = node.toNodeList;
  //console.log((node.level, node.name, children.length);
  if(children.length > 0) {
    var i;
    var dY = frame.height / (node.descentWeight - 1);
    var yy = frame.y;
    var h;
    for(i = 0; children[i] != null; i++) {
      h = dY * (children[i].descentWeight);
      TreeDraw._drawRectanglesTreeChildren(children[i], new Rectangle(frame.x + frame.width, yy, frame.width, h), colors, margin);
      yy += h;
    }
  }
};


/**
 * Creates a simple treemap visualization.
 *
 * @param {Rectangle} frame
 * @param {Tree} tree The Tree to draw.
 *
 * @param {ColorList} colorList
 * @param {NumberList} weights weights of leaves
 * @param {String} textColor if not provided will be calculated to contrast node color
 * @param {Node} externalSelectedNode node to force selection (by id)
 * @param {Number} imageMode 0: do not show images (default)<br>1: if image defined for a node then show it<br>2: if image defined for node show it and also write text<br>3: if image defined for node show it and write text at bottom
 * @return {Node} selected node
 * @return {Node} over node
 * tags:draw
 */
TreeDraw.drawTreemap = function(frame, tree, colorList, weights, textColor, externalSelectedNode, imageMode, graphics) {
  if(frame==null || tree==null) return;

  if(graphics==null) graphics = frame.graphics; //momentary fix

  var change = frame.memory == null || frame.memory.tree != tree || frame.memory.width != frame.width || frame.memory.height != frame.height || frame.memory.weights != weights;

  if(externalSelectedNode != null) externalSelectedNode = tree.nodeList.getNodeById(externalSelectedNode.id);

  var changeSelection = (externalSelectedNode != null && (frame.memory == null || externalSelectedNode.id != frame.memory.nodeSelected.id));
  var nNodes = tree.nodeList.length;
  imageMode = imageMode == null ? 0 : imageMode;
  var node;
  var i;
  var leaves;
  var nLeaves;

  if(change) {
    var changeInTree = frame.memory==null || frame.memory.tree!=tree;
    //console.log('changeInTree', changeInTree);
    //var changeInWeights = frame.memory != null && frame.memory.weights != weights;
    //var prevNodeSelected = frame.memory==null?null:frame.memory.nodeSelected;
    //var prevFocusFrame = frame.memory==null?null:frame.memory.prevFocusFrame;

    if(frame.memory==null){
      frame.memory = {
        width: frame.width,
        height: frame.height,
        weights: weights,
        nFLastChange: graphics.nF,
        tree:tree,
        nodeSelected:tree.nodeList[0]
      };
    } else {
      frame.memory.nFLastChange = graphics.nF;
      frame.memory.weights = weights;
      frame.memory.width = frame.width;
      frame.memory.height = frame.height;

      if(changeInTree){
        frame.memory.tree = tree;
        frame.memory.actualColorList = null;
      } 

      if(frame.memory.nodeSelected!=null) frame.memory.nodeSelected = tree.nodeList.getNodeById(frame.memory.nodeSelected.id);

      if(changeSelection) frame.memory.nodeSelected = externalSelectedNode;
    }

    //frame.memory.nodeSelected = prevNodeSelected==null?tree.nodeList[0]:prevNodeSelected;

    leaves = (!changeInTree && frame.memory.leaves) ? frame.memory.leaves : tree.getLeaves();
    frame.memory.leaves = leaves;
    nLeaves = leaves.length;

    if(weights == null) {

      var weightProperty = tree.nodeList[0].weight==null?"descentWeight":"weight";

      //tree.nodeList.forEach(function(node) {
      for(i=0; i<nNodes; i++){
        tree.nodeList[i]._treeMapWeight = tree.nodeList[i][weightProperty];//.descentWeight;
      }
      //});
    } else {
      

      //leaves.forEach(function(node, i) {
      for(i=0; i<nLeaves; i++){
        leaves[i]._treeMapWeight = weights[i];
      }
      //});

      var assignTreemapWeight = function(node) {
        var i;
        var n;
        if(node.toNodeList.length === 0) {
          return node._treeMapWeight;
        } else {
          node._treeMapWeight = 0;
          n = node.toNodeList.length;
          for(i = 0; i<n; i++) {
            node._treeMapWeight += assignTreemapWeight(node.toNodeList[i]);
          }
        }
        return node._treeMapWeight;
      };
      assignTreemapWeight(tree.nodeList[0]);
    }

    tree.nodeList[0]._outRectangle = new Rectangle(0, 0, frame.width, frame.height);
    tree.nodeList[0]._inRectangle = TreeDraw._inRectFromOutRect(tree.nodeList[0]._outRectangle,tree.nodeList[0].name == '');

    TreeDraw._generateRectangles(tree.nodeList[0]);

    //frame.memory.focusFrame = prevFocusFrame==null?TreeDraw._expandRect(tree.nodeList[0]._outRectangle):prevFocusFrame;
    //console.log('frame.memory.focusFrame', frame.memory.focusFrame);
    //if(frame.memory.nodeSelected!=null) console.log('frame.memory.nodeSelected.id', frame.memory.nodeSelected.id);

    //if(frame.memory.focusFrame==null || changeSelection) frame.memory.focusFrame = TreeDraw._expandRect(frame.memory.nodeSelected._outRectangle);
    
    //frame.memory.focusFrame = TreeDraw._expandRect(frame.memory.nodeSelected._outRectangle);
    
    if(frame.memory.nodeSelected==null) frame.memory.nodeSelected=tree.nodeList[0];

    if(frame.memory.nodeSelected==tree.nodeList[0]){
      frame.memory.focusFrame = frame.memory.nodeSelected._outRectangle.clone();
      frame.memory.focusFrame.x+=1;
      frame.memory.focusFrame.y+=1;
      frame.memory.focusFrame.width-=2;
      frame.memory.focusFrame.height-=2;
    } else {
      frame.memory.focusFrame = TreeDraw._expandRect(frame.memory.nodeSelected._outRectangle);
    }
    // frame.memory.focusFrame = TreeDraw._expandRect(frame.memory.nodeSelected._outRectangle);
    // frame.memory.focusFrame.x+=1;
    // frame.memory.focusFrame.y+=1;
    // frame.memory.focusFrame.width-=2;
    // frame.memory.focusFrame.height-=2;
    
    //if(changeInTree){
      frame.memory.kx = frame.width / frame.memory.focusFrame.width;
      frame.memory.mx = -frame.memory.kx * frame.memory.focusFrame.x;
      frame.memory.ky = frame.height / frame.memory.focusFrame.height;
      frame.memory.my = -frame.memory.ky * frame.memory.focusFrame.y;
    //}

    graphics.setText('black', 12);
    //tree.nodeList.forEach(function(node) {
    for(i=0; i<nNodes; i++){
      node = tree.nodeList[i];
      node._textWidth = graphics.getTextW(node.name);
    }
    //});
  }
  // patch by Jeff
  // bug introduced likely by change on Friday by S which I don't understand
  // Shows up as wrong text sizes in treemap for labels because node._textWidth are undefined
  // Set them here if they are undefined
  
  if(tree.nodeList[0]._textWidth==null || (nNodes > 1 && tree.nodeList[1]._textWidth==null) || tree.nodeList[0]._textWidth==0 || (nNodes > 1 && tree.nodeList[1]._textWidth==0) ){
    graphics.setText('black', 12);
    for(i=0; i<nNodes; i++){
      node = tree.nodeList[i];
      //if(node._textWidth == null || node._textWidth == 0)
      node._textWidth = graphics.getTextW(node.name);
    }
  }
  

  if(frame.memory.colorList != colorList || frame.memory.actualColorList == null) {
    if(leaves==null) leaves = tree.getLeaves();
    
    frame.memory.nFLastChange = graphics.nF;
    frame.memory.image = null;
    if(colorList!=null){
      frame.memory.actualColorList = colorList;
    } else if(leaves[0].color!=null){
      frame.memory.actualColorList = leaves.getPropertyValues('color');
      frame.memory.actualColorList.bFromLeaves = true;
    } else {
      frame.memory.actualColorList = colorList == null ? ColorListGenerators.createCategoricalColors(0, tree.nLevels, ColorScales.grayToOrange, 0.1) : colorList;
    }
    var nodeColors = tree.nodeList.getPropertyValues('color');
    var bColorsForEachNode = false;
    var numNulls = nodeColors.countElement(null);
    if(nodeColors.length - numNulls == tree.nodeList.length){
      bColorsForEachNode = true;
      frame.memory.actualColorList = nodeColors;
    }
    else if(nodeColors.length - numNulls == tree.nodeList.length-1 && tree.nodeList[0].color == null){
      // allow case where every node but root has color set
      bColorsForEachNode = true;
      tree.nodeList[0].color = nodeColors[0] = 'rgb(128,128,128)';
      frame.memory.actualColorList = nodeColors;
    }

    frame.memory.nodesColorList = new ColorList();
    if(textColor == null) frame.memory.textsColorList = new ColorList();

    if(frame.memory.actualColorList.length <= tree.nLevels && !frame.memory.actualColorList.bFromLeaves) {
      //tree.nodeList.forEach(function(node, i) {
      for(i=0; i<nNodes; i++){
        node = tree.nodeList[i];
        frame.memory.nodesColorList[i] = node._color = frame.memory.actualColorList[node.level % frame.memory.actualColorList.length];
      }
      //});
    } else if(!bColorsForEachNode && frame.memory.actualColorList.length == frame.memory.leaves.length) {
      
      leaves = frame.memory.leaves;
      nLeaves = leaves.length;

      for(i=0; i<nLeaves; i++){
        node = leaves[i];
        node._color = frame.memory.actualColorList[i];
        node._rgb = ColorOperators.colorStringToRGB(node._color);
      }

      // frame.memory.leaves.forEach(function(node, i) {
        
      // });

      var assignColor = function(node) {
        
        //console.log('-'.repeat(node.level),node.level,node.name);
        
        if(node.toNodeList.length === 0) return;

        var sumWeights = 0;
        var i;
        var son;
        var nSons = node.toNodeList.length;

        node._rgb = [0, 0, 0];
        for(i = 0; i<nSons; i++) {
          son = node.toNodeList[i];
          assignColor(son);
          node._rgb[0] += (son._rgb[0]*son.weight);
          node._rgb[1] += (son._rgb[1]*son.weight);
          node._rgb[2] += (son._rgb[2]*son.weight);
          sumWeights+=son.weight;

          //console.log(' '.repeat(node.level), '•••', son.level, son.weight, son.name);

        }
        if(sumWeights == 0)
          for(i = 0; i<nSons; i++) {
            // just use a linear combination of children colors (as if weight==1)
            son = node.toNodeList[i];
            node._rgb[0] += (son._rgb[0]*1);
            node._rgb[1] += (son._rgb[1]*1);
            node._rgb[2] += (son._rgb[2]*1);
            sumWeights+=1;
          }
        //console.log('sumWeights', sumWeights, 'node._rgb', node._rgb.join(','));

        node._rgb[0] = Math.floor(node._rgb[0] / sumWeights);
        node._rgb[1] = Math.floor(node._rgb[1] / sumWeights);
        node._rgb[2] = Math.floor(node._rgb[2] / sumWeights);
        //console.log('new node._rgb', node._rgb.join(','));
      };

      assignColor(tree.nodeList[0]);//recursive
      
      for(var i=0; i<nNodes; i++){
        node = tree.nodeList[i];
        if(node._rgb && node._rgbF == null) node._rgbF = [node._rgb[0], node._rgb[1], node._rgb[2]];
        if(node._rgb==null) node._rgb=[255,255,255];
        frame.memory.nodesColorList[i] = 'rgb(' + node._rgb[0] + ',' + node._rgb[1] + ',' + node._rgb[2] + ')';
      }
      //});
    } else {
      //tree.nodeList.forEach(function(node, i) {
      for(i=0; i<nNodes; i++){
        node = tree.nodeList[i];
        node._color = frame.memory.nodesColorList[i] = frame.memory.actualColorList[i % frame.memory.actualColorList.length];
      }
      //});
    }

    if(textColor == null) {
      var rgb;
      //tree.nodeList.forEach(function(node, i) {
      for(i=0; i<nNodes; i++){
        node = tree.nodeList[i];
        rgb = node._color ? ColorOperators.colorStringToRGB(node._color) : [0, 0, 0];
        frame.memory.textsColorList[i] = (rgb[0] + rgb[1] + rgb[2] > 360) ? 'black' : 'white';
      }
      //});
    }

    frame.memory.colorList = colorList;
  }

  if(textColor == null) textColor = 'black';
  var textOutlineColor = ColorOperators.invertColor(textColor);
  textOutlineColor = ColorOperators.addAlpha(textOutlineColor,.5);

  var kxF = frame.width / frame.memory.focusFrame.width;
  var mxF = -kxF * frame.memory.focusFrame.x;
  var kyF = frame.height / frame.memory.focusFrame.height;
  var myF = -kyF * frame.memory.focusFrame.y;

  var v = kxF > frame.memory.kx ? 0.1 : 0.2;

  if(change) v=1;

  var antiv = 1 - v;

  frame.memory.kx = antiv * frame.memory.kx + v * kxF;
  frame.memory.mx = antiv * frame.memory.mx + v * mxF;
  frame.memory.ky = antiv * frame.memory.ky + v * kyF;
  frame.memory.my = antiv * frame.memory.my + v * myF;
  var kx = frame.memory.kx;
  var mx = frame.memory.mx;
  var ky = frame.memory.ky;
  var my = frame.memory.my;

  var tx = function(x) {
    return kx * x + mx;
  };
  var ty = function(y) {
    return ky * y + my;
  };


  var x, y;
  var margTextX, margTextY;
  var textSize;
  var exceedes;
  var rect;
  var overNode = null;
  var overI;
  var mouseOnFrame = frame.containsPoint(graphics.mP);
  //var moving = graphics.nF - frame.memory.nFLastChange < 50 || Math.pow(frame.memory.kx - kxF, 2) + Math.pow(frame.memory.ky - kyF, 2) + Math.pow(frame.memory.mx - mxF, 2) + Math.pow(frame.memory.my - myF, 2) > 0.01;
  var captureImage = false;// !moving && frame.memory.image == null && !mouseOnFrame;
  var drawingImage = false;// !moving && !mouseOnFrame && frame.memory.image != null &&  !captureImage && frame.memory.image.width > 0 && !changeSelection;

  if(drawingImage) {
    graphics.drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
  } else {
    if(captureImage) {
      //OLD VERSION
    } else {
      graphics.context.save();
      graphics.clipRect(frame.x, frame.y, frame.width, frame.height);
    }

    graphics.setStroke('black', 0.2);

    for(i=0; i<nNodes; i++){
      node = tree.nodeList[i];

      rect = new Rectangle(tx(node._outRectangle.x), ty(node._outRectangle.y), node._outRectangle.width * kx, node._outRectangle.height * ky);

      if(rect.width > 7 && rect.height > 5 && rect.x < frame.width && rect.getRight() > 0 && rect.y < frame.height && rect.getBottom() > 0) {

        x = Math.round(frame.x + rect.x) + 0.5;
        y = Math.round(frame.y + rect.y) + 0.5;

        if(node._rgbF) {
          node._rgbF[0] = 0.95 * node._rgbF[0] + 0.05 * node._rgb[0];
          node._rgbF[1] = 0.95 * node._rgbF[1] + 0.05 * node._rgb[1];
          node._rgbF[2] = 0.95 * node._rgbF[2] + 0.05 * node._rgb[2];
          graphics.setFill('rgb(' + Math.floor(node._rgbF[0]) + ',' + Math.floor(node._rgbF[1]) + ',' + Math.floor(node._rgbF[2]) + ')');
        } else {
          graphics.setFill(frame.memory.nodesColorList[i]);
        }

        if(graphics.fsRectM(x, y, Math.floor(rect.width), Math.floor(rect.height))) {
          overNode = node;
          overI = i;
        }

        if(node.image && imageMode > 0){
          var rImage = rect.clone();
          rImage.x += frame.x;
          rImage.y += frame.y;
          graphics.fitImage(node.image,rImage);
        }

        if(rect.width > 20 && (node.image == null || imageMode != 1)) {
          margTextX = rect.width * TreeDraw.PROP_RECT_MARGIN * 0.8;
          margTextY = rect.height * TreeDraw.PROP_RECT_MARGIN * 0.15;
          textSize = rect.height * TreeDraw.PROP_RECT_LABEL - 2;
          if(textSize >= 7) {

            var propTextSpace = (rect.width - 2 * margTextX) / (node._textWidth * textSize / 12);
            exceedes = propTextSpace < 1; //(node._textWidth*textSize/12)>(rect.width-1.2*margTextX);

            var textSizeAdj = exceedes ? textSize * propTextSpace : textSize;
            graphics.setText(textColor ? textColor : frame.memory.textsColorList[i], textSizeAdj);
            var textW = graphics.getTextW(node.name);
            if(imageMode > 0 && node.image != null){
              // fill a background rect
              var rTextBackground = new Rectangle(x + margTextX, y + margTextY,textW,textSizeAdj);
              if(imageMode == 3)
                rTextBackground.y = y + rect.height - margTextY - textSizeAdj;
              var fillSave = graphics.context.fillStyle;
              graphics.setFill(textOutlineColor);
              graphics.fRect(rTextBackground);
              graphics.setFill(fillSave);
            }
            if(imageMode == 3 && node.image != null)
              graphics.fText(node.name, x + margTextX, y + rect.height - margTextY - textSizeAdj);
            else  
              graphics.fText(node.name, x + margTextX, y + margTextY);
          }
        }
      } else {//not visible
        if(node._rgbF) {
          node._rgbF[0] = 0.95 * node._rgbF[0] + 0.05 * node._rgb[0];
          node._rgbF[1] = 0.95 * node._rgbF[1] + 0.05 * node._rgb[1];
          node._rgbF[2] = 0.95 * node._rgbF[2] + 0.05 * node._rgb[2];
        }
      }
    }

    if(captureImage) {
      //OLD VERSION
    }
  }

  if(mouseOnFrame) {
    if(overNode) {
      graphics.setCursor('pointer');

      rect = new Rectangle(tx(overNode._outRectangle.x), ty(overNode._outRectangle.y), overNode._outRectangle.width * kx, overNode._outRectangle.height * ky);
      x = Math.round(frame.x + rect.x) + 0.5;
      y = Math.round(frame.y + rect.y) + 0.5;
      graphics.setStroke(textColor ? textColor : frame.memory.textsColorList[overI], 2);
      graphics.sRect(x, y, Math.floor(rect.width), Math.floor(rect.height));

      if(graphics.MOUSE_UP_FAST) {
        if(overNode==tree.nodeList[0]){
          frame.memory.focusFrame = overNode._outRectangle.clone();
          frame.memory.focusFrame.x+=1;
          frame.memory.focusFrame.y+=1;
          frame.memory.focusFrame.width-=2;
          frame.memory.focusFrame.height-=2;
        } else {
          frame.memory.focusFrame = TreeDraw._expandRect(overNode._outRectangle);
        }

        //frame.memory.focusFrame = TreeDraw._expandRect(overNode._outRectangle);
        frame.memory.nodeSelected = overNode;

        frame.memory.image = null;
      }
    }
    if(graphics.MOUSE_DOWN) {
      frame.memory.prevMX = graphics.mX;
      frame.memory.prevMY = graphics.mY;
    }
    if(graphics.MOUSE_PRESSED) {
      var scale = 5 * frame.memory.focusFrame.width / frame.width;
      frame.memory.focusFrame.x -= (graphics.mX - frame.memory.prevMX) * scale;
      frame.memory.focusFrame.y -= (graphics.mY - frame.memory.prevMY) * scale;

      frame.memory.prevMX = graphics.mX;
      frame.memory.prevMY = graphics.mY;
    }
    if(graphics.WHEEL_CHANGE !== 0) {
      var center = frame.memory.focusFrame.getCenter();
      var zoom = 1 - 0.1 * graphics.WHEEL_CHANGE;
      frame.memory.focusFrame.x = center.x - frame.memory.focusFrame.width * 0.5 * zoom;
      frame.memory.focusFrame.y = center.y - frame.memory.focusFrame.height * 0.5 * zoom;
      frame.memory.focusFrame.width *= zoom;
      frame.memory.focusFrame.height *= zoom;
    }
    if(graphics.MOUSE_PRESSED || graphics.WHEEL_CHANGE !== 0) {
      frame.memory.image = null;
    }
  }

  if(changeSelection) {
    frame.memory.focusFrame =  externalSelectedNode==tree.nodeList[0]?externalSelectedNode._outRectangle:TreeDraw._expandRect(externalSelectedNode._outRectangle);
    frame.memory.nodeSelected = externalSelectedNode;
    frame.memory.image = null;
  }

  if(!captureImage && !drawingImage) graphics.context.restore();

  var returnArray = [
  {
    type: "Node",
    name: "nodeSelected",
    description: "selected node",
    value: frame.memory.nodeSelected
  },
  {
    type: "Node",
    name: "overNode",
    description: "Hovered node",
    value: overNode
  }
  ];
  returnArray.output = true;


  return returnArray;

};

/**
 * @ignore
 */
TreeDraw._generateRectangles = function(node) {
  var n = node.toNodeList.length;

  var i;
  var weights = new NumberList();
  var child;
  var sumWeights = 0;
  var pairs = [];//to build positions, that help squarify
  var sortedWeights = new mo.NumberList();

  for(i=0; i<n; i++){
    child = node.toNodeList[i];
    weights[i]=child._treeMapWeight;
    sumWeights+=child._treeMapWeight;
    pairs.push([i, weights[i]]);
  }
  ////helpin squarify
  pairs.sort(function(a, b) {
    if(a[1] < b[1]) return 1;
    return -1;
  });
  var positions = new NumberList();
  ////

  for(i=0; i<n; i++){
    weights[i]/=sumWeights;
    positions[i] = pairs[i][0];
    //sortedWeights[i] = pairs[n-i-1][1]/=sumWeights;
    sortedWeights[i] = pairs[i][1]/=sumWeights;
  }
  weights.positions = positions;
  weights.sortedWeights = sortedWeights;


  var rectangles = RectangleOperators.squarify(node._inRectangle, weights, true, false);

  //node.toNodeList.forEach(function(child, i) {
  for(i=0; i<n; i++){
    child = node.toNodeList[i];
    child._outRectangle = TreeDraw._reduceRect(rectangles[i]);
    child._inRectangle = TreeDraw._inRectFromOutRect(child._outRectangle);

    TreeDraw._generateRectangles(child);
  }
  //});
};

/**
 * @ignore
 */
TreeDraw._reduceRect = function(rect) {
  return new Rectangle(rect.x + rect.width * TreeDraw.PROP_RECT_REDUCTION_MARGIN, rect.y + rect.height * TreeDraw.PROP_RECT_REDUCTION_MARGIN, rect.width * (1 - 2 * TreeDraw.PROP_RECT_REDUCTION_MARGIN), rect.height * (1 - 2 * TreeDraw.PROP_RECT_REDUCTION_MARGIN));
};
TreeDraw._expandRect = function(rect) {
  return new Rectangle(rect.x - rect.width * TreeDraw.PROP_RECT_EXPANTION_MARGIN, rect.y - rect.height * TreeDraw.PROP_RECT_EXPANTION_MARGIN, rect.width * (1 + 2 * TreeDraw.PROP_RECT_EXPANTION_MARGIN), rect.height * (1 + 2 * TreeDraw.PROP_RECT_EXPANTION_MARGIN));
};
TreeDraw._inRectFromOutRect = function(rect,bSkipLabelSpace) {
  if(bSkipLabelSpace)
    return new Rectangle(rect.x + rect.width * TreeDraw.PROP_RECT_MARGIN, rect.y + rect.height * TreeDraw.PROP_RECT_MARGIN, rect.width * (1 - 2 * TreeDraw.PROP_RECT_MARGIN), rect.height * (1 - 2*TreeDraw.PROP_RECT_MARGIN));
  return new Rectangle(rect.x + rect.width * TreeDraw.PROP_RECT_MARGIN, rect.y + rect.height * TreeDraw.PROP_RECT_LABEL, rect.width * (1 - 2 * TreeDraw.PROP_RECT_MARGIN), rect.height * (1 - TreeDraw.PROP_RECT_MARGIN - TreeDraw.PROP_RECT_LABEL));
};
TreeDraw.PROP_RECT_MARGIN = 0.03;
TreeDraw.PROP_RECT_LABEL = 0.2;
TreeDraw.PROP_RECT_REDUCTION_MARGIN = 0.01;
TreeDraw.PROP_RECT_EXPANTION_MARGIN = 0.05;



///////////////////////////decision tree



/**
 * Decision tree visualization, tree from {@link TableOperators}'s buildDecisionTree function.
 * @param {Rectangle} frame
 * @param {Tree} tree
 *
 * @param {String} textColor
 * @return {Node} selected node
 * @return {Node} hovered node
 * @return {NumberList} indexes of rows in original Table that conform the population defined by the selected node
 * @return {Object} filters (Nest Object) contains information about all the numercial and categorical filters defined by the selected node
 * tags:draw,ds,machine-learning
 */
TreeDraw.drawDecisionTree = function(frame, tree, textColor, graphics) {
  if(frame==null || tree==null) return;

  if(graphics==null) graphics = frame.graphics; //momentary fix

  var change = frame.memory == null || frame.memory.tree != tree || frame.memory.width != frame.width || frame.memory.height != frame.height;

  textColor = textColor||'black';

  var gap = frame.height * 0.06;
  var hTree = tree.nLevels * (frame.height - gap) / (tree.nLevels + 1);
  var hLevel = hTree / tree.nLevels;
  var changeInResult = false;

  if(change) {
    var changeInTree = frame.memory != null && frame.memory.tree != null && frame.memory.tree != tree;

    frame.memory = {
      tree: tree,
      width: frame.width,
      height: frame.height,
      nodeSelected: tree.nodeList[0],
      nFLastChange: graphics.nF,
      leaves: null,
      image: null
    };

    if(changeInTree || frame.memory.leaves == null) {
      frame.memory.leaves = tree.getLeaves().getSortedByProperty('valueFollowingProbability', false);
    }



    tree.nodeList[0]._outRectangle = new Rectangle(0, 0, frame.width, hTree);
    tree.nodeList[0]._inRectangle = TreeDraw._inRectFromOutRectDecision(tree.nodeList[0]._outRectangle, hLevel);
    TreeDraw._generateRectanglesDecision(tree.nodeList[0], hLevel);

    frame.memory.focusFrame = new Rectangle(0, 0, frame.width, frame.height);
    frame.memory.kx = frame.width / frame.memory.focusFrame.width;
    frame.memory.mx = -frame.memory.kx * frame.memory.focusFrame.x;
    frame.memory.ky = frame.height / frame.memory.focusFrame.height;
    frame.memory.my = -frame.memory.ky * frame.memory.focusFrame.y;

    graphics.setText(textColor, 12);
    tree.nodeList.forEach(function(node) {
      //node.isLeaf = node.toNodeList.length === 0;
      //node.label = node.isLeaf === 0 ? Math.round(node.valueFollowingProbability * 100) / 100 : node.bestFeatureName;
      node._textWidth = graphics.getTextW(node.label);
    });


  }

  if(frame.memory.followingWeights) {
    tree.nodeList.forEach(function(node) {
      node._treeMapWeight = node.descentWeight;
    });
  }

  var kxF = frame.width / frame.memory.focusFrame.width;
  var mxF = -kxF * frame.memory.focusFrame.x;

  var v = kxF > frame.memory.kx ? 0.05 : 0.1;
  var antiv = 1 - v;

  frame.memory.kx = antiv * frame.memory.kx + v * kxF;
  frame.memory.mx = antiv * frame.memory.mx + v * mxF;
  var kx = frame.memory.kx;
  var mx = frame.memory.mx;

  var tx = function(x) {
    return kx * x + mx;
  };

  var x, y;
  var margTextX, margTextY;
  var textSize;
  var exceedes;
  var rect;
  var overNode = null;
  var overI;
  var mouseOnFrame = frame.containsPoint(graphics.mP);
  //var moving = graphics.nF - frame.memory.nFLastChange < 80 || Math.pow(frame.memory.kx - kxF, 2) + Math.pow(frame.memory.mx - mxF, 2) > 0.001;
  var captureImage = false;//provisional // !moving && frame.memory.image == null && !mouseOnFrame;
  var drawingImage = false;//provisional // !moving && !mouseOnFrame && frame.memory.image != null &&  !captureImage && frame.memory.image.width > 0;
  var yLeaves;
  
  if(drawingImage) {
    graphics.drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
  } else {
    //c.l('drawing');
    if(captureImage) {
      // TODO refactor this to not reassign context
      // var newCanvas = document.createElement("canvas");
      // newCanvas.width = frame.width;
      // newCanvas.height = frame.height;
      // var newContext = newCanvas.getContext("2d");
      // newContext.clearRect(0, 0, frame.width, frame.height);
      // var mainContext = context;
      // context = newContext;
      // var prevFx = frame.x;
      // var prevFy = frame.y;
      // frame.x = 0;
      // frame.y = 0;
      // setFill('white');
      // fRect(0, 0, frame.width, frame.height);
      // setText('black', 12);
    } else {
      graphics.context.save();
      graphics.clipRect(frame.x, frame.y, frame.width, frame.height);
    }

    yLeaves = frame.y + hTree + gap;

    graphics.setStroke('black', 0.2);

    tree.nodeList.forEach(function(node, i) {

      rect = new Rectangle(tx(node._outRectangle.x), node._outRectangle.y, node._outRectangle.width * kx, node._outRectangle.height);

      if(rect.x < frame.width && rect.getRight() > 0 && rect.y < frame.height && rect.getBottom() > 0) {

        x = Math.round(frame.x + rect.x) + 0.5;
        y = Math.round(frame.y + rect.y) + 0.5;

        if(node.pattern) {
          graphics.context.fillStyle = node.pattern;
          graphics.context.fillRect(x, y, Math.floor(rect.width), Math.floor(rect.height));

          if(graphics.sRectM(x, y, Math.floor(rect.width), Math.floor(rect.height))) {
            overNode = node;
            overI = i;
          }

        } else {

          graphics.setFill(node._color);

          if(graphics.fsRectM(x, y, Math.floor(rect.width), Math.floor(rect.height))) {
            overNode = node;
            overI = i;
          }
        }

        var realWidth = Math.min(rect.getRight(), frame.width) - Math.max(rect.x, 0);

        if(realWidth > 18) {
          margTextX = rect.width * TreeDraw.PROP_RECT_MARGIN * 0.8;
          margTextY = rect.height * TreeDraw.PROP_RECT_MARGIN * 0.15;
          var tC = textColor ? textColor : frame.memory.textsColorList[i];
          textSize = 18;

          graphics.setText(tC, textSize);
          exceedes = true;
          if(exceedes) {
            graphics.clipRect(x, y - 17, rect.width, rect.height+17);
          }

          
          
          //feature (or P for leaves)
          if(!tree.supervisedValue && node.isLeaf){
            graphics.setText(tC, textSize);
            graphics.fText(node.supervisedValue, Math.max(x, frame.x) + 8, y+(node.isLeaf?rect.height:hLevel)-21);
          } else {
            graphics.fText(node.label, Math.max(x, frame.x) + 8, y+(node.isLeaf?rect.height:hLevel)-21);
          }

          if(node.value) {
            textSize = 14;
            graphics.setText(tC, textSize);

            graphics.fText(node.value, Math.max(x, frame.x) + 8, y+2);//y - 17);
          }

          //size
          if(realWidth - node._textWidth > 64) {
            textSize = 12;
            graphics.setText(tC, textSize, null, 'right');

            graphics.fText("s=" + node.weight, Math.min(frame.x + rect.getRight(), frame.getRight()) - 2, y + 1);
            graphics.fText("e=" + Math.round(node.entropy * 100) / 100, Math.min(frame.x + rect.getRight(), frame.getRight()) - 2, y + 12);
            if(node.toNodeList.length >= 0) {
              graphics.fText("P=" + Math.round(node.valueFollowingProbability * 1000) / 1000, Math.min(frame.x + rect.getRight(), frame.getRight()) - 2, y + 23);
            }
            graphics.fText("l=" + Math.round(node.lift * 100) / 100, Math.min(frame.x + rect.getRight(), frame.getRight()) - 2, y + 23 + (node.toNodeList.length >= 0 ? 11 : 0));
          }

          if(exceedes) {
            graphics.context.restore();
          }
        }
      }
    });

    //leaves (spectrum below)

    var x0 = frame.x;
    var w;
    var sx = frame.width / tree.sumWeightsLeaves;// (tree.nodeList[0].getPropertyValues("weight").getSum());
    var waitingForMark = true;
    var waitingForDoubleMark = true;
    var waitingForHalfMark = true;
    var waitingFor15Mark = true;
    var waitingFor067Mark = true;

    var sortedLeaves = frame.memory.leaves;

    if(!tree.supervisedValue){
      sortedLeaves = sortedLeaves.getSortedByProperty("supervisedValue");
    }

    sortedLeaves.forEach(function(node) {
      graphics.setStroke('black', 0.2);

      w = sx * node.weight;

      if(node.pattern) {
        graphics.context.fillStyle = node.pattern;
        graphics.context.fillRect(x0, yLeaves, w, hLevel);

        if(graphics.sRectM(x0, yLeaves, w, hLevel)) {
          overNode = node;
          overI = node.i;// tree.nodeList.indexOf(node);
        }

      } else {
        graphics.setFill(node._color);
        if(graphics.fsRectM(x0, yLeaves, w, hLevel)) {
          overNode = node;
          overI = node.i;// tree.nodeList.indexOf(node);
        }
        if(!tree.supervisedValue && w>10){
          graphics.clipRect(x0, yLeaves, w-4, hLevel);
          graphics.setText('black', 12);
          graphics.fText(node.supervisedValue, x0+4, yLeaves+hLevel-16);
          graphics.context.restore();
        }
      }

      node._xLeaf = x0;
      node._wLeaf = w;
      if(tree.supervisedValue){
        graphics.setStroke('black', 1);

        if(waitingForMark && node.valueFollowingProbability < tree.nodeList[0].valueFollowingProbability) {
          waitingForMark = false;
          graphics.setFill('black');
          graphics.fLines(x0, yLeaves - 14, x0 + 4, yLeaves - 8, x0, yLeaves - 2, x0 - 4, yLeaves - 8);
        }
        if(waitingForDoubleMark && node.valueFollowingProbability <= tree.nodeList[0].valueFollowingProbability * 2) {
          waitingForDoubleMark = false;
          graphics.line(x0, yLeaves - 14, x0, yLeaves - 2);
        }
        if(waitingForHalfMark && node.valueFollowingProbability < tree.nodeList[0].valueFollowingProbability * 0.5) {
          waitingForHalfMark = false;
          graphics.line(x0, yLeaves - 14, x0, yLeaves - 2);
        }
        if(waitingFor15Mark && node.valueFollowingProbability <= tree.nodeList[0].valueFollowingProbability * 1.5) {
          waitingFor15Mark = false;
          graphics.line(x0, yLeaves - 8, x0, yLeaves - 2);
        }
        if(waitingFor067Mark && node.valueFollowingProbability < tree.nodeList[0].valueFollowingProbability * 0.66667) {
          waitingFor067Mark = false;
          graphics.line(x0, yLeaves - 8, x0, yLeaves - 2);
        }
      }

      x0 += w;

    });

    if(captureImage) {
      // TODO refactor this to not reassign context
      // context = mainContext;
      // frame.memory.image = new Image();
      // frame.memory.image.src = newCanvas.toDataURL();
      // frame.x = prevFx;
      // frame.y = prevFy;
      // drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
    }
  }

  if(mouseOnFrame) {
    if(overNode) {
      graphics.setCursor('pointer');

      //rect = new Rectangle(tx(overNode._outRectangle.x), ty(overNode._outRectangle.y), overNode._outRectangle.width*kx, overNode._outRectangle.height*ky);
      rect = new Rectangle(tx(overNode._outRectangle.x), overNode._outRectangle.y, overNode._outRectangle.width * kx, overNode._outRectangle.height);
      x = Math.round(frame.x + rect.x) + 0.5;
      y = Math.round(frame.y + rect.y) + 0.5;

      graphics.setStroke(textColor ? textColor : frame.memory.textsColorList[overI], 2);

      if(overNode._wLeaf) {
        graphics.setFill('rgba(0,0,0,0.5)');
        graphics.context.beginPath();

        graphics.context.moveTo(x, yLeaves - gap);
        graphics.context.bezierCurveTo(x, yLeaves - 0.65 * gap, overNode._xLeaf, yLeaves - gap * 0.35, overNode._xLeaf, yLeaves);
        graphics.context.lineTo(overNode._xLeaf + overNode._wLeaf, yLeaves);
        graphics.context.bezierCurveTo(overNode._xLeaf + overNode._wLeaf, yLeaves - gap * 0.35, x + rect.width, yLeaves - 0.65 * gap, x + rect.width, yLeaves - gap);
        graphics.context.fill();

        graphics.sRect(overNode._xLeaf, yLeaves, overNode._wLeaf, hLevel);
      } else if(overNode.parent){
        var leaves = overNode.getLeaves();
        graphics.setFill('rgba(0,0,0,0.5)');
        for(var i=0; i<leaves.length; i++){
          graphics.fRect(leaves[i]._xLeaf, yLeaves-gap*0.4, leaves[i]._wLeaf, gap*0.4);
        }
      }

      graphics.sRect(x, y, Math.floor(rect.width), Math.floor(rect.height));

      if(graphics.MOUSE_UP_FAST) {
        frame.memory.focusFrame = new Rectangle(overNode._outRectangle.x - overNode._outRectangle.width * 0.025, 0, overNode._outRectangle.width * 1.05, frame.height); // TreeDraw._expandRect(overNode._outRectangle);

        if(frame.memory.focusFrame.x < 0) {
          frame.memory.focusFrame.width += frame.memory.focusFrame.x;
          frame.memory.focusFrame.x = 0;
        }

        if(frame.memory.focusFrame.getRight() > frame.width) {
          frame.memory.focusFrame.width -= (frame.memory.focusFrame.getRight() - frame.width);
          frame.memory.focusFrame.x = frame.width - frame.memory.focusFrame.width;
        }


        frame.memory.nodeSelected = overNode;
        changeInResult = true;

        frame.memory.image = null;
      }
    }
    if(graphics.MOUSE_DOWN) {
      frame.memory.prevMX = graphics.mX;
    }
    if(graphics.MOUSE_PRESSED) {
      var scale = 5 * frame.memory.focusFrame.width / frame.width;
      frame.memory.focusFrame.x -= (graphics.mX - frame.memory.prevMX) * scale;
      frame.memory.prevMX = graphics.mX;
    }
    if(graphics.WHEEL_CHANGE !== 0) {
      var center = frame.memory.focusFrame.getCenter();
      var zoom = 1 + 0.1 * graphics.WHEEL_CHANGE;
      frame.memory.focusFrame.x = center.x - frame.memory.focusFrame.width * 0.5 * zoom;
      frame.memory.focusFrame.width *= zoom;
    }
    if(graphics.MOUSE_PRESSED || graphics.WHEEL_CHANGE !== 0) {

      frame.memory.image = null;

    }
  }

  if(!captureImage && !drawingImage) {
    graphics.context.restore();
  }


  if(frame.memory.overNode != overNode) {
    frame.memory.overNode = overNode;
    changeInResult = true;
  }

  if(changeInResult || frame.memory.result == null) {
    frame.memory.result = [
      {
        value: frame.memory.nodeSelected,
        type: 'Node'
      },
      {
        value: frame.memory.overNode,
        type: 'Node'
      }
      ,
      {
        value: frame.memory.nodeSelected.indexes,
        type: 'Node'
      }
      ,
      {
        value: frame.memory.nodeSelected.filters,
        type: 'Array'
      }
    ];
  }

  return frame.memory.result;
};

/**
 * @ignore
 */
TreeDraw._generateRectanglesDecision = function(node, hLevel) {

  var weights = new NumberList();
  var child;

  for(var i =0; i<node.toNodeList.length; i++){
    child = node.toNodeList[i];
    weights[i] = child.weight;
  }

  //node.toNodeList.forEach(function(node) {
  //  weights.push(node.weight);
  //});

  var rectangles = TreeDraw._horizontalRectanglesDecision(node._inRectangle, weights);

  //node.toNodeList.forEach(function(child, i) {
  for(i =0; i<node.toNodeList.length; i++){
    child = node.toNodeList[i];
    child._outRectangle = rectangles[i];
    child._inRectangle = TreeDraw._inRectFromOutRectDecision(child._outRectangle, hLevel);
    TreeDraw._generateRectanglesDecision(child, hLevel);
  }
  //});
};

/**
 * @ignore
 */
TreeDraw._inRectFromOutRectDecision = function(rect, hLevel) {
  return new Rectangle(rect.x, rect.y + hLevel, rect.width, rect.height - hLevel);
};

/**
 * @ignore
 */
TreeDraw._horizontalRectanglesDecision = function(rect, weights) {
  var rects = new List();
  var x0 = rect.x;
  var w;
  var newWeights = NumberListOperators.normalizeToSum(weights);

  newWeights.forEach(function(weight) {
    w = weight * rect.width;
    rects.push(new Rectangle(x0, rect.y, w, rect.height));
    x0 += w;
  });

  return rects;
};
