import List from "src/dataTypes/lists/List";
import NumberList from "src/dataTypes/numeric/NumberList";
import ImageOperators from "src/tools/graphic/ImageOperators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import DrawTexts from "src/tools/graphic/DrawTexts";
import StringList from "src/dataTypes/strings/StringList";

/**
 * @classdesc  String Conversions
 *
 * @namespace
 * @category strings
 */
function StringConversions() {}
export default StringConversions;



/**
 * converts a string in json format into an Object (JSON.parse(string))
 * @param  {String} string in format json
 * @return {Object}
 * tags:conversion
 */
StringConversions.stringToObject = function(string) {
  try {
    return JSON.parse(string);
  } catch(err) {
    // fall through and try some common quasi-json formats
  }
  if(string == null) return null;
  // often we have a list of objects on separate lines without enclosing [] or commas between
  var aObjs = string.split(/\r?\n/);
  var s='[';
  var sAdded, bAddedItem = false;
  for(var i = 0; i<aObjs.length; i++){
    if(aObjs[i].trim().length == 0) continue;
    if(aObjs[i] == '{'){
      // assume each object definition spread across multiple lines
      sAdded = '';
      while(aObjs[i] != '}' && i < aObjs.length){
        sAdded += ' ' + aObjs[i];
        i++;
      }
      sAdded += '}';
    }
    else
      sAdded = aObjs[i];
    sAdded = (bAddedItem ? ',\r\n' : '\r\n') + sAdded;
    bAddedItem = true;
    s += sAdded;
  }
  s += '\r\n]';
  try {
    return JSON.parse(s);
  } catch(err) {
    return null;
  }
};

/**
 * converts a string into an integer (not for use in cryptography)
 * @param  {String} string of arbitrary length
 *
 * @param {Number} m is upper limit to numeric code (default: 4294967295, also the max)
 * @param {Number} k is number of hashcodes to return for input (default: 1)
 * @return {Number} hashcode for the string, unless k > 1, then a NumberList
 * tags:conversion
 */
StringConversions.stringToHash = function(str,m,k) {
  // Fowler/Noll/Vo hashing
  // from https://github.com/jasondavies/bloomfilter.js/blob/master/bloomfilter.js
  if(str == null) return null;
  m = m == null ? 4294967295 : m;
  k = k == null ? 1 : k;
  var x,i,c,d,n,a = 2166136261;
  for(i = 0, n = str.length; i < n; ++i){
    c = str.charCodeAt(i);
    d = c & 0xff00;
    if(d) a = StringConversions._fnv_multiply(a ^ d >> 8);
    a = StringConversions._fnv_multiply(a ^ c & 0xff);
  }
  a = StringConversions._fnv_mix(a);
  x = a % m;
  var b = k == 1 ? 0 : StringConversions._fnv_mix(StringConversions._fnv_multiply(a));
  var nL = new NumberList();
  for(i = 0;i < k;i++){
    nL.push(x < 0 ? (x + m) : x);
    x = (x + b) % m;
  }
  if(k == 1)
    return nL[0];
  return nL;
}

/**
 * @ignore
*/
StringConversions._fnv_multiply = function(a){
  // a * 16777619 mod 2**32, part of Fowler/Noll/Vo hashing.
  return a + (a << 1) + (a << 4) + (a << 7) + (a << 8) + (a << 24);
};

/**
 * @ignore
*/
StringConversions._fnv_mix = function(a){
  // part of Fowler/Noll/Vo hashing.
  // See https://web.archive.org/web/20131019013225/http://home.comcast.net/~bretm/hash/6.html
  a += a << 13;
  a ^= a >>> 7;
  a += a << 3;
  a ^= a >>> 17;
  a += a << 5;
  return a & 0xffffffff;
};

/**
 * converts a string into an Image object
 * @param  {String|StringList} string to be placed into an image form. Can also be a StringList in which case a list of Images are returned
 *
 * @param  {String} clrBackground is the background color of the image (default:white). If the first inlet is a list then this inlet can be a list of colors also.
 * @param  {String} clrText is the color of the text (default:black or white, whichever gives best contrast on background color) If the first inlet is a list then this inlet can be a list of colors also.
 * @param  {Number} width of the resulting image (default:200)
 * @param  {Number} height of the resulting image (default:200)
 * @param  {Number} fontHeight of the text (default:12)
 * @param  {Boolean} bHorizontalCenter center the text horizontally (default:false)
 * @param  {Boolean} bVerticalCenter center the text vertically (default:false)
 * @return {Image} image form of the string
 * tags:conversion,image
 */
StringConversions.stringToImage = function(string, clrBackground, clrText, width, height, fontHeight, bHorizontalCenter, bVerticalCenter){
  if(string == null) return;
  clrBackground = clrBackground == null ? 'white' : clrBackground;
  var clrText0 = clrText;
  if(clrText == null && !clrBackground.isList){
    var brightness = ColorOperators.getPerceivedBrightness(clrBackground);
    clrText = brightness > 125 ? 'black' : 'white';
  }
  clrText = clrText == null ? 'black' : clrText;
  width = width == null ? 200 : width;
  height = height == null ? 200 : height;
  fontHeight = fontHeight ==  null ? 12 : fontHeight;
  bHorizontalCenter = bHorizontalCenter == null ? false : bHorizontalCenter;
  bVerticalCenter = bVerticalCenter == null ? false : bVerticalCenter;

  if(string.type == 'StringList'){
    // return a list of images
    var Limg = new List();
    for(var i=0; i < string.length; i++){
      var clrb = clrBackground.isList == true ? clrBackground[i] : clrBackground;
      var clrt = clrText.isList == true ? clrText[i] : clrText;
      if(clrText0 == null){
        var brightness = ColorOperators.getPerceivedBrightness(clrb);
        clrt = brightness > 125 ? 'black' : 'white';
      }
      Limg.push(StringConversions.stringToImage(string[i], clrb, clrt, width, height, fontHeight, bHorizontalCenter, bVerticalCenter));
    }
    return Limg;
  }

  // make temporary graphics object
  var gt = new mo.Graphics({
    container: null,
    dimensions: {width: width, height: height},
    noLoop: true,
    noStart: true
  });
  gt.setFill(clrBackground);
  gt.fRect(0,0,width,height);

  gt.setText(clrText,fontHeight,null,null,null);
  if(bVerticalCenter){
    // if it doesn't fit vertically then turn off bVerticalCenter to show top info which is usually more important
    // get number of lines
    var textLines = DrawTexts.textWordWrapReturnLines(string, width, height, fontHeight, true, gt);
    if(textLines.length * fontHeight > height)
      bVerticalCenter = false;
  }

  DrawTexts.fillTextRectangle(string,0,0,width,height,fontHeight,false,true,gt,bHorizontalCenter,bVerticalCenter);
  var imgRet = gt.captureCanvas();
  return imgRet;
};
