import List from "src/dataTypes/lists/List";
import Point3D from "src/dataTypes/geometry/Point3D";

Polygon3D.prototype = new List();
Polygon3D.prototype.constructor = Polygon3D;

/**
 * @classdesc Polygon3D brings the {@link Polygon} concept into three
 * dimensions through the use of {@link Point3D}.
 *
 * @description Creates a new Polygon3D.
 * @constructor
 * @category geometry
 */
function Polygon3D() {
  var array = List.apply(this, arguments);
  array = Polygon3D.fromArray(array);
  return array;
}
export default Polygon3D;

/**
 * @todo write docs
 */
Polygon3D.fromArray = function(array) {
  var result = List.fromArray(array);
  result.type = "Polygon3D";

  //assign methods to array:
  result.getBarycenter = Polygon3D.prototype.getBarycenter;

  return result;
};

/**
 * Returns the barycenter Point3D for Polygon3D
 * @return {Point3D} barycenter
 * tags:math,geometry,3D
 */
Polygon3D.prototype.getBarycenter = function() {
	var x=0;
	var y=0;
	var z=0;
	for(var i=0; i<this.length; i++){
		x+=this[i].x;
		y+=this[i].y;
		z+=this[i].z;
	}
	return new Point3D(x/this.length, y/this.length, z/this.length);
};

