import ColorList from "src/dataTypes/graphic/ColorList";
import ColorOperators from "src/operators/graphic/ColorOperators";
import Polygon3D from "src/dataTypes/geometry/Polygon3D";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import NumberTable from "src/dataTypes/numeric/NumberTable";

/**
 * @classdesc Tools for working with Lists of colors.
 *
 * @namespace
 * @category colors
 */
function ColorListOperators() {}
export default ColorListOperators;

/**
 * receives n arguments and performs addition
 *
 * @todo finish docs
 */
ColorListOperators.colorListFromColorScale = function(colorScale, nColors) {
  return colorScale.getColorList.apply(colorScale, [nColors]);
};

/**
 * Creates a new ColorList from a given ColorScale, splitting the scale up into
 * nColors number of colors
 *
 * @param  {ColorScale} colorScaleFunction The ColorScale to split up.
 * @param  {Number} nColors The number of colors to add to the list.
 * @return {ColorList} new ColorList.
 */
ColorListOperators.colorListFromColorScaleFunction = function(colorScaleFunction, nColors) {
  var colorList = new ColorList();
  var i;
  for(i = 0; i < nColors; i++) {
    colorList[i] = colorScaleFunction(i / (nColors - 1));
  }
  return colorList;
};

/**
 * creates a color from a combination of colors with different weights
 * @param {ColorList} colors
 * 
 * @param {NumberList} weights for each color (without weights colors will be equally weighed)
 * @return {String} combined color
 * tags:combine
 */
ColorListOperators.colorsLinearCombination = function(colorList, weights) {
  if(colorList==null || colorList.length==0) return;
  if(colorList.length==1) return colorList[0];

  if(weights==null) weights = mo.ListGenerators.createListWithSameElement(colorList.length,1);

  weights = mo.NumberListOperators.normalizeToSum(weights);

  var rgb = ColorOperators.colorStringToRGB(colorList[0], weights[0]);
  var rgbN;

  for(var i=1; i<colorList.length; i++){
    rgbN = ColorOperators.colorStringToRGB(colorList[i], weights[i]);
    rgb[0]+=rgbN[0];
    rgb[1]+=rgbN[1];
    rgb[2]+=rgbN[2];
  }

  return 'rgb('+Math.floor(rgb[0])+','+Math.floor(rgb[1])+','+Math.floor(rgb[2])+')';
};


/**
 * @todo write docs
 */
ColorListOperators.colorListFromColorScaleFunctionAndNumberList = function(colorScaleFunction, numberList, normalize) {
  normalize = normalize == null ? true : normalize;

  if(normalize) numberList = NumberListOperators.normalized(numberList);

  var colorList = new ColorList();
  var i;
  for(i = 0; numberList[i] != null; i++) {
    colorList[i] = colorScaleFunction(numberList[i]);
  }
  return colorList;
};


/**
 * @todo write docs
 */
ColorListOperators.polygon3DToColorList = function(polygon3D) {
  var nPoints = polygon3D.length;
  var colorList = new ColorList();
  var i;
  for(i = 0; i < nPoints; i++) {
    colorList.push(ColorOperators.point3DToColor(polygon3D[i]));
  }
  return colorList;
};


/**
 * @todo write docs
 */
ColorListOperators.colorListToPolygon3D = function(colorList) {
  var nColors = colorList.length;
  var polygon3D = new Polygon3D();
  var i;
  for(i = 0; i < nColors; i++) {
    polygon3D.push(ColorOperators.colorToPoint3D(colorList[i]));
  }
  return polygon3D;
};

/**
 * extracts RGB values from colors and build a RGB NumberTable
 * @param {ColorList} colorList colors to be converted
 * @return {NumberTable} rgb table
 * tags:
 */
ColorListOperators.colorListToRGBTable = function(colorList) {
  if(colorList == null) return null;
  var nColors = colorList.length;
  var table = new NumberTable();
  table[0] = new mo.NumberList();
  table[1] = new mo.NumberList();
  table[2] = new mo.NumberList();
  table[0].name = 'r';
  table[1].name = 'g';
  table[2].name = 'b';
  var rgb;
  var i;
  for(i = 0; i < nColors; i++) {
    rgb = ColorOperators.colorStringToRGB(colorList[i]);
    table[0][i] = rgb[0];
    table[1][i] = rgb[1];
    table[2][i] = rgb[2];
  }
  return table;
};

/**
 * extracts HSV values from colors and build a HSV NumberTable, H in range [0,360], others in [0,1]
 * @param {ColorList} colorList colors to be converted
 * @return {NumberTable} hsv table
 * tags:
 */
ColorListOperators.colorListToHSVTable = function(colorList) {
  if(colorList == null) return null;
  var nColors = colorList.length;
  var table = new NumberTable();
  table[0] = new mo.NumberList();
  table[1] = new mo.NumberList();
  table[2] = new mo.NumberList();
  table[0].name = 'h';
  table[1].name = 's';
  table[2].name = 'v';
  var rgb,hsv;
  var i,j;
  for(i = 0; i < nColors; i++) {
    rgb = ColorOperators.colorStringToRGB(colorList[i]);
    hsv = ColorOperators.RGBtoHSV(rgb[0],rgb[1],rgb[2]);
    for(j = 0; j < 3; j++){
      if(hsv[j] != Math.floor(hsv[j]))
        hsv[j] = Number(hsv[j].toFixed(4));
      table[j][i] = hsv[j];
    }
  }
  return table;
};
