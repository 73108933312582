import Tree from "src/dataTypes/structures/networks/Tree";
import Node from "src/dataTypes/structures/elements/Node";
import DateList from "src/dataTypes/dates/DateList";
import DateInterval from "src/dataTypes/dates/DateInterval";
import DateOperators from "src/operators/dates/DateOperators";
import Table from "src/dataTypes/lists/Table";
import NumberList from "src/dataTypes/numeric/NumberList";
import StringList from "src/dataTypes/strings/StringList";
import StringListConversions from "src/operators/strings/StringListConversions";
import NumberTable from "src/dataTypes/numeric/NumberTable";


/**
 * @classdesc Provides a set of tools that work with DateLists.
 *
 * @namespace
 * @category dates
 */
function DateListOperators() {}
export default DateListOperators;


/**
 * @todo write docs
 */
DateListOperators.buildTimeTreeFromDates = function(dates) {
  if(dates == null) return;

  var tree = new Tree();
  var minYear;
  var maxYear;

  var minDate = dates[0];
  var maxDate = dates[0];



  dates.forEach(function(date) {
    minDate = date < minDate ? date : minDate;
    maxDate = date > maxDate ? date : maxDate;
  });

  minYear = minDate.getFullYear();
  maxYear = minDate.getFullYear();

  var superior = new Node("years", "years");
  tree.addNodeToTree(superior);
  superior.dates = dates.clone();

  var y, m, d, h, mn;
  var yNode, mNode, dNode, hNode, mnNode;
  var nDaysOnMonth;

  //var N=0;

  for(y = minYear; y <= maxYear; y++) {
    yNode = new Node(String(y), String(y));
    tree.addNodeToTree(yNode, superior);
  }

  dates.forEach(function(date) {
    y = DateListOperators._y(date);
    yNode = superior.toNodeList[y - minYear];

    if(yNode.dates == null) {
      yNode.dates = new DateList();

      for(m = 0; m < 12; m++) {
        mNode = new Node(DateOperators.MONTH_NAMES[m] + "_" + y, DateOperators.MONTH_NAMES[m]);
        tree.addNodeToTree(mNode, yNode);
        nDaysOnMonth = DateOperators.getNDaysInMonth(y, m + 1);
      }
    }
    yNode.dates.push(date);


    m = DateListOperators._m(date);
    mNode = yNode.toNodeList[m];
    if(mNode.dates == null) {
      mNode.dates = new DateList();
      for(d = 0; d < nDaysOnMonth; d++) {
        dNode = new Node((d + 1) + "_" + mNode.id, String(d + 1));
        tree.addNodeToTree(dNode, mNode);
      }
    }
    mNode.dates.push(date);

    d = DateListOperators._d(date);
    dNode = mNode.toNodeList[d];
    if(dNode.dates == null) {
      dNode.dates = new DateList();
      for(h = 0; h < 24; h++) {
        hNode = new Node(h + "_" + dNode.id, String(h) + ":00");
        tree.addNodeToTree(hNode, dNode);
      }
    }
    dNode.dates.push(date);

    h = DateListOperators._h(date);
    hNode = dNode.toNodeList[h];
    if(hNode.dates == null) {
      hNode.dates = new DateList();
      for(mn = 0; mn < 60; mn++) {
        mnNode = new Node(mn + "_" + hNode.id, String(mn));
        tree.addNodeToTree(mnNode, hNode);
      }
    }
    hNode.dates.push(date);

    mn = DateListOperators._mn(date);
    mnNode = hNode.toNodeList[mn];
    if(mnNode.dates == null) {
      mnNode.dates = new DateList();
      //c.l(date);
      // N++;
      // for(s=0; s<60; s++){
      // 	sNode = new Node(String(s), s+"_"+mnNode.id);
      // 	tree.addNodeToTree(sNode, mnNode);
      // }
    }
    mnNode.weight++;
    mnNode.dates.push(date);

    // s = DateListOperators._s(date);
    // sNode = mnNode.toNodeList[s];
    // if(sNode.dates==null){
    // 	sNode.dates = new DateList();
    // }
    // sNode.dates.push(date);
    // sNode.weight++;
  });

  tree.assignDescentWeightsToNodes();

  //



  // for(y=minYear; y<=maxYear; y++){
  // 	yNode = new Node(String(y), String(y));
  // 	tree.addNodeToTree(yNode, superior);

  // 	for(m=0; m<12; m++){
  // 		mNode = new Node(DateOperators.MONTH_NAMES[m], DateOperators.MONTH_NAMES[m]+"_"+y);
  // 		tree.addNodeToTree(mNode, yNode);
  // 		nDaysOnMonth = DateOperators.getNDaysInMonth(y, m+1);

  // 		for(d=0; d<nDaysOnMonth; d++){
  // 			dNode = new Node(String(d+1), (d+1)+"_"+mNode.id);
  // 			tree.addNodeToTree(dNode, mNode);

  // 			for(h=0; h<24; h++){
  // 				hNode = new Node(String(h), h+"_"+dNode.id);
  // 				tree.addNodeToTree(hNode, dNode);

  // 				for(mn=0; mn<60; mn++){
  // 					mnNode = new Node(String(mn), mn+"_"+hNode.id);
  // 					tree.addNodeToTree(mnNode, hNode);

  // 					for(s=0; s<60; s++){
  // 						sNode = new Node(String(s), s+"_"+mnNode.id);
  // 						tree.addNodeToTree(sNode, mnNode);
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  return tree;
};

/**
 * @ignore
 */
DateListOperators._y = function(date) {
  return date.getFullYear();
};

/**
 * @ignore
 */
DateListOperators._m = function(date) {
  return date.getMonth();
};

/**
 * @ignore
 */
DateListOperators._d = function(date) {
  return date.getDate() - 1;
};

/**
 * @ignore
 */
DateListOperators._h = function(date) {
  return date.getHours();
};

/**
 * @ignore
 */
DateListOperators._mn = function(date) {
  return date.getMinutes();
};

/**
 * @ignore
 */
DateListOperators._s = function(date) {
  return date.getSeconds();
};

/**
 * @ignore
 */
DateListOperators._ms = function(date) {
  return date.getMilliseconds();
};

/**
 * get all DateLists from a Table
 * @param  {Table} Table where some Lists are DateList
 * @return {Table}
 * tags:filter
 */
DateListOperators.getDateLists = function(table){
  var newT = new mo.Table();
  for(var i=0; i<table.length; i++){
    if(table[i].type=="DateList") newT.push(table[i]);
  }
  return newT;
};

/**
 * builds various types of summary tables from dates and optional associated values
 * @param  {DateList} list of dates
 *
 * @param  {Number} outputType 0 - Weekday by Hour (default)<br>outputType 1 - Month by Day<br>outputType 2 - Day Sequence (with optional DateInterval)
 * @param  {NumberList} values associated with dates (optional)
 * @param  {DateInterval} range of dates to use for outputType=2 (optional)
 * @return {Table}
 * tags:dates
 */
DateListOperators.buildSummaryTableFromDates = function(dates,outputType,nlValues,intDates){
  if(dates == null || dates.type != 'DateList') return null;
  outputType = outputType == null ? 0 : outputType;
  var nameType = 'Weekday by Hour Summary';
  var sCol0 = 'Hour';
  if(outputType == 1){
    nameType = 'Month by Day Summary';
    sCol0 = 'Day';
  }
  else if(outputType == 2){
    nameType = 'Sequence';
    sCol0 = 'Date';
  }
  var tab = new Table();
  if(dates.name != null)
    tab.name = dates.name + ' ' + nameType;
  else
    tab.name = nameType;
  tab.push(new StringList());
  tab[0].name = sCol0;
  var lang = window && window.navigator && window.navigator.language ? window.navigator.language : 'en-US';
  var dt0 = new Date(2016,0,17,10,0,0,0); // sunday
  var i,dt,nL,j,d,h;
  if(outputType == 2){
    // do this separately, it's too different from the other two outputs
    if(intDates == null || intDates.type != 'DateInterval')
      intDates = new DateInterval(dates.getMin(),dates.getMax());
    tab.push(new NumberList());
    tab[1].name = 'Value';
    var iDays = DateOperators.daysBetweenDates(intDates.date0,intDates.date1);
    // fill values with zero to start
    for(i=0;i<=iDays;i++){
      dt = DateOperators.addDaysToDate(intDates.date0,i);
      tab[0][i] = DateOperators.dateToString(dt,1);
      tab[1][i] = 0;
    }
    // now process dates
    for(i=0;i<dates.length;i++){
      // get row number
      j = Math.floor(DateOperators.daysBetweenDates(intDates.date0,dates[i]));
      if(j < 0 || j >= tab[1].length) continue; // skip entries outside of range
      if(bValues)
        tab[1][j] += nlValues[i];
      else
        tab[1][j] ++;
    }
    return tab;
  }

  var bValues = nlValues && nlValues.length == dates.length;
  var nCols = outputType == 0 ? 7 : 12;
  var inc = outputType == 0 ? 1 : 30;
  for(i=0;i<nCols;i++){
    dt = i == 0 ? dt0 : DateOperators.addDaysToDate(dt0,i*inc);
    nL = new NumberList();
    nL.name = dt.toLocaleString(lang, outputType == 0 ? {weekday: 'long'} : {month: 'long'});
    tab.push(nL);
    if(i==0){
      if(outputType == 0)
        for(j=0;j<24;j++){
          if(j==0)
            tab[0][j] = 'Midnight';
          else if(j==12)
            tab[0][j] = 'Noon';
          else if(j > 12)
            tab[0][j] = String(j-12);
          else
            tab[0][j] = String(j);
        }
      else
        for(j=0;j<31;j++){
          tab[0][j] = String(j+1);
        }
    }
  }
  for(d=1;d<=nCols;d++){
    for(h=0;h<tab[0].length;h++)
      tab[d][h]=0;
  }
  for(i=0;i<dates.length;i++){
    if(outputType==0){
      d = dates[i].getDay();
      h = dates[i].getHours();
    }
    else{
      d = dates[i].getMonth();
      h = dates[i].getDate()-1;
    }
    if(bValues)
      tab[d+1][h]+=nlValues[i];
    else
      tab[d+1][h]++;
  }

  return tab;
};

/**
 * deprecated, use transformDateList
 * @param  {DateList} dates  list of dates to convert
 *
 * @param  {NumberList} list of outputs to create. Include 1 or more of the following (default: 0,1,2):<br>0: year<br>1: month<br>2: day of month<br>3: day of week (0 is sunday)<br>4: hour<br>5: minute<br>6: second<br>7: decimal date<br>8: day number of year<br>9: quarter<br>10: decimal hours<br>11: day indicator columns(adds 7)<br>12: month indicator columns(adds 12)<br>13: day of week name<br>14: month name<br>15: week number in year<br>16: day number
 * @param  {String} basename is a word to use at the start of column names. (Default: none)
 * @param  {Date|String} startDate is start of day numbering(Default: 2000-01-01)<br>Can be Date object or string.<br>Can also be a DateList or StringList of dates in which case the earliest date is used as the reference.<br>Can also be the string 'earliest' in which case the earliest date in the input list is used.
 * @return {Table}
 * tags: deprecated
 * replacedBy:transformDateList
 */
DateListOperators.encodeDatesAsNumericFeatures = function(dates, nLOutputs, sBaseName, dStart) {
  return DateListOperators.transformDateList(dates, nLOutputs, sBaseName, dStart);
};

/**
 * converts each date to selected number of different numeric features
 * @param  {DateList} dates  list of dates to convert
 *
 * @param  {NumberList} list of outputs to create. Include 1 or more of the following (default: 0,1,2):<br>0: year<br>1: month<br>2: day of month<br>3: day of week (0 is sunday)<br>4: hour<br>5: minute<br>6: second<br>7: decimal date<br>8: day number of year<br>9: quarter<br>10: decimal hours<br>11: day indicator columns(adds 7)<br>12: month indicator columns(adds 12)<br>13: day of week name<br>14: month name<br>15: week number in year<br>16: day number(use inlet below to set day 0)
 * @param  {String} basename is a word to use at the start of column names. (Default: none)
 * @param  {Date|String} startDate is start of day numbering(Default: 2000-01-01)<br>Can be Date object or string.<br>Can also be a DateList or StringList of dates in which case the earliest date is used as the reference.<br>Can also be the string 'earliest' in which case the earliest date in the input list is used.
 * @return {Table}
 * tags: dates,encode,transform,extract
 * examples:jeff/examples/transformDateLists
 */
DateListOperators.transformDateList = function(dates, nLOutputs, sBaseName, dStart) {
  if(dates == null) return null;
  if(dates.type == 'StringList')
    dates = StringListConversions.toDateList(dates);
  if(nLOutputs == null)
    nLOutputs = NumberList.fromArray([0,1,2]);
  if(nLOutputs.type != 'NumberList' && !(nLOutputs instanceof Array) )
    throw new Error('Invalid input. Second argument must be a NumberList.');
  sBaseName = sBaseName == null ? '' : sBaseName;
  var aNames=['year','month','day of month','day of week','hour','minute','second','decimal date','day number of year','quarter','decimal hour','day','month','day name','month name','week number in year','day number'];
  var aDays=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  var aMonths=['January','February','March','April','May','June','July','August','September','October','November','December'];
  var table;

  dStart = dStart == null ? '2000-01-01' : dStart;
  if(dStart.type == 'DateList'){
    dStart = dStart.getMin();
  }
  else if(dStart.type == 'StringList'){
    dStart = StringListConversions.toDateList(dStart);
    dStart = dStart.getMin();
  }
  else if(typeof dStart == 'string'){
    if(dStart == 'earliest')
      dStart = dates.getMin();
    else{
      dStart = DateOperators.parseDate(dStart);
      if(isNaN(dStart))
        throw new Error('Invalid format for date:' + dStart);
    }
  }
  dStart = DateOperators.clearHoursMinutesSeconds(dStart);

  if(nLOutputs.includes(13) || nLOutputs.includes(14))
    table = new Table();
  else
    table = new NumberTable();
  var k,s2,cur;
  for(var c=0; c < nLOutputs.length; c++){
    var cType = nLOutputs[c];
    cur = table.length;
    if(isNaN(cType) || cType % 1 !== 0 || cType < 0 || cType > 16)
      throw new Error('Invalid output type.');
    if(cType == 11){
      for(k=0;k<7;k++){
        table.push(new NumberList());
        s2 = sBaseName == '' ? aNames[cType] : sBaseName + ' ' + aNames[cType];
        table[cur+k].name = s2 + ':' + aDays[k];
      }
    }
    else if(cType == 12){
      for(k=0;k<12;k++){
        table.push(new NumberList());
        s2 = sBaseName == '' ? aNames[cType] : sBaseName + ' ' + aNames[cType];
        table[cur+k].name = s2 + ':' + aMonths[k];
      }
    }
    else{
      if(cType == 13 || cType == 14)
        table.push(new StringList());
      else
        table.push(new NumberList());
      table[cur].name = sBaseName == '' ? aNames[cType] : sBaseName + ' ' + aNames[cType];
    }
    for(var r=0; r < dates.length ; r++){
      var dt = dates[r];
      switch(cType){
        case 0:
          table[cur].push(dt.getFullYear());
          break;
        case 1:
          table[cur].push(dt.getMonth() + 1);
          break;
        case 2:
          table[cur].push(dt.getDate());
          break;
        case 3:
          table[cur].push(dt.getDay());
          break;
        case 4:
          table[cur].push(dt.getHours());
          break;
        case 5:
          table[cur].push(dt.getMinutes());
          break;
        case 6:
          table[cur].push(dt.getSeconds());
          break;
        case 7:
          table[cur].push(DateOperators.getDecimalDate(dt));
          break;
        case 8:
          table[cur].push(DateOperators.nDayInYear(dt));
          break;
        case 9:
          var dd = DateOperators.getDecimalDate(dt);
          dd = Math.floor((dd - Math.floor(dd)) * 4) + 1;
          table[cur].push(dd);
          break;
        case 10:
          var hrs = dt.getHours() + (dt.getMinutes()*60 + dt.getSeconds())/3600;
          hrs = Number(hrs.toFixed(5));
          table[cur].push(hrs);
          break;
        case 11:
          for(k=0;k<7;k++){
            if(dt.getDay() == k)
              table[cur+k].push(1);
            else
              table[cur+k].push(0);
          }
          break;
        case 12:
          for(k=0;k<12;k++){
            if(dt.getMonth() == k)
              table[cur+k].push(1);
            else
              table[cur+k].push(0);
          }
          break;
        case 13:
          table[cur].push(DateOperators.dateToString(dt,8));
          break;
        case 14:
          table[cur].push(DateOperators.dateToString(dt,7));
          break;
        case 15:
          table[cur].push(DateOperators.getWeekInYear(dt));
          break;
        case 16:
          table[cur].push(Math.round(DateOperators.daysBetweenDates(dStart,DateOperators.clearHoursMinutesSeconds(dt))));
          break;
      }
    }
  }
  return table;
};

/**
 * converts each date to a different time resolution
 * @param  {DateList} dates  list of dates to change the time resolution for, can also be a NumberList of decimal dates
 *
 * @param  {Number} mode time resolution to use<|>0: Adaptive, choose resolution automatically based on maxPeriods (default)<|>1: decade<|>2: year<|>3: quarter<|>4: month<|>5: week<|>6: day<|>7: hour<|>8: minute<|>9: second
 * @param  {Number} maxPeriods is used for adaptive choosing of resolution (default:100)
 * @param  {Boolean} bAdaptiveUseActualPeriodsInData use the actual number of distinct periods in this data in the limit check for adaptive mode(default:false)
 * @param  {Number} modeOutput specifies the type of output<|>0: datelist only(default)<|>1: table with datelist in first col and string label for date in second col<|>2: an object with the table stored as property .table and extra information inside property .extra
 * @return {DateList}
 * tags:dates,extract,transform
 * examples:jeff/examples/changeTimeResolution
 */
DateListOperators.changeTimeResolution = function(dates, mode, maxPeriods,bAdaptiveUseActualPeriodsInData,modeOutput) {
  if(dates == null) return;
  maxPeriods = maxPeriods == null ? 100 : maxPeriods;
  mode = mode == null ? 0 : mode;
  bAdaptiveUseActualPeriodsInData = bAdaptiveUseActualPeriodsInData == null ? false : bAdaptiveUseActualPeriodsInData;
  modeOutput = modeOutput == null ? 0 : modeOutput;
  if(mode != Math.floor(mode) || mode < 0 || mode > 9)
    throw new Error('Invalid mode value provided in changeTimeResolution');
  // convert to regular dates
  var typeInitial = dates.type;
  if(dates.type == 'StringList')
    dates = StringListConversions.toDateList(dates);
  else if(dates.type == 'NumberList'){
    // decimal dates
    dates = DateOperators.convertFromDecimalDate(dates);
  }
  if(dates.type != 'DateList')
    throw new Error('changeTimeResolution input must be a DateList, StringList containing dates, or a NumberList of decimal dates');
  var tEncoded = mo.DateListOperators.encodeDatesAsNumericFeatures(dates,[0,1,2,3,4,5,6,7]);
  var i,numPeriods,dt,dtTemp;
  // get interval of dates
  var intDates = tEncoded[7].getInterval();
  var dt0 = DateOperators.convertFromDecimalDate(intDates.x);
  var dt1 = DateOperators.convertFromDecimalDate(intDates.y);
  var nSecondsInSpan = (dt1.getTime() - dt0.getTime())/1000;
  // number of seconds in each time resolution, quarters/months/years/decades are approximate due to varying lengths
  var aTimeScales = [3652*24*3600,365*24*3600,91*24*3600,31*24*3600,7*24*3600,24*3600,3600,60,1];
  if(mode == 0){
    // choose mode 1->9
    mode = 9;
    for(mode=9; mode>=1; mode--){
      // find # periods in timespan at this resolution
      if(bAdaptiveUseActualPeriodsInData){
        var dates2 = DateListOperators.changeTimeResolution(dates,mode);
        var nLTemp = dates2.toNumberList(); // decimal dates
        numPeriods = nLTemp.getWithoutRepetitions().length;
      }
      else
        numPeriods = nSecondsInSpan/aTimeScales[mode-1];
      if(numPeriods <= maxPeriods){
        break;
      }
    }
    // we don't go beyond decade resolution
  }
  if(mode < 1) mode = 1;
  // now mode is set to 1->9, convert dates to proper resolution based on mode
  var datesRet = new DateList();
  datesRet.name = dates.name;
  var sLLabel = new StringList();
  sLLabel.name = dates.name == null ? 'label' : dates.name + ' label';
  var year,month,day,hour,minute,second,s;
  var fn0 = function(n){
      if(n<10) return '0'+n;
      return ''+n;
  }

  for(i=0; i < dates.length; i++){
    year = tEncoded[0][i];
    month = tEncoded[1][i]-1;
    day = tEncoded[2][i];
    hour = tEncoded[4][i];
    minute = tEncoded[5][i];
    second = tEncoded[6][i];
    switch(mode){
      case 1: // decade
        year = Math.floor(year/10)*10;
        s = String(year);
        dt = new Date(year,0);
        break;
      case 2: // year
        dt = new Date(year,0);
        s = String(year);
        break;
      case 3: // quarter
        var q = ' Q' + (Math.floor(month/3) + 1);
        month = Math.floor(month/3)*3;
        dt = new Date(year,month); // months are zero-based
        s = String(year) + q;
        break;
      case 4: // month
        dt = new Date(year,month);
        s = String(year) + '-' + fn0(month+1) + '-01';
        break;
      case 5: // week
        // map to the sunday just before the original date
        dtTemp = new Date(year,month,day - tEncoded[3][i]);
        year = dtTemp.getFullYear();
        month = dtTemp.getMonth();
        day = dtTemp.getDate();
        dt = new Date(year,month,day);
        s = String(year) + '-' + fn0(month+1) + '-' + fn0(day);
        break;
      case 6: // day
        dt = new Date(year,month,day);
        s = String(year) + '-' + fn0(month+1) + '-' + fn0(day);
        break;
      case 7: // hour
        dt = new Date(year,month,day,hour);
        s = String(year) + '-' + fn0(month+1) + '-' + fn0(day) + ' ' + fn0(hour) + ':00:00';
        break;
      case 8: // minute
        dt = new Date(year,month,day,hour,minute);
        s = String(year) + '-' + fn0(month+1) + '-' + fn0(day) + ' ' + fn0(hour) + ':' + fn0(minute) + ':00';
        break;
      case 9: // second
        dt = new Date(year,month,day,hour,minute,second);
        s = String(year) + '-' + fn0(month+1) + '-' + fn0(day) + ' ' + fn0(hour) + ':' + fn0(minute) + ':' + fn0(second);
        break;
    }
    datesRet.push(dt);
    sLLabel.push(s);
  }
  // we want to emit the dates in the same form as they came in
  if(typeInitial == 'NumberList')
    datesRet = datesRet.toNumberList();
  else if(typeInitial == 'StringList')
    datesRet = DateOperators.dateToString(datesRet,1);
  if(modeOutput == 0)
    return datesRet;
  var tabOutput = new Table();
  tabOutput.push(datesRet);
  tabOutput.push(sLLabel);
  if(modeOutput == 1)
    return tabOutput;

  numPeriods = nSecondsInSpan/aTimeScales[mode-1];
  numPeriods = Number.isInteger(numPeriods) ? numPeriods+1 : Math.ceil(numPeriods);

  var oResult = {
    'table': tabOutput,
    'extra': {
      'numPeriods' :numPeriods,
      'modeUsed' : mode,
      'intervalDates' : intDates
    }
  };

  oResult.name = dates.name + "("+mode+")";

  return oResult;
};

/**
 * extracts a property from each date in a list
 * @param  {DateList} dates  a list of dates to operate on
 *
 * @param  {Number} iMode of output to create (default: 0):<|>0: year<|>1: month<|>2: day of month<|>3: day of week (0 is sunday)<|>4: hour<|>5: minute<|>6: second<|>7: decimal date<|>8: day number in year<|>9: quarter<|>10: decimal hours<|>11: day indicator vars(array of 7)<|>12: month indicator vars(array of 12)<|>13: day of week name<|>14: month name<|>15: am/pm
 * @param  {String} name for new list. Default uses input list name and a label based on the property extracted
 * @return {Number}
 * tags: dates
 */
DateListOperators.extractPropertiesFromDate = function(dates, cType, sBaseName){
  if(dates == null) return null;
  cType = cType == null ? 0 : cType;
  if(isNaN(cType) || cType % 1 !== 0 || cType < 0 || cType > 15)
    throw new Error('Invalid output type.');
  // handle case of a single item
  if(!dates.isList && !Array.isArray(dates))
    return DateOperators.extractPropertiesFromDate(dates,cType);
  if(dates.type == 'StringList')
    dates = StringListConversions.toDateList(dates);

  sBaseName = sBaseName == null ? dates.name : sBaseName;
  var aNames=['year','month','day of month','day of week','hour','minute','second','decimal date','day number in year','quarter','decimal hour','day','month','day name','month name','am/pm'];
  var LOut = new mo.List();
  LOut.name = sBaseName == '' ? aNames[cType] : sBaseName + ' ' + aNames[cType];

  for(var i=0;i<dates.length;i++)
    LOut.push(DateOperators.extractPropertiesFromDate(dates[i],cType));
  return LOut.getImproved();
};
