/**
 * based on https://github.com/tomarad/JSON-Schema-Instantiator
 */
// The JSON Object that defines the default values of certain types.
const typesInstantiator = {
    'string': '',
    'number': 0,
    'integer': 0,
    'null': null,
    'boolean': false, 
    'object': { }
};

/**
 * @classdesc  SchemaInstantiator
 *
 * @namespace
 * @category basics
 */
function SchemaInstantiator() {}
export default SchemaInstantiator; 

/**
 * The main function.
 * Calls sub-objects recursively, depth first, using the sub-function 'visit'.
 * @param schema - The schema to instantiate.
 * 
 * @param options - Options to apply.
 * @returns {*}
 */
SchemaInstantiator.instantiate =  function(schema, options) {
    options = options || {};
  
    /**
     * Visits each sub-object using recursion.
     * If it reaches a primitive, instantiate it.
     * @param obj - The object that represents the schema.
     * @param name - The name of the current object.
     * @param data - The instance data that represents the current object.
     */
    function visit(obj, name, data) {
        if (!obj) {
            return;
        }
  
        var i;
        var type = mo.SchemaInstantiator._getObjectType(obj);
  
        // We want non-primitives objects (primitive === object w/o properties).
        if (type === 'object' && obj.properties) {
            data[name] = data[name] || { };
  
            // Visit each property.
            for (var property in obj.properties) {
                if (obj.properties.hasOwnProperty(property)) {
                    if (mo.SchemaInstantiator._shouldVisit(property, obj, options)) {
                        visit(obj.properties[property], property, data[name]);
                    }
                }
            }

        } else if (obj.allOf) {
            for (i = 0; i < obj.allOf.length; i++) {
                visit(obj.allOf[i], name, data);
            }
        } else if (obj.$ref) {
            obj = mo.SchemaInstantiator._findDefinition(schema, obj.$ref);
            visit(obj, name, data);
        } else if (type === 'array') {
            data[name] = [];
            var len = 0;
            if (obj.minItems || obj.minItems > 0) {
                len = obj.minItems;
            }
  
            // Instantiate 'len' items.
            for (i = 0; i < len; i++) {
                visit(obj.items, i, data[name]);
            }
        } else if (mo.SchemaInstantiator._isEnum(obj)) {
            data[name] = mo.SchemaInstantiator._instantiateEnum(obj);
        } else if (mo.SchemaInstantiator._isPrimitive(obj)) {
            data[name] = mo.SchemaInstantiator._instantiatePrimitive(obj);
        }
    };
  
    var data = {};
    visit(schema, 'kek', data);
    return data['kek'];
}
   
/**
 * Extracts the type of the object.
 * If the type is an array, set type to first in list of types.
 * If obj.type is not overridden, it will fail the isPrimitive check.
 * Which internally also checks obj.type.
 * @param obj - An object.
*/
SchemaInstantiator._getObjectType = function(obj) {
    // Check if type is array of types.
    if (Array.isArray(obj)) {
      obj.type = obj.type[0];
    }
  
    return obj.type;
}

/**
 * Checks if the property should be visited
 * @param {*} property 
 * @param {*} obj 
 * @param {*} options 
 */
SchemaInstantiator._shouldVisit = function(property, obj, options) {
    return (!options.requiredPropertiesOnly) || 
        (options.requiredPropertiesOnly && mo.SchemaInstantiator._isPropertyRequired(property, obj.required) );
}

/**
 * Checks whether a property is on required array.
 * @param property - the property to check.
 * @param requiredArray - the required array
 * @returns {boolean}
 */
SchemaInstantiator._isPropertyRequired = function(property, requiredArray) {
    var found = false;
    requiredArray = requiredArray || [];
    requiredArray.forEach(function(requiredProperty) {
        if (requiredProperty === property) {
            found = true;
        }
    });
    return found;
}

/**
* Finds a definition in a schema.
* Useful for finding references.
*
* @param schema    The full schema object.
* @param ref       The reference to find.
* @return {*}      The object representing the ref.
*/
SchemaInstantiator._findDefinition = function(schema, ref) {
    var propertyPath = ref.split('/').slice(1); // Ignore the #/uri at the beginning.
    var currentProperty = propertyPath.splice(0, 1)[0];

    var currentValue = schema;

    while (currentProperty) {
        currentValue = currentValue[currentProperty];
        currentProperty = propertyPath.splice(0, 1)[0];
    }

    return currentValue;
}

/**
 * Checks whether a variable is an enum.
 * @param obj - an object.
 * @returns {boolean}
 */
SchemaInstantiator._isEnum = function(obj) {
    return Object.prototype.toString.call(obj.enum) === '[object Array]';
}

/**
 * Instantiate an enum.
 * @param val - The object that represents the primitive.
 * @returns {*}
 */
SchemaInstantiator._instantiateEnum = function(val) {
    // Support for default values in the JSON Schema.
    if (val.default) {
        return val.default;
    }
    if (!val.enum.length) {
        return undefined;
    }
    return val.enum[0];
}

/**
 * Checks whether a variable is a primitive.
 * @param obj - an object.
 * @returns {boolean}
 */
SchemaInstantiator._isPrimitive = function(obj) {
    var type = obj.type;
  
    return typesInstantiator[type] !== undefined;
}

/**
 * Instantiate a primitive.
 * @param val - The object that represents the primitive.
 * @returns {*}
 */
SchemaInstantiator._instantiatePrimitive = function(val) {
    var type = val.type;
  
    // Support for default values in the JSON Schema.
    if (val.hasOwnProperty('default')) {
      return val.default;
    }
  
    return typesInstantiator[type];
}