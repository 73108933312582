export default {
	"$schema": "http://json-schema.org/draft-04/schema#",
	"title": "Meta Extractor Schema for a Table",
	"type": "object",
	"properties": {
		"origin": {
			"type": "object",
			"properties": {
				"type": {
					"type": "string"
				},
				"source": {
					"type": "object",
					"properties": {}
				},
				"hasDataBaseOrigin": {
					"type": "boolean"
				}
			}
		},
		"data": {
			"type": "object",
			"properties": {
				"countSampleRows": {
					"type": "number"
				},
				"countRows": {
					"type": "number"
				},
				"frequency": {
					"type": "number"
				},
				"nAttributes": {
					"type": "number"
				},
				"countRowsSimpleText": {
					"type": "string"
				},
				"score": {
					"type": "number"
				}
			}
		},
		"information": {
			"type": "object",
			"properties": {
				"content": {
					"type": "object",
					"properties": {}
				},
				"score": {
					"type": "number"
				}
			}
		},
		"meaning": {
			"type": "object",
			"properties": {
				"ontologyConcepts": {
					"type": "object",
					"properties": {}
				},
				"label": {
					"type": "string"
				},
				"score": {
					"type": "number"
				}
			}
		},
		"relations": {
			"type": "array",
			"items": {
				"type": "object",
				"properties": {
					"name": {
						"type": "string"
					},
					"relations": {
						"type": "array",
						"items": {
							"type": "array"
						}
					}
				},
				"required": [
					"name",
					"relations"
				]
			}
		},
		"attributes": {
			"type": "array",
			"items": {}
		},
		"containsId": {
			"type": "boolean"
		},
		"containsDateList": {
			"type": "boolean"
		},
		"containsDateListHighProportionDifferent": {
			"type": "boolean"
		},
		"specification": {
			"type": "object",
			"properties": {
				"name": {
					"type": "string"
				}
			}
		},
		"originId": {
			"type": "string"
		},
		"name": {
			"type": "string"
		},
		"user": {
			"type": "string"
		},
		"organization": {
			"type": "string"
		},
		"id": {
			"type": "string"
		},
		"hash": {
			"type": "string"
		},
		"score": {
			"type": "number"
		}
	}
} 