import NumberList from "src/dataTypes/numeric/NumberList";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import { typeOf } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc Provides a set of tools that work with Numbers.
 *
 * @namespace
 * @category numbers
 */
function NumberOperators() {}
export default NumberOperators;


/**
 * applies a math operation on a number, numberList, numberTable, or combinations (if you provide your own Function in the 5th parameter, you can use other types different than numeric)
 * @param {Number|Object} operation <|>0:x+y<|>1:x*y<|>2:x^2<|>3:x^3<|>4:x^y<|>5:√x<|>6:log(x)<|>7:log(x+y)<|>8:log2(x)<|>9:log2(x+y)<|>10:log_y(x)<|>11:cos(x)<|>12:cos(x+y)<|>13:sin(x)<|>14:sin(x+y)<|>15:tan(x)<|>16:tan(x+y)<|>17:atan2(x, y)<|>18:floor(x)<|>19:round(x)<|>20:ceil(x)<|>21:abs(x)<|>22:x%y<|>23:x-y<|>24:x/y
 * @param {Number|Object} x first parameter
 *
 * @param {Number} y second parameter, you can combine types such as operating a number and a numberList or two numberTables
 * @param {Number} factor multiplies result
 * @param {String|Function} mapFunction optional Function (that takes one or two parameters) or a string (describing a javascript equation, in terms of x and y, such as "x*x-y+20*Math.cos(y)") that defines te map function, to be used instead of provided options in first inlet; if the function operates on different types such as string, the parameters could be of the required type
 * @param {String} name to be assigned to Object (specially useful when the result is a new list), if not provided the resulting list will have the name of x if it's a list
 * @param {Number} numIterations optionally apply multiple iterations, taking the result and using it as x in a next iteration, and so on. (use negative to return last value of iterartion instead of the sequence)
 * @return {Object} number, numberList or numberTable result of the operation
 * tags:math
 * examples:santiago/examples/modules/mathOperation
 * lessons:initial_tricks
 */
NumberOperators.mathOperation = function(operation, x, y, factor, mapFunction, name, numIterations){

  if(numIterations>1){
    var accumulated = x["isList"]?new mo.Table():new mo.List();
    accumulated.push(x);
    for(var i=0; i<numIterations; i++){
      x = NumberOperators.mathOperation(operation, x, y, factor, mapFunction, name);
      accumulated.push(x);
    }
    return accumulated.getImproved();
  } else if(numIterations<=-1){
    return NumberOperators.mathOperation(operation, NumberOperators.mathOperation(operation, x, y, factor, mapFunction, name, numIterations+1), y, factor, mapFunction, name);
  }

  var f = mapFunction!=null?mapFunction:[NumberOperators._sum, NumberOperators._multiplication, NumberOperators._pow2, NumberOperators._pow3, Math.pow, Math.sqrt, Math.log, NumberOperators._logSum, Math.log2, NumberOperators._log2Sum, NumberOperators._log_y, Math.cos, NumberOperators._cosSum, Math.sin, NumberOperators._sinSum, Math.tan, NumberOperators._tanSum, Math.atan2, Math.floor, Math.round, Math.ceil, Math.abs, NumberOperators._modulo, NumberOperators._subtract,NumberOperators._divide][operation]; 
  factor = factor==null?1:factor;

  var type_x = x==null?"object":(x["isTable"]?"table":(x["isList"]?"list":"object"));
  var type_y = y==null?"object":(y["isTable"]?"table":(y["isList"]?"list":"object"));

  var combinedType = type_x+"_"+type_y;
  var i, nL, nT, l;

  if(typeOf(f)=="string"){
    eval("f = function(x,y){ return "+f+"}");
  }

  switch(combinedType){
    case "object_list":
      nL = new mo.List();
      nL.name = name==null?y.name:name;
      for(i=0;i<y.length;i++){
        nL[i] = factor==1?f(x, y[i]):factor*f(x, y[i]);
      }
      return nL.getImproved();
    case "list_object":
      nL = new mo.List();
      nL.name = name==null?x.name:name;
      for(i=0;i<x.length;i++){
        nL[i] = factor==1?f(x[i], y):factor*f(x[i], y);
      }
      return nL.getImproved();
    case "list_list":
      nL = new mo.List();
      nL.name = name==null?y.name:name;
      l = Math.min(x.length, y.length);
      for(i=0;i<l;i++){
        nL[i] = factor==1?f(x[i], y[i]):factor*f(x[i], y[i]);
      }
      return nL.getImproved();
    case "object_table":
      nT = new mo.Table();
      nT.name = name;
      for(i=0;i<y.length;i++){
        nT[i] = NumberOperators.mathOperation(operation, x, y[i], factor, mapFunction);
      }
      return nT.getImproved();
    case "table_object":
      nT = new mo.Table();
      nT.name = name;
      for(i=0;i<x.length;i++){
        nT[i] = NumberOperators.mathOperation(operation, x[i], y, factor, mapFunction);
      }
      return nT.getImproved();
    case "list_table":
    case "table_list":
    case "table_table":
      nT = new mo.Table();
      nT.name = name;
      l = Math.min(x.length, y.length);
      for(i=0;i<x.length;i++){
        nT[i] = NumberOperators.mathOperation(operation, x[i], y[i], factor, mapFunction);
      }
      return nT.getImproved();
    default:
      return factor==1?f(x, y):factor*f(x, y);
  }
  return;
};
NumberOperators._sum = function(x,y){
  return x+y;
};
NumberOperators._multiplication = function(x,y){
  return x*y;
};
NumberOperators._pow2 = function(x){
  return Math.pow(x, 2);
};
NumberOperators._pow3 = function(x){
  return Math.pow(x, 2);
};
NumberOperators._logSum = function(x,y){
  return Math.log(x+y);
};
NumberOperators._log2Sum = function(x,y){
  return Math.log2(x+y);
};
NumberOperators._log_y = function(x,y){
  return Math.log(x)/Math.log(y);
};
NumberOperators._cosSum = function(x,y){
  return Math.cos(x+y);
};
NumberOperators._sinSum = function(x,y){
  return Math.sin(x+y);
};
NumberOperators._tanSum = function(x,y){
  return Math.tan(x+y);
};
NumberOperators._modulo = function(x,y){
  return x%y;
};
NumberOperators._subtract = function(x,y){
  return x-y;
};
NumberOperators._divide = function(x,y){
  return x/y;
};


/**
 * converts number into a string
 *
 * @param {Number} value The number to convert
 * @param {Number} nDecimals Number of decimals to include. Defaults to 0.
 */
NumberOperators.numberToString = function(value, nDecimals ) {
  var string = value.toFixed(nDecimals);
  // for 0 decimals we don't trim trailing zeros
  if(nDecimals == 0)
    return string;
  while(string.charAt(string.length - 1) == '0') {
    string = string.substring(0, string.length - 1);
  }
  if(string.charAt(string.length - 1) == '.') string = string.substring(0, string.length - 1);
  return string;
};

/**
 * decent algebraic method to create pseudo random numbers
 * @param {Number} seed
 * tags:random
 */
NumberOperators.getRandomWithSeed = function(seed) {
  seed = seed==null?1:seed;

  seed = (seed * 9301 + 49297) % 233280;
  return seed / (233280.0);
};

/**
 * @todo write docs
 */
NumberOperators.numberFromBinaryPositions = function(binaryPositions) {
  var i;
  var n = 0;
  for(i = 0; binaryPositions[i] != null; i++) {
    n += Math.pow(2, binaryPositions[i]);
  }
  return n;
};

/**
 * @todo write docs
 */
NumberOperators.numberFromBinaryValues = function(binaryValues) {
  var n = 0;
  var l = binaryValues.length;
  for(var i = 0; i < l; i++) {
    n += binaryValues[i] == 1 ? Math.pow(2, (l - (i + 1))) : 0;
  }
  return n;
};

/**
 * @todo write docs
 */
NumberOperators.powersOfTwoDecomposition = function(number, length) {

  var powers = new NumberList();

  var constructingNumber = 0;
  var biggestPower;

  while(constructingNumber < number) {
    biggestPower = Math.floor(Math.log(number) / Math.LN2);
    powers[biggestPower] = 1;
    number -= Math.pow(2, biggestPower);
  }

  length = Math.max(powers.length, length == null ? 0 : length);

  for(var i=0; i<powers.length;i++){
    powers[i] = powers[i]?1:0;
  }
  while(powers.length<length){
    powers.unshift(0);
  }

  return powers;
};

/**
 * converts a number into binary (as a #L with 0s and 1s)
 * @param {Number} number to be converted
 * 
 * @param {Number} length optional length for returned #L (filled with 0s in most significant positions if binary number is shorter)
 * @param {boolean} bLeastSignificantFirst if true then the least significant digits come first (default:true)
 * @return {NumberList} numberList with 0s and 1s, the number in binary decomposition
 * tags:math,conversion
 * examples:jeff/examples/toBinary
 */
NumberOperators.toBinary = function(number, length, bLeastSignificantFirst){
  bLeastSignificantFirst = bLeastSignificantFirst == null ? true : bLeastSignificantFirst;
  var nL = NumberOperators.powersOfTwoDecomposition(number, length);
  if(!bLeastSignificantFirst)
    nL = nL.getReversed();
  return nL;
};


/**
 * converts a list of booleans, or a NumberList of 0s and 1s to its, to its binary number
 * @param {List} boolean List or NumberList of 0s and 1s (any other NumberList will be converted into 0s and 1s, with any value different from 0 converted into 1)
 *
 * @param {boolean} bLeastSignificantFirst if true then the least significant digits come first (default:true)
 * @return {Number} number
 * tags:math,conversion
 * examples:jeff/examples/toBinary
 */
NumberOperators.binaryToNumber = function(booleanListOrNumberList, bLeastSignificantFirst){
  if(booleanListOrNumberList==null) return;
  bLeastSignificantFirst = bLeastSignificantFirst == null ? true : bLeastSignificantFirst;
  var nL = bLeastSignificantFirst ? booleanListOrNumberList.getReversed() : booleanListOrNumberList;
  var n = 0;
  var l = nL.length;

  for(var i=0; i<l; i++){
    n += Number(nL[i])==0 ? 0 : Math.pow(2, (l - (i + 1)));
  }

  return n;
};


/**
 * @todo write docs
 */
NumberOperators.positionsFromBinaryValues = function(binaryValues) {
  var i;
  var positions = new NumberList();
  for(i = 0; binaryValues[i] != null; i++) {
    if(binaryValues[i] == 1) positions.push(i);
  }
  return positions;
};

//////////Random Generator with Seed, From http://baagoe.org/en/w/index.php/Better_random_numbers_for_javascript

/**
 * @ignore
 */
NumberOperators._Alea = function() {
  return(function(args) {
    // Johannes Baagøe <baagoe@baagoe.com>, 2010
    var s0 = 0;
    var s1 = 0;
    var s2 = 0;
    var c = 1;

    if(args.length === 0) {
      args = [+new Date()];
    }
    var mash = NumberOperators._Mash();
    s0 = mash(' ');
    s1 = mash(' ');
    s2 = mash(' ');

    for(var i = 0; i < args.length; i++) {
      s0 -= mash(args[i]);
      if(s0 < 0) {
        s0 += 1;
      }
      s1 -= mash(args[i]);
      if(s1 < 0) {
        s1 += 1;
      }
      s2 -= mash(args[i]);
      if(s2 < 0) {
        s2 += 1;
      }
    }
    mash = null;

    var random = function() {
      var t = 2091639 * s0 + c * 2.3283064365386963e-10; // 2^-32
      s0 = s1;
      s1 = s2;
      // https://github.com/nquinlan/better-random-numbers-for-javascript-mirror/blob/master/support/js/Alea.js#L38
      return s2 = t - (c = t | 0);
    };
    random.uint32 = function() {
      return random() * 0x100000000; // 2^32
    };
    random.fract53 = function() {
      return random() +
        (random() * 0x200000 | 0) * 1.1102230246251565e-16; // 2^-53
    };
    random.version = 'Alea 0.9';
    random.args = args;
    return random;

  }(Array.prototype.slice.call(arguments)));
};

/**
 * @ignore
 */
NumberOperators._Mash = function() {
  var n = 0xefc8249d;

  var mash = function(data) {
    data = data.toString();
    for(var i = 0; i < data.length; i++) {
      n += data.charCodeAt(i);
      var h = 0.02519603282416938 * n;
      n = h >>> 0;
      h -= n;
      h *= n;
      n = h >>> 0;
      h -= n;
      n += h * 0x100000000; // 2^32
    }
    return(n >>> 0) * 2.3283064365386963e-10; // 2^-32
  };

  mash.version = 'Mash 0.9';
  return mash;
};

// create default random function (uses date as seed so non-repeat)
NumberOperators.random = new NumberOperators._Alea();
NumberOperators.stackRandom = [];

NumberOperators.randomSeed = function(seed){
  NumberOperators.stackRandom.push(NumberOperators.random);
  if(NumberOperators.stackRandom.length > 100){
    // only keep 100 random generators on stack, in case they are lazy and never pop
    NumberOperators.stackRandom.shift(); // drop the oldest
  }
  NumberOperators.random = new NumberOperators._Alea("my", seed, "seeds");
  NumberOperators.lastNormal = NaN;
};

NumberOperators.randomSeedPop = function(){
  if(NumberOperators.stackRandom.length > 0){
    NumberOperators.random = NumberOperators.stackRandom.pop();
  }
  // if none in stack then just leave current one active
}

// many of these below are from based on 
// https://github.com/mvarshney/simjs-source/blob/master/src/random.js

NumberOperators.powerLaw = function(x0,x1,n){
  var y = NumberOperators.random();
  var x = Math.pow( (Math.pow(x1,n+1)-Math.pow(x0,n+1))*y + Math.pow(x0,n+1), 1/(n+1) );
  return x;
};

NumberOperators.exponential = function(lambda,bClamp){
  var v = -Math.log(NumberOperators.random()) / lambda;
  while(v > 1 && bClamp){
    v = -Math.log(NumberOperators.random()) / lambda;
  } 
  return v; 
};

NumberOperators.pareto = function(alpha){
  var u = NumberOperators.random();
  return 1.0 / Math.pow((1 - u), 1.0 / alpha);
};

NumberOperators.normal = function(mean,standardDeviation) {
  var z = NumberOperators.lastNormal;
  NumberOperators.lastNormal = NaN;
  if (!z) {
    var a = NumberOperators.random() * 2 * Math.PI;
    var b = Math.sqrt(-2.0 * Math.log(1.0 - NumberOperators.random()));
    z = Math.cos(a) * b;
    NumberOperators.lastNormal = Math.sin(a) * b;
  } 
  return mean + z * standardDeviation;
};

NumberOperators.weibull = function(alpha, beta, bClamp) {
  var u = 1.0 - NumberOperators.random();
  var v = alpha * Math.pow(-Math.log(u), 1.0 / beta);
  while(v > 1 && bClamp){
    u = 1.0 - NumberOperators.random();
    v = alpha * Math.pow(-Math.log(u), 1.0 / beta);
  } 
  return v; 
};

// from https://www.riskamp.com/beta-pert
NumberOperators.betaPERT = function(min,max,mode,lambda){
  var range = max-min;
  if(range==0) return min;
  if(range < 0) throw new Error('Invalid values for min and max in NumberOperators.betaPERT');
  if(mode < min || mode > max) throw new Error('Invalid value for mode in NumberOperators.betaPERT');
  var mu = (min+max+lambda*mode) / (lambda+2);
  var v;
  if(Math.abs(mu - mode) < .0001)
    v = (lambda/2) + 1;
  else
    v = ( (mu-min)*(2*mode-min-max)) / ((mode-mu)*(max-min));
  var w = (v * (max-mu)) / (mu-min);
  return NumberOperators.rbeta(v,w)*range + min;
};

// from http://stackoverflow.com/questions/9590225/is-there-a-library-to-generate-random-numbers-according-to-a-beta-distribution-f
NumberOperators.rbeta = function(alpha, beta){
  var alpha_gamma = NumberOperators.rgamma(alpha, 1);
  return alpha_gamma / (alpha_gamma + NumberOperators.rgamma(beta, 1));
};

NumberOperators.SG_MAGICCONST = 1 + Math.log(4.5);
NumberOperators.LOG4 = Math.log(4.0);

NumberOperators.rgamma = function(alpha, beta){
  var v,x;
  // does not check that alpha > 0 && beta > 0
  if (alpha > 1) {
    // Uses R.C.H. Cheng, "The generation of Gamma variables with non-integral
    // shape parameters", Applied Statistics, (1977), 26, No. 1, p71-74
    var ainv = Math.sqrt(2.0 * alpha - 1.0);
    var bbb = alpha - NumberOperators.LOG4;
    var ccc = alpha + ainv;

    while (true) {
      var u1 = NumberOperators.random();
      if (!((1e-7 < u1) && (u1 < 0.9999999))) {
        continue;
      }
      var u2 = 1.0 - NumberOperators.random();
      v = Math.log(u1/(1.0-u1))/ainv;
      x = alpha*Math.exp(v);
      var z = u1*u1*u2;
      var r = bbb+ccc*v-x;
      if (r + NumberOperators.SG_MAGICCONST - 4.5*z >= 0.0 || r >= Math.log(z)) {
        return x * beta;
      }
    }
  }
  else if (alpha == 1.0) {
    var u = NumberOperators.random();
    while (u <= 1e-7) {
      u = NumberOperators.random();
    }
    return -Math.log(u) * beta;
  }
  else { // 0 < alpha < 1
    // Uses ALGORITHM GS of Statistical Computing - Kennedy & Gentle
    while (true) {
      var u3 = NumberOperators.random();
      var b = (Math.E + alpha)/Math.E;
      var p = b*u3;
      if (p <= 1.0) {
        x = Math.pow(p, (1.0/alpha));
      }
      else {
        x = -Math.log((b-p)/alpha);
      }
      var u4 = NumberOperators.random();
      if (p > 1.0) {
        if (u4 <= Math.pow(x, (alpha - 1.0))) {
          break;
        }
      }
      else if (u4 <= Math.exp(-x)) {
        break;
      }
    }
    return x * beta;
  }
};

/**
 * returns number of decimal places in number including scientific notation
 * from https://stackoverflow.com/questions/10454518/javascript-how-to-retrieve-the-number-of-decimals-of-a-string-number
 *
 * @param {Number} value The number to examine
 * @return {Number} decimals
 */
NumberOperators.decimalPlaces = function(value) {
  var match = String(value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) { return 0; }
  return Math.max(
       0,
       // Number of digits right of decimal point.
       (match[1] ? match[1].length : 0)
       // Adjust for scientific notation.
       - (match[2] ? +match[2] : 0));
};

/**
 * returns number of significant digits in number including scientific notation
 * from https://stackoverflow.com/questions/22884720/what-is-the-fastest-way-to-count-the-number-of-significant-digits-of-a-number
 *
 * @param {Number} value The number to examine
 * @return {Number} digits
 */
NumberOperators.significantDigits = function(value) {
  // note that this does not count as significant trailing zeroes after decimal. Ex 3.00 gives 1 sig digit
  if(value==null || isNaN(value)) return 0;
  return Number(value)
      .toExponential()
      .replace(/e[\+\-0-9]*$/, '')  // remove exponential notation
      .replace( /^0\.?0*|\./, '')    // remove decimal point and leading zeros
      .length;
};

/**
 * returns logistic function value, accepts any real number and returns in range [0,1] by default
 * @param {Number} value The input, any real number
 *
 * @param {Number} maxValue maximum resulting value (default:1)
 * @param {Number} midpoint x-value of the curves midpoint (default:0)
 * @param {Number} k is the steepness of the curve (default:1)
 * @return {Number} result
 */
NumberOperators.logistic = function(value,maxValue,midpoint,k) {
  if(value==null || isNaN(value)) return null;
  maxValue = maxValue == null ? 1 : maxValue;
  midpoint = midpoint == null ? 0 : midpoint;
  k = k == null ? 1 : k;
  return maxValue / (1 + Math.exp(-k*(value-midpoint)));
};

/**
 * returns a short string version of the number
 *
 * @param {Number} value, any real number
 *
 * @param {Number} prec is the precision to use (default:2)
 * @return {String} result
 * tags:transform,clean
*/
NumberOperators.formatShort = function(value,prec) {
  if(value == null) return;
  if(value == 0 || value == Infinity) return String(value);
  if(isNaN(value)) return value;
  prec = (prec == null || prec < 1) ? 2 : prec;
  if(String(value).length <= prec) return String(value);
  var nice = parseFloat((value).toPrecision(prec));
  var order = Math.floor(Math.log10(nice));

  var suffix = '';
  if(order >= 15){
    nice = parseFloat((nice / Math.pow(10, order)).toPrecision(prec));
    suffix = 'e' + order + '';
  }
  else if (order >= 12){
    nice /= Math.pow(10, 12);
    suffix = 't';
  }
  else if(order >= 9){
    nice /= Math.pow(10, 9);
    suffix = 'b';
  }
  else if(order >= 6){
    nice /= Math.pow(10, 6);
    suffix = 'm';
  }
  else if(order >= 3){
    nice /= Math.pow(10, 3);
    suffix = 'k';
  }
  else if(order < -3){
    nice = parseFloat((nice / Math.pow(10, order)).toPrecision(prec));
    suffix = 'e' + order + '';
  }
  // clean up floating point errors that sometimes occur
  nice = parseFloat(nice.toPrecision(12))
  return nice + suffix;
};

/**
 * returns a "nice" number approximately equal to range. Nice numbers are 1,2,5 and all power of ten multiples of these
 *
 * @param {Number} range is the data range
 *
 * @param {Number} bRound is whether to round the result (default: false)
 * @return {Number} result
 * tags:transform
*/
NumberOperators.niceNum=function(range,bRound){
  bRound = bRound == null ? false : bRound;
  var exponent,fraction,niceFraction;
  exponent = Math.floor(Math.log(range) / Math.LN10);
  fraction = range / Math.pow(10, exponent);

  if(bRound) {
    if(fraction < 1.5)
      niceFraction = 1;
    else if(fraction < 3)
      niceFraction = 2;
    else if(fraction < 7)
      niceFraction = 5;
    else
      niceFraction = 10;
  }
  else {
    if(fraction <= 1)
      niceFraction = 1;
    else if(fraction <= 2)
      niceFraction = 2;
    else if(fraction <= 5)
      niceFraction = 5;
    else
      niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

/**
 * returns a position literal (0->0th, 1->1st, 2->2nd,  3->3rd…) for an ordinal
 * @param {Number} n is the number for which to get the string suffix version
 * @return {String} result
 * tags:transform
*/
NumberOperators.numberToPosition = function(n){
  var j = n % 10;
  var k = n % 100;
  if(j == 1 && k != 11)
    return n + 'st';
  if(j == 2 && k != 12)
    return n + 'nd';
  if(j == 3 && k != 13)
    return n + 'rd';
  return n + 'th';
};

/**
 * returns true if object satisfies isNaN, false otherwise, note that string versions of numbers are valid numbers
 * @param  {Object} object
 * @return {Boolean}
 * tags:special
 */
NumberOperators.isNaN = function(object) {
  if(object === undefined) return null;
  return isNaN(object);
};
