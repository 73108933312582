import List from "src/dataTypes/lists/List";
import Table from "src/dataTypes/lists/Table";
import NumberList from "src/dataTypes/numeric/NumberList";
import ColorList from "src/dataTypes/graphic/ColorList";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import ListOperators from "src/operators/lists/ListOperators";
import ColorListGenerators from "src/operators/graphic/ColorListGenerators";
import StringList from "src/dataTypes/strings/StringList";
import StringListOperators from "src/operators/strings/StringListOperators";
import NumberOperators from "src/operators/numeric/NumberOperators";
import TableOperators from "src/operators/lists/TableOperators";
import TableConversions from "src/operators/lists/TableConversions";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import { instantiateWithSameType } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc NumberTable Operators
 *
 * a NumberTable as a matrix: has n lists, each with m values, being a mxn matrix
 * the following NumberTable:
 * [ [0, 4, 7], [3, 8, 1] ]
 * is notated:
 * | 0   4   7 |
 * | 3   8   1 |
 *
 * @namespace
 * @category numbers
 */
function NumberTableOperators() {}
export default NumberTableOperators;

/**
 * normalizes the table to its maximal value
 * @param {NumberTable} numbertable NumberTable
 *
 * @param  {Number} factor optional factor
 * @return {NumberTable}
 * tags:normalization
 */
NumberTableOperators.normalizeTableToMax = function(numbertable, factor) {
  if(numbertable==null) return;
  factor = factor == null ? 1 : factor;

  var newTable = new NumberTable();
  var i;
  var antimax = factor / numbertable.getMax();
  for(i = 0; numbertable[i] != null; i++) {
    newTable[i] = numbertable[i].factor(antimax);
  }
  newTable.name = numbertable.name;
  return newTable;
};

/**
 * returns a table with having normalized all the numberLists
 * @param {NumberTable} numbertable NumberTable (also accepts Tables, and it leaves unchanged any non numeric List)
 *
 * @param  {Number} factor optional factor
 * @param  {String} mode is type of normalization:<|>min-max: minimum value gets mapped to 0, maximum to 1 but multiplied by factor (default)<|>z-score: mean of zero, stdev of 1
 * @return {Table}
 * tags:normalization
 */
NumberTableOperators.normalizeLists = function(numbertable, factor, mode) {
  if(numbertable==null || !numbertable.length || !numbertable[0].length) return;
  
  mode = mode == null ? 'min-max' : mode;

  var newTable = instantiateWithSameType(numbertable);
  var i;
  for(i = 0; numbertable[i] != null; i++) {
    if(numbertable[i].type != 'NumberList'){
      newTable[i] = numbertable[i].clone();
      continue;
    }
    if(mode == 'min-max')
      newTable[i] = NumberListOperators.normalizeToMax(numbertable[i], factor);
    else if(mode == 'z-score')
      newTable[i] = NumberListOperators.normalizeByZScore(numbertable[i]);
  }
  newTable.name = numbertable.name;
  return newTable;
};

/**
 * normalize each numberList to its maximum value
 * @param {NumberTable} numbertable to be normalized
 *
 * @param {Number} factorValue optional factor
 * @return {NumberTable}
 * tags:normalization
 */
NumberTableOperators.normalizeListsToMax = function(numbertable, factorValue) {
  if(numbertable==null || !numbertable[0].length) return;

  var newTable = new NumberTable();
  var numberlist;
  var l = numbertable.length;
  var i;
  for(i = 0; i<l; i++) {
    numberlist = numbertable[i];
    newTable[i] = NumberListOperators.normalizeToMax(numberlist, factorValue);
  }
  newTable.name = numbertable.name;
  return newTable;
};

/**
 * normalize each numberList to make them sum 1 (or optional factor value)
 * @param {NumberTable} numbertable to be normalized
 *
 * @param {Number} factorValue optional factor (each numberList values will add up factorValue)
 * @return {NumberTable}
 * tags:normalization
 */
NumberTableOperators.normalizeListsToSum = function(numbertable, factorValue) {
  if(numbertable==null || !numbertable[0].length) return;

  var newTable = new NumberTable();
  var numberlist;
  var l = numbertable.length;
  var i;
  for(i = 0; i<l; i++) {
    numberlist = numbertable[i];
    newTable[i] = NumberListOperators.normalizeToSum(numberlist, factorValue);
  }
  newTable.name = numbertable.name;
  return newTable;
};

/**
 * smooth numberLists by calculating averages with neighbors
 * @param  {NumberTable} numberTable
 *
 * @param  {Number} intensity weight for neighbors in average (0&lt;=intensity&lt;=0.5)
 * @param  {Number} nIterations number of ieterations
 * @return {List} numberList of indexes, or list of numberTables
 * tags:statistics
 */
NumberTableOperators.averageSmootherOnLists = function(numberTable, intensity, nIterations) {
  if(numberTable == null || !numberTable[0].length) return;

  intensity = intensity || 0.5;
  nIterations = nIterations || 1;

  var newNumberTable = new NumberTable();
  newNumberTable.name = numberTable.name;
  numberTable.forEach(function(nL, i) {
    newNumberTable[i] = NumberListOperators.averageSmoother(numberTable[i], intensity, nIterations);
  });
  return newNumberTable;
};


/**
 * calculates k means for k clusters of rows in a numberTable
 * @param  {NumberTable} numberTable with 2 or more numberLists
 * @param  {Number} k number of means
 *
 * @param  {Number} returnIndexesMode return mode:<|>0:return list of lists of indexes of rows (default)<|>1:return a list of number of mean, k different values, one per row<|>2:return a list of categorical colors, k different colors, one per row<|>3:return means<|>4:return list of sub-tables<|>5:return object with all the previous
 * @param  {Number} number of iterations (1000 by default)
 * @param  {NumberList} listSeeds is the list of indexes of rows to use for initial cluster means. If not specified they are chosen randomly
 * @return {Object} result (list, numberList, colorList, numberTable or object)
 * tags:statistics
 * examples:santiago/examples/kMeans
 */
NumberTableOperators.kMeans = function(numberTable, k, returnIndexesMode, N, listSeeds){
  if(numberTable == null || numberTable[0]==null || k == null || k <= 0 || numberTable.getLengths().getInterval().getAmplitude()!==0) return null;

  returnIndexesMode = returnIndexesMode==null?0:returnIndexesMode;
  N = (N==null || (N<=0))?1000:N;

  var clusters = new NumberTable();// = returnIndexesMode?new NumberList():new NumberTable();
  clusters.name = "k-means clusters";
  var i, j, l;
  var jK;
  var row;
  var d;
  var dMin;
  var n;
  var means = new NumberTable();
  var length = numberTable.length;
  var nRows = numberTable[0].length;
  var rows = numberTable.getTransposed();
  var initdMin = 99999999;
  var nRowsMean;
  var meanRowsIndexes;
  var newMean;
  var clustersPrev = clusters.clone();
  var numTrials,maxTrials=100;

  if(k>=rows.length) return rows;

  var equalToPreviousMean = function(row, meansSoFar){
    var kSoFar = meansSoFar.length;
    for(i=0; i<kSoFar; i++){
      if( ListOperators.containSameElements(row, meansSoFar[i]) ) return true;
    }
    return false;
  };


  //initial means (Forgy method, picking random rows)

  for(j = 0; j < k; j++) {
    if(listSeeds && j < listSeeds.length){
      means[j] = rows[listSeeds[j]].clone();
      continue;
    }
    row = rows.getRandomElement();
    // It's possible if there are repeated rows in the input data to run forever looking for enough unique rows.
    // Add a check and reduce k if it looks like we are having trouble finding enough.
    numTrials = 0;
    while(equalToPreviousMean(row, means) && numTrials < maxTrials){
      row = rows.getRandomElement();
      numTrials++;
    }
    if(numTrials == maxTrials){
      k = j;
      break;
    }

    means[j] = row.clone();

    //console.log('initial mean', means[j].join(', '));
  }


  for(n = 0; n < N; n++) {
    //iterations

    //clean clusters
    for(j = 0; j < k; j++) {
      clusters[j] = new NumberList();
      clusters[j].name = "-means cluster "+j;
    }

    //for each row finds its closer mean
    for(i = 0; i<nRows; i++) {
      row = rows[i];
      dMin = initdMin;
      jK = 0;

      for(j = 0; j < k; j++) {
        d = NumberListOperators.distance(row, means[j]);
        if(d < dMin) {
          dMin = d;
          jK = j;
        }
      }

      //console.log('i, jK', i, jK);

      //closer mean to row i is in index jK
      //row i assigned to cluster jK
      clusters[jK].push(i);
    }
    // Sometimes a cluster will end up empty (usually when you have repeated items) which breaks the algorithm.
    // Detect this and add an item from another cluster.
    for(i=0; i < clusters.length; i++){
      if(clusters[i].length == 0){
        for(j=0; j < clusters.length; j++){
          if(j == i || clusters[j].length < 2) continue;
          // move last item from cluster j to cluster i
          var iRemove = clusters[j].length-1;
          clusters[i].push(clusters[j][iRemove]);
          clusters[j] = clusters[j].getWithoutElementAtIndex(iRemove);
          break; // only once
        }
      }
    }

    // Check to see if clusters have changed since previous iteration.
    // If they are the same then we are done and do not need to do all the iterations
    if(clusters.isEquivalent(clustersPrev))
      break;
    clustersPrev = clusters.clone();

    //console.log('clusters.getLengths()', clusters.getLengths());

    //for each mean it calculates its new values, based on its recently assigned rows
    for(j=0; j<k; j++){
      meanRowsIndexes = clusters[j];
      nRowsMean = meanRowsIndexes.length;
      means[j] = new NumberList();
      means[j].name = "k-means mean "+j;

      newMean = means[j];

      row = rows[meanRowsIndexes[0]];
      //console.log(j, meanRowsIndexes[0], row);

      //each new mean is the average of its rows values
      for(l=0; l<length; l++){
        newMean[l] = row[l]/nRowsMean;
      }
      for(i=1; i<nRowsMean; i++){
        row = rows[meanRowsIndexes[i]];
        for(l=0; l<length; l++){
            newMean[l] += row[l]/nRowsMean;
        }
      }

    }
  }

  // console.log('clusters.getLengths()', clusters.getLengths());
  // console.log('clusters', clusters);

  //prepare results

  var meanNumber;
  var cluster;
  var sizeCluster;


  if(returnIndexesMode==1 || returnIndexesMode==5){
    meanNumber = new NumberList();
    meanNumber.name = "mean index";
    for(i=0; i<k; i++){
      cluster = clusters[i];
      sizeCluster = cluster.length;
      for(j=0; j<sizeCluster; j++){
        meanNumber[cluster[j]] = i;
      }
    }
  }


  var colors;

  if(returnIndexesMode==2 || returnIndexesMode==5){
    colors = new ColorList();
    colors.name = "mean color";
    var catColors = ColorListGenerators.createDefaultCategoricalColorList(k);
    for(i=0; i<k; i++){
      cluster = clusters[i];
      sizeCluster = cluster.length;
      for(j=0; j<sizeCluster; j++){
        colors[cluster[j]] = catColors[i];
      }
    }
  }

  var subTables = new List();

  if(returnIndexesMode==4 || returnIndexesMode==5){
    for(i=0; i<k; i++){
      subTables[i] = new NumberTable();
      subTables[i].name = 'k-means subtable_'+i;
      cluster = clusters[i];
      sizeCluster = cluster.length;
      for(j=0; j<sizeCluster; j++){
        subTables[i].push(rows[cluster[j]]);
      }
      subTables[i] = subTables[i].getTransposed();
    }
  }

  switch(returnIndexesMode){
    case 0://return list of indexes of rows
      return clusters;
    case 1://return a list of number of mean, k different values, one per row
      return meanNumber;
    case 2://return a list of categorical colors, k different colors, one per row
      return colors;
    case 3://return means
      return means;
    case 4://return list of sub-tables
      return subTables;
    case 5://return object with all the previous
      return {indexes:clusters, means:means, meanNumber:meanNumber, colors:colors, subtables:subTables};
  }

  return null;
};



// /**
//  * builds a k-nearest neighbors function, that calculates a class membership or a regression, taking the vote or average of the k nearest instances, using Euclidean distance, and applies it to a list of points, see: http://en.wikipedia.org/wiki/K-nearest_neighbors_algorithm
//  * <br>[!] regression still not built
//  * @param  {NumberTable} numberTable
//  * @param  {List} propertyList categories or values
//  *
//  * @param  {Polygon} vectorList optional list of points to be tested, if provided classes or regressions are calculated, if not the function is returned
//  * @param  {Number} k number of neighbors
//  * @param  {Boolean} calculateClass if true propertyList is a list of categories for membership calculation, if false a numberList for regression
//  * @param {Number} matrixN if provided, the result will be a numberTable with values in a grid in the numberTable ranges
//  * @return {Object} kNN Function or a matrix (grid) of values if matrixN is provided, or classes or values from points if vectorList is provided
//  * tags:ds
//  */
// NumberTableOperators.kNN = function(numberTable, propertyList, vectorList, k, calculateClass, matrixN) {//@todo: imporve performance
//   if(numberTable == null ||  propertyList == null) return null;

//   k = k || 1;
//   calculateClass = calculateClass == null ? true : calculateClass;

//   var i, j;

//   var fKNN = function(vector) {
//     var i, j;
//     var d2;

//     var table = new NumberTable();

//     table[0] = new NumberList();
//     table[1] = new NumberList();
//     numberTable[0].forEach(function(val, i) {//TODO: make it more efficient by using for
//       d2 = 0;
//       numberTable.forEach(function(nList, j) {
//         d2 += Math.pow(nList[i] - vector[j], 2);
//       });
//       if(table[1].length < k || table[1][k - 1] > d2) {
//         var inserted = false;
//         for(j = 0; table[1][j] < k; j++) {
//           if(d2 < table[1][j]) {
//             table[1].splice(j, 0, d2);
//             table[0].splice(j, 0, i);
//             inserted = true;
//             break;
//           }
//         }
//         if(!inserted && table[1].length < k) {
//           table[1].push(d2);
//           table[0].push(i);
//         }
//       }
//     });

//     if(calculateClass) {
//       var classTable = new Table();
//       classTable[0] = new List();
//       classTable[1] = new NumberList();
//       for(i = 0; i < k; i++) {
//         var clas = propertyList[table[0][i]];
//         var index = classTable[0].indexOf(clas);
//         if(index == -1) {
//           classTable[0].push(clas);
//           classTable[1].push(1);
//         } else {
//           classTable[1][index]++;
//         }
//       }
//       var max = classTable[1][0];
//       var iMax = 0;
//       classTable[1].slice(1).forEach(function(val, i) {
//         if(val > max) {
//           max = val;
//           iMax = i + 1;
//         }
//       });
//       return classTable[0][iMax];
//     }

//     var val;
//     var combination = 0;
//     var sumD = 0;
//     for(i = 0; i < k; i++) {
//       val = propertyList[table[0][i]];
//       combination += val / (table[1][i] + 0.000001);
//       sumD += (1 / (table[1][i] + 0.000001));
//     }

//     //console.log('vector:', vector[0], vector[1], 'colsest:', Math.floor(100000000 * table[1][0]), Math.floor(100000000 * table[1][1]), 'categories', propertyList[table[0][0]], propertyList[table[0][1]], 'result', combination / sumD);

//     return combination / sumD;

//     //regression
//     //…
//   };

//   if(vectorList == null) {

//     if(matrixN != null && matrixN > 0) {
//       var propertiesNumbers = {};
//       var n = 0;

//       propertyList.forEach(function(val) {
//         if(propertiesNumbers[val] == null) {
//           propertiesNumbers[val] = n;
//           n++;
//         }
//       });

//       var p;
//       var matx = new NumberTable();
//       var ix = numberTable[0].getInterval();
//       var minx = ix.x;
//       var kx = ix.getAmplitude() / matrixN;
//       var iy = numberTable[1].getInterval();
//       var miny = iy.x;
//       var ky = iy.getAmplitude() / matrixN;

//       for(i = 0; i < matrixN; i++) {
//         matx[i] = new NumberList();

//         for(j = 0; j < matrixN; j++) {
//           p = [
//             minx + kx * i,
//             miny + ky * j
//           ];

//           fKNN(p);

//           matx[i][j] = calculateClass ? propertiesNumbers[fKNN(p)] : fKNN(p);
//         }
//       }
//       //return matrix
//       return matx;
//     }

//     //return Function
//     return fKNN;
//   }

//   var results = instantiateWithSameType(propertyList);

//   vectorList.forEach(function(vector) {
//     results.push(fKNN(vector));
//   });

//   //return results
//   return results;
// };


/**
 * calculates the matrix product of two Numbertables
 * @param  {NumberTable} numberTable0 first numberTable
 * @param  {NumberTable} numberTable1 second numberTable
 * @return {NumberTable} result
 */
NumberTableOperators.product = function(numberTable0, numberTable1){
  if(numberTable0==null || numberTable1==null) return;
  var n = numberTable0.length;
  var m = numberTable0[0].length;

  if(n === 0 || m === 0 || n!=numberTable1[0].length || m!=numberTable1.length) return;


  var newTable = new NumberTable();
  var i, j, k;
  var val;

  for(i=0; i<n; i++){
    newTable[i] = new NumberList();
    for(j=0; j<n; j++){
      val = 0;
      for(k=0; k<m; k++){
        val+=numberTable0[i][k]*numberTable1[k][j];
      }
      newTable[i][j] = val;
    }
  }

  return newTable;
};



/**
 * calculates the covariance matrix
 * @param  {NumberTable} numberTable
 * @return {NumberTable}
 * tags:statistics
 */
NumberTableOperators.getCovarianceMatrix = function(numberTable){//TODO:build more efficient method
  if(numberTable==null) return;
  return NumberTableOperators.product(numberTable, numberTable.getTransposed()).factor(1/numberTable.length);
};

/**
 * returns a table of assignments that minimizes (approximately) the cost of assigning column to row elements
 * @param  {NumberTable} tabCost is a table of costs for the assignment. Element [i][j] has the cost for matching list1[i] with list2[j]
 *
 * @return {NumberTable} containing 2 lists having the indexes for the matches in the order list,row and a 3rd having the cost
 * tags:combinatorics
 */
NumberTableOperators.linearAssignmentGreedySearch = function(tabCost){
  var tabMatches = new NumberTable();
  tabMatches.push(new NumberList());
  tabMatches[0].name = 'list1 index';
  tabMatches.push(new NumberList());
  tabMatches[1].name = 'list2 index';
  tabMatches.push(new NumberList());
  tabMatches[2].name = 'cost';

  if(tabCost == null || tabCost.length == 0 || tabCost[0].length == 0) return tabMatches;
  tabCost = tabCost.clone(); // since we change it below we don't want to destroy the input

  // now find minimum cost in matrix and where it is.
  var locMin = tabCost.getMinCell();
  while(tabCost[locMin[0]][locMin[1]] < Number.MAX_VALUE){
    // save the match
    tabMatches[0].push(locMin[0]);
    tabMatches[1].push(locMin[1]);
    tabMatches[2].push(tabCost[locMin[0]][locMin[1]]);
    // remove other items from further consideration in same row / col
    for(var i=0; i < tabCost.length; i++){
      tabCost[i][locMin[1]] = Number.MAX_VALUE;
    }
    for(var j=0; j < tabCost[0].length; j++){
      tabCost[locMin[0]][j] = Number.MAX_VALUE;
    }
    locMin = tabCost.getMinCell();
  }
  return tabMatches;
};


/**
 * returns a list of the features which best separate the categories and are most independent of each other. Kmeans is used to group features together so only one feature from each group is returned. If no category list is specified then the first feature in each group is returned. For a numeric target the absolute value of correlation is used.
 * @param  {Table} nTFeatures is a table of features across the top and rows corresponding to items. The features can be numeric, categorical strings, dates, or long text
 *
 * @param  {StringList|NumberList} target is a list of category values or numeric values you are trying to predict
 * @param  {Number} n is the number of features to return (default:5). Must be less than the number of features in the table.
 * @param  {Boolean} normalize when true normalize the input features (default)<br> when false use features as they are
 *
 * @return {NumberList} nLBestFeatures returns the column indexes of the best feature by these criteria
 * tags:ds,machine-learning
 * examples:jeff/examples/getBestFeatures
*/
NumberTableOperators.getBestFeatures = function(nTFeatures,sLCat,n,normalize){
  if(nTFeatures == null || (sLCat != null && !sLCat.isList) )return;
  // Now we support features that are dates,long text, categorical text, as well as numbers
  n = n == null ? 5 : n;
  normalize = normalize == null ? true : normalize;
  var t=new Table();
  t.push(new NumberList());
  t.push(new StringList());
  t.push(new NumberList());
  t.push(new NumberList());
  t.push(new NumberList());
  t[0].name='Correlation Ratio';
  t[1].name='Feature';
  t[2].name='Index';
  t[3].name='Group';
  t[4].name='Selected';
  var nTFeatures0 = nTFeatures;
  if(normalize)
    nTFeatures = NumberTableOperators.normalizeLists(nTFeatures,null,'z-score');
  var r;
  var bClass = true;
  if(sLCat != null){
    if(sLCat.type == 'NumberList'){
      bClass = false;
      t[0].name = 'Absolute Correlation';
    }
    else if(sLCat.type != 'StringList')
      sLCat = sLCat.toStringList();
  }
  var tTemp,tWrap,nLr,j,iMax,res;
  var nTFeaturesNumeric = new Table(); // use to hold actual lists used for kmeans
  for(var i=0;i<nTFeatures.length;i++){
    // now nTFeatures[i] can be Dates, strings, long text etc
    if(sLCat == null)
      r = 1;
    else if(bClass){
      if(nTFeatures[i].type == 'NumberList'){
        r = StringListOperators.correlationRatio(sLCat,nTFeatures[i],2);
        nTFeaturesNumeric.push(nTFeatures[i]);
      }
      else{
        tWrap = new Table();
        tWrap.push(nTFeatures[i]);
        res = TableOperators.encodeTableColumnsAsNumbers(tWrap,null,null,null,40);
        tTemp = res[0].value;
        nLr = new NumberList();
        for(j=0; j < tTemp.length; j++){
          r = StringListOperators.correlationRatio(sLCat,tTemp[j],2);
          nLr.push(r);
        }
        iMax = nLr.getIndexOfMax();
        if(res[1].value.columnInfo[0].pType == 'categorical'){
          // use average
          r = nLr.getAverage();
        }
        else{
          // use max
          r = nLr[iMax];
        }
        // for kmeans grouping use the best numeric predictor from this feature
        nTFeaturesNumeric.push(tTemp[iMax]);
      }
    }
    else{
      if(nTFeatures[i].type == 'NumberList'){
        r = Math.abs(NumberListOperators.pearsonProductMomentCorrelation(sLCat,nTFeatures[i]));
        nTFeaturesNumeric.push(nTFeatures[i]);
      }
      else {
        tWrap = new Table();
        tWrap.push(nTFeatures[i]);
        res = TableOperators.encodeTableColumnsAsNumbers(tWrap,null,null,null,40);
        tTemp = res[0].value;
        nLr = new NumberList();
        for(j=0; j < tTemp.length; j++){
          r = Math.abs(NumberListOperators.pearsonProductMomentCorrelation(sLCat,tTemp[j]));
          nLr.push(r);
        }
        // use max for now
        iMax = nLr.getIndexOfMax();
        if(res[1].value.columnInfo[0].pType == 'categorical'){
          // use average
          r = nLr.getAverage();
        }
        else{
          // use max
          r = nLr[iMax];
        }
        // for kmeans grouping use the best numeric predictor from this feature
        nTFeaturesNumeric.push(tTemp[iMax]);
      }
    }
    r = Number(r.toFixed(5));
    t[0].push(r);
    t[1].push(nTFeatures[i].name == '' ? ('Feature ' + i) : nTFeatures[i].name);
    t[2].push(i);
    t[4].push(0);
  }
  t=t.getListsSortedByList(0,false);
  // Use best features by criteria as starting points for clusters in kmeans.
  // This will reduce the chance that high quality features will be removed because they are in same group
  // make it deterministic
  NumberOperators.randomSeed(1);
  var nLGroups;
  var nTt = nTFeaturesNumeric.getTransposed();
  if(n >= nTt[0].length){
    nLGroups = t[2].clone();
    n = nTt[0].length;
  }
  else
    nLGroups = NumberTableOperators.kMeans(nTt,n,1,null,t[2]);
  NumberOperators.randomSeedPop();
  t=t.getListsSortedByList(2,true);
  for(i=0;i<nTFeatures.length;i++){
    t[3][i] = nLGroups[i];
  }
  t=t.getListsSortedByList(0,false);

  // get highest correlation from each group
  var nLBestFeatures = new NumberList();
  var nLGroupsUsed = new NumberList();
  nLBestFeatures.push(t[2][0]);
  nLGroupsUsed.push(t[3][0]);
  t[4][0] = 1;
  var nLKeep = new mo.NumberList();
  nLKeep.push(0);
  for(i=1;i<t[0].length;i++){
    if(nLGroupsUsed.includes(t[3][i]))continue;
    nLBestFeatures.push(t[2][i]);
    nLGroupsUsed.push(t[3][i]);
    nLKeep.push(i);
    t[4][i] = 1;
    if(nLGroupsUsed.length == n)break;
  }
  var tBestFeatures = new mo.Table();
  var tTemp = t.getRows(nLKeep);
  tBestFeatures[0] = tTemp[1];
  tBestFeatures[1] = tTemp[0];
  var aRet = [
    {
      type: "Table",
      name: "tResults",
      description: "Table with best feature columns only",
      value: nTFeatures0.getElements(nLBestFeatures)
    },
    {
      type: "NumberList",
      name: "nLBestFeatures",
      description: "List of the indexes of the best features",
      value: nLBestFeatures
    },
    {
      type: "Table",
      name: "tMetricValuesAndGroups",
      description: "Table of metric values and groups for all features",
      value: t
    },
    {
      type: "Table",
      name: "tBestFeatures",
      description: "Table of best features and metrics",
      value: tBestFeatures
    },
    {
      type: "NumberList",
      name: "nLBestMetrics",
      description: "List of the metric values of the best features. Can be used as weights.",
      value: tBestFeatures[1]
    }
  ];
  aRet.isOutput = true;
  return aRet;
};

/**
 * calculates the mean absolute error between corresponding elements
 * @param  {NumberTable} nT1 is the 1st NumberTable (or NumberList)
 * @param  {NumberTable} nT2 is the 2nd NumberTable (or NumberList)
 *
 * @return {Number} mae is the average of the absolute differences between corresponding elements
 * tags:statistics
 */
NumberTableOperators.meanAbsoluteError = function(nT1, nT2){
  if(nT1 == null || nT2 == null) return;
  var temp,i,j;
  if(nT1.type == 'NumberList'){
    temp = new NumberTable();
    temp.push(nT1);
    nT1 = temp;
  }
  if(nT2.type == 'NumberList'){
    temp = new NumberTable();
    temp.push(nT2);
    nT2 = temp;
  }
  if(nT1.type != 'NumberTable' || nT2.type != 'NumberTable')
    throw new Error('Input must be NumberTables or NumberLists');
  if(nT1.length != nT2.length)
    throw new Error('Inputs must have same number of elements');

  var n=0, sumAbsoluteErrors = 0;
  for(i=0; i < nT1.length; i++){
    if(nT1[i].length != nT2[i].length)
      throw new Error('Input lengths are not the same');
    for(j=0; j < nT1[i].length; j++){
      n++;
      sumAbsoluteErrors += Math.abs(nT1[i][j] - nT2[i][j]);
    }
  }
  if(n==0) return 0;
  return sumAbsoluteErrors/n;
};

/**
 * Classification method that finds the most likely class or value based on k nearest neighbours in terms of euclidean distance. Items in rows and features in columns. More information about this classification method: https://en.wikipedia.org/wiki/K-nearest_neighbors_algorithm 
 * @param  {NumberTable} nTKnown table where each row is an item with feature value elements
 * @param  {StringList|NumberList} LKnown is a list of class values for each item
 *
 * @param  {NumberTable} nTPredict table where each row is an item with feature value elements. If null, then each row from first inlet table is predicted based on the others in that same table.
 * @param  {Number} k the number of nearest neighbours to use (default:3)
 * @param  {Boolean} bNormalize if true normalize so that each feature has the same weight (default:false)
 * @param  {Number} mode used to aggregate for numeric predictions:<|>0: simple average(default)<|>1: median<|>-1: weighted by inverse distance<|>-2: weighted by square of inverse distance
 * @return {List} the list of predicted classes
 * tags:statistics,machine-learning
 * examples:santiago/examples/modules/kNN
 */
NumberTableOperators.kNearestNeighboursPredictor = function(nTKnown, LKnown, nTPredict, k, bNormalize, mode) {
  if(nTKnown == null || LKnown == null || nTKnown.length == 0) return;
  k = k == null ? 3 : k;
  bNormalize = bNormalize == null ? false : bNormalize;
  mode = mode == null ? 0 : mode;
  if(nTPredict != null && nTPredict.type == 'NumberList'){
    // wrap it and continue
    var temp = nTPredict;
    nTPredict = new NumberTable();
    nTPredict.push(temp);
    nTPredict = nTPredict.getTransposed();
  }
  if(LKnown.length != nTKnown[0].length)
    throw new Error('The NumberTable in the first inlet must have the same number of rows as elements in the second inlet.');

  var i, j, tDistances, tAgg, aObjs, denom, total;
  var bNumericCols = true;
  if(nTKnown.isTable){
    for(i = 0; i < nTKnown.length && bNumericCols; i++)
      bNumericCols = bNumericCols && nTKnown[i].type == 'NumberList';
    if(!bNumericCols)
      throw new Error('The first inlet must have numeric columns.');
  }
  else
    throw new Error('The first inlet must be a NumberTable.');

  if(bNormalize){
    var nLAverages = new mo.NumberList();
    var nLStdDevs = new mo.NumberList();
    // clone input table since we modify it by calculating zscores
    nTKnown = nTKnown.clone();
    for(i = 0; i < nTKnown.length; i++){
      nLStdDevs.push(nTKnown[i].getStandardDeviation());
      nLAverages.push(nTKnown[i].getAverage());
      nTKnown[i] = mo.NumberListOperators.normalizeByZScore(nTKnown[i]);
    }
    // adjust nTPredict in exactly same manner if it is present
    if(nTPredict != null){
      nTPredict = nTPredict.clone();
      var stddev;
      for(i = 0; i < nTPredict.length; i++){
        stddev = nLStdDevs[i];
        if(stddev === 0) stddev = 1;
        for(j=0; j < nTPredict[i].length; j++)
          nTPredict[i][j] = (nTPredict[i][j] - nLAverages[i]) / stddev;
      }
    }
  }

  var LPredict = instantiateWithSameType(LKnown);
  LPredict.name = LKnown.name == '' ? 'Predicted' : 'Predicted ' + LKnown.name;
  //  transpose once here rather than every call to kNearestNeighbours
  var nTKnownTransposed = nTKnown.getTransposed();
  var nLen = nTKnownTransposed.length;
  if(nTPredict != null)
    nLen = nTPredict[0].length;
  for(i = 0; i < nLen; i++){
    if(nTPredict)
      tDistances = NumberListOperators.kNearestNeighbours(nTKnownTransposed,nTPredict.getRow(i),k,2,true);
    else
      tDistances = NumberListOperators.kNearestNeighbours(nTKnownTransposed,i,k,2,true);
    // replace index with class value
    if(LKnown.type == 'StringList'){
      for(j = 0; j < tDistances[0].length; j++){
        tDistances[0][j] = LKnown[tDistances[0][j]];
      }
      // find most common class, for ties we want to keep the one with lowest total distance
      tAgg = TableOperators.aggregateTable(tDistances,0,NumberList.fromArray([0,1,1]),NumberList.fromArray([0,1,2]));
      // resulting tAgg should have class, count of class, sum of distances
      tAgg.assignNames('class,count,dist');
      aObjs = TableConversions.TableToObject(tAgg).array;
      aObjs.sort(function(a,b){
        if(a.count == b.count){
          if(a.dist == b.dist)
            return a.class < b.class ? -1 : 1;
          return a.dist - b.dist;
        }
        return b.count - a.count;
      });
      LPredict.push(aObjs[0].class);
    }
    else{
      // numeric
      var ntValueWeights = new NumberTable();
      ntValueWeights.push(new NumberList()); // value
      ntValueWeights.push(new NumberList()); // weight
      var weight;
      for(j = 0; j < tDistances[0].length; j++){
        ntValueWeights[0].push(LKnown[tDistances[0][j]]);
        if(mode == -1){
          weight = 1 / tDistances[1][j];
          ntValueWeights[1].push(weight);
        }
        else if(mode == -2){
          weight = 1 / (tDistances[1][j] * tDistances[1][j]);
          ntValueWeights[1].push(weight);
        }
      }
      if(mode == 0)
        LPredict.push(ntValueWeights[0].getAverage());
      else if(mode == 1)
        LPredict.push(ntValueWeights[0].getMedian());
      else if(ntValueWeights[1].getMax() == Infinity){
        // we had distances of zero in either mode -1 or -2. Use a simple average of the infinitely close values
        denom = total = 0;
        for(j = 0; j < ntValueWeights[0].length; j++){
          if(ntValueWeights[1][j] == Infinity){
            denom++;
            total += ntValueWeights[0][j];
          }
        }
        LPredict.push(total/denom);
      }
      else{
        denom = total = 0;
        for(j = 0; j < ntValueWeights[0].length; j++){
          denom += ntValueWeights[1][j];
          total += ntValueWeights[0][j] * ntValueWeights[1][j];
        }
        LPredict.push(total/denom);
      }
    }
  }
  return LPredict;
};

/**
 * Find a reasonable path connecting a start item to an end item. Path is of a specified length and steps between items is kept small. Items are in rows with features in columns.
 * @param  {NumberTable} ntFeatures table where each row is an item with feature value elements
 * @param  {Number} iStartRow is the row index of the starting item
 * @param  {Number} iEndRow is the row index of the ending item
 *
 * @param  {Number} n is the number of items in the connecting path (default:3)
 * @param  {Boolean} bNormalize if true normalize so that each feature has the same weight (default:false)
 * @param  {Boolean} bIncludeEndPoints if true include the start and end row indexes in the output path (default:false)
 * @return {NumberList} nLPathRows is a list of the items in order from start to end
 * tags: statistics
 */
NumberTableOperators.getPathBetweenItems = function(ntFeatures, iStartRow, iEndRow, n, bNormalize, bIncludeEndPoints) {
  if(ntFeatures == null || iStartRow == null || iEndRow == null) return null;
  n = n == null ? 3 : n;
  bNormalize = bNormalize == null ? false : bNormalize;
  bIncludeEndPoints = bIncludeEndPoints == null ? false : bIncludeEndPoints;
  if(iStartRow >= ntFeatures[0].length)
    throw new Error('iStartRow must be less than the number of rows in feature table.');
  if(iEndRow >= ntFeatures[0].length)
    throw new Error('iEndRow must be less than the number of rows in feature table.');
  var nLSel = new NumberList();
  nLSel.push(iStartRow);
  nLSel.push(iEndRow);

  if(bNormalize){
    // clone input table since we modify it and don't want a side effect
    ntFeatures = ntFeatures.clone();
    for(i = 0; i < ntFeatures.length; i++)
      ntFeatures[i] = mo.NumberListOperators.normalizeByZScore(ntFeatures[i]);
  }
  ntFeatures = ntFeatures.getTransposed();

  var vA = ntFeatures[iStartRow];
  var vB = ntFeatures[iEndRow];
  var nLPath = new NumberList(); // store indexes into original set
  var nLPathD = new NumberList();
  var i,vD,nLNearest,d;
  // vC is current starting point
  var vC = vA.clone();
  for(i=0; i<n; i++){
    vD = vB.subtract(vC);
    vD = vD.factor(1/(n-i+1));
    vD = vC.add(vD);
    nLNearest = NumberListOperators.kNearestNeighbours(ntFeatures,vD,nLPath.length+3,1,true);
    // don't reuse any existing points or the start and end points
    nLNearest = ListOperators.difference(nLNearest,nLPath);
    nLNearest = ListOperators.difference(nLNearest,nLSel);
    // should always have at least one
    nLPath.push(nLNearest[0]);
    d  =NumberListOperators.distance(vA,ntFeatures[[nLNearest[0]]]);
    d -=NumberListOperators.distance(vB,ntFeatures[[nLNearest[0]]]);
    nLPathD.push(d);
    vC = vD.clone();
  }
  // sometimes in non-optimal order for close points, reorder
  var t = new Table();
  t.push(nLPath);
  t.push(nLPathD);
  t  = t.getListsSortedByList(1);
  nLPath = t[0];
  if(bIncludeEndPoints){
    var nLTemp = new NumberList();
    nLTemp.push(iStartRow);
    nLTemp = nLTemp.concat(nLPath);
    nLTemp.push(iEndRow);
    nLPath = nLTemp;
  }
  return nLPath;
};

/**
 * calculates a nearest neighbour similarity score between two NumberTables where features are in columns and items in rows
 * @param  {NumberTable} t1 first numberTable
 * @param  {NumberTable} t2 second numberTable
 *
 * @param  {Number} k is the number of neighbours to consider (default:10)
 * @param  {Number} n is the number of item samples to use (default:100 or number of rows)
 * @param  {String} distanceMetric is the type of distance metric to use:<|>euclidean: distance (default)<|>cosine: distance<|>manhattan: distance
 * @param  {Boolean} bNormalize if true then z-normalize tables before calculation (default:false)
 * @param  {Boolean} bInvert if true then find the similarity of the farthest neighbours instead of the nearest (default:false)
 * @return {Number} similarity in range [0,1]
 * tags: statistics
*/
NumberTableOperators.getNearestNeighbourSimilarity = function(t1,t2,k,n,distanceMetric,bNormalize,bInvert){
  if(t1 == null || t2 == null || t1.length == 0 || t2.length == 0) return;
  if(t1[0].length != t2[0].length)
    throw new Error('The 2 tables must have the same number of rows.');

  k = k == null ? 10 : k;
  n = n == null ? 100 : n;
  distanceMetric = distanceMetric == null ? 'euclidean' : distanceMetric;
  bNormalize = bNormalize == null ? false : bNormalize;
  bInvert = bInvert == null ? false : bInvert;

  var i,j,L1,L2,Lint,pSim,total=0;
  if(bNormalize){
    t1 = NumberTableOperators.normalizeLists(t1,null,'z-score');
    t2 = NumberTableOperators.normalizeLists(t2,null,'z-score');
  }

  if(n > t1[0].length)
    n = t1[0].length;
  var Li = NumberListGenerators.createSortedNumberList(n);
  if(n < t1[0].length)
    Li = Li.getRandomElements(n,true,1,false);
  for(i=0;i<Li.length;i++){
    j = Li[i];
    L1=NumberListOperators.kNearestNeighbours(t1,j,k,1,null,distanceMetric,bInvert);
    L2=NumberListOperators.kNearestNeighbours(t2,j,k,1,null,distanceMetric,bInvert);
    Lint=ListOperators.intersection(L1,L2);
    pSim=Lint.length/L1.length;
    total+=pSim;
  }
  return Number(NumberOperators.numberToString(total/Li.length,4));
};


/**
 * build NetworkTables filled with numbers in different ways
 * @param  {Number} mode 0: identity matrix (default)<|>1: same value all cells (use factor as value)<|>2: random values<|>3: random values on diagonal
 * @param  {Number} nColumns
 *
 * @param  {Number} nRows (by default equal to nColumns)
 * @param  {Number} factor optional value to fill or factor cells (default: 1)
 * @return {NumberTable}
 * tags:generator,math
 */
NumberTableOperators.generateNumberTable=function(mode, nColumns, nRows, factor){//this method should be placed at NumberTableGenerators.js
  mode = mode==null?0:mode;
  nRows = nRows==null?nColumns:nRows;
  factor = factor==null?1:factor;

  var nT = new NumberTable();
  var valueAtCell;

  switch(mode){
    case 0:
      valueAtCell = function(i,j){
        return i==j?factor:0;
      }
      break;

    case 1:
      valueAtCell = function(i,j){
        return factor;
      }
      break;

    case 2:
      valueAtCell = function(i,j){
        return Math.random()*factor;
      }
      break;

    case 3:
      valueAtCell = function(i,j){
        return i==j?Math.random()*factor:0;
      }
      break;
  };

  for(var i=0; i<nColumns; i++){
    nT[i] = new NumberList();
    nT[i].name = "col_"+i;
    for(var j=0; j<nRows; j++){
      nT[i][j] = valueAtCell(i,j);
    }
  }

  return nT;
};
