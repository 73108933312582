import ColorList from "src/dataTypes/graphic/ColorList";
import ColorScales from "src/operators/graphic/ColorScales";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import ListOperators from "src/operators/lists/ListOperators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import Table from "src/dataTypes/lists/Table";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";


/**
 * @classdesc Tools for generating {@link List|Lists} of colors.
 *
 * @namespace
 * @category colors
 */
function ColorListGenerators() {}
export default ColorListGenerators;

ColorListGenerators._HARDCODED_CATEGORICAL_COLORS = new ColorList(
  "#d62728", "#1f77b4", "#2ca02c", "#ff7f00", "#9467bd", "#bcbd22", "#8c564b", "#17becf", "#dd4411", "#206010", "#e377c2",
  "#3330ff", "#dd8811", "#ff220e", "#1f66a3", "#8c453a", "#2ba01c", "#dfc500", "#945600", "#ff008b", "#e37700", "#7f7f7f"
);

/**
 * create a simple list of categorical colors
 * @param  {Number} nColors
 *
 * @param  {Number} alpha 1 by default
 * @param {Boolean} invert invert colors
 * @param {Boolean} darken colors beyond starting colorList length (default=false)
 * @return {ColorList}
 * tags:generator
 */
ColorListGenerators.createDefaultCategoricalColorList = function(nColors, alpha, invert, bDarken) {
  //alpha = alpha == null ? 1 : alpha;
  bDarken = bDarken == null ? false : bDarken;
  var colors = ColorListGenerators.createCategoricalColors(2, nColors,null,null,null,null,null,bDarken);
  if(alpha!=null && alpha <= 1) colors = colors.addAlpha(alpha);

  if(invert) colors = colors.getInverted();

  return colors;
};

/**
 * Creates a ColorList of categorical colors based on an input List. All entries with the same value will get the same color.
 * @param {List} the list containing categorical data with same length as given list
 *
 * @param {ColorList} optional colors to be used
 * @param {Boolean} stableColors (default:false) guarantee that an element has a unique associated color, independent of other elements in list, useful to guarantee consistency accross visualizations
 */
ColorListGenerators.colorsForCategoricalList = function(list, colorList, stableColors){
  if(list==null) return;

  if(stableColors){
    var colors = new ColorList();
    colors.name = list.name+" colors";
    for(var i=0; i<list.length; i++){
      colors[i] = ColorOperators.stringToColor(String(list[i]));
    }
    return colors;
  }

  return ColorListGenerators.createCategoricalColorListForList(list, colorList)[0].value;
};

//@todo: change this method (and try to not break things)

/**
 * Creates a ColorList of categorical colors based on an input List. All entries with the same value will get the same color.
 * @param {List} list containing categorical data
 *
 * @param {ColorList} ColorList with categorical colors
 * @param {Number} alpha transparency
 * @param {String} color to mix
 * @param {Number} interpolation value (0-1) for color mix
 * @param {Boolean} invert invert colors
 * @param {Boolean} darken colors beyond internal colorList length (default=false)
 * @return {ColorList} ColorList with categorical colors that match the given list
 * @return {List} elements list of elemnts that match colors (equivalent to getWithoutRepetions)
 * @return {ColorList} ColorList with different categorical colors
 * @return {Table} dictionary dictionary table with elemnts and matching colors
 * @return {Object} citionaryObject (relational array, from objects to colors)
 * tags:generator
 */
ColorListGenerators.createCategoricalColorListForList = function(list, colorList, alpha, color, interpolate, invert, bDarken){

  if(list==null) return null;// new ColorList();

  //alpha = alpha == null ? 1 : alpha;

  //if(!color)
    //color = "#fff";
  interpolate = interpolate==null?0:interpolate;
  //bDarken = bDarken == null ? false : bDarken;

  //list = List.fromArray(list);
  
  var diffValues = list.getWithoutRepetitions();
  var diffColors;
  if(colorList && interpolate>0){
    diffColors = colorList.getInterpolated(color, interpolate);
  } else {
    diffColors = ColorListGenerators.createCategoricalColors(2, diffValues.length, null, alpha, color, interpolate, colorList,bDarken);
  }
  if(alpha!=null && alpha<=1) diffColors = diffColors.addAlpha(alpha);

  if(invert) diffColors = diffColors.getInverted();

  var colorDictTable = Table.fromArray([diffValues, diffColors]);
  var dictionaryObject = ListOperators.buildDictionaryObjectForDictionary(colorDictTable);

  var fullColorList = ListOperators.translateWithDictionary(list, colorDictTable, 'black');

  fullColorList = ColorList.fromArray(fullColorList);
  

  // var dictionaryTable = new Table();
  // dictionaryTable[0] = diffValues;
  // dictionaryTable[1] = fullColorList;

  return [
    {
      value: fullColorList,
      type: 'ColorList'
    }, {
      value: diffValues,
      type: diffValues.type
    }, {
      value: diffColors,
      type: 'ColorList'
    }, {
      value: colorDictTable,//new Table(diffValues, fullColorList),
      type: 'Table'
    }, {
      value: dictionaryObject,
      type: 'Object'
    }
  ];
};


/**
 * create a colorList based on a colorScale
 * @param  {Number} n number of colors
 *
 * @param  {ColorScale} colorScale (grayToOrange by default)
 * @param {Number} transformation apply a transformation:<|>0:none (default)<|>1:^2<|>2:sqrt<|>3:^4<|>4:^sqrt4
 * @return {ColorList}
 * tags:generator
 */
ColorListGenerators.createColorListFromColorScale = function(n, colorScale, transformation){
  if(n==null) return;
  transformation = transformation==null?0:transformation;

  colorScale = colorScale==null?ColorScales.grayToOrange:colorScale;

  var i;
  var colorList = new mo.ColorList();

  switch(transformation){
    case 0:
      for(i=0; i<n; i++){
        colorList[i] = colorScale(i/(n-1));
      }
      break;
    case 1:
      for(i=0; i<n; i++){
        colorList[i] = colorScale(Math.pow(i/(n-1), 2));
      }
      break;
    case 2:
      for(i=0; i<n; i++){
        colorList[i] = colorScale(Math.sqrt(i/(n-1)));
      }
      break;
    case 3:
      for(i=0; i<n; i++){
        colorList[i] = colorScale(Math.pow(i/(n-1), 4));
      }
      break;
    case 4:
      for(i=0; i<n; i++){
        colorList[i] = colorScale(Math.pow(i/(n-1), 0.25));
      }
      break;
  }

  return colorList;
};


/**
 * create a colorList based on a colorScale and values from a numberList (that will be normalized)
 * @param  {NumberList} numberList
 *
 * @param  {ColorScale} colorScale (grayToOrange by default)
 * @param  {Number} mode<|>0:normalize numberList<|>2:numberList contains color scale values (between 0 and 1)
 * @return {ColorList}
 * tags:generator
 */
ColorListGenerators.createColorListFromNumberList = function(numberList, colorScale, mode) {
  if(numberList==null) return null;

  mode = mode == null ? 0 : mode;
  colorScale = colorScale==null?ColorScales.grayToOrange:colorScale;

  var colorList = new ColorList();
  var newNumberList;
  var i;
  var l = numberList.length;

  switch(mode) {
    case 0: //0 to max
      newNumberList = NumberListOperators.normalizeToMax(numberList);
      break;
    case 1: //min to max
      break;
    case 2: //values between 0 and 1
      newNumberList = numberList;
      break;
  }

  for(i = 0; i<l; i++) {
    colorList[i]  = colorScale(newNumberList[i]);
  }

  return colorList;
};

/**
 * create a colorList based on thre colors and values from a numberList, the first color is for negative values, the second for 0, and the third for positive
 * @param  {NumberList} numberList
 *
 * @param  {String} colorNeg
 * @param  {String} color0
 * @param  {String} colorPos
 * @param  {Number} emphasize level (default:0), colors will be farther from color0 (0, 1, 2)
* @param {Interval} optional interval for min and max values (colorNeg and colorPos will be assigned to values below and above interval limits)
 * @return {ColorList}
 * tags:generator
 */
ColorListGenerators.createSymmetricalColorListFromNumberList = function(numberList, colorNeg, color0, colorPos, emphasize, interval){
  if(numberList==null) return;
  var inter = interval==null?numberList.getInterval():interval;
  var maxAbs = Math.max(Math.abs(inter.x),Math.abs(inter.y));

  colorNeg = colorNeg==null?'red':colorNeg;
  color0 = color0==null?'gray':color0;
  colorPos = colorPos==null?'blue':colorPos;

  var rgb0 = ColorOperators.colorStringToRGB(colorNeg);
  var rgb1 = ColorOperators.colorStringToRGB(color0);
  var rgb2 = ColorOperators.colorStringToRGB(colorPos);

  var colorList = new ColorList();
  var abs;

  for(var i = 0; i<numberList.length; i++){
    abs = Math.abs(numberList[i])/maxAbs;
    if(emphasize>0) abs = Math.sqrt(abs);
    if(emphasize>1) abs = Math.sqrt(abs);
    if(interval!=null && numberList[i]<interval.x){
      colorList[i] = colorNeg;
    } else if(interval!=null && numberList[i]>interval.y){
      colorList[i] = colorPos;
    } else {
      colorList[i] = ColorOperators.RGBArrayToString(numberList[i]<0?ColorOperators.interpolateColorsRGB(rgb1, rgb0, abs):ColorOperators.interpolateColorsRGB(rgb1, rgb2, abs));
    }
  }

  return colorList;
};


/**
 * Creates a new ColorList that contains the provided color. Size of the List
 * is controlled by the nColors input.
 *
 * @param {Number} nColors Length of the list.
 * @param {Color} color Color to fill list with.
 */
ColorListGenerators.createColorListWithSingleColor = function(nColors, color) {
  var colorList = new ColorList();
  for(var i = 0; i < nColors; i++) {
    colorList.push(color);
  }
  return colorList;
};

/**
 * Creates a new ColorList of specified size by interpolating between two colors
 * @param {Number} nColors Length of the list (default 8).
 * @param {String} color1 is the first color
 * @param {String} color2 is the second color
 * @return {ColorList} ColorList with colors in between the two input colors
 * tags:generator
*/
ColorListGenerators.createColorListBetweenTwoColors = function(nColors, color1, color2) {
  nColors = nColors == null? 8:nColors;
  if(color1 == null || color2 == null) return;
  var colorList = new ColorList();
  for(var i = 0; i < nColors; i++) {
    var clr = nColors == 1 ? color1 : ColorOperators.interpolateColors(color1,color2,i/(nColors-1),true);
    colorList.push(clr);
  }
  return colorList;
};


/**
 * Creates a new ColorList from the full spectrum. Size of the List is controlled by the nColors input
 * @param {Number} nColors Length of the list (default 8).
 * @param {Number} saturation in range [0,1]
 * @param {Number} value in range [0,1]
 * @return {ColorList} ColorList with spectrum colors
 * tags:generator
*/
ColorListGenerators.createColorListSpectrum = function(nColors, saturation,value) {
  // use HSV and rotate through hues
  nColors = nColors == null? 8:nColors;
  saturation = saturation == null? 1:saturation;
  value = value == null? 1:value;
  var colorList = new ColorList();
  var hue;
  for(var i = 0; i < nColors; i++) {
    // hue of 0 == hue of 360 so we go to nColors-1
    hue = 360*i/nColors;
    colorList.push(ColorOperators.HSVtoHEX(hue,saturation,value));
  }
  return colorList;
};

/**
 * Creates a new ColorList of the spectrum with some tints and shades and greys as well
 *
 * @param {Number} hues number of hues (default: 5)
 * @param {Number} nTintsAndShades number of lighter and darker variations of the hues to include (default: 2)
 *
 * @param {Boolean} bIncludeShadesOfGray (default:true)
 * @return {ColorList} ColorList with spectrum colors and variations
 * tags:generator
*/
ColorListGenerators.createColorListSpectrumTintsAndShades = function(hues,nTintsAndShades,bIncludeShadesOfGray) {
  hues = hues == null ? 5 : hues;
  nTintsAndShades = (nTintsAndShades == null || nTintsAndShades < 0) ? 2 : nTintsAndShades;
  bIncludeShadesOfGray = bIncludeShadesOfGray == null ? true : bIncludeShadesOfGray;

  var cLBase = ColorListGenerators.createColorListSpectrum(hues);
  var cLSpectrum = new ColorList();
  var i,j,c,crgb,crgb2;
  if(nTintsAndShades>0){
    for(i = 0;i < cLBase.length;i++){
      crgb = ColorOperators.colorStringToRGB(cLBase[i]);
      // tints
      for(j = 0;j < nTintsAndShades;j++){
        crgb2 = ColorOperators.interpolateColorsRGB([255,255,255],crgb,(j+1)/(nTintsAndShades+1));
        cLSpectrum.push(ColorOperators.RGBArrayToString(crgb2));
      }
      cLSpectrum.push(cLBase[i]);
      // shades
      for(j = 0;j < nTintsAndShades;j++){
        crgb2 = ColorOperators.interpolateColorsRGB(crgb,[0,0,0],(j+1)/(nTintsAndShades+1));
        cLSpectrum.push(ColorOperators.RGBArrayToString(crgb2));
      }
    }
  }
  else
    cLSpectrum = cLBase.clone();
  if(bIncludeShadesOfGray){
    // always add at least white + black plus enough greys to match the other blocks
    j = 1+nTintsAndShades*2-2;
    if(j == -1) j = 1;
    cLSpectrum.push('rgb(255,255,255)');
    for(i = 0;i < j;i++){
      c = 255 - Math.floor(255*(i+1)/(j+1));
      cLSpectrum.push('rgb('+c+','+c+','+c+')');
    }
    cLSpectrum.push('rgb(0,0,0)');
  }
  return cLSpectrum;
};

/**
 * Creates a colorList with a color associated to each different string (the color is produced from its characters)
 * @param {List} list
 *
 * @param {Table|Object} dictionary giving string to color mapping. Either a table with strings in first column and colors in second, or an object with strings as keys.<br>Any string not present in the dictionary is converted to a color in the usual manner
 * @return {ColorList} ColorList with spectrum colors
 * tags:generator,decoder,conversion
*/
ColorListGenerators.stringsToColors = function(list,dictionary){
  if(list==null) return;

  if(dictionary != null && dictionary.isTable)
    dictionary = ListOperators.buildDictionaryObjectForDictionary(dictionary);

  var colors =  new ColorList();
  for(var i=0; i<list.length; i++){
    colors[i] = dictionary ? dictionary[String(list[i])] : null;
    if(colors[i] == null)
      colors[i] = ColorOperators.stringToColor(String(list[i]));
  }

  return colors;
};


/**
 * Creates a ColorList of categorical colors
 * @param {Number} mode 0:simple picking from color scale function, 1:random (with seed), 2:hardcoded colors, 3:spectrum colors, 4:evolutionary algorithm, guarantees non consecutive similar colors(spectrum), 5:evolutionary algorithm, guarantees non consecutive similar colors(colorScale)
 * @param {Number} nColors
 *
 * @param {ColorScale} colorScaleFunction
 * @param {Number} alpha transparency
 * @param {String} interpolateColor color to interpolate
 * @param {Number} interpolateValue interpolation value [0, 1] (default:.5)
 * @param {ColorList} colorList colorList to be used in mode 2 (if not colorList is provided it will use default categorical colors)
 * @param {Boolean} darken colors beyond starting colorList length for mode 2(default=false)
 * @return {ColorList} ColorList with categorical colors
 * tags:generator
 */
ColorListGenerators.createCategoricalColors = function(mode, nColors, colorScaleFunction, alpha, interpolateColor, interpolateValue, colorList, bDarken) {
  colorScaleFunction = colorScaleFunction == null ? ColorScales.temperature : colorScaleFunction;
  //bDarken = bDarken == null ? false : bDarken;
  interpolateValue = interpolateValue==null?0.5:interpolateValue;
  var i, j;
  var newColorList = new ColorList();
  switch(mode) {
    case 0: //picking from ColorScale
      for(i = 0; i < nColors; i++) {
        newColorList[i] = colorScaleFunction(i / (nColors - 1));
      }
      break;
    case 1: //seeded random numbers
      var values = NumberListGenerators.createRandomNumberList(nColors, null, 0);
      for(i = 0; i < nColors; i++) {
        newColorList[i] = colorScaleFunction(values[i]);
      }
      break;
    case 2:
      colorList = colorList==null?ColorListGenerators._HARDCODED_CATEGORICAL_COLORS:colorList;
      var nInterpolate;
      for(i = 0; i < nColors; i++) {
        newColorList[i] = colorList[i%colorList.length];

        if(bDarken && i >= colorList.length){
          // move towards black
          nInterpolate = Math.floor(i/colorList.length);
          for(j=0; j < nInterpolate;j++){
            newColorList[i]= ColorOperators.interpolateColors(newColorList[i],'black',0.20);
          }
        }
      }
      break;
    case 3:
      newColorList = ColorListGenerators.createColorListSpectrum(nColors);
      break;
    case 4:
    case 5:
      var randomNumbersSource = NumberListGenerators.createRandomNumberList(1001, null, 0);
      var positions = NumberListGenerators.createSortedNumberList(nColors);
      var randomNumbers = NumberListGenerators.createRandomNumberList(nColors, null, 0);
      var randomPositions = ListOperators.sortListByNumberList(positions, randomNumbers);

      var nGenerations = Math.floor(nColors * 2) + 100;
      var nChildren = Math.floor(nColors * 0.6) + 5;
      var bCircular = mode == 4;
      var bExtendedNeighbourhood = mode == 4;
      var bestEvaluation = ColorListGenerators._evaluationFunction(randomPositions,bCircular,bExtendedNeighbourhood);
      var child;
      var bestChildren = randomPositions;
      var nr = 0;
      var evaluation;

      for(i = 0; i < nGenerations; i++) {
        for(j = 0; j < nChildren; j++) {
          child = ColorListGenerators._sortingVariation(randomPositions, randomNumbersSource[nr], randomNumbersSource[nr + 1]);
          nr = (nr + 2) % 1001;
          evaluation = ColorListGenerators._evaluationFunction(child,bCircular,bExtendedNeighbourhood);
          if(evaluation > bestEvaluation) {
            bestChildren = child;
            bestEvaluation = evaluation;
          }
        }
        randomPositions = bestChildren;
      }
      if(mode == 4){
        var colorListSpectrum = ColorListGenerators.createColorListSpectrum(nColors);
        for(i = 0; i < nColors; i++) {
          newColorList.push(colorListSpectrum[randomPositions[i]]);
        }
      }
      else{ // 5
        for(i = 0; i < nColors; i++) {
          newColorList.push(colorScaleFunction((1 / nColors) + randomPositions[i] / (nColors + 1))); //TODO: make more efficient by pre-nuilding the colorList
        }
      }
      break;
  }

  if(interpolateValue>0 && interpolateColor != null) {
    newColorList = newColorList.getInterpolated(interpolateColor, interpolateValue);
  }

  if(alpha!=null) {
    newColorList = newColorList.addAlpha(alpha);
  }


  return newColorList;
};

/**
 * @ignore
 */
ColorListGenerators._sortingVariation = function(numberList, rnd0, rnd1) { //private
  var newNumberList = numberList.clone();
  var pos0 = Math.floor(rnd0 * newNumberList.length);
  var pos1 = Math.floor(rnd1 * newNumberList.length);
  var cache = newNumberList[pos1];
  newNumberList[pos1] = newNumberList[pos0];
  newNumberList[pos0] = cache;
  return newNumberList;
};

/**
 * @ignore
 */
ColorListGenerators._evaluationFunction = function(numberList, bCircular, bExtendedNeighbourhood) { //private
  // bCircular == true means distance between 0 and n-1 is 1
  // bExtendedNeighbourhood == true means consider diffs beyond adjacent pairs
  var sum = 0;
  var i,d,r2,
    len=numberList.length,
    h=Math.floor(len/2);
  var range = bExtendedNeighbourhood ? 4 : 1;
  for(var r=1; r <= range; r++){
    r2 = r*r;
    for(i = 0; numberList[i + r] != null; i++) {
      d = Math.abs(numberList[i + r] - numberList[i]);
      if(bCircular && d > h){
        d = len-d;
      }
      sum += Math.sqrt(d/r2);
    }
  }
  return sum;
};

/**
 * Creates an object dictionary that matches elements from a list (that could contain repeated elements) with categorical colors. If a table is input then all the categorical lists in the table are used.
 * @param {List|Table} the list containing categorical data. Can also be a table in which case all the categorical lists are used.
 *
 * @param {ColorList} ColorList with categorical colors
 * @param {Number} alpha transparency
 * @param {String} color to mix
 * @param {Number} interpolation value (0-1) for color mix
 * @param {Boolean} invert invert colors
 * @return {Object} object dictionary that delivers a color for each element on original list
 * tags:generator
 */
ColorListGenerators.createCategoricalColorListDictionaryObject = function(list, colorList, alpha, color, interpolate, invert){
  if(list==null) return;

  if(list.isTable){
    // special processing for tables, look at all categorical lists
    var table = list;
    var i,colorListRemaining,colorListToUse,oForList,oForTable,n,info;
    var colorListAll = colorList == null ? ColorListGenerators._HARDCODED_CATEGORICAL_COLORS : colorList;
    colorListRemaining = colorListAll.clone();
    // we want to try and have unique colors even for different categories if we can
    oForTable = {};
    for(i=0; i < table.length; i++){
      info = ListOperators.buildInformationObject(table[i]);
      if(!info.isCategorical) continue;
      n = info.numberDifferentElements;
      if(n >= colorListAll.length)
        oForList = ColorListGenerators.createCategoricalColorListDictionaryObject(table[i],colorListAll,alpha,color,interpolate,invert);
      else{
        if(n > colorListRemaining.length)
          colorListRemaining = colorListAll.clone();
        oForList = ColorListGenerators.createCategoricalColorListDictionaryObject(table[i],colorListRemaining,alpha,color,interpolate,invert);
        colorListRemaining = colorListRemaining.getSubList(n,colorListRemaining.length-1);
      }
      // add the oForList to the oForTable
      // Note that if the same string is used in different lists the color choice might not be optimal
      oForTable = Object.assign(oForTable,oForList);
    }
    return oForTable;
  }

  var diffValues = list.getWithoutRepetitions();

  var diffColors = ColorListGenerators.createCategoricalColors(2, diffValues.length, null, alpha, color, interpolate, colorList);

  if(invert) diffColors = diffColors.getInverted();

  var dictionaryObject = {};

  var l = diffColors.length;
  var i;

  for(i=0; i<l; i++){
    dictionaryObject[diffValues[i]] = diffColors[i];
  }

  // diffValues.forEach(function(element, i){
  //   dictionaryObject[element] = diffColors[i];
  // });

  return dictionaryObject;

};
