import OntoMap from "src/MetaExtractor/OntoMap/OntoMap";

/**
 * @classdesc Create OntoMaps
 *
 * @namespace
 * @category ontology
 */
function OntoMapGenerators() {
}
export default OntoMapGenerators;

OntoMapGenerators._colorByType = {
    Table:'#fb8072',
    Column:'#fdb462',
    Entity:'#8dd3c7',
    Attribute:'#bebada',
    Context:'#80b1d3',
    EntityRelation:'#ffffb3'
}

//================================================================================//
// .                        MAIN FUNCTIONS FOR ONTOMAP INSTANTIATION                          //
//================================================================================//

/**
 * Function that creates a new OntoMap Object instantiating the basic properties
 * @param {*} mE 
 */
OntoMapGenerators._createOntoMap = function(mE){
    let oM = new mo.OntoMap();
    mE = mE || {organization: 'noOrganizationInformation'};

    let DW_URI_CLIENT_BASE = oM.DW_URI_CLIENT_BASE + mE.organization + '#';

    this._owl_constructs = oM.CONSTRUCTS;

    oM.setBaseNamespace(mE.organization, DW_URI_CLIENT_BASE);
    return oM;
};

/**
 * Create an OntoMap object from TableExtractor List
 * @param {MetaExtractor} mEL 
 * 
 * @param {OntoMap} oM 
 * 
 * @return {OntoMap} OntoMap 
 * tags:ontology, special
 */
OntoMapGenerators.createOntoMapFromTableListExtractor = function(mEL, oM){

    //get or create the ontoMap to populate
    if( !oM ) oM = OntoMapGenerators._createOntoMap(mEL);

    for(let i=0; i<mEL.attributes.length; i++){
        let mETable = mEL.attributes[i];
        OntoMapGenerators.createOntoMapFromTableExtractor(mETable,oM);
    }

    //build inter table relations
    for(let i=0; i<mEL.relations.length; i++){
        
        let relationName = mEL.relations[i].name;
        let relationColor = mEL.relations[i].color;
        let relationSymetric = mEL.relations[i].directional;
        for(let j=0; j<mEL.relations[i].relations.length; j++){
            let relationArray = mEL.relations[i].relations[j];
            let DWResourceSubject = oM.nodeList.getNodeById(relationArray[0]);
            let DWResourceObject = oM.nodeList.getNodeById(relationArray[1]);
            let DWAttributeSubject = oM.nodeList.getNodeById(relationArray[2]);
            let DWAttributeObject = oM.nodeList.getNodeById(relationArray[3]);
            
            let relationIRI = relationArray[2]+relationArray[3];
            let relationObject = Object.assign({value:relationArray[4]}, mEL.relations[i]);
            let relationPropForNode = ['name','color','value'];
            OntoMapGenerators._declareRelationAsIndividual(oM, relationObject, relationIRI, relationPropForNode, DWAttributeSubject, DWAttributeObject);
            //OntoMapGenerators._declareRelationAsIndividual(oM, mEL.relations[i], relationArray, relationColor, relationName, DWAttributeSubject, DWAttributeObject, relationSymetric);
      
            //manage to create table relations
            let DWResourceRelationIRI =  relationArray[0]+relationArray[1];
            let DWResourcesRelationNode = oM.nodeList.getNodeById(DWResourceRelationIRI);
            if(!DWResourcesRelationNode) {
                relationObject.color = mo.ColorOperators.interpolateColors( relationColor, 'black', 0.6 );
                DWResourcesRelationNode = OntoMapGenerators._declareRelationAsIndividual(oM, relationObject, DWResourceRelationIRI, relationPropForNode, DWAttributeSubject, DWAttributeObject);
            }
            //always add the weight of the relations that already existis
            DWResourcesRelationNode.weight += relationArray[4];
            OntoMapGenerators._owl_dataPropertyAssertion(oM.DW_UNIVERSE_URI+'value', DWResourcesRelationNode, DWResourcesRelationNode.weight);
        }
    }

    return oM;
}

/**
 * Create an OntoMap object from TableExtractor
 * @param {MetaExtractor} mE 
 * 
 * @param {OntoMap} oM 
 * 
 * @return {OntoMap} OntoMap 
 * tags:ontology, special
 */
OntoMapGenerators.createOntoMapFromTableExtractor = function(mE, full_oM){

    //get or create the ontoMap to populate
    //if( !oM ) oM = OntoMapGenerators._createOntoMap(mE);
    if( !full_oM ) full_oM = OntoMapGenerators._createOntoMap(mE);
    let oM = OntoMapGenerators._createOntoMap(mE);

    console.log('createOntoMapFromTableExtractor');
    let DWResourceNode = OntoMapGenerators._owl_declaration(oM, 'NamedIndividual', mE.id);
    DWResourceNode.name = mE.name;
    DWResourceNode.color = OntoMapGenerators._colorByType.Table;
    DWResourceNode.isOrigin = true;
    OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+'Table', DWResourceNode);

    //let specificationName = mE.specification.name != ''? mE.specification.name : 'Unidentified';
    //OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+specificationName, DWResourceNode);
    
    OntoMapGenerators._mELiteralPropertiesMapping(DWResourceNode, mE, oM.DW_UNIVERSE_URI);
    OntoMapGenerators._owl_annotationAssertion(this._owl_constructs.annotations.label, DWResourceNode, mE.name);

    // Iterate the Lists (DWAttrbiutes) to instantiate them and map its Literal properties
    for(let i=0; i<mE.attributes.length; i++) {
        let list_mE = mE.attributes[i];

        // Instantiate
        let DWAttributeNode = OntoMapGenerators._owl_declaration(oM, 'NamedIndividual', list_mE.id);
        DWAttributeNode.isOrigin = true;

        //Map data and annotation properties (literal properties)
        OntoMapGenerators._mELiteralPropertiesMapping(DWAttributeNode, list_mE, oM.DW_UNIVERSE_URI);
        //add name as label attribute
        OntoMapGenerators._owl_annotationAssertion(this._owl_constructs.annotations.label, DWAttributeNode,list_mE.name);

        // Set the node name property
        DWAttributeNode.name = list_mE.name;
        DWAttributeNode.color = OntoMapGenerators._colorByType.Column;
        //DWAttributeNode.color = mo.ColorOperators.stringToColor(list_mE.dimension);
        //DWAttributeNode.color = mo.ColorOperators.interpolateColors( DWAttributeNode.color, 'black', 0.5);
        DWAttributeNode.content = list_mE.dimension;

        // Select the more specific DWAttribute type (dimension)
        let mEDimension = list_mE.dimension;
        let dwColumnType = oM.DW_UNIVERSE_URI;
        switch(mEDimension){
            case 'classification':
                dwColumnType += 'Classification';
                break;
            case 'demographic':
                dwColumnType += 'Demographic';
                break;
            case 'space':
                dwColumnType += 'Space';
                break;
            case 'time':
                dwColumnType += 'Time';
                break;
            case 'identification':
                dwColumnType += 'Identification';
                break;
            case 'count':
                dwColumnType += 'Count';
                break;
            case 'external resource':
                dwColumnType += 'ExternalResource';
                break;
            default:
                dwColumnType += 'UnIdentifiedSpect'
        }


        //assert the two new classes intantiated by DWAttributeNode
        OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+'Column', DWAttributeNode); // <- this will be deleted when inferences engine is working. as it will be inferred
        OntoMapGenerators._owl_classAssertion(dwColumnType, DWAttributeNode);

        OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+'hasColumn', DWResourceNode, DWAttributeNode, oM);

    }

    // Build relations 
    for(let i=0; i<mE.relations.length; i++) {

        let relationType = mE.relations[i].name;

        //avoid is similar from mE
        //if(relationType === 'is similar') continue;
        let relationColor = mE.relations[i].color;
        let relationSymetric = !mE.relations[i].directional;
        for(let j=0; j<mE.relations[i].relations.length; j++) {
            let relation = mE.relations[i].relations[j];
            let DWAttributeSubject = oM.getNodeById( relation[2] );//oM.getNodeById( mE.attributes[relation[2]].id );
            let DWAttributeObject = oM.getNodeById( relation[3] );//oM.getNodeById(  mE.attributes[relation[3]].id );

            //declare relation between similar columns as Object Property
            if(relationType === 'is similar') {
                OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+'isSimiliarColumn', DWAttributeSubject, DWAttributeObject, oM);
            } //when the relation is a Statistical algorithm instantiate the realtion as an INDIVIDUAL
            else {
                let relationIRI = relation[2]+relation[3];
                let relationObject = Object.assign({value:relation[4]}, mE.relations[i]);
                let relationPropForNode = ['name','color','value'];
                let relationIndividual = OntoMapGenerators._declareRelationAsIndividual(oM,relationObject, relationIRI, relationPropForNode, DWAttributeSubject, DWAttributeObject);
                //let relationIndividual = OntoMapGenerators._declareRelationAsIndividual(oM,mE.relations[i], relation,relationColor,relationType, DWAttributeSubject, DWAttributeObject, relationSymetric);
                relationIndividual.isOrigin = true;
            }

        }
    };

    //Map the recently instantiated individuals to DWConcepts individuals
    OntoMapGenerators._dwConceptsMapping(oM, full_oM);

    //After instantiate DWconcepts deliver the new Origin Individuals into the full Ontology     
    if( full_oM ) {
        for(let i=0; i<oM.nodeList.length;i++){
            full_oM.nodeList.addNode( oM.nodeList[i] );
        }
        for(let i=0; i<oM.relationList.length;i++){
            full_oM.relationList.addRelation( oM.relationList[i] );
        }
    }
    
    //console.log('New OntoMap', oM);
    return full_oM;
}

/**
 * Function aimed to create new Individuals from the incoming ontoMap object. 
 * This incoming ontoMap Object will held the instantiation of the Origin Individuals of a Table.
 * 
 * @param {OntoMap} oM working Ontology used to map( detect and create ) new individuals
 * 
 * @return {OntoMap} 
 * tags:ontology, special, ontomap
 */
OntoMapGenerators._dwConceptsMapping = function(oM, full_oM){
    if(oM == null) return;

    let clusters = mo.NetworkOperators.buildNetworkClusters(oM);
    let attributesCollectionsOrphan = [];
    let allEntities = [];
    let allContexts = [];

    //loop through every group of individuals
    for(let i=0; i<clusters.length; i++){
        let listIndividuals = [];
        let listEntity = [];
        let listContext = [];
        let listContextNames = [];
        let listAttributesNames = [];

        let propRanking = new mo.Table();
        propRanking.push( new mo.StringList() );
        propRanking[0].name = "Criteria";
        propRanking.push( new mo.NumberList() );
        propRanking[1].name = "Frequency";
        propRanking.indexDict = {}

        //goes through each individual within the cluster
        for(let j=0; j<clusters[i].length; j++){
            let ontoResource = clusters[i][j];
            let dProp = ontoResource.ontoDataProperties;

            //check if the individual is a List or Column, those are the ones we are interested In
            if( dProp["http://drumwave.com/datacatalog/ontologies/universal#objectType"] === "List") {
                
                let IndividualType = OntoMapGenerators._fieldDWConceptRecognition(ontoResource);
                // Check if the List represent an Entity 
                if(IndividualType === 'Entity'){
                    listEntity.push(ontoResource);
                } else if (IndividualType === 'Context') {
                    listContext.push(ontoResource);
                    listContextNames.push(ontoResource.name); 
                } else if (IndividualType === 'Attribute') {
                    listIndividuals.push(ontoResource);
                    listAttributesNames.push(ontoResource.name);
                }

            } else {
                let indx = propRanking.indexDict[ontoResource.name];
                if(indx == null) {
                    indx = propRanking[0].length;
                    propRanking.indexDict[ontoResource.name] = indx;
                    propRanking[1][indx] = 0;
                }
                propRanking[0][indx] = ontoResource.name;
                propRanking[1][indx]++;
            }
        
        }

        let attributeCluster = propRanking[0].join('-');//[attClustMaxIndex];
        let attributesNames = '(A)'+listAttributesNames.join('-').replace(/\s/g,'');

        let attributeCollectionIndividual = OntoMapGenerators._owl_declaration(full_oM, 'NamedIndividual', attributesNames) ;//mo.MetaExtractor._getHashFromObject({"name":ObjectProperty,"individualName":individual.name}));
        attributeCollectionIndividual.isDWConcept = true;
        attributeCollectionIndividual.isAttribute = true;
        attributeCollectionIndividual.content = attributeCluster;
        attributeCollectionIndividual.color = OntoMapGenerators._colorByType.Attribute;
        attributeCollectionIndividual.label = attributesNames;

        OntoMapGenerators._owl_annotationAssertion(full_oM.CONSTRUCTS.annotations.label, attributeCollectionIndividual, attributesNames);
        OntoMapGenerators._owl_classAssertion(full_oM.DW_UNIVERSE_URI+'Attribute', attributeCollectionIndividual);

        // recognize entities and attributes and attach origins to it
        for(let j=0; j<listIndividuals.length; j++){
            let individual = listIndividuals[j];
            OntoMapGenerators._owl_objectPropertyAssertion(full_oM.DW_UNIVERSE_URI+'hasOrigin',attributeCollectionIndividual,individual, full_oM);
        }

        if(listContext.length){

            let contextAttName = '(C)'+listContextNames.join('-').replace(/\s/g,'');
            let contextAttributeIndividual = OntoMapGenerators._owl_declaration(full_oM, 'NamedIndividual', contextAttName) ;
            contextAttributeIndividual.isDWConcept = true;
            contextAttributeIndividual.isContext = true;
            contextAttributeIndividual.color = OntoMapGenerators._colorByType.Context;
            contextAttributeIndividual.label = attributesNames;

            OntoMapGenerators._owl_annotationAssertion(full_oM.CONSTRUCTS.annotations.label, contextAttributeIndividual, contextAttName);
            OntoMapGenerators._owl_classAssertion(full_oM.DW_UNIVERSE_URI+'Context', contextAttributeIndividual);

            allContexts.push(contextAttributeIndividual);

            for(let j=0; j<listContext.length; j++) {
                let individual = listContext[j];
                OntoMapGenerators._owl_objectPropertyAssertion(full_oM.DW_UNIVERSE_URI+'hasOrigin',contextAttributeIndividual,individual, full_oM);
            }
        }

        if(!listEntity.length) {
            attributesCollectionsOrphan.push([attributeCollectionIndividual, listIndividuals]);
            continue;
        }

        for(let j=0; j<listEntity.length; j++){
            let individual = listEntity[j];
            let individualName = '(E)'+individual.name.replace(/\s/g,'_');
            let EntityIndividual = OntoMapGenerators._owl_declaration(full_oM, 'NamedIndividual', individualName) ;//mo.MetaExtractor._getHashFromObject({"name":ObjectProperty,"individualName":individual.name}));

            EntityIndividual.color = OntoMapGenerators._colorByType.Entity;
            EntityIndividual.label = individualName;
            EntityIndividual.isDWConcept = true;
            EntityIndividual.isEntity = true;
            EntityIndividual.isKey = Boolean(individual.isKey);
            OntoMapGenerators._owl_annotationAssertion(oM.CONSTRUCTS.annotations.label, EntityIndividual, individualName);
            OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+'Entity', EntityIndividual);
            OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+'hasOrigin',EntityIndividual,individual, full_oM);
            OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+'isIdentifierOf',individual,EntityIndividual, full_oM);

            allEntities.push(EntityIndividual);
            //if(!individual.isKey) continue;

            OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+'hasAttribute',EntityIndividual, attributeCollectionIndividual, full_oM);
        }
    }

    //Instantiate Entities relations
    let entitiesRelations = [];
    for(let i=0; i<allEntities.length; i++){
        let ent0 = allEntities[i];
        for(let j=i; j<allEntities.length; j++){
            if(i === j) continue;
            let ent1 = allEntities[j];

            let relationIRI = ent0.id + ent1.id;
            let relationObject = {
                name: 'EntityRelation',
                label: relationIRI,
                content: 'relation between Entities',
                color:OntoMapGenerators._colorByType.EntityRelation
            }
            let relationPropForNode = ['name','label','content','color'];
            let relationObjectProperties = {
                subjectFragment: 'hasRelationCommingIn',
                objectFragment: 'hasRelationGoingOut'
            }
            let entityRelationIndividual = OntoMapGenerators._declareRelationAsIndividual(
                full_oM, relationObject, relationIRI, relationPropForNode, ent0, ent1, relationObjectProperties 
            );

            entityRelationIndividual.isDWConcept = true;
            entityRelationIndividual.isEntityRelation = true;

            entitiesRelations.push(entityRelationIndividual);
        }

    }

    for(let i=0; i<entitiesRelations.length; i++){
        let entityRelationIndividual = entitiesRelations[i];

        for(let j=0; j<allContexts.length; j++){
            let contextIndividual = allContexts[j];
            OntoMapGenerators._owl_objectPropertyAssertion(full_oM.DW_UNIVERSE_URI+'hasContext',entityRelationIndividual ,contextIndividual, full_oM);
        }
    }

    for(let i=0; i<attributesCollectionsOrphan.length; i++){
        let AttCollection = attributesCollectionsOrphan[i][0];
        let attributes = attributesCollectionsOrphan[i][1];

        let attachedToAnEntity = false;
        for(let j=0; j<attributes.length; j++){
            let attr = attributes[j];
            
            for(let k=0; k<attr.toNodeList.length; k++){
                let toNode = attr.toNodeList[k];
                if(toNode.ontoObjectProperties[oM.DW_UNIVERSE_URI_+'isIdentifierOf'] && toNode.ontoObjectProperties[oM.DW_UNIVERSE_URI_+'isIdentifierOf'].length){
                    let entityNodeID = toNode.ontoObjectProperties[oM.DW_UNIVERSE_URI_+'isIdentifierOf'][0];
                    let entityNode = oM.getNodeById(entityNodeID);

                    //attach the attribute to the entity
                    OntoMapGenerators._owl_objectPropertyAssertion(
                        oM.DW_UNIVERSE_URI+'hasAttribute',
                        entityNode,
                        AttCollection,
                        full_oM
                    );
                    attachedToAnEntity = true;
                }
            }
        }

        if(!attachedToAnEntity) {

            for(let i=0; i<allEntities.length; i++){
                let entityNode = allEntities[i];

                if(entityNode.isKey) {
                    //attach the attribute to the entity
                    OntoMapGenerators._owl_objectPropertyAssertion(
                        oM.DW_UNIVERSE_URI+'hasAttribute',
                        entityNode,
                        AttCollection,
                        full_oM
                    );
                    break;
                }
            }
        }
    }
    return full_oM;

}

//================================================================================//
// .                        ENCODE AND DECODE ONTO MAP                            //
//================================================================================//

/**
 * Encodes the OntoMap into a JSON+LD format
 * @param {OntoMap} oM 
 * 
 * @return {JSON} JSON+LD
 * tags:ontology, special
 */
OntoMapGenerators.encodeOntoMapToJSONLD = function(oM){
    if(oM == null) return;

    let jsonld = [];

    let ontologyDeclaration = {
        "@id" : oM.NAMESPACES.base.namespace,
        "@type" : [ 
                "http://www.w3.org/2002/07/owl#Ontology"
        ],
        "http://www.w3.org/2002/07/owl#imports" : [
            {
                "@id" : "http://drumwave.com/datacatalog/ontologies/universal" //Universal Ontology DrumWave
            }
        ]
      }
    
    // build the object
    jsonld.push(ontologyDeclaration);

    // build individuals
    for(let i=0; i<oM.nodeList.length; i++){
        let individual = oM.nodeList[i];
    
        let individualJSONLD = {
            "@id" : individual.IRI.full,
            [oM.NAMESPACES.rdfs.namespace+'label']: individual.name
        } 

        //DataProperties
        let individualDataProperties = Object.keys(individual.ontoDataProperties).reduce(
            (JSONLD_Prop, dataPropertyURI) =>{
                JSONLD_Prop[dataPropertyURI] = [{
                    "@value":String(individual.ontoDataProperties[dataPropertyURI])
                }]
                
                return JSONLD_Prop;
            },
            individualJSONLD
        );

        //ObjectProperties
        let individualObjectProperties = Object.keys(individual.ontoObjectProperties).reduce(
            (JSONLD_Prop, ObjectPropertyURI) =>{

                if( ObjectPropertyURI === 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type') {
                    JSONLD_Prop['@type'] = individual.ontoObjectProperties[ObjectPropertyURI].map( nodeIRI => nodeIRI );
                } else {

                    JSONLD_Prop[ObjectPropertyURI] = individual.ontoObjectProperties[ObjectPropertyURI].map(
                        (nodeIRI) => {
                            return {
                                "@id":nodeIRI//node.IRI.full
                            }
                        }
                    );
                }
                return JSONLD_Prop;
            },
            individualJSONLD
        );


        //Annotation Properties
        let individualAnnotationProperties = Object.keys(individual.ontoAnnotationProperties).reduce(
            (JSONLD_Prop, annotationPropertyURI) =>{
                JSONLD_Prop[annotationPropertyURI] = individual.ontoAnnotationProperties[annotationPropertyURI];
                return JSONLD_Prop;
            },
            {}
        );

        jsonld.push(individualJSONLD);
        //jsonld.push(individualDataProperties);
        //jsonld.push(individualObjectProperties);
        //jsonld.push(individualAnnotationProperties);
    }

    return jsonld;
}

/**
 * Encodes the OntoMap into a turtle format
 * @param {OntoMap} oM 
 * 
 * @param {Boolean} includeImports should the encoding include ontology imports default (false)
 * 
 * @return {String} Turtle string
 * tags:ontology, special
 */
OntoMapGenerators.encodeOntoMapToTurtle = function(oM, includeImports){
    if(oM == null) return;
    console.log('encodeOntoMapToTurtle')
    includeImports = includeImports == null? false:includeImports;

    let temporalPrefixDictionary = {};
    //=============================== PREFIXES DECLARATION ===============================//
    let turtle = "@prefix dw: <"+oM.DW_UNIVERSE_URI+"> .\n";
    temporalPrefixDictionary[oM.DW_UNIVERSE_URI.split('#')[0]] = 'dw';

    turtle += Object.keys(oM.NAMESPACES)
        .map( key => { 
            let obj = oM.NAMESPACES[key];
            let prefix = obj.prefix;//key;//following turtle syntax 
            temporalPrefixDictionary[obj.namespace.split('#')[0]] = prefix;
            return `@prefix ${prefix}: <${obj.namespace}> .` 
        })
        .join('\n');
       
    //=============================== ONTOLOGY DECLARATION AND IMPORT ===============================//
    if(includeImports) turtle += `\n\n<${oM.NAMESPACES.base.namespace}> rdf:type owl:Ontology ;\n\towl:imports${oM.IMPORTS.map( imp => `\t\t<${imp.split('#')[0]}> `).join(',\n')} .\n\n`;
    else turtle += `\n\n<${oM.NAMESPACES.base.namespace}> rdf:type owl:Ontology .\n\n`;


    //=============================== INDIVIDUALS ===============================//
    turtle += "#################################################################\n"+
                "#    Individuals\n"+
                "#################################################################\n\n";


    let jsonld = [];
    //debugger
    // build individuals
    for(let i=0; i<oM.nodeList.length; i++){
        let individual = oM.nodeList[i];
    
        //turtle += `\n#${individual.name}\n${individual.IRI.short}\t${oM.NAMESPACES.rdfs.prefix}:label\t${individual.name}\n`;
        turtle += `\n###${individual.name}\n${individual.IRI.short}`;

        turtle += '\n\t ### object properties \n'
        //ObjectProperties
        Object.keys(individual.ontoObjectProperties).forEach(
            (ObjectPropertyURI) =>{

                if( ObjectPropertyURI === 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type') {
                    turtle += `\trdf:type${individual.ontoObjectProperties[ObjectPropertyURI].map( nodeIRI => `\t\t${temporalPrefixDictionary[nodeIRI.split('#')[0]]+':'+nodeIRI.split('#')[1]} `).join(',\n')} ;\n`
                } else {
                    let objPropertyURI = temporalPrefixDictionary[ObjectPropertyURI.split('#')[0]]+':'+ObjectPropertyURI.split('#')[1];
                    turtle += `\t${objPropertyURI}${individual.ontoObjectProperties[ObjectPropertyURI].map( nodeIRI => `\t\t${temporalPrefixDictionary[nodeIRI.split('#')[0]]+':'+nodeIRI.split('#')[1]}`).join(' ,\n')} ;\n`
                }
            }
        );

        turtle += '\t ### data properties \n'

        //DataProperties
        let dataPropArr = Object.keys(individual.ontoDataProperties).map(
            (dataPropertyURI) =>{
                let dataPropURI = temporalPrefixDictionary[dataPropertyURI.split('#')[0]]+':'+dataPropertyURI.split('#')[1];
                return `\t${dataPropURI}\t"${String(individual.ontoDataProperties[dataPropertyURI])}"`//.map( literalValue => `\t\t${String(literalValue)}`).join(',\n')}`
            }
        );//.join(' ;\n') + ' ;\n';

        if(dataPropArr && dataPropArr.length > 0) {
            turtle += dataPropArr.join(' ;\n') + ' ;\n';
        }

        turtle += '\t ### annotation properties \n'
        //Annotation Properties
        turtle += Object.keys(individual.ontoAnnotationProperties).map(
            (annotationPropertyURI) =>{
                let propURI = temporalPrefixDictionary[annotationPropertyURI.split('#')[0]]+':'+annotationPropertyURI.split('#')[1];
                return `\t${propURI}\t"${String(individual.ontoAnnotationProperties[annotationPropertyURI])}"`
            }
        ).join(" ;\n");

        turtle += ' .\n'
        continue;
    }

    return turtle;
}

/**
 * Decodes a n3 format into an OntoMap 
 * @param {String} n3 
 * 
 * @param {OntoMap} oM  
 * @param {Boolean} includesDWRelationIndividuals   
 * 
 * @return {OntoMap} OntoMap
 * tags:ontology,special
 */
OntoMapGenerators.decodeN3ToOntoMap = function(n3, oM, includesDWRelationIndividuals){
    includesDWRelationIndividuals = includesDWRelationIndividuals || false;
    console.log('decodeN3ToOntoMap');
    //get or create the ontoMap to populate
    if( !oM ) oM = OntoMapGenerators._createOntoMap();
    let lines = n3.split('\n');
    //debugger
    for(let lN = 0; lN<lines.length; lN++){
        if( lines[lN] === '') continue;
        let triple = lines[lN].split(' ');
        let subject = triple.splice(0,1)[0];// extract the first element of the array 
        let predicate = triple.splice(0,1)[0];// again, extract the new first element of the array
        let object = triple.join(' ');// join the remaining values. join by ' ' to revert possible splitting made in the previous split(' ')

        //filter blanknodes
        if(subject.split(':')[0] === '_' || object.split(":")[0] === '_') continue;

        let OntoMapSubjectNode = oM.getNodeById(subject);
        if( !OntoMapSubjectNode && object === 'owl:NamedIndividual.'){
            OntoMapSubjectNode = OntoMapGenerators._owl_declaration(oM, 'NamedIndividual', subject);
            continue;
        } else if(OntoMapSubjectNode) {
           
            if(predicate === 'a')  {
                object = object.split(':')[1].replace(/.$/,'');
                OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+object, OntoMapSubjectNode);

                // give color by type specification
                if( OntoMapSubjectNode.color == null ) OntoMapSubjectNode.color = mo.ColorOperators.stringToColor(object);
                if(object === 'OriginRelation') OntoMapSubjectNode.isRelation = true;

            } else if(predicate === 'rdfs:label') {
                OntoMapSubjectNode.name = object.replace(/[.$\"]/g,'');//.replace(/.$/,'');
                OntoMapGenerators._owl_annotationAssertion(oM.CONSTRUCTS.annotations.label, OntoMapSubjectNode, OntoMapSubjectNode.name);
            } 
            // Blank namespace indicates a working Ontology 
            else if(predicate.split(':')[0] === '') {
                 
                //check data property vs object property
                if(/\"/.test(object)) {

                    let propertyName = predicate.split(':')[1];
                    object = object.replace(/[.$\"]/g,'')
                    OntoMapGenerators._owl_dataPropertyAssertion(oM.DW_UNIVERSE_URI+propertyName, OntoMapSubjectNode, object);
                    if(propertyName === 'color') {
                        let col = object;
                        OntoMapSubjectNode.color = col;//mo.ColorOperators.interpolateColors( col, 'black', 0.5);
                    }
                } else {

                    object = object.replace(/.$/,'');
                    let OntoMapObjectNode = oM.getNodeById(object);
                    if(!OntoMapObjectNode) OntoMapObjectNode = OntoMapGenerators._owl_declaration(oM, 'NamedIndividual', object);

                    let propertyName = predicate.split(':')[1];
                    let relation = OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+propertyName, OntoMapSubjectNode, OntoMapObjectNode, oM);
                    if(relation) relation.color =  mo.ColorOperators.stringToColor(propertyName);
                }
            }
        }

        

    }
    return oM;
}

/**
 * Get a simplified OntoMap networks.
 * Build a network where the Nodes representing relations get turn into Actual relations simplifying the network
 * without relations as Nodes
 * @param {NetWork} ontoMap 
 * 
 * @param  {Boolean} list_list_within builds relations within lists of same table (default:true)
 * @param  {Boolean} list_list_across builds relations of lists across tables (default:true)
 * @param  {Boolean} table_list builds relations between tables and their lists (default:true)
 * @param  {Boolean} table_table builds relations between tables (default:true)
 * 
 * @return {Network} simplifiedOntoMap
 * tags:ontology, special
 */
OntoMapGenerators.getSimplifiedOntoMap = function(oM, list_list_within, list_list_across, table_list, table_table){

    //TODO apply this boolean configurations
    list_list_within = list_list_within || true;
    list_list_across = list_list_across || true;
    table_list =  table_list || true;
    table_table = table_table || true;

    //Clone the network
    let simplifiedNet = oM.clone(["isRelation","color","weight","content","ontoDataProperties"]);

    simplifiedNet.IMPORTS = oM.IMPORTS;
    simplifiedNet.NAMESPACES = oM.NAMESPACES;

    //Replace Relation Nodes by moebio Network relations
    for(i = 0; simplifiedNet.nodeList[i] != null; i++) {
        if(simplifiedNet.nodeList[i].isRelation) {
            let rNode = simplifiedNet.nodeList[i];
            if(rNode.nodeList.length >= 2) {

                let newRelation = OntoMapGenerators._buildOntoRelation(rNode.name, rNode.nodeList[0],  rNode.nodeList[1], simplifiedNet);
                newRelation.color = rNode.color;
                
            }
            simplifiedNet.removeNode(rNode);
            i--;
        }
    }

    //apply relations filters
    for(i = 0; simplifiedNet.relationList[i] != null; i++) {

        let relation = simplifiedNet.relationList[i];
        relation.name = relation.name.split('#')[1]?relation.name.split('#')[1]:relation.name;
        if(!list_list_within) {
 
        }
    }
    return simplifiedNet;
}

//================================================================================//
// .                        DWCONCEPTS DETECTION                                  //
//================================================================================//

/**
 *  
 * @param {Node} resource 
 */
OntoMapGenerators._fieldDWConceptRecognition = function(resource){
    
    if(OntoMapGenerators._entitiyRecognition(resource)) return 'Entity';
    if(OntoMapGenerators._contextRecognition(resource)) return 'Context';
    return 'Attribute';

}

/**
 * Function that recognize a given resource as an Entity
 * @param {Node} resource ontoMap node representing an indiviual
 * 
 * @return {Boolean} It is or not a DWConcept/Entity
 */
OntoMapGenerators._entitiyRecognition = function(resource){

    let dProp = resource.ontoDataProperties;
    let oProp = resource.ontoObjectProperties;
    //Only individuals representing Origin/Lists goes through this process
    if( dProp["http://drumwave.com/datacatalog/ontologies/universal#objectType"] === "List") {

        let isEntityIdentifier = false;
        //First check if it is a Key
        if(dProp["http://drumwave.com/datacatalog/ontologies/universal#allDifferent"] &&
            dProp["http://drumwave.com/datacatalog/ontologies/universal#maxCountCharacters"] <= 255 ) {
            dProp["http://drumwave.com/datacatalog/ontologies/universal#isKey"] = "True";
            isEntityIdentifier = true;
            resource.isKey = true;
        } else if (oProp["http://www.w3.org/1999/02/22-rdf-syntax-ns#type"].indexOf("http://drumwave.com/datacatalog/ontologies/universal#Identification") !== -1 &&
            dProp["http://drumwave.com/datacatalog/ontologies/universal#maxCountCharacters"] <= 255) {
            //isEntityIdentifier = true;
        }

        //Instantiate the individual and attach its origin
        if(isEntityIdentifier){
            return true;
        } 
    }
    return false;
}

/**
 * 
 * @param {Node} resource 
 */
OntoMapGenerators._contextRecognition = function(resource){

    let dProp = resource.ontoDataProperties;
    let oProp = resource.ontoObjectProperties;
    let isContextAttribute = false;
    
    //Only individuals representing Origin/Lists goes through this process
    if( dProp["http://drumwave.com/datacatalog/ontologies/universal#objectType"] === "List") {

        if (oProp["http://www.w3.org/1999/02/22-rdf-syntax-ns#type"].indexOf("http://drumwave.com/datacatalog/ontologies/universal#Time") !== -1 || 
        oProp["http://www.w3.org/1999/02/22-rdf-syntax-ns#type"].indexOf("http://drumwave.com/datacatalog/ontologies/universal#Space") !== -1) {
            isContextAttribute = true;
        }

    }
    return isContextAttribute;
}

//================================================================================//
// .             DATA AND ANNOTATION PROPERTIES DEFINITIONS .                     //
//================================================================================//

OntoMapGenerators._mELiteralPropertiesMapping = function(node, mE, _prefix) {
    if(node == null) return;
    let prefix = _prefix || node.IRI.full || ':';

    for(let k of Object.keys(mE)) {
        if(k === 'relations' || k === 'attributes') continue;

        if(mE[k] instanceof Object && !Array.isArray(mE[k]) ) {
            OntoMapGenerators._mELiteralPropertiesMapping(node, mE[k], prefix);
        }
        else {
            let property = prefix+k;

            if(k === 'name')
                OntoMapGenerators._owl_annotationAssertion(this._owl_constructs.annotations.label, node, mE[k]);
            else
                OntoMapGenerators._owl_dataPropertyAssertion(property, node, mE[k]);
        }
    }
}

//================================================================================//
// .             ONTO MAP RELATIONS AS INDIVIDUAL DECLARATIONS                    //
//================================================================================//

OntoMapGenerators._declareRelationAsIndividual = function(oM, relationObject, relationIRI, propertiesOnNode, DWAttributeSubject, DWAttributeObject, relationObjectProperties) {
    //create the node representing the relation
    let DWRelationNode = OntoMapGenerators._owl_declaration(oM, 'NamedIndividual', relationIRI);
    OntoMapGenerators._mELiteralPropertiesMapping(
        DWRelationNode,
        relationObject,
        oM.DW_UNIVERSE_URI
    );

    //Map the given properties as root properties for the Node
    for(let i=0; i<propertiesOnNode.length; i++) {    
        let prop = propertiesOnNode[i];
        let propVal = relationObject[prop];
        if(propVal) DWRelationNode[prop] = propVal;
    }

    OntoMapGenerators._assertDWRelationIndividual(DWRelationNode, DWAttributeSubject, DWAttributeObject, oM, relationObjectProperties);

    return DWRelationNode;
}

/**
 * This function assert the triples definition for the specific DWRelation individuals.
 * In DW UNIVERSAL ONTOLOGY a relation is a class that holds the information of relations between DWResources, this DWRelation indivudals can be 
 * used to draw inferences upon the ontology resources and attributes.
 * 
 * @param {*} DWRelation 
 * @param {*} DWAttributeSubject 
 * @param {*} DWAttributeObject 
 */
OntoMapGenerators._assertDWRelationIndividual = function(DWRelation, DWAttributeSubject, DWAttributeObject, oM, relationObjectProperties){

    //set a specific property used to later filter relation nodes
    DWRelation.isRelation = true;

    //parse Relation name into DWRelation classes
    let specificRelation;
    switch(DWRelation.name){
        case "Pearson Correlation":
            specificRelation = "Correlation";
            break;
        case "information gain":
            specificRelation = "InfoGain";
            break;
        case "Jaccard":
            specificRelation = "Jaccard";
            break;
        default:
            specificRelation = DWRelation.name;
    }

    //Go specific with relation type, otherwise go generic with DWRelation
    if(specificRelation) OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+specificRelation, DWRelation); 
    //OntoMapGenerators._owl_classAssertion(oM.DW_UNIVERSE_URI+'OriginRelation', DWRelation); 

    //set ObjectProperty relation
    let subjectPropFragment = relationObjectProperties? relationObjectProperties.subjectFragment || "subjectRelatedBy":"subjectRelatedBy";
    let objectPropFragment = relationObjectProperties? relationObjectProperties.objectFragment || "objectRelatedBy":"objectRelatedBy";
    OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+subjectPropFragment, DWAttributeSubject,DWRelation, oM);
    OntoMapGenerators._owl_objectPropertyAssertion(oM.DW_UNIVERSE_URI+objectPropFragment, DWAttributeObject,DWRelation, oM );

}

//================================================================================//
// .             ONTO MAP NETWORK ELEMENT INSTANTIATION                           //
//================================================================================//

/**
 * 
 * @param {String} eT EntityType
 * @param {String} IRI  Internationalized Resource Identifiers (IRIs)  https://www.w3.org/TR/owl2-syntax/#IRIs 
 * @param {String} n name
 */
OntoMapGenerators._buildOntoNode = function( eT, I, oMBaseIri, nodeName){

    let newOntoNode = new mo.Node(I);

    newOntoNode.ontoDataProperties = {};
    newOntoNode.ontoObjectProperties = {};
    newOntoNode.ontoAnnotationProperties = {};

    newOntoNode.entityType = eT;
    newOntoNode.IRI = {
        prefix: oMBaseIri.prefix,
        namespace: oMBaseIri.namespace,
        fragmentIdentifier: I,
        short: oMBaseIri.prefix+':'+I,
        full: oMBaseIri.namespace+I
    }

    return newOntoNode;
}

OntoMapGenerators._buildOntoRelation = function(OPE, a1, a2, oM){

    //OntoMap relation creation
    let simplifiedRelationName = OPE;//.split('#')[1];
    let relation = new mo.Relation(a1.id+'    '+a2.id,simplifiedRelationName, a1, a2);
    relation.color = a2.color;
    oM.addRelation(relation);
    
    let oMBaseIri = oM.NAMESPACES.base;
    relation.IRI = {
        prefix: oMBaseIri.prefix,
        namespace: oMBaseIri.namespace,
        fragmentIdentifier: OPE,
        short: oMBaseIri.prefix+':'+OPE,
        full: oMBaseIri.namespace+OPE
    }
    return relation;
}

//================================================================================//
// .             ONTO MAP Ontology properties assertion in OntoMap Network        //
//================================================================================//

/**
 * This method assert a triple adding a new Value (object) to a List, that represents differents values fo the same property (predicate)
 * of a specific node (subject)
 * 
 * @param {*} s Subject
 * @param {*} p Predicate
 * @param {*} o Object
 */
OntoMapGenerators._assertValueToSubjectPredicate = function( s, p, o) {

    // Use the Object IRI as the object value
    let objectIRI = typeof(o) === 'string'?o:o.IRI.full;

    s.ontoObjectProperties[p] = s.ontoObjectProperties[p] || [];
    if( s.ontoObjectProperties[p].indexOf(objectIRI) === -1) s.ontoObjectProperties[p].push(objectIRI);
}


/**
 * This functions assert a tripple giving one single value (object) to the property (predicate) od the node (subject)
 * @param {*} s Subject
 * @param {*} p Predicate
 * @param {*} o Object
 */
OntoMapGenerators._assertSingleValueToSubjectPredicate = function( s, p, o) {
    s[p] = o;
}

//================================================================================//
// .            OWL METHODS. USES STANDARD DEFINITIONS                            //
//================================================================================//

/**
 * https://www.w3.org/2007/OWL/wiki/Syntax#Entity_Declarations_and_Typing
 * Each IRI I used in an OWL 2 ontology, and in OntoMap oM can be, and sometimes even needs to be, declared in oM;
 * roughly speaking, this means that the axiom closure of oM must contain an appropriate declaration for I.
 * A declaration for I in oM serves two purposes:
 *      - A declaration says that I exists — that is, it says that I is part of the vocabulary of oM.
 *      - A declaration associates with I an entity type — that is, it says whether I is used in oM as a ClassNode,
 *          DatatypeNode, ObjectPropertyNode, DataPropertyNode, AnnotatioPropertyNode, an NamedIndividualNode,
 *          or a combination thereof.
 * 
 * @param {OntoMap} oM ontoMap
 * @param {String} eT entity Type
 * @param {String} I IRI  Internationalized Resource Identifiers (IRIs)  https://www.w3.org/TR/owl2-syntax/#IRIs 
 */
OntoMapGenerators._owl_declaration = function( oM, eT, I){
    if(eT == null || I == null || oM == null) return;

    let newOntoNode = OntoMapGenerators._buildOntoNode(eT, I, oM.NAMESPACES.base);

    let existingOntoNode = oM.getNodeById(newOntoNode.id);
    if( existingOntoNode ) return existingOntoNode;

    OntoMapGenerators._owl_classAssertion(
        this._owl_constructs[eT],
        newOntoNode
    );
    oM.nodeList.addNode(newOntoNode);

    return newOntoNode;
}


/**
 * https://www.w3.org/2007/OWL/wiki/Syntax#Class_Assertions
 * A class assertion ClassAssertion( CE _i ) states that the individual _i is an instance of the class expression CE.
 * @param {Node} CE Class expression
 * @param {Node} _i individual
 */
OntoMapGenerators._owl_classAssertion = function( CE, _i) {
    OntoMapGenerators._assertValueToSubjectPredicate(
        _i,
        this._owl_constructs.isA,
        CE
    );
}

/**
 * https://www.w3.org/TR/owl2-syntax/#Annotation_Assertion
 * An annotation assertion AnnotationAssertion( AP as av ) states that the annotation subject as — an IRI or an anonymous individual — is annotated with the annotation property AP and the annotation value av.
 * @param {*} AP annotation property
 * @param {*} as annotation subject (IRI or anonymous individual)
 * @param {*} av annotation value
 */
OntoMapGenerators._owl_annotationAssertion = function(AP, as, av){
    OntoMapGenerators._assertSingleValueToSubjectPredicate(as.ontoAnnotationProperties, AP, av);
}

/**
 * https://www.w3.org/2007/OWL/wiki/Syntax#Subclass_Axioms
 * A subclass axiom SubClassOf( CE1 CE2 ) states that the class expression CE1 is a subclass of the class expression CE2.
 * Roughly speaking, this states that CE1 is more specific than CE2. Subclass axioms are a fundamental type of axioms in OWL 2 
 * and can be used to construct a class hierarchy. Other kinds of class expression axiom can be seen as syntactic shortcuts 
 * for one or more subclass axioms.
 * 
 * @param {Node} CE1 class expression
 * @param {Node} CE2 class expression
 */
OntoMapGenerators._owl_subClassOf = function( CE1, CE2) { 
    OntoMapGenerators._assertValueToSubjectPredicate(
        CE1,
        OntoMapGenerators._owl_constructs.subClassOf,
        CE2
    );
}

/**
 * https://www.w3.org/2007/OWL/wiki/Syntax#Positive_Data_Property_Assertions
 * States that the individual a is connected by the data property expression DPE to the literal lt.
 * 
 * @param {Node} DPE Data Properrty Expression https://www.w3.org/TR/owl2-syntax/#Data_Properties
 * @param {Node} a individual https://www.w3.org/TR/owl2-syntax/#Individuals
 * @param {*} lt literal https://www.w3.org/TR/owl2-syntax/#Literals
 */
OntoMapGenerators._owl_dataPropertyAssertion = function( DPE, a, lt){ 
    OntoMapGenerators._assertSingleValueToSubjectPredicate(a.ontoDataProperties, DPE, lt);
}

/**
 * https://www.w3.org/2007/OWL/wiki/Syntax#Positive_Object_Property_Assertions
 * A positive object property assertion ObjectPropertyAssertion( OPE a1 a2 )
 * states that the individual a1 is connected by the object property expression OPE to the individual a2.
 * 
 * @param {*} OPE ObjectProperty https://www.w3.org/TR/owl2-syntax/#Object_Properties
 * @param {*} a1 subject Individual
 * @param {*} a2 object Individual
 * @param {OntoMap} oM
 */
OntoMapGenerators._owl_objectPropertyAssertion = function( OPE, a1, a2, oM){ 
    OntoMapGenerators._assertValueToSubjectPredicate(a1, OPE, a2);

    if(!oM) return

    //if has oM build the Network Relation object
    return OntoMapGenerators._buildOntoRelation( OPE, a1, a2, oM);
}    