import NumberList from "src/dataTypes/numeric/NumberList";
import StringList from "src/dataTypes/strings/StringList";
import Table from "src/dataTypes/lists/Table";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import List from "src/dataTypes/lists/List";
import RectangleList from "src/dataTypes/geometry/RectangleList";
import StringOperators from "src/operators/strings/StringOperators";
import { typeOf } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc Create default lists
 *
 * @namespace
 * @category basics
 */
function ListGenerators() {}
export default ListGenerators;


/**
 * Generates a List made of several copies of same element (returned List is improved)
 * @param {Number} nValues length of the List (if a List is provided, it reads its length)
 * @param {Object} element object to be placed in all positions
 *
 * @param {String} name optional name for the list
 * @return {List} generated List
 * tags:generator
 */
ListGenerators.createListWithSameElement = function(nValues, element, name) {
  if(nValues == null) return null;
  if(Array.isArray(nValues)) nValues=nValues.length;

  var list;
  switch(typeOf(element)) {
    case 'number':
      list = new NumberList();
      break;
    case 'List':
      list = new Table();
      break;
    case 'NumberList':
      list = new NumberTable();
      break;
    case 'Rectangle':
      list = new RectangleList();
      break;
    case 'string':
      list = new StringList();
      break;
    case 'boolean':
      list = new List(); //TODO:update once BooleanList exists
      break;
    default:
      list = new List();
  }

  var i;

  for(i = 0; i < nValues; i++) {
    list[i] = element;
  }

  if(name != null)
    list.name = name;
  return list;
};

/**
 * Generates a List built froma seed element and a function that will be applied iteratively
 * @param {Object} nValues length of the List
 * @param {Object} firstElement first element
 * @param {Object} dynamicFunction sequence generator function, elementN+1 =  dynamicFunction(elementN)
 * @return {List} generated List
 * tags:generator
 */
ListGenerators.createIterationSequence = function(nValues, firstElement, dynamicFunction) {
  var list = ListGenerators.createListWithSameElement(1, firstElement);
  for(var i = 1; i < nValues; i++) {
    list[i] = dynamicFunction(list[i - 1]);
  }
  return list;
};

/**
 * Generates a List containing unique elements
 * @param {Number} nValues length of the List
 *
 * @param {Number} style of items to use:<br>0: prefix followed by minimal alpha code (default)<br>1: prefix nnn<br>2: lorem ipsum text (prefix ignored)
 * @param {String} prefix for generated elements, default if not specified is ''
 * @param {String} name optional name for the list
 * @param {Number} nWords number of words for lorem ipsum text (default:5)
 * @return {List} generated List
 * tags:generator
 */
ListGenerators.createListWithUniqueElements = function(nValues, style, prefix, name, nWords) {
  style = style == null ? 0 : style;
  prefix = prefix == null ? '' : prefix;
  nWords = nWords == null ? 5 : nWords;
  var list;
  if(prefix == '' && style == 1)
    list = new NumberList();
  else
    list = new StringList();
  if(name != null)
    list.name = name;

  var i,j;

  if(style == 0){
    // find number of chars required
    var nChars = 1;
    var sChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var len=sChars.length;
    while(nValues > Math.pow(len,nChars)){
      nChars++;
    }
    var nLPos = ListGenerators.createListWithSameElement(nChars,0);
    var s;
    for(i = 0;i < nValues; i++){
      s='';
      for(j = 0;j < nLPos.length;j++)
        s = sChars[nLPos[j]] + s;
      list.push(prefix + s);
      for(j = 0;j < nLPos.length;j++){
        nLPos[j]++;
        if(nLPos[j] != len)
          break;
        nLPos[j] = 0;
      }
    }
  }
  else if(style == 1){
    for(i = 0; i < nValues; i++) {
      if(prefix == '')
        list.push(i+1);
      else
        list.push(prefix + (i+1));
    }
  }
  else if(style == 2){
    for(i = 0; i < nValues; i++) {
      s = StringOperators.WORDLISTS['lorem'].getRandomElements(nWords).join(' ');
      list.push(s);
    }
  }

  return list;
};

