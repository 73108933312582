import { TwoPi, HalfPi } from "src/Global";
import Rectangle from "src/dataTypes/geometry/Rectangle";
import Point from "src/dataTypes/geometry/Point";
import IntervalTableOperators from "src/operators/numeric/interval/IntervalTableOperators";
import NumberList from "src/dataTypes/numeric/NumberList";
import NumberTableFlowOperators from "src/operators/numeric/numberTable/NumberTableFlowOperators";
import ColorScales from "src/operators/graphic/ColorScales";
import ColorListGenerators from "src/operators/graphic/ColorListGenerators";
import IntervalTableDraw from "src/visualization/numeric/IntervalTableDraw";
import GeometryOperators from "src/operators/geometry/GeometryOperators";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import Table from "src/dataTypes/lists/Table";
import ColorList from "src/dataTypes/graphic/ColorList";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import TableOperators from "src/operators/lists/TableOperators";
import Polygon from "src/dataTypes/geometry/Polygon";
import Interval from "src/dataTypes/numeric/Interval";
import NumberOperators from "src/operators/numeric/NumberOperators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import RectangleList from "src/dataTypes/geometry/RectangleList";
import StringList from "src/dataTypes/strings/StringList";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import DrawTexts from "src/tools/graphic/DrawTexts";
import RectangleOperators from "src/operators/geometry/RectangleOperators";

function NumberTableDraw() {}
export default NumberTableDraw;

/**
 * draws a matrix, with cells colors associated to values from a ColorScale
 * @param  {Rectangle} frame
 * @param  {NumberTable} numberTable
 *
 * @param  {ColorScale} colorScale
 * @param  {Boolean} listColorsIndependent if true each numberList will be colored to fit the colorScale range
 * @param  {Number} margin
 * @return {Point}
 * tags:draw
 */
NumberTableDraw.drawNumberTable = function(frame, numberTable, colorScale, listColorsIndependent, margin, graphics) {
  if(frame == null ||  numberTable == null || numberTable.type == null || numberTable.type != "NumberTable" ||  numberTable.length < 2) return null;

  if(graphics==null) graphics = frame.graphics; //momentary fix

  colorScale = colorScale == null ? ColorScales.blueToRed : colorScale;
  listColorsIndependent = listColorsIndependent || false;
  margin = margin == null ? 2 : margin;

  var dX = frame.width / numberTable.length;
  var dY = frame.height / numberTable[0].length;

  var i;
  var j;
  var numberList;
  var x;

  var overCoordinates;

  var minMaxInterval;
  var amp;
  if(!listColorsIndependent) {
    minMaxInterval = numberTable.getInterval();
    amp = minMaxInterval.getAmplitude();
  }

  var mouseXOnColumn;

  var n = numberTable.length;
  var nR;

  for(i = 0; i<n; i++) {
    numberList = numberTable[i];
    x = Math.round(frame.x + i * dX);
    mouseXOnColumn = graphics.mX > x && graphics.mX <= x + dX;
    if(listColorsIndependent) {
      minMaxInterval = numberList.getInterval();
      amp = minMaxInterval.getAmplitude();
    }
    nR = numberList.length;
    for(j = 0; j<nR; j++) {
      graphics.context.fillStyle = colorScale((numberList[j] - minMaxInterval.x) / amp);
      graphics.context.fillRect(x, Math.round(frame.y + j * dY), Math.ceil(dX) - margin, Math.ceil(dY) - margin);
      if(mouseXOnColumn && graphics.mY > frame.y + j * dY && graphics.mY <= frame.y + (j + 1) * dY) overCoordinates = new Point(i, j);
    }
  }

  return overCoordinates;
};

/**
 * draws a ScatterPlot, if the provided NumberTable contains a third NumberList it also draws circles
 * @param  {Rectangle} frame
 * @param  {NumberTable} numberTable with two lists
 *
 * @param  {StringList} texts
 * @param  {ColorList} colors
 * @param  {Number} maxRadius
 * @param  {Boolean} loglog logarithmical scale for both axis
 * @param  {Number} margin in pixels
 * @return {Number} index of rollovered element
 * tags:draw
 */
NumberTableDraw.drawSimpleScatterPlot = function(frame, numberTable, texts, colors, maxRadius, loglog, margin, graphics) {
  if(frame == null ||  numberTable == null || numberTable.type != "NumberTable" ||  numberTable.length < 2 ||  numberTable[0].length === 0 || numberTable[1].length === 0) return; //todo:provisional, this is System's work

  if(graphics==null) graphics = frame.graphics; //momentary fix

  if(numberTable.length < 2) return;

  maxRadius = maxRadius || 20;
  loglog = loglog || false;
  margin = margin || 0;

  var subframe = new Rectangle(frame.x + margin, frame.y + margin, frame.width - margin * 2, frame.height - margin * 2);
  subframe.bottom = subframe.getBottom();

  var i;
  var x, y;
  var list0 = NumberListOperators.normalized(loglog ? numberTable[0].log(1) : numberTable[0]);
  var list1 = (loglog ? numberTable[1].log(1) :  NumberListOperators.normalized(numberTable[1]));
  var radii = numberTable.length <= 2 ? null :  NumberListOperators.normalized(numberTable[2]).sqrt().factor(maxRadius);
  var nColors = (colors == null) ? null : colors.length;
  var n = Math.min(list0.length, list1.length, (radii == null) ? 300000 : radii.length, (texts == null) ? 300000 : texts.length);
  var iOver;

  for(i = 0; i < n; i++) {
    x = subframe.x + list0[i] * subframe.width;
    y = subframe.bottom - list1[i] * subframe.height;

    if(radii == null) {
      if(NumberTableDraw._drawCrossScatterPlot(x, y, colors == null ? 'rgb(150,150,150)' : colors[i % nColors],graphics)) iOver = i;
    } else {
      graphics.setFill(colors == null ? 'rgb(150,150,150)' : colors[i % nColors]);
      if(graphics.fCircleM(x, y, radii[i], radii[i] + 1)) iOver = i;
    }
    if(texts != null) {
      graphics.setText('black', 10);
      graphics.fText(texts[i], x, y);
    }
  }

  if(margin > 7 && list0.name !== "" && list1.name !== "") {
    graphics.setText('black', 10, null, 'right', 'middle');
    graphics.fText(list0.name, subframe.getRight() - 2, subframe.bottom + margin * 0.5);
    graphics.fTextRotated(list1.name, subframe.x - margin * 0.5, subframe.y + 1, -HalfPi);
  }

  if(iOver != null) {
    graphics.setCursor('pointer');
    return iOver;
  }
};

NumberTableDraw._drawCrossScatterPlot = function(x, y, color, graphics) {
  graphics.setStroke(color, 1);
  graphics.line(x, y - 2, x, y + 2);
  graphics.line(x - 2, y, x + 2, y);
  return Math.pow(graphics.mX - x, 2) + Math.pow(graphics.mY - y, 2) < 25;
};

/**
 * draws a slopegraph
 * @param  {Rectangle} frame
 * @param  {NumberTable} numberTable with at least two numberLists
 * @param  {StringList} texts
 * @return {Object}
 */
NumberTableDraw.drawSlopeGraph = function(frame, numberTable, texts, graphics) {
  if(frame == null ||  numberTable == null || numberTable.type != "NumberTable") return; //todo:provisional, this is System's work

  if(numberTable.length < 2) return;

  var margin = 16;
  var subframe = new Rectangle(frame.x + margin, frame.y + margin, frame.width - margin * 2, frame.height - margin * 2);
  subframe.bottom = subframe.getBottom();

  var i;
  var y0, y1;
  var list0 =  NumberListOperators.normalized(numberTable[0]);
  var list1 =  NumberListOperators.normalized(numberTable[1]);
  var n = Math.min(list0.length, list1.length, texts == null ? 2000 : texts.length);

  var x0 = subframe.x + (texts == null ? 10 : 0.25 * subframe.width);
  var x1 = subframe.getRight() - (texts == null ? 10 : 0.25 * subframe.width);

  graphics.setStroke('black', 1);

  for(i = 0; i < n; i++) {
    y0 = subframe.bottom - list0[i] * subframe.height;
    y1 = subframe.bottom - list1[i] * subframe.height;

    graphics.line(x0, y0, x1, y1);

    if(texts != null && (subframe.bottom - y0) >= 9) {
      graphics.setText('black', 9, null, 'right', 'middle');
      graphics.fText(texts[i], x0 - 2, y0);
    }
    if(texts != null && (subframe.bottom - y1) >= 9) {
      graphics.setText('black', 9, null, 'left', 'middle');
      graphics.fText(texts[i], x1 + 2, y1);
    }
  }
};


/**
 * based on a integers NumberTable draws a a matrix of rectangles with colors associated to number of elelments in overCoordinates
 * @param  {Rectangle} frame
 * @param  {Object} coordinates, it could be a polygon, or a numberTable with two lists
 *
 * @param  {ColorScale} colorScale
 * @param  {Number} margin
 * @return {NumberList} list of positions of elements on clicked coordinates
 * tags:draw
 */
NumberTableDraw.drawDensityMatrix = function(frame, coordinates, colorScale, margin, graphics) {
  if(coordinates == null || coordinates[0] == null) return;

  colorScale = colorScale == null ?
								ColorScales.whiteToRed
								:
    typeof colorScale == 'string' ?
									ColorScales[colorScale]
									:
    colorScale;
  margin = margin || 0;

  var i, j;
  var x, y;
  var minx, miny;
  var matrixColors;
  var numberTable;
  var polygon;

  //setup
  if(frame.memory == null || coordinates != frame.memory.coordinates || colorScale != frame.memory.colorScale) {

    var isNumberTable = coordinates[0].x == null;
    if(isNumberTable) {
      numberTable = coordinates;
      if(numberTable == null ||  numberTable.length < 2 || numberTable.type != "NumberTable") return;
    } else {
      polygon = coordinates;
    }

    var max = 0;
    var nCols = 0;
    var nLists = 0;
    var matrix = new NumberTable();
    var n = isNumberTable ? numberTable[0].length : polygon.length;

    matrixColors = new Table();

    if(isNumberTable) {
      minx = numberTable[0].getMin();
      miny = numberTable[1].getMin();
    } else {
      minx = polygon[0].x;
      miny = polygon[0].y;
      for(i = 1; i < n; i++) {
        minx = Math.min(polygon[i].x, minx);
        miny = Math.min(polygon[i].y, miny);
      }
    }


    for(i = 0; i < n; i++) {
      if(isNumberTable) {
        x = Math.floor(numberTable[0][i] - minx);
        y = Math.floor(numberTable[1][i] - miny);
      } else {
        x = Math.floor(polygon[i].x - minx);
        y = Math.floor(polygon[i].y - miny);
      }

      if(matrix[x] == null) matrix[x] = new NumberList();
      if(matrix[x][y] == null) matrix[x][y] = 0;
      matrix[x][y]++;
      max = Math.max(max, matrix[x][y]);
      nCols = Math.max(nCols, x + 1);
      nLists = Math.max(nLists, y + 1);
    }

    for(i = 0; i < nCols; i++) {
      if(matrix[i] == null) matrix[i] = new NumberList();
      matrixColors[i] = new ColorList();
      for(j = 0; j < nLists; j++) {
        if(matrix[i][j] == null) matrix[i][j] = 0;
        matrixColors[i][j] = colorScale(matrix[i][j] / max);
      }
    }
    frame.memory = {
      matrixColors: matrixColors,
      coordinates: coordinates,
      colorScale: colorScale,
      selected: null
    };

  } else {
    matrixColors = frame.memory.matrixColors;
  }

  //c.log(matrixColors.length, matrixColors[0].length, matrixColors[0][0]);

  //draw
  var subframe = new Rectangle(frame.x + margin, frame.y + margin, frame.width - margin * 2, frame.height - margin * 2);
  subframe.bottom = subframe.getBottom();
  var dx = subframe.width / matrixColors.length;
  var dy = subframe.height / matrixColors[0].length;
  var prevSelected = frame.memory.selected;

  if(graphics.MOUSE_UP_FAST) frame.memory.selected = null;

  for(i = 0; matrixColors[i] != null; i++) {
    for(j = 0; matrixColors[0][j] != null; j++) {
      graphics.setFill(matrixColors[i][j]);
      if(graphics.fRectM(subframe.x + i * dx, subframe.bottom - (j + 1) * dy, dx + 0.5, dy + 0.5) && graphics.MOUSE_UP_FAST) {
        frame.memory.selected = [i, j];
      }
    }
  }


  //selection
  if(frame.memory.selected) {
    graphics.setStroke('white', 5);
    graphics.sRect(subframe.x + frame.memory.selected[0] * dx - 1, subframe.bottom - (frame.memory.selected[1] + 1) * dy - 1, dx + 1, dy + 1);
    graphics.setStroke('black', 1);
    graphics.sRect(subframe.x + frame.memory.selected[0] * dx - 1, subframe.bottom - (frame.memory.selected[1] + 1) * dy - 1, dx + 1, dy + 1);
  }

  if(prevSelected != frame.memory.selected) {
    if(frame.memory.selected == null) {
      frame.memory.indexes = null;
    } else {
      minx = numberTable[0].getMin();
      miny = numberTable[1].getMin();

      x = frame.memory.selected[0] + minx;
      y = frame.memory.selected[1] + miny;

      frame.memory.indexes = new NumberList();

      for(i = 0; numberTable[0][i] != null; i++) {
        if(numberTable[0][i] == x && numberTable[1][i] == y) frame.memory.indexes.push(i);
      }
    }

  }

  if(frame.memory.selected) return frame.memory.indexes;
};

/**
 * draws a steamgraph
 * @param  {Rectangle} frame
 * @param  {NumberTable} numberTable
 *
 * @param {Boolean} normalized normalize each column, making the graph of constant height
 * @param {Boolean} sorted sort flow polygons
 * @param {Number} intervalsFactor number between 0 and 1, factors the height of flow polygons
 * @param {Boolean} bezier draws bezier (soft) curves
 * @param {ColorList} colorList colors of polygons
 * @param {StringList} horizontalLabels to be placed in the bottom
 * @param {Boolean} showValues show values in the stream
 * @param {Number} logFactor if >0 heights will be transformed logaritmically log(logFactor*val + 1)
 * @param {String} backgroundColor
 * @param {String} textColor
 * @return {NumberList} list of positions of elements on clicked coordinates
 * tags:draw
 * examples:santiago/experiments/GDPR
 */
NumberTableDraw.drawStreamgraph = function(frame, numberTable, normalized, sorted, intervalsFactor, bezier, colorList, horizontalLabels, showValues, logFactor, backgroundColor, textColor, graphics) {
  if(numberTable == null ||  numberTable.length < 2 || numberTable.type != "NumberTable") return;

  if(graphics==null) graphics = frame.graphics; //momentary fix

  bezier = bezier == null ? true : bezier;

  textColor = textColor == null ? 'white' : textColor;

  //var self = NumberTableDraw.drawStreamgraph;

  intervalsFactor = intervalsFactor == null ? 1 : intervalsFactor;

  //setup
  if(frame.memory == null || numberTable != frame.memory.numberTable || normalized != frame.memory.normalized || sorted != frame.memory.sorted || intervalsFactor != frame.memory.intervalsFactor || bezier != frame.memory.bezier || frame.width != frame.memory.width || frame.height != frame.memory.height || logFactor != frame.memory.logFactor) {
		var nT2 = logFactor?numberTable.applyFunction(function(val){return Math.log(logFactor*val+1);}):numberTable;

    frame.memory = {
      numberTable: numberTable,
      normalized: normalized,
      sorted: sorted,
      intervalsFactor: intervalsFactor,
      bezier: bezier,
      flowIntervals: IntervalTableOperators.scaleIntervals(NumberTableFlowOperators.getFlowTableIntervals(nT2, normalized, sorted), intervalsFactor),
      fOpen: 1,
      names: numberTable.getNames(),
      mXF: graphics.mX,
      width: frame.width,
      height: frame.height,
      logFactor: logFactor,
      image: null
    };

  }

  if(colorList && frame.memory.colorList != colorList) frame.memory.image = null;

  if(frame.memory.colorList != colorList || frame.memory.colorList == null) {
    frame.memory.actualColorList = colorList == null ? ColorListGenerators.createDefaultCategoricalColorList(numberTable.length, 0.7) : colorList;
    frame.memory.colorList = colorList;
  }

  var flowFrame = new Rectangle(0, 0, frame.width, horizontalLabels == null ? frame.height : (frame.height - 14));
  flowFrame.graphics = graphics;

  if(backgroundColor!=null){
    graphics.setFill(backgroundColor);
    graphics.fRect(frame.x, frame.y, frame.width, frame.height);
  }

  if(frame.memory.image == null) {
    //frame.memory.image = new Image(10,10);
    // TODO refactor to not reassign context
    ///// capture image
    var newCanvas = document.createElement("canvas");
    newCanvas.width = frame.width;
    newCanvas.height = frame.height;
    var newContext = newCanvas.getContext("2d");
    newContext.clearRect(0, 0, frame.width, frame.height);
    var mainContext = graphics.context;
    graphics.context = newContext;
    IntervalTableDraw.drawIntervalsFlowTable(frame.memory.flowIntervals, flowFrame, frame.memory.actualColorList, bezier, 0.3);
    graphics.context = mainContext;
    frame.memory.image = new Image();
    frame.memory.image.src = newCanvas.toDataURL();
    /////
  }

  var x0, x1;
  if(frame.memory.image) {
    frame.memory.fOpen = 0.8 * frame.memory.fOpen + 0.2 * (frame.containsPoint(graphics.mP) ? 0.8 : 1);
    frame.memory.mXF = 0.7 * frame.memory.mXF + 0.3 * graphics.mX;
    frame.memory.mXF = Math.min(Math.max(frame.memory.mXF, frame.x), frame.getRight());

    if(frame.memory.fOpen < 0.999) {
      graphics.context.save();
      graphics.context.translate(frame.x, frame.y);
      var cut = frame.memory.mXF - frame.x;
      x0 = Math.floor(cut * frame.memory.fOpen);
      x1 = Math.ceil(frame.width - (frame.width - cut) * frame.memory.fOpen);

      graphics.drawImage(frame.memory.image, 0, 0, cut, flowFrame.height, 0, 0, x0, flowFrame.height);
      graphics.drawImage(frame.memory.image, cut, 0, (frame.width - cut), flowFrame.height, x1, 0, (frame.width - cut) * frame.memory.fOpen, flowFrame.height);

      NumberTableDraw._drawPartialFlow(flowFrame, frame.memory.flowIntervals, frame.memory.names, frame.memory.actualColorList, cut, x0, x1, 0.3, sorted, showValues ? numberTable : null, textColor);

      graphics.context.restore();
    } else {
      graphics.drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
    }
  }

  if(horizontalLabels) NumberTableDraw._drawHorizontalLabels(frame, frame.getBottom() - 5, numberTable, horizontalLabels, x0, x1);
};

NumberTableDraw._drawHorizontalLabels = function(frame, y, numberTable, horizontalLabels, x0, x1, graphics) {
  if(graphics==null) graphics = frame.graphics; //momentary fix

  var dx = frame.width / (numberTable[0].length - 1);
  var x;
  var mX2 = Math.min(Math.max(graphics.mX, frame.x + 1), frame.getRight() - 1);
  var iPosDec = (mX2 - frame.x) / dx;
  var iPos = Math.round(iPosDec);

  x0 = x0 == null ? frame.x : x0 + frame.x;
  x1 = x1 == null ? frame.x : x1 + frame.x;

  horizontalLabels.forEach(function(label, i) {
    graphics.setText('black', (i == iPos && x1 > (x0 + 4)) ? 14 : 10, null, 'center', 'middle');

    if(x0 > x1 - 5) {
      x = frame.x + i * dx;
    } else if(iPos == i) {
      x = (x0 + x1) * 0.5 - (x1 - x0) * (iPosDec - iPos);
    } else {
      x = frame.x + i * dx;
      if(x < mX2) {
        x = frame.x + i * dx * frame.memory.fOpen;
      } else if(x > mX2) {
        x = frame.x + i * dx * frame.memory.fOpen + (x1 - x0);
      }
    }
    graphics.fText(horizontalLabels[i], x, y);
  });
};

NumberTableDraw._drawPartialFlow = function(frame, flowIntervals, labels, colors, x, x0, x1, OFF_X, sorted, numberTable, textColor, graphics) {
  if(graphics==null) graphics = frame.graphics; //momentary fix

  var w = x1 - x0;
  var wForText = numberTable == null ? (x1 - x0) : (x1 - x0) * 0.85;

  var nDays = flowIntervals[0].length;

  var wDay = frame.width / (nDays - 1);

  var iDay = (x - frame.x) / wDay; // Math.min(Math.max((nDays-1)*(x-frame.x)/frame.width, 0), nDays-1);
  iDay = Math.max(Math.min(iDay, nDays - 1), 0);

  var i;
  var i0 = Math.floor(iDay);
  var i1 = Math.ceil(iDay);

  var interval0;
  var interval1;

  var y, h;

  var wt;
  var pt;

  var text;

  var offX = OFF_X * wDay; //*(frame.width-(x1-x0))/nDays; //not taken into account

  //var previOver = iOver;
  var iOver = -1;

  var X0, X1, xx;

  var ts0, ts1;

  for(i = 0; flowIntervals[i] != null; i++) {

    graphics.setFill(colors[i]);
    interval0 = flowIntervals[i][i0];
    interval1 = flowIntervals[i][i1];

    X0 = Math.floor(iDay) * wDay;
    X1 = Math.floor(iDay + 1) * wDay;

    xx = x;

    y = GeometryOperators.trueBezierCurveHeightHorizontalControlPoints(X0, X1, interval0.x, interval1.x, X0 + offX, X1 - offX, xx);
    h = GeometryOperators.trueBezierCurveHeightHorizontalControlPoints(X0, X1, interval0.y, interval1.y, X0 + offX, X1 - offX, xx) - y;

    y = y * frame.height + frame.y;
    h *= frame.height;

    //if(h<1) continue;

    if(graphics.fRectM(x0, y, w, h)) iOver = i;

    if(h >= 5 && w > 40) {
      graphics.setText(textColor, h, null, null, 'middle');

      text = labels[i];

      wt = graphics.getTextW(text);
      pt = wt / wForText;

      if(pt > 1) {
        graphics.setText(textColor, h / pt, null, null, 'middle');
      }

      graphics.context.fillText(text, x0, y + h * 0.5);

      if(numberTable) {
        wt = graphics.getTextW(text);

        ts0 = Math.min(h, h / pt);
        ts1 = Math.max(ts0 * 0.6, 8);

        graphics.setText(textColor, ts1, null, null, 'middle');
        graphics.fText(Math.round(numberTable[i][i0]), x0 + wt + w * 0.03, y + (h + (ts0 - ts1) * 0.5) * 0.5);
      }


    }
  }

  return iOver;
};



/**
 * draws a circular steamgraph Without labels
 * @param {Rectangle} frame
 * @param {Table} dataTable table with a categorical list and many numberLists
 *
 * @param {Boolean} normalized normalize each column, making the graph of constant height
 * @param {Boolean} sorted sort flow polygons
 * @param {Number} intervalsFactor number between 0 and 1, factors the height of flow polygons
 * @param {ColorList} colorList colors of polygons
 * @return {Number} index of position of element on first list associated with shape
 * tags:draw
 */
NumberTableDraw.drawCircularStreamgraph = function(frame, dataTable, normalized, sorted, intervalsFactor, colorList, graphics) {
  if(dataTable == null ||  dataTable.length < 3 || dataTable[0].length < 2) return;

  if(graphics==null) graphics = frame.graphics; //momentary fix

  intervalsFactor = intervalsFactor == null ? 1 : intervalsFactor;

  var over;

  //setup
  if(frame.memory == null || dataTable != frame.memory.dataTable || normalized != frame.memory.normalized || sorted != frame.memory.sorted || intervalsFactor != frame.memory.intervalsFactor || frame.width != frame.memory.width || frame.height != frame.memory.height) {
    var numberTable = TableOperators.getNumberTableFromTable(dataTable);
    
    if(numberTable.length<2) return;

    numberTable = numberTable.getTransposed();

    var names = dataTable.getNames().slice(1);
    if(names!=null && names.join('')==='') names = null;

    frame.memory = {
      dataTable:dataTable,
      numberTable: numberTable,
      categories:dataTable[0],
      names:names,
      normalized: normalized,
      sorted: sorted,
      intervalsFactor: intervalsFactor,
      flowIntervals: IntervalTableOperators.scaleIntervals(NumberTableFlowOperators.getFlowTableIntervals(numberTable, normalized, sorted), intervalsFactor),
      fOpen: 1,
      mXF: graphics.mX,
      width: frame.width,
      height: frame.height,
      radius: Math.min(frame.width, frame.height) * 0.46 - (names == null ? 0 : 8),
      r0: Math.min(frame.width, frame.height) * 0.05,
      angles: new NumberList(),
      zoom: 1,
      angle0: 0,
      image: null
    };

    var dA = TwoPi / numberTable[0].length;
    numberTable[0].forEach(function(val, i) {
      frame.memory.angles[i] = i * dA;
    });
  }

  if(frame.memory.colorList != colorList || frame.memory.colorList == null) {
    frame.memory.actualColorList = colorList == null ? ColorListGenerators.createDefaultCategoricalColorList(frame.memory.numberTable.length, 0.4) : colorList;
    frame.memory.colorList = colorList;
  }

  var mouseOnFrame = frame.containsPoint(graphics.mP);

  if(mouseOnFrame) {
    if(graphics.MOUSE_DOWN) {
      frame.memory.downX = graphics.mX;
      frame.memory.downY = graphics.mY;
      frame.memory.pressed = true;
      frame.memory.zoomPressed = frame.memory.zoom;
      frame.memory.anglePressed = frame.memory.angle0;
    }

    frame.memory.zoom *= (1 - 0.4 * graphics.WHEEL_CHANGE);
  }

  if(graphics.MOUSE_UP) frame.memory.pressed = false;
  if(frame.memory.pressed) {
    var center = frame.getCenter();
    var dx0 = frame.memory.downX - center.x;
    var dy0 = frame.memory.downY - center.y;
    var d0 = Math.sqrt(Math.pow(dx0, 2) + Math.pow(dy0, 2));
    var dx1 = graphics.mX - center.x;
    var dy1 = graphics.mY - center.y;
    var d1 = Math.sqrt(Math.pow(dx1, 2) + Math.pow(dy1, 2));
    frame.memory.zoom = frame.memory.zoomPressed * ((d1 + 5) / (d0 + 5));
    var a0 = Math.atan2(dy0, dx0);
    var a1 = Math.atan2(dy1, dx1);
    frame.memory.angle0 = frame.memory.anglePressed + a1 - a0;
  }

  if(mouseOnFrame) frame.memory.image = null;

  var captureImage = false;// frame.memory.image == null && !mouseOnFrame;
  var drawingImage = false;// !mouseOnFrame && frame.memory.image != null &&  !captureImage;

  if(drawingImage) {
    graphics.drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
  } else {
    if(captureImage) {
      // TODO refactor to not reassign context

      // var newCanvas = document.createElement("canvas");
      // newCanvas.width = frame.width;
      // newCanvas.height = frame.height;
      // var newContext = newCanvas.getContext("2d");
      // newContext.clearRect(0, 0, frame.width, frame.height);
      // var mainContext = context;
      // context = newContext;
      // var prevFx = frame.x;
      // var prevFy = frame.y;
      // frame.x = 0;
      // frame.y = 0;
      // setFill('white');
      // fRect(0, 0, frame.width, frame.height);
    }

    graphics.context.save();
    graphics.clipRect(frame.x, frame.y, frame.width, frame.height);

    over = IntervalTableDraw.drawCircularIntervalsFlowTable(frame, frame.memory.flowIntervals, frame.getCenter(), frame.memory.radius * frame.memory.zoom, frame.memory.r0, frame.memory.actualColorList, frame.memory.categories, true, frame.memory.angles, frame.memory.angle0);
    
    frame.memory.zoom = Math.max(Math.min(frame.memory.zoom, 2000), 0.08);

    if(frame.memory.names) {
      var a;
      var r = frame.memory.radius * frame.memory.zoom + 8;

      graphics.setText('black', 14, null, 'center', 'middle');

      frame.memory.names.forEach(function(name, i) {
        a = frame.memory.angle0 + frame.memory.angles[i];

        graphics.fTextRotated(String(name), frame.getCenter().x + r * Math.cos(a), frame.getCenter().y + r * Math.sin(a), a + HalfPi);
      });
    }

    graphics.context.restore();


    if(captureImage) {
      // TODO refactor to not reassign context
      // context = mainContext;
      // frame.memory.image = new Image();
      // frame.memory.image.src = newCanvas.toDataURL();
      // frame.x = prevFx;
      // frame.y = prevFy;
      // drawImage(frame.memory.image, frame.x, frame.y, frame.width, frame.height);
    }
  }

  return over;
};

/**
 * draws a Line Chart, one line for each column in the table
 * @param  {Rectangle} frame
 * @param  {NumberTable} numberTable with each column representing a line in the chart
 *
 * @param  {Boolean} bxAxis if true show an x axis (default: true)
 * @param  {Boolean} byAxis if true show a  y axis (default: true)
 * @param  {String} sxLabel is the label for the x axis
 * @param  {String} syLabel is the label for the y axis
 * @param  {Number} padding in pixels (default:1)
 * @param  {String} colors for series. Many input options<br>0: unique categorical color (default)<br>colorString: all series will use this color<br>ColorList or StringList: each series will use the corresponding color
 * @param  {Number} pointStyle is how to show points<|>0: do not show (default)<|>1: filled circles<|>2: open circles<|>3: show an x<|>4: filled circles at mouse period only
 * @param  {Number} lineStyle is how to show the curve<|>0: do not show<|>1: straight lines (default)<|>2: smoothed lines
 * @param  {Number} labelStyle is how to show labels<|>0: do not show<|>1: on right, as many as possible without overlap (default)<|>2: hovered only, near mouse
 * @param  {List} xAxisList is a list giving the values for the x-axis. Can be a StringList
 * @param  {Object} graphics object to use for drawing
 * @param  {Boolean} byLog if true use a logarithmic y axis (default: false)
 * @param  {Number} fixedRightLabelWidth is the width to use on the right for labelStyle right (default: use the longest label)
 * @param  {Number} minYRange is the minimum value to use in the plot on the y axis (default: use the smallest y value in the data)
 * @param  {Number} maxYRange is the maximum value to use in the plot on the y axis (default: use the largest y value in the data)
 * @param  {Number} tmTooltip is the tooltip delay.<br>-1: no tooltip (default)<br>nnnn: milliseconds after mousemove to show
 * @param  {NumberList} nLSeriesStyle is a list of styles, one for each series<br>Values are 0: faded, 1: normal, 2: bold
 * @return {Number} index of line hovered over
 * tags:deprecated,draw
 * examples: jeff/examples/drawSimpleLineChart
 */
NumberTableDraw.drawSimpleLineChart = function(frame, table, bxAxis, byAxis, sxLabel, syLabel, padding, colors, pointStyle, lineStyle, labelStyle, xAxisList, graphics, byLog, fixedRightLabelWidth, minYRange, maxYRange, tmTooltip, nLSeriesStyle) {
  if(frame == null || table == null) return;
  // allow a single NumberList also
  if(table.type == "NumberList"){
    var temp = new NumberTable();
    temp.push(table);
    table = temp;
  }
  if(table.type != "NumberTable" || table[0].length === 0) return;

  if(graphics==null || graphics.cW === undefined) graphics = frame.graphics;
  padding = padding == null ? 1 : padding;
  bxAxis = bxAxis == null ? true : bxAxis;
  byAxis = byAxis == null ? true : byAxis;
  colors = colors == null ? 0 : colors;
  pointStyle = pointStyle == null ? 0 : pointStyle;
  lineStyle = lineStyle == null ? 1 : lineStyle;
  labelStyle = labelStyle == null ? 1 : labelStyle;
  byLog = byLog == null ? false : byLog;

  graphics.clipRect(frame.x, frame.y, frame.width, frame.height);

  var i,s;
  var fontHeight = 10;

  if(frame.memory == null || table != frame.memory.table){
    // change in input table, compute slow things here
    frame.memory = {};
    frame.memory.table = table;
    if(xAxisList && xAxisList.type == 'NumberList' && !xAxisList.isSorted()){
      // if not sorted then sort
      var tab1 = table.clone();
      tab1.push(xAxisList);
      tab1 = tab1.getListsSortedByList(tab1.length-1,true,true);
      xAxisList = tab1[tab1.length-1];
      frame.memory.table = tab1.getWithoutElementAtIndex(tab1.length-1);
    }
    frame.memory.intRangeyRaw = table.getInterval();
    if(isNaN(frame.memory.intRangeyRaw.x) || isNaN(frame.memory.intRangeyRaw.y)){
      // at least one NaN in the table, handle
      frame.memory.table = table.clone();
      for(i=0; i < frame.memory.table.length; i++){
        var nL=frame.memory.table[i];
        var int0 = nL.getInterval();
        if(isNaN(int0.x) || isNaN(int0.y)){
          // create list of zeros
          frame.memory.table[i] = NumberListGenerators.createSortedNumberList(nL.length,0,0);
          frame.memory.table[i].name = 'Invalid values! ' + table[i].name;
        }
      }
      frame.memory.intRangeyRaw = frame.memory.table.getInterval();
    }
    frame.memory.r = new Rectangle(); // gets set below
    frame.memory.LColors = null;
    frame.memory.sLLabels = new StringList();
    frame.memory.maxLabelWidth = 0;
    graphics.setText('black',fontHeight);
    for(i=0; i < frame.memory.table.length; i++){
      s = frame.memory.table[i].name;
      if(s == null || s == '')
        s = 'Series ' + i;
      frame.memory.sLLabels.push(s);
      frame.memory.maxLabelWidth = Math.max(frame.memory.maxLabelWidth,graphics.getTextW(s));
    }
  }
  if(!frame.memory.r.isEqual(frame) || frame.memory.xAxisList != xAxisList || frame.memory.byLog != byLog || frame.memory.maxYRange != maxYRange || frame.memory.minYRange != minYRange){
    // change in geometry (also triggered on data change)
    frame.memory.tmMove = new Date().getTime();
    frame.memory.r = new Rectangle(frame.x,frame.y,frame.width,frame.height);
    frame.memory.intRangex = new Interval(0,table[0].length-1);
    frame.memory.xAxisList = xAxisList;
    frame.memory.bNumericX = false;
    frame.memory.maxYRange = maxYRange;
    frame.memory.minYRange = minYRange;
    if(xAxisList && frame.memory.xAxisList.type == 'NumberList'){
      frame.memory.bNumericX = true;
      // assumes numbers in order of increasing x
      frame.memory.intRangex = new Interval(xAxisList[0],xAxisList[xAxisList.length-1]);
    }
    frame.memory.byLog = byLog;
    if(xAxisList == null){
      frame.memory.intRangexLabel = new Interval(0,table[0].length-1);
      frame.memory.nLTicksx = frame.memory.intRangexLabel.getNiceTickValues(frame.width > 300 ? 10 : 5);
      // redefine based on actual ticks used
      frame.memory.intRangexLabel = new Interval(frame.memory.nLTicksx[0],frame.memory.intRangexLabel.y);
      frame.memory.xAxisTickInc = 1;
    }
    else{
      frame.memory.nLTicksx = xAxisList;
      if(frame.memory.bNumericX)
        frame.memory.intRangexLabel = new Interval(xAxisList[0],xAxisList[xAxisList.length-1]);
      else
        frame.memory.intRangexLabel = new Interval(0,xAxisList.length-1);
      frame.memory.xAxisTickInc = Math.ceil(xAxisList.length/8);
    }
    if(maxYRange && !isNaN(maxYRange))
      frame.memory.intRangeyRaw.y = maxYRange;
    else
      frame.memory.intRangeyRaw = frame.memory.table.getInterval();
    if(minYRange != null && !isNaN(minYRange))
      frame.memory.intRangeyRaw.x = minYRange;
    frame.memory.nLTicksy = frame.memory.intRangeyRaw.getNiceTickValues(frame.height > 300 ? 10 : 5);
    frame.memory.intRangey = frame.memory.intRangeyRaw.clone();
    if(frame.memory.byLog){
      frame.memory.intRangeylog = frame.memory.intRangey.clone();
      frame.memory.intRangeylog.x = frame.memory.intRangey.x <= 0 ? -.1 : Math.log(frame.memory.intRangey.x);
      frame.memory.intRangeylog.y = frame.memory.intRangey.y <= 0 ? -.1 : Math.log(frame.memory.intRangey.y);
    }
  }
  if(frame.memory.LColors == null || frame.memory.colors != colors){
    frame.memory.colors = colors;
    var n = frame.memory.table.length;
    if(colors == 0 || colors == '0')
      frame.memory.LColors = ColorListGenerators.createDefaultCategoricalColorList(n,null,null,true);
    else if(typeof colors == 'string')
      frame.memory.LColors = ColorListGenerators.createColorListWithSingleColor(1,colors);
    else if(colors.type == 'ColorList')
      frame.memory.LColors = colors.clone();
    else if(colors.type == 'StringList'){
      var bAllColors = true;
      frame.memory.LColors = new ColorList();
      for(i=0; i < colors.length && bAllColors; i++){
        if(ColorOperators.colorStringToRGB(colors[i])==null)
          bAllColors=false;
        else
          frame.memory.LColors.push(colors[i]);
      }
      if(!bAllColors)
        frame.memory.LColors = ColorListGenerators.stringsToColors(colors);
    }
  }
  var tmNow = new Date().getTime();
  if(graphics.MOUSE_MOVED || graphics.MOUSE_PRESSED || graphics.MOUSE_UP)
    frame.memory.tmMove = tmNow;
  var bShowTooltip = tmTooltip != -1 && (tmNow - frame.memory.tmMove) >= tmTooltip;

  var spacingLabel = 3;
  var marginLeft = byAxis ? fontHeight * 5.0 + padding : padding;
  var marginBottom = bxAxis ? fontHeight * 2.5 + padding : padding;
  var rightLabelWidth = fixedRightLabelWidth != null ? fixedRightLabelWidth : frame.memory.maxLabelWidth;
  var labelWidth = Math.ceil(Math.min(rightLabelWidth+spacingLabel,(frame.width-marginLeft-padding)*.5));
  var marginRight = labelStyle == 1 ? labelWidth + padding : padding;
  var marginTop = Math.ceil(fontHeight/2);
  if(byAxis && !bxAxis){
    marginBottom += fontHeight/2;
  }

  var rPlot = new Rectangle(frame.x + marginLeft, frame.y + marginTop + padding, frame.width - marginRight - marginLeft, frame.height - padding - marginBottom - marginTop);
  var rLabelArea = new Rectangle(rPlot.getRight(),rPlot.y,marginRight+spacingLabel,rPlot.height);

  var clrAxes = 'rgb(100,100,100)';
  var clr,c,series,x,y,poly,r,rLabel,intyVal,v;
  var inty = new Interval(rPlot.y+rPlot.height,rPlot.y);
  var intx = new Interval(rPlot.x,rPlot.x+rPlot.width);
  // build the lines
  var tPoly = new Table();
  // also find closest to mouse
  var iClosest = -1,iClosesti = -1;
  var dist,distMin = Infinity,distMinPer = Infinity;
  var fnPolyGetLasty = function(poly0,def){
    // find last non-null point and return y, otherwise def value
    for(var i=poly0.length-1; i >= 0; i--){
      if(poly0[i] != null)
        return poly0[i].y;
    }
    return def;
  }
  intyVal = frame.memory.byLog ? frame.memory.intRangeylog : frame.memory.intRangey;
  // first calc point positions and create polys
  for(c=0; c < frame.memory.table.length; c++){
    series = frame.memory.table[c];
    poly = new Polygon();
    for(i=0; i < series.length; i++){
      if(frame.memory.bNumericX)
        x = frame.memory.intRangex.remap(frame.memory.xAxisList[i],intx);
      else
        x = frame.memory.intRangex.remap(i,intx);
      v = series[i];
      if(frame.memory.byLog)
        v = v <= 0 ? -.1 : Math.log(v);
      y = intyVal.remap(v,inty);
      var pt = v == null ? null : new Point(x,y);
      poly.push(pt);
      dist = Math.abs(x - graphics.mX);
      if(dist < distMinPer){
        distMinPer = dist;
        iClosesti = i;
      }
      if(i>0 && pt != null && graphics.mX < rLabelArea.x){
        if(poly[i-1] == null)
          dist = graphics.mP.distanceToPoint(poly[i]);
        else
          dist = GeometryOperators.distancePointToSegment(graphics.mP,poly[i-1],poly[i]);
        if(dist < distMin && dist < 10 && graphics.MOUSE_IN_DOCUMENT && (!graphics.MOUSE_PRESSED || graphics.MOUSE_DOWN)){
          distMin = dist;
          iClosest = c;
        }
      }
    }
    tPoly.push(poly);
  }
  // now calc label positions but don't draw yet
  // we want to place labels in order of series style so dominant labels are closer
  // to their natural locations.
  var aSer = [];
  for(c=0; c < frame.memory.table.length; c++){
    aSer.push( {c:c, c2: nLSeriesStyle ? nLSeriesStyle[c] : 1});
  }
  aSer = aSer.sort( function(a,b){
    return a.c2 == b.c2 ? a.c - b.c : b.c2 - a.c2;
  })
  // labels
  var rLLabels = new RectangleList();
  var xLabel = rPlot.getRight() + spacingLabel;
  for(var cn=0; cn < aSer.length; cn++){
    c = aSer[cn].c;
    series = frame.memory.table[c];
    poly = tPoly[c];
    clr = poly.clr;
    if(labelStyle == 1){
      // show on right if doesn't overlap with existing labels
      y = fnPolyGetLasty(tPoly[c],rLabelArea.y + rLabelArea.height/2);
      if(y < rPlot.y)
        y = rPlot.y;
      else if(y > rPlot.getBottom() - fontHeight/2)
        y = rPlot.getBottom() - fontHeight/2;
      rLabel = new Rectangle(xLabel,y - fontHeight/2,marginRight,fontHeight);
      rLabel.c = c;
      if(!rLabelArea.containsRectangle(rLabel))
        rLabelArea = RectangleOperators.minRect(rLabel,rLabelArea);
      var rLabel0 = rLabel.clone(); rLabel0.c = rLabel.c;
      var bPlaced = RectangleOperators.placeRectangleAvoidingOthers(rLabel,rLabelArea,rLLabels,1,0,1);
      if(bPlaced){
        rLLabels.push(rLabel);
        poly.yLabel = rLabel.y + fontHeight/2;
      }
      else if(c == iClosest){
        poly.bOverwrite = true;
        poly.yLabel = rLabel0.y + fontHeight/2;
      }
      if(graphics.mX >= rLabelArea.x){
        dist = Math.abs(poly.yLabel - graphics.mY);
        if(dist < distMin && dist < 10 && graphics.MOUSE_IN_DOCUMENT && (!graphics.MOUSE_PRESSED || graphics.MOUSE_DOWN)){
          distMin = dist;
          iClosest = c;
        }
      }
    }
  }

  // set colors and alpha for series based on nLSeriesStyle and iClosest
  for(c=0; c < frame.memory.table.length; c++){
    poly = tPoly[c];
    clr = frame.memory.LColors[c % frame.memory.LColors.length];
    var wStroke = 1;
    var alpha = null;
    if(nLSeriesStyle != null){
      if(nLSeriesStyle[c] == 2)
        wStroke = 2;
      else if(nLSeriesStyle[c] == 1)
        wStroke = 1;
      else if(nLSeriesStyle[c] == 0)
        wStroke = .65;
      else
        wStroke = nLSeriesStyle[c]; // any number besides 0,1,2 gets used directly as a strokeWidth
    }
    if(iClosest != -1 && iClosest != c)
      alpha = .35;
    else if(iClosest != -1 && iClosest == c){
      alpha = 1;
      wStroke = Math.max(wStroke,1);
    }
    if(alpha != null)
      clr = ColorOperators.addAlpha(clr,alpha);
    poly.clr = clr;
    poly.wStroke = wStroke;
  }

  var fnDrawLabel = function(cc,xPos,yPos,c2){
    c2 = c2 == null ? 1 : c2;
    graphics.setText(tPoly[cc].clr, fontHeight, null, 'left', 'middle', c2  > 1 ? 'bold' : '');
    var s = frame.memory.table[cc].name;
    if(s == null || s == '')
      s = 'Series ' + cc;
    graphics.fText(s,xPos,yPos);
  };

  // draw labels only
  for(var cn=0; cn < aSer.length; cn++){
    c = aSer[cn].c;
    series = frame.memory.table[c];
    poly = tPoly[c];
    clr = poly.clr;
    if(labelStyle == 2 && iClosest == c){
      graphics.setText(clr, fontHeight, null, 'right', 'middle');
      s = frame.memory.table[c].name;
      if(s == null || s == '')
        s = 'Series ' + iClosest;
      graphics.fText(s, graphics.mP.x, graphics.mP.y - fontHeight);
    }
    else if(labelStyle == 1){
      // show on right if doesn't overlap with existing labels
      if(false && c == 0 && iClosest != -1){
        if(poly.yLabel < rPlot.y || poly.yLabel > rPlot.getBottom()) continue;
        fnDrawLabel(iClosest,xLabel,poly.yLabel);
      }
      if(poly.bOverwrite){
        var rBlank = new Rectangle(xLabel,poly.yLabel - fontHeight/2,rLabelArea.width,fontHeight);
        graphics.setFill('rgba(255,255,255,.5)');
        graphics.fRect(rBlank);
      }
      fnDrawLabel(c,xLabel,poly.yLabel,aSer[cn].c2);
    }
  }

  var fnSplitPolyByNulls = function(poly0){
    // return an array of polys split by null points
    var aPolys = [];
    var cPoly = new Polygon();
    for(var i=0; i < poly0.length; i++){
      if(poly0[i] == null){
        if(cPoly.length > 0)
          aPolys.push(cPoly);
        cPoly = new Polygon();
        continue;
      }
      cPoly.push(poly0[i]);
    }
    if(cPoly.length > 0)
      aPolys.push(cPoly);
    return aPolys;
  };
  // show lines and points
  graphics.clipRect(rPlot.x, rPlot.y-2, rPlot.width, rPlot.height+2);
  for(c=0; c < frame.memory.table.length; c++){
    series = frame.memory.table[c];
    poly = tPoly[c];
    graphics.setStroke(poly.clr,poly.wStroke);
    var aPolys = fnSplitPolyByNulls(poly);
    for(var j=0; j < aPolys.length; j++){
      var polyj = aPolys[j];
      if(polyj.length == 1){
        graphics.setFill(poly.clr);
        graphics.fCircle(polyj[0].x,polyj[0].y,2);
        continue;
      }
      if(lineStyle == 1)
        graphics.sPolygon(polyj);
      else if(lineStyle == 2){
        graphics.context.beginPath();
        graphics.drawSmoothPolygon(polyj,false,10);
        graphics.context.stroke();
      }
    }
    if(pointStyle != 0){
      graphics.setFill(poly.clr);
      graphics.setStroke(poly.clr,.5);
      r = (pointStyle == 1 || pointStyle == 4)? 2 : 3;
      for(i=0; i < poly.length; i++){
        if(poly[i] == null) continue;
        x=poly[i].x;
        y=poly[i].y;
        if(pointStyle == 1)
          graphics.fCircle(x,y,r);
        else if(pointStyle == 2)
          graphics.sCircle(x,y,r);
        else if(pointStyle == 3){
          graphics.line(x-r,y-r,x+r,y+r);
          graphics.line(x-r,y+r,x+r,y-r);
        }
        else if(pointStyle == 4 && i == iClosesti)
          graphics.fCircle(x,y,r);
      }
    }
  }
  graphics.context.restore();
  // axes
  graphics.setStroke(clrAxes,1);
  graphics.setText(clrAxes, fontHeight, null, 'center', 'middle');
  var fnPeriodLabel = function(ip){
    if(xAxisList != null)
      s = NumberOperators.formatShort(xAxisList[ip],4);
    else
      s = String(ip);
    return s;
  };
      
  var tickLen = 4, w = 0, lastx = frame.x, lasty, iPrec;
  if(bxAxis){
    y = rPlot.getBottom();
    graphics.line(rPlot.x - padding,y,rPlot.getRight() + padding,y);
    var bFirst = true;
    for(i=0; i < frame.memory.nLTicksx.length; i+= frame.memory.xAxisTickInc){
      if(xAxisList){
        if(frame.memory.bNumericX)
          x = frame.memory.intRangexLabel.remap(frame.memory.xAxisList[i],intx);
        else
          x = frame.memory.intRangexLabel.remap(i,intx);
      }
      else
        x = frame.memory.intRangexLabel.remap(frame.memory.nLTicksx[i],intx);
      if(x > lastx + 4){
        graphics.line(x,y,x,y + tickLen);
        if(frame.memory.bNumericX){
          if(xAxisList != null)
            s = NumberOperators.formatShort(frame.memory.nLTicksx[i],4);
          else
            s = NumberOperators.formatShort(frame.memory.nLTicksx[i]);
        }
        else
          s = frame.memory.nLTicksx[i];
        w = graphics.fTextW(s, x, y + tickLen + 5);
        lastx = x + w;
      }
      // make sure we try last label -> this helps with non linear distributions
      if(i + frame.memory.xAxisTickInc >= frame.memory.nLTicksx.length && bFirst){
        i = frame.memory.nLTicksx.length - 1 - frame.memory.xAxisTickInc;
        bFirst = false;
      }
    }
    if(sxLabel != null && sxLabel != ''){
      graphics.setText('black', fontHeight, null, 'center', 'middle');
      graphics.fText(sxLabel, intx.getInterpolatedValue(0.5), y + tickLen + 5 + fontHeight);
    }
  }
  if(byAxis){
    x = rPlot.x;
    graphics.line(x,rPlot.y - padding,x,rPlot.getBottom() + padding);
    graphics.setText(clrAxes, fontHeight, null, 'right', 'middle');
    lasty = Infinity;
    // find max resolution required to distinguish number labels
    var sLLabels;
    for(iPrec=2; iPrec<10; iPrec++){
      sLLabels = new mo.StringList();
      for(i=0; i < frame.memory.nLTicksy.length; i++){
        sLLabels.push(NumberOperators.formatShort(frame.memory.nLTicksy[i],iPrec));
      }
      if(sLLabels.getWithoutRepetitions().length==frame.memory.nLTicksy.length)
        break;
    }
    for(i=0; i < frame.memory.nLTicksy.length; i++){
      v = frame.memory.nLTicksy[i];
      if(frame.memory.byLog)
        v = v <= 0 ? -.1 : Math.log(v);
      y = intyVal.remap(v,inty);
      if(y < rPlot.y || y > rPlot.getBottom()) continue;
      graphics.line(x - tickLen,y,x,y);
      s = sLLabels[i];
      if(lasty - y >= fontHeight/2){
        graphics.fText(s, x - tickLen, y);
        lasty = y;
      }
    }
    if( (syLabel != null && syLabel != '') || frame.memory.byLog){
      graphics.setText('black', fontHeight, null, 'center', 'middle');
      s = syLabel == null ? '' : syLabel;
      if(frame.memory.byLog)
        s = s + '(log scale)';
      graphics.fTextRotated(s, frame.x + fontHeight*.8 + 1 , inty.getInterpolatedValue(0.5),-HalfPi);
    }
  }
  if(bShowTooltip && rPlot.containsPoint(graphics.mP)){
    var tTool = new Table();
    tTool.push(new NumberList());
    tTool.push(new StringList());
    tTool.push(new ColorList());
    for(c=0; c < frame.memory.table.length; c++){
      series = frame.memory.table[c];
      tTool[0].push(series[iClosesti]);
      s = series.name;
      if(s == null || s == '')
        s = 'Series ' + c;
      tTool[1].push(s);
      tTool[2].push(ColorOperators.addAlpha(tPoly[c].clr,1));
    }
    tTool = TableOperators.filterTable(tTool,'!=',null,0);
    tTool =  tTool.getListsSortedByList(0,false,true);
    tTool[0] = NumberListOperators.setMinimumDiscriminatingPrecision(tTool[0]);
    graphics.setText('black',fontHeight,null,'left','top');
    var wLabel = 0, wValue = 0;
    var sPeriodLabel = fnPeriodLabel(iClosesti);
    wLabel = graphics.getTextW(sPeriodLabel);
    for(c = 0; c < tTool[0].length; c++){
      w = graphics.getTextW(tTool[0][c]);
      wValue = Math.max(wValue,w);
      w = Math.min(rPlot.width/4,graphics.getTextW(tTool[1][c]));
      wLabel = Math.max(wLabel,w);
    }
    wLabel = Math.min(wLabel,rPlot.width/2);
    var h = Math.min((tTool[0].length+1) *  fontHeight + 4,rPlot.height);
    w =  Math.round(wLabel + 15 + wValue);
    var rTooltip = new Rectangle(graphics.mX,graphics.mY-h,w,h);
    if(rTooltip.y < rPlot.y){
      rTooltip.y += rTooltip.height;
      if(rTooltip.x - rTooltip.width > rPlot.x)
        rTooltip.x -= rTooltip.width;
      else
        rTooltip.x += 12;
    }
    if(rTooltip.getRight() > rPlot.getRight())
      rTooltip.x -= rTooltip.width;
    if(rTooltip.getBottom() > rPlot.getBottom())
      rTooltip.y = rPlot.y;
    graphics.setFill('rgba(255,255,255,.9)');
    graphics.setStroke('rgb(200,200,200)');
    if(tTool[0].length > 0){
      graphics.fsRect(rTooltip);
      // draw period label
      graphics.setText(clrAxes,fontHeight);
      s = DrawTexts.cropString(graphics,sPeriodLabel,wLabel);
      graphics.fText(s,rTooltip.x+2,rTooltip.y+2);
      for(c = 0; c < tTool[0].length; c++){
        if(rTooltip.y+2 + (c+2)*fontHeight > rTooltip.getBottom()) continue;
        graphics.setText(tTool[2][c],fontHeight);
        s = DrawTexts.cropString(graphics,tTool[1][c],wLabel);
        graphics.fText(s,rTooltip.x+2,rTooltip.y+2 + (c+1)*fontHeight);
        graphics.fText(tTool[0][c],rTooltip.x+13 + wLabel,rTooltip.y+2 + (c+1)*fontHeight);
      }
    }
  }
  graphics.context.restore();
  var aRet = [
    {
      type: "Number",
      name: "closestSeries",
      description: "The series closest to the mouse",
      value: iClosest
    },
    {
      type: "Rectangle",
      name: "rPlot",
      description: "The rectangle of the plot region",
      value: rPlot
    },
    {
      type: "Number",
      name: "closestPeriod",
      description: "The period closest to the mouse",
      value: iClosesti
    }
  ];
  aRet.isOutput = true;
  return aRet;
};
