import List from "src/dataTypes/lists/List";
import ListConversions from "src/operators/lists/ListConversions";
import NumberTable from "src/dataTypes/numeric/NumberTable";
import Table from "src/dataTypes/lists/Table";
import NumberList from "src/dataTypes/numeric/NumberList";
import StringList from "src/dataTypes/strings/StringList";
import NodeList from "src/dataTypes/structures/lists/NodeList";
import StringOperators from "src/operators/strings/StringOperators";
import ColorOperators from "src/operators/graphic/ColorOperators";
import ColorScales from "src/operators/graphic/ColorScales";
import NumberOperators from "src/operators/numeric/NumberOperators";
import NumberListOperators from "src/operators/numeric/numberList/NumberListOperators";
import NumberListGenerators from "src/operators/numeric/numberList/NumberListGenerators";
import ListGenerators from "src/operators/lists/ListGenerators";
import Interval from "src/dataTypes/numeric/Interval";
import TableConversions from "src/operators/lists/TableConversions";
import TableGenerators from "src/operators/lists/TableGenerators";
import { typeOf, instantiate, instantiateWithSameType } from "src/tools/utils/code/ClassUtils";

/**
 * @classdesc List Operators
 *
 * @namespace
 * @category basics
 */
function ListOperators() {}
export default ListOperators;


/**
 * deprecated
 */
ListOperators.getElement = function(list, indexOrName){
  return list.getElement(indexOrName);
};

/**
 * multi-ouput operator that gives acces to individual elements
 * @param  {List} list
 *
 * @param  {Number} fromIndex (default 0)
 * @return {Object} first Object
 * @return {Object} second Object
 * @return {Object} third Object
 * @return {Object} fourth Object
 * @return {Object} fifth Object
 * @return {Object} sisxth Object
 * @return {Object} seventh Object
 * @return {Object} eight Object
 * @return {Object} ninth Object
 * @return {Object} tenth Object
 * tags:
 */
ListOperators.getFirstElements = function(list, fromIndex) {
  if(list == null){
    fromIndex = 0;
    // to clear all the outputs insted of just the first
    list = [null,null,null,null,null,null,null,null,null,null];
  }

  fromIndex = fromIndex == null ? 0 : Number(fromIndex);

  return [
  {
    type: "Object",
    name: "first value",
    description: "first value",
    value: list[fromIndex + 0]
  },
  {
    type: "Object",
    name: "second value",
    description: "second value",
    value: list[fromIndex + 1]
  },
  {
    type: "Object",
    name: "third value",
    description: "third value",
    value: list[fromIndex + 2]
  },
  {
    type: "Object",
    name: "fourth value",
    description: "fourth value",
    value: list[fromIndex + 3]
  },
  {
    type: "Object",
    name: "fifth value",
    description: "fifth value",
    value: list[fromIndex + 4]
  },
  {
    type: "Object",
    name: "sixth value",
    description: "sixth value",
    value: list[fromIndex + 5]
  },
  {
    type: "Object",
    name: "seventh value",
    description: "seventh value",
    value: list[fromIndex + 6]
  },
  {
    type: "Object",
    name: "eight value",
    description: "eight value",
    value: list[fromIndex + 7]
  },
  {
    type: "Object",
    name: "ninth value",
    description: "ninth value",
    value: list[fromIndex + 8]
  },
  {
    type: "Object",
    name: "tenth value",
    description: "tenth value",
    value: list[fromIndex + 9]
  }];
};


/**
* check if two lists contain same elements
* @param {List} list0 first list
* @param {List} list1 second list
* @return {Boolean}
* tags:
*/
ListOperators.containSameElements = function(list0, list1) {
  if(list0==null || list1==null) return null;

  var l = list0.length;
  var i;

  if(l!=list1.length) return;

  for(i=0; i<l; i++){
    if(list0[i]!=list1[i]) return false;
  }

  return true;
};

/**
* return the fraction of corresponding elements that are the same and in same position
* @param {List} list0 first list
* @param {List} list1 second list
* @return {Number}
* tags:
*/
ListOperators.fractionOfSameElements = function(list0, list1) {
  if(list0==null || list1==null) return null;

  var l = Math.max(list0.length,list1.length);
  var i;
  var n=0;
  for(i=0; i<l; i++){
    if(list0[i]==list1[i])
      n++;
  }
  if(l==0) return 0;
  return n/l;
};

/**
* return a list of same length as the inputs saying whether corresponding elements are equal
* @param {List} list0 first list
* @param {List} list1 second list
*
* @param {Object} valSame is the value to use when corresponding items are the same (default: value from lists)
* @param {Object} valDiff is the value to use when corresponding items are different (default: false)
* @return {List}
* tags:
*/
ListOperators.getCorrespondenceBetweenLists = function(list0, list1, valSame, valDiff) {
  if(list0==null || list1==null) return null;
  if(list0.length != list1.length)
    throw new Error('The two lists must be the same length');
  valDiff = valDiff == null ? false : valDiff;

  var newList = new List();
  var l = Math.max(list0.length,list1.length);
  var i;
  for(i=0; i<l; i++){
    if(list0[i] == list1[i])
      newList.push(valSame == null ? list0[i] : valSame);
    else
      newList.push(valDiff);
  }
  return newList.getImproved();;
};

/**
* return a table giving the confusion matrix, the actual versus predicted counts for each combination of classes
* @param {List} list0 actual values list
* @param {List} list1 predicted values list
*
* @return {Table} giving actual versus predicted counts for each combination of classes
* tags:statistics,machine-learning
*/
ListOperators.getConfusionMatrix = function(lista, listp) {
  if(lista==null || listp==null) return null;
  if(lista.length != listp.length)
    throw new Error('The two lists must be the same length');

  var listAll = ListOperators.union(lista,listp).getWithoutRepetitions().getSorted();
  var oCounts = {};
  var i,j,c = '-|-';

  for(i=0; i < listAll.length; i++){
    for(j=0; j < listAll.length; j++){
      oCounts[listAll[i] + c + listAll[j]] = 0;
    }
  }

  for(i = 0; i < lista.length; i++){
    oCounts[lista[i] + c + listp[i]]++;
  }

  var t = new Table();
  t.name = 'Confusion Matrix';
  for(i=0; i <= listAll.length; i++){
    if(i == 0){
      t.push(new StringList());
      t[i].name = 'Predicted Class';
    }
    else{
      t.push(new NumberList());
      t[i].name = 'Actual:'+listAll[i-1];
    }
    for(j=0; j < listAll.length; j++){
      if(i == 0)
        t[i].push(listAll[j]);
      else
        t[i].push(oCounts[listAll[i-1] + c + listAll[j]]);
    }
  }
  return t;
};

/**
* return a table giving the precision and recall for each class as well as number of cases
* @param {List} list0 actual values list
* @param {List} list1 predicted values list
*
* @return {Table} table giving the precision and recall for each class
* tags:statistics,machine-learning
*/
ListOperators.evaluateClassification = function(lista, listp) {
  if(lista==null || listp==null) return null;

  var tConfusionMatrix = ListOperators.getConfusionMatrix(lista, listp);

  var sLClasses = tConfusionMatrix[0].clone();
  tConfusionMatrix = tConfusionMatrix.getWithoutElementAtIndex(0);
  var t = new Table();
  t.push(sLClasses);
  t.push(new NumberList());
  t.push(new NumberList());
  t.push(new NumberList());
  t.assignNames('Class,Precision,Recall,Cases');
  
  var i,nTruePositives,nAllPositives,nTotalInClass,Recall,Precision,nLRow;

  for(i=0; i < tConfusionMatrix.length; i++){
    nTruePositives = tConfusionMatrix[i][i];
    nLRow = tConfusionMatrix.getRow(i);
    nAllPositives = nLRow.getSum();
    nTotalInClass = tConfusionMatrix[i].getSum();
    Precision = nAllPositives == 0 ? 0 : 100 * nTruePositives / nAllPositives;
    Recall = nTotalInClass == 0 ? 0 : 100 * nTruePositives / nTotalInClass;

    t[1].push(Number(Precision.toFixed(2)));
    t[2].push(Number(Recall.toFixed(2)));
    t[3].push(nTotalInClass);
  }

  return t;
};

/**
* return a report in Html that gives details comparing actual to predicted classes
* @param {List} list0 actual values list
* @param {List} list1 predicted values list
*
* @param {String} title shown at top of report
* @return {String} report in Html
* tags:statistics,machine-learning,html,compare
*/
ListOperators.getClassificationEvaluationReport = function(lista, listp, title) {
  if(lista==null || listp==null) return null;

  var sHtml = '';
  if(title != null && typeof title == 'string' )
    sHtml += '<b>' + title + '</b><br>';
  var accuracy = ListOperators.fractionOfSameElements(lista,listp);
  sHtml += '<b>Accuracy: ' + (100*accuracy).toFixed(2) + '%</b><br>';

  var tFreq = lista.getFrequenciesTable(true);
  var iMostCommonClass = tFreq[1][0];
  sHtml += '<b>Accuracy of most frequent class model:</b> ' + (100*iMostCommonClass/lista.length).toFixed(2) + '%<br>';
  sHtml += '<b>Total Cases:</b> ' + lista.length + '<br><br>';

  var tConfusionMatrix = ListOperators.getConfusionMatrix(lista, listp);
  // build formatting table to highlight cells
  var r,c,tot,f,clr;
  var tConfusionMatrixF = TableGenerators.createTableWithSameElement(tConfusionMatrix.length,tConfusionMatrix[0].length,'');
  for(c=1; c < tConfusionMatrix.length; c++){
    tot = tConfusionMatrix[c].getSum();
    for(r=0; r < tConfusionMatrix[c].length; r++){
      if(c - 1 == r) continue;
      f = tConfusionMatrix[c][r]/tot;
      clr = ColorScales.whiteToRed(Math.min(1,f/.35));
      tConfusionMatrixF[c][r] = 'border:1px solid rgba(200,200,200,.5);background-color:' + clr + ';';
    }
  }
  sHtml += '<b>Confusion Matrix</b><br>';
  sHtml += TableConversions.tableToHtml(tConfusionMatrix,null,null,null,null,'background-color: #ffffff;',tConfusionMatrixF);
  var tPrecRecall = ListOperators.evaluateClassification(lista, listp);
  var tPrecRecallF = TableGenerators.createTableWithSameElement(tPrecRecall.length,tPrecRecall[0].length,'');
  for(c=1; c < 3; c++){
    for(r=0; r < tPrecRecall[c].length; r++){
      f = tPrecRecall[c][r]/100;
      clr = ColorScales.whiteToRed(Math.min(1,(1 - f)/.35));
      tPrecRecallF[c][r] = 'border:1px solid rgba(200,200,200,.5);background-color:' + clr + ';';
    }
  }
  sHtml += '<br><b>Precision and Recall</b><br>';
  sHtml += TableConversions.tableToHtml(tPrecRecall,null,null,null,null,'background-color: #ffffff;',tPrecRecallF);
  return sHtml;
};

/**
 * first position of element in list (-1 if element doesn't belong to the list)
 * @param  {List} list
 * @param  {Object} element
 * @return {Number}
 * tags:
 */
ListOperators.indexOf = function(list, element) {
  if(list==null) return;
  return list.indexOf(element);
};

/**
 * builds a new list with an element replaced
 * @param  {List} list
 * @param  {Object} elementToSearch element that will be replaced
 * @param  {Object} elementToPlace element that will be placed instead
 * @return {List}
 * tags:
 */
ListOperators.replaceElement = function(list, elementToSearch, elementToPlace){
  if(list==null || elementToSearch==null) return null;

  var newList = new List();
  newList.name = list.name;
  var l = list.length;
  var i;
  for(i=0; i<l; i++){
    newList[i] = (list[i]==elementToSearch)?elementToPlace:list[i];
  }
  return newList.getImproved();
};

/**
 * builds a new list with an element at specified index replaced
 * @param  {List} list
 *
 * @param  {Number} index of element that will be replaced (default:0)
 * @param  {Object} elementToPlace element that will be placed instead
 * @return {List}
 * tags:
 */
ListOperators.replaceElementAtIndex = function(list, index, elementToPlace){
  if(list==null || elementToPlace==null) return null;
  index = index == null ? 0 : index;

  if(typeof index != 'number' || index % 1 != 0 || index < 0 || index >= list.length)
    throw new Error('Invalid index in replaceElement');

  var newList = list.clone();
  newList[index] = elementToPlace;
  return newList.getImproved();
};

/**
 * replaces all nulls in a list
 * @param  {List} list
 * @param  {Number} mode of replacement<|>0:replace by element<|>1:by previous non-null element<|>2:by next non-null element<|>3:average (if all non-null elements are numbers)<|>4:local average, average of previous and next non-null values (if numbers)<|>5:interpolate numbers (if all non-null elements are numbers)<|>6:by most common element (different to null)<|>7:by median (if all non-null elements are numbers)
 * @param  {Object} element that will replace nulls
 *
 * @param {Object} nullElement element interpreted as null (null by default), examples: NaN, NA, ""…
 * @return {List}
 * tags:transform,clean
 * examples:santiago/examples/forIntroPanel/replacingElementsInTable
 */
ListOperators.replaceNullsInList = function(list, mode, element, nullElement){
  if(list==null || mode==null) return;

  if(mode===0 && element==null){
    throw new Error("when mode is 0, must provide a non-null element to replace nulls");
  }

  var n = list.length;
  var i;
  var average = 0;
  var nNumbers = 0;
  var previous;
  var next;
  var factor;
  var newList = new List();
  var i0, i1;

  switch(mode){
    case 6://most common element
      element = list.getWithoutElement(null).getMostRepeatedElement();
      return ListOperators.replaceNullsInList(list, 0, element);
    case 3://average
      for(i=0; i<n; i++){
        if(typeof list[i] == 'number'){
          average+=list[i];
          nNumbers++;
        }
      }
      element = average/nNumbers;
      return ListOperators.replaceNullsInList(list, 0, element);
    case 7://median
      element = list.getWithoutElement(null).getSorted();
      if(element.length>1 && element.length/2 == Math.floor(element.length/2)){
        element = (element[(element.length/2)-1]+element[element.length/2])*0.5
      } else {
        element = element[Math.floor(element.length/2)];
      }
    case 0://element
      for(i=0; i<n; i++){
        newList[i] = list[i]==nullElement?element:list[i];
      }
      break;
    case 1://previous
      for(i=0; i<n; i++){
        if(list[i]!=nullElement) {
          previous = list[i];
          break;
        }
      }
      for(i=0; i<n; i++){
        if(list[i]==nullElement){
          newList[i] = previous;
        } else {
          newList[i] = previous = list[i];
        }
      }
      break;
    case 2://next
      for(i=n-1; i>=0; i--){
        if(list[i]!=nullElement) {
          next = list[i];
          break;
        }
      }
      for(i=n-1; i>=0; i--){
        if(list[i]==nullElement){
          newList[i] = next;
        } else {
          newList[i] = next = list[i];
        }
      }
      break;
    case 4://local average
      i0 = 0;
      for(i=0; i<n; i++){
        if(list[i]!=nullElement) {
          i1 = i;
          newList[i] = element = list[i];
          break;
        }
      }
      
      if(element==nullElement) return list;

      if(i==n) i1=n;

      while(i0<n){
        for(i=i0; i<i1; i++){
          newList[i] = element;
        }
        for(i=i1; i<n; i++){
          if(list[i]==nullElement){
            i0 = i;
            break;
          } else {
            newList[i] = list[i];
          }
        }
        if(i==n) {
          i0=i1=n;
        } else {
          for(i=i0; i<n; i++){
            if(list[i]!=nullElement){
              i1 = i;
              break;
            }
          }
          if(i==n) i1=n;
        }
        if(i0>=0) {
          element = i1==n?list[i0-1]:(list[i0-1]+list[i1])*0.5;
        }
      }
      break;
    case 5://interpolation
      i0 = 0;
      for(i=0; i<n; i++){
        if(list[i]!=nullElement) {
          i1 = i;
          newList[i] = previous = next = list[i];
          break;
        }
      }

      if(previous==nullElement) return list;

      if(i==n) i1=n;

      while(i0<n){
        factor = (next - previous)/(1+i1-i0);
        for(i=i0; i<i1; i++){
          newList[i] = previous + (i-i0+1)*factor;
        }
        for(i=i1; i<n; i++){
          if(list[i]==nullElement){
            i0 = i;
            break;
          } else {
            newList[i] = list[i];
          }
        }
        if(i==n) {
          i0=i1=n;
        } else {
          for(i=i0; i<n; i++){
            if(list[i]!=nullElement){
              i1 = i;
              break;
            }
          }
          if(i==n) i1=n;
        }
        if(i0>0) previous = list[i0-1];
        next = i1==n?previous:list[i1];
      }
      break;
  }

  newList.name = list.name;
  return newList.getImproved();

};

/**
 * concats lists
 * @param  {List} list0
 * @param  {List} list1
 *
 * @param  {List} list2
 * @param  {List} list3
 * @param  {List} list4
 * @return {List} list5
 * tags:combine
 */
ListOperators.concat = function() {
  if(arguments == null || arguments.length === 0 ||  arguments[0] == null) return null;
  if(arguments.length == 1) return arguments[0];

  var i;
  var list = arguments[0].concat(arguments[1]);
  for(i = 2; i<arguments.length; i++) {
    list = list.concat(arguments[i]);
  }
  return list;
};

/**
 * assembles a List
 * @param  {Object} argument0
 *
 * @param  {Object} argument1
 * @param  {Object} argument2
 * @param  {Object} argument3
 * @param  {Object} argument4
 * @param  {Object} argument5
 * @param  {Object} argument6
 * @param  {Object} argument7
 * @param  {Object} argument8
 * @param  {Object} argument9
 * @return {List}
 * tags:combine
 * lessons:lists_and_tables
 */
ListOperators.assemble = function() {
  return List.fromArray(Array.prototype.slice.call(arguments, 0)).getImproved();
};



/**
 * reverses a list
 * @param {List} list
 * @return {List}
 * tags:sorting
 */
ListOperators.reverse = function(list) {
  return list.getReversed();
};

/**
 * builds a boolean dictionary, and relation array whose value (booleanDictionary[element]) is true if the element belongs to the list
 * @param {List} list
 * @return {Object}
 * tags:dictionary
 */
ListOperators.getBooleanDictionaryForList = function(list){
  if(list==null) return;

  var dictionary = {};
  var l = list.length;
  var i;
  for(i=0;i<l;i++){
    dictionary[list[i]] = true;
  }

  return dictionary;
};



/**
 * builds a dictionary that matches an element of a List with its index on the List (indexesDictionary[element] --> index)
 * it assumes there's no repetitions on the list (if that's not tha case the last index of the element will be delivered)
 * efficiently replaces indexOf
 * @param  {List} list
 * @return {Object}
 * tags:dictionary
 */
ListOperators.getSingleIndexDictionaryForList = function(list){
  if(list==null) return;

  var i;
  var l = list.length;

  var dictionary = {};
  for(i=0; i<l; i++){
    dictionary[list[i]] = i;
  }

  return dictionary;
};

/**
 * builds a dictionary that matches an element of a List with all its indexes on the List (indexesDictionary[element] --> numberList of indexes of element on list)
 * if the list has no repeated elements, and a single is required per element, use ListOperators.getSingleIndexDictionaryForList
 * @param  {List} list
 * @return {Object}
 * tags:dictionary
 */
ListOperators.getIndexesDictionary = function(list){
  if(list==null) return;
  var indexesDictionary = {};
  var i;
  var l = list.length;

  //list.forEach(function(element, i){
  for(i=0; i<l; i++){
    if(indexesDictionary[list[i]]==null) indexesDictionary[list[i]]=new NumberList();
    indexesDictionary[list[i]].push(i);
  }

  return indexesDictionary;
};

/**
 * @todo write docs
 */
ListOperators.getIndexesTable = function(list){
  if(list==null) return;
  var indexesTable = new Table();
  indexesTable[0] = new List();
  indexesTable[1] = new NumberTable();
  var indexesDictionary = {};
  var indexOnTable;
  var element;
  var i;

  for(i=0; i<list.length; i++){
    element = list[i];
    indexOnTable = indexesDictionary[element];
    if(indexOnTable==null){
      indexesTable[0].push(element);
      indexesTable[1].push(new NumberList(i));
      indexesDictionary[element]=indexesTable[0].length-1;
    } else {
      indexesTable[1][indexOnTable].push(i);
    }
  }

  indexesTable[0] = indexesTable[0].getImproved();

  return indexesTable;
};


/**
 * builds a dictionar object (relational array) for a dictionar (table with two lists)
 * @param  {Table} dictionary table with two lists, typically without repetitions, elements of the second list being the 'translation' of the correspdonent on the first
 * @return {Object} relational array
 * tags:
 */
ListOperators.buildDictionaryObjectForDictionary = function(dictionary){
  if(dictionary==null || dictionary.length<2 || dictionary[0] == null || dictionary[1] == null) return;

  var dictionaryObject = {};

  dictionary[0].forEach(function(element, i){
    dictionaryObject[element] = dictionary[1][i];
  });

  return dictionaryObject;
};


/**
 * using a table with two columns as a dictionary (first list elements to be read, second list result elements), translates a List (replaces elements according to dictionary)
 * @param  {List} list to transalte
 * @param  {Table} dictionary table with two lists
 *
 * @param {Object} nullElement element to place in case no translation is found
 * @param {Boolean} keepsOriginal if no translation is found keeps original value (default: false)
 * @return {List}
 * tags:transform
 */
ListOperators.translateWithDictionary = function(list, dictionary, nullElement, keepsOriginal) {
  if(list==null || dictionary==null || dictionary.length<2) return;

  var dictionaryObject = ListOperators.buildDictionaryObjectForDictionary(dictionary);

  var newList = ListOperators.translateWithDictionaryObject(list, dictionaryObject, nullElement, keepsOriginal);

  newList.dictionaryObject = dictionaryObject;

  return newList;
};


/**
 * creates a new list that is a translation of a list using a dictionar object (a relation array)
 * @param  {List} list
 * @param  {Object} dictionaryObject
 *
 * @param  {Object} nullElement element to place in case no translation is found
 * @param {Boolean} if no translation is found keeps original value (default: false)
 * @return {List}
 * tags:
 */
ListOperators.translateWithDictionaryObject = function(list, dictionaryObject, nullElement, keepsOriginal) {
  if(list==null || dictionaryObject==null) return;

  var newList = new List();
  var i;
  var nElements = list.length;

  for(i=0; i<nElements; i++){
    newList[i] = dictionaryObject[list[i]];
  }

  if(nullElement!=null || keepsOriginal){
    var l = list.length;
    for(i=0; i<l; i++){
      if(newList[i]==null) newList[i]=keepsOriginal?list[i]:nullElement;
    }
  }
  
  newList.name = list.name;
  return newList.getImproved();
};


/**
 * @todo write docs
 */
ListOperators.sortListByNumberList = function(list, numberList, descending) {
  if(descending == null) descending = true;
  if(numberList == null || numberList.length === 0) return list;

  return list.getSortedByList(numberList,!descending,false,true);
};


/**
 * calculates the position of elements of a list if it were sorted (rankings)
 * @param  {List} list
 *
 * @param  {Boolean} ascendant if true (default) rankings ara lower for lower values
 * @param {Boolean} randomSortingForEqualElements random sorting for equal elements, so rankings among them will be random
 * @param {Boolean} normalize to get values in [0,1]
 * @return {NumberList} positions (or ranks) of elements
 * tags:
 */
ListOperators.getRankings = function(list, ascendant, randomSortingForEqualElements, normalize){
  if(list==null) return null;
  
  ascendant = ascendant==null?true:ascendant;

  var indexes = NumberListGenerators.createSortedNumberList(list.length);
  indexes = indexes.getSortedByList(list, ascendant, randomSortingForEqualElements);
  var rankings = new NumberList();
  var l = list.length;
  var i;
  for(i=0;i<l; i++){
    rankings[indexes[i]] = i;
  }

  if(normalize) rankings=rankings.getNormalized();

  rankings.name = list.name;

  return rankings;
};


/**
 * @todo write docs
 */
ListOperators.sortListByIndexes = function(list, indexedArray) {
  var newList = instantiate(typeOf(list));
  newList.name = list.name;
  var nElements = list.length;
  var i;
  for(i = 0; i < nElements; i++) {
    newList.push(list[indexedArray[i]]);
  }
  return newList;
};


/**
 * @todo write docs
 */
ListOperators.concatWithoutRepetitions = function() {
  var l = arguments.length;
  if(l===0) return;
  if(l==1) return arguments[0];

  var i, j;
  var newList = arguments[0].clone();
  var newListBooleanDictionary = ListOperators.getBooleanDictionaryForList(newList);
  var addList;
  var nElements;
  for(i = 1; i < l; i++) {
    addList = arguments[i];
    nElements = addList.length;
    for(j = 0; j < nElements; j++) { // TODO Is the redefing of i intentional? <----- !
      //if(newList.indexOf(addList[i]) == -1) newList.push(addList[i]);
      if(!newListBooleanDictionary[addList[j]]) newList.push(addList[j]);
    }
  }
  return newList.getImproved();
};

/**
 * builds a table: a list of sub-lists from the original list, each sub-list determined size subListsLength, and starting at certain indexes separated by step
 * @param  {List} list
 * @param  {Number} subListsLength length of each sub-list
 * @param  {Number} step slifing step
 * @param  {Number} finalizationMode<br>0:all sub-Lists same length, doesn't cover the List<br>1:last sub-List catches the last elements, with lesser length<br>2:all lists same length, last sub-list migth contain elements from the beginning of the List
 * @return {Table}
 * tags:advanced
 */
ListOperators.slidingWindowOnList = function(list, subListsLength, step, finalizationMode) {
  finalizationMode = finalizationMode || 0;
  var table = new Table();
  var newList;
  var nElements = list.length;
  var i;
  var j;

  step = Math.max(1, step);

  switch(finalizationMode) {
    case 0: //all sub-Lists same length, doesn't cover the List
      for(i = 0; i < nElements; i += step) {
        if(i + subListsLength <= nElements) {
          newList = new List();
          for(j = 0; j < subListsLength; j++) {
            newList.push(list[i + j]);
          }
          table.push(newList.getImproved());
        }
      }
      break;
    case 1: //last sub-List catches the last elements, with lesser length
      for(i = 0; i < nElements; i += step) {
        newList = new List();
        for(j = 0; j < Math.min(subListsLength, nElements - i); j++) {
          newList.push(list[i + j]);
        }
        table.push(newList.getImproved());
      }
      break;
    case 2: //all lists same length, last sub-list migth contain elements from the beginning of the List
      for(i = 0; i < nElements; i += step) {
        newList = new List();
        for(j = 0; j < subListsLength; j++) {
          newList.push(list[(i + j) % nElements]);
        }
        table.push(newList.getImproved());
      }
      break;
  }

  return table.getImproved();
};

/**
 * @todo write docs
 */
ListOperators.getNewListForObjectType = function(object) {
  var newList = new List();
  newList[0] = object;
  return instantiateWithSameType(newList.getImproved());
};


/*
deprectaed, use intersection instead
 */
// ListOperators.listsIntersect = function(list0, list1) {
//   var list = list0.length < list1.length ? list0 : list1;
//   var otherList = list0 == list ? list1 : list0;
//   for(var i = 0; list[i] != null; i++) {
//     if(otherList.indexOf(list[i]) != -1) return true;
//   }
//   return false;
// };


/**
 * creates a List that contains the union of up to five Lists (removing repetitions)
 * @param  {List} list0 first list
 * @param  {List} list1 second list
 *
 * @param  {List} list2 third list
 * @param  {List} list3 fourth list
 * @param  {List} list4 fifth list
 * @return {List} the union of all Lists
 * tags:combine
 */
ListOperators.union = function(list0, list1, list2, list3, list4) {
  if(list0==null || list1==null) return;

  var union = new List();
  var l0 = list0.length;
  var l1 = list1.length;
  var l2 = list2 == null ? 0 : list2.length;
  var l3 = list3 == null ? 0 : list3.length;
  var l4 = list4 == null ? 0 : list4.length;
  var i, k;

  if(list0.type=='NodeList' || list1.type=='NodeList'){
    union = new NodeList();
    union = list0.clone();
    for(i = 0; i<l1; i++){
      if(list0.getNodeById(list1[i].id)==null) union.addNode(list1[i]);
    }
    if(list2) union = ListOperators.union(union,list2);
    if(list3) union = ListOperators.union(union,list3);
    if(list4) union = ListOperators.union(union,list4);
    return union;
  }

  var obj = {};

  for(i = 0; i<l0; i++) obj[list0[i]] = list0[i];
  for(i = 0; i<l1; i++) obj[list1[i]] = list1[i];
  for(i = 0; i<l2; i++) obj[list2[i]] = list2[i];
  for(i = 0; i<l3; i++) obj[list3[i]] = list3[i];
  for(i = 0; i<l4; i++) obj[list4[i]] = list4[i];
  
  for(k in obj) {
    union.push(obj[k]);
  }
  return union.getImproved();
};

/**
 * creates a List that contains the intersection of up to five Lists (elements present in ALL lists, result without repetitions)
 * @param  {List} list0 first list
 * @param  {List} list1 second list
 *
 * @param  {List|Object} list2 third list, can also be a pre-calculated boolean dictionary for list0 (built with ListOperators.getBooleanDictionaryForList), for faster operation
 * @param  {List} list3 fourth list
 * @param  {List} list4 fifth list
 * @return {List} intersection of both lists
 * tags:compare
 */
ListOperators.intersection = function(list0, list1, list2, list3, list4) {
  if(list0==null || list1==null) return;

  // handle optional case where list2 is actually a dictionary
  var booleanDictionary0 = null;
  if(!Array.isArray(list2) && typeof list2 == 'object'){
    booleanDictionary0 = list2;
    list2 = null;
  }
  var intersection;
  var l0  = list0.length;
  var l1  = list1.length;
  var i;
  var element;

  if(list0.type=="NodeList" && list1.type=="NodeList"){
    intersection = new NodeList();
    for(i=0; i<l0; i++){
      if(list1.getNodeById(list0[i].id))
        intersection.addNode(list0[i]);
    }
    if(list2) intersection = ListOperators.intersection(intersection,list2);
    if(list3) intersection = ListOperators.intersection(intersection,list3);
    if(list4) intersection = ListOperators.intersection(intersection,list4);
    return intersection;
  }


  var dictionary =  booleanDictionary0==null?ListOperators.getBooleanDictionaryForList(list0):booleanDictionary0;
  var dictionaryIntersected = {};
  var dict2 = list2 == null ? null : ListOperators.getBooleanDictionaryForList(list2);
  var dict3 = list3 == null ? null : ListOperators.getBooleanDictionaryForList(list3);
  var dict4 = list4 == null ? null : ListOperators.getBooleanDictionaryForList(list4);
  
  intersection = new List();

  // in case where we have list2->4 we want to avoid recursive calls so we don't do getImproved over and over
  for(i=0; i<l1; i++){
    element = list1[i];
    if(dictionaryIntersected[element]==null && dictionary[element] &&
      (dict2 == null || dict2[element] ) &&
      (dict3 == null || dict3[element] ) &&
      (dict4 == null || dict4[element] ) ){

      dictionaryIntersected[element]=true;
      intersection.push(element);
    }
  }

  return intersection.getImproved();
};

/**
 * creates a List that contains the difference between two lists (subtracting the second list to the first)
 * @param  {List} list0 first list
 * @param  {List} list1 second list
 * @return {List} list0 subtracted list1
 * tags:compare
 */
ListOperators.difference = function(list0, list1) {
  if(list0==null || list1==null) return;

  var dictionary =  ListOperators.getBooleanDictionaryForList(list1);
  var dictionaryDif =  {};
  var i;
  var difference = new List();
  var l0  = list0.length;

  if(list0.type=="NodeList"){
    //@todo:finish
  }

  for(i=0; i<l0; i++){
    if(!dictionary[list0[i]] && !dictionaryDif[list0[i]]){
      difference.push(list0[i]);
      dictionaryDif[list0[i]] = true;
    }
  }

  return difference.getImproved();
};

/**
 * creates a List that contains the symmetric difference of two List (elements present in only one of the lists)
 * @param  {List} list0 first list
 * @param  {List} list1 second list
 * @return {List} symmetric difference of two lists
 * tags:compare
 */
ListOperators.symmetricDifference = function(list0, list1) {
  if(list0==null || list1==null) return;

  var dictionary0 =  ListOperators.getBooleanDictionaryForList(list0);
  var dictionary1 =  ListOperators.getBooleanDictionaryForList(list1);
  var dictionaryDif =  {};
  var i;
  var difference = new List();
  var l0  = list0.length;
  var l1  = list1.length;

  if(list0.type=="NodeList"){
    //@todo:finish
  }

  for(i=0; i<l0; i++){
    if(!dictionary1[list0[i]] && !dictionaryDif[list0[i]]){
      difference.push(list0[i]);
      dictionaryDif[list0[i]] = true;
    }
  }
  for(i=0; i<l1; i++){
    if(!dictionary0[list1[i]] && !dictionaryDif[list1[i]]){
      difference.push(list1[i]);
      dictionaryDif[list1[i]] = true;
    }
  }

  return difference.getImproved();
};




/**
 * returns the list of common elements between two lists (deprecated, use intersection instead)
 * @param  {List} list0
 * @param  {List} list1
 * @return {List}
 */
// ListOperators.getCommonElements = function(list0, list1) {
//   var nums = list0.type == 'NumberList' && list1.type == 'NumberList';
//   var strs = list0.type == 'StringList' && list1.type == 'StringList';
//   var newList = nums ? new NumberList() : (strs ? new StringList() : new List());

//   var list = list0.length < list1.length ? list0 : list1;
//   var otherList = list0 == list ? list1 : list0;

//   for(var i = 0; list[i] != null; i++) {
//     if(otherList.indexOf(list[i]) != -1) newList.push(list[i]);
//   }
//   if(nums || strs) return newList;
//   return newList.getImproved();
// };


/**
 * calculates Jaccard index |list0 ∩ list1|/|list0 ∪ list1| see: https://en.wikipedia.org/wiki/Jaccard_index
 * @param  {List} list0
 * @param  {List} list1
 *
 * @param  {Number} sigma value added to the intersection, so two lists are more distant whenever the interestion is small or 0 and the union gets bigger ( [A,B] closer to [P,Q] than [A,B,C,D,D] to [P,Q,R,S,T,U,V] the later pair holding more differences)
 * @param  {Object} booleanDictionary0 optional pre-calculated boolean dictionary for list0 (built with ListOperators.getBooleanDictionaryForList), for faster operation
 * @return {Number}
 * tags:statistics,compare
 */
ListOperators.jaccardIndex = function(list0, list1, sigma, booleanDictionary0) {//TODO: see if this can be more efficient, maybe one idctionar for doing union and interstection at the same time
  if(list0==null || list1==null) return;
  if(list0.length===0 && list1.length===0) return 0;
  sigma = sigma==null?0:sigma;

  var inter = ListOperators.intersection(list0, list1, booleanDictionary0).length;
  return (inter+sigma)/(list0.getWithoutRepetitions().length + list1.getWithoutRepetitions().length - inter);
};


/**
 * calculates Jaccard distance 1 - |list0 ∩ list1|/|list0 ∪ list1| see: https://en.wikipedia.org/wiki/Jaccard_index
 * @param  {List} list0
 * @param  {List} list1
 * @return {Number}
 * tags:statistics,compare
 */
ListOperators.jaccardDistance = function(list0, list1) {
  return 1 - ListOperators.jaccardIndex(list0, list1);
};


/**
 * filters a list by different criteria
 * @param  {List} list to be filtered
 * @param  {String} operator <|>=c:(default, exact match for numbers, contains for strings)<|>==<|>&lt;<|>&lt;=<|>&gt;<|>&gt;=<|>!=<|>contains<|>between<|>init<|>property value: property name on value, property value on value2
 * @param  {Object} value to compare against
 *
 * @param  {List} otherList optionally used to verify condition on elements, instead of given list (selected elements belong to original list)
 * @param  {Object} value2 used for "between" and "property value" operators
 * @param  {Number} mode <|>0:returns filtered list<|>1:returns indexes<|>2:boolean list
 * @return {Number}
 * tags:filter
 */
ListOperators.filterList = function(list, operator, value, otherList, value2, mode){
  if(list==null) return;

  if(operator==null) operator='=c';
  if(operator == '=') operator = '==';

  if(otherList==null) otherList = list;

  if(mode===true) mode=1;//from previous version in which 

  
  var l = list.length;
  var i;

  if(mode==2){
    var booleans = new mo.List();

    switch(operator){
      case "==":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]===value);
        }
        break;
      case "<":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]<value);
        }
        break;
      case "<=":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]<=value);
        }
        break;
      case ">":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]>value);
        }
        break;
      case ">=":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]>=value);
        }
        break;
      case "!=":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]!==value);
        }
        break;
      case "contains":
        for(i=0; i<l; i++){
          booleans.push(otherList[i].includes(value));
        }
        break;
      case "between":
        for(i=0; i<l; i++){
          booleans.push(otherList[i]>=value && otherList[i]<=value2);
        }
        break;
      case "init":
        for(i=0; i<l; i++){
          booleans.push(otherList[i].indexOf(value)===0);
        }
        break;
      case "property value":
        for(i=0; i<l; i++){
          booleans.push(otherList[i][value]==value2);
        }
        break;
    }
    return booleans;
  }

  var newList = new List();
  var listToUse = (mode==1)?NumberListGenerators.createSortedNumberList(list.length):list;

  switch(operator){
    case "==":
      for(i=0; i<l; i++){
        if(otherList[i]==value) newList.push(listToUse[i]);
      }
      break;
    case "<":
      for(i=0; i<l; i++){
        if(otherList[i]<value) newList.push(listToUse[i]);
      }
      break;
    case "<=":
      for(i=0; i<l; i++){
        if(otherList[i]<=value) newList.push(listToUse[i]);
      }
      break;
    case ">":
      for(i=0; i<l; i++){
        if(otherList[i]>value) newList.push(listToUse[i]);
      }
      break;
    case ">=":
      for(i=0; i<l; i++){
        if(otherList[i]>=value) newList.push(listToUse[i]);
      }
      break;
    case "!=":
      for(i=0; i<l; i++){
        if(otherList[i]!=value) newList.push(listToUse[i]);
      }
      break;
    case "contains":
      for(i=0; i<l; i++){
        if(otherList[i].includes(value)) newList.push(listToUse[i]);
      }
      break;
    case "between":
      for(i=0; i<l; i++){
        if(otherList[i]>=value && otherList[i]<=value2) newList.push(listToUse[i]);
      }
      break;
    case "init":
      for(i=0; i<l; i++){
        if(otherList[i].indexOf(value)===0) newList.push(listToUse[i]);
      }
      break;
    case "property value":
      for(i=0; i<l; i++){
        if(otherList[i][value]==value2) newList.push(listToUse[i]);
      }
      break;
  }
  return newList.getImproved();
};

/**
 * applies a function on list elements and return new list, "iterator", the function will receive, iteratively, the following elements: list[i], i, param0, param1… or list[i], param0, param1… if optional iterator is false
 * @param  {List} list
 * @param  {Function} func function to be applied, the function receives the element, its position on the list (if iterator is set to true), and, optionally, two parameters func(list[i], i, param0, param1). Alternatively it can receive the name of an operator (ietartor will be automatically set to false)
 *
 * @param {Object} param0 optional param to be sent to function (will receive it after index)
 * @param {Object} param1 optional param to be sent to function (will receive it after param0)
 * @param {String} listName name for new list
 * @param {Object} param2 optional param to be sent to function (will receive it after param1)
 * @param {Object} param3 optional param to be sent to function (will receive it after param2)
 * @param {Object} param4 optional param to be sent to function (will receive it after param3)
 * @param {Object} param5 optional param to be sent to function (will receive it after param4)
 * @param {boolean} iterator (default:true) if true, the second parameter the function receives is the iteration index, if false the function receives elements of the list and parameters
 * @return {List}
 * tags:transform
 * examples:santiago/examples/countWordsDichotomyAnalysis
 */
ListOperators.mapFunctionOnList = function(list, func, param0, param1, listName, param2, param3, param4, param5, iterator){
  if(list==null || func==null) return;
  if(iterator==null) iterator=true;

  if(typeof(func)=="string"){
    iterator = false;
    func = mo.StringOperators.stringToFunction(func);
  }

  var newList = new List();
  var l = list.length;
  var i;

  if(iterator){
    for(i=0; i<l; i++){
      newList[i] = func.call(this, list[i], i, param0, param1, param2, param3, param4, param5);
    }
  } else {
    
    for(i=0; i<l; i++){
      var args = [list[i]];
      if(param0!=null) args[1] = param0;
      if(param1!=null) args[2] = param1;
      if(param2!=null) args[3] = param2;
      if(param3!=null) args[4] = param3;
      if(param4!=null) args[5] = param4;
      if(param5!=null) args[6] = param5;
      newList[i] = func.apply(this, args);
    }
  }

  newList.name = listName;

  return newList.getImproved();
};

/**
 * applies a function on list elements and return new list
 * @param  {List} list of elements used as parameters
 * @param  {List} functionList List of functions to be applied (or strings), each function receives the element, its position on the list, and, optionally, two parameters func(list[i], i, param0, param1)
 *
 * @param {Object} param0 optional param to be sent to function (will receive it after index)
 * @param {Object} param1 optional param to be sent to function (will receive it after param0)
 * @return {List}
 * tags:advanced,transform
 */
ListOperators.mapFunctionsOnList = function(list, functionList, param0, param1){
  if(list==null || functionList==null) return;

  var listResults = new List();
  var l = Math.min(list.length, functionList.length);

  for(var i=0; i<l; i++){
    var func = functionList[i];
    if(typeof(func)=="string") func = mo.StringOperators.stringToFunction(func);
    var args = [list[i]];
    if(param0!=null) args[1] = param0;
    if(param1!=null) args[2] = param1;
    listResults[i] = func.apply(this, args);
  }
  return listResults.getImproved();
};

ListOperators._aggregationDictionary = {
  "first element":0,
  "count":1,
  "sum":2,
  "average":3,
  "mean":3,
  "min":4,
  "max":5,
  "standard deviation":6,
  "sd":6,
  "enlist":7,
  "last element":8,
  "most common element":9,
  "most common value":9,
  "most common":9,
  "common":9,
  "random element":10,
  "random value":10,
  "random":10,
  "indexes":11,
  "count non repeated elements":12,
  "count non-repeated elements":12,
  "count non repeated values":12,
  "count non-repeated values":12,
  "count non repeated":12,
  "count non-repeated":12,
  "enlist non-repeated elements":13,
  "enlist non repeated elements":13,
  "enlist non-repeated values":13,
  "enlist non repeated values":13,
  "enlist non-repeated":13,
  "enlist non repeated":13,
  "concat elements":14,
  "concat":14,
  "concat non-repeated elements":15,
  "concat non repeated elements":15,
  "concat non-repeated values":15,
  "concat non repeated values":15,
  "concat non-repeated":15,
  "concat non repeated":15,
  "frequencies tables":16,
  "frequencies":16,
  "concat":17,
  "average weighted by listWeight parameter":18,
  "median":19,
  "function":20,
  "0":0,
  "1":1,
  "2":2,
  "3":3
};

/**
 * aggregates values of a list using an aggregator list as reference
 *
 * @param  {List} aggregatorList aggregator list that typically contains several repeated elements
 * @param  {List} toAggregateList list of elements that will be aggregated
 * @param  {Number} mode aggregation modes:<|>0:first element (default)<|>1:count<|>2:sum<|>3:average<|>4:min<|>5:max<|>6:standard deviation<|>7:enlist (creates a list of elements)<|>8:last element<|>9:most common element<|>10:random element<|>11:indexes<|>12:count non repeated elements<|>13:enlist non repeated elements<|>14:concat elements (for strings, uses ', ' as separator)<|>15:concat non-repeated elements<|>16:frequencies tables<|>17:concat (for strings, no separator)<|>18:average weighted by listWeight parameter<|>19:median<|>20:function (must be provided in last inlet)
 *
 * @param  {Table} indexesTable optional already calculated table of indexes of elements on the aggregator list (if not provided, the method calculates it)
 * @param  {NumberList} weightList list of numbers used for weighted average calculations
 * @param {Function} aggregationFunction aggregation function (mode 20)
 * @return {Table} contains a list with non repeated elements on the first list, and the aggregated elements on a second list
 * tags:transform
 */
ListOperators.aggregateList = function(aggregatorList, toAggregateList, mode, indexesTable, weightList, aggregationFunction){
  if(aggregatorList==null || toAggregateList==null) return null;
  var table = new Table();

  if(mode!=null && typeOf(mode)=='string') mode = ListOperators._aggregationDictionary[mode];
  if(mode == 18 && weightList == null) throw Error("weightList must be defined to aggregate to a weighted average.");

  indexesTable = aggregatorList.indexesTable;

  if(indexesTable==null){
    indexesTable = ListOperators.getIndexesTable(aggregatorList);
    aggregatorList.indexesTable = indexesTable;
  }
  
  if(mode==11) return indexesTable;
  

  table[0] = indexesTable[0];

  if(mode===0 && aggregatorList==toAggregateList){
    table[1] = indexesTable[0];
    return table;
  }

  mode = mode==null?0:mode;

  var list;
  var elementsTable;
  var nIndexes = indexesTable[1].length;
  var indexes;
  var index;
  var elements;
  var i, j;

  switch(mode){
    case 0://first element
      table[1] = new List();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        table[1].push(toAggregateList[indexes[0]]);
      }
      table[1] = table[1].getImproved();
      return table;
    case 1://count
      table[1] = new NumberList();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        table[1].push(indexes.length);
      }
      return table;
    case 2://sum
    case 3://average
    case 18://weighted average
    case 19://median
      var sum,sumWeighted,sumWeights;
      table[1] = new NumberList();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        sum = 0;
        sumWeighted = 0;
        sumWeights = 0;
        //indexes.forEach(function(index){
          var nLValues = new NumberList();
        for(j=0; j<indexes.length; j++){
          index = indexes[j];
          sum+=toAggregateList[index];
          if(mode==19)
            nLValues.push(toAggregateList[index]);
          if(mode==18){
            sumWeighted+=toAggregateList[index]*weightList[index];
            sumWeights +=weightList[index];
          }
        }
        if(mode==3) sum/=indexes.length;
        else if(mode==18) sum = (sumWeights==0) ? 0 : sumWeighted/sumWeights;
        if(mode==19) sum = nLValues.getMedian();
        // get rid of most common case of floating point errors leading to lots of garbage decimals
        if(mode == 2 && !Number.isInteger(sum))
          sum = Number(parseFloat(sum).toPrecision(12));
        table[1].push(sum);
      }
      return table;
    case 4://min
      var min;
      table[1] = new NumberList();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        min = 99999999999;
        //indexes.forEach(function(index){
        for(j=0; j<indexes.length; j++){
          index = indexes[j];
          min=Math.min(min, toAggregateList[index]);
        }
        table[1].push(min);
      }
      return table;
    case 5://max
      var max;
      table[1] = new NumberList();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        max = -99999999999;
        //indexes.forEach(function(index){
        for(j=0; j<indexes.length; j++){
          index = indexes[j];
          max=Math.max(max, toAggregateList[index]);
        }
        table[1].push(max);
      }
      return table;
    case 6://standard deviation
      var average;
      table = ListOperators.aggregateList(aggregatorList, toAggregateList, 3, indexesTable);
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        sum = 0;
        average = table[1][i];
        //indexes.forEach(function(index){
        for(j=0; j<indexes.length; j++){
          index = indexes[j];
          sum += Math.pow(toAggregateList[index] - average, 2);
        }
        table[1][i] = Math.sqrt(sum/indexes.length);
      }
      return table;
    case 16://frequency table
    case 7://enlist
      table[1] = new Table();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        list = new List();
        //indexes.forEach(function(index){
        for(j=0; j<indexes.length; j++){
          index = indexes[j];
          if(toAggregateList[index]!=null) list.push(toAggregateList[index]);
        }
        
        list = list.getImproved();

        if(mode==16){
          list = list.getFrequenciesTable(true);
        }

        table[1].push(list);
      }
      if(mode==16) return table;
      return table.getImproved();
    case 8://last element
      table[1] = new List();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        table[1].push(toAggregateList[indexes[indexes.length-1]]);
      }
      table[1] = table[1].getImproved();
      return table;
    case 9://most common
      table[1] = new List();

      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      //elementsTable[1].forEach(function(elements){
      for(i=0; i<elementsTable[1].length; i++){
        elements = elementsTable[1][i];
        table[1].push(elements.getMostRepeatedElement());
      }
      table[1] = table[1].getImproved();
      
      return table;
    case 10://random
      table[1] = new List();
      //indexesTable[1].forEach(function(indexes){
      for(i=0; i<nIndexes; i++){
        indexes = indexesTable[1][i];
        table[1].push( toAggregateList[indexes[ Math.floor(Math.random()*indexes.length) ]] );
      }
      table[1] = table[1].getImproved();
      return table;
    case 11://indexes (returned previosuly)
      break;
    case 12://count non repeated
      table[1] = new NumberList();
      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      for(i=0;i<elementsTable[1].length;i++){
        elements = elementsTable[1][i];
        table[1].push(elements.getWithoutRepetitions().length);
      }
      return table;
    case 13://enlist non repeated
      table[1] = new List();
      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      for(i=0;i<elementsTable[1].length;i++){
        elements = elementsTable[1][i];
        table[1].push(elements.getWithoutRepetitions());
      }
      table[1] = table[1].getImproved();
      return table;
    case 14://concat string ", "
    case 17://concat string
      var sep = mode==14?", ":"";
      table[1] = new StringList();
      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      for(i=0;i<elementsTable[1].length;i++){
        elements = elementsTable[1][i];
        table[1].push( elements.join(sep) );
      }
      return table;
    case 15://concat with "," string non repeated
      table[1] = new StringList();
      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      //elementsTable[1].forEach(function(elements){
      for(i=0;i<elementsTable[1].length;i++){
        elements = elementsTable[1][i];
        table[1].push( elements.getWithoutRepetitions().join(', ') );
      }
      return table;
    case 16://frequencies table (solved on case 7)
      break;
    case 20://aggregation function
      table[1] = new List();
      elementsTable = ListOperators.aggregateList(aggregatorList, toAggregateList, 7, indexesTable);
      for(i=0;i<elementsTable[1].length;i++){
        elements = elementsTable[1][i];
        table[1].push( aggregationFunction(elements) );
      }
      table[1] = table[1].getImproved();
      return table;
  }

  return null;
};

/**
 * Analyses wether two lists are categorical identical, one is subcategorical to the other, or there's no relation
 * @param  {List} list0
 * @param  {List} list1
 * @return {Number} 0:no relation, 1:categorical identical, 2:list0 subcategorical to list1, 3:list1 subcategorical to list0
 * tags:compare
 */
ListOperators.subCategoricalAnalysis = function(list0, list1){
  if(list0==null || list1==null) return;

  var dictionary = {};
  var element, projection;
  var i;
  var list0SubCategorical = true;
  for(i=0; list0[i]!=null; i++){
    element = list0[i];
    projection = dictionary[element];
    if(projection==null){
      dictionary[element] = list1[i];
    } else if(projection!=list1[i]){
      list0SubCategorical = false;
      break;
    }
  }

  dictionary = {};
  var list1SubCategorical = true;
  for(i=0; list1[i]!=null; i++){
    element = list1[i];
    projection = dictionary[element];
    if(projection==null){
      dictionary[element] = list0[i];
    } else if(projection!=list0[i]){
      list1SubCategorical = false;
      break;
    }
  }

  if(list1SubCategorical && list0SubCategorical) return 1;
  if(list0SubCategorical) return 2;
  if(list1SubCategorical) return 3;
  return 0;
};

/**
 * calculates de entropy of a list, properties _mostRepresentedValue and _biggestProbability are added to the list
 * @param  {List} list with repeated elements (actegorical list)
 *
 * @param {Object} valueFollowing if a value is provided, the property _P_valueFollowing will be added to the list, with proportion of that value in the list
 * @param {Table} freqTable for saving time, in case the frequency table with sorted elements has been already calculated (with list.getFrequenciesTable(true))
 * @param {Number} normalizedMode <|>0:no normalization (values can be >1)<|>1: simple normalization, max value is 1<|>2:super-normalization, taking into account minimal entropy for the number of different elements in the list
 * @return {Number}
 * tags:statistics,advanced
 */
ListOperators.getListEntropy = function(list, valueFollowing, freqTable, normalizedMode) {
  if(list == null) return;

  if(list.length < 2) {
    if(list.length == 1) {
      list._mostRepresentedValue = list[0];
      list._biggestProbability = 1;
      if(valueFollowing != null) list._P_valueFollowing = list[0] == valueFollowing ? 1 : 0;
    } else {
      if(valueFollowing != null) list._P_valueFollowing = 0;
    }
    return 0;
  }

  if(list.infoObject!=null) freqTable = list.infoObject.frequenciesTable;

  if(freqTable==null) freqTable = list.getFrequenciesTable(true);// ListOperators.countElementsRepetitionOnList(list, true);

  list._mostRepresentedValue = freqTable[0][0];
  var N = list.length;
  list._biggestProbability = freqTable[1][0] / N;
  if(freqTable[0].length == 1) {
    list._P_valueFollowing = list[0] == valueFollowing ? 1 : 0;
    return 0;
  }
  var entropy = 0;

  var norm = Math.log(freqTable[0].length);
  var l = freqTable[1].length;//number of different elements (change the name of the variable!!!!!)
  var i;
  var val;
  for(i=0; i<l; i++){
    val = freqTable[1][i];
    entropy -= val/N * Math.log(val/N);// / norm;
  }

  if(normalizedMode>0) entropy*=(1/norm);

  /*
  if(normalizedMode>=1){
    var min = ( -((l-1)/N)*Math.log(1/N) - ((N-(l-1))/N)*Math.log((N-(l-1))/N) )/norm;
    entropy = (entropy-min)/(1-min);
  }
  */
 
  if(normalizedMode==2){
    var mintropy = function(nV, n){
        if(nV==1) return 0;
        if(nV==n) return 1;
        var fq = n-(nV-1);//frequency of single most repeated element
        return  ( -(nV-1)*(1/n)*Math.log(1/n) - 1*(fq/n)*Math.log(fq/n) )/Math.log(nV);
        
    }

    var min = mintropy(l, list.length);
    if(min<1) entropy = (entropy-min)/(1-min);
  }

  if(valueFollowing != null) {
    var index = freqTable[0].indexOf(valueFollowing);
    list._P_valueFollowing = index == -1 ? 0 : freqTable[1][index] / N;
  }
  return entropy;
};

/**
 * calculates de diverity of a List based on 3 methods: Simpson's diversity index (probability of two elements being equal), proportion of distinct elements in the list, entropy * proportion.
 * @param  {List} list with repeated elements 
 * 
 * @param {Number} mode <|>0:Simpson's diversity index (probability of two elements being different)<|>1: proportion of distinct elements in the list<|>2:entropy * proportion
 * @param {Table} freqTable for saving time, in case the frequency table with sorted elements has been already calculated (with list.getFrequenciesTable())
 * @return {Number}
 * tags:statistics,advanced
 */
ListOperators.getListDiversity = function(list, mode, freqTable) {
  mode = (mode == null? 0: mode);
  if ((mode < 0) || (mode > 2) || (list == null)) return;    
  if(list.length<2) return 0;    

  if (!list["isList"]) {
      if(Array.isArray(list)) list = List.fromArray(list);
      else return;
  }

  if (list.infoObject != null) freqTable = list.infoObject.frequenciesTable;
  if (freqTable == null) freqTable = list.getFrequenciesTable();

  switch(mode){
    case 0:
      var sum=0;
      var total = list.length*(list.length-1);
      for(var i=0; i<freqTable[1].length; i++){
          sum+=freqTable[1][i]*(freqTable[1][i]-1);
      }
      return (1 - sum/total);
    case 1:
      return freqTable[0].length/list.length;
    case 2:
      return ListOperators.getListEntropy(list,null,null,1)*freqTable[0].length/list.length;
  }
  return;
};

/**
 * measures how much a feature decreases entropy when segmenting by its values by a supervised variable
 * @param  {List} feature
 * @param  {List} supervised
 *
 * @param {Boolean} reductionRatioNormalization (default:false) if true returns the ratio of supervised entropy and average entropy of subsets, effectively meeasuring the overall reduction of entropy of one List by the other
 * @return {Number}
 * tags:ds,advanced,compare,statistics
 */
ListOperators.getInformationGain = function(feature, supervised, reductionRatioNormalization) {
  if(feature == null || supervised == null || feature.length != supervised.length) return null;

  var entS = supervised.infoObject==null?ListOperators.getListEntropy(supervised):supervised.infoObject.entropy;
  var ig = entS;
  var childrenObject = {};
  var childrenLists = new Table();
  var i;
  var N = feature.length;
  var element;


  //feature.forEach(function(element, i) {
  for(i=0; i<N; i++){
    element = feature[i];
    if(childrenObject[element] == null) {
      childrenObject[element] = new List();
      childrenLists.push(childrenObject[element]);
    }
    childrenObject[element].push(supervised[i]);
  }//);

  N = childrenLists.length;//.getLengths().getSum();
  var n_total = supervised.length;//childrenLists.getLengths().getSum();

  //console.log('-------------');
  //console.log('N:', N);
  //console.log('n_total:', n_total);
  //console.log('entropy parent:', ig);

  var entropy;
  var enCond = 0; //conditional entropy
  
  //childrenLists.forEach(function(cl) {
  for(i=0; i<N; i++){
    //console.log("["+childrenLists[i].join(',')+"]", "entropy:", ListOperators.getListEntropy(childrenLists[i]));
    //console.log("     childrenLists[i].length", childrenLists[i].length);
    //console.log("     (childrenLists[i].length / n_total)", (childrenLists[i].length / n_total));
    //console.log("     (childrenLists[i].length / n_total) * ListOperators.getListEntropy(childrenLists[i])", (childrenLists[i].length / n_total) * ListOperators.getListEntropy(childrenLists[i]));
    entropy = (childrenLists[i].infoObject==null?ListOperators.getListEntropy(childrenLists[i]):childrenLists[i].infoObject.entropy);
    enCond += (childrenLists[i].length / n_total) * entropy;
  }

  var ig = entS - enCond; // non-normalized ig

  if(reductionRatioNormalization) {
      ig = ig/entS; //ig normalizaed as ratio
  }

  //});

  return ig;
};

/**
 * @todo write docs
 */
ListOperators.getInformationGainAnalysis = function(feature, supervised) {
  if(feature == null || supervised == null || feature.length != supervised.length) return null;

  var ig = ListOperators.getListEntropy(supervised);
  var childrenObject = {};
  var childrenLists = [];
  var N = feature.length;
  var entropy;
  var sets = new List();

  feature.forEach(function(element, i) {
    if(childrenObject[element] == null) {
      childrenObject[element] = new List();
      childrenLists.push(childrenObject[element]);
    }
    childrenObject[element].push(supervised[i]);
  });

  childrenLists.forEach(function(cl) {
    entropy = ListOperators.getListEntropy(cl);
    ig -= (cl.length / N) * entropy;

    sets.push({
      children: cl,
      entropy: entropy,
      infoGain: ig
    });
  });

  return sets;
};


/**
 * Takes a List and returns its elements grouped by identic value. Each list in the table is assigned a "valProperty" value which is used for sorting
 * @param  {List} list of elements to group
 * @param  {Boolean} whether the results are to be sorted or not
 * @param  {Number} mode: 0 for returning original values, 1 for indices in original list
 *
 * @param  {Boolean} fillBlanks: whether to fill missing slots or not (if data is sequential)
 * @return {Table}
 * tags:dani
 */
ListOperators.groupElements = function(list, sortedByValue, mode, fillBlanks) {
  if(!list)
    return;
  var result = ListOperators._groupElements_Base(list, null, sortedByValue, mode, fillBlanks);
  return result;
};


/**
 * Takes a List and returns its elements grouped by identic value. Each list in the table is assigned a "valProperty" value which is used for sorting
 * @param  {List} list of elements to group
 * @param  {String} name of the property to be used for grouping
 * @param  {Boolean} wether the results are to be sorted or not
 * @param  {Number} mode: 0 for returning original values, 1 for indices in original list
 *
 * @param  {Boolean} fillBlanks: whether to fill missing slots or not (if data is sequential)
 * @return {Table}
 * tags:dani
 */
ListOperators.groupElementsByPropertyValue = function(list, propertyName, sortedByValue, mode, fillBlanks) {
  if(!list)
    return;
  var result = ListOperators._groupElements_Base(list, propertyName, sortedByValue, mode, fillBlanks);
  return result;
};



/**
 * @ignore
 */
ListOperators._groupElements_Base = function(list, propertyName, sortedByValue, mode, fillBlanks) {
  if(!list)
    return;
  if(mode == undefined){
    mode = 0;
  }
  var resultOb = {};
  var resultTable = new Table();
  var pValue, item, minValue, maxValue, i;
  for(i = 0; i < list.length; i++) {
    item = list[i];
    pValue = propertyName == undefined ? item : item[propertyName];
    if(resultOb[pValue] === undefined) {
      resultOb[pValue] = new List();
      resultOb[pValue].name = pValue;
      resultOb[pValue].valProperty = pValue;
      resultTable.push(resultOb[pValue]);
    }
    if(mode === 0)
      resultOb[pValue].push(item);
    else if(mode == 1)
      resultOb[pValue].push(i);
    // Update boundaries
    if(minValue === undefined || pValue < minValue) {
      minValue = pValue;
    }
    if(maxValue === undefined || pValue > maxValue) {
      maxValue = pValue;
    }
  }

  // Fill the blanks
  if(fillBlanks) {
    var numBlanks = 0;
    for(i = minValue; i < maxValue; i++) {
      if(resultOb[i] === undefined) {
        resultOb[i] = new List();
        resultOb[i].name = i;
        resultOb[i].valProperty = i;
        resultTable.push(resultOb[i]);
        numBlanks++;
      }
    }
    //console.log("numBlanks: ", numBlanks)
  }

  // To-do: looks like getSortedByProperty is removing the valProperty from the objects
  if(sortedByValue)
    resultTable = resultTable.getSortedByProperty("name"); // "valProperty"

  return resultTable;

};


/**
 * expands a Lists repeating its elements by given amounts. Builds a new, longer, List, with each element repeated a number of times indicated by a numberList
 * @param  {List} list of elements to repeat
 * @param  {NumberList} number of repetitions for each element in list (if repetitions is shorter than list, it cycles through it with %)
 * @return {List}
 * tags:transform
 */
ListOperators.repeatElements = function(list, repetitions) {
  if(!list || !repetitions)
    return;
  var newList = instantiateWithSameType(list);
  newList.name = list.name;

  for (var i = 0; i<list.length; i++) {
    for (var j = 0; j < repetitions[i%repetitions.length]; j++) {
      newList.push(list[i]);
    };
  };

  return newList;
};




ListOperators._isCategorical = function(list, numberOfDifferentElements){
  //list unique < 20
  //at least one repeated
  return numberOfDifferentElements > 1 && numberOfDifferentElements<list.length && (list.length<=20 || numberOfDifferentElements/list.length<0.8);
};

/**
 * builds an object with statistical information about the list (infoObject property will be added to the list)
 * @param  {List} list
 *
 * @param  {Boolean} bUseExistingObjectIfPresent (default is true)
 * @return {Object}
 */
ListOperators.buildInformationObject = function(list, bUseExistingObjectIfPresent){
  if(list==null) return;

  bUseExistingObjectIfPresent = bUseExistingObjectIfPresent==null?true:bUseExistingObjectIfPresent;

  if(bUseExistingObjectIfPresent == true && list.infoObject != null) return list.infoObject;
  
  var n = list.length;
  var i, val;

  var infoObject = {
    isInfoObject:true,//useful for solving ambiguity when reading infoObject.type
    type:list.type,
    name:list.name,
    length:n
  };

  infoObject.frequenciesTable = list.getFrequenciesTable(true, true, true);
  infoObject.numberDifferentElements = infoObject.frequenciesTable[0].length;
  infoObject.mostFrequentElement = infoObject.frequenciesTable[0][0];
  infoObject.isNumeric =  list.type == "NumberList";

  infoObject.isCategorical = ListOperators._isCategorical(list, infoObject.numberDifferentElements);// infoObject.numberDifferentElements/list.length<0.8;
  infoObject.isLongTexts = false;
  infoObject.isDefault = false;

  infoObject.isNumericCategorical = infoObject.isNumeric && infoObject.isCategorical;

  infoObject.isUnique = infoObject.numberDifferentElements == list.length;

  infoObject.containsNulls = infoObject.frequenciesTable[0].includes(null) || infoObject.frequenciesTable[0].includes(undefined);
  infoObject.containsNullsOrEquivalent = infoObject.containsNulls || infoObject.frequenciesTable[0].includes("") || infoObject.frequenciesTable[0].includes("null");

  infoObject.isLongitude = false;
  infoObject.isLatitude = false;

  infoObject.isSmall = list.length<=100;

  if(list.type == "NumberList") {
    var min = 999999999999;
    var max = -999999999999;
    var average = 0;
    var standardDeviation = 0;
    var shorten = new NumberList();
    var index = 0;
    var accumsum = 0;
    var maxAccumsum = -999999999999;
    var sizeAccum = Math.max(Math.floor(list.length/50), 1);
    var allIntegers = true;

    //isSorted
    //isConsecutive
    //years like

    for(i=0; i<n; i++){
      val = list[i];
      min = Math.min(min, val);
      max = Math.max(max, val);
      average += val;
      accumsum += val;
      index++;
      if(val%1!==0) allIntegers = false;
      if(index==sizeAccum){
        accumsum /= index;
        maxAccumsum = Math.max(maxAccumsum, accumsum);
        shorten.push(accumsum);
        accumsum=0;
        index=0;
      }
    }

    if(index !== 0){
        accumsum /=index;
        maxAccumsum = Math.max(maxAccumsum, accumsum);
        shorten.push(accumsum);
    }

    shorten = shorten.factor(1/maxAccumsum);
    var sum = average;
    average /= list.length;

    for(i = 0; i<n; i++) {
      standardDeviation += Math.pow(list[i] - average, 2);
    }



    infoObject.min = min;
    infoObject.max = max;
    infoObject.average = average;
    infoObject.sum = sum;
    //infoObject.median = list.getMedian(); //probably too costful
    infoObject.standardDeviation = Math.sqrt(standardDeviation/n);
    infoObject.allIntegers = allIntegers;
    infoObject.kind = allIntegers?"integer numbers":"numbers";
    infoObject.allPositive = infoObject.min>=0;
    infoObject.allNegative = infoObject.min<0;
    infoObject.histogram = NumberListOperators.rangeCounts(list, 50);
    // geo tests
    if(list.name){
      var sNameLower = list.name.toLowerCase().trim();
      if(infoObject.min >= -90 && infoObject.max <= 90){
        if(sNameLower == 'lat' || sNameLower == 'lat.' || sNameLower.match(/latitud.*/i))
          infoObject.isLatitude = true;
      }
      if(infoObject.min >= -180 && infoObject.max <= 180){
        if(sNameLower == 'lon' || sNameLower == 'lon.' || sNameLower == 'long' || sNameLower.match(/longitud.*/i) || sNameLower == 'long.' || sNameLower == 'lng')
          infoObject.isLongitude = true;
      }
    }
  }

  if(list.type != "NumberList" || infoObject.allIntegers) {
    
    infoObject.categoricalColors = infoObject.frequenciesTable[3];
    
    if(list.type=="StringList"){
      var textLengths = list.getLengths();

      infoObject.averageTextLengths = 0;
      infoObject.minTextLength = 999999999999999;
      infoObject.maxTextLength = 0;

      for(i=0; i<n; i++){
        infoObject.averageTextLengths+=textLengths[i];
        if(textLengths[i]>infoObject.maxTextLength){
          infoObject.maxTextLength = textLengths[i];
        }
        if(textLengths[i]<infoObject.minTextLength){
          infoObject.minTextLength = textLengths[i];
        }
      }
      infoObject.averageTextLengths = Number(NumberOperators.numberToString(infoObject.averageTextLengths/n, (infoObject.averageTextLengths/n < 10) ? 1 : 0));
      if(infoObject.averageTextLengths>40) infoObject.isLongTexts = true; //[!] completely arbitrary, there might be a better criteria

    }

    if(infoObject.isLatitude || infoObject.isLongitude){
      infoObject.kind = "geo coordinate";
    } else if(list.type=="StringList" && !infoObject.isCategorical){
      //if 80% of texts are different, they aren't reckoned as categories
      infoObject.kind = "strings";
    } else if(list.type=="StringList" && !infoObject.isCategorical){
      infoObject.kind = "strings";


    } else if(list.type=="List"){
      // Count number of category-like unique items and look at ratio
      var iCategoryLike=0;
      for(i=0; i<infoObject.frequenciesTable[0].length; i++){
        val=infoObject.frequenciesTable[0][i];
        if(isNaN(parseFloat(val)) || !isFinite(val))
          iCategoryLike++; // string
        else if(Math.floor(val) == val && val < 1000)
          iCategoryLike++; // simple integer less than 1000
      }
      if(infoObject.isCategorical && iCategoryLike/infoObject.numberDifferentElements>0.8)
        infoObject.kind = "categories";
      else
        infoObject.kind = "strings";
    } else if(list.type!="NumberList"){// ||  (list.type=="NumberList" && infoObject.numberDifferentElements/list.length<0.8) ){
      infoObject.kind = "categories";
    } else if(list.type=="NumberList"){
      infoObject.kind = "integer numbers";
      if(infoObject.numberDifferentElements/list.length <= 0.2 && infoObject.numberDifferentElements <= 30)
        infoObject.kind = "categories";
    }
  }

  //entropy for all lists (even if not categorical)
  infoObject.entropy = infoObject.numberDifferentElements==list.length?1:
                          (infoObject.numberDifferentElements==1?0:ListOperators.getListEntropy(list, null, infoObject.frequenciesTable));
                          
  infoObject.isLabel = list.type != "StringList" && infoObject.isUnique && infoObject.maxTextLength<100;
  infoObject.isDefault = !infoObject.isNumeric && !infoObject.isCategorical && !infoObject.isLongTexts;

  list.infoObject = infoObject;

  return infoObject;

};

/**
 * returns a string providing ifnormation about the list
 *
 * @param  {List} list
 * @param  {Number} level
 * @return {String}
 */
ListOperators.getReport = function(list, level, infoObject) { //TODO:complete
  if(list==null) return;

  infoObject = infoObject==null?ListOperators.buildInformationObject(list):infoObject;

  var ident = "\n" + (level > 0 ? StringOperators.repeatString("  ", level) : "");
  var text = level > 0 ? (ident + "////report of instance of List////") : "///////////report of instance of List//////////";

  var length = list.length;
  var i;

  text += ident + "name: " + list.name;
  text += ident + "type: " + list.type;
  text += ident + "kind: " + infoObject.kind;

  if(length === 0) {
    text += ident + "single element: [" + list[0] + "]";
    return text;
  } else {
    text += ident + "length: " + length;
    text += ident + "first element: [" + list[0] + "]";
  }

  switch(list.type) {
    case "NumberList":
      text += ident + "min: " + infoObject.min;
      text += ident + "max: " + infoObject.max;
      text += ident + "average: " + infoObject.average;
      //text += ident + (infoObject.allIntegers?"":"not ")+"all are integers";
      if(length < 41) {
        text += ident + "numbers: " + list.join(", ");
      }
      break;
    case "StringList":
      text += ident + "min element length: " + infoObject.minTextLength;
      text += ident + "avg element length: " + infoObject.averageTextLengths;
      text += ident + "max element length: " + infoObject.maxTextLength;
    case "List":
    case "ColorList":
      var freqTable = infoObject.frequenciesTable;//list.getFrequenciesTable(true);
      list._freqTable = freqTable;
      text += ident + "number of different elements: " + infoObject.numberDifferentElements;// freqTable[0].length;
      if(freqTable[0].length < 10) {
        text += ident + "elements frequency:";
      } else {
        text += ident + "some elements frequency:";
      }

      for(i = 0; i < freqTable[0].length && i < 10; i++) {
        text += ident + "  [" + String(freqTable[0][i]) + "]: " + freqTable[1][i];
      }

      var joined;
      if(list.type == "List") {
        joined = list.join("], [");
      } else {
        joined = ListConversions.toStringList(list).join("], [");
      }

      if(joined.length < 1000) text += ident + "strings: [" + joined + "]";
      break;

  }

  list._infoObject = infoObject;

  return text;
};


/**
 * returns an html string providing information about the list
 *
 * @param  {List} list
 * @param  {Number} level
 * @return {String}
 */
ListOperators.getReportHtml = function(list, level, infoObject) { //TODO:complete
  if(list==null) return;

  infoObject = infoObject==null?ListOperators.buildInformationObject(list):infoObject;
  //console.log('infoObject.entropy', infoObject.entropy);

  var ident = "<br>" + (level > 0 ? StringOperators.repeatString("&nbsp", level) : "");
  var text =  level > 0 ? "" : "<b><font style=\"font-size:18px\">list report</font></b>";

  var length = list.length;
  var i, n;

  var categoriesText = function(list, ident, infoObject){
    //console.log('infoObject.entropy', infoObject.entropy);
    var text = "";
    if(infoObject.entropy) text += ident + "entropy: <b>" + NumberOperators.numberToString(infoObject.entropy, 4) + "</b>";
    if(infoObject.frequenciesTable[0].length < list.length){
      text += ident + "number of different elements: <b>" + infoObject.frequenciesTable[0].length + "</b>";
    } else {
      text += ident + "<b>all elements are different</b>";
    }
    
    if(infoObject.frequenciesTable[0].length < 10) {
      text += ident + "elements frequency:";
    } else if(infoObject.frequenciesTable[0].length < list.length){
      text += ident + "some elements frequency:";
    }

    if(infoObject.frequenciesTable[0].length < list.length){
      for(i = 0; i < infoObject.frequenciesTable[0].length && i < 10; i++) {
        text += ident + "  [<b>" + String(infoObject.frequenciesTable[0][i]) + "</b>]: <font style=\"font-size:10px\"><b><font color=\""+ColorOperators.colorStringToHEX(infoObject.categoricalColors[i])+"\">" + infoObject.frequenciesTable[1][i] + "</font></b></font>";
      }
    }

    var joined;
    if(list.type == "List") {
      joined = list.join("], [");
    } else {
      joined = ListConversions.toStringList(list).join("], [");
    }

    if(joined.length < 21) text += ident + "contents: [" + joined + "]";

    return text;
  };

  if(list.name){
    text += ident + "name: <b>" + list.name + "</b>";
  } else {
    text += ident + "<i>no name</i>";
  }
  text += ident + "type: <b>" + list.type + "</b>";
  text += ident + "kind: <b>" + infoObject.kind + "</b>";

  if(length === 0) {
    text += ident + "single element: [<b>" + list[0] + "</b>]";
    return text;
  } else {
    text += ident + "length: <b>" + length + "</b>";
    text += ident + "first element: [<b>" + list[0] + "</b>]";
  }

  switch(list.type) {
    case "NumberList":
      text += ident + "min: <b>" + infoObject.min + "</b>";
      text += ident + "max: <b>" + infoObject.max + "</b>";
      text += ident + "average: <b>" + infoObject.average + "</b>";
      text += ident + "standard deviation: <b>" + infoObject.standardDeviation + "</b>";
      //text += ident + (infoObject.allIntegers?"":"not ")+"all are integers</b>";
      if(length < 41) {
        text += ident + "numbers: <b>" + list.join("</b>, <b>") + "</b>";
      }

      if(infoObject.kind=="categories" || infoObject.kind=="integer numbers") text += categoriesText(list, ident, infoObject);

      text += ident;
      n = infoObject.histogram.length;
      var histoNorm = NumberListOperators.normalizeToMax(infoObject.histogram);

      for(i=0; i<n; i++){
        text += "<font style=\"font-size:7px\"><font color=\""+ColorOperators.colorStringToHEX(ColorScales.blueToRed(histoNorm[i]))+"\">█</font></font>";
      }
      break;
    case "StringList":
      text += ident + "min element length: <b>" + infoObject.minTextLength + "</b>";
      text += ident + "avg element length: <b>" + infoObject.averageTextLengths + "</b>";
      text += ident + "max element length: <b>" + infoObject.maxTextLength + "</b>";
    case "List":
    case "ColorList":
      var freqTable = infoObject.frequenciesTable;
      var catColors = infoObject.categoricalColors;

      text += categoriesText(list, ident, infoObject);

      var weights = freqTable[2];
      var bars = StringOperators.createsCategoricalColorsBlocksHtml(weights, 55, catColors);
      if(bars.length < 1000){
        text += ident;
        text += "<font style=\"font-size:7px\">"+bars+"</font>";
      }

      break;
  }

  list._infoObject = infoObject;

  return text;
};


/**
 * returns a table containing all possible lists (overlooking sorting) of k elements, with k&lt;=n list length, see https://en.wikipedia.org/wiki/Combination
 * @param  {List} list
 * @param  {Number} k length of sublists
 * @return {Table} containing all sublists of size k
 * tags:combinatorics,advanced
 */
ListOperators.kCombinations = function(list, k){
  if(list==null || k==null) return;

  var kCombinations = new Table();
  var i, j;
  var head, tailkCombinations;

  if (k > list.length || k <= 0) {
    return kCombinations;
  }
  
  if (k == list.length) {
    kCombinations.push(list.clone());
    return kCombinations;
  }
  
  if (k == 1) {
    for (i = 0; i < list.length; i++) {
      kCombinations.push( new List(list[i]).getImproved() );
    }
    return kCombinations;
  }
  
  for (i = 0; i < list.length - k + 1; i++) {
    head = list.slice(i, i+1);
    tailkCombinations = ListOperators.kCombinations(List.fromArray(list.slice(i + 1)).getImproved(), k - 1);
    for (j = 0; j < tailkCombinations.length; j++) {
      kCombinations.push( List.fromArray( head.concat(tailkCombinations[j]) ).getImproved() );
    }
  }
  return kCombinations.getImproved();

};

/**
 * returns a table containing all possible sublists (overlooking sorting), that is all kCombinations with k = 1 … n, see https://en.wikipedia.org/wiki/Combination
 * @param  {List} list
 *
 * @param {Boolean} includeEmpty (default: false)
 * @return {Table} containing all sublists of sizes from 1 to n
 * tags:combinatorics
 */
ListOperators.allSubLists = function(list, includeEmpty){
  if(list==null) return null;

  var allSubLists  = new Table();
  var k;
  if(includeEmpty) allSubLists.push(new mo.List());
  for(k=1; k<=list.length; k++){
    allSubLists = Table.fromArray( allSubLists.concat( ListOperators.kCombinations(list, k) ) );
  }
  return allSubLists.getImproved();
};

/**
 * anonymize a list using several different techniques
 * @param  {List} list to be modified
 *
 * @param  {Number} mode to use for anonymization. Options are:<br>0: leave as it is<br>1: generate unique random string with same number of words<br>2: generate unique random integer<br>3: shuffle existing data elements<br>4: random numbers in same interval<br>5: random numbers from comparable normal distribution<br>6: simplify numeric values to fewer sig digits<br>7: add noise to numbers based on small fraction of standard dev<br>8: generate unique alpha code<br>9: add noise to numbers but keep consistent ranking
 * @param  {Object} dictionary object of remappings to use, will be modified with new mappings also
 * @param  {StringList|String} sLValues is a list of new items to use as a basis for mode 8<br>Can also be a string name of a built-in list, one of [greek, female names, male names, names, cities, fruit]
 * @param  {Number} seed to use for randomization
 * @param  {Number} fraction of standard deviation to use for modes 7 and 9(default:0.01)
 * @return {List} modified list
 * tags:transform,#needs_example
 */
ListOperators.anonymizeList = function(list, mode, dict, sLValues, seed, fraction){
  if(list==null) return null;
  if(mode == 0) return list;

  if(mode != null && (isNaN(mode) || mode%1 !== 0 || mode > 9) )
    throw new Error('Invalid value for mode:' + mode);
  if(list.type != 'NumberList' && [4,5,6,7,9].includes(mode))
    throw new Error('Invalid numeric mode for non-numeric input:' + mode);

  if(seed != null)
    NumberOperators.randomSeed(seed);

  var len = list.length;
  var infoObject = ListOperators.buildInformationObject(list);
  var modeDefault = 1;
  if(list.type == 'NumberList'){
    if(infoObject.allIntegers && infoObject.isCategorical)
      modeDefault = 2;
    else
      modeDefault = 7;
  }
  mode = mode == null ? modeDefault : mode;
  fraction = fraction == null ? 0.01 : fraction;

  if(dict == null) dict = {};

  if(typeOf(sLValues) == 'string'){
    if(StringOperators.WORDLISTS[sLValues.toLowerCase()] != null)
      sLValues = StringOperators.WORDLISTS[sLValues.toLowerCase()];
    else if(sLValues.toLowerCase() == 'names'){
      // build combination of male lastname, female lastname
      // should maybe be done once in StringOperators
      var allNames = ListOperators.union(StringOperators.WORDLISTS['female names'],
                                         StringOperators.WORDLISTS['male names']);
      sLValues = ListOperators.crossCombineLists(allNames,StringOperators.WORDLISTS['last names']);
      sLValues = sLValues.getSortedRandom();
    }
    else{
      // unknown list
      throw new Error('Invalid built-in list name:' + sLValues);
    }
  }

  var newList = instantiateWithSameType(list);
  // intentionally lose the name
  if(newList.type != 'NumberList' && mode == 2)
    newList = new NumberList();
  var i,nnn,candidateList,s,v;

  var fnCheckCandidates = function(dict,list,candidateList,newList,bCorresponding,bPreserveNullsAndSpaces){
    var i,j;
    j=0;
    for(i=0;i<list.length;i++){
      if(bPreserveNullsAndSpaces){
        if(list[i] == null){
          newList.push(null);
          continue;
        }
        else if(String(list[i]).match(/^\s+$/)){
          newList.push(' ');
          continue;
        }
      }
      s=dict[list[i]];
      if(s == null){
        s=candidateList[j];
        j++;
        dict[list[i]]=s;
      }
      else if(bCorresponding)
        j++;
      newList.push(s);
    }
  }

  var intDecimals = new Interval(0,0);
  if(newList.type == 'NumberList' && !infoObject.allIntegers)
    intDecimals = NumberListOperators.getDecimalsInterval(list);

  switch(mode){
    case 1:
      candidateList = new StringList();
      var words;
      for(i=0;i < len;i++){
        words = String(list[i]).match(/\w+/g);
        if(words==null) words=['dummy'];
        words = ListGenerators.createListWithUniqueElements(1,2,null,null,words.length);
        candidateList.push(words[0]);
      }
      fnCheckCandidates(dict,list,candidateList,newList,true,true);
      break;
    case 2:
      // unique random integers in range given by infoObject.numberDifferentElements
      candidateList = NumberListGenerators.createSortedNumberList(infoObject.numberDifferentElements,1);
      // scramble order so the index doesn't carry information about original order of elements
      candidateList = candidateList.getSortedRandom();
      fnCheckCandidates(dict,list,candidateList,newList,false);
      break;
    case 3:
      // shuffle
      newList = list.getSortedRandom();
      break;
    case 4:
      // random numbers in same interval
      var int0 = new Interval(infoObject.min,infoObject.max);
      for(i=0;i < len;i++){
        v = int0.getRandom();
        if(infoObject.allIntegers)
          v = Math.round(v);
        else
          v = Number(v.toFixed(intDecimals.y));
        newList.push(v);
      }
      break;
    case 5:
      // random numbers from comparable normal distribution
      for(i=0; i<len; i++){
        v = NumberOperators.normal(infoObject.average,infoObject.standardDeviation);
        // also restrict to > 0 if original list was
        while(infoObject.min > 0 && v < 0)
          v = NumberOperators.normal(infoObject.average,infoObject.standardDeviation);
        if(infoObject.allIntegers)
          v = Math.round(v);
        else
          v = Number(v.toFixed(intDecimals.y));
        newList.push(v);
      }
      break;
    case 6:
      // simplify numeric values to fewer sig digits
      var intDigits = NumberListOperators.getSignificantDigitsInterval(list);
      var numDigits = Math.max(1,Math.ceil(intDigits.y/2));
      newList = NumberListOperators.setSignificantDigits(list,numDigits);
    case 7:
      // add noise to numbers based on small fraction of standard dev
      var sd = fraction*infoObject.standardDeviation;
      if(infoObject.allIntegers)
        sd = Math.max(1,sd); // allow for some variation of integers even when sd is small
      for(i=0; i<len; i++){
        var noise = NumberOperators.normal(0,sd);
        v = list[i]+noise;
        // also restrict to > 0 if original list was
        while(infoObject.min > 0 && v < 0){
          noise = NumberOperators.normal(0,sd);
          v = list[i]+noise;
        }
        if(infoObject.allIntegers)
          v = Math.round(v);
        else
          v = Number(v.toFixed(intDecimals.y));
        newList.push(v);
        // all integer fields are often in ranged categories like [0,100] or [1,10]
        // if current max is multiple of ten then restrict new list to same max
        if(infoObject.allIntegers && infoObject.max % 10 == 0)
          newList = newList.trim(null,infoObject.max);
      }
      break;
    case 8:
      if(sLValues != null){
        if(sLValues.length >= infoObject.numberDifferentElements)
          candidateList = sLValues.getRandomElements(infoObject.numberDifferentElements,true);
        else{
          // augment sLValues with nnn codes
          candidateList = sLValues.clone();
          // add trailing 1 to initial values
          for(i=0;i<candidateList.length;i++){
            candidateList[i]=candidateList[i] + ' 1';
          }
          i=0;
          nnn=2; // start at 2 since intitial value is already present, consider it '1'
          while(candidateList.length < infoObject.numberDifferentElements){
            candidateList.push(sLValues[i] + ' ' + nnn);
            i++;
            if(i>=sLValues.length){
              i=0;
              nnn++;
            }
          }
        }
      }
      else
        candidateList = ListGenerators.createListWithUniqueElements(infoObject.numberDifferentElements);
      // scramble order so the index doesn't carry information about original order of elements
      candidateList = candidateList.getSortedRandom();
      fnCheckCandidates(dict,list,candidateList,newList,false,true);
      break;
    case 9:
      // noise but keep consistent ranking
      var nt = new NumberTable();
      nt.push(NumberListGenerators.createSortedNumberList(list.length));
      nt.push(list);
      nt = nt.getListsSortedByList(1);
      // recursive call to get numbers with noise
      candidateList = ListOperators.anonymizeList(list,7,null,null,null,fraction).getSorted();
      nt.push(candidateList);
      nt = nt.getListsSortedByList(0);
      newList = nt[2];
      break;
    default:
      // invalid mode
      throw new Error('Invalid value for mode:' + mode);
  }

  if(seed != null)
    NumberOperators.randomSeedPop();

  return newList;
}

/**
 * Combine elements of two lists in a crosswise fashion with a separator between them
 * @param  {List} list1 to be combined
 * @param  {List} list2 to be combined
 *
 * @param  {String} sep is the separator to use between elements (default:' ')
 * @return {List} output StringList
 * tags:combine
 */
ListOperators.crossCombineLists = function(list1, list2, sep){
  if(list1 == null || list2 == null) return null;
  sep = sep == null ? ' ' : sep;
  var i,j;
  var newList = new StringList();
  for(i=0;i<list1.length;i++){
    for(j=0;j<list2.length;j++){
      newList.push(list1[i] + sep + list2[j]);
    }
  }
  return newList;
};

/**
 * Produce a short string characterizing the list using different methods
 * @param  {List} list to characterize
 *
 * @param  {Number} mode is method used to characterize:<br>0:first element (default)<br>1:sum<br>2:average<br>3:min<br>4:max<br>5:list concatenated with commas<br>6:most common element<br>7:unique count<br>8:unique concatenated with commas<br>9:range of values<br>10:median<br>11:up to 3 items from list then count of remaining<br>12:item frequency table inline (maximum 5 elements)<br>13:word rate/100 table inline (maximum 5 elements)
 * @param  {Boolean} bShowMode if true will include in parenthesis the method used (default:false)
 * @return {String} output string
 * tags:
 */
ListOperators.characterizeList = function(list, mode, bShowMode){
  if(list == null) return null;
  if(!list.isList) throw new Error('first inlet must be a list');
  if(list.length == 0) return '';
  if(list.infoObject == null) ListOperators.buildInformationObject(list);
  mode = mode == null ? 0 : mode;
  bShowMode = bShowMode == null ? false : bShowMode;
  if(isNaN(mode) || mode % 1 != 0 || mode < 0 || mode > 13) throw new Error('Mode must be an integer between 0 and 13');
  if([1,2,3,4,9,10].includes(mode) && list.type != 'NumberList') throw new Error('This mode only applicable for NumberList input');

  var sRes,i,l2,tf,s;
  switch(mode){
    case 0:
      sRes = list[0] + (bShowMode ? '(first)' : '');
      break;
    case 1:
      sRes = list.getSum() + (bShowMode ? '(sum)' : '');
      break;
    case 2:
      var intDec = NumberListOperators.getDecimalsInterval(list);
      sRes = NumberOperators.numberToString(list.infoObject.average,intDec.y+1) + (bShowMode ? '(avg)' : '');
      break;
    case 3:
      sRes = list.infoObject.min + (bShowMode ? '(min)' : '');
      break;
    case 4:
      sRes = list.infoObject.max + (bShowMode ? '(max)' : '');
      break;
    case 5:
      if(list.type != 'StringList')
        sRes = list.toStringList().join(', ') + (bShowMode ? '(all)' : '');
      else
        sRes = list.join(', ') + (bShowMode ? '(all)' : '');
      break;
    case 6:
      sRes = list.infoObject.mostFrequentElement + (bShowMode ? '(common)' : '');
      break;
    case 7:
      sRes = list.infoObject.numberDifferentElements + (bShowMode ? '(unique count)' : '');
      break;
    case 8:
      l2 = list.infoObject.frequenciesTable[0];
      if(l2.type != 'StringList')
        sRes = l2.toStringList().join(',') + (bShowMode ? '(unique)' : '');
      else
        sRes = l2.join(', ') + (bShowMode ? '(unique)' : '');
      break;
    case 9:
      sRes = list.infoObject.min + '-' + list.infoObject.max + (bShowMode ? '(range)' : '');
      break;
    case 10:
      sRes = list.getMedian() + (bShowMode ? '(median)' : '');
      break;
    case 11:
      sRes = '';
      for(i=0;i<3 && i < list.length;i++){
        if(sRes != '') sRes += ', ';
        sRes += String(list[i]);
      }
      if(list.length > 3)
        sRes += ', + ' + (list.length-3) + ' more';
      if(bShowMode)
        sRes += '(short list)';
      break;
    case 12:
      // freq table inline
      tf = list.infoObject.frequenciesTable;
      var OtherCount = 0;
      sRes = '';
      for(i=0;i<tf[0].length;i++){
        if(i < 5){
          if(sRes != '') sRes += ', ';
          sRes += String(tf[0][i]) + ':' + tf[1][i]; 
        }
        else
          OtherCount += tf[1][i];
      }
      if(OtherCount > 0)
        sRes += ', Other:' + OtherCount;
      sRes += (bShowMode ? '(frequency)' : '');
      break;
    case 13:
      // word freq table inline
      l2 = (list.type == 'StringList') ? list : list.toStringList();
      l2 = StringOperators.getWords(l2.join(' '),false,true,null,null,null,2);
      tf = l2.getFrequenciesTable(true,true);
      sRes = '';
      for(i=0;i < 5 && i<tf[0].length;i++){
        if(sRes != '') sRes += ', ';
        s = (100*tf[2][i]).toPrecision(2);
        s = s.includes('e') ? parseFloat(s) : s;
        sRes += String(tf[0][i]) + ':' + s;
      }
      sRes += (bShowMode ? '(word rate)' : '');
  }
  sRes = sRes.replace(/\n/g, ' '); // want single line result
  return sRes;
};

/**
 * map elements in the list to closest value in the options list. Numbers use absolute value, otherwise normalized string edit distance.
 * @param {List} list is the input list
 * @param {List} listOptions contains the possible choices
 *
 * @param {Number} outputMode <|>0:returns list of closest values (default)<|>1:returns table with original list, closest value, and distance
 * @return {List} newList is original list with values replaced by closest option
 * tags:
 * examples:jeff/examples/EditDistance
 */
ListOperators.mapElementsToClosestOption = function(list,listOptions,outputMode) {
  if(list == null) return null;
  if(listOptions == null || listOptions.length == 0) return list;
  outputMode = outputMode == null ? 0 : outputMode;

  var bNumeric = false;
  if(list.type == 'NumberList'){
    if(listOptions.type != 'NumberList')
      throw new Error('List of options must be numeric for numeric input list');
    bNumeric = true;
  }
  var fnDiff = function(a,b){
    return Math.abs(a-b);
  };
  var listOptionsOriginal = listOptions;
  var listOriginal = list.clone(); // sometimes it is output so we clone it
  if(!bNumeric){
    if(list.type != 'StringList') list = list.toStringList();
    if(listOptions.type != 'StringList') listOptions = listOptions.toStringList();
    list = list.toLowerCase();
    listOptions = listOptions.toLowerCase();
    fnDiff = function(a,b){
      return StringOperators.getLevenshteinDistance(a,b,true);
    }
  }

  var newList = list.clone();
  newList.name = 'Closest';
  var nLMin = new NumberList();
  nLMin.name = 'Distance';
  var nLIndexes = new NumberList();
  nLIndexes.name = 'Index of Closest';
  var i,j,d,dMin,jMin;
  var oFound = {}, oDist = {};
  for(i=0;i<list.length;i++){
    if(oFound[list[i]] == null){
      jMin = 0;
      dMin = fnDiff(list[i],listOptions[0]);
      for(j=1;j < listOptions.length && dMin != 0;j++){
        d = fnDiff(list[i],listOptions[j]);
        if(d < dMin){
          dMin = d;
          jMin = j;
        }
      }
      oFound[list[i]] = jMin;
      oDist[list[i]] = dMin;
    }
    else{
      jMin = oFound[list[i]];
      dMin = oDist[list[i]];
    }
    newList[i] = listOptionsOriginal[jMin];
    nLMin[i] = dMin;
    nLIndexes[i] = jMin;
  }
  var t = new Table();
  t.push(listOriginal);
  t.push(newList);
  t.push(nLMin);
  t.push(nLIndexes);
  if(outputMode == 0)
    return newList;
  return t;
};

/**
 * produce a list of different length from the input list by skipping or duplicating elements in a regular way
 * @param {List} list is the input list. If this is a table then each list is modified in the same manner.
 * @param {Number} newLength is the length of the new list
 *
 * @return {List} newList has values selected from original list 
 * tags:ds
 */
ListOperators.skipSample = function(list,newLength){
  if(list == null || list.length == 0) return null;
  var newList = instantiateWithSameType(list);
  newList.name = list.name;
  if(newLength == 0) return newList;
  var i,j,r = list.length/newLength;
  if(list.isTable){
    if(!mo.TableOperators.allListsSameLength(list))
      throw new Error('ListOperators.skipSample: Invalid when run on a table with lists of different lengths');
    for(i=0; i < list.length; i++){
      newList.push(ListOperators.skipSample(list[i],newLength));
    }
  }
  else{
    for(i=0; i < newLength; i++){
      j = Math.floor(i*r);
      newList.push(list[j]);
    }
  }
  return newList;
};

/**
 * Produce a new (same size) list with changes that remove information, useful for anonimization, syhntesis… for similar numeric transformations use erodeNumberList
 * @param  {List} list to transform
 * @param  {Number} mode <br>0:replace values by gibberish (preserves structure)<br>1:replace values by colors names (peserves structure)<br>2:scramble
 * @return {List} output string
 * tags:transform,advanced,#to_review,#hide
 */
ListOperators.erodeList = function(list, mode){

};

