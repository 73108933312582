/**
 * @classdesc Generate {@link ColorScale|ColorScales} with various properties.
 *
 * @namespace
 * @category colors
 */
function ColorScaleGenerators() {}
export default ColorScaleGenerators;


/**
 * creates a ColorScale function from colors and positions, a numberList with values in (0,1) (positions length must be colorList length minus 2)
 * @param  {ColorList} colorList
 * 
 * @param  {NumberList} positions values between 0 and 1 that determine the positions of the intermediary colors (length must be colorList length minus 2, first and last colors have positions 0 and 1)
 * @return {ColorScale}
 * tags:generator
 * examples:santiago/examples/colors/createColorScaleFromColors
 */
ColorScaleGenerators.createColorScaleFromColors = function(colorList, positions) {
  if(colorList == null ) return null;
  if(colorList.length==1){
    cS = function(t){return colorList[0]};
  } else if(colorList.length==2){
    colorList.rgbs = colorList.getRgbArrays();
    var cS = function(t) {
      var antit = 1 - t;
      return 'rgb(' +
          Math.floor( antit*colorList.rgbs[0][0] + t*colorList.rgbs[1][0] ) + ',' +
          Math.floor( antit*colorList.rgbs[0][1] + t*colorList.rgbs[1][1] ) + ',' +
          Math.floor( antit*colorList.rgbs[0][2] + t*colorList.rgbs[1][2] ) + ')';
    };

    return cS;
  }

  //if(positions == null || colorList.length <= 0 || positions.length <= 0 || colorList.length != (positions.length + 2)) return null;

  if(positions==null){
    var step = 1/(colorList.length-1);
    positions = mo.NumberListGenerators.createSortedNumberList(colorList.length-2, step, step);
  }

  if(colorList.rgbs == null) {
    colorList.rgbs = colorList.getRgbArrays();
  }

  positions = positions.slice();
  positions.unshift(0);
  positions.push(1);

  var cS = function(t) {
    var i;
    var intert, antit;
    for(i = 0; positions[i + 1] != null; i++) {
      if(t <= positions[i + 1]) {
        intert = (t - positions[i]) / (positions[i + 1] - positions[i]);
        antit = 1 - intert;
				return 'rgb(' +
					Math.floor( antit*colorList.rgbs[i][0] + intert*colorList.rgbs[i+1][0] ) + ',' +
					Math.floor( antit*colorList.rgbs[i][1] + intert*colorList.rgbs[i+1][1] ) + ',' +
					Math.floor( antit*colorList.rgbs[i][2] + intert*colorList.rgbs[i+1][2] ) + ')';
      }
    }
  };

  return cS;
};
