import ColorOperators from "src/operators/graphic/ColorOperators";

/**
 * @classdesc Functions for drawing {@link Color|Colors}.
 *
 * @namespace
 * @category drawing
 */
function ColorsDraw() {}
export default ColorsDraw;

/**
 * draws a color scale, with optional min and max associated values
 * @param  {Rectangle} frame
 * @param  {ColorScale} colorScale
 *
 * @param  {String} minValue label to show for min color
 * @param  {String} maxValue label to show for max color
 * @param  {Number} fontSize for any labels (default:12)
 * @param  {Number} orientation for the legend<|>0: chosen by width and height (default)<|>1: vertical<|>2: horizontal
 * tags:draw
 * example:santiago/examples/colors/createColorScaleFromColors
 */
ColorsDraw.drawColorScaleLegend = function(frame, colorScale, minValue, maxValue, fontSize, orientation, graphics) {
  if(colorScale==null) return;

  if(graphics==null) graphics = frame.graphics;

  // TODO refactor this to import context from Global and not reassign it.
  var change = true;//frame.memory == null || frame.width != frame.memory.w || frame.height != frame.memory.h || colorScale != frame.memory.cS || minValue != frame.memory.min || maxValue != frame.memory.max;
  fontSize = fontSize == null ? 12 : fontSize;
  orientation = orientation == null ? 0 : orientation;
  if(change) {
    // frame.memory = {
    //   w: frame.width,
    //   h: frame.height,
    //   cS: colorScale,
    //   min: minValue,
    //   max: maxValue
    // };

    ///// capture image 1
    // TODO refactor to not reassign context
    // var newCanvas = document.createElement("canvas");
    // newCanvas.width = frame.width;
    // newCanvas.height = frame.height;
    // var newContext = newCanvas.getContext("2d");
    // newContext.clearRect(0, 0, frame.width, frame.height);
    // var mainContext = context;
    // context = newContext;
    /////

    var x,clr;
    graphics.clipRect(frame.x,frame.y,frame.width,frame.height);

    if(orientation == 2 || (orientation == 0 && frame.width > frame.height) ) {

      for(x = 0; x < frame.width; x += 2) {
        graphics.setFill(colorScale(x / frame.width));
        graphics.fRect(x+frame.x, 0+frame.y, 2, frame.height);
      }

      // graphics.setStroke('rgba(255,255,255,0.8)', 3);

      if(minValue != null) {
        clr = ColorOperators.getPerceivedBrightness(colorScale(0)) > 125 ? 'black' : 'white';
        graphics.setText(clr, fontSize, null, 'left', 'middle');
        graphics.fText(minValue, 4+frame.x, frame.height * 0.5+frame.y);
      }

      if(maxValue != null) {
        clr = ColorOperators.getPerceivedBrightness(colorScale(1)) > 125 ? 'black' : 'white';
        graphics.setText(clr, fontSize, null, 'right', 'middle');
        graphics.fText(maxValue, frame.width - 4+frame.x, frame.height * 0.5+frame.y);
      }
    } else {

      for(x = 0; x < frame.height; x += 2) {
        graphics.setFill(colorScale(x / frame.height));
        graphics.fRect(0+frame.x, x+frame.y, frame.width, 2);
      }

      if(minValue != null) {
        clr = ColorOperators.getPerceivedBrightness(colorScale(0)) > 125 ? 'black' : 'white';
        graphics.setText(clr, fontSize, null, 'center', 'top');
        graphics.fText(minValue, frame.x + 0.5*frame.width, 4+frame.y);
      }

      if(maxValue != null) {
        clr = ColorOperators.getPerceivedBrightness(colorScale(1)) > 125 ? 'black' : 'white';
        graphics.setText(clr, fontSize, null, 'center', 'bottom');
        graphics.fText(maxValue, frame.x + 0.5*frame.width, frame.y+frame.height-4);
      }
    }
    graphics.restore();
    //// capture image 2
    // TODO refactor to not reassign context
    // context = mainContext;
    // frame.memory.image = new Image();
    // frame.memory.image.src = newCanvas.toDataURL();
    ////
  }


  // if(frame.memory.image) {
  //   graphics.drawImage(frame.memory.image, frame.x, frame.y);
  // }

};
