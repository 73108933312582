import Network from "src/dataTypes/structures/networks/Network";
import NodeList from "src/dataTypes/structures/lists/NodeList";
import Relation from "src/dataTypes/structures/elements/Relation";
import Node from "src/dataTypes/structures/elements/Node";

Tree.prototype = new Network();
Tree.prototype.constructor = Tree;

/**
 * @classdesc Trees are Networks that have a hierarchical structure.
 *
 * @description Create a new Tree.
 * @constructor
 * @category networks
 */
function Tree() {
  Network.apply(this);
  this.type = "Tree";

  this.nLevels = 0;
  this._createRelation = this.createRelation;
  this.createRelation = this._newCreateRelation;
}
export default Tree;


/**
 * Adds a given Node to the tree, under the given parent Node.
 *
 * @param {Node} node
 * @param {Node} parent
 */
Tree.prototype.addNodeToTree = function(node, parent) {
  this.addNode(node);
  if(parent == null) {
    node.level = 0;
    node.parent = null;
  } else {
    var relation = new Relation(parent.id + "_" + node.id, parent.id + "_" + node.id, parent, node);
    this.addRelation(relation);
    node.level = parent.level + 1;
    node.parent = parent;
    parent.weight++;
  }
  node.weight = 1;
  this.nLevels = Math.max(this.nLevels, node.level + 1);
};

/**
 * @ignore
 */
Network.prototype._newCreateRelation = function(parent, node, id, weight) {
  if(id == null) id = this.relationList.getNewId();
  this._createRelation(parent, node, id, weight);
  node.level = parent.level + 1;
  node.parent = parent;
  this.nLevels = Math.max(this.nLevels, node.level + 1);
};

/**
 * Adds a new parent node to the Tree.
 *
 * @param {Node} node New Parent Node.
 * @param {Node} child Node that will become the child.
 */
Tree.prototype.addFather = function(node, child) {
  //TODO: is children supposed to be child?
  if(child.parent != null || this.nodeList.indexOf(child) == -1) return false;
  this.addNode(node);
  child.parent = node;
  child.level = 1;
  this.nLevels = Math.max(this.nLevels, 1);
  this.createRelation(node, child);
};

/**
 * Provides a {@link NodeList} of all the Nodes of the Tree at a given level.
 *
 * @param {Number} level Level (depth) of the Tree to extract Nodes at.
 * @return {NodeList} All Nodes at the given level of the tree.
 * tags:
 */
Tree.prototype.getNodesByLevel = function(level) {
  level = level===0?0:level;

  var newNodeList = new NodeList();
  newNodeList.name = "level_"+level;
  var l = this.nodeList.length;
  var i;
  for(i = 0; i<l; i++) {
    if(this.nodeList[i].level == level) newNodeList.addNode(this.nodeList[i]);
  }
  return newNodeList;
};

/**
 * Returns the leaves (nodes without children) of a tree.
 *
 * @param {Node} node Optional parent Node to start the leaf search from. If no Node is provided, all leaf Nodes are returned.
 * @return {NodeList} Leaves of the Tree or sub-tree.
 */
Tree.prototype.getLeaves = function(node) {
  var leaves = new NodeList();
  var i, l;
  leaves.name = "leaves";
  if(node) {
    if(node.toNodeList.length === 0) {
      leaves.addNode(node);
      return leaves;
    }
    var addLeaves = function(candidate) {
      if(candidate.toNodeList.length === 0) {
        leaves.addNode(candidate);
      } else {
        l = candidate.toNodeList.length;
        for(i=0; i<l; i++){
          addLeaves(candidate.toNodeList[i]);
        }
        //candidate.toNodeList.forEach(addLeaves);
      }
    };
    l = node.toNodeList.length;
    for(i=0; i<l; i++){
      addLeaves(node.toNodeList[i]);
    }
    //node.toNodeList.forEach(addLeaves);
  } else {
    l = this.nodeList.length;
    for(i=0; i<l; i++){
      if(this.nodeList[i].toNodeList.length === 0) leaves.addNode(this.nodeList[i]);
    }

    // this.nodeList.forEach(function(candidate) {
    //   if(candidate.toNodeList.length === 0) leaves.addNode(candidate);
    // });
  }
  return leaves;
};

/**
 * assignDescentWeightsToNodes
 *
 * @return {undefined}
 */
Tree.prototype.assignDescentWeightsToNodes = function() {
  this._assignDescentWeightsToNode(this.nodeList[0]);
};

/**
 * @ignore
 */
Tree.prototype._assignDescentWeightsToNode = function(node) {
  var i;
  if(node.toNodeList.length === 0) {
    node.descentWeight = node.weight;
    return node.descentWeight;
  }
  node.descentWeight = 0;
  for(i = 0; node.toNodeList[i] != null; i++) {
    node.descentWeight += this._assignDescentWeightsToNode(node.toNodeList[i]);
  }
  node.weight = node.descentWeight;
  return node.descentWeight;
};

/**
 * Returns a string indicating the size of the Tree.
 *
 * @return {String} Log message indicating Tree's size.
 */
Tree.prototype.getReport = function() {// @todo to be placed in TreeOperators
  //TODO: remove relation input?
  return "Tree contains " + this.nodeList.length + " nodes and " + this.relationList.length + " relations";
};

/**
 * Provides a NodeList of all the descendant nodes from a given node.
 *
 * @param {Node} nodeStart Optional parent Node to start the traversal from. If no Node is provided the root is used.
 * @param {Number} mode is the traversal mode:<|>0: Depth First (default)<|>1: Breadth First
 * @param {Function} fnCallback is an optional callback function invoked on each node. If the callback function does not return true for a node then it's children are not processed.
 * @return {NodeList} All Nodes in order of traversal
 * tags: tree,extract
 */
Tree.prototype.traverse = function(nodeStart,mode,fnCallback) {
  if(this == null || this.type != 'Tree') return;

  if(nodeStart == null){
    var LNodes = this.getNodesByLevel(0);
    nodeStart = LNodes[0];
    if(nodeStart == null) return;
  }
  mode = mode == null ? 0 : mode;

  var aKeep=[nodeStart];
  var i,n,nodesChildren,iCount=0,bProcessChildren;
  var retNodeList = new NodeList();

  while(aKeep.length>0){
    n = mode == 0 ? aKeep.pop() : aKeep.shift();
    bProcessChildren = true;
    if(fnCallback)
      bProcessChildren = fnCallback(n,iCount++,this);
    retNodeList.addNode(n);
    if(!bProcessChildren) continue;

    nodesChildren = this.relationList.getChildrenOfNode(n);
    if(nodesChildren.length == 0)
      continue;

    if(mode == 0) // depthfirst
      for(i = nodesChildren.length-1; i>=0; i--){
         aKeep.push(nodesChildren[i]);
      }
    else
      for(i = 0; i< nodesChildren.length; i++){
         aKeep.push(nodesChildren[i]);
      }
  }
  return retNodeList;
};


/**
 * Provides a NodeList of all the ancestor nodes from a given node or set of nodes.
 * @param {Node|NodeList} nodeStart is a Node or NodeList to start from
 * @return {NodeList} nodeListAncestors is the list of all ancestor nodes without repetition
 * tags: tree,extract
 */
Tree.prototype.getAncestorsOf = function(nodeStart) {
  if(this == null || this.type != 'Tree') return;

  var node,i,nodes;
  if(nodeStart.type == 'Node'){
    // wrap it in a list
    nodes = new NodeList();
    nodes.addNode(nodeStart);
  }
  else if(nodeStart.type == 'NodeList')
    nodes = nodeStart;
  else
    return;

  var retNodeList = new NodeList();
  for(i=0; i < nodes.length; i++){
    node = nodes[i];
    while(node.parent != null){
      retNodeList.addNode(node.parent);
      node = node.parent;
    }
  }
  // could have duplicates, remove them
  retNodeList = retNodeList.getWithoutRepetitions();
  return retNodeList;
};

/**
 * Builds a version of the tree that contains only the given nodes plus their ancestors
 *
 * @param {NodeList} nodesStart is the list of nodes to keep plus all their ancestors
 * @return {Tree} is the filtered tree
 * tags:tree,filter
 */
Tree.prototype.buildFilteredTree = function(nodesStart) {
  if(this == null || this.type != 'Tree') return;
  if(nodesStart.type != 'NodeList')
    throw new Error('Invalid input type for buildFilteredTree');

  var treeRet = this.clone();
  var nodesAncestors = this.getAncestorsOf(nodesStart);
  var nodesRemove = new NodeList();
  var nodeTest, i;
  for(i=0; i < treeRet.nodeList.length; i++){
    nodeTest = treeRet.nodeList[i];
    if(nodesAncestors.getNodeById(nodeTest.id) == null &&
       nodesStart.getNodeById(nodeTest.id) == null){
      // not an ancestor and not in original set, remove it
      nodesRemove.addNode(nodeTest);
    }
  }
  // now go and remove all these nodes from tree (can't do it directly while looping on nodeList)
  for(i=0; i<nodesRemove.length; i++)
    treeRet.removeNode(nodesRemove[i]);
  return treeRet;
};

/**
 * Clones the tree
 *
 * @param  {StringList} nodePropertiesNames list of properties names to be copied from old nodes into new nodes
 * @param  {StringList} relationPropertiesNames
 * @param  {String} idsSubfix optional sufix to be added to ids
 * @param  {String} namesSubfix optional sufix to be added to names
 * @return {Networked} network with exact structure than original
 */
Tree.prototype.clone = function(nodePropertiesNames, relationPropertiesNames, idsSubfix, namesSubfix) {
  // Jeff July 4, 2018: This code was copied and modified slightly from Network.clone
  // We should have a better way of keeping it in one location.
  // Also, the cloning of Nodes with all their extra custom properties is problematic.
  // Ideally, we use a solution that handles this automatically, perhaps using getOwnPropertyNames
  var newTree = new Tree();
  var newNode, newRelation;
  newTree.nLevels = this.nLevels;

  if(nodePropertiesNames==null) nodePropertiesNames=[];
  if(relationPropertiesNames==null) relationPropertiesNames=[];
  nodePropertiesNames.push('parent');
  nodePropertiesNames.push('level');
  nodePropertiesNames.push('bOpen');
  nodePropertiesNames.push('color');

  idsSubfix = idsSubfix == null ? '' : String(idsSubfix);
  namesSubfix = namesSubfix == null ? '' : String(namesSubfix);

  this.nodeList.forEach(function(node) {
    newNode = new Node(idsSubfix + node.id, namesSubfix + node.name);
    if(idsSubfix !== '') newNode.basicId = node.id;
    if(namesSubfix !== '') newNode.basicName = node.name;
    if(nodePropertiesNames) {
      nodePropertiesNames.forEach(function(propName) {
        if(node[propName] != null) newNode[propName] = node[propName];
      });
    }
    newTree.addNode(newNode);
  });

  this.relationList.forEach(function(relation) {
    newRelation = new Relation(idsSubfix + relation.id, namesSubfix + relation.name, newTree.nodeList.getNodeById(idsSubfix + relation.node0.id), newTree.nodeList.getNodeById(idsSubfix + relation.node1.id), relation.weight);
    if(idsSubfix !== '') newRelation.basicId = relation.id;
    if(namesSubfix !== '') newRelation.basicName = relation.name;
    if(relationPropertiesNames) {
      relationPropertiesNames.forEach(function(propName) {
        if(relation[propName] != null) newRelation[propName] = relation[propName];
      });
    }
    newTree.addRelation(newRelation);
  });

  return newTree;
};
